import styled from 'styled-components/macro';

const FilterWrap = styled.div`
  // flex: 1;
  & select {
    // margin: 0 25px;
    padding: 6px;
    border: 1px solid #d2d2d2;
    outline: none;
  }
`;

export {
  FilterWrap,
};
