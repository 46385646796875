/* Release 3.2 - 8.1 - Starts */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FormHeading,
  FormButtonsWrap,
  FormButton,
  Table,
  TableHeadingWrap,
} from "../";
import { columns } from "../../util/config";
import { unselectAllRecord, pushMessage } from "../../actions";

const AddressData = (props) => {
  const [queryFilter, setTableFilter] = useState({address_record_type: { eq: '9OFF' }});
  const [queryFilter_perminant, setTableFilter_permant] = useState(
    {

      address_record_type: { eq: '1' },
      country_name: { eq: 'USA' }
    }
    );
  const [employeeDetails, setEmployeeDetails] = useState([]);
 
  //let refetchAfterCreateRequest = [{
   // query: delegatesForManager,
    //variables: {
     // limit: 20,
     // offset: 0,
     // orderBy: [{column: "id", order: "DESC"}]
   // }
  //}];
  
  const {
    selectedEmployees,
    hideModal,
    unselectAllRecord,
  } = props;

  const orderBy = {};

  useEffect(() => {
    if (selectedEmployees && selectedEmployees.length > 0) {
      setEmployeeDetails(selectedEmployees[0]);
      /*setTableFilter(
        { 
          pers_no: { eq: selectedEmployees[0].pers_no} ,
          address_record_type: {eq : '9OFF'}
        }
      );*/

      setTableFilter({ pers_no: { eq: selectedEmployees[0].pers_no }, address_record_type: "9OFF"  });
      setTableFilter_permant({ pers_no: { eq: selectedEmployees[0].pers_no },address_record_type: "1",country_name:"USA" })
      
    }
  }, []);

  const handleQuit = () => {
    hideModal();
    unselectAllRecord("employees");
  };
  
  return (
    <div>
      <FormHeading>Addresses</FormHeading> 
      <TableHeadingWrap
        style={{ marginBlockEnd: "1em" }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            {" "}
            <b>Nokia ID :</b>{" "}
          </label>
          {employeeDetails && employeeDetails.nokia_id
            ? employeeDetails.nokia_id
            : ""}
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
            marginLeft:"0"
          }}
        >
          <label>
            <b> Name : </b>
          </label>
          {employeeDetails && employeeDetails.full_name
            ? employeeDetails.full_name
            : ""}
        </div>
      </TableHeadingWrap>
      <FormHeading>Office Location</FormHeading> 
      {employeeDetails &&  queryFilter.pers_no &&   
        <Table
          recordType="employeehistoryaddresssection"
          columns={columns.employeehistoryaddressoffice}
          query="addresscurrentHistoryList"
          where={queryFilter}
          order={orderBy}
          checkboxIsDisplayed={false}
          enablePagination={false}
        />
      }
      {employeeDetails && employeeDetails.country_name =='USA' &&  queryFilter_perminant.pers_no &&
      <FormHeading>Permanent Residence</FormHeading> 
}
{employeeDetails && employeeDetails.country_name =='USA' &&  queryFilter_perminant.pers_no &&   
        
        <Table
          recordType="employeehistoryaddresssection"
          columns={columns.employeehistoryaddrespermanent}
          query="addresscurrentHistoryList"
          where={queryFilter_perminant}
          order={orderBy}
          checkboxIsDisplayed={false}
          enablePagination={false}
        />
      }
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
      </FormButtonsWrap>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
  };
};

export default connect(mapStateToProps, { unselectAllRecord, pushMessage })(
  AddressData
);
/* Release 3.2 - 8.1 - Ends */
