export default (table) => {
  let name = 'new_position_title';
  let visible_in_tooltip = /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
  ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true;
  /* Release 3.2 - 1.1 - Ends */
  let defaultValue = (value, data) => data.position_title;

  switch (table) {
    case 'editCompensation':
    case 'rejectRequests':
    case 'approveRequests':
      case 'editInternalHire':
      name = 'request.new_position_title';
      defaultValue = (value, data) => data.request.for.position_title;
      break;
    case 'myRequests':
    case 'requestsHistory':
    case 'approvedRequestHistory':
    case 'pendingRequests':
      defaultValue = (value, data) => data.for.position_title;
      break;
    default:
      break;
  }

  return { 
    name,
    label: "Position Title",
    tooltip_section: "New",
    visible_in_tooltip,
    defaultValue
  };
};