import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { renderRoutes } from 'react-router-config';
import { ThemeProvider } from 'styled-components/macro';
import GlobalStyle from './style';
import theme from './style/theme';
import { AppContainer, Footer, BodyWrap, Modal } from './components';
import { CONFIG, GET_FORMULAS } from './graphql';
import { setConfig, setFormulas } from './actions';

const App = ({ route, className }) => {
  const { loading: fLoading, error: fError, data: fData } =  useQuery(GET_FORMULAS); //Admin calculations for R2 start
  // load configuration

  const { loading: cLoading, error: cError, data: cData } = useQuery(CONFIG);
  if (cLoading || cError) return null;
  if (cData) route.store.dispatch(setConfig(cData));
  /* Admin calculations for R2 start */
  if (fLoading || fError) return null;
  if (fData) route.store.dispatch(setFormulas(fData));
  /* Admin calculations for R2 end */

  
  
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
          <GlobalStyle />
          <BodyWrap>
            {renderRoutes(route.routes)}
          </BodyWrap>
          <Footer />
      </AppContainer>
      <Modal context="popup" />
    </ThemeProvider>
  );
}

export default {
  component: App
};
