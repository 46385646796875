import React from "react";
import ReactDOM from "react-dom";
import { AzureAD } from "react-aad-msal";
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { Provider } from "react-redux";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import getRoutes from "./Routes";
import createStore from "./store";
import * as serviceWorker from "./serviceWorker";
import { AUTHORIZE } from "./graphql";
import { updateAccountFromAuthToken } from "./actions/index";
import { authProvider } from "./util/auth";
import { isDebug } from "./util";
/**Release 2 Admin table starts*/
import {
  ApolloClient as ApolloUpClient,
  ApolloLink,
  InMemoryCache,
  concat,
} from "@apollo/client";

import { createUploadLink } from "apollo-upload-client";

/**Release 2 Admin table Ends*/
const extractMSALToken = function () {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  let token = null;

  for (const key of Object.keys(sessionStorage)) {
    if(!token) { //stop unwanted loop AADSTS50196 error on office 356 log... - TrackingID#2208020050000912
      if (key.includes('"authority":')) {
        const val = JSON.parse(sessionStorage.getItem(key));
        if (val.expiresIn) {
          // We have a (possibly expired) token
          /* Start AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
          if (val.idToken === val.accessToken) {
            // Found the correct token
            // Token match and expire then redirect to logout
            if(val.expiresIn > timestamp) {
              token = val.idToken;
            } else {
              sessionStorage.removeItem("proxy_access");
              sessionStorage.removeItem("proxy_name");
              sessionStorage.removeItem("access_token_payload");
              sessionStorage.removeItem("access_token");
              sessionStorage.clear();
              authProvider.logout();
            }
          }
          // else{
          //   // Clear old data
          //   sessionStorage.removeItem(key)
          // }
          /* End AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
        }
      }
    }
  }
  if (token) return token;
  throw new Error("No valid token found");
};

const acquireInternalToken = function () {
  if (!acquireInternalToken.promise) {
    acquireInternalToken.promise = new Promise((resolve, reject) => {
      if (proxyCheck == null) {
        apolloClient
          .query({ query: AUTHORIZE, fetchPolicy: "no-cache" })
          .then((result) => {
            sessionStorage.setItem("access_token", result.data.authorize.token);
            if (!accessPayload) {
              sessionStorage.setItem(
                "access_token_payload",
                JSON.stringify(result.data.authorize.payload)
              );
              store.dispatch(
                updateAccountFromAuthToken(result.data.authorize.payload)
              );
            }
            resolve(result.data.authorize.token);
          })
          .catch((error) => {
            console.log("ERROR acquiring access token");
            console.log(error.message);
            reject(error);
          });
      } else {
        apolloProxyClient
          .query({ query: AUTHORIZE, fetchPolicy: "no-cache" })
          .then((result) => {
            sessionStorage.setItem("access_token", result.data.authorize.token);
            if (!accessPayload) {
              sessionStorage.setItem(
                "access_token_payload",
                JSON.stringify(result.data.authorize.payload)
              );
              store.dispatch(
                updateAccountFromAuthToken(result.data.authorize.payload)
              );
            }
            resolve(result.data.authorize.token);
          })
          .catch((error) => {
            console.log("ERROR acquiring access token");
            console.log(error.message);
            reject(error);
          });
      }
    });
  }
  return acquireInternalToken.promise;
};

const proxyCheck = sessionStorage.getItem("proxy_access");
const store = createStore();
let accessPayload = sessionStorage.getItem("access_token_payload");
if (accessPayload) {
  store.dispatch(updateAccountFromAuthToken(JSON.parse(accessPayload)));
}

/**Release 2 Admin Tables - starts */
const httpLink = new createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_API + "/graphql",
});
const authMiddleware = new ApolloLink(async (operation, forward) => {
  // add the authorization to the headers
  await authProvider.getAccessToken();
  const adIdToken = await authProvider.getIdToken();

  let externalToken = sessionStorage.getItem("msal.idtoken");

  // If msal.istoken is not found, then we can extract it from the authority
  if (!externalToken) {
    externalToken = extractMSALToken();
  }

  if (externalToken !== adIdToken.idToken.rawIdToken) {
    // External token renewed, need to renew internal as well
    delete acquireInternalToken.promise;
    sessionStorage.removeItem("proxy_access");
    sessionStorage.removeItem("proxy_name");
    sessionStorage.removeItem("access_token_payload");
    sessionStorage.removeItem("access_token");
    sessionStorage.clear();
  }

  /* Start AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
  if (externalToken && operation.operationName === "Authorize") {
    operation.setContext({
      headers: {
        authorization: `Bearer ${externalToken}`,
      },
    });
  } else if (operation.operationName !== "Authorize") {
    let internalToken = sessionStorage.getItem("access_token");
    if (!internalToken) {
      // Exchange external token to internal token
      internalToken = await acquireInternalToken();
    }
    if (internalToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${internalToken}`,
        },
      });
    }
  }
  /* End AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
  return forward(operation);
});
export const apollouploadClient = new ApolloUpClient({
  //uri: process.env.REACT_APP_GRAPHQL_API,
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            console.log(
              "UNAUTHENTICATED response from server, clearing internal access token for renewal"
            );
            delete acquireInternalToken.promise;
            sessionStorage.removeItem("access_token");
            return forward(operation);
          default:
            if (isDebug) console.log("API ERROR: ", err);
            return forward(operation);
        }
      }
    } else if (networkError) {
      console.log(
        "Network error: Token renewal operation failed due to timeout. Response from server, clearing internal access token for renewal"
      );
      delete acquireInternalToken.promise;
      sessionStorage.removeItem("proxy_access");
      sessionStorage.removeItem("proxy_name");
      sessionStorage.removeItem("access_token_payload");
      sessionStorage.removeItem("access_token");
      sessionStorage.clear();
      return forward(operation);
    }
  },
});
/**Release 2 Admin Tables - Ends */
export const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API,
  request: async (operation) => {
    await authProvider.getAccessToken();
    const adIdToken = await authProvider.getIdToken();
    
    let externalToken = sessionStorage.getItem("msal.idtoken");

    // If msal.istoken is not found, then we can extract it from the authority
    if (!externalToken) {
      externalToken = extractMSALToken();
    }
    
    if (externalToken !== adIdToken.idToken.rawIdToken) {
      // External token renewed, need to renew internal as well
      delete acquireInternalToken.promise;
      sessionStorage.removeItem("proxy_access");
      sessionStorage.removeItem("proxy_name");
      sessionStorage.removeItem("access_token_payload");
      sessionStorage.removeItem("access_token");
      sessionStorage.clear();
    }
    /* Start AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
    if (externalToken && operation.operationName === "Authorize") {
      operation.setContext({
        headers: {
          authorization: `Bearer ${externalToken}`,
          from: "",
          ProxyUser: "",
        },
      });
    } else if (operation.operationName !== "Authorize") {
      let internalToken = sessionStorage.getItem("access_token");
      if (!internalToken) {
        internalToken = await acquireInternalToken();
      }
      if (internalToken) {
        operation.setContext({
          headers: {
            authorization: `Bearer ${internalToken}`,
            from: "",
            ProxyUser: "",
          },
        });
      }
    }
    /* End AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
  },
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            console.log(
              "UNAUTHENTICATED response from server, clearing internal access token for renewal"
            );
            delete acquireInternalToken.promise;
            sessionStorage.removeItem("access_token");
            return forward(operation);
          default:
            if (isDebug) console.log("API ERROR: ", err);
            return forward(operation);
        }
      }
    } else if (networkError) {
      console.log(
        "Network error: Token renewal operation failed due to timeout. Response from server, clearing internal access token for renewal"
      );
      delete acquireInternalToken.promise;
      sessionStorage.removeItem("proxy_access");
      sessionStorage.removeItem("proxy_name");
      sessionStorage.removeItem("access_token_payload");
      sessionStorage.removeItem("access_token");
      sessionStorage.clear();
      return forward(operation);
    }
  },
});

export const apolloProxyClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API,
  request: async (operation) => {
    await authProvider.getAccessToken();
    const adIdToken = await authProvider.getIdToken();

    let externalToken = sessionStorage.getItem("msal.idtoken");

    // If msal.istoken is not found, then we can extract it from the authority
    if (!externalToken) {
      externalToken = extractMSALToken();
    }

    if (externalToken !== adIdToken.idToken.rawIdToken) {
      // Always renew internal token
      delete acquireInternalToken.promise;
      sessionStorage.removeItem("proxy_access");
      sessionStorage.removeItem("proxy_name");
      sessionStorage.removeItem("access_token_payload");
      sessionStorage.removeItem("access_token");
      sessionStorage.clear();
    }
    /* Start AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
    if (externalToken && operation.operationName === "Authorize") {
      operation.setContext({
        headers: {
          authorization: `Bearer ${externalToken}`,
          from: "Proxy",
          ProxyUser: proxyCheck || "",
        },
      });
    } else if (operation.operationName !== "Authorize") {
      let internalToken = sessionStorage.getItem("access_token");
      if (!internalToken) {
        internalToken = await acquireInternalToken();
      }
      if (internalToken) {
        operation.setContext({
          headers: {
            authorization: `Bearer ${internalToken}`,
            from: "Proxy",
            ProxyUser: proxyCheck || "",
          },
        });
      }
    }
    /* End AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
  },
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            console.log(
              "UNAUTHENTICATED response from server, clearing internal access token for renewal"
            );
            delete acquireInternalToken.promise;
            sessionStorage.removeItem("access_token");
            return forward(operation);
          default:
            if (isDebug) console.log("API ERROR: ", err);
            return forward(operation);
        }
      }
    } else if (networkError) {
      console.log(networkError);
      console.log(
        "Network error: Token renewal operation failed due to timeout. Response from server, clearing internal access token for renewal"
      );
      delete acquireInternalToken.promise;
      sessionStorage.removeItem("proxy_access");
      sessionStorage.removeItem("proxy_name");
      sessionStorage.removeItem("access_token_payload");
      sessionStorage.removeItem("access_token");
      sessionStorage.clear();
      return forward(operation);
    }
  },
});

ReactDOM.render(
  <ApolloProvider
    client={proxyCheck == null ? apolloClient : apolloProxyClient}
  >
    <Provider store={store}>
      <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
        <BrowserRouter>{renderRoutes(getRoutes(store))}</BrowserRouter>
      </AzureAD>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
