import styled from 'styled-components/macro';

const TableHeadingWrap = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const TableActionsWrap = styled.div``;

const TableAction = styled.button`
  cursor: pointer;
  color: ${({ disabled }) => disabled ? '#cccccc' : '#0A61EE'};
  padding: 10px;
  border: 1px solid;
  border-color: ${({ disabled }) => disabled ? '#cccccc' : '#0A61EE'};
  background-color: transparent;
  border-radius: 5px;
  width: ${({ width }) => width ? width : 'auto'};

  & + & {
    margin-left: 1rem;
  }
`;

const RowWrap = styled.div`
  display: flex;
  background-color: ${({ background }) => background ? background : '#ffffff'};
`;

const TableHeaderRowWrap = styled.div`
  display: flex;
  background-color: #ECF0F3;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const CellWrap = styled.div`
  border: 1px solid #d2d2d2;
  border-right: 0;
  
  flex: ${({ size, width }) => size ? 'none' : width ? `0 0 ${width}` : '1'};
  width: ${({ size }) => size ? size : 'auto'};
  padding: 6px;
  display: flex;
  position: relative;
  font-weight: ${({ context }) => context === "header" ? 'bold' : 'normal'};
  overflow: hidden;
  justify-content: ${({ justify }) => justify ? justify : 'space-between'};
  align-items: center;
`;

const SymbolSpan = styled.span`
  width: 60px;
  align-self: center;
`;

const SortWrap = styled.span`
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: space-between;
`;

const SortEl = styled.span`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: ${({ desc, active }) => desc ? `8px solid ${active ? '#000000' : '#7A80DE'}` : '0'};
  border-bottom: ${({ asc, active }) => asc ? `8px solid ${active ? '#000000' : '#7A80DE'}` : '0'};
  cursor: pointer;

  &:hover {
    border-top-color: #000000;
    border-bottom-color: #000000;
  }
`;

const GridLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectCellWrap = styled(CellWrap)`
  align-items: center;
  justify-content: center;

  & input[type="checkbox"] {
    width: 18px;
    height: 18px;
  }
`;

const InfoCellWrap = styled.div`
  color: #0A61EE;
  border: 1px solid #0A61EE;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 26px;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const IconWrap = styled.span`
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 1.5px solid #000000;
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
  margin-right: 1rem;
`;

const StatusWrap = styled.span`
  line-height: 1rem;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-radius: 5px;
  height: max-content;
  background-color: ${({value}) => 
    value === 'open' || value === 'pending' ? '#F3E69D' 
    : value === 'approved' ? '#C8ECBB'
      : value === 'rejected' || value === 'canceled' ? '#F7AAAA'
        : value === 'review' ? 'lightblue'
          : value === 'stopped' ? 'orange'
            : value === 'archived' ? 'white'
              : 'transparent' };
`;
/* Release 3.1 - Starts */
const IconWrapWithoutBorder = styled.span`
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
  margin-right: 1rem;
`;
/* Release 3.1 - Ends */
export {
  TableHeadingWrap,
  TableActionsWrap,
  TableAction,
  TableHeaderRowWrap,
  RowWrap,
  CellWrap,
  GridLoader,
  SortWrap,
  SortEl,
  SelectCellWrap,
  InfoCellWrap,
  IconWrap,
  SymbolSpan,
  StatusWrap,
  /* Release 3.1 - Starts */
  IconWrapWithoutBorder,
  /* Release 3.1 - Ends */
};
