//Release 2 - Budget Management add new budget pop - up
import React, { useState, useEffect } from "react";
import { UploadFile } from "./UploadFile";
import { connect } from "react-redux";
import { ErrorsBlock, IconWrap } from "../";
import { pushMessage } from "../../actions";

const TableImport = (props) => {
  const { modal, 
    /* Release 3.1 - Starts */
  hideModal, 
  pushMessage, 
  /* Release 3.1 - Ends */
  } = props;
  console.log(modal.modalContent);
  const [submitErrors, setSubmitErrors] = useState(false);
  const [selectedtable] = useState(modal.modalContent.toString());
  const tablemapping = {
    gppsForAdmin: "GPP Guidelines",
    itpsForAdmin: "Theoretical ITP",
    conForAdmin: "Countries",
    incForAdmin: "Increases",
    tapUsersForAdmin: "TAP Users",
    /* DEM0065375 - External Users Access Starts  */
    externalTapUsersForAdmin:"External Tap Users",
    /* DEM0065375 - External Users Access Ends  */
    orgHRBPUsersForAdmin: "PP Users",
    orgAdminUsersForAdmin: "Admin Users",
    gctUsersForAdmin: "GCT Users",
    hrConnectionForAdmin: "HR Services",
    /* Release 3.2 - 10.1 - Starts */
    orgRBCBUsersForAdmin: "RBCB Users",
    /* Release 3.2 - 10.1 - Ends */
    /* Release 3.2 - 10.4 - Starts */
    orgKeyUsersForAdmin: "Key Users",
    /* Release 3.2 - 10.4 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    orgBusinessOwnersForAdmin: "Business Owners",
    /* Release 3.2 - 1.1 - Ends */
  };

  useEffect(() => {}, []);

  return (
    <div
      style={{
        width: "50%",
        padding: "2px",
        margin: "0 auto",
      }}
    >
      <input id="searchResult" style={{ display: "none" }} />
      <div
        style={{
          fontSize: "20px",
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        Import admin table updates - {tablemapping[selectedtable]}
      </div>
      <div style={{ fontSize: "1.5rem" }}>
        <UploadFile
          adtable={selectedtable}
          /* Release 3.1 - Starts */ 
          hideModal={hideModal}
          pushMessage={pushMessage} 
          /* Release 3.1 - Ends */
        />
      </div>

      {submitErrors.length > 0 &&
        submitErrors.map((err) => {
          return (
            <ErrorsBlock key={Math.random()}>
              <IconWrap>!</IconWrap>
              {err}
            </ErrorsBlock>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps, { pushMessage })(TableImport);
