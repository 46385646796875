
import { numberRenderer } from '../../renderers';
export default (table) => {
  
   // console.log("test rerender after change",changeValue);
    let name = 'ttc_increase_percentage';
    switch (table) {
    
        case 'editInternalHire':
        
          name = "request.ttc_increase_percentage";

          break;
          default:
            break;
    }
  
    let property = {
        type: "float",
        inputType: "number",
        name,
        label: "Total Target Cash increase %",
        section: "Merit/Promotion Increase",
        visible: true,
        editable: true,
        colFrom : "internalHire",
        renderer: numberRenderer,
        width:'10%'
    }

    return property;
  };
