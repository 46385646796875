export default (table) => {
  let name = 'job_name';

  switch (table) {
    case 'rejectRequests':
    case 'approveRequests':
    case 'editCompensation':
    case 'editOnetime':    
    /* Release 3.2 - 1.1 - Starts */
    case 'editBEA':
    /* Release 3.2 - 1.1 - Ends */
      case 'editInternalHire':
      name = 'request.for.job_name'; 
      break;
    case 'myRequests':
    case 'requestsHistory':
    case 'approvedRequestHistory':
    case 'pendingRequests':
      name = 'for.job_name'; 
      break;
    default:
      break;
  }

  return {
      name,
      tooltip_section: "Current",
      label: "Job",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
      (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
      /* Release 3.2 - 1.1 - Ends */
    };
};