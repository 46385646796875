/* Release 3.2 - 1.1 - Starts */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  BudgetRow,
  BudgetCell,
  BudgetLabel,
  BudgetWrap,
  BudgetContent,
} from ".";
import { apolloClient, apolloProxyClient } from "../index";
import { organizationBEABusinessOwnerBudget } from "../graphql";
import { currencyDisplayRenderer } from "../util";

const BOBudget = (props) => {
  const { account } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const isGct = account.roles.includes("gct");

  const isAdmin = account.roles.includes("admin");
  const [bEBusinessOwnerInfo, setBEBusinessOwnerInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const getBEBusinessOwnerInfo = async () => {
    setLoading(true);
    return await new Promise((resolve, reject) => {
      httpClient
        .query({
          query: organizationBEABusinessOwnerBudget,
          variables: {
            where: {
              pers_no: {
                eq: account && account.pers_no ? account.pers_no : "",
              },
            },
            offset: 0,
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          if (
            response.data &&
            response.data.organizationBEABusinessOwnerBudget &&
            response.data.organizationBEABusinessOwnerBudget.items.length > 0
          ) {
            resolve(response.data.organizationBEABusinessOwnerBudget.items);
          } else {
            resolve([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    });
  };
  useEffect(() => {
    getBEBusinessOwnerInfo().then((BEBusinessOwnerData) => {
      setBEBusinessOwnerInfo(BEBusinessOwnerData);
    });
  }, []);

  return (
    <React.Fragment>
      {bEBusinessOwnerInfo && bEBusinessOwnerInfo.length ? (
        <BudgetWrap>
          <BudgetContent>
            {bEBusinessOwnerInfo.map((item, index) => {
              return (
                <>
                <BudgetRow>
                  <BudgetCell>
                    <BudgetLabel>{item.merge_full_name ? item.merge_full_name : "N/A"} Budget </BudgetLabel>
                  </BudgetCell>
                </BudgetRow>
                <BudgetRow key={Math.random()}>
                  <BudgetCell>
                    <BudgetLabel>Budget: </BudgetLabel>
                    {currencyDisplayRenderer(item.bo_budget, {
                      currency: "EUR",
                    })}
                  </BudgetCell>
                  <BudgetCell>
                    <BudgetLabel>In Progress: </BudgetLabel>
                    {currencyDisplayRenderer(item.bo_budget_in_progress, {
                      currency: "EUR",
                    })}
                  </BudgetCell>
                  <BudgetCell>
                    <BudgetLabel>Spent: </BudgetLabel>
                    {currencyDisplayRenderer(item.bo_budget_spent, {
                      currency: "EUR",
                    })}
                  </BudgetCell>
                  <BudgetCell>
                    <BudgetLabel>Available Forecast: </BudgetLabel>
                    {currencyDisplayRenderer(item.bo_budget_available, {
                      currency: "EUR",
                    })}
                  </BudgetCell>
                  <BudgetCell>
                    <BudgetLabel>Available: </BudgetLabel>
                    {currencyDisplayRenderer(
                      item.bo_budget - item.bo_budget_spent,
                      { currency: "EUR" }
                    )}
                  </BudgetCell>
                </BudgetRow>
                </>
              );
            })}
          </BudgetContent>
        </BudgetWrap>
      ): '' }
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps)(BOBudget);
/* Release 3.2 - 1.1 - Ends */
