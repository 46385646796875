export default (table) => {
  /*Release 2 - budget management column*/
       
    return {
         name: "description", 
         label: "Budget Description",
         section: "Budget Management",
         visible: true,
         sortable: true,
         width:'15%'
    };
  };