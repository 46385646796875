/* Release 3.2 - 1.1 - Starts */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { showModal, toggleShowInEur, unselectAllRecord, budgetSetUpdated } from "../actions";
import {
  GlobalMessages,
  Header,
  Budget,
  Table,
  TableHeadingWrap,
  TableActionsWrap,
  TableAction,
} from "../components";
import { columns } from "../util/config";

const BEABudgetManage = (props) => {
  const { messages, showModal, account, config, unselectAllRecord, selectedBudgets, budgetSetUpdated} = props;
  const { roles, accessibles } = account;
  const isGct = roles.includes("gct");
  const isAdmin = roles.includes("admin");
  const isBCB = roles.includes("bcb");
  const currentYear = new Date().getFullYear();
  const nextYear = new Date().getFullYear() + 1;
  const tableQuery = "organizationBEABudget";
  const [tablesearch, setSearchValue] = useState(null);
  const [tableCurrentYear, setTableYear] = useState(currentYear.toString());
  const [tableRefreshKey, setTableRefreshKey] = useState(1);
  const orderBy = { budget_id: "DESC" };

  useEffect(() => {
    // unselectAllRecord("budgets");
    // console.log("BEABudgetMgmt account ", account);
    // console.log("BEABudgetMgmt config ", config);
    if (messages.length > 0) {
      setTableRefreshKey(Math.floor(Math.random() * 86));
      budgetSetUpdated(true);
    }
  }, [
    unselectAllRecord,
    messages,
    budgetSetUpdated
  ]);
  
  const hasGlobalMessages = messages.length > 0;
  const hasSelectedBudgets = selectedBudgets.length > 0 && selectedBudgets.length == 1;
  const hasBudgetVisibility =
    (accessibles && accessibles.budgetOrgs.length > 0) ||
    isGct ||
    isAdmin ||
    isBCB;
  const visible = isGct || isAdmin || isBCB;

  const handleAddBudget = () => {
    showModal({ component: "addBEABudget", content: tableCurrentYear });
  };

  const handleDeleteBudget = () => {
    showModal({ component: "deleteBEABudget", content: tableCurrentYear });
  };

  const handleEditBudget = (value) => {
    showModal({ component: "editBEABudget", content: value });
  };

  const handleBusinessOwnerBudget = (value) => {
    showModal({ component: "businessOwnerBudget" });
  };
  
  const onChange = (event) => setSearchValue(event.target.value);

  const onYearChanged = (event) => {
    unselectAllRecord("budgets");
    setTableYear(event.target.value);
  };

  return (
    <div style={{ fontSize: "0.8rem" }}>
      <input id="searchResult" style={{ display: "none" }} />
      <Header {...props} />
      {hasBudgetVisibility && <Budget {...props} />}
      {hasGlobalMessages && <GlobalMessages />}
      <TableHeadingWrap
        style={{ justifyContent: "center", marginBlockEnd: "1em" }}
      >
        <div
          style={{
            width: "34%",

            display: "flex",
            justifyContent: "left",
            gap: "10px",
            alignItems: "baseline",
            borderRight: "2px solid rgba(128, 128, 128, 0.63)",
          }}
        >
          <h2
            style={{
              marginBlockStart: "0em",
              marginBlockEnd: "0em",
            }}
          >
            Business Excellence Award Budget Management
          </h2>
        </div>
        {visible && (
          <div
            style={{
              width: "28%",
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              fontSize: "15px",
              gridGap: "10px",
              alignItems: "baseline",
              borderRight: "2px solid #808080a1",
            }}
          >
            <label>Search : </label>
            <input
              placeholder="Organisation name"
              style={{
                border: "2px solid #80808063",
                borderRadius: "5px",
                height: "30px",
              }}
              type="search"
              onChange={onChange}
            />
          </div>
        )}
        {visible && (
          <div
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "left",
              paddingLeft: "2%",
              fontWeight: "500",
              fontSize: "15px",
              gridGap: "10px",
            }}
          >
            {" "}
            <label>Select Year : </label>
            <input
              style={{
                border: "1px solid black",
                height: "1rem",
                padding: "0.4em",
              }}
              type="radio"
              id="cyear"
              value={currentYear}
              onChange={onYearChanged}
              checked={tableCurrentYear === currentYear.toString()}
            ></input>
            <label>{currentYear}</label>
            <input
              style={{
                border: "1px solid black",
                height: "1rem",
                padding: "0.4em",
              }}
              type="radio"
              id="nyear"
              value={nextYear}
              onChange={onYearChanged}
              checked={tableCurrentYear === nextYear.toString()}
            ></input>
            <label>{nextYear}</label>
          </div>
        )}

        {(isGct || isAdmin || isBCB) && (
          <div
            style={{
              width: "37%",
              display: "flex",
              justifyContent: "left",
              fontWeight: "500",
              gridGap: "10px",
            }}
          >
            <TableActionsWrap>
              <TableAction onClick={handleAddBudget}>
                Add Budget for {tableCurrentYear}
              </TableAction>
            </TableActionsWrap>
            <TableActionsWrap>
              <TableAction disabled={!hasSelectedBudgets} onClick={handleDeleteBudget}>
                Delete Budget
              </TableAction>
            </TableActionsWrap>
            <TableActionsWrap>
              <TableAction disabled={!hasSelectedBudgets} onClick={handleBusinessOwnerBudget}>
                Business Owner Budget for {tableCurrentYear}
              </TableAction>
            </TableActionsWrap>
          </div>
        )}
      </TableHeadingWrap>

      <Table
        recordType="budgets"
        columns={columns.beabudgetmanage}
        query={tableQuery}
        where={
          isBCB
            ? tablesearch && tablesearch.trim().length != 0
              ? {
                  OR: [
                    {
                      organization_unit_name: {
                        contains: tablesearch.toString(),
                      },
                    },
                  ],
                  organization_unit: { in: accessibles.budgetOrgs },
                  year: { eq: tableCurrentYear.toString() },
                }
              : {
                  organization_unit: { in: accessibles.budgetOrgs },
                  year: { eq: tableCurrentYear.toString() },
                }
            : tablesearch && tablesearch.trim().length != 0
            ? {
                OR: [
                  {
                    organization_unit_name: {
                      contains: tablesearch.toString(),
                    },
                  },
                ],
                year: { eq: tableCurrentYear.toString() },
              }
            : { year: { eq: tableCurrentYear.toString() } }
        }
        order={orderBy}
        Budgetfilter={tablesearch}
        editOnClick={handleEditBudget}
        config={config}
        account={account}
        key={tableRefreshKey}
        checkboxIsDisplayed={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedBudgets: state.selectedBudgets,
    account: state.account,
    messages: state.messages,
    config: state.config,
  };
};

export default connect(mapStateToProps, { showModal, toggleShowInEur, unselectAllRecord, budgetSetUpdated })(
  BEABudgetManage
);

/* Release 3.2 - 1.1 - Ends */
