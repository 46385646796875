import { GET_ALL_FORMULAS } from '../actions/index';

const intialState = {
  formulas: []
};

export default (state = intialState, action) => {
  switch (action.type) {
    case GET_ALL_FORMULAS:
        return {...state, formulas: action.payload.formulas}
      
    default:
      return state;
  }
};
