import { SET_UPDATED } from '../actions/index';

const intialState = {
  isVisible: false,
  isUpdated: false
};

export default (state = intialState, action) => {
  switch (action.type) {
    case SET_UPDATED:
      return Object.assign({}, state, {
        isUpdated: action.payload
      });
    default:
      return state;
  }
};
