import { percentRenderer } from '../../renderers';

export default (table) => {
  
  return {
    type: "float",
    inputType: "number",
    name: "request.override_fte_incentive_target_percentage",
    label: "Override Incentive Target Percentage",
    section: "HR",
    visible: true,
    renderer: percentRenderer,
    allow: ['hr1', 'hr2', 'hr3', 'bcb']
  };
};