export default (table) => {
  let name = 'new_position_title';
  let defaultValue = (value, data) => data.position_title;
  let maxlength = 0;
  switch (table) {
    case 'editCompensation':
      case 'editInternalHire':
      name = 'request.new_position_title';
      defaultValue = '';
      break;

    case 'internalHire' :
      maxlength = 40;
      break;
    default:
      break;
  }

  return {
    type: "string",
    inputType: "text",
    name,
    label: "Position Title",
    section: "Promotion",
    visible: true,
    editable: true,
    //width: '200px',
    width: '130px',   // CHANGE THE WIDTH FOR DEFECT 1 
    defaultValue,
    maxlength
  };
};