//Release 2 - Edit budget pop -up (part of budget management)
import React, { useState, useRef, useEffect } from 'react';
import { apolloClient, apolloProxyClient } from '../../index';
import { useMutation } from '@apollo/react-hooks';
import { Ring } from 'react-awesome-spinners';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp, faChartPie } from '@fortawesome/free-solid-svg-icons'
import { FormButton, FormButtonsWrap, ErrorsBlock, LoaderWrap, IconWrap, DescriptionInput } from '../';
import AsyncSelect from 'react-select/async';
import { unselectAllRecord, pushMessage } from '../../actions';
import { PARENT_BUDGET, EDIT_BUDGET ,budgetsForAdmin} from '../../graphql';
import { parseType } from 'graphql';


const EditBudget = props => {
    const { account, hideModal, modal, unselectAllRecord, pushMessage } = props;    
    const proxyCheck = sessionStorage.getItem("proxy_access");
    const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
    const {  roles, accessibles } = account;
    const isGct = roles.includes('gct')

    const isAdmin = roles.includes('admin')
    const isBCB = roles.includes('bcb');
    const Budgetdata = modal.modalContent;
    console.log(Budgetdata);
    const [submitErrors, setSubmitErrors] = useState(false);
    const [year] = useState(Budgetdata.year);
    const [budget_available] = useState(Budgetdata.budget_available);
    const [budget_in_progress] = useState(Budgetdata.budget_in_progress);
    const [budget_spent] = useState(Budgetdata.budget_spent);
    const [capital_budget, setcaps] = useState(Budgetdata.capital_budget);
    const [budget] = useState(Budgetdata.budget);
    const [description] = useState(Budgetdata.description);
    const [organization_unit_name] = useState(Budgetdata.organization_unit.organization_unit_name);

    const [isParent] = useState(Budgetdata.organization_unit.organization_unit_1 == Budgetdata.budget_organization_unit ? true : false);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [capitalAvailable, setCapitalAvailable] = useState(0);
    const [childallocation, setChildAllocation] = useState(0);
    const [newCapital, setNewCapital] = useState(capital_budget);
    const [newBudget, setNewBudget] = useState(budget);
    const [requestDescription, setRequestDescription] = useState(Budgetdata.description);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const capitalInput = useRef(null);
    const budgetInput = useRef(null);
    useEffect(() => {
        if (newBudget > (capitalAvailable + budget)) {
            alert("Budget must be lesser than available capital");
            setNewBudget(budget);
            budgetInput.current.value = "€ " + budget;
        }
    }, [capitalAvailable]);
    useEffect(() => {
        setIsSubmitting(true);
        budgetInput.current.value = "€ " + budget.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        httpClient.query({
            query: PARENT_BUDGET,
            fetchPolicy: "network-only",
            variables: { organization_unit: Budgetdata.budget_organization_unit, organization_unit_1: Budgetdata.organization_unit.organization_unit_1, year: year.toString() },
        }).then((response) => {

            if (response.data && response.data.organizationBudgetForAdmin) {
                console.log(response.data);
                setcaps(response.data.organizationBudgetForAdmin.capital_budget);
                setCapitalAvailable(response.data.organizationBudgetForAdmin.capital_budget_available ? response.data.organizationBudgetForAdmin.capital_budget_available : 0);
                setChildAllocation(response.data.organizationBudgetForAdmin.childs_organization_bugdet);
                capitalInput.current.value = "€ " + response.data.organizationBudgetForAdmin.capital_budget ? response.data.organizationBudgetForAdmin.capital_budget.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
                
            }
            setIsSubmitting(false);
        }, error => {
            console.log('Graph QL error: ' + error.message);
            alert("Issue in Checking Parent Organisation detalis - "+ error.message);
            setSubmitErrors(true);
            setIsSubmitting(false);
        });
    }, []
    )

    const [editBudget] = useMutation(EDIT_BUDGET);

    const labelStyle = {
        "fontSize": "15px",
        "fontWeight": "400",
        "width": "50%",
        "float": "left",
        "marginTop": "2rem"
    }

    function formatNumber(n) {

        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


    function formatCurrency(input_val, event, blur) {
        if (input_val === "") { return; }
        var original_len = input_val.length;
        var caret_pos = event.target.selectionStart;


        if (input_val.indexOf(".") >= 0) {

            var decimal_pos = input_val.indexOf(".");
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = formatNumber(left_side);
            right_side = formatNumber(right_side);


            if (blur === "blur") {

                right_side += "00";
            }

            right_side = right_side.substring(0, 2);

            input_val = "€" + left_side + "." + right_side;
            if (blur === "blur" && event.target.id == "new_budget" && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) > (capitalAvailable + budget )) {
                alert("Budget must be lesser than available capital");
                input_val = budget;
            }
            if (blur === "blur" &&  event.target.id == "new_budget" && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) < (budget_in_progress + budget_spent)) {
                alert("Budget must not be lesser than Budget In Progress and Budget Spent");
                input_val = budget;
            }
            if (blur === "blur" && event.target.id == "new_capital" && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) < childallocation) {
                alert("Capital budget must not be lesser than the sum of child organisation allocations");
                input_val = capital_budget;
            }
            else if (blur === "blur" && event.target.id == "new_capital" && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) >= childallocation) {
                setCapitalAvailable(parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) - childallocation);
           
            }

           


        } else {

            input_val = formatNumber(input_val);
            input_val = "€" + input_val;

            if (blur === "blur") {

                input_val += ".00";
                //Budget must be within available capital
                if (event.target.id == "new_budget" && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) > (capitalAvailable + budget  )) {
                    alert("Budget must not cross the availble capital");
                    input_val = budget;

                }
                //Budget must not be lessaer than In Progress and Spent
                if (blur === "blur" && event.target.id == "new_budget" && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) < (budget_in_progress + budget_spent)) {
                    alert("Budget must not be lesser than Budget In Progress and Budget Spent");
                    input_val = budget;
                }
                if (blur === "blur" && event.target.id == "new_capital" && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) < childallocation) {
                    alert("Capital budget must not be lesser than the sum of child organisation allocations");
                    input_val = capital_budget;
                }
                else if (blur === "blur" && event.target.id == "new_capital" && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) >= childallocation) {
                    setCapitalAvailable(parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) - childallocation);
                   
                }
                

            }
        }
        event.target.value = input_val;
        if (blur === "blur") {
            if (event.target.id == "new_budget") { setNewBudget(parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) == NaN ? 0 : parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", ""))) }
            if (event.target.id == "new_capital") { setNewCapital(parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) == NaN ? 0 : parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", ""))) }
        }

        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        event.target.setSelectionRange(caret_pos, caret_pos);
    }

    const handleDescriptionChange = event => setRequestDescription(event.target.value);

    const handleQuit = event => {
        hideModal();

    };

    const handleEdit = async event => {
        try {
            setIsSubmitting(true);
            const results = await editBudget({
                
                variables: {
                    "budget_id" : parseInt( Budgetdata.budget_id),
                    data: {

                        description: requestDescription,
                        budget: parseFloat(newBudget),
                        capital_budget: isParent ? parseFloat(newCapital) : parseFloat(0),
                        budget_available: parseFloat(newBudget) - parseFloat(budget_in_progress) - parseFloat(budget_spent),
                        budget_in_progress: parseFloat(budget_in_progress),
                        budget_spent: parseFloat(budget_spent),
                        year: year.toString(),
                        organization_unit: Budgetdata.budget_organization_unit.toString()
                    }
                },
              
                    awaitRefetchQueries: true
               ,
                refetchQueries :  [
                    { query: budgetsForAdmin, variables: {
                        where: { year: { eq: year.toString() } },
                        
                        offset: 0,
                        limit: 20,
                        
                        orderBy : null
                    }}
                  ]
                
            });
            //console.log(results);
            if (results) {
                setIsSubmitting(false);
                hideModal();

                pushMessage({
                    type: 'success',
                    message: 'Budget Details Edited successfully!'
                })
            }
        }
        catch (e) {
            console.log('Graph QL error: ');
            alert("Issue in Editing the Budget Details");
            setSubmitErrors(true);
            setIsSubmitting(false);
        }

    };

    const handleCurrencyKeyUp = (e) => {
        formatCurrency(e.target.value, e);
    }
    const handleCurrencyBlur = (e) => {
        formatCurrency(e.target.value, e, "blur");
    }
    return (
        <div style={{
            "width": "50%",
            "padding": "2px",
            "margin": "0 auto"
        }}>
            <input id="searchResult" style={{ "display": "none" }} />
            <div style={{
                "fontSize": "20px",
                "fontWeight": "400",
                "textAlign": "center"
            }}>Edit Budget</div>

            <div style={{ "width": "100%", "float": "left" }}>
                {/*  ADD FORM FIELD FIELDS TO FIX DEFECT 11 STARTS */}
                <div>
                    <div style={labelStyle}>Year</div>

                    <div style={labelStyle}>
                        <label>{year}</label>
                    </div>
                </div>
                <div>
                    <div style={labelStyle}>Organisation Unit</div>
                    <div style={labelStyle}><label>{organization_unit_name}</label>
                        <div>
                            {isParent && <div style={{
                                "paddingTop": "0.6rem",
                                "float": "left",
                                "color": "forestgreen"
                            }}> <FontAwesomeIcon icon={faArrowCircleUp} />  Parent Organisation </div>}
                        </div>
                    </div>
                </div>
                <div>
                    <div style={labelStyle}>Capital Budget {isParent && <span style={{"color" : "red"}}>*</span>}</div>

                    <div style={labelStyle}><input id="new_capital" ref={capitalInput} type="text" style={{ "height": "2rem", "width" : "100%"  }}  disabled={isParent && (isGct || isAdmin) ? "" : "disabled"} pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" placeholder="&#8364;" onKeyUp={handleCurrencyKeyUp} onBlur={handleCurrencyBlur} /></div>
                    <div>
                        {<div style={{
                            "paddingTop": "0.6rem",
                            "fontSize": "15px",
                            "color": "forestgreen"
                        }}> <FontAwesomeIcon icon={faChartPie} />  Capital Available : €  {capitalAvailable.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </div>}
                    </div>
                </div>
                <div>
                    <div style={labelStyle}>Budget <span style={{"color" : "red"}}>*</span></div>
                    <div style={labelStyle}><input id="new_budget" ref={budgetInput} type="text" style={{ "height": "2rem", "width" : "100%"  }}  pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" placeholder="&#8364;" onKeyUp={handleCurrencyKeyUp} onBlur={handleCurrencyBlur} /></div>
                </div>
                
                <div>
                    <div style={labelStyle}>Budget Spent</div>
                    <div style={labelStyle}><label>&#8364; {budget_spent.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</label></div>
                </div>
                <div>
                    <div style={labelStyle}>Budget In Progress</div>
                    <div style={labelStyle}><label>&#8364; {budget_in_progress.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</label></div>
                </div>
                <div>
                    <div style={labelStyle}>Budget Forecast</div>
                    <div style={labelStyle}><label>&#8364; {budget_available.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</label></div>
                </div>
                <div>
                    <div style={labelStyle}>Budget Description</div>
                    <div style={labelStyle}><DescriptionInput
                         style={{"width" : "100%"}}
                        name='request_description'
                        onChange={handleDescriptionChange}
                        defaultValue={description}
                    /></div>
                </div>

            </div>
            {/*  ADD FORM FIELD FIELDS TO FIX DEFECT 11 ENDS */}

            {submitErrors.length > 0 && submitErrors.map(err => {
                return (
                    <ErrorsBlock key={Math.random()}>
                        <IconWrap>!</IconWrap>
                        {err}
                    </ErrorsBlock>
                );
            })}
            <FormButtonsWrap style={{ "width": "100%" }}>
                <FormButton onClick={handleQuit}>Quit</FormButton>
                <FormButton primary disabled={isSubmitting || submitErrors || newBudget < 0 || newCapital < 0 ? true : false} onClick={handleEdit}>Submit</FormButton>
                {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
            </FormButtonsWrap>

        </div>
    );
};

const mapStateToProps = state => {
    return {
   
        account: state.account
    };
};

export default connect(
    mapStateToProps,
    {  pushMessage }
)(EditBudget);
