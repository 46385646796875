import React, { useEffect } from 'react';
import { popMessage, removeMessage } from '../../actions';
import { connect } from 'react-redux';
import { GlobalMessagesWrap, GlobalMessage, GlobalMessageClose } from '../';

const GlobalMessages = props => {
  const { messages, popMessage, removeMessage } = props;
  // auto remove last added message every 10000 ms
  useEffect(() => {
    const interval = setInterval(() => popMessage(), 10000);
    return () => clearInterval(interval);
  });
  // manually remove message
  const handleCloseMessage = index => event => removeMessage(index);

  return (
    <GlobalMessagesWrap>
      {messages.map((m, i) => {
        return (
          <GlobalMessage key={Math.random()} {...m}>
            {m.message}
            <GlobalMessageClose onClick={handleCloseMessage(i)} />
          </GlobalMessage>
        );
      })}
    </GlobalMessagesWrap>
  );
};

const mapStateToProps = state => {
  return { messages: state.messages };
};

export default connect(mapStateToProps, { popMessage, removeMessage })(GlobalMessages);
