import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { apolloClient, apolloProxyClient } from '../../index';
import { Grid } from 'react-awesome-spinners';
import { connect } from 'react-redux';
import { Ring } from 'react-awesome-spinners';
import { unselectAllRecord, budgetSetUpdated, pushMessage, selectRecord, updateRecord } from '../../actions';
import { DatePickerField, SelectedTable, CalculatorNew, FormButton, FormButtonsWrap, ErrorsBlock, LoaderWrap, FormLabel, FormLabelInline, FormHeading, DescriptionInput, IconWrap } from '../';
import { columns } from '../../util/config';
import { CREATE_REQUEST_WITH_BUDGET, BUDGET, requests, moc_requests,moc_edit } from '../../graphql';
import { convertToUTC } from '../../util/helper';
import { Pagination, GridLoader } from '../';
import exportFromJSON from 'export-from-json';
import {
  currencyDisplayRenderer,
  percentRenderer,
  numberRenderer,
} from "../../util";

import "react-datepicker/dist/react-datepicker.css";

const Mocotsubmission = props => {
  const { modal, account, hideModal, selectedEmployees, unselectAllRecord, budgetSetUpdated, pushMessage, selectRecord, config, updateRecord } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const indexColumn = columns.requestOnetime.find(c => c.index);

  const DEFAULT_PAGE_SIZE = 20;
  const [triggerCalc, setCalcTrigger] = useState(false);
  const [updatedItem, setUpdatedItem] = useState('');
  const [updateData, setUpdateData] = useState({});
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const pageOffset = (currentPage - 1) * pageSize;
  const [requestDescription, setRequestDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const now = new Date();
  const nextMonth = now.getMonth() === 11 ? new Date(now.getFullYear()+1, 0 , 1) : new Date(now.getFullYear(), now.getMonth()+1, 1);
  
  const [createRequest] = useMutation(CREATE_REQUEST_WITH_BUDGET);
  const [data, setresdata] = useState(undefined);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(false);
  const [calciarray, setcalci] = useState([]);
  const [pagetrigger , setpagetrigger] = useState(false);

  const [editMOC] = useMutation(moc_edit);
  useEffect(() => {
      handleTrigger();
  }, [data])
  useEffect(() => {
    setloading(true);
      httpClient.query({
          query: moc_requests,
          variables: { where: modal.modalContent.where, limit: parseInt(pageSize), offset: pageOffset, orderBy: null },
          fetchPolicy: "network-only"
      })
          .then((response) => {


              if (response.data && response.data.moc_requests) {



               
                  response.data.moc_requests.items.forEach((element, index, array) => {
                      const ele = { ...element, ...element.for };
                      delete ele.for;
                      selectRecord(ele, indexColumn, "employees");

                  })
                  setresdata(response.data);
                  setloading(false);
                  seterror(false);


              }

          })
          .catch((err) => {
              console.error(err);
              setresdata(undefined);
              setloading(false);
              seterror(true);

          }
          )



  }, [pagetrigger])


  const onPageSizeChange = event => {
    setloading(true);
    unselectAllRecord('employees');
    setPageSize(event.target.value);
    setCurrentPage(1);
    setpagetrigger(pagetrigger ? false : true);
};

const onPageChange = currentPage => {
    setloading(true);
    unselectAllRecord('employees');
    setCurrentPage(currentPage);
    setpagetrigger(pagetrigger ? false : true);
};

  const update = (item, data) => {
    if (updatedItem !== item) {
      setUpdatedItem(item);
    }
    setUpdateData(data);
  };
  

  const handleChange = (args) => (event) => {
    console.log("On change called" );
    const { column, columns, item } = args;
    const indexColumn = columns.find((c) => c.index);
    let value =
        column.inputType === "checkbox"
            ? event.target.checked
            : event.target.value;

    value =
        (column.inputType !== "checkbox" && parseInt(value)) < 0 ? 0 : value;

        const calarray = [];
      
            calarray.push(<CalculatorNew
                key = {1}
                columns={columns}
                recordType="employees"
                item={ item[indexColumn.name]}
                data={  {
                    request_type: "onetime",
                    [column.name]: column.type === "float" ? parseFloat(value) : value,
                }}


            />)
            setcalci(calarray);


};

  const handleDescriptionChange = event => setRequestDescription(event.target.value);

  const handlePaymentDateChange = date => setPaymentDate(date);

  const handleQuit = event => { hideModal(); unselectAllRecord('employees'); };

  const hasBudgetVisibility = account.accessibles && account.accessibles.budgetOrgs.length > 0;


  const handleRequestOnetimeSend = async event => {
    setIsSubmitting(true);
    setSubmitErrors([]);

    if (!paymentDate) {
      setSubmitErrors(['Payment Date is required!']);
      setIsSubmitting(false);
      return null;
  }

    if (!requestDescription) {
      setSubmitErrors(['Reason is required!']);
      setIsSubmitting(false);
      return null;
    }

    let refetchAfterCreateRequest = [{
      query: requests,
      variables: {
        where: { requester_pers_no: { eq: account.pers_no } },
        limit: 20,
        offset: 0,
        orderBy: [{column: "created_ts", order: "DESC"}]
      }
    }];
    if (hasBudgetVisibility) {
      account.accessibles.budgetOrgs.forEach(b =>
        refetchAfterCreateRequest.push({ 
          query: BUDGET, variables: { organization_unit: b } 
        })
      );
    }

    const results = await Promise.all(selectedEmployees.map(async ({
        pers_no,
        nokia_id,
        country_name,
        currency,
        ttc_increase_amount,
        ttc_increase_percentage,
        request_type,
        offcycle_budget_update,
        payment_type,
        payment_reason,
        target_budget_org,
        organization_unit_2_name,
        organization_unit_1_name
      }) => {
      try {
        await createRequest({
          variables: {
            data: {
              payment_date: convertToUTC(paymentDate),
              request_description: requestDescription,
              requester_pers_no: account.pers_no,
              request_country: country_name,
              request_type,
              pers_no,
              ttc_increase_amount,
              ttc_increase_percentage,
              currency,
              offcycle_budget_update,
              payment_type,
              payment_reason,
              target_budget_org,
              organization_unit_2_name,
              organization_unit_1_name
            }
          },
          awaitRefetchQueries: true,
          refetchQueries: refetchAfterCreateRequest
        });
        
        return { 'pers_no': false,'Person No.' : pers_no, 'Nokia ID' : nokia_id, 'Reason' : 'Successfully saved the data' };
      } catch(e) {
        const err_request_exist = e.graphQLErrors.some(er => er.extensions.code === 'EXISTING_REQUEST');
        return { 'pers_no': err_request_exist ? `There is already a pending request for employee ${nokia_id}` : e.message,'Person No.' : pers_no,'Nokia ID' : nokia_id, 'Reason' : e.message };
            
      } 
    }));

    const errors = results.filter(r => r['pers_no'] );
    const success = results.filter(r => !r['pers_no'] );
    await editMOC({
      variables: { where : {request_type:{eq:'onetime'}, import_status: { eq: "pending" },requester_pers_no: { eq: account.pers_no.toString() },  pers_no : {in :  results.filter(r => !r['pers_no'] ).map(e => e['Person No.'])} } , data: {import_status : 'completed', Submission_Status : 'Success'}} ,
       awaitRefetchQueries: false,
       refetchQueries: [
         { query: moc_requests, variables: { where: modal.modalContent.where, limit: parseInt(pageSize), offset: pageOffset, orderBy: null },fetchPolicy: "network-only"}
       ]
    });
    
    if(errors.length > 0) {
      
      const fileName = "MassOffCycle_OneTime" + "_" + new Date().toISOString();
      const exportType = 'csv';
      errors.forEach(function(v){ delete v['pers_no'] });

      const data = errors;
      exportFromJSON({data , fileName, exportType });
      alert("Some or all of the requests submitted are failed. Please review the requests on the CSV that would be downloaded")
      setIsSubmitting(false);
  } else {
    hideModal();
    pushMessage({
      type: "success",
      message: "Request(s) successfully reviewed.",
    });
  }
 // hideModal();
  unselectAllRecord('employees');
  setIsSubmitting(false);
  setCurrentPage(1);
  setpagetrigger(pagetrigger ? false : true);
  budgetSetUpdated(true);
  };
  const handleTrigger = () => {
    const calarray = [];
    selectedEmployees.forEach((element, i) => {
        calarray.push(      <CalculatorNew
          key={i}
          columns={columns.requestOnetime}
          recordType="employees"
          item={element[indexColumn.name]}
          data={{
                request_type: 'onetime',
                ttc_increase_amount: parseFloat( element.ttc_increase_amount) < 0 ? 0:parseFloat(element.ttc_increase_amount)
            }}


        />)
    })
    setcalci(calarray);
  }

  const handleExportData = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (selectedEmployees && selectedEmployees.length > 0) {
      var data = selectedEmployees.map(function(item){
        let current_itp_situation = item && item.current_itp_situation ? item.current_itp_situation : 'Norm'
        let new_itp_situation = item.new_itp_situation ? item.new_itp_situation : 'Norm';
        
        let total_increaseed_percent = 0;
        total_increaseed_percent = item.ttc_increase_percentage;
    
        return {
          "ID": item.employee_request_id,
          "Pers No.": item.pers_no,
          "Nokia ID": item.nokia_id,
          "Full Name": item.full_name,
          "Current Total Target Cash": item && item.fte_annual_salary ? currencyDisplayRenderer(item.fte_annual_salary, item) : '',
          "One-Time Amount": currencyDisplayRenderer(item.ttc_increase_amount, item),
          "% In Relation to Annual Base Salary": percentRenderer((item.ttc_increase_amount / item.fte_annual_salary) * 100),
          "% In Relation To Total Target Cash": percentRenderer(total_increaseed_percent),
          "Payment Type": item.payment_type ? item.payment_type : '',
          "Payment Reason": item.payment_reason ? item.payment_reason : '',
          "Target Budget": item.target_budget_org ? item.target_budget_org : '',
          "Current-Country": item && item.country_name ? item.country_name : '',
          "Current-Job Family": item && item.job_family_name ? item.job_family_name : '',
          "Current-Job Sub Family": item && item.job_subfamily_name ? item.job_subfamily_name : '', 
          "Current-Job": item && item.job_name ? item.job_name : '',
          "Current-Job Grade": item && item.job_grade ? item.job_grade : '', 
          "Current-Position Title": item && item.position_title ? item.position_title : '', 
          "Current-Annual Base Salary": item && item.fte_annual_salary ? currencyDisplayRenderer(item.fte_annual_salary, item) : '',
          "Current-Incentive Target Percentage": item && item.fte_incentive_target_percentage ? percentRenderer(item.fte_incentive_target_percentage) + ' ' + current_itp_situation : '', 
          "Current-Incentive Target Amount": item && item.fte_incentive_target_amount ? currencyDisplayRenderer(item.fte_incentive_target_amount, item) : '',
          "Current-Total Target Cash": item && item.fte_ttc_annual_salary ? currencyDisplayRenderer(item.fte_ttc_annual_salary, item) : '', 
          "Current-TTC Compa-Ratio": item && item.ttc_compa_ratio ? numberRenderer(item.ttc_compa_ratio) : '', 
          "Current-Incentive Plan": item && item.incentive_plan_type ? item.incentive_plan_type : '', // verify this 
          "Current-Quartile": item && item.ttc_employee_quartile ? item.ttc_employee_quartile : '', 
          "Off-Cycle Budget Spending": item.offcycle_budget_update ? currencyDisplayRenderer(item.offcycle_budget_update, item) : '',                                             
        }
      });
      data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':""'));
      const fileName = "massoffcycle-onetime" + new Date().toISOString();
      const exportType = "csv";
      exportFromJSON({ data, fileName, exportType });
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      alert("No Record Found to Export the Excel");
    }
  };
  let includeDatesFinal = [];
  if(!loading && data)
  {
    /*  DEM0065665 - Effective date Starts */
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var currentDisplayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    var processYear = date.getFullYear();

    //let includeDatesFinal = [];
    
    const { allEffectiveDateConfigs } = config;
    //console.log("selectedEmployees====>", selectedEmployees)
    //console.log("allEffectiveDateConfigs====>", allEffectiveDateConfigs)
    var calenderDateRange = new Array();
    var dateRangeIncr = 0;
    if(selectedEmployees.length == 1)
    {
      //for(var incr_1 = 0; incr_1 < selectedEmployees.length; incr_1++)
      //{
        var ISO_Code      = selectedEmployees[0].country;
        var Company_Code  = selectedEmployees[0].company_code;
        var Region        = selectedEmployees[0].geo_region;
        if(Region == 'EUR')
          Region = 'Europe';
        var Country       = selectedEmployees[0].country_name;
        var is_employee_matched = 'No';
        if(allEffectiveDateConfigs.length > 0)
        {
          for(var incr_2 = 0; incr_2 < allEffectiveDateConfigs.length; incr_2++)
          {
            var ID_Config                 = allEffectiveDateConfigs[incr_2].id;
            var ISO_Code_Config            = allEffectiveDateConfigs[incr_2].iso_code;
            var Company_Code_Config        = allEffectiveDateConfigs[incr_2].company_code;
            var Region_Config              = allEffectiveDateConfigs[incr_2].region;
            var Country_Config             = allEffectiveDateConfigs[incr_2].country;
            var EffDate_Compensation_Config = allEffectiveDateConfigs[incr_2].salary_increase_effective_date;
            var EffDate_Onetime_Config     = allEffectiveDateConfigs[incr_2].one_time_payment_effective_date;
            
            //if(ISO_Code == ISO_Code_Config && Region == Region_Config && Country == Country_Config)
            if(Country == Country_Config)
            {
              is_employee_matched = 'Yes';
              calenderDateRange[dateRangeIncr] = EffDate_Onetime_Config;
              break;

              /*var exp_1 = new Array();
              exp_1 = Company_Code_Config.split(",");
              if(exp_1.length > 0)
              {
                for(var incr_3 = 0; incr_3 < exp_1.length; incr_3++)
                {
                  if(Company_Code == exp_1[incr_3])
                  {
                    is_employee_matched = 'Yes';
                    break;
                  }
                }
              }
              if(is_employee_matched == 'Yes')
              {
                calenderDateRange[dateRangeIncr] = EffDate_Onetime_Config;
              }
              break;*/
            }
          }
        }
      //}
      //console.log("calenderDateRange=====>", calenderDateRange)
      //console.log("is_employee_matched=====>", is_employee_matched)
      if(is_employee_matched == 'Yes')
      {
        for (let i=0; i<12; i++) 
        {
          var processMonth = (currentDisplayMonth.getMonth()+1);
          //console.log("processMonth.length===>"+processMonth.length);
          if(processMonth != 12 && processMonth != 11 && processMonth != 10)
          {
            processMonth = '0'+processMonth;
          }

          if(calenderDateRange[0] == 'Only the 1st of the month')
          {
            includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
          }
          else if(calenderDateRange[0] == 'Only 1st and 16th of the month')
          {
            includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
            includeDatesFinal.push(new Date(processMonth+"/16/"+processYear));
          }
          else if(calenderDateRange[0] == 'Only 1st or 15th of the month')
          {
            includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
            includeDatesFinal.push(new Date(processMonth+"/15/"+processYear));
          }
          else if(calenderDateRange[0] == 'Any date during the month')
          {
            var totalDaysInMonth = new Date(processYear, processMonth, 0).getDate();
            //console.log("totalDaysInMonth====>", totalDaysInMonth);
            if(totalDaysInMonth > 0)
            {
              for(var days_incr = 1; days_incr <= totalDaysInMonth; days_incr++)
              {
                if(days_incr < 10)
                {
                  days_incr = '0'+days_incr;
                }
                includeDatesFinal.push(new Date(processMonth+"/"+days_incr+"/"+processYear));
              }
            }
          }
          //console.log("====>", processMonth);
          if(processMonth == 12)
          {
            var date_1 = new Date();
            processYear = date.getFullYear()+1;
          }
          currentDisplayMonth.setMonth(currentDisplayMonth.getMonth() + 1);
        }
      }
      else
      {
        for (let i=0; i<12; i++) 
        {
          var processMonth = (currentDisplayMonth.getMonth()+1);
          //console.log("processMonth.length===>"+processMonth.length);
          if(processMonth != 12 && processMonth != 11 && processMonth != 10)
          {
            processMonth = '0'+processMonth;
          }

          var totalDaysInMonth = new Date(processYear, processMonth, 0).getDate();
          //console.log("totalDaysInMonth====>", totalDaysInMonth);
          if(totalDaysInMonth > 0)
          {
            for(var days_incr = 1; days_incr <= totalDaysInMonth; days_incr++)
            {
              if(days_incr < 10)
              {
                days_incr = '0'+days_incr;
              }
              includeDatesFinal.push(new Date(processMonth+"/"+days_incr+"/"+processYear));
            }
          }
          //console.log("====>", processMonth);
          if(processMonth == 12)
          {
            var date_1 = new Date();
            processYear = date.getFullYear()+1;
          }
          currentDisplayMonth.setMonth(currentDisplayMonth.getMonth() + 1);
        }
      }
    }
    else if(selectedEmployees.length > 1)
    {
      calenderDateRange[dateRangeIncr] = 'Only the 1st of the month';
      var countryMatchedWithConfig = 'N';
      var allCountries = new Array();
      for(var incr_1 = 0; incr_1 < selectedEmployees.length; incr_1++)
      {
        var Country       = selectedEmployees[incr_1].country_name;
        allCountries[incr_1] = Country;
      }

      var allCountrySame = allCountries.every( (val, i, arr) => val === arr[0] );
      console.log("allCountries======>", allCountries);
      console.log("allCountrySame======>", allCountrySame);
      if(allCountrySame == true)
      {
        for(var incr_2 = 0; incr_2 < allEffectiveDateConfigs.length; incr_2++)
        {
          var Country_Config             = allEffectiveDateConfigs[incr_2].country;
          var EffDate_Compensation_Config = allEffectiveDateConfigs[incr_2].salary_increase_effective_date;
          var EffDate_Onetime_Config     = allEffectiveDateConfigs[incr_2].one_time_payment_effective_date;
          if(allCountries[0] == Country_Config)
          {
            countryMatchedWithConfig = 'Y';
            is_employee_matched = 'Yes';
            calenderDateRange[0] = EffDate_Onetime_Config;
            break;
          }
        }
        if(countryMatchedWithConfig == 'N')
        {
          calenderDateRange[0] = 'Any date during the month';
        }
      }
      
      
      for (let i=0; i<12; i++) 
      {
        var processMonth = (currentDisplayMonth.getMonth()+1);
        //console.log("processMonth.length===>"+processMonth.length);
        if(processMonth != 12 && processMonth != 11 && processMonth != 10)
        {
          processMonth = '0'+processMonth;
        }

        if(calenderDateRange[0] == 'Only the 1st of the month')
        {
          includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
        }
        else if(calenderDateRange[0] == 'Only 1st and 16th of the month')
        {
          includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
          includeDatesFinal.push(new Date(processMonth+"/16/"+processYear));
        }
        else if(calenderDateRange[0] == 'Only 1st or 15th of the month')
        {
          includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
          includeDatesFinal.push(new Date(processMonth+"/15/"+processYear));
        }
        else if(calenderDateRange[0] == 'Any date during the month')
        {
          var totalDaysInMonth = new Date(processYear, processMonth, 0).getDate();
          //console.log("totalDaysInMonth====>", totalDaysInMonth);
          if(totalDaysInMonth > 0)
          {
            for(var days_incr = 1; days_incr <= totalDaysInMonth; days_incr++)
            {
              if(days_incr < 10)
              {
                days_incr = '0'+days_incr;
              }
              includeDatesFinal.push(new Date(processMonth+"/"+days_incr+"/"+processYear));
            }
          }
        }
        //console.log("====>", processMonth);
        if(processMonth == 12)
        {
          var date_1 = new Date();
          processYear = date.getFullYear()+1;
        }
        currentDisplayMonth.setMonth(currentDisplayMonth.getMonth() + 1);
      }
    }
    //console.log("CURRENT Year===>"+processYear);
    var minimumDate = '';

    var minimumDate_Highlighted = '';
    var minimumDate_Selectable = '';
    console.log("calenderDateRange===>", calenderDateRange);
    /*  DEM0065665 - Effective date Starts */
    //var currentDisplayMonthDefault = new Date(now.getFullYear(), now.getMonth(), 1);
    if(calenderDateRange[0] == 'Only the 1st of the month')
    {
      if(date.getDate() > '1')
      {
        minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

        minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
      }
      else
      {
        minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

        minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
      }
    }
    else if(calenderDateRange[0] == 'Only 1st or 15th of the month')
    {
      if(date.getDate() > '1')
      {
        minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

        minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
      }
      else
      {
        minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

        minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
      }
    }
    else if(calenderDateRange[0] == 'Only 1st and 16th of the month')
    {
      if(date.getDate() > '1')
      {
        minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

        minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
      }
      else
      {
        minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

        minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
      }
    }
    else
    {
      if(date.getDate() > '1')
      {
        minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

        minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
      }
      else
      {
        minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

        minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
      }
    }
  }
  if(minimumDate == undefined)
  {
    var date_last = new Date();
    if(date_last.getDate() > '1')
    {
      minimumDate = new Date(date_last.getFullYear(), date_last.getMonth()+1, 1);

      minimumDate_Highlighted = new Date(date_last.getFullYear(), date_last.getMonth()+1, 1);
          minimumDate_Selectable = new Date(date_last.getFullYear(), date_last.getMonth(), 1);
    }
    else
    {
      minimumDate = new Date(date_last.getFullYear(), date_last.getMonth(), 1);

      minimumDate_Highlighted = new Date(date_last.getFullYear(), date_last.getMonth(), 1);
          minimumDate_Selectable = new Date(date_last.getFullYear(), date_last.getMonth(), 1);
    }
  }
  const [paymentDate, setPaymentDate] = useState(minimumDate_Highlighted);
  //console.log("includeDatesFinal===>", includeDatesFinal);
  //console.log("includeDatesFinal Length===>", includeDatesFinal.length);
  //console.log("minimumDate===>", minimumDate);
  /*  DEM0065665 - Effective date Ends */

  return (
    <div>
      <FormHeading>Request One-Time Payment</FormHeading> 
      <FormLabel>Selected employees: {selectedEmployees.length}</FormLabel>
      <FormHeading style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
      <div>
        Total Off-Cycle Budget Spend : &euro; {selectedEmployees.reduce((accumulator, current) => accumulator + (current.offcycle_budget_update*current.exchange_rate), 0).toFixed(2)}
      </div>
      {!loading && data && (  
      <FormButton
            disabled={isSubmitting}
            onClick={handleExportData}
          >
        Export To Excel
      </FormButton>
      )}
      </FormHeading>     
      {loading && <GridLoader><Grid color={'#001235'} /></GridLoader>}
      {error && (<div><h2>Unable to fetch the pending requests</h2></div>)}
       {!loading && data && (
                <SelectedTable
                    recordType="employees"
                    items={selectedEmployees}
                    columns={columns.requestOnetime}
                    onChange={handleChange}
                />)}
                  {!loading && data && (
                <Pagination
                    count={data.moc_requests.totalCount}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    reqFrom = "moc"
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}
                />
            )}
             {!loading && data && (
      <FormLabelInline>Payment date:</FormLabelInline>)}

      {/*  DEM0065665 - Effective date Starts */}
      {/*!loading && data && (
        <DatePickerField selected={paymentDate} onChange={handlePaymentDateChange} />)*/}

      {!loading && data && (
                <DatePickerField 
                selected={paymentDate} 
                onChange={handlePaymentDateChange} 
                minDate={minimumDate_Selectable} 
                includeDates={includeDatesFinal}
                /*includeDates={[
                  new Date('12/01/2022'),
                  new Date('12/15/2022'), 
                  new Date('01/01/2023'),
                  new Date('01/15/2023')]}*/
                />)
            }
        {!loading && data && (
                <i style={{'width':'100%','float':'left'}}><b>Please note that in some countries only possible effective date is 1st of the month due to Payroll requirements. If you need further support, please open an HR ticket to People Experience Care team.</b></i>)
            }    
        {/*  DEM0065665 - Effective date Ends */}
      {!loading && data && (
      <FormLabel>Reason for request:
        <i style={{'width':'100%','float':'left'}}>[Please do not use the Reason field for Backdated changes dates as this field is visible only for approvers and not updating CoreHR System!]</i>
      </FormLabel>)}
      {!loading && data && (
      <DescriptionInput
        name='request_description'
        onChange={handleDescriptionChange}
      />)}
      {submitErrors.length > 0 && submitErrors.map(err => {
        return (
          <ErrorsBlock key={Math.random()}>
            <IconWrap>!</IconWrap>
            {err}
          </ErrorsBlock>
        );
      })}
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
        <FormButton primary disabled={isSubmitting} onClick={handleRequestOnetimeSend}>Send request(s)</FormButton>
        {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
      </FormButtonsWrap>
      <>
                {calciarray}
            </>

    </div>
  );
};

const mapStateToProps = state => {
  return {
      selectedEmployees: state.selectedEmployees,
      account: state.account,
      /*  DEM0065665 - Effective date Starts */
      config: state.config,
      /*  DEM0065665 - Effective date Ends */
  };
};

export default connect(
  mapStateToProps,
  { unselectAllRecord, budgetSetUpdated, pushMessage, selectRecord, updateRecord }
)(Mocotsubmission);
