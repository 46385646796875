/* Release 3.2 - 9.1Req - Starts */
import React from 'react';
import { TableHeader, Row, TableHeadingWrap, TableActionsWrap, TableAction, FormHeading } from '..';
import exportFromJSON from "export-from-json";
import { currencyDisplayRenderer } from "../../util";

const SimulationTable = props => {
  /**release  2 Our talent scenario change - changeData prop added */
  const { columns, recordType, items , changeData, query, handleSimulationSelect, selectedEmployeeDetail} = props;
  const indexColumn = columns.find(c => c.index);
  
  const handleExportData = (event) => {
    let datalist = JSON.parse(JSON.stringify(items).replace(/\:null/gi, ':""'));
    datalist = datalist.map(function(dl){
      return {
        "RR Number": dl.rr_number,
        "Effective Date": dl.effective_date,
        "Nokia Id": dl.nokia_id,
        "Name": dl.full_name,
        "New Job Family": dl.promotion_job_family_name,
        "New Job Sub Family": dl.promotion_job_subfamily_name,
        "New Job": dl.promotion_job_name,
        "New Job Grade": dl.promotion_job_grade,
        "Position Title": dl.new_position_title,
        "Current Total Target Cash": dl.fte_ttc_annual_salary,
        "Promotional increase % guideline": dl.promotional_increase_percentage_guideline,
        "Total Target Cash Increase": dl.ttc_increase_amount,
        "Total Target Cash increase %": dl.ttc_increase_percentage,
        /* Release 3.2 - 13.1 - 149299 - Starts */
        "Case 3-12 months Base Salary paid (FTE)": dl.case_3_12_month_actual_incentive_amount_fte,
         /* Release 3.2 - 13.1 - Starts */
         "Case 3-12 months ACTUAL incentive amount paid  (FTE)": dl.lastYearWageHistory && dl.lastYearWageHistory.addl_pay_amount ? dl.lastYearWageHistory.addl_pay_amount : 0,
         "Case 3-12 months Total Target Cash": dl.sum_of_pay_amount ? dl.sum_of_pay_amount : 0,
         /* Release 3.2 - 13.1 - Ends */
         /* Release 3.2 - 13.1 - 149299 - Ends */
        "New Annual Base Salary": dl.new_fte_annual_salary,
        "New Incentive Target Amount": dl.new_fte_incentive_target_amount,
        "New Incentive Target %": dl.new_incentive_target_percentage,
        "Override Transition allowance (Local Currency)": dl.override_transition_allowance_local_currency,
        "New Total Target Cash": dl.new_fte_ttc_annual_salary,
        "Total Increase Amount": dl.ttc_increase,
        "Total Increase %": dl.ttc_increase_perc,
        "Hiring Manager": dl.hiring_manager_detail,                    
        "Off-Cycle Budget Spending": currencyDisplayRenderer(dl.Final_Off_Cycle_Budget, dl),                    
        "Off-Cycle Budget Spending in euro": dl.Final_Off_Cycle_Budget_euro, 
        /*Release DEM0069362:Add additional data for TAP users - Part 1 Start*/
        "TTC Compa-ratio": dl.new_ttc_compa_ratio, 

      }
    });
    
    const fileName = "simulationdata_" + new Date().toISOString();
    const exportType = "csv";

    exportFromJSON({ data: datalist, fileName, exportType });     
  };
  
  return (
    <React.Fragment>
    <TableHeadingWrap>
      <FormHeading>Simuations Data</FormHeading>
      <TableActionsWrap>
        <TableAction onClick={handleExportData}>
          Export To Excel
        </TableAction>
      </TableActionsWrap>
    </TableHeadingWrap>
    <div style={{ overflowX: "auto", position: "sticky" }}>
      <TableHeader recordType={recordType} checkboxIsDisplayed={true} selectable={true} columns={columns}/>
      {items.map((item, index) => {
        const background = index % 2 ? '#f5f5f5' : '#ffffff';
        let checked_status = selectedEmployeeDetail[0] && 
                          selectedEmployeeDetail[0].employee_request_simulation_id == item.employee_request_simulation_id ? true : false;
        return (
          <Row 
            key={`row-${item[indexColumn.name]}`}
            columns={columns}
            item={item}
            background={background}
            expandable
            keepLocalCurrency
            changeData
            checkboxIsDisplayed={true}
            selectable= {true}
            checked_status= {checked_status}
            handleSimulationSelect= {handleSimulationSelect}
            {...props}
          />
        );
      })} 
    </div>
    </React.Fragment>
  );
};

export { SimulationTable };
/* Release 3.2 - 9.1Req - Ends */
