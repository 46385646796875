import { SET_CONFIG } from '../actions/index';

const intialState = {
  exchangeRates: [],
  jobsCatalog: [],
  gppLookupTable: [],
  itpLookupTable: [],
  jobGrades: ['TA','BTA','BT5','BT4','BT3','BT2','BT1','01','02','03','04','05','06','07','08','09','10','11','12','13','21','22','23'],
  incentivePlans: [],
  noDecimalCurrencies: ['BDT', 'BIF', 'CLP', 'CVE', 'DJF', 'EGP', 'GNF', 'HUF', 'IDR', 'ISK', 'JPY', 'KMF', 'KRW', 'NGN', 'PYG', 'RWF', 'UGX', 'UYI', 'VND', 'VUV', 'XAF', 'XOF', 'XPF', 'RON'],
  allSalaryRanges: [],
  /*  DEM0065665 - Effective date Starts */
  allEffectiveDateConfigs: []
  /*  DEM0065665 - Effective date Ends */
};

export default (state = intialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return Object.assign({}, state, {
        exchangeRates: action.payload.exchange,
        jobsCatalog: action.payload.allJobs,
        gppLookupTable: action.payload.gpps,
        itpLookupTable: action.payload.itps,
        incentivePlans: action.payload.incentivePlanTypes,
        allSalaryRanges: action.payload.allSalaryRanges,
        countryLookupTable : action.payload.countries,
        increaseLookupTable : action.payload.increases,
        /*  DEM0065665 - Effective date Starts */
        allEffectiveDateConfigs : action.payload.effectiveDateConfigs
        /*  DEM0065665 - Effective date Ends */
      });
    default:
      return state;
  }
};
