/* Release 3.1 - Starts */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { connect } from "react-redux";
import { Ring } from "react-awesome-spinners";
import { Header } from "../components";
import { GET_LOCKDOWNTOOL_LIST, UPDATE_LOCKDOWNTOOL_INFO } from "../graphql";
import {
  FormHeading,
  FormLabel,
  DescriptionInput,
  FormButtonsWrap,
  FormButton,
  LoaderWrap,
} from "../components/index";
import { apolloClient, apolloProxyClient } from "../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

import "./LockdownTool.css";

const LockdownTool = (props) => {
  const { account } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { roles } = account;
  const isAdmin = roles.includes("admin");
  const [rowId, setRowId] = useState("0");
  const [content, setContent] = useState("");
  const [isToolLock, setIsToolLock] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messageType, setMessageType] = useState("error");
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateLockdownToolInfo] = useMutation(UPDATE_LOCKDOWNTOOL_INFO);

  useEffect(() => {
    getLockdownToolInfo().then((lockdownToolInfo) => {
      setRowId(lockdownToolInfo.id);
      setContent(lockdownToolInfo.content);
      setIsToolLock(lockdownToolInfo.is_active);
    });
  }, []);

  const getLockdownToolInfo = async () => {
    setMessages([]);
    return await new Promise((resolve, reject) => {
      httpClient
        .query({
          query: GET_LOCKDOWNTOOL_LIST,
          variables: {},
        })
        .then((response) => {
          if (
            response.data &&
            response.data.getLDT &&
            response.data.getLDT.length > 0
          ) {
            resolve(response.data.getLDT[0]);
          }
        })
        .catch((err) => {
          console.error(err);
          setMessages(["Internal Server Error!"]);
          setMessageType("error");
        });
    })
      .catch((err) => {
        console.error(err);
        setMessages(["Internal Server Error!"]);
        setMessageType("error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnClick = async () => {
    setIsSubmitting(true);
    setMessages([]);
    if (!content) {
      setMessages(["Content is required!"]);
      setMessageType("error");
      setIsSubmitting(false);
    } else {
      await updateLockdownToolInfo({
        variables: {
          id: rowId,
          content: content,
          is_active: isToolLock,
        },
      })
        .then((response) => {
          if (response.data.updateLDT) {
            let successMsg = isToolLock ? "Lockdown Tool has been locked successfully." : "Lockdown Tool has been unlocked successfully.";
            setMessages([successMsg]);
            setMessageType("success");
          } else {
            setMessages(["Lockdown Tool Updation Failed!"]);
            setMessageType("error");
          }
        })
        .catch((err) => {
          setMessages(["Internal Server Error!"]);
          setMessageType("error");
          console.error(err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const isActiveChangeHandler = () => {
    setIsToolLock(!isToolLock);
  };
  const handleContentChange = (event) => setContent(event.target.value);

  const NotificationMassages = (props) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        setMessages([]);
        setMessageType("error");
      }, 6000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <>
        {props.messages && props.messages.length > 0 && (
          <div
            key={Math.random()}
            style={{
              border: "1px solid",
              margin: "10px 0px",
              padding: "15px 10px 15px 50px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "10px center",
              color: props.type === "error" ? "#D8000C" : "#4F8A10",
              backgroundColor: props.type === "error" ? "#FFBABA" : "#DFF2BF",
            }}
          >
            {props.messages.map((err) => {
              {
                return (
                  <>
                    <FontAwesomeIcon
                      icon={props.type === "error" ? faTimesCircle : faCheck}
                      style={{
                        marginRight: "10px",
                      }}
                    />
                    {err}
                    <br />
                  </>
                );
              }
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Header {...props} />
      <NotificationMassages messages={messages} type={messageType} />
      {isAdmin && loading === false && (
        <>
          <div className="container">
            <form>
              <div className="row">
                <div className="col-90">
                  <FormHeading>Lockdown Tool</FormHeading>
                </div>
              </div>
              <div className="row">
                <div className="col-10">
                  <FormLabel>Content</FormLabel>
                </div>
                <div className="col-90">
                  <DescriptionInput
                    value={content}
                    name="content"
                    onChange={handleContentChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-10">
                  <FormLabel>Lock Tool</FormLabel>
                </div>
                <div className="col-90">
                  <input
                    type="checkbox"
                    checked={isToolLock}
                    onChange={isActiveChangeHandler}
                  />
                </div>
              </div>
              <div className="row">
                <FormButtonsWrap>
                  <FormButton
                    type="button"
                    primary
                    disabled={isSubmitting}
                    onClick={handleOnClick}
                  >
                    Submit
                  </FormButton>
                  {isSubmitting && (
                    <LoaderWrap>
                      <Ring color={"#001235"} size="2" sizeUnit="rem" />
                    </LoaderWrap>
                  )}
                </FormButtonsWrap>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps)(LockdownTool);
/* Release 3.1 - Ends */
