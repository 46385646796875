// Color definitions - never use these directly
// Always use role colors - see bellow
const basicColors = {
  yellow: '#FFD200',
  yellow70: '#FFE04D',
  yellow50: '#FFE980',
  yellow30: '#FFF2B3',
  yellow15: '#FFF8D9',
  lightYellow: '#FFFBD5',
  gold: '#BF9E00',
  secondaryBlue: '#3CB9E6',
  supportBlue: '#006EFA',
  supportRed: '#FA3E67',
  black: '#000000',
  black80: '#333333',
  black70: '#4D4D4D',
  black66: '#575757',
  black50: '#808080',
  black40: '#999999',
  black30: '#B3B3B3',
  coolGrey: '#919196',
  coolGrey70: '#B2B2B6',
  coolGrey50: '#C8C8CB',
  coolGrey40: '#D3D3D5',
  coolGrey30: '#DEDEE0',
  coolGrey15: '#EFEFEF',
  coolGrey5: '#FAFAFA',
  socialGray: '#707070',
  white: '#FFFFFF'
};

// Role colors - always use these, never definitions above
const roleColors = {
  primaryBrandColor: basicColors.yellow,
  primaryCtaColor: basicColors.yellow,
  primaryCtaHoverColor: basicColors.yellow70,
  primaryCtaDisabledColor: basicColors.coolGrey40,
  primaryCtaTextColor: basicColors.black,
  primaryCtaDisabledTextColor: basicColors.black66,
  primaryCtaBorderColor: basicColors.gold,
  primaryMediaBlockColor: basicColors.yellow,
  secondaryCtaColor: basicColors.coolGrey5,
  secondaryCtaHoverColor: basicColors.white,
  secondaryCtaTextColor: basicColors.black,
  secondaryCtaBorderColor: basicColors.black,
  secondaryMediaBlockColor: basicColors.yellow,
  strongTextColor: basicColors.black,
  normalTextColor: basicColors.black80,
  lightTextColor: basicColors.black66,
  fadedTextColor: basicColors.black40,
  negativeTextColor: basicColors.white,
  errorTextColor: basicColors.supportRed,
  primaryBgColor: basicColors.white,
  subtleBgColor: basicColors.coolGrey5,
  cardShoutBgColor: basicColors.black,
  cardScreamBgColor: basicColors.yellow,
  disabledBgColor: basicColors.coolGrey15,
  cardBorderColor: basicColors.coolGrey15,
  inputBorderColor: basicColors.black,
  errorBorderColor: basicColors.supportRed,
  requiredIndicatorColor: basicColors.supportRed,
  separatorColor: basicColors.coolGrey40,
  menuItemColor: basicColors.black,
  menuItemBgColor: basicColors.white,
  mainMenuHoverColor: basicColors.coolGrey15,
  selectedMenuItemColor: basicColors.white,
  selectedMenuItemBgColor: basicColors.black,
  selectedToggleButtonBgColor: basicColors.coolGrey30,
  linkColor: basicColors.supportBlue,
  formSelectionBgColor: basicColors.gold,
  graphBlue1: basicColors.secondaryBlue,
  socialMenuBgColor: basicColors.socialGray,
  socialMenuItemColor: basicColors.coolGrey30
};

const other = {
  // Dimensions
  container: '1200px',
  containerSmall: '1157px',
  gutterSize: '32px',
  // CSS
  borderLight: '1px solid #D3D3D5',
  boxShadow: '0 6px 21px 0 rgba(0,0,0,0.10)',
  boxShadow2: '0 8px 12px 0 rgba(0,0,0,0.11)',
  buttonActiveBoxShadow: 'inset 0 2px 8px rgba(0,0,0,.2)',
  buttonHoverBoxShadow: '0 2px 8px rgba(0,0,0,.2)',
  inputFocusBoxShadow: 'inset 0 2px 8px rgba(0,0,0,.2)',
  // Fonts
  fontFamily: "'NokiaPureHeadline', 'Arial', 'Helvetica', 'sans-serif'",
  // SVG
  checkMarkWhite:
    'url(data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjE0cHgiIGhlaWdodD0iMTFweCIgdmlld0JveD0iMCAwIDE0IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI4OS4wMDAwMDAsIC0xMjIzLjAwMDAwMCkiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIyLjUiPgogICAgICAgICAgICA8cG9seWxpbmUgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjk2LjAwMDAwMCwgMTIyOC41MDAwMDApIHJvdGF0ZSgtMzYwLjAwMDAwMCkgdHJhbnNsYXRlKC0yOTYuMDAwMDAwLCAtMTIyOC41MDAwMDApICIgcG9pbnRzPSIyOTEgMTIyOC4wNjYyMyAyOTUuNSAxMjMyIDMwMSAxMjI1Ij48L3BvbHlsaW5lPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==)',
  inputErrorMessage:
    'url(data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDE2IDE2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNjguMDAwMDAwLCAtNTY0LjAwMDAwMCkiPgogICAgICAgICAgICA8Y2lyY2xlIGZpbGw9IiNGRjMyMjMiIGN4PSIxMzc2IiBjeT0iNTcyIiByPSI4Ij48L2NpcmNsZT4KICAgICAgICAgICAgPHJlY3QgZmlsbD0iI0ZGRkZGRiIgeD0iMTM3NSIgeT0iNTY5IiB3aWR0aD0iMiIgaGVpZ2h0PSI0Ij48L3JlY3Q+CiAgICAgICAgICAgIDxyZWN0IGZpbGw9IiNGRkZGRkYiIHg9IjEzNzUiIHk9IjU3NCIgd2lkdGg9IjIiIGhlaWdodD0iMiI+PC9yZWN0PgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==)',
  selectBackgroundImage:
    'url(data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjE0cHgiIGhlaWdodD0iOXB4IiB2aWV3Qm94PSIwIDAgMTQgOSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnICBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTA3LjAwMDAwMCwgLTg2NS4wMDAwMDApIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iTGluZS1Db3B5LTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUxNC4wMDAwMDAsIDg2OS41MDAwMDApIHJvdGF0ZSgtMzYwLjAwMDAwMCkgdHJhbnNsYXRlKC01MTQuMDAwMDAwLCAtODY5LjUwMDAwMCkgIiBwb2ludHM9IjUwOSA4NjcgNTE0IDg3MiA1MTkgODY3Ij48L3BvbHlsaW5lPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==)',
  linkArrow:
    'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA4IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUwNS4wMDAwMDAsIC02NTguMDAwMDAwKSIgc3Ryb2tlPSIjMDA2RUZBIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8cG9seWxpbmUgcG9pbnRzPSI1MDYgNjU5IDUxMSA2NjMuNSA1MDYgNjY4Ij48L3BvbHlsaW5lPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==)',
  buttonArrow:
    'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA4IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUwNS4wMDAwMDAsIC02NTguMDAwMDAwKSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8cG9seWxpbmUgcG9pbnRzPSI1MDYgNjU5IDUxMSA2NjMuNSA1MDYgNjY4Ij48L3BvbHlsaW5lPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==)',
  yellowArrowDown:
    'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNHB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAxNCAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5hcnJvdy1oZWFkLXJpZ2h0IGNvcHkgNTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iSm9oZGEtc3Vvcml0dXN0YS0tLVBhbGF1dGVrZXNrdXN0ZWx1IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJKb2hkYS1zdW9yaXR1c3RhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDA2LjAwMDAwMCwgLTM4Ni4wMDAwMDApIiBzdHJva2U9IiNGRkQyMDIiIHN0cm9rZS13aWR0aD0iMyI+ICAgICAgICAgICAgPGcgaWQ9ImFycm93LWhlYWQtcmlnaHQtY29weS01IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0MTMuMDAwMDAwLCAzOTAuNTAwMDAwKSByb3RhdGUoLTI3MC4wMDAwMDApIHRyYW5zbGF0ZSgtNDEzLjAwMDAwMCwgLTM5MC41MDAwMDApIHRyYW5zbGF0ZSg0MTAuMDAwMDAwLCAzODUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aCIgcG9pbnRzPSIwIDAgNS42MjUgNS4zMDM1NzE0MyAwIDEwLjYwNzE0MjkiPjwvcG9seWxpbmU+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+)',
  yellowArrowUp:
    'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNHB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAxNCAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5hcnJvdy1oZWFkLXJpZ2h0IGNvcHkgNDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iSm9oZGEtc3Vvcml0dXN0YS0tLVBhbGF1dGVrZXNrdXN0ZWx1IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJKb2hkYS1zdW9yaXR1c3RhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDA2LjAwMDAwMCwgLTI4MC4wMDAwMDApIiBzdHJva2U9IiNGRkQyMDIiIHN0cm9rZS13aWR0aD0iMyI+ICAgICAgICAgICAgPGcgaWQ9ImFycm93LWhlYWQtcmlnaHQtY29weS00IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0MTMuMDAwMDAwLCAyODUuNTAwMDAwKSByb3RhdGUoLTkwLjAwMDAwMCkgdHJhbnNsYXRlKC00MTMuMDAwMDAwLCAtMjg1LjUwMDAwMCkgdHJhbnNsYXRlKDQxMC4wMDAwMDAsIDI4MC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoIiBwb2ludHM9IjAgMCA1LjYyNSA1LjMwMzU3MTQzIDAgMTAuNjA3MTQyOSI+PC9wb2x5bGluZT4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=)',
  yellowArrowRight:
    'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNHB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAxNCAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5hcnJvdy1oZWFkLXJpZ2h0IGNvcHkgNTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iSm9oZGEtc3Vvcml0dXN0YS0tLVBhbGF1dGVrZXNrdXN0ZWx1IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJKb2hkYS1zdW9yaXR1c3RhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDA2LjAwMDAwMCwgLTM4Ni4wMDAwMDApIiBzdHJva2U9IiNGRkQyMDIiIHN0cm9rZS13aWR0aD0iMyI+ICAgICAgICAgICAgPGcgaWQ9ImFycm93LWhlYWQtcmlnaHQtY29weS01IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0MTMuMDAwMDAwLCAzOTAuNTAwMDAwKSByb3RhdGUoMC4wMDAwMDApIHRyYW5zbGF0ZSgtNDEzLjAwMDAwMCwgLTM5MC41MDAwMDApIHRyYW5zbGF0ZSg0MTAuMDAwMDAwLCAzODUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aCIgcG9pbnRzPSIwIDAgNS42MjUgNS4zMDM1NzE0MyAwIDEwLjYwNzE0MjkiPjwvcG9seWxpbmU+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+)'  
};

export default { ...roleColors, ...other };
