export default (table) => {
  
  return {
    type: "boolean",
    inputType: "checkbox",
    name: "request.override_promotion_increase",
    label: "Override Mandatory Total Target Cash Increase?",
    section: "HR",
    visible: true,
    editable: (column, data) => data.request.request_type === 'compensation' || data.request.request_type === 'internalhire',//Adding this feature to internalhire
    allow: ['hr1', 'hr2', 'hr3', 'bcb']
  };
};