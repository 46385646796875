import styled from 'styled-components/macro';

const PaginationWrap = styled.div`
  display: flex;
  padding: 10px 0;
`;

const PageSizeWrap = styled.div`
  flex: 1;

  & select {
    margin: 0 5px;
    padding: 6px;
    border: 1px solid #d2d2d2;
    outline: none;
  }
`;

const PaginationNavigationWrap = styled.div`
  display:flex;

  & div {
      margin: 0 2px;
      padding: 6px 0;
      background-color: #ffffff;
      cursor: pointer;
      text-align: center;

      &:hover {
        background-color: #DDDDDD;
      }

      &.current {
        background-color: #DDDDDD;
      }
    }
  }
`;

export {
  PaginationWrap,
  PageSizeWrap,
  PaginationNavigationWrap
};
