import React from 'react';
/*Release 2 - budget management column*/
export default (table) => {
     let  renderer=(value, data) =>  <>&#8364; {data.capital_budget.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </>;
          
       return {
            name: "capital_budget", 
            label: "Capital Budget",
            section: "Budget Management",
            visible: true,
            sortable: true,
            width:'10%',
            renderer
       };
     };