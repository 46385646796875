/* Release 3.1 - Starts */
import { dateRendererForInternalHire, effectiveDateEditablecheck } from "../../renderers";

export default (table) => {
  let name = "request.effective_date";

  return {
    type: "string",
    inputType: "date",
    name,
    //allow: ["admin", "rtap", "gtap"],
    label: /* Release 3.2 - 12.2 - Starts */"Effective date"/* Release 3.2 - 12.2 - Ends */,
    visible: true,
    editable: effectiveDateEditablecheck,
    width: "10%",
    renderer: dateRendererForInternalHire,
  };
};
/* Release 3.1 - Ends */
