export const requestTypeRenderer = (value) => {
  /* Release 3.2 - 1.1 - Starts */
  switch(value) {
    case 'compensation':
      return 'Compensation Change';
      break;
    case 'internalhire':
      return 'Internal Hiring';
      break;
    case 'onetime':
      return 'One-Time Payment';
      break;
    case 'businessexcellenceaward':
      return 'Business Excellence Award';
      break;
    default:
      return 'One-Time Payment';
      break;
  }
  /* Release 3.2 - 1.1 - Ends */
};
