import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showModal, unselectAllRecord, toggleShowInEur } from '../actions';
import { Budget, 
  GlobalMessages, 
  Table, 
  TableHeadingWrap, 
  Header, 
  TableActionsWrap, 
  TableAction, 
  /* Release 3.2 - 7.1 - Starts */
  LoaderWrap
  /* Release 3.2 - 7.1 - Ends */
} from '../components';
import { columns } from '../util/config';
// R2 - imported inputfield for search
import { InputField } from '../components/styled';
/* Release 3.2 - 7.1 - Starts */
import { Ring } from "react-awesome-spinners";
/* Release 3.2 - 7.1 - Ends */

const RequestsHistory = props => {
  // const { account, messages, unselectAllRecord, toggleShowInEur } = props;
  const { account, messages, unselectAllRecord, toggleShowInEur, config, showModal, } = props;
  /* Release 3.2 - 10.3 - Starts */
  const { forceCurrency, accessibles } = account;
  const isBCB = account.roles.includes('bcb');
  /* Release 3.2 - 10.3 - Ends*/
  // unselect all requests which may be selected on another page
  useEffect(() => { unselectAllRecord('requests'); }, [unselectAllRecord]);
  // R2 - Search Functionality State variable
  const [searchText, setSearchText] = useState('');
  const orderBy = { created_ts: "DESC" };
  const isGct = account.roles.includes("gct")
  const isAdmin = account.roles.includes('admin')
  /* Release 3.2 - 10.4 - Starts */
  const isKeyUser = account.roles.includes('keyuser')
  /* Release 3.2 - 10.4 - Ends */
   /* Release 3.2 - 10.1 - Starts */
  const isRcb = account.roles.includes('rcb')
  /* Release 3.2 - 10.1 - Ends */
  /* Release 3.2 - 1.1 - Starts */
  const isHR1 = account.roles.includes('hr1');
  const isHR2 = account.roles.includes('hr2');
  const isHR3 = account.roles.includes('hr3');
  const isChr= account.roles.includes('chr');
  const isHRC= account.roles.includes('hrc');
  const isRTap= account.roles.includes('rtap');
  const isGTap= account.roles.includes('gtap');
  /* Release 3.2 - 1.1 - Ends */
  
  const hasGlobalMessages = messages.length > 0;
  const hasBudgetVisibility = ((accessibles && accessibles.budgetOrgs.length > 0) || isGct || isAdmin);  
  const [queryFilter, setTableFilter] = useState({});
  /*  FIX THE DEFECT 10 STARTS  */
  const toggleDisplayCurrency = event => {
    toggleShowInEur(forceCurrency ? null : 'EUR');
  }
  /*  FIX THE DEFECT 10 ENDS  */

  // R2 - Search Functionality Starts
  const handleSearchChange = event => {
    /* Release 3.2 - 10.1 - Starts */
    let filterValue = {...queryFilter};
    /* Release 3.2 - 10.1 - Ends */
    setSearchText(event.target.value);
    const searchFilter = event.target.value;
    if(searchFilter.length >= 2) {
      /* Release 3.2 - 10.1 - Starts */
      //setTableFilter({ employee_search: searchFilter });
      filterValue.employee_search = searchFilter;
      /* Release 3.2 - 10.1 - Ends */
    } else if(searchFilter === '' || searchFilter.length < 2) {
       /* Release 3.2 - 10.1 - Starts */
      //setTableFilter({ });
      delete filterValue.employee_search;
      /* Release 3.2 - 10.1 - Ends */
    }
    /* Release 3.2 - 10.1 - Starts */
    setTableFilter(filterValue);
    /* Release 3.2 - 10.1 - Ends */
  }

  const clearChange = () => {
    setSearchText('')  
    setTableFilter({});
  }
  // R2 - Search Functionality Ends
  /* Release 3.2 - 7.1 - Starts */
  const handelExportToExcel = (event) =>
    showModal({ component: "exportToExcelAllRequests", content: queryFilter });
  /* Release 3.2 - 7.1 - Ends */
  return (
    <>   
      <Header {...props}/>
      {hasBudgetVisibility && <Budget {...props} />}
      {hasGlobalMessages && <GlobalMessages />}
      <TableHeadingWrap>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h2>All Requests</h2>
          {/* R2 - Search and clear input boxes */}
          <InputField type="text" placeholder="Search on Nokia Id, Request Id, Email, Name, Country [at least 2 characters]" value={searchText} onChange={handleSearchChange} style={{width: '350px', marginLeft: '20px', height: '32px', outline: '#CCC', borderRadius: '5px', border: '1px solid #CCC'}}/>
          <div onClick={clearChange} style={{height: '32px', fontSize: '16px', cursor: 'pointer', fontWeight: '500', textAlign: 'center', position: 'relative', top: '6px', right: '20px'}}>X</div>
          {/* R2 - Search and clear input boxes */}
        </div>
        {/*  FIX THE DEFECT 10 STARTS  */}
        <TableActionsWrap>          
          {/* Release 3.2 - 7.1 - Starts */}
          <TableAction
              disabled={false}
              onClick={handelExportToExcel}
            >
              Export To Excel
            </TableAction>        
          {/* Release 3.2 - 7.1 - Ends */}
          <TableAction onClick={toggleDisplayCurrency}>{`Switch to ${forceCurrency === 'EUR' ? 'local currency' : 'EUR'}`}</TableAction>
        </TableActionsWrap>
        {/*  FIX THE DEFECT 10 ENDS  */}
      </TableHeadingWrap>
      {/* <Table recordType="requests" columns={columns.requestsHistory} query="requests" where={queryFilter} order={orderBy}/> */}
      {/* DEFECT No. 14 Statrs  */}
      <Table recordType="requests" columns={columns.requestsHistory} query="requests" where={queryFilter} order={orderBy}
        isEuroSort={forceCurrency === 'EUR'?true:false}
        config = {config}
        account = {account}
      />
    {/* DEFECT No. 14 Ends  */}
    </>
  );
};

const mapStateToProps = state => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
    messages: state.messages,
    // {/* DEFECT No. 14 Statrs  */}
    config:state.config
   // {/* DEFECT No. 14 Ends  */}
  };
};

export default connect(
  mapStateToProps,
  { showModal, unselectAllRecord, toggleShowInEur }
)(RequestsHistory);
