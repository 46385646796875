/* Release 3.1 - Starts */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Header,
  Budget,
  TableHeadingWrap,
  TableActionsWrap,
  TableAction,
  Table,
  GlobalMessages,
} from "../components";
import { InputField } from "../components/styled";
import { toggleShowInEur, showModal, unselectAllRecord } from "../actions";
import { columns } from "../util/config";

const ChangeApprover = (props) => {
  const {
    account,
    config,
    toggleShowInEur,
    showModal,
    selectedRequests,
    unselectAllRecord,
    messages,
  } = props;
  const { roles, accessibles, forceCurrency } = account;
  const isAdmin = roles.includes("admin");

  const [searchText, setSearchText] = useState("");
  const [queryFilter, setTableFilter] = useState({
    approver_pers_no: { ne: account.pers_no },
    approval_status: { eq: "active" },
  });
  const [updatedKey, setUpdatedKey] = useState(1);

  const hasBudgetVisibility =
    (accessibles && accessibles.budgetOrgs.length > 0) || isAdmin;
  const hasSelectedRequests =
    selectedRequests.length > 0 && selectedRequests.length == 1;
  const orderBy = { employee_request_id: "DESC" };
  const hasGlobalMessages = messages.length > 0;

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    const searchFilter = event.target.value;
    const searchObj = { ...queryFilter, employee_search: searchFilter };
    if (searchFilter.length >= 2) {
      setTableFilter(searchObj);
    } else if (searchFilter === "" || searchFilter.length < 2) {
      const { employee_search, ...newqueryFilter } = queryFilter;
      setTableFilter(newqueryFilter);
    }
  };

  const clearChange = () => {
    setSearchText("");
    const { employee_search, ...newqueryFilter } = queryFilter;
    setTableFilter(newqueryFilter);
  };

  const toggleDisplayCurrency = (event) => {
    toggleShowInEur(forceCurrency ? null : "EUR");
  };

  const handleRequestChangeApprover = (event) =>
    showModal({ component: "changeApprover" });

  useEffect(() => {
    unselectAllRecord("requests");
    if (messages.length > 0) {
      setUpdatedKey(Math.floor(Math.random() * 100));
    }
  }, [unselectAllRecord, messages]);

  return (
    <>
      {isAdmin && (
        <>
          <Header {...props} />
          {hasBudgetVisibility && <Budget {...props} />}
          {hasGlobalMessages && <GlobalMessages />}
          <TableHeadingWrap>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ marginRight: "15px" }}>Requests to Approver</h2>
              <InputField
                type="text"
                placeholder="Search on Nokia Id, Request Id, Email, Name [at least 2 characters]"
                value={searchText}
                onChange={handleSearchChange}
                style={{
                  width: "350px",
                  marginLeft: "20px",
                  height: "32px",
                  outline: "#CCC",
                  borderRadius: "5px",
                  border: "1px solid #CCC",
                }}
              />
              <div
                onClick={clearChange}
                style={{
                  height: "32px",
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: "500",
                  textAlign: "center",
                  position: "relative",
                  top: "6px",
                  right: "20px",
                }}
              >
                X
              </div>
            </div>
            <TableActionsWrap>
              <TableAction onClick={toggleDisplayCurrency}>
                {`Switch to ${
                  forceCurrency === "EUR" ? "local currency" : "EUR"
                }`}
              </TableAction>
              <TableAction
                disabled={!hasSelectedRequests}
                onClick={handleRequestChangeApprover}
              >
                Change Approve
              </TableAction>
            </TableActionsWrap>
          </TableHeadingWrap>
          <Table
            recordType="requests"
            columns={columns.changeApproverRequests}
            query="approvals"
            where={queryFilter}
            order={orderBy}
            isEuroSort={forceCurrency === "EUR" ? true : false}
            config={config}
            account={account}
            checkboxIsDisplayed={true}
            key={updatedKey}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
    config: state.config,
    selectedRequests: state.selectedRequests,
    messages: state.messages,
  };
};

export default connect(mapStateToProps, {
  showModal,
  toggleShowInEur,
  unselectAllRecord,
})(ChangeApprover);
/* Release 3.1 - Ends */
