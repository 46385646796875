import React, { useState, useEffect } from "react";
/*Release 2 - budget management column*/
export default (table) => {
  let renderer = (value, data, efunc) => (
    <li
      style={{
        listStyle: "none",
        textDecoration: "underline",
        cursor: "pointer",
        color: "#5876ed",
      }}
      id={data.budget_id}
      onClick={() => efunc(data)}
    >
      {" "}
      {table == "beabudgetmanage" ? data.organization_unit_name : data.organization_unit.organization_unit_name}{" "}
    </li>
  );

  return {
    name: "organization_unit_name",
    label: "Organisation Name",
    section: "Budget Management",
    visible: true,
    sortable: true,
    width: "15%",
    renderer,
  };
};
