export default (table) => {
  let name = 'request.by';
  let sortable = false;
  let renderer = (value) => `${value.full_name} (${value.nokia_id})`;
  let width = undefined;

  switch (table) {
    case 'approvedRequestHistory':
    case 'pendingRequests':
      width = "200px"
      break;
    default:
      break;
  }

  return {
    type: "string",
    name,
    label: "Requester",
    visible: true,
    width,
    sortable,
    renderer
  };
};