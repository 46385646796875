/* Release 3.1 - Starts */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { GET_LOCKDOWNTOOL_LIST } from "../graphql";
import { apolloClient, apolloProxyClient } from "../index";
import { Ring } from "react-awesome-spinners";
import { Header } from "../components";

import "./Maintenance.css";

const Maintenance = (props) => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [isToolLock, setIsToolLock] = useState(false);
  const history = useHistory();
  const { account } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { roles } = account;
  const isAdmin = roles.includes("admin");
  const isLM = roles.includes("manager");
  const isSuperManager = roles.includes("supermanager");
  const isGct = roles.includes("gct");
  const isApprover = !isLM && !isSuperManager;

  useEffect(() => {
    if (!isAdmin) {
    getLockdownToolInfo().then((lockdownToolInfo) => {
      setContent(
        lockdownToolInfo.content ? lockdownToolInfo.content :
          "Sorry for the inconvenience.Our website is currently undergoing scheduled maintenance."
      );
      setIsToolLock(lockdownToolInfo.is_active);
      setLoading(false);
    });
    } else {
      if (isLM || isSuperManager || isGct) {
        history.push("/employees");
      } else if (isApprover) {
        history.push("/pending-requests");
      }
    }
  }, []);

  const getLockdownToolInfo = async () => {
    return await new Promise((resolve, reject) => {
      httpClient
        .query({
          query: GET_LOCKDOWNTOOL_LIST,
          variables: {},
        })
        .then((response) => {
          if (
            response.data &&
            response.data.getLDT &&
            response.data.getLDT.length > 0
          ) {
            resolve(response.data.getLDT[0]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }).catch((err) => {
      console.error(err);
    });
  };

  return (
    <>
      {!loading ? (
        <>
          <Header {...props} isToolLock={isToolLock}/>
          <div class="wrap" style={{ "width": "70%"}}>
            <h1>Notice!</h1>
            <h2>
              <p style={{ "white-space": "pre-line", "text-align": "justify"}}>{content}</p>
            </h2>
          </div>
        </>
      ) : (
        <div class="wrap">
          <Ring color={"#001235"} size="2" sizeUnit="rem" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps)(Maintenance);

/* Release 3.1 - Ends */
