import { currencyDisplayRenderer } from '../../renderers';

export default (table) => {
  return {
      type: "float",
      name: "fte_addl_ttc_amount",
      section: "Current Compensation Information",
      label: "Additional Total Target Cash Amount",
      visible: true,
      sortable: true,
      renderer: currencyDisplayRenderer,
      width: "100px"
    };
};