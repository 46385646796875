export const filterJobCatalog = (filters, groupBy, config, data) => {
  const { jobsCatalog } = config;
  
  const unique = {};
  const distinct = [];

  jobsCatalog.forEach( x => {
    const filtered = Object.keys(filters).every(key =>
      typeof filters[key] === 'function' && filters[key](x[key])
    );

    if (filtered && !unique[x[groupBy]]) {
      distinct.push(x[groupBy]);
      unique[x[groupBy]] = true;
    }
  });

  return distinct.sort();
};

const filterValueForField = (field, value, currentData, storedData) => {
  // OPTIONS FILTER
  const matchValue = !storedData
    // OPTIONS FILTER
    ? currentData[`promotion_${field}`] ? currentData[`promotion_${field}`] : currentData[field]
    // FILTER FROM FORM
    : currentData[`promotion_${field}`] ? currentData[`promotion_${field}`] : storedData[`promotion_${field}`];

  return value === matchValue;
};

export const buildFilters = (forField, data, storedData) => {
  const currentData = data.__typename === 'Request' ? {...data.for, ...data} : { ...data};
  const job_fields = ['job_family_name', 'job_subfamily_name', 'job_name', 'pay_grade', 'job_grade'];
  
  const fields_to_filter = job_fields.slice(0,job_fields.indexOf(forField));

  // const shouldBuldFilters = storedData && fields_to_filter.some(x => currentData[[`promotion_${x}`]]);
  // console.log(shouldBuldFilters)
  let filters_to_return = {};
  // if (shouldBuldFilters) {
    fields_to_filter.forEach( field => {
      filters_to_return[field] = value => filterValueForField(field, value, currentData, storedData)
    });
  // }

  return filters_to_return;
  // return shouldBuldFilters ? filters_to_return : null;

}