export default (table) => {
  return {
      type: "string",
      name: "job_grade",
      section: "Current Information",
      label: "Current Job Grade",
      visible: true,
      filterable: true,
      width: "60px",
      sortable:true
    };
};