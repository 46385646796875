import { currencyDisplayRenderer ,currencySymbolRenderer, numberRenderer} from '../../renderers';

export default (table) => {
  let name = 'new_fte_annual_salary';
  let defaultValue = (value, data) => data.new_fte_annual_salary;
  
  let renderer = numberRenderer;
  switch (table) {

    case 'editInternalHire':
    name = 'request.new_fte_annual_salary';

    defaultValue = (value, data) => data.request.new_fte_annual_salary;
    break;
  default:
    break;
}
  

  return { 
    type: "float",
    name,
    label: "New Annual Base Salary",
    section: "internalHire",
    colFrom : "internalHire",
    visible: true,
    defaultValue,
    renderer,
 
    editable : (value, data) => data.incentive_plan_type === "YLIP" ? true : false,
    inputType : "number",
    //before : currencySymbolRenderer,
    width : '10%',
      
  };
};