import { GET_FILTERS, UPDATE_FILTERS } from '../actions/index';

const intialState = {"mocFilters":{}};

export default (state = intialState, action) => {
  switch (action.type) {
    case GET_FILTERS:
      return Object.assign({}, state, {
        isVisible: true,
        modalContent: action.content,
        modalComponent: action.component
      });
    case UPDATE_FILTERS:
      return Object.assign({}, state, {
        mocFilters: {...action}
      });
    default:
      return state;
  }
};
