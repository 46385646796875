export default (table) => {
    let name = 'rr_number';
    let defaultValue = "";
    switch (table) {
       
          case 'editInternalHire':
          name = 'request.rr_number';
      
          defaultValue = (value, data) => data.request.rr_number;
          break;
        default:
          break;
      }
  
    return {
        type: "string",
        inputType: "text",
        name,
        label: "RR Number",
        section: "TAP Employees Information",
        visible: true,
        editable: true,
        width: '10%',
        maxlength : 128,
        defaultValue
      };
  };