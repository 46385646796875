import { PUSH_MESSAGE, POP_MESSAGE, REMOVE_MESSAGE } from '../actions/index';

export default (state = [], action) => {
  switch (action.type) {
    case PUSH_MESSAGE:
      return state.concat([action.payload]);
    case POP_MESSAGE:
      const clone = [ ...state ];
      clone.pop()
      return clone;
    case REMOVE_MESSAGE:
      return state.filter((item, index) => action.index !== index);
    default:
      return state;
  }
};
