/* Release 3.2 - 1.1 - Starts */
import { budgetOwnerCurrencyRenderer } from '../../renderers';
export default (table) => {
  let renderer = (value, data, account, config) =>
    budgetOwnerCurrencyRenderer(data.bo_budget_in_progress, data, account, config);
  return {
    name: "bo_budget_in_progress",
    label: "In Progress",
    section: "beabusinessownerbudgetmanage",
    visible: true,
    sortable: false,
    width: "15%",
    renderer,
  };
};
/* Release 3.2 - 1.1 - Ends */
