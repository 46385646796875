export const SELECT_EMPLOYEE = 'SELECT_EMPLOYEE';
export const SELECT_REQUEST = 'SELECT_REQUEST';
export const SELECT_DELEGATES = 'SELECT_DELEGATES';/*  FIXING DELEGATION ISSUE */
/* Release 3.2 - 1.1 - Starts */
export const SELECT_BUDGET = 'SELECT_BUDGET';
export const SELECT_BUSINESSOWNERBUDGET = 'SELECT_BUSINESSOWNERBUDGET';
/* Release 3.2 - 1.1 - Ends */
const selectType = { employees: SELECT_EMPLOYEE, requests: SELECT_REQUEST, delegates: SELECT_DELEGATES, /* Release 3.2 - 1.1 - Starts */ budgets: SELECT_BUDGET, businessOwnerBudgets:SELECT_BUSINESSOWNERBUDGET /* Release 3.2 - 1.1 - Ends */ };

export const selectRecord = (empl, index, type) => dispatch =>
  dispatch({ type: selectType[type], indexColumn: index, payload: {...empl} });

export const UNSELECT_EMPLOYEE = 'UNSELECT_EMPLOYEE';
export const UNSELECT_REQUEST = 'UNSELECT_REQUEST';
export const UNSELECT_DELEGATES = 'UNSELECT_DELEGATES';
/* Release 3.2 - 1.1 - Starts */
export const UNSELECT_BUDGET = 'UNSELECT_BUDGET';
export const UNSELECT_BUSINESSOWNERBUDGET = 'UNSELECT_BUSINESSOWNERBUDGET';
/* Release 3.2 - 1.1 - ends */
const unselectType = { employees: UNSELECT_EMPLOYEE, requests: UNSELECT_REQUEST, delegates: UNSELECT_DELEGATES, /* Release 3.2 - 1.1 - Starts */ budgets: UNSELECT_BUDGET, businessOwnerBudgets:UNSELECT_BUSINESSOWNERBUDGET /* Release 3.2 - 1.1 - Ends */ };
export const unselectRecord = (empl, index, type) => dispatch =>
  dispatch({ type: unselectType[type], indexColumn: index, payload: empl });

export const UNSELECT_ALL_EMPLOYEES = 'UNSELECT_ALL_EMPLOYEES';
export const UNSELECT_ALL_REQUESTS = 'UNSELECT_ALL_REQUESTS';
export const UNSELECT_ALL_DELEGATES = 'UNSELECT_ALL_DELEGATES';
/* Release 3.2 - 1.1 - Starts */
export const UNSELECT_ALL_BUDGETS = 'UNSELECT_ALL_BUDGETS';
export const UNSELECT_ALL_BUSINESSOWNERBUDGETS = 'UNSELECT_ALL_BUSINESSOWNERBUDGETS';
/* Release 3.2 - 1.1 - Ends */
const unselectAllType = {employees: UNSELECT_ALL_EMPLOYEES, requests: UNSELECT_ALL_REQUESTS, delegates: UNSELECT_ALL_DELEGATES, /* Release 3.2 - 1.1 - Starts */ budgets: UNSELECT_ALL_BUDGETS, businessOwnerBudgets:UNSELECT_ALL_BUSINESSOWNERBUDGETS /* Release 3.2 - 1.1 - Ends */ };
export const unselectAllRecord = (type) => dispatch =>
  dispatch({ type: unselectAllType[type] });

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_DELEGATES = 'UPDATE_DELEGATES';
/* Release 3.2 - 1.1 - Starts */
export const UPDATE_BUDGET = 'UPDATE_BUDGET';
export const UPDATE_BUSINESSOWNERBUDGET = 'UPDATE_BUSINESSOWNERBUDGET';
/* Release 3.2 - 1.1 - Ends */
const updateType = { employees: UPDATE_EMPLOYEE, requests: UPDATE_REQUEST, delegates: UPDATE_DELEGATES, /* Release 3.2 - 1.1 - Starts */ budgets: UPDATE_BUDGET, businessOwnerBudgets:UPDATE_BUSINESSOWNERBUDGET /* Release 3.2 - 1.1 - Ends */ };
export const updateRecord = (id, indexColumn, newData, type) => dispatch =>
  dispatch({ type: updateType[type], indexColumn: indexColumn, id: id, data: newData });





export const GET_FILTERS = 'GET_FILTERS';
export const getFilters = value => dispatch => dispatch({ ...value, type: GET_FILTERS });

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const updateFilters = value => dispatch => {dispatch({ ...value, type: UPDATE_FILTERS })};

export const SHOW_MODAL = 'SHOW_MODAL';
export const showModal = value => dispatch => dispatch({ ...value, type: SHOW_MODAL });

export const HIDE_MODAL = 'HIDE_MODAL';
export const hideModal = () => dispatch => dispatch({ type: HIDE_MODAL });

export const TOGGLE_DISPLAY_CURRENCY_EUR = 'TOGGLE_DISPLAY_CURRENCY_EUR';
export const toggleShowInEur = currency => dispatch =>
  dispatch({ type: TOGGLE_DISPLAY_CURRENCY_EUR, payload: currency });

export const UPDATE_ACCOUNT_FROM_AUTH = 'UPDATE_ACCOUNT_FROM_AUTH';
export const updateAccountFromAuthToken = tokenPayload => dispatch =>
  dispatch({ type: UPDATE_ACCOUNT_FROM_AUTH, payload: tokenPayload });

export const SET_CONFIG = 'SET_CONFIG';
export const setConfig = data => dispatch => dispatch({ type: SET_CONFIG, payload: data });

//Admin calculations for R2 start
export const GET_ALL_FORMULAS = 'GET_ALL_FORMULAS';
export const setFormulas = data => dispatch => dispatch({ type: GET_ALL_FORMULAS, payload: data });
//Admin calculations for R2 End
export const SET_UPDATED = 'SET_UPDATED';
export const budgetSetUpdated = (updated) => dispatch => dispatch({ type: SET_UPDATED, payload: updated });


export const POP_MESSAGE = 'POP_MESSAGE';
export const popMessage = () => dispatch => dispatch({ type: POP_MESSAGE });

export const PUSH_MESSAGE = 'PUSH_MESSAGE';
export const pushMessage = mess => dispatch => dispatch({ type: PUSH_MESSAGE, payload: {...mess} });

export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const removeMessage = idx => dispatch => dispatch({ type: REMOVE_MESSAGE, index: idx });
