import { numberRenderer, currencySymbolRenderer, currencyDisplayRenderer } from '../../renderers';

export default (table) => {
  let name = 'ttc_increase_amount';
  let renderer = numberRenderer;
  let before = null;
  let label = "Total Target Cash Increase";
  let width = null;
  let inputType = null;
  let sortable = false;
  let editable = false;
  let colFrom = "Others"

  switch (table) {
    case 'requestOnetime':
      label = "One-time Payment";
      inputType = "number";
      before = currencySymbolRenderer;
      editable = true;
      break;
    case 'requestCompesation':
      width = '150px';
      inputType = "number";
      before = currencySymbolRenderer;
      editable = true;
      break;
    case 'internalHire':
      width = '10%';
      inputType = "number";
      before = currencySymbolRenderer;
      editable = true;
      colFrom = "internalHire";
      break;
    case 'myRequests':
      label = 'Amount';
      sortable = true;
      renderer = currencyDisplayRenderer;
      //reason width issue - px to %
      width = "8%";
      break;
    case 'requestHistory':
      label = 'Amount';
      sortable = true;
      renderer = currencyDisplayRenderer;
      width = "120px";
      break;
    case 'approvedRequestHistory':
    case 'pendingRequests':
      // //Start Code Issue fixed 15
      // name = "request.ttc_increase_amount";
      // label = 'Amount';
      // renderer = (value, data, account, config) => currencyDisplayRenderer(value, data.request, account, config);
      // width = "150px";
      // sortable=true;

     
      //Start Code Issue fixed 15
      name = "ttc_increase_amount";
      label = 'Amount';
      renderer = (value, data, account, config) => currencyDisplayRenderer(data.request.ttc_increase_amount, data.request, account, config);
      width = "120px";
      sortable=true;
      break;
       //Start Code Issue fixed 15

      break;
     //End Code Issue fixed 15
    case 'rejectRequests':
    // case 'approveRequests':
    //   name = "request.ttc_increase_amount";
    //   label = 'Amount';
    //   renderer = (value, data, account, config) => currencyDisplayRenderer(value, data.request, account, config);
    //   width = "150px";
    //   break;
 //Start Code Issue fixed 15
    case 'approveRequests':
      name = "ttc_increase_amount";
      label = 'Amount';
      renderer = (value, data, account, config) => currencyDisplayRenderer(data.request.ttc_increase_amount, data.request, account, config);
      width = "120px";
      break;
 //End Code Issue fixed 15
    case 'editCompensation':
      case 'editInternalHire':
      inputType = "number";
      name = "request.ttc_increase_amount";
      width = "200px";
      editable = true;
      before = (value, data, account, config) => currencySymbolRenderer(value, data.request, account, config);
      break;
    case 'editOnetime':
      inputType = "number";
      name = "request.ttc_increase_amount";
      label = "One-time Payment";
      editable = true;
      before = (value, data, account, config) => currencySymbolRenderer(value, data.request, account, config);
      break;
    /* Release 3.2 - 1.1 - Starts */
    case 'editBEA':
      inputType = "number";
      name = "request.ttc_increase_amount";
      label = "Award Amount";
      editable = true;
      before = (value, data, account, config) => currencySymbolRenderer(value, data.request, account, config);
      break;    
    case 'requestBusinessExcellenceAward':
      label = "Award Amount";
      inputType = "number";
      before = currencySymbolRenderer;
      editable = true;
      break;
    /* Release 3.2 - 1.1 - Ends */
    default:
      break;
  }

  return {
    type: "float",
    inputType,
    name,
    label,
    section: "Merit/Promotion Increase",
    visible: true,
    sortable,
    editable,
    colFrom,
    before,
    renderer,
    width
  };
};