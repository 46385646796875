/* Release 3.1 - Starts */
import { formatDateMonthFirst, formatDate } from "../../util/helper";
import { effectiveDateEditablecheck, paymentDateEditablecheck } from "../../util/renderers/field_validation";

/* Release 3.1 - Ends */

export const dateTimeRenderer = (value, data, account = {}, config = {}) => {
  /*  FIXING DELEGATION ISSUE */
  if(value != null)
  {
    const date = new Date(parseInt(value));
    return date.toLocaleString(undefined, { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'});
  }
  else
  {
    return 'N/A';  
  }
};

export const dateRenderer = (value, data, account = {}, config = {}) => {
  /*  FIXING DELEGATION ISSUE */
  if(value != null)
  {
    const date = new Date(parseInt(value));
    return date.toLocaleString(undefined, { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC'});
  }
  else
  {
    return 'N/A';  
  }
};
/* Release 3.2 - 9.1Req - Starts */
export const dateRendererFromString = (value, data, account = {}, config = {}) => {
  /*  FIXING DELEGATION ISSUE */
  if(value != null)
  {
    const date = new Date(value);
    return formatDateMonthFirst(date);
  }
  else
  {
    return 'N/A';  
  }
};
/* Release 3.2 - 9.1Req - Ends */
/* Release 3.1 - Starts */
export const dateRendererForInternalHire = (value, data, account = {}, config = {}) => {
  let isEditable = effectiveDateEditablecheck(value, data);
  let request_type = data.request_type ? data.request_type : data.request && data.request.request_type ? data.request.request_type : null;
  if(value != null && (request_type == "internalhire" ||  request_type == "compensation"))
  {
    var date = new Date(value);
    return isEditable ? formatDate(date): formatDateMonthFirst(date);
  }
  else
  {
    return 'N/A'; 
  }
};
/* Release 3.1 - Ends */
/* Release 3.2 - 12.2 - Starts */
export const paymentDateRendererForInternalHire = (value, data, account = {}, config = {}) => {
  let isEditable = paymentDateEditablecheck(value, data);
  let request_type = data.request_type ? data.request_type : data.request && data.request.request_type ? data.request.request_type : null;
  if(value != null && (request_type == "businessexcellenceaward" ||  request_type == "onetime"))
  {
    const date = new Date(value);
    return isEditable ? formatDate(date): formatDateMonthFirst(date);
  }
  else
  {
    return 'N/A'; 
    // if (
    //   /* Release 3.2 - 1.1 - Starts */
    //   (data.request.request_type == "onetime" || data.request.request_type == "businessexcellenceaward")
    //   /* Release 3.2 - 1.1 - Ends */      
    //   && data.request.payment_date) {
    //   const date = new Date(data.request.payment_date);
    //   return isEditable ? formatDate(date): formatDateMonthFirst(date);
    // } else {
    //   return ''; 
    // } 
  }
};

/* Release 3.2 - 12.2 - Ends */
