/*  Release 3.2 - 1.1 - Starts */
import React, { useState, useRef, useEffect } from "react";
import { apolloClient, apolloProxyClient } from "../../../index";
import { useMutation } from "@apollo/react-hooks";
import { Ring } from "react-awesome-spinners";
import { connect } from "react-redux";
import {
  FormButton,
  FormButtonsWrap,
  LoaderWrap,
  DescriptionInput,
} from "../..";
import AsyncSelect from "react-select/async";
import { pushMessage } from "../../../actions";
import {
  bgOrganizations,
  getBEABudgetBasedOnOrg,
  createBEABudget,
  organizationBEABudget,
} from "../../../graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheck,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const AddBEABudget = (props) => {
  const { hideModal, modal, pushMessage, account } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { roles, accessibles } = account;
  const isBCB = roles.includes("bcb");
  const [popUpAction, setPopUpAction] = useState("Add");
  const [messages, setMessages] = useState([]);
  const [messageType, setMessageType] = useState("error");
  const selectedyear = modal.modalContent.toString();
  const [selectedOrg, setSelectedOrg] = useState("");
  const [capitalBudget, setCapitalBudget] = useState(0.0);
  const [budget, setBudget] = useState(0.0);
  const [budgetInProgress, setBudgetInProgress] = useState(0.0);
  const [budgetSpent, setBudgetSpent] = useState(0.0);
  const [budgetForecast, setBudgetForecast] = useState(0.0);
  const [budgetAvailable, setBudgetAvailable] = useState(0.0);
  const [requestDescription, setRequestDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const capitalInput = useRef(null);
  const budgetInput = useRef(null);
  const selectInputRef = useRef();
  const [allOrgs, setAllOrgs] = useState([]);
  const [addBEABudget] = useMutation(createBEABudget);
  const [queryFilter, setQueryFilter] = useState(isBCB ? { organization_unit: { in: accessibles.budgetOrgs } } :{});

  useEffect(() => {
    getBGOrganization().then((resultBGOrganization) => {
      if (resultBGOrganization) {
        const filteredOrgArray = 
        isBCB
          ? resultBGOrganization.filter((item) =>
              accessibles.budgetOrgs ? accessibles.budgetOrgs.includes(item.organization_unit) : false
            )
          : 
          resultBGOrganization;
        setAllOrgs(
          filteredOrgArray
            ? filteredOrgArray.map((a) => ({
                label: a.organization_unit_name,
                value: a.organization_unit,
              }))
            : []
        );
      }
    });
  }, []);

  const getBGOrganization = async () => {
    return await new Promise((resolve) => {
      httpClient
        .query({
          query: bgOrganizations,
          variables: {
            //where: queryFilter
          },
        })
        .then((response) => {
          if (response.data && response.data.bgOrganizations) {
            resolve(response.data.bgOrganizations);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const parentDiv = {
    width: "80%",
    padding: "2px",
    margin: "0 auto",
  };
  const rowStyle = {
    width: "100%",
    display: "flex",
  };
  const columnQuarterStyle = {
    fontSize: "15px",
    fontWeight: "400",
    width: "25%",
    float: "left",
    margin: "10px",
  };
  const columnHalfStyle = {
    fontSize: "15px",
    fontWeight: "400",
    width: "50%",
    float: "left",
    margin: "10px",
  };
  const columnFullStyle = {
    fontSize: "15px",
    fontWeight: "400",
    width: "100%",
    float: "left",
    margin: "10px",
  };
  const columnHeaderStyle = {
    fontSize: "20px",
    fontWeight: "400",
    width: "100%",
    textAlign: "center",
    margin: "10px",
  };
  const columnButtonStyle = {
    width: "50%",
    float: "left",
    margin: "0 10px !important",
  };

  function formatNumber(n) {
    return n
      .toString()
      .replace(/[^0-9.]/g, "")//.replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function formatCurrency(input_val, event) {
    // console.log("input_val : " + input_val);
    // console.log("capitalInput : " + capitalInput.current.value);
    // console.log("budgetInput : " + budgetInput.current.value);
    if (input_val === "") {
      return;
    }

    if (event.target.id == "new_budget" && capitalInput.current.value == "") {
      setMessages(["Please enter capital budget."]);
      setMessageType("error");
      budgetInput.current.value = "";
      return;
    }

    let capitalBudgetInputVal = 0;
    let budgetInputVal = 0;
    let budgetInprogressInputVal = 0;
    let budgetSpentInputVal = 0;

    if (event.target.id == "new_capital") {
      capitalBudgetInputVal = parseFloat(
        input_val.replaceAll("€", "").replaceAll(",", "").replaceAll(" ", "")
      );
    } else {
      capitalBudgetInputVal = parseFloat(
        capitalInput.current.value
          .toString()
          .replaceAll("€", "")
          .replaceAll(",", "")
          .replaceAll(" ", "")
      );
    }
    if (event.target.id == "new_budget") {
      budgetInputVal = parseFloat(
        input_val.replaceAll("€", "").replaceAll(",", "").replaceAll(" ", "")
      );
    } else {
      budgetInputVal = parseFloat(
        budgetInput.current.value
          .toString()
          .replaceAll("€", "")
          .replaceAll(",", "")
          .replaceAll(" ", "")
      );
    }

    budgetInprogressInputVal = parseFloat(
      budgetInProgress
        .toString()
        .replaceAll("€", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
    );
    budgetSpentInputVal = parseFloat(
      budgetSpent
        .toString()
        .replaceAll("€", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
    );

    // console.log("capitalBudgetInputVal : " + capitalBudgetInputVal);
    // console.log("budgetInputVal : " + budgetInputVal);
    // console.log("budgetInprogressInputVal : " + budgetInprogressInputVal);
    // console.log("budgetSpentInputVal : " + budgetSpentInputVal);
    // console.log("budgetForecast : " + (budgetInputVal - (budgetInprogressInputVal + budgetSpentInputVal)));
    // console.log("budgetAvailable : " + (budgetInputVal - budgetSpentInputVal));

    if (event.target.id == "new_capital") {
      if (budgetInputVal > capitalBudgetInputVal) {
        setMessages(["Capital budget must be greater than the budget."]);
        setMessageType("error");
        input_val = parseFloat(capitalBudget);
      } else if (
        budgetInprogressInputVal + budgetSpentInputVal >
        capitalBudgetInputVal
      ) {
        setMessages([
          "Capital budget must be greater than the sum of budget inprogress and spent.",
        ]);
        setMessageType("error");
        input_val = parseFloat(budget);
      }
      // setCapitalBudget(input_val);
    }
    if (event.target.id == "new_budget") {
      if (budgetInputVal > capitalBudgetInputVal) {
        setMessages(["Budget must be lesser than the capital budget."]);
        setMessageType("error");
        input_val = parseFloat(budget);
        budgetInputVal = parseFloat(
          input_val.toString().replaceAll("€", "").replaceAll(",", "").replaceAll(" ", "")
        );
      } else if (
        budgetInprogressInputVal + budgetSpentInputVal >
        budgetInputVal
      ) {
        setMessages([
          "Budget must be greater than the budget inprogress and spent.",
        ]);
        setMessageType("error");
        input_val = parseFloat(budget);
        budgetInputVal = parseFloat(
          input_val.toString().replaceAll("€", "").replaceAll(",", "").replaceAll(" ", "")
        );
      }
      // setBudget(input_val);
      setBudgetForecast(
        formatNumber(
          parseFloat(budgetInputVal - (budgetInprogressInputVal + budgetSpentInputVal)).toFixed(2)
        )        
      );
      setBudgetAvailable(
        formatNumber(
          parseFloat(budgetInputVal - budgetSpentInputVal).toFixed(2)
        )
      );
    }

    event.target.value = "€ " + formatNumber(parseFloat(input_val.toString().replaceAll("€", "").replaceAll(",", "").replaceAll(" ", "")).toFixed(2));
  }

  const handleDescriptionChange = (event) => {
    setRequestDescription(event.target.value);
  };

  const FetchOrg = (input) => {
    setMessages([]);
    if (input.length >= 3 && allOrgs && allOrgs.length > 0) {
      return new Promise((resolve) => {
        resolve(
          allOrgs.filter(function (el) {
            return el.label.toLowerCase().includes(input.toLowerCase());
          })
        );
      });
    } else {
      return new Promise((resolve) => {
        resolve();
      });
    }
  };

  const handleQuit = () => {
    hideModal();
  };

  const setOrg = async (e) => {
    if (e && e.value) {
      setSelectedOrg(e.value);
      await getExistingBGOrganization(e.value).then(
        (resultOfSelectedBGBudget) => {
          if (resultOfSelectedBGBudget) {
            setMessageType("info");
            setMessages([
              "Organization '" +
                e.label +
                "' already have budget information for the year " +
                selectedyear.toString() +
                ".",
            ]);
            selectInputRef.current.select.select.clearValue();  
            setSelectedOrg("");
          }
        }
      );
    }    
  };

  const getExistingBGOrganization = async (selected_organization_unit) => {
    return await new Promise((resolve) => {
      httpClient
        .query({
          query: getBEABudgetBasedOnOrg,
          variables: {
            organization_unit: selected_organization_unit,
            year: selectedyear,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data && response.data.beaBudgetOrganization) {
            resolve(response.data.beaBudgetOrganization);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const handleCurrencyOnBlur = (e) => {
    formatCurrency(e.target.value, e);
  };

  const LoadingIndicator = (e) => {
    return (
      <>
        {e.selectProps.inputValue &&
        e.selectProps.inputValue.replace(/\s+/g, "").length >= 3 ? (
          <span className="loading">...</span>
        ) : (
          ""
        )}
      </>
    );
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      let capitalBudgetInputVal = parseFloat(
        capitalInput.current.value
          ? capitalInput.current.value
              .toString()
              .replaceAll("€", "")
              .replaceAll(",", "")
              .replaceAll(" ", "")
          : 0
      );
      let budgetInputVal = parseFloat(
        budgetInput.current.value
          ? budgetInput.current.value
              .toString()
              .replaceAll("€", "")
              .replaceAll(",", "")
              .replaceAll(" ", "")
          : 0
      );
      let budgetInprogressInputVal = 0;
      let budgetSpentInputVal = 0;
      if (!selectedOrg) {
        setMessages(["Organisation is required."]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }
      if (capitalBudgetInputVal == 0) {
        setMessages(["Capital budget is required."]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }
      if (budgetInputVal == 0) {
        setMessages(["Budget should is required."]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }
      if (budgetInputVal > capitalBudgetInputVal) {
        setMessages(["Budget should be lesser than capital budget."]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }
      if (budgetInprogressInputVal + budgetSpentInputVal > budgetInputVal) {
        setMessages([
          "Budget should be greater than sum of budget inprogress and spent.",
        ]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }
      if (!requestDescription || requestDescription.replaceAll(" ", "") == "") {
        setMessages(["Description is required."]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }

      const results = await addBEABudget({
        variables: {
          data: {
            description: requestDescription,
            budget: budgetInputVal,
            capital_budget: capitalBudgetInputVal,
            budget_available: budgetInputVal,
            budget_in_progress: parseFloat(0),
            budget_spent: parseFloat(0),
            year: selectedyear.toString(),
            organization_unit: selectedOrg,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: organizationBEABudget,
            variables: {
              where: { year: { eq: selectedyear.toString() } },
            },
          },
        ],
      });
      if (results) {
        setIsSubmitting(false);
        hideModal();
        pushMessage({
          type: "success",
          message: "Budget created successfully!",
        });
      }
    } catch (e) {
      console.log("Issue in creating the budget. Graph QL error: " + e.message);
      setMessages([e.message]);
      setMessageType("error");
      setIsSubmitting(false);
    }
  };

  const MessageComponent = (props) => {
    return (
      <>
        <FontAwesomeIcon
          icon={
            props.type === "error"
              ? faTimesCircle
              : props.type === "info"
              ? faInfoCircle
              : faCheck
          }
          style={{
            marginRight: "10px",
          }}
        />
        {props.err}
        <br />
      </>
    );
  };

  const NotificationMassages = (props) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        setMessages([]);
        setMessageType("error");
      }, 6000);
      return () => clearTimeout(timer);
    }, []);
    return (
      <>
        {props.messages && props.messages.length > 0 && (
          <div
            key={Math.random()}
            style={{
              border: "1px solid",
              margin: "10px 0px",
              padding: "15px 10px 15px 50px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "10px center",
              color:
                props.type === "error"
                  ? "#D8000C"
                  : props.type === "info"
                  ? "#31708f"
                  : "#4F8A10",
              backgroundColor:
                props.type === "error"
                  ? "#FFBABA"
                  : props.type === "info"
                  ? "#d9edf7"
                  : "#DFF2BF",
            }}
          >
            {props.messages.map((err) => {
              {
                return <MessageComponent type={props.type} err={err} />;
              }
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <div style={parentDiv}>
      <div style={rowStyle}>
        <div style={columnHeaderStyle}>
          <input id="searchResult" style={{ display: "none" }} />
          {popUpAction} Business Excellence Award Budget
        </div>
      </div>
      <NotificationMassages
        key={Math.random()}
        messages={messages}
        type={messageType}
      />
      <div style={rowStyle}>
        <div style={columnHalfStyle}>
          <div>Year</div>
          <div>{selectedyear}</div>
        </div>
        <div style={columnHalfStyle}>
          <div>
            Organisation Unit <span style={{ color: "red" }}>*</span>
          </div>
          <div>
            <AsyncSelect
              ref={selectInputRef}
              components={{ LoadingIndicator }}
              loadingMessage={(e) =>
                e.inputValue && e.inputValue.replace(/\s+/g, "").length >= 3
                  ? "Loading"
                  : "Type at least three character"
              }
              noOptionsMessage={(e) =>
                e.inputValue && e.inputValue.replace(/\s+/g, "").length >= 3
                  ? "No matches found"
                  : "Type at least three character"
              }
              style={{ width: "100%" }}
              loadOptions={FetchOrg}
              onChange={(e) => setOrg(e)}
              placeholder="Type atleast three character"
              className="select"
            />
            <div></div>
          </div>
        </div>
      </div>
      <div style={rowStyle}>
        <div style={columnHalfStyle}>
          <div>
            Capital Budget <span style={{ color: "red" }}>*</span>
          </div>
          <div>
            <input
              id="new_capital"
              ref={capitalInput}
              type="text"
              style={{ height: "2rem", width: "100%" }}
              pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
              placeholder="&#8364;"
              onBlur={handleCurrencyOnBlur}
            />
          </div>
        </div>
        <div style={columnHalfStyle}>
          <div>
            Budget <span style={{ color: "red" }}>*</span>
          </div>
          <div>
            <input
              id="new_budget"
              ref={budgetInput}
              type="text"
              style={{ height: "2rem", width: "100%" }}
              pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
              placeholder="&#8364;"
              onBlur={handleCurrencyOnBlur}
            />
          </div>
        </div>
      </div>
      <div style={rowStyle}>
        <div style={columnQuarterStyle}>
          <div>Budget Spent</div>
          <div>
            <label>&#8364; {budgetSpent}</label>
          </div>
        </div>
        <div style={columnQuarterStyle}>
          <div>Budget In Progress</div>
          <div>
            <label>&#8364; {budgetInProgress}</label>
          </div>
        </div>
        <div style={columnQuarterStyle}>
          <div>Budget Forecast</div>
          <div>
            <label>&#8364; {budgetForecast ? budgetForecast : 0}</label>
          </div>
        </div>
        <div style={columnQuarterStyle}>
          <div>Budget Available</div>
          <div>
            <label>&#8364; {budgetAvailable ? budgetAvailable : 0}</label>
          </div>
        </div>
      </div>
      <div style={rowStyle}>
        <div style={columnFullStyle}>
          <div>
            Budget description <span style={{ color: "red" }}>*</span>
          </div>
          <div>
            <DescriptionInput
              style={{ width: "100%", height: "auto" }}
              name="request_description"
              onChange={handleDescriptionChange}
              defaultValue=""
            />
          </div>
        </div>
      </div>
      <div style={rowStyle}>
        <div style={columnButtonStyle}>
          <FormButtonsWrap style={{ width: "100%", marginTop: "0px" }}>
            <FormButton onClick={handleQuit}>Quit</FormButton>
            <FormButton primary disabled={isSubmitting} onClick={handleSubmit}>
              {popUpAction === "Add" ? "Submit" : "Update"}
            </FormButton>
            {isSubmitting && (
              <LoaderWrap>
                <Ring color={"#001235"} size="2" sizeUnit="rem" />
              </LoaderWrap>
            )}
          </FormButtonsWrap>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps, { pushMessage })(AddBEABudget);
/*  Release 3.2 - 1.1 - Ends */
