import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showModal, unselectAllRecord, toggleShowInEur } from "../actions";
import {
  GlobalMessages,
  Table,
  TableHeadingWrap,
  Header,
  TableActionsWrap,
  TableAction   /* Release 3.2 - 7.1 - Ends */
} from "../components";
import { columns } from "../util/config";

import { InputField } from "../components/styled";
import { Redirect } from "react-router-dom";

const AllRequestsHrc = (props) => {
  const { account, messages, unselectAllRecord, toggleShowInEur, config, showModal, } =
    props;
  const { forceCurrency, accessibles } = account;
  useEffect(() => {
    unselectAllRecord("requests");
  }, [unselectAllRecord]);
  const [searchText, setSearchText] = useState("");
  const [queryFilter, setTableFilter] = useState({});
  const orderBy = { created_ts: "DESC" };
  const isHRC = account.roles.includes("hrc");

  if (!isHRC) {
    return <Redirect to="/employees" />;
  }

  const hasGlobalMessages = messages.length > 0;

  const toggleDisplayCurrency = (event) => {
    toggleShowInEur(forceCurrency ? null : "EUR");
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    const searchFilter = event.target.value;
    if (searchFilter.length >= 2) {
      setTableFilter({ employee_search: searchFilter });
    } else if (searchFilter === "" || searchFilter.length < 2) {
      setTableFilter({});
    }
  };

  const clearChange = () => {
    setSearchText("");
    setTableFilter({});
  };

  /* Release 3.2 - 7.1 - Starts */
  const handelExportToExcel = () => showModal({ component: "exportToExcelAllRequestsHrc", content: queryFilter });
  /* Release 3.2 - 7.1 - Ends */

  return (
    <>
      <Header {...props} />

      {hasGlobalMessages && <GlobalMessages />}
      <TableHeadingWrap>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>All Requests</h2>
          <InputField
            type="text"
            placeholder="Search on Nokia Id, Request Id, Email, Name, Country [at least 2 characters]"
            value={searchText}
            onChange={handleSearchChange}
            style={{
              width: "350px",
              marginLeft: "20px",
              height: "32px",
              outline: "#CCC",
              borderRadius: "5px",
              border: "1px solid #CCC",
            }}
          />
          <div
            onClick={clearChange}
            style={{
              height: "32px",
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: "500",
              textAlign: "center",
              position: "relative",
              top: "6px",
              right: "20px",
            }}
          >
            X
          </div>
        </div>

        <TableActionsWrap>
          {/* Release 3.2 - 7.1 - Starts */}  
          <TableAction
              disabled={false}
              onClick={handelExportToExcel}
            >
              Export To Excel New
            </TableAction>          
          {/* Release 3.2 - 7.1 - Ends */}
          <TableAction onClick={toggleDisplayCurrency}>{`Switch to ${
            forceCurrency === "EUR" ? "local currency" : "EUR"
          }`}</TableAction>
        </TableActionsWrap>
      </TableHeadingWrap>

      <Table
        recordType="requests"
        columns={columns.allrequesthrc}
        query="requestallhrc"
        where={queryFilter}
        order={orderBy}
        isEuroSort={forceCurrency === "EUR" ? true : false}
        config={config}
        account={account}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
    messages: state.messages,
    // {/* DEFECT No. 14 Statrs  */}
    config: state.config,
    // {/* DEFECT No. 14 Ends  */}
  };
};

export default connect(mapStateToProps, {
  showModal,
  unselectAllRecord,
  toggleShowInEur,
})(AllRequestsHrc);
