import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { showModal, unselectAllRecord, toggleShowInEur } from '../actions';
import { GlobalMessages, Header, Budget, Table, TableHeadingWrap, TableActionsWrap, TableAction, SelectFilter } from '../components';
import { columns } from '../util/config';
import { canRaiseRequest } from '../util/helper';
import {Redirect} from 'react-router-dom';
const MyDelegates = props => {
  const {selectedDelegates, messages, account, showModal, unselectAllRecord, modal } = props;
  const {pers_no, roles, accessibles } = account;

  const isGct = roles.includes("gct");
  const isSuperManager = roles.includes('supermanager');
  const isBCB = roles.includes('bcb');
  const isHR1 = roles.includes('hr1');
  const isHR2 = roles.includes('hr2');
  /*  HR3_ROLE STARTS */
  const isHR3 = roles.includes('hr3');
  /*  HR3_ROLE STARTS */
  const isGtap = roles.includes('gtap');
  const isRtap = roles.includes('rtap');
  const isHRC = roles.includes('hrc');

  
  var autoString = Math.random().toString();
  const [tableQuery, setTableQuery] = useState('delegatesForManager')
  const [tableWhere, setTableWhere] = useState(
    { 
      pers_no: {eq: pers_no}, 
      delegation_pers_no: {eq: pers_no}, 
      approver_type: {ne: autoString}, 
    })
  const [orderBy, setOrderBy] = useState({ start_date: "DESC" });

  useEffect(() => { unselectAllRecord('delegates'); }, [unselectAllRecord]);
  
  useEffect(() => {
    // && modal.modalComponent==='createdelegate'
    if(!modal.isVisible && (modal.modalComponent==='revokedelegate' || modal.modalComponent==='createdelegate')){

      //setTableWhere('delegatesForManager');

      //console.log("Model Information After Close Starts");
      //console.log('Model',modal);

      //console.log("WHERE VALUES BEFORE CHANGE===>");
      //console.log(tableWhere);
      var autoString = Math.random().toString();
      setTableWhere(
        { 
          pers_no: {eq: pers_no}, 
          delegation_pers_no: {eq: pers_no}, 
          approver_type: {ne: autoString}, 
        }
      );
      
      //console.log("WHERE VALUES AFTER CHANGE===>");
      //console.log(tableWhere);
      
      //console.log("Model Information After Close Ends");
    }
     //alert(isRefresh);
    },[modal.isVisible]);
    

  

  //const [tableQuery, setTableQuery] = useState('dd')
  //const [tableWhere, setTableWhere] = useState({pers_no: pers_no, delegation_pers_no: pers_no})

  const hasGlobalMessages = messages.length > 0;
  const hasSelectedDelegates = selectedDelegates.length > 0;

  const handleRevokeDelegate = event => {
    //console.log(selectedDelegates.length);
    if(selectedDelegates.length > 0)
      showModal({component: 'revokedelegate'});
  };

  const handleCreateDelegate = event => {
    showModal({component: 'createdelegate'});
  };
  /**Release 2 add delegates for new roles rtap and gtap */
  //if(!isGct && !isSuperManager && !isBCB && !isHR1 && !isHR2 && !isGtap && !isRtap)
  /*  HR3_ROLE STARTS */
  if(!isGct && !isSuperManager && !isBCB && !isHR1 && !isHR2 && !isHR3 && !isGtap && !isRtap && !isHRC)
  /*  HR3_ROLE ENDS */
  {
    //console.log("INVALID USER");
    return  <Redirect  to="/employees" />
  }
  else
  {
    //console.log("VALID USER");
  }

  return (
    <>   
      <Header {...props}/>
      {hasGlobalMessages && <GlobalMessages />}
      <TableHeadingWrap>
        <h2>My Delegates</h2>
        {hasSelectedDelegates && <div>{selectedDelegates.length} selected</div>}
        
        <TableActionsWrap>
          <TableAction width="120px" onClick={handleRevokeDelegate}>Revoke</TableAction>
          <TableAction width="100px" onClick={handleCreateDelegate}>Create Delegate</TableAction>
        </TableActionsWrap>
      </TableHeadingWrap>
      {!modal.isVisible && 
        <Table recordType="delegates" columns={columns.delegatesForManager} query={tableQuery} where={tableWhere}  />
      }
    </>
  );  
};

const mapStateToProps = state => {
  return {
    selectedDelegates: state.selectedDelegates,
    account: state.account,
    messages: state.messages,
    modal:state.modal
  };
};

export default connect(
  mapStateToProps,
  { showModal, unselectAllRecord }
)(MyDelegates);
