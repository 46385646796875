export default (table) => {
  let name = 'full_name';
  let sortable = false;
  switch (table) {
    case 'editCompensation':
    case 'editOnetime':
    /* Release 3.2 - 1.1 - Starts */
    case 'editBEA':
    /* Release 3.2 - 1.1 - Ends */
      case 'editInternalHire':
      name = 'request.for.full_name';
      break;
    case 'employees':
      sortable = true;
      break;
    /* Release 3.2 - 1.1 - Starts */
    case 'employees_businessexcellenceaward':
      sortable = true;
      break;
    /* Release 3.2 - 1.1 - Ends */
    default:
      break;
  }
  return {
    type: "string",
    name,
    label: "Name",
    section: "Current Information",
    visible: true,
    sortable
  };
};