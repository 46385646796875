//Release 2- Admin Proxy New Requirement for admins 
import React, { useState } from 'react';
import { apolloClient, apolloProxyClient } from '../index';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faUndo } from '@fortawesome/free-solid-svg-icons'
import { Header } from '../components';
import AsyncSelect from 'react-select/async';
import { PEOPLE_SUGGEST } from '../graphql';

const MyProxy = props => {
  const { account } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { roles } = account;

  // Release 2 Roles are not used in the code anywhere
  const isGct = roles.includes("gct");
  const isSuperManager = roles.includes('supermanager');
  const isBCB = roles.includes('bcb');
  const isHR1 = roles.includes('hr1');
  const isHR2 = roles.includes('hr2');

  /*  HR3_ROLE STARTS */
  const isHR3 = roles.includes('hr3');
  /*  HR3_ROLE STARTS */
  const [isProxy, setProxy] = useState(sessionStorage.getItem("proxy_access") ? true : false);
  const [proxyNo, setSelProxy] = useState(sessionStorage.getItem("proxy_access") == null ? '' : sessionStorage.getItem("proxy_access"));
  const [proxyName, setSelProxyName] = useState(sessionStorage.getItem("proxy_name") == null ? '' : sessionStorage.getItem("proxy_name"));

  const mainstyle = {
    alignContent: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '1%'
  };

  const labelstyle = {
    fontSize: '17px'
  }
  const styles1 = {
    width: '25%',
    marginLeft: '0.5%',
    fontSize: '12px'
  };
  const btn = {
    height: '41px',
    fontSize: '15px',
    marginRight: '10px',
    color: 'white',
    background: '#0065ed',
    border: 'white',
    borderRadius: '5px'

  };

// Release 2 The api which shows the suggestion to select a person to be proxied 

  const FetchPeople = (input) => {
    if (input.length >= 3) {
      console.log(input);

      return new Promise((resolve, reject) => {

        httpClient.query({
          query: PEOPLE_SUGGEST,
          variables: { where: { searchvalue: input }, limit: 15, offset: 0 },
        })
          .then((response) => {
            console.log(response.data);

            if (response.data && response.data.match_people) {

              resolve(response.data.match_people.items.map(
                (a) => ({
                  label: a.full_name + " (" + a.nokia_id + ") (" + a.email_work + ")",
                  value: a.email_work,
                  Name : a.full_name
                })
              ));
            }
          }
          )
          .catch((err) =>
            console.error(err)
          )
      })
    }
  };
  //Release 2 - set hooks for session variables
  const setPeople = (e) => {
    console.log(e);
    setSelProxy(e.value);
setSelProxyName(e.Name);
  }


// Release 2 - set the sessions and reload the page
  const createProxy = () => {
    let pro_confirm = window.confirm("Are you sure you want to Proxy to user - " + proxyName);
    if (pro_confirm) {
      sessionStorage.removeItem('access_token_payload');
      sessionStorage.removeItem('access_token');
      sessionStorage.clear();
      sessionStorage.setItem("proxy_access", proxyNo);
      sessionStorage.setItem("proxy_name", proxyName);
      window.location.href = window.location.origin;
    }
  }
  const deleteProxy = () => {

    sessionStorage.removeItem("proxy_access");
    sessionStorage.removeItem("proxy_name");
    sessionStorage.removeItem('access_token_payload');
    sessionStorage.removeItem('access_token');
    sessionStorage.clear();
    window.location.href = window.location.origin;
  }
  return (
    <>
      <Header {...props} />


      <div style={mainstyle} >
        <h1>  Proxy Now   <FontAwesomeIcon icon={faUsers} /> </h1>
      </div>
      <div style={mainstyle} >
        <label style={labelstyle}>Select the Target User :  </label>
        <div style={styles1}>

          <AsyncSelect
            //loadOptions={fetchArtists}
            loadOptions={FetchPeople}
            onChange={(e) => setPeople(e)}
            placeholder="Type at least three letters"
            className="select"
          />
        </div>
      </div>

      <div style={mainstyle}>
        <button style={btn} onClick={createProxy}> <FontAwesomeIcon icon={faUsers} /> Proxy Now</button>
        <button style={btn} onClick={deleteProxy}> <FontAwesomeIcon icon={faUndo} /> Clear </button>
      </div>

    </>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account
  };
};

export default connect(
  mapStateToProps
)(MyProxy);
