/* eslint-disable no-self-compare */
import { useMemo, useEffect, useCallback } from 'react';
//import { useLazyQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { updateRecord } from '../../actions';
import { capitalize, filterJobCatalog, filterPaymentTypes } from '../../util';
//import { SALARY_RANGE } from '../../graphql';

const BUDGET_MULTIPLIER = 3.5;
const CURRENT_TIMESTAMP = Date.now();

const round = (v, d) => Number(Math.round(v+'e'+d)+'e-'+d);
const isNaN = (maybeNaN) => maybeNaN !== maybeNaN;


const Moccalculator= props => {
  const { columns, recordType, item, data, updateRecord, config, account,calupdate } = props;

  //const [getRange, { data: rangeData, called }] = useLazyQuery(SALARY_RANGE);

  const indexColumn = columns.find(c => c.index);

  const flattenRequestData = data => {
    return { ...data.request.for, ...{
      employee_request_id: data.employee_request_id,
      request_type: data.request.request_type,
      currency: data.request.currency,
      ttc_increase_amount: data.request.ttc_increase_amount,
      ttc_increase_percentage: data.request.ttc_increase_percentage,
      payment_type: selectedItem.request.payment_type,
      payment_reason: selectedItem.request.payment_reason,
      is_gpp_eligible: data.request.is_gpp_eligible,
      gpp_increase_amount: data.request.gpp_increase_amount,
      gpp_increase_percentage: data.request.gpp_increase_percentage,
      promoted: data.request.promoted,
      promotion_job_family_name: data.request.promotion_job_family_name,
      promotion_job_subfamily_name: data.request.promotion_job_subfamily_name,
      promotion_job_name: data.request.promotion_job_name,
      promotion_pay_grade: data.request.promotion_pay_grade,
      promotion_job_grade: data.request.promotion_job_grade,
      promotion_job_grade_delta: data.request.promotion_job_grade_delta,
      promotion_incentive_target_percentage: data.request.promotion_incentive_target_percentage,
      demoted: data.request.demoted,
      mandatory_ttc_increase_percentage: data.request.mandatory_ttc_increase_percentage,
      mandatory_ttc_increase_amount: data.request.mandatory_ttc_increase_amount,
      apply_ti_harmonization: data.request.apply_ti_harmonization,
      interim_job_family_name: data.request.interim_job_family_name,
      interim_job_grade: data.request.interim_job_grade,
      interim_fte_incentive_target_amount: data.request.interim_fte_incentive_target_amount,
      interim_incentive_target_percentage: data.request.interim_incentive_target_percentage,
      interim_total_increase_amount: data.request.interim_total_increase_amount,
      interim_increase_amount_for_salincr: data.request.interim_increase_amount_for_salincr,
      interim_increase_amount_for_tih: data.request.interim_increase_amount_for_tih,
      step_1_abs: data.request.step_1_abs,
      step_1_ita: data.request.step_1_ita,
      step_1_itp: data.request.step_1_itp,
      interim_theo_itp: data.request.interim_theo_itp,
      interim_itp_situation: data.request.interim_itp_situation,
      step_2_abs_gap: data.request.step_2_abs_gap,
      step_2_ita_gap: data.request.step_2_ita_gap,
      interim_remainder_for_split: data.request.interim_remainder_for_split,
      new_fte_annual_salary: data.request.new_fte_annual_salary,
      new_fte_incentive_target_amount: data.request.new_fte_incentive_target_amount,
      new_incentive_target_percentage: data.request.new_incentive_target_percentage,
      new_fte_ttc_annual_salary: data.request.new_fte_ttc_annual_salary,
      new_itp_situation: data.request.new_itp_situation,
      new_quartile: data.request.new_quartile,
      override_manager_proposal: data.request.override_manager_proposal,
      override_fte_annual_salary: data.request.override_fte_annual_salary,
      override_fte_incentive_target_amount: data.request.override_fte_incentive_target_amount,
      override_promotion_increase: data.request.override_promotion_increase,
      override_ti_harmonization: data.request.override_ti_harmonization,
      override_fte_incentive_target_percentage: data.request.override_fte_incentive_target_percentage,
      override_fte_ttc_annual_salary: data.request.override_fte_ttc_annual_salary,
      new_position_title: data.request.new_position_title,
      interim_pay_grade: data.request.interim_pay_grade,
      interim_salary_range_min: data.request.interim_salary_range_min,
      interim_salary_range_mid: data.request.interim_salary_range_mid,
      interim_salary_range_max: data.request.interim_salary_range_max,
      new_ttc_compa_ratio: data.request.new_ttc_compa_ratio,
      interim_salary_range_spread: data.request.interim_salary_range_spread,
      ttc_increase:data.request.ttc_increase,
      ttc_increase_perc:data.request.ttc_increase_perc
    }};
  }

  let selectedItem = props[`selected${capitalize(recordType)}`].find(e => e[indexColumn.name] === item);
  if (recordType === 'requests' && selectedItem) {
    selectedItem = flattenRequestData(selectedItem);
  }

  /*  DEFECTS FIX STARTS  */
  const ttc_increase = useMemo(
    () => 
        selectedItem
        ? ((selectedItem.new_fte_ttc_annual_salary)- (selectedItem.fte_ttc_annual_salary))
        : 0,
    [selectedItem]
  );
   //phase2 
   const ttc_increase_perc = useMemo(
    () => 
        selectedItem
        ? round((
          ((selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary)/selectedItem.fte_ttc_annual_salary) * 100), 10)
        :0,
    [selectedItem]
  );

  const total_increaseed_amount = useMemo(
    () => 
        selectedItem
        ? ((selectedItem.new_fte_ttc_annual_salary)- (selectedItem.fte_ttc_annual_salary))
        : 0,
    [selectedItem]
  );
  /*  DEFECTS FIX ENDS  */

  const exchange_rate = useMemo(
    () => {
      const { exchangeRates } = config;
      if (!selectedItem || !selectedItem.currency) return 1;
      const match = exchangeRates.find(r => r.to_currency === selectedItem.currency && r.from_currency === 'EUR');
      return match ? 1/match.exchange_rate : 1;
    },
    [selectedItem, config]
  );

  const salary_range_spread = useMemo(
    () => {
      if (!selectedItem) return 0;

      return (1- selectedItem.salary_range_min/selectedItem.salary_range_mid)+(selectedItem.salary_range_max/selectedItem.salary_range_mid - 1);
    },
    [selectedItem]
  );

  const tenure = useMemo(
    () =>
      selectedItem
      ? (CURRENT_TIMESTAMP - (selectedItem.pers_start_date || CURRENT_TIMESTAMP)) / 86400000 / 365
      : 0,
    [selectedItem]
  );
  
  const is_gpp_eligible = useMemo(
    () =>
      selectedItem
      ? selectedItem.job_grade === 6 && selectedItem.interim_job_grade === 7 && selectedItem.tenure >= 1.5 && selectedItem.tenure <= 4
      : false,
    [selectedItem]
  );

  const ttc_increase_amount = useMemo(
    () => {
      if (data.ttc_increase_amount !== undefined) { 
        const v = isNaN(data.ttc_increase_amount) ? 0 : data.ttc_increase_amount;
        return Math.abs(v);
      }
      if (data.request && data.request.ttc_increase_amount !== undefined) {
        const v = isNaN(data.request.ttc_increase_amount) ? 0 : data.request.ttc_increase_amount;
        return Math.abs(v);
      }

      return selectedItem
        ? round(selectedItem.fte_ttc_annual_salary * ((selectedItem.ttc_increase_percentage || 0)/100), 10)
        : 0;
    },
    [selectedItem, data]
  );

  const ttc_increase_percentage = useMemo(
    () => {
      if (data.ttc_increase_percentage !== undefined) return Math.abs(data.ttc_increase_percentage);
      if (data.request && data.request.ttc_increase_percentage !== undefined) return Math.abs(data.request.ttc_increase_percentage);
      return selectedItem
        ? round((((selectedItem.ttc_increase_amount || 0) / selectedItem.fte_ttc_annual_salary) * 100), 10)
        : 0;
    },
    [selectedItem, data]
  );

  const override_fte_incentive_target_percentage = useMemo(
    () => {
      return selectedItem && selectedItem.override_fte_annual_salary
        ? (selectedItem.override_fte_incentive_target_amount / selectedItem.override_fte_annual_salary) * 100
        : 0;
    },
    [selectedItem]
  );

  const override_fte_ttc_annual_salary = useMemo(
    () =>
      selectedItem
      ? selectedItem.override_fte_incentive_target_amount + selectedItem.override_fte_annual_salary + selectedItem.fte_addl_ttc_amount
      : 0,
    [selectedItem]
  );

  const gpp_increase_amount = useMemo(
    () => {
      if (data.gpp_increase_amount) return data.gpp_increase_amount;

      return selectedItem
        ? selectedItem.fte_ttc_annual_salary * (selectedItem.gpp_increase_percentage/100)
        : null;
    },
    [selectedItem, data]
  );

  const gpp_increase_percentage = useMemo(
    () => {
      if (data.gpp_increase_percentage) return data.gpp_increase_percentage;

      return selectedItem
        ? (selectedItem.gpp_increase_amount / selectedItem.fte_ttc_annual_salary) * 100
        : null;
    },
    [selectedItem, data]
  );

  const promotion_job_family_name = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.promotion_job_family_name) return activeData.promotion_job_family_name;

      return selectedItem
        ? selectedItem.promotion_job_family_name || selectedItem.job_family_name
        : null;
    },
    [selectedItem, data]
  );

  const new_position_title = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.new_position_title) return activeData.new_position_title;

      return selectedItem
        ? selectedItem.new_position_title || selectedItem.position_title
        : null;
    },
    [selectedItem, data]
  );

  const promotion_job_subfamily_name = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.promotion_job_subfamily_name) return activeData.promotion_job_subfamily_name;

      // const f = buildFilters('job_subfamily_name', activeData, selectedItem);
      // console.log(f)

      let filters = null;
      if (activeData.promotion_job_family_name) {
        filters = { job_family_name: v => v === activeData.promotion_job_family_name };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'job_subfamily_name', config, activeData);
        return filtered.shift();
      }
      
      return selectedItem
        ? selectedItem.promotion_job_subfamily_name || selectedItem.job_subfamily_name
        : null;
    },
    [selectedItem, data, config]
  );
  const promotion_job_name = useMemo(
    () => {
      const { jobsCatalog } = config;
      // edit or create
      const activeData = data.request ? data.request : data;
      // changed - Job Role Name
      if (activeData.promotion_job_name) return activeData.promotion_job_name;
      
      let filters = null;
      if (activeData.promotion_job_subfamily_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === activeData.promotion_job_subfamily_name
        };
      }
      if (activeData.promotion_job_family_name) {
        filters = {
          job_family_name: v => v === activeData.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name
        };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'job_name', config, activeData);
        // Return job title which matches current job grade, if possible for given job selection
        const namesAndGrades = jobsCatalog.filter(j => filtered.includes(j.job_name));
        const matching = namesAndGrades.find(jx => jx.pay_grade.startsWith(selectedItem.job_grade));
        return matching ? filtered.find(x => x === matching.job_name) : filtered.shift();
      }
      // changed - Something else or Nothing
      return selectedItem
        ? selectedItem.promotion_job_name || selectedItem.job_name
        : null;
    },
    [selectedItem, data, config]
  );

  const promotion_pay_grade = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;
      // console.log('promotion_pay_grade', activeData.promotion_pay_grade)
      // if (activeData.promotion_pay_grade) return activeData.promotion_pay_grade;
      // if (data.request && data.request.promotion_pay_grade) return data.request.promotion_pay_grade;

      let filters = null;
      if (activeData.promotion_job_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === activeData.promotion_job_name
        };
      }
      if (activeData.promotion_job_subfamily_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === activeData.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name
        };
      }
      if (activeData.promotion_job_family_name) {
        filters = {
          job_family_name: v => v === activeData.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name
        };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'pay_grade', config, activeData);
        //const matchingGrade = filtered.find(x => x.startsWith(selectedItem.job_grade));
        //return matchingGrade ? matchingGrade : filtered.shift();
        return filtered.shift();
      }

      return selectedItem
        ? selectedItem.promotion_pay_grade || selectedItem.pay_grade
        : null;
    },
    [selectedItem, data, config]
  );

  const promotion_job_grade = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.promotion_job_grade) return activeData.promotion_job_grade;

      let filters = null;
      if (activeData.promotion_pay_grade) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name,
          pay_grade: v => v === activeData.promotion_pay_grade
        };
      }
      if (activeData.promotion_job_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === activeData.promotion_job_name,
          pay_grade: v => v === selectedItem.promotion_pay_grade
        };
      }
      if (activeData.promotion_job_subfamily_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === activeData.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name,
          pay_grade: v => v === selectedItem.promotion_pay_grade
        };
      }
      if (activeData.promotion_job_family_name) {
        filters = {
          job_family_name: v => v === activeData.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name,
          pay_grade: v => v === selectedItem.promotion_pay_grade
        };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'job_grade', config, activeData);
        return filtered.shift();
      }

      return selectedItem
        ? selectedItem.promotion_job_grade || selectedItem.job_grade
        : null;
    },
    [selectedItem, data, config]
  );

  const gpp_guideline_max = useMemo(
    () => {
      const { gppLookupTable } = config;
      if (!selectedItem || !selectedItem.is_gpp_eligible) return 0.00;
      const match = gppLookupTable.find(obj => obj.quartile === selectedItem.ttc_employee_quartile && obj.country === selectedItem.country);
      return match ? match.max : 0;
    },
    [selectedItem, config]
  );

  const promoted = useMemo(
    () => {
      const { jobGrades } = config;
      return selectedItem
        ? jobGrades.findIndex(x => x === selectedItem.promotion_job_grade) > jobGrades.findIndex(x => x === selectedItem.job_grade) && selectedItem.promotion_job_family_name
          ? true
          : false
        : false
    },
    [selectedItem, config]
  );

  const demoted = useMemo(
    () => {
      const { jobGrades } = config;

      return selectedItem
        ? jobGrades.findIndex(x => x === selectedItem.promotion_job_grade) < jobGrades.findIndex(x => x === selectedItem.job_grade) && selectedItem.promotion_job_family_name
          ? true
          : false
        : false
    },
    [selectedItem, config]
  );

  const promotion_job_grade_delta = useMemo(
    () =>
      selectedItem
      ? selectedItem.promotion_job_grade - selectedItem.job_grade
        ? (selectedItem.promotion_job_grade - selectedItem.job_grade).toString()
        : null
      : null,
    [selectedItem]
  );

  const promotion_incentive_target_percentage = useMemo(
    () => {
      const { itpLookupTable } = config;
      if (!selectedItem || !selectedItem.promoted) return 0.00;
      const salesJobFamily = selectedItem.promotion_job_family_name === 'Sales' || selectedItem.promotion_job_family_name === 'Pre-Sales';
      const usaOrCanada = selectedItem.country_name === 'USA' || selectedItem.country_name === 'Canada';
      const jf = !salesJobFamily ? '*' : selectedItem.promotion_job_family_name;
      const c = !usaOrCanada || (!salesJobFamily && selectedItem.country_name !== 'USA') ? '*' : selectedItem.country_name;
      const jg = salesJobFamily ? '*' : selectedItem.promotion_job_grade;
      const match = itpLookupTable.find(obj => obj.job_family === jf && obj.country === c && obj.job_grade === jg);
      return match ? match.itp : 0;
    },
    [selectedItem, config]
  );

  const mandatory_ttc_increase_percentage = useMemo(
    () => {
      if (!selectedItem) return 0;
      if(
        !selectedItem.override_promotion_increase &&
        selectedItem.promoted &&
        selectedItem.promotion_job_family_name !== 'Sales' &&
        selectedItem.promotion_job_family_name !== 'Pre-Sales'
      ) {
        if (selectedItem.promotion_incentive_target_percentage - selectedItem.fte_incentive_target_percentage > 5) {
          return 5;
        } else {
          if (selectedItem.promotion_incentive_target_percentage - selectedItem.fte_incentive_target_percentage > 0) {
            return selectedItem.promotion_incentive_target_percentage - selectedItem.fte_incentive_target_percentage;
          }
        }
      }

      return 0;
    },
    [selectedItem]
  );

  const mandatory_ttc_increase_amount = useMemo(
    () =>
      selectedItem
      ? selectedItem.fte_annual_salary * (selectedItem.mandatory_ttc_increase_percentage/100)
      : 0,
    [selectedItem]
  );

  const apply_ti_harmonization = useMemo(
    () => {
      // default to false
      if (!selectedItem) return false;
      // force apply TI
      if (selectedItem.override_ti_harmonization === 'Apply TI Harmonization') return true;
      // force don't apply TI
      if (selectedItem.demoted) return false;
      // without override, matching conditions for TI
      if (
        (!selectedItem.override_ti_harmonization || selectedItem.override_ti_harmonization === 'Do not override') &&
        (selectedItem.interim_itp_situation !== 'Norm' || selectedItem.promoted)
      ) {
        return true;
      }
      // without override, not matching conditions for TI
      return false;
    },
    [selectedItem]
  );

  const interim_harmonization_type = useMemo(
    () => {
      if (!selectedItem) return 'No TIH';

      if (!selectedItem.apply_ti_harmonization) return 'No TIH';

      if (selectedItem.interim_itp_situation === 'Norm') return 'No TIH';
        
      if (selectedItem.interim_itp_situation === 'High') return 'Full TIH';

      if (['Sales', 'Pre-Sales'].includes(selectedItem.interim_job_family_name)) return 'Full TIH';
        
      if (['Q1', 'BM'].includes(selectedItem.ttc_employee_quartile)) return 'No TIH';
        
      return 'Partial TIH';
    },
    [selectedItem]
  );

  const interim_job_family_name = useMemo(
    () =>
      selectedItem
      ? selectedItem.promoted || selectedItem.promotion_job_family_name != selectedItem.job_family_name ? selectedItem.promotion_job_family_name : selectedItem.job_family_name
      : 0,
    [selectedItem]
  );

  const interim_job_grade = useMemo(
    () =>
      selectedItem
      ? selectedItem.promoted || selectedItem.promotion_job_family_name != selectedItem.job_family_name ? selectedItem.promotion_job_grade : selectedItem.job_grade
      : 0,
    [selectedItem]
  );

  const interim_pay_grade = useMemo(
    () => {
      return selectedItem
        ? selectedItem.promotion_pay_grade // selectedItem.promoted ? selectedItem.promotion_pay_grade : selectedItem.pay_grade
        : 0;
    },
    [selectedItem]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const get_salary_range = (country, payScaleArea, payGrade, currency) => {
    const salaryRanges = config.allSalaryRanges
    let salaryRangeFound;
    for(const salaryRange of salaryRanges){
      // eslint-disable-next-line
      if(salaryRange.country_name == country && salaryRange.grade_region == payScaleArea && salaryRange.pay_grade == payGrade && salaryRange.currency_code == currency) {
        salaryRangeFound = salaryRange
        break
      }
    }
    return salaryRangeFound
  }

  const interim_salary_range_min = useMemo(
    () => {
      // console.log('interim_salary_range_min' , selectedItem, selectedItem && selectedItem.interim_pay_grade)
      if (!selectedItem || !selectedItem.interim_pay_grade) return 0;
      // getRange({ variables: {
      //   country: selectedItem.country_name,
      //   payScaleArea: selectedItem.pay_scale_area,
      //   payGrade: selectedItem.interim_pay_grade,
      //   currency: selectedItem.currency
      // }});
      const salaryRangeData = get_salary_range(selectedItem.country_name, selectedItem.pay_scale_area, selectedItem.promotion_pay_grade, selectedItem.currency)
      return salaryRangeData ? salaryRangeData.ttc_salary_range_min : 0

      // return rangeData && rangeData.salaryRange
      // ? rangeData.salaryRange.min
      // : 0;
    },
    [selectedItem, get_salary_range]
  );

  const interim_salary_range_mid = useMemo(
    () => {
      if (!selectedItem || !selectedItem.interim_pay_grade) return 0;

      const salaryRangeData = get_salary_range(selectedItem.country_name, selectedItem.pay_scale_area, selectedItem.promotion_pay_grade, selectedItem.currency)
      return salaryRangeData ? salaryRangeData.ttc_salary_range_mid : 0

      // getRange({ variables: {
      //   country: selectedItem.country_name,
      //   payScaleArea: selectedItem.pay_scale_area,
      //   payGrade: selectedItem.interim_pay_grade,
      //   currency: selectedItem.currency
      // }});

      // return rangeData && rangeData.salaryRange
      // ? rangeData.salaryRange.mid
      // : 0;
    },
    [selectedItem, get_salary_range]
  );

  const interim_salary_range_max = useMemo(
    () => {
      if (!selectedItem || !selectedItem.interim_pay_grade) return 0;

      // getRange({ variables: {
      //   country: selectedItem.country_name,
      //   payScaleArea: selectedItem.pay_scale_area,
      //   payGrade: selectedItem.interim_pay_grade,
      //   currency: selectedItem.currency
      // }});

      // return rangeData && rangeData.salaryRange
      // ? rangeData.salaryRange.max
      // : 0;
      const salaryRangeData = get_salary_range(selectedItem.country_name, selectedItem.pay_scale_area, selectedItem.promotion_pay_grade, selectedItem.currency)
      return salaryRangeData ? salaryRangeData.ttc_salary_range_max : 0
    },
    [selectedItem, get_salary_range]
  );

  const interim_salary_range_spread = useMemo(
    () => {
      if (!selectedItem) return 0;

      return (1- selectedItem.interim_salary_range_min/selectedItem.interim_salary_range_mid)+(selectedItem.interim_salary_range_max/selectedItem.interim_salary_range_mid - 1);
    },
    [selectedItem]
  );

  const interim_fte_incentive_target_amount = useMemo(
    () =>
      selectedItem
      ? selectedItem.fte_incentive_target_amount + selectedItem.mandatory_ttc_increase_amount
      : 0,
    [selectedItem]
  );

  const interim_incentive_target_percentage = useMemo(
    () =>
      selectedItem
      ? (selectedItem.interim_fte_incentive_target_amount / selectedItem.fte_annual_salary) * 100
      : 0,
    [selectedItem]
  );

  const interim_total_increase_amount = useMemo(
    () => 
      selectedItem
      ? selectedItem.ttc_increase_amount + ( selectedItem.promoted && selectedItem.is_gpp_eligible ? selectedItem.gpp_increase_amount : 0)
      : 0,
    [selectedItem]
  );

  const interim_increase_amount_for_salincr = useMemo(
    () =>
      selectedItem
      ? selectedItem.override_ti_harmonization !== 'DO NOT apply TI Harmonization'
        ? 0
        : selectedItem.interim_total_increase_amount
      : 0,
    [selectedItem]
  );

  const interim_increase_amount_for_tih = useMemo(
    () =>
      selectedItem
      ? selectedItem.override_ti_harmonization !== 'DO NOT apply TI Harmonization'
        ? selectedItem.interim_total_increase_amount
        : 0
      : 0,
    [selectedItem]
  );

  const step_1_abs = useMemo(
    () =>
      selectedItem
      ? selectedItem.fte_annual_salary + (selectedItem.interim_increase_amount_for_salincr / (1 + (selectedItem.interim_incentive_target_percentage / 100)))
      : 0,
    [selectedItem]
  );

  const step_1_ita = useMemo(
    () =>
      selectedItem
      ? selectedItem.step_1_abs * (selectedItem.interim_incentive_target_percentage / 100)
      : 0,
    [selectedItem]
  );

  const step_1_itp = useMemo(
    () =>
      selectedItem
      ? round((selectedItem.step_1_ita / selectedItem.step_1_abs) * 100, 2)
      : 0,
    [selectedItem]
  );

  const interim_theo_itp = useMemo(
    () => {
      const { itpLookupTable } = config;
      if (!selectedItem) return 0.00;
      const salesJobFamily = selectedItem.interim_job_family_name === 'Sales' || selectedItem.interim_job_family_name === 'Pre-Sales';
      const usaOrCanada = selectedItem.country_name === 'USA' || selectedItem.country_name === 'Canada';
      const jf = !salesJobFamily ? '*' : selectedItem.interim_job_family_name;
      const c = !usaOrCanada || (!salesJobFamily && selectedItem.country_name !== 'USA') ? '*' : selectedItem.country_name;
      const jg = salesJobFamily ? '*' : selectedItem.interim_job_grade;
      const match = itpLookupTable.find(obj => obj.job_family === jf && obj.country === c && obj.job_grade === jg);
      return match ? match.itp : 0;
    },
    [selectedItem, config]
  );

  const interim_itp_situation = useMemo(
    () =>
      selectedItem
      ? selectedItem.step_1_itp === selectedItem.interim_theo_itp ? 'Norm' : selectedItem.step_1_itp > selectedItem.interim_theo_itp ? 'High' : 'Low'
      : 'Norm',
    [selectedItem]
  );

  const step_2_abs_gap = useMemo(
    () => {
      if (!selectedItem) return 0;
      if (!selectedItem.apply_ti_harmonization) return 0;
      if (selectedItem.interim_itp_situation !== 'High') return 0;
      if (!selectedItem.interim_theo_itp) return 0;

      return (selectedItem.step_1_ita / (selectedItem.interim_theo_itp / 100)) - selectedItem.step_1_abs;
    },
    [selectedItem]
  );

  const step_2_ita_gap = useMemo(
    () => {
      if (!selectedItem) return 0;
      if (!selectedItem.apply_ti_harmonization) return 0;
      if (selectedItem.interim_itp_situation !== 'Low') return 0;
      if (!selectedItem.interim_theo_itp) return 0;

      return (selectedItem.step_1_abs * (selectedItem.interim_theo_itp / 100)) - selectedItem.step_1_ita;
    },
    [selectedItem]
  );

  const interim_remainder_for_split = useMemo(
    () => {
      if (!selectedItem) return 0;
      if (!selectedItem.apply_ti_harmonization) return selectedItem.interim_increase_amount_for_tih;
      if (
        (selectedItem.step_2_abs_gap && selectedItem.interim_increase_amount_for_tih > selectedItem.step_2_abs_gap) ||
        (selectedItem.step_2_ita_gap && selectedItem.interim_increase_amount_for_tih > selectedItem.step_2_ita_gap) ||
        (!selectedItem.step_2_abs_gap && !selectedItem.step_2_ita_gap)
      ) {
        return selectedItem.interim_increase_amount_for_tih - selectedItem.step_2_ita_gap - selectedItem.step_2_abs_gap;
      } else {
        return 0;
      }
    },
    [selectedItem]
  );

  const new_fte_annual_salary = useMemo(
    () => {
      const { noDecimalCurrencies = [] } = config;
      if (!selectedItem) return 0;

      const hasNoDecimas = noDecimalCurrencies.find(c => c === selectedItem.currency);
      let value = 0;

      if (selectedItem.override_manager_proposal) {
        value = selectedItem.override_fte_annual_salary || selectedItem.new_fte_annual_salary || null;
        return hasNoDecimas ? round(value, 0) : value;
      }
      if (!selectedItem.apply_ti_harmonization) {
        value = selectedItem.fte_annual_salary + selectedItem.interim_total_increase_amount / ( 1 + (selectedItem.fte_incentive_target_percentage / 100));
        return hasNoDecimas ? round(value, 0) : value;
      }
      if (selectedItem.interim_itp_situation === 'Low') {
        if (selectedItem.interim_increase_amount_for_tih <= selectedItem.step_2_ita_gap) {
          value = selectedItem.step_1_abs;
          return hasNoDecimas ? round(value, 0) : value;
        } else {
          value = selectedItem.step_1_abs + selectedItem.interim_remainder_for_split / (1 + (selectedItem.interim_theo_itp / 100));
          return hasNoDecimas ? round(value, 0) : value;
        }
      } else {
        if (selectedItem.interim_itp_situation === 'Norm') {
          value = selectedItem.step_1_abs + selectedItem.interim_remainder_for_split / (1 + (selectedItem.interim_theo_itp / 100));
          return hasNoDecimas ? round(value, 0) : value;
        } else {
          if (selectedItem.interim_increase_amount_for_tih <= selectedItem.step_2_abs_gap) {
            value = selectedItem.step_1_abs + selectedItem.interim_increase_amount_for_tih;
            return hasNoDecimas ? round(value, 0) : value;
          } else {
            value = selectedItem.step_1_abs + selectedItem.step_2_abs_gap + selectedItem.interim_remainder_for_split / (1 + (selectedItem.interim_theo_itp / 100));
            return hasNoDecimas ? round(value, 0) : value;
          }
        }
      }
    },
    [selectedItem, config]
  );

  const new_fte_incentive_target_amount = useMemo(
    () => {
      const { noDecimalCurrencies = [] } = config;
      if (!selectedItem) return 0;

      const hasNoDecimas = noDecimalCurrencies.find(c => c === selectedItem.currency);
      let value = 0;

      if (selectedItem.override_manager_proposal) {
        value = selectedItem.override_fte_incentive_target_amount || selectedItem.new_fte_incentive_target_amount;
        return hasNoDecimas ? round(value, 0) : value;
      }
      if (!selectedItem.apply_ti_harmonization) {
        value = selectedItem.step_1_ita + (selectedItem.interim_remainder_for_split * (selectedItem.interim_theo_itp / 100)) / (1 + (selectedItem.interim_theo_itp / 100));
        // value = selectedItem.step_1_ita + (selectedItem.interim_remainder_for_split * (selectedItem.interim_incentive_target_percentage / 100)) / (1 + (selectedItem.interim_incentive_target_percentage / 100));
        return hasNoDecimas ? round(value, 0) : value;
      }
      if (selectedItem.interim_itp_situation === 'Low') {
        if (selectedItem.interim_increase_amount_for_tih <= selectedItem.step_2_ita_gap) {
          value = selectedItem.step_1_ita + selectedItem.interim_increase_amount_for_tih;
          return hasNoDecimas ? round(value, 0) : value;
        } else {
          value = selectedItem.step_1_ita + selectedItem.step_2_ita_gap + (selectedItem.interim_remainder_for_split * (selectedItem.interim_theo_itp / 100)) / (1 + (selectedItem.interim_theo_itp / 100));
          return hasNoDecimas ? round(value, 0) : value;
        }
      } else {
        if (selectedItem.interim_itp_situation === 'Norm') {
          value = selectedItem.step_1_ita + (selectedItem.interim_remainder_for_split * (selectedItem.interim_theo_itp / 100)) / (1 + (selectedItem.interim_theo_itp / 100));
          return hasNoDecimas ? round(value, 0) : value;
        } else {
          if (selectedItem.interim_increase_amount_for_tih <= selectedItem.step_2_abs_gap) {
            value = selectedItem.step_1_ita;
            return hasNoDecimas ? round(value, 0) : value;
          } else {
            value = selectedItem.step_1_ita + (selectedItem.interim_remainder_for_split * (selectedItem.interim_theo_itp / 100)) / (1 + (selectedItem.interim_theo_itp / 100));
            return hasNoDecimas ? round(value, 0) : value;
          }
        }
      }
    },
    [selectedItem, config]
  );

  const new_incentive_target_percentage = useMemo(
    () =>
      selectedItem
      ? round((selectedItem.new_fte_incentive_target_amount / selectedItem.new_fte_annual_salary ) * 100, 2)
      : 0,
    [selectedItem]
  );

  const new_fte_ttc_annual_salary = useMemo(
    () => {
      const { noDecimalCurrencies = [] } = config;
      if (!selectedItem) return 0;

      const hasNoDecimas = noDecimalCurrencies.find(c => c === selectedItem.currency);
      const value = selectedItem.new_fte_incentive_target_amount + selectedItem.new_fte_annual_salary + selectedItem.fte_addl_ttc_amount;

      return hasNoDecimas ? round(value, 0) : value;
    },
    [selectedItem, config]
  );

  const new_itp_situation = useMemo(
    () => 
      selectedItem
        ? selectedItem.new_incentive_target_percentage === selectedItem.interim_theo_itp ? 'Norm' : selectedItem.new_incentive_target_percentage > selectedItem.interim_theo_itp ? 'High' : 'Low'
        : 'Norm',
    [selectedItem]
  );

  const new_ttc_check = useMemo(
    () => {
      if (selectedItem && selectedItem.new_fte_ttc_annual_salary) {
        const summ = selectedItem.fte_ttc_annual_salary + selectedItem.mandatory_ttc_increase_amount + selectedItem.interim_total_increase_amount;
        return (Math.round(parseFloat(selectedItem.new_fte_ttc_annual_salary)*100)/100) === (Math.round(parseFloat(summ)*100)/100);
      }
      return false;
    },
    [selectedItem]
  );

  const new_ttc_compa_ratio = useMemo(
    () => {
      if (selectedItem) {
        return selectedItem.new_fte_ttc_annual_salary ? selectedItem.new_fte_ttc_annual_salary / selectedItem.interim_salary_range_mid : selectedItem.ttc_compa_ratio;
      }
      return 0.00;
    },
    [selectedItem]
  );

  const new_quartile = useMemo(
    () => {
      if (selectedItem && selectedItem.new_ttc_compa_ratio > 0) {
        if (selectedItem.new_ttc_compa_ratio < (1-(selectedItem.interim_salary_range_spread/2))) {
          return 'BM';
        }

        if (selectedItem.new_ttc_compa_ratio < (1-(selectedItem.interim_salary_range_spread/4))) {
          return 'Q1';
        }

        if (selectedItem.new_ttc_compa_ratio < 1) {
          return 'Q2';
        }

        if (selectedItem.new_ttc_compa_ratio < (1+(selectedItem.interim_salary_range_spread/4))) {
          return 'Q3';
        }

        if ( selectedItem.new_ttc_compa_ratio < (1+(selectedItem.interim_salary_range_spread/2))) {
          return 'Q4';
        }

        return 'AM';
      }
      return 'NA';
    },
    [selectedItem]
  );

  const new_incentive_plan_type = useMemo(
    () =>
      selectedItem
      // eslint-disable-next-line
      ? selectedItem.incentive_plan_type === 'YPDI' && selectedItem.job_grade == 12 && selectedItem.interim_job_grade == 13
        ? 'YMDI'
        : selectedItem.incentive_plan_type
      : '',
    [selectedItem]
  );

  const individual_budget_amount = useMemo(
    () =>
      selectedItem
      ? selectedItem.fte_ttc_annual_salary * (BUDGET_MULTIPLIER/100)
      : 0,
    [selectedItem]
  );

  const individual_spending_amount = useMemo(
    () =>
      selectedItem
      ? selectedItem.ttc_increase_amount + (selectedItem.is_gpp_eligible ? 0 : selectedItem.mandatory_ttc_increase_amount)
      : 0,
    [selectedItem]
  );

  const individual_balance_amount = useMemo(
    () =>
      selectedItem
      ? selectedItem.individual_budget_amount - selectedItem.individual_spending_amount
      : 0,
    [selectedItem]
  );

  const gpp_budget = useMemo(
    () =>
      selectedItem
      ? selectedItem.promoted && selectedItem.is_gpp_eligible
        ? selectedItem.fte_ttc_annual_salary * (selectedItem.gpp_guideline_max/100) + selectedItem.mandatory_ttc_increase_amount
        : 0
      : 0,
    [selectedItem]
  );

  const gpp_spending = useMemo(
    () =>
      selectedItem
      ? selectedItem.promoted && selectedItem.is_gpp_eligible
        ? selectedItem.gpp_increase_amount + selectedItem.mandatory_ttc_increase_amount
        : 0
      : 0,
    [selectedItem]
  );

  const gpp_balance = useMemo(
    () =>
      selectedItem
      ? selectedItem.gpp_budget - selectedItem.gpp_spending
      : 0,
    [selectedItem]
  );

  const total_spending = useMemo(
    () =>
      selectedItem
      ? selectedItem.ttc_increase_amount + (selectedItem.is_gpp_eligible ? selectedItem.gpp_spending : 0)
      : 0,
    [selectedItem]
  );

  const offcycle_budget_update = useMemo(
    () =>
      selectedItem && selectedItem.new_fte_ttc_annual_salary
      ? selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary
      : 0,
    [selectedItem]  
  );

  const payment_type = useMemo(
    () => {
      const activeData = data.request ? data.request : data;
      if (activeData.payment_type) return activeData.payment_type;
      let paymentType = filterPaymentTypes()[0];
      if(selectedItem && selectedItem.payment_type) {
        paymentType = selectedItem.payment_type
      }
      return paymentType;
    },
    [selectedItem, data]
  );

  const payment_reason = useMemo(
    () => {
      return selectedItem && selectedItem.payment_reason ? selectedItem.payment_reason : "Merit lump-sum";
    },
    [selectedItem]
  );

  const target_budget_org = useMemo(
    () => {
      const column = columns.find(c => c.name === "target_budget_org");

      const isAllowed = (column, account) => {
        if (column.allow) {
          return column.allow.find(role => account.roles.find(arole => arole === role));
        }
        return true;
      };
      // Not Allowed for role
      if (!column || !isAllowed(column, account)) return undefined;
      // Manual change
      const activeData = data.request ? data.request : data;
      if (activeData.target_budget_org) return activeData.target_budget_org;
      // Pass on calculate or default value
      return selectedItem
        ? selectedItem.target_budget_org
          ? selectedItem.target_budget_org
          : selectedItem.organization_unit_4 || selectedItem.organization_unit_3 || selectedItem.organization_unit_2 || selectedItem.organization_unit_1
        : undefined 
    },
    [selectedItem, data, columns, account]
  );

  const calcData = useMemo(
    () => {
      return {
        salary_range_spread,
        exchange_rate,
        tenure,
        payment_type,
        payment_reason,
        is_gpp_eligible,
        ttc_increase_amount,
        ttc_increase_percentage,
        gpp_increase_amount,
        gpp_increase_percentage,
        gpp_guideline_max,
        promotion_job_family_name,
        promotion_job_subfamily_name,
        promotion_job_name,
        promotion_pay_grade,
        promotion_job_grade,
        promoted,
        demoted,
        promotion_job_grade_delta,
        promotion_incentive_target_percentage,
        mandatory_ttc_increase_percentage,
        mandatory_ttc_increase_amount,
        apply_ti_harmonization,
        interim_harmonization_type,
        interim_job_family_name,
        interim_job_grade,
        interim_pay_grade,
        interim_salary_range_min,
        interim_salary_range_mid,
        interim_salary_range_max,
        interim_salary_range_spread,
        interim_fte_incentive_target_amount,
        interim_incentive_target_percentage,
        interim_total_increase_amount,
        interim_increase_amount_for_salincr,
        interim_increase_amount_for_tih,
        step_1_abs,
        step_1_ita,
        step_1_itp,
        interim_theo_itp,
        interim_itp_situation,
        step_2_abs_gap,
        step_2_ita_gap,
        interim_remainder_for_split,
        new_fte_annual_salary,
        new_fte_incentive_target_amount,
        new_incentive_target_percentage,
        new_fte_ttc_annual_salary,
        new_itp_situation,
        new_ttc_check,
        new_ttc_compa_ratio,
        new_quartile,
        new_incentive_plan_type,
        individual_budget_amount,
        individual_spending_amount,
        individual_balance_amount,
        gpp_budget,
        gpp_spending,
        gpp_balance,
        total_spending,
        offcycle_budget_update,
        override_fte_incentive_target_percentage,
        override_fte_ttc_annual_salary,
        target_budget_org,
        new_position_title,
        ttc_increase,
        ttc_increase_perc
      };
    },
    [
      salary_range_spread,
      exchange_rate,
      tenure,
      payment_type,
      payment_reason,
      is_gpp_eligible,
      ttc_increase_amount,
      ttc_increase_percentage,
      gpp_increase_amount,
      gpp_increase_percentage,
      gpp_guideline_max,
      promotion_job_family_name,
      promotion_job_subfamily_name,
      promotion_job_name,
      promotion_pay_grade,
      promotion_job_grade,
      promoted,
      demoted,
      promotion_job_grade_delta,
      promotion_incentive_target_percentage,
      mandatory_ttc_increase_percentage,
      mandatory_ttc_increase_amount,
      apply_ti_harmonization,
      interim_harmonization_type,
      interim_job_family_name,
      interim_job_grade,
      interim_pay_grade,
      interim_salary_range_min,
      interim_salary_range_mid,
      interim_salary_range_max,
      interim_salary_range_spread,
      interim_fte_incentive_target_amount,
      interim_incentive_target_percentage,
      interim_total_increase_amount,
      interim_increase_amount_for_salincr,
      interim_increase_amount_for_tih,
      step_1_abs,
      step_1_ita,
      step_1_itp,
      interim_theo_itp,
      interim_itp_situation,
      step_2_abs_gap,
      step_2_ita_gap,
      interim_remainder_for_split,
      new_fte_annual_salary,
      new_fte_incentive_target_amount,
      new_incentive_target_percentage,
      new_fte_ttc_annual_salary,
      new_itp_situation,
      new_ttc_check,
      new_ttc_compa_ratio,
      new_quartile,
      new_incentive_plan_type,
      individual_budget_amount,
      individual_spending_amount,
      individual_balance_amount,
      gpp_budget,
      gpp_spending,
      gpp_balance,
      total_spending,
      offcycle_budget_update,
      override_fte_incentive_target_percentage,
      override_fte_ttc_annual_salary,
      target_budget_org,
      new_position_title,
      ttc_increase,
      ttc_increase_perc
    ]
  );

   /* added below code for resolution for defect #16 and #17--START */
 
   function isNegative(n) {
    return ((n = +n) || 1 / n) < 0;
  }
 
  function UpdateN() {
    
    let ttc_increase_amount = document.querySelectorAll(
      'input[name="ttc_increase_amount"]'
    );
    let ttc_increase_percentage = document.querySelectorAll(
      'input[name="ttc_increase_percentage"]'
    );
    ttc_increase_amount.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    ttc_increase_percentage.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
  }
 
  /* code for resolution for defect #16 and #17--END */
 
  
  const updateEmployeeData = useCallback(
    () => {
      if (Object.keys(data).length) {
        const updateData = recordType === 'employees'
          ? {...data, ...calcData}
          : {request: {...data.request, ...calcData}};
          console.log("inside the updateEmployeeData");
        updateRecord(item, indexColumn, updateData, recordType);
      }
      /* added below code for resolution for defect #16 and #17--START */
      setTimeout(() => {
        UpdateN();
      }, 300);
  /* code for resolution for defect #16 and #17--END */ 
    },
    [data, calcData, item, indexColumn, updateRecord, recordType]
  );


  useEffect(() => {
    updateEmployeeData(data, calcData);
  }, [data, calcData, updateEmployeeData]);



 // console.log("calc data" , calcData);

  return null;
};

const mapStateToProps = state => {
  return {
    selectedEmployees: state.selectedEmployees,
    selectedRequests: state.selectedRequests,
    config: state.config,
    account: state.account
  };
};

export default connect(
  mapStateToProps,
  { updateRecord }
)(Moccalculator);
