import { dateTimeRenderer } from '../../renderers';

export default (table) => {
  let name = "created_ts";
  //let sortable = false;
  //Issue Start Issue 15
  let sortable = true;
  let rendererdate=null;
  let renduretrue=false;
  //Issue End Issue 15
  //Width issue in prod
let width = "150px";
  //width issue in prod ends

  switch (table) {
    case 'approvedRequestHistory':
         //Issue Start Issue 15
        renduretrue=true;
        rendererdate = (value, data, account, config) => dateTimeRenderer(data.request.created_ts, data.request, account, config);
         //Issue Start Issue 15
    case 'pendingRequests':
      // name = "request.created_ts";
       //Issue Start Issue 15
       renduretrue=true;
       rendererdate = (value, data, account, config) => dateTimeRenderer(data.request.created_ts, data.request, account, config);
        //Issue Start Issue 15
       break;
    case 'myRequests':
        sortable = true;
        width = "8%";
        break;
    case 'requestsHistory':
        sortable = true;
        break;
    default:
        break;
  }

  return {
      type: "datetime",
      name,
      label: "Date",
      visible: true,
      sortable,
       //Issue Start Issue 15
      renderer: renduretrue ? rendererdate :  dateTimeRenderer,
       //Issue Start Issue 15
      width
    };
};