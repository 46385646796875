import React from "react";
import { connect } from "react-redux";
import { InputField, CellWrap, SymbolSpan } from "../..";
/**Release 2 - Our Talent - ref add */
import { apolloClient, apolloProxyClient } from "../../../index";

import AsyncSelect from "react-select/async";
import { HM_SUGGEST } from "../../../graphql";
/**Release 2 - Our Talent - ref add ends */

const Cell = (props) => {
  const {
    column,
    rowData,
    onChange,
    keepLocalCurrency,
    config,
    cellEditFunc,
    background,
    changeData,
  } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;

  const { placeholder, ...otherProps } = column;

  const test = (data) => {
    if (typeof cellEditFunc != "string") {
      cellEditFunc(data);
    }
  };

  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  //release 2 budgetmanagement - organisation click data pass - ends

  /**Release 2 - Our Talent - Data passage for scenarios and  people picker starts */
  const styles1 = {
    width: "100%",
    marginLeft: "0.5%",
    zIndex: "99999",
    position: "relative",
  };
  const FetchPeople = (input) => {
    if (input.length >= 3) {
      //console.log(input);

      return new Promise((resolve, reject) => {
        httpClient
          .query({
            query: HM_SUGGEST,
            variables: {
              where: { searchvalue: input, module: "internalhire" },
              limit: 15,
              offset: 0,
            },
          })
          .then((response) => {
            //console.log(response.data);

            if (response.data && response.data.hiringmanager) {
              resolve(
                response.data.hiringmanager.items.map((a) => ({
                  label:
                    a.full_name +
                    " (" +
                    a.nokia_id +
                    ") (" +
                    a.email_work +
                    ")",
                  value: a.pers_no,
                }))
              );
            }
          })
          .catch((err) => console.error(err));
      });
    }
  };

  /**Release 2 - Our Talent - Data passage for scenarios test starts */
  const columncheck = () => {
    //---------------------enablement and diabale of fields based on SIP - release 2. Covered each scenarios separately to accomidate future changes and UAT changes--------------------
    const casethree = [
      "Sales to Non-Sales Promo NO",
      "Sales to Non-Sales Promo YES",
      "Pre-Sales to Non-Sales Promo NO",
      "Pre-Sales to Non-Sales Promo YES",
      "Sales to Pre-Sales Promo YES",
      "Sales to Pre-Sales Promo NO",
    ];
    /* Release 3.2 - 19.1 Starts */    
    const incentive_plan_type = rowData && rowData.incentive_plan_type ? rowData.incentive_plan_type : (rowData && rowData.request && rowData.request.for && rowData.request.for.incentive_plan_type) ? rowData.request.for.incentive_plan_type : "";
    /* Release 3.2 - 19.1 Ends */
    if (column.section && column.section === "internalHireSimulation") {
      column.editable = false;
    } else {
      if (isEmpty(changeData)) {
        if (
          column.name.replace("request.", "") == "ttc_increase_amount" &&
          column.colFrom == "internalHire"
        ) {
          column.editable = true;
        }
        if (
          column.name.replace("request.", "") == "ttc_increase_percentage" &&
          column.colFrom == "internalHire"
        ) {
          column.editable = true;
        }
        if (
          column.name.replace("request.", "") ==
            "case_3_12_month_actual_incentive_amount_fte" &&
          column.colFrom == "internalHire"
        ) {
          column.disabled = true;
        }
        if (
          column.name.replace("request.", "") == "new_fte_annual_salary" &&
          column.colFrom == "internalHire"
        ) {
          column.editable =
            /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
              ? true
              : false; /* Release 3.2 - 19.1 Ends */
        }
        if (
          column.name.replace("request.", "") ==
            "new_fte_incentive_target_amount" &&
          column.colFrom == "internalHire"
        ) {
          column.editable =
            /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
              ? true
              : false; /* Release 3.2 - 19.1 Ends */
        }
        if (
          column.name.replace("request.", "") ==
            "override_transition_allowance_local_currency" &&
          column.colFrom == "internalHire"
        ) {
          column.disabled = true;
        }
      }
      if (
        isEmpty(changeData) &&
        (rowData.request
          ? rowData.request.request_type == "internalhire"
            ? true
            : false
          : false)
      ) {
        if (rowData.request.is_sip) {
          if (column.name.replace("request.", "") == "ttc_increase_amount") {
            column.editable = false;
          }
          if (
            column.name.replace("request.", "") == "ttc_increase_percentage"
          ) {
            column.editable = false;
          }
          if (
            column.name.replace("request.", "") ==
            "case_3_12_month_actual_incentive_amount_fte"
          ) {
            column.disabled = casethree.includes(rowData.request.sip_case)
              ? false
              : true;
          }
          if (column.name.replace("request.", "") == "new_fte_annual_salary") {
            column.editable = true;
          }
          if (
            column.name.replace("request.", "") ==
            "new_fte_incentive_target_amount"
          ) {
            column.editable = true;
          }
          if (
            column.name.replace("request.", "") ==
            "override_transition_allowance_local_currency"
          ) {
            column.disabled = casethree.includes(rowData.request.sip_case)
              ? false
              : true;
          }
        } else {
          if (
            column.name.replace("request.", "") == "ttc_increase_amount" &&
            column.colFrom == "internalHire"
          ) {
            column.editable = true;
          }
          if (
            column.name.replace("request.", "") == "ttc_increase_percentage" &&
            column.colFrom == "internalHire"
          ) {
            column.editable = true;
          }
          if (
            column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte" &&
            column.colFrom == "internalHire"
          ) {
            column.disabled = true;
          }
          if (
            column.name.replace("request.", "") == "new_fte_annual_salary" &&
            column.colFrom == "internalHire"
          ) {
            column.editable =
              /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
                ? true
                : false; /* Release 3.2 - 19.1 Ends */
          }
          if (
            column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount" &&
            column.colFrom == "internalHire"
          ) {
            column.editable =
              /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
                ? true
                : false; /* Release 3.2 - 19.1 Ends */
          }
          if (
            column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency" &&
            column.colFrom == "internalHire"
          ) {
            column.disabled = true;
          }
        }
      } else if (
        isEmpty(changeData) &&
        (rowData.request
          ? false
          : rowData.request_type == "internalhire"
          ? true
          : false)
      ) {
        if (rowData.is_sip) {
          if (column.name.replace("request.", "") == "ttc_increase_amount") {
            column.editable = false;
          }
          if (
            column.name.replace("request.", "") == "ttc_increase_percentage"
          ) {
            column.editable = false;
          }
          if (
            column.name.replace("request.", "") ==
            "case_3_12_month_actual_incentive_amount_fte"
          ) {
            column.disabled = casethree.includes(rowData.sip_case)
              ? false
              : true;
          }
          if (column.name.replace("request.", "") == "new_fte_annual_salary") {
            column.editable = true;
          }
          if (
            column.name.replace("request.", "") ==
            "new_fte_incentive_target_amount"
          ) {
            column.editable = true;
          }
          if (
            column.name.replace("request.", "") ==
            "override_transition_allowance_local_currency"
          ) {
            column.disabled = casethree.includes(rowData.sip_case)
              ? false
              : true;
          }
        } else {
          if (
            column.name.replace("request.", "") == "ttc_increase_amount" &&
            column.colFrom == "internalHire"
          ) {
            column.editable = true;
          }
          if (
            column.name.replace("request.", "") == "ttc_increase_percentage" &&
            column.colFrom == "internalHire"
          ) {
            column.editable = true;
          }
          if (
            column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte" &&
            column.colFrom == "internalHire"
          ) {
            column.disabled = true;
          }
          if (
            column.name.replace("request.", "") == "new_fte_annual_salary" &&
            column.colFrom == "internalHire"
          ) {
            column.editable =
              /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
                ? true
                : false; /* Release 3.2 - 19.1 Ends */
          }
          if (
            column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount" &&
            column.colFrom == "internalHire"
          ) {
            column.editable =
              /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
                ? true
                : false; /* Release 3.2 - 19.1 Ends */
          }
          if (
            column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency" &&
            column.colFrom == "internalHire"
          ) {
            column.disabled = true;
          }
        }
      } else {
        let tempChangeData = changeData.request
          ? { ...changeData.request, request_type: "internalhire" }
          : { ...changeData, request_type: "internalhire" };
        if (
          tempChangeData.promotion_job_family_name &&
          tempChangeData.request_type == "internalhire"
        ) {
          /**Set the default one */

          let promo_NS = "";
          let temRowData = rowData.request
            ? { ...rowData.request, ...rowData.request.for }
            : { ...rowData };
          if (
            temRowData.job_family_name !== "Pre-Sales" &&
            temRowData.job_family_name !== "Sales"
          ) {
            temRowData.job_family_name = "Non-Sales";
          }
          if (
            tempChangeData.promotion_job_family_name !== "Pre-Sales" &&
            tempChangeData.promotion_job_family_name !== "Sales"
          ) {
            promo_NS = "Non-Sales";
          }

          //sscenario 1,4,7
          if (promo_NS == temRowData.job_family_name) {
            //console.log("column log check", column);
            //no change in configuration
            if (column.name.replace("request.", "") == "ttc_increase_amount") {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") == "ttc_increase_percentage"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte"
            ) {
              column.disabled = true;
            }
            if (
              column.name.replace("request.", "") == "new_fte_annual_salary"
            ) {
              column.editable =
                /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
                  ? true
                  : false; /* Release 3.2 - 19.1 Ends */
            }
            if (
              column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount"
            ) {
              column.editable =
                /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
                  ? true
                  : false; /* Release 3.2 - 19.1 Ends */
            }
            if (
              column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency"
            ) {
              column.disabled = true;
            }
          } else if (
            tempChangeData.promotion_job_family_name ==
            temRowData.job_family_name
          ) {
            if (column.name.replace("request.", "") == "ttc_increase_amount") {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") == "ttc_increase_percentage"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte"
            ) {
              column.disabled = true;
            }
            if (
              column.name.replace("request.", "") == "new_fte_annual_salary"
            ) {
              column.editable =
                /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
                  ? true
                  : false; /* Release 3.2 - 19.1 Ends */
            }
            if (
              column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount"
            ) {
              column.editable =
                /* Release 3.2 - 19.1 Starts */ incentive_plan_type === "YLIP"
                  ? true
                  : false; /* Release 3.2 - 19.1 Ends */
            }
            if (
              column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency"
            ) {
              column.disabled = true;
            }
          }
          //scenario 2
          else if (
            tempChangeData.promotion_job_family_name == "Pre-Sales" &&
            temRowData.job_family_name == "Sales"
          ) {
            if (column.name.replace("request.", "") == "ttc_increase_amount") {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") == "ttc_increase_percentage"
            ) {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte"
            ) {
              column.disabled = false;
            }
            if (
              column.name.replace("request.", "") == "new_fte_annual_salary"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency"
            ) {
              column.disabled = false;
            }
          }
          //scenario 3
          else if (
            tempChangeData.promotion_job_family_name !== "Sales" &&
            tempChangeData.promotion_job_family_name !== "Pre-Sales" &&
            temRowData.job_family_name == "Sales"
          ) {
            if (column.name.replace("request.", "") == "ttc_increase_amount") {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") == "ttc_increase_percentage"
            ) {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte"
            ) {
              column.disabled = false;
            }
            if (
              column.name.replace("request.", "") == "new_fte_annual_salary"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency"
            ) {
              column.disabled = false;
            }
          }
          //scenario 5
          else if (
            tempChangeData.promotion_job_family_name == "Sales" &&
            temRowData.job_family_name == "Pre-Sales"
          ) {
            if (column.name.replace("request.", "") == "ttc_increase_amount") {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") == "ttc_increase_percentage"
            ) {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte"
            ) {
              column.disabled = true;
            }
            if (
              column.name.replace("request.", "") == "new_fte_annual_salary"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency"
            ) {
              column.disabled = true;
            }
          }
          //scenario 6
          else if (
            tempChangeData.promotion_job_family_name !== "Sales" &&
            tempChangeData.promotion_job_family_name !== "Pre-Sales" &&
            temRowData.job_family_name == "Pre-Sales"
          ) {
            if (column.name.replace("request.", "") == "ttc_increase_amount") {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") == "ttc_increase_percentage"
            ) {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte"
            ) {
              column.disabled = false;
            }
            if (
              column.name.replace("request.", "") == "new_fte_annual_salary"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency"
            ) {
              column.disabled = false;
            }
          }
          //scenario 8
          else if (
            tempChangeData.promotion_job_family_name == "Sales" &&
            temRowData.job_family_name == "Non-Sales"
          ) {
            if (column.name.replace("request.", "") == "ttc_increase_amount") {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") == "ttc_increase_percentage"
            ) {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte"
            ) {
              column.disabled = true;
            }
            if (
              column.name.replace("request.", "") == "new_fte_annual_salary"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency"
            ) {
              column.disabled = true;
            }
          }
          //scenario 9
          else if (
            tempChangeData.promotion_job_family_name == "Pre-Sales" &&
            temRowData.job_family_name == "Non-Sales"
          ) {
            if (column.name.replace("request.", "") == "ttc_increase_amount") {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") == "ttc_increase_percentage"
            ) {
              column.editable = false;
            }
            if (
              column.name.replace("request.", "") ==
              "case_3_12_month_actual_incentive_amount_fte"
            ) {
              column.disabled = true;
            }
            if (
              column.name.replace("request.", "") == "new_fte_annual_salary"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "new_fte_incentive_target_amount"
            ) {
              column.editable = true;
            }
            if (
              column.name.replace("request.", "") ==
              "override_transition_allowance_local_currency"
            ) {
              column.disabled = true;
            }
          }
        }
      }
    }
  };
  /**Release 2 - Our Talent - Data passage for scenarios test ends */

  const columnValue = (args) => {
    const { column, rowData, accountConfig, config } = args;

    const acc = keepLocalCurrency
      ? { forceCurrency: rowData.currency }
      : accountConfig;
/* Release DEM0069359:Add additional data for TAP users - Part 1 Start */
      if (column.name === "building_name") {
        if (column.section === "employeehistoryaddresssection") {
        return rowData.building_id +" - " +rowData.building_name;
       }
      }

      if (column.name === "work_state_province") {
        if (column.section === "employeehistoryaddresssection") {
        return (rowData.work_state_province +" - " +rowData.state_fullname);
       }
      }

  /* Release DEM0069359:Add additional data for TAP users - Part 1 Start */    
/* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
if (column.name === "Contract_type_name") {
  if (column.section === "latestInformation") {
    if(rowData.Contract_end_date!==null){
      return rowData.Contract_type_name +"  " + column.renderer(rowData.Contract_end_date, rowData, acc, config) || "";
    }else{
       return rowData.Contract_type_name;
    }
    
 }
}
if (column.name === "Reason_for_Addl_Pay") {
  if (column.section === "referralAward") {
  return rowData.Reason_for_Addl_Pay +" - " +rowData.Reason_for_Addl_Pay_Text;
 }
}
if (column.name === "Date_To") {
  if (column.section === "actionsIT0000") {
      if(rowData.Date_To===null )
      { 
        return '12/31/9999';  
    }
      else{
        if (column.renderer) {
          return column.renderer(rowData.Date_To, rowData, acc, config) || "";
        }
        //return rowData.Date_To;
      }
  
 }
}
/* Release DEM0069362:Add additional data for TAP users - Part 1 Start */

    /*  FOR FIXING DEFECT 1 STARTS  */
    if (column.name === "total_increaseed_amount") {
      if (column.section === "My Request Page") {
        let firstAmt = rowData.mandatory_ttc_increase_amount;
        let secondAmt = rowData.ttc_increase_amount;
        let finalAmt = firstAmt + secondAmt;
        if (column.renderer) {
          return column.renderer(finalAmt, rowData, acc, config) || "";
        }
      } else if (column.section === "Edit Compensation Page") {
        let firstAmt = rowData.request.mandatory_ttc_increase_amount;
        let secondAmt = rowData.request.ttc_increase_amount;
        let finalAmt = firstAmt + secondAmt;
        if (column.renderer) {
          return column.renderer(finalAmt, rowData, acc, config) || "";
        }
      }
    } else if (column.name === "total_increaseed_percent") {
    /*  FOR FIXING DEFECT 1 ENDS  */
    /**CHANGE REQUEST -  Add total increase percentage starts */
      if (column.section === "My Request Page") {
        // let firstAmt = rowData.mandatory_ttc_increase_percentage;
        // let secondAmt = rowData.ttc_increase_percentage;
        // let finalAmt = firstAmt + secondAmt;
        if (rowData.request_type === "onetime" 
        /* Release 3.2 - 1.1 - Starts */
        || rowData.request_type === "businessexcellenceaward"
        /* Release 3.2 - 1.1 - Ends */
        ) {
          let finalAmt = rowData.ttc_increase_percentage;
          return column.renderer(finalAmt, rowData, acc, config) || "";
        } else {
          let finalAmt =
            ((rowData.new_fte_ttc_annual_salary -
              rowData.for.fte_ttc_annual_salary) /
              rowData.for.fte_ttc_annual_salary) *
            100;
          if (column.renderer) {
            return column.renderer(finalAmt, rowData, acc, config) || "";
          }
        }
      } else if (column.section === "Approved Requests Page") {
        // let firstAmt = rowData.request.mandatory_ttc_increase_percentage;
        // let secondAmt = rowData.request.ttc_increase_percentage;
        // let finalAmt = firstAmt + secondAmt;
        if (rowData.request.request_type === "onetime"
        /* Release 3.2 - 1.1 - Starts */
        || rowData.request.request_type === "businessexcellenceaward"
        /* Release 3.2 - 1.1 - Ends */
        ) {
          let finalAmt = rowData.request.ttc_increase_percentage;
          return column.renderer(finalAmt, rowData, acc, config) || "";
        } else {
          let finalAmt =
            ((rowData.request.new_fte_ttc_annual_salary -
              rowData.request.for.fte_ttc_annual_salary) /
              rowData.request.for.fte_ttc_annual_salary) *
            100;

          if (column.renderer) {
            return column.renderer(finalAmt, rowData, acc, config) || "";
          }
        }
      } else if (column.section === "Pending Requests Page") {
        // let firstAmt = rowData.request.mandatory_ttc_increase_percentage;
        //let secondAmt = rowData.request.ttc_increase_percentage;
        //let finalAmt = firstAmt + secondAmt;
        if (rowData.request.request_type === "onetime"
        /* Release 3.2 - 1.1 - Starts */
        || rowData.request.request_type === "businessexcellenceaward"
        /* Release 3.2 - 1.1 - Ends */
        ) {
          let finalAmt = rowData.request.ttc_increase_percentage;
          return column.renderer(finalAmt, rowData, acc, config) || "";
        } else {
          let finalAmt =
            ((rowData.request.new_fte_ttc_annual_salary -
              rowData.request.for.fte_ttc_annual_salary) /
              rowData.request.for.fte_ttc_annual_salary) *
            100;

          if (column.renderer) {
            return column.renderer(finalAmt, rowData, acc, config) || "";
          }
        }
      } else if (column.section === "History Requests Page") {
        //let firstAmt = rowData.mandatory_ttc_increase_percentage;
        //let secondAmt = rowData.ttc_increase_percentage;
        //let finalAmt = firstAmt + secondAmt;
        if (rowData.request_type === "onetime"
        /* Release 3.2 - 1.1 - Starts */
        || rowData.request_type === "businessexcellenceaward"
        /* Release 3.2 - 1.1 - Ends */
        ) {
          let finalAmt = rowData.ttc_increase_percentage;
          return column.renderer(finalAmt, rowData, acc, config) || "";
        } else {
          let finalAmt =
            ((rowData.new_fte_ttc_annual_salary -
              rowData.for.fte_ttc_annual_salary) /
              rowData.for.fte_ttc_annual_salary) *
            100;

          if (column.renderer) {
            return column.renderer(finalAmt, rowData, acc, config) || "";
          }
        }
      }
    } else if (column.name === "is_active") {
    /**CHANGE REQUEST -  Add total increase percentage Ends */
    /*  FIXING DELEGATION ISSUE */
      if (
        column.section === "Delegation List Page" ||
        column.section === "Delegation Revoke Page"
      ) {
        if (rowData.is_active == "1") return "Active";
        else if (rowData.is_active == "2") return "Inactive";
        else if (rowData.is_active == "0") return "Pending";
      }
    } else if (column.name === "approver_type") {
      if (
        column.section === "Delegation List Page" ||
        column.section === "Delegation Revoke Page"
      ) {
        if (rowData.approver_type == "bcb") {
          return "Business C&B";
        } else if (rowData.approver_type == "hr1") {
          return "HRBP N – 1";
        } else if (rowData.approver_type == "hr2") {
          return "HRBP N – 2";
        } else if (rowData.approver_type == "hr3") {
        /*  HR3_ROLE STARTS */
          return "HRBP N – 3";
        } else if (rowData.approver_type == "supermanager") {
        /*  HR3_ROLE ENDS */
          return "Second Line managers (SLM)";
        } else if (rowData.approver_type == "gct") {
          return "Global Compensation";
        } else if (rowData.approver_type == "gtap") {
          return "Global TAP";
        } else if (rowData.approver_type == "rtap") {
          return "Regional TAP";
        }
      }
    }
    //Release 2 people picker defult
    else if (column.label === "Hiring Manager") {
      if (column.section === "EditInternalRequest") {
        return {
          label:
            rowData.request.hmanager.full_name +
            " (" +
            rowData.request.hmanager.nokia_id +
            ") (" +
            rowData.request.hmanager.email_work +
            ")",
          value: rowData.request.hmanager.pers_no,
        };
      }
    }

    const value = column.name.split(".").reduce((o, i) => o[i], rowData);
    //release 2 budget management link in org. name
    if (column.renderer) {
      if (
        column.name == "organization_unit_name" &&
        column.section == "Budget Management"
      ) {
        return column.renderer(value, rowData, test, acc, config) || "";
      } else {
        return column.renderer(value, rowData, acc, config) || "";
      }
    }
    return value || columnDefault(args);
  };

  const columnPlaceholder = (args) => {
    const { column, rowData, accountConfig, config } = args;
    const acc = keepLocalCurrency
      ? { forceCurrency: rowData.currency }
      : accountConfig;
    const value = column.name.split(".").reduce((o, i) => o[i], rowData);
    if (column.placeholder) {
      return column.placeholder(value, rowData, acc, config) || "";
    }
    return null;
  };

  const columnAfter = (args) => {
    const { column, rowData, accountConfig, config } = args;
    const acc = keepLocalCurrency
      ? { forceCurrency: rowData.currency }
      : accountConfig;
    const value = column.name.split(".").reduce((o, i) => o[i], rowData);
    if (column.after) {
      return column.after(value, rowData, acc, config) || "";
    }
    return "";
  };

  const columnBefore = (args) => {
    const { column, rowData, accountConfig, config } = args;
    const acc = keepLocalCurrency
      ? { forceCurrency: rowData.currency }
      : accountConfig;
    const value = column.name.split(".").reduce((o, i) => o[i], rowData);
    if (column.before) {
      return column.before(value, rowData, acc, config) || "";
    }
    return "";
  };

  const columnDefault = (args) => {
    const { column, rowData } = args;
    const value = column.name.split(".").reduce((o, i) => o[i], rowData);
    if (column.defaultValue) {
      return column.defaultValue(value, rowData) || "";
    }
    return "";
  };

  const columnOptions = column.options
    ? typeof column.options === "function"
      ? column.options(column, config, rowData)
      : column.options
    : [];

  const isEditable = () =>
    typeof column.editable === "function"
      ? column.editable(column, rowData) || false
      : column.editable;
  const style_budget_available = {
    background: "#a9f3a9"
  }
  const style_budget_in_progress = {
    background: "#f1f172"
  }
  const style_budget_spent = {
    background: "#ec9595"
  }
  const style_cellwrap_default = {
    background: background
  }
  const style_award_message = {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    maxWidth: '180px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    background: background,
  }
  const style_nominator_email_address = {
    display: 'inline-block',
    background: background,
  }
  return (
    <CellWrap
      {...otherProps}
      key={`cell-${column.name}`}
      style={
        column.name == "budget_available"
          ? style_budget_available
          : column.name == "budget_in_progress"
          ? style_budget_in_progress
          : column.name == "budget_spent"
          ? style_budget_spent
          : column.name == "award_message" || column.name == "request.award_message"
          ? style_award_message
          : column.name == 'nominator_email_address' || column.name == 'request.nominator_email_address'
          ? style_nominator_email_address
          : style_cellwrap_default
      }
      title={column.name == "award_message" ? columnValue(props) : ''}
    >
      {columncheck()}
      {!isEditable() && (
        <>
          {column.before && columnBefore(props)}
          {columnValue(props)}
          {column.after && columnAfter(props)}
        </>
      )}
      {isEditable() && (
        <>
          {(!column.inputType || column.inputType === "text") && (
            <InputField
              debounceTimeout={500}
              key={`input-${column.name}`}
              name={column.name}
              type="text"
              onChange={onChange(props)}
              value={columnValue(props)}
              placeholder={columnPlaceholder(props)}
              /**release 2 Our talent */
              disabled={props.column.disabled ? "disabled" : ""}
            />
          )}
          {column.inputType === "number" && (
            <>
              {column.before && (
                <SymbolSpan>
                  {column.before(rowData[column.name], rowData)}
                </SymbolSpan>
              )}
              <InputField
                debounceTimeout={1200}
                key={`input-${column.name}`}
                name={column.name}
                type="number"
                step="1.00"
                onChange={onChange(props)}
                value={columnValue(props)}
                placeholder={columnPlaceholder(props)}
                /**release 2 Our talent */
                disabled={props.column.disabled ? "disabled" : ""}
              />
              {column.after && (
                <SymbolSpan>
                  {column.after(rowData[column.name], rowData)}
                </SymbolSpan>
              )}
            </>
          )}
          {column.inputType === "checkbox" && (
            <input
              key={`input-${column.name}`}
              name={column.name}
              type="checkbox"
              onChange={onChange(props)}
              checked={columnValue(props)}
            />
          )}
          {column.inputType === "select" && (
            <select
              key={`select-${column.name}`}
              name={column.name}
              onChange={onChange(props)}
              value={columnValue(props)}
            >
              {columnOptions.map((opt) => {
                return (
                  <React.Fragment key={Math.random()}>
                    {opt.value && (
                      <option value={opt.value}>{opt.label}</option>
                    )}
                    {!opt.value && <option value={opt}>{opt}</option>}
                  </React.Fragment>
                );
              })}
            </select>
          )}
          {column.inputType === "peoplepicker" && (
            <div style={styles1}>
              <AsyncSelect
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 999999999999999999,
                  }),
                }}
                loadOptions={FetchPeople}
                onChange={onChange(props)}
                defaultValue={columnValue(props)}
                placeholder="Type at least three letters"
                className="select"
              />
            </div>
          )}

          {(!column.inputType || column.inputType === "date") && (
            <InputField
              debounceTimeout={500}
              key={`input-${column.name}`}
              name={column.name}
              type="date"
              onChange={onChange(props)}
              value={columnValue(props)}
              placeholder={columnPlaceholder(props)}
              /**release 2 Our talent */
              disabled={props.column.disabled ? "disabled" : ""}
            />
          )}
        </>
      )}
    </CellWrap>
  );
};

const mapStateToProps = (state) => {
  return {
    accountConfig: state.account,
    config: state.config,
  };
};

export default connect(mapStateToProps)(Cell);
