import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { showModal, toggleShowInEur, updateFilters } from "../actions";
import { apolloClient, apolloProxyClient } from "../index";
import {
  GlobalMessages,
  Header,
  Budget,
  BOBudget,
  Table,
  TableHeadingWrap,
  TableActionsWrap,
  TableAction,
  SelectFilter,
} from "../components";
import { columns } from "../util/config";
import { canRaiseRequest } from "../util/helper";
import { useMutation } from "@apollo/react-hooks";
import { moc_requests, requests, SAVE_EXPORT_FILTERS } from "../graphql";
import {
  unselectAllRecord,
} from "../actions";

const MassOffCycle = (props) => {
  const {
    selectedEmployees,
    messages,
    account,
    toggleShowInEur,
    config,
    showModal,
    mocFilters,
    updateFilters,
    unselectAllRecord,
  } = props; // DEFECT No. 14
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { forceCurrency, pers_no, roles, accessibles } = account;
  console.log("roles are", roles);
  const isGct = roles.includes("gct");
  const isManager = roles.includes("manager");
  const isSuperManager = roles.includes("supermanager");
  const isAdmin = roles.includes("admin"); 

  const isHr =
    roles.includes("hr1") ||
    roles.includes("hr2") ||
    roles.includes("hr3") ||
    roles.includes("rcb") ||
    roles.includes("chr") ||
    roles.includes("hrc") ||
    roles.includes("bcb") ||
    roles.includes("rhr");
  
  /* Release 3.2 - 1.1 - Starts */
  const isBusinessOwner = roles.includes("business_owner");
  const isOnlyBusinessOwner =
    !roles.includes("manager") &&
    !roles.includes("supermanager") &&
    !roles.includes("bcb") &&
    !roles.includes("hr1") &&
    !roles.includes("hr2") &&
    !roles.includes("hr3") &&
    !roles.includes("chr") &&
    !roles.includes("admin") &&
    !roles.includes("rcb") &&
    !roles.includes("hrc") &&
    !roles.includes("rhr") &&
    roles.includes('business_owner');
  /* Release 3.2 - 1.1 - Ends */
  const ddlRequestTypeOptions = isOnlyBusinessOwner ?
  [
    {
      //columnsString: JSON.stringify(columns.admin_con),
      text: "Business Excellence Award",
      value: "businessexcellenceaward",
    },
  ]
  : 
  [
    {
      text: "Compensation Change",
      value: "compensation",
    },
    {
      //columnsString: JSON.stringify(columns.admin_gpp),
      text: "One-time Payment",
      value: "onetime",
    },
    {
      //columnsString: JSON.stringify(columns.admin_con),
      text: "Business Excellence Award",
      value: "businessexcellenceaward",
    },
  ];
  /* Release 3.2 - 1.1 - Ends */

  const [tableQuery, setTableQuery] = useState(
    isGct || isAdmin
      ? "employees"
      : isHr
      ? "employeesForHr"
      : "employeesForManager"
  );
  const [tableWhere, setTableWhere] = useState(
    isHr || isGct || isAdmin ? {} : { line_manager_pers_no: { eq: pers_no } }
  );
  const filterOptions =
    isGct || isAdmin || isHr
      ? ["All", "Direct Subordinates"]
      : ["All", "Direct Subordinates", "2 Below"];
  const hasSelectedEmployees = selectedEmployees.length > 0;
  const hasGlobalMessages = messages.length > 0;
  const hasBudgetVisibility =
    (accessibles && accessibles.budgetOrgs.length > 0) || isGct || isAdmin;
  const visible = isGct || isAdmin || isSuperManager || isHr;
  const hasOnlyDirectSelected = isSuperManager
    ? canRaiseRequest(selectedEmployees, account.pers_no)
    : true;
  //MOC Hari changes for review and submit - Starts
  /* Release 3.2 - 1.1 - Starts*/
  const [/* Release 3.2 - 1.1 - Starts*/currentRequestType/* Release 3.2 - 1.1 - Ends*/, setCurrentRequestType] = useState(ddlRequestTypeOptions[0].value);
  /* Release 3.2 - 1.1 - Ends*/
  const [hasPendingImports, setPendingImport] = useState(false);
  const [CurrentPendingData, setPendingData] = useState("");
  const [ImportLoad, setImportLoad] = useState(false);  
  const [budgetRefreshKey, setBudgetRefreshKey] = useState(2);
  useEffect(() => {
    httpClient
      .query({
        query: moc_requests,
        fetchPolicy: "network-only",
        variables: {
          where: {
            request_type: { eq: /* Release 3.2 - 1.1 - Starts*/currentRequestType/* Release 3.2 - 1.1 - Ends*/ },
            import_status: { eq: "pending" },
            requester_pers_no: { eq: account.pers_no.toString() },
            module: "massoffcycle",
          },

          offset: 0,
          limit: 20,
        },
      })
      .then(
        (response) => {
          if (response.data && response.data.moc_requests) {
            if (response.data.moc_requests.totalCount > 0) {
              setPendingImport(true);
              setPendingData(response.data);
            } else {
              setPendingImport(false);
              setPendingData("");
            }
          }
        },
        (error) => {
          console.log("Graph QL error: " + error.message);
          setPendingImport(false);
          setPendingData("");
        }
      );
      /* Release 3.2 - 1.1 - Starts */
      if (messages.length > 0) {
        setBudgetRefreshKey(Math.floor(Math.random() * 87));
      }
      /* Release 3.2 - 1.1 - Ends */
  }, [/* Release 3.2 - 1.1 - Starts*/currentRequestType/* Release 3.2 - 1.1 - Ends*/, ImportLoad,  messages]);
  //MOC Hari changes for review and submit - Ends
  const [saveExport] = useMutation(SAVE_EXPORT_FILTERS);

  const reloadReview = () => {
    setImportLoad(ImportLoad ? false : true);
  };

  /* Release 3.2 - 1.1 - Starts */
  const onSelectRequestTypeChange = (event) => {
    setCurrentRequestType(event.target.value);
  };
  /* Release 3.2 - 1.1 - Ends */

  const onSelectChange = (event) => {
    const filter = event.target.value;
    console.log("filter", filter);

    switch (filter) {
      case "Direct Subordinates":
        if (isManager || isHr || isAdmin) {
          setTableQuery("managerDirectSubOrdinates");
          setTableWhere({ line_manager_pers_no: { eq: pers_no } });
        }
        break;
      case "2 Below":
        if (isManager) {
          setTableQuery("managerSecondSubOrdinates");
          setTableWhere({ line_manager_pers_no: { eq: pers_no } });
        }
        break;
      default:
        console.log(
          "isGct:",
          isGct,
          "isAdmin:",
          isAdmin,
          "isHr:",
          isHr,
          "isManager:",
          isManager
        );
        if (isGct || isAdmin) {
          setTableQuery("employees");
          /**release 2 - dropdown change issue start */
          setTableWhere({});
          /**release 2 - dropdown change issue ends */
          break;
        }
        if (isHr) {
          setTableQuery("employeesForHr");
          /**release 2 - dropdown change issue  starts*/
          setTableWhere({});
          /**release 2 - dropdown change issue ends */
          break;
        }
        if (isManager) {
          setTableQuery("employeesForManager");
          setTableWhere({ line_manager_pers_no: { eq: pers_no } });
          break;
        }
    }
  };

  const toggleDisplayCurrency = (event) => {
    toggleShowInEur(forceCurrency ? null : "EUR");
  };

  useEffect(() => {
    console.log("use effect filters", mocFilters);
    //People other that isHr || isGct || isAdmin should have fixed linemanager
    //prepareFilterQuery();
    //{line_manager_pers_no: {eq: pers_no}}
    if (Object.keys(mocFilters.mocFilters).length > 1) {
      setTableWhere({ ...prepareFilterQuery() });
      //setTableWhere({line_manager_pers_no: {eq: pers_no},job_grade:{in:['09']}});
    } else {
      setTableWhere(
        isHr || isGct || isAdmin
          ? {}
          : { line_manager_pers_no: { eq: pers_no } }
      );
    }
  }, [mocFilters]);

  /**MOC Hari Changes starts */
  const calculateData = (event) => {
    /* Release 3.2 - 1.1 - Starts*/
    unselectAllRecord("employees");
    showModal({
      component: currentRequestType === "onetime" ? "mocotsubmit" : currentRequestType === "compensation" ? "mocsubmit" : "mocbeasubmit",
      content: {
        data: CurrentPendingData,
        where: {
          request_type: { eq: /* Release 3.2 - 1.1 - Starts*/currentRequestType/* Release 3.2 - 1.1 - Ends*/ },
          import_status: { eq: "pending" },
          requester_pers_no: { eq: account.pers_no.toString() },
          module: "massoffcycle",
        },
      },
    });
    /* Release 3.2 - 1.1 - Ends*/
  };
  /**MOC Hari changes ends */

  const filterExport = (event) => {
    console.log("mocFilters main", mocFilters);
    showModal({ component: "exportFilters" });
  };
  const doExport = (event) => {
    //if(Object.keys(mocFilters.mocFilters).length > 1){
    if (
      window.confirm(
        `Exporting employees list for ${
          /* Release 3.2 - 1.1 - Starts */
          currentRequestType == "compensation" 
          ? "Compensation Change"
          : currentRequestType == "onetime"  
          ? "One Time Compensation" 
          : "Business Excellence Award"
          /* Release 3.2 - 1.1 - Ends */
        }. Do you want to proceed?`
      )
    ) {
      console.log("moc filters are", mocFilters.mocFilters);
      initiateExport();
    }
    // }else{
    //   alert('Could not find the filters inputs. Please apply some filters.');
    // }
  };
  const initiateExport = async () => {
    await saveExport({
      variables: {
        where: tableWhere,
        template: /* Release 3.2 - 1.1 - Starts*/currentRequestType/* Release 3.2 - 1.1 - Ends*/,
        export_status: "pending",
        query_name: tableQuery,
      },
    })
      .then((response) => {
        if (response.data) {
          if (response.data.saveQuery) {
            alert(
              "Export has been initiated for employees, as per the filters applied. You will receive a mail with the same shortly."
            );
          } else if (response.data === null) {
            alert("could not save export information!");
          }
          console.log("resp data", response.data);
        } else {
          alert("could not save export information!");
        }
      })
      .catch((err) => {
        alert("could not save export information!");
        console.error(err);
      });
  };
  const clearFilters = (event) => {
    event.preventDefault();
    if (
      window.confirm(
        "Are you sure you want to clear filters and load original records?"
      )
    ) {
      updateFilters({});
    }
  };
  const prepareFilterQuery = () => {
    let filtersForQuery = { ...tableWhere };
    if (mocFilters.mocFilters.country.length > 0) {
      filtersForQuery.country_name = { in: mocFilters.mocFilters.country };
    } else {
      delete filtersForQuery.country_name;
    }
    if (mocFilters.mocFilters.hrRegion.length > 0) {
      filtersForQuery.hr_region = { in: mocFilters.mocFilters.hrRegion };
    } else {
      delete filtersForQuery.hr_region;
    }
    if (mocFilters.mocFilters.jobGrade.length > 0) {
      filtersForQuery.job_grade = { in: [...mocFilters.mocFilters.jobGrade] };
    } else {
      delete filtersForQuery.job_grade;
    }
    if (mocFilters.mocFilters.incentivePlan.length > 0) {
      filtersForQuery.incentive_plan_type = {
        in: [...mocFilters.mocFilters.incentivePlan],
      };
    } else {
      delete filtersForQuery.incentive_plan_type;
    }
    if (mocFilters.mocFilters.organizationUnit1Name.length > 0) {
      filtersForQuery.organization_unit_1 = {
        in: [...mocFilters.mocFilters.organizationUnit1Name],
      };
    } else {
      delete filtersForQuery.organization_unit_1;
    }
    if (mocFilters.mocFilters.organizationUnit2Name.length > 0) {
      filtersForQuery.organization_unit_2 = {
        in: [...mocFilters.mocFilters.organizationUnit2Name],
      };
    } else {
      delete filtersForQuery.organization_unit_2;
    }
    if (mocFilters.mocFilters.organizationUnit3Name.length > 0) {
      filtersForQuery.organization_unit_3 = {
        in: [...mocFilters.mocFilters.organizationUnit3Name],
      };
    } else {
      delete filtersForQuery.organization_unit_3;
    }
    if (mocFilters.mocFilters.organizationUnit4Name.length > 0) {
      filtersForQuery.organization_unit_4 = {
        in: [...mocFilters.mocFilters.organizationUnit4Name],
      };
    } else {
      delete filtersForQuery.organization_unit_4;
    }
    if (mocFilters.mocFilters.lineManager.length > 0) {
      filtersForQuery.line_manager_pers_no = {
        in: [...mocFilters.mocFilters.lineManager],
      };
    } else {
      delete filtersForQuery.line_manager_pers_no;
    }
    return filtersForQuery;
  };

  const importFile = () => {
    showModal({
      component: "massOffCycleImport",
      content: { /* Release 3.2 - 1.1 - Starts*/currentType: currentRequestType/* Release 3.2 - 1.1 - Ends*/, reloadReview },
    });
  };
  return (
    <>
      <Header {...props} />
      {hasBudgetVisibility && <Budget {...props} />}
      <BOBudget account={account} key={budgetRefreshKey} />
      {hasGlobalMessages && <GlobalMessages />}
      <TableHeadingWrap>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ marginRight: "25px" }}>Mass Requests</h2>
          {visible && (
            <SelectFilter
              options={filterOptions}
              onSelectChange={onSelectChange}
            ></SelectFilter>
          )}
          <select
              value={/* Release 3.2 - 1.1 - Starts*/currentRequestType/* Release 3.2 - 1.1 - Ends*/}
              onChange={onSelectRequestTypeChange}
              style={{
                padding: "6px",
                border: "1px solid #d2d2d2",
                outline: "none",
                marginLeft: "5px",
              }}
            >
              {ddlRequestTypeOptions.map(({ text, value }) => (
                <option value={value} key={value}>
                  {text}
                </option>
              ))}
          </select>
          {/* {hasSelectedEmployees && (
            <div>{selectedEmployees.length} selected</div>
          )} */}
        </div>

        {!isAdmin && (
          <TableActionsWrap>
            <span
              onClick={clearFilters}
              style={{
                margin: "0 16px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Clear Filters
            </span>
            <TableAction onClick={toggleDisplayCurrency}>{`Switch to ${
              forceCurrency === "EUR" ? "local currency" : "EUR"
            }`}</TableAction>
            <TableAction onClick={filterExport}>Filter</TableAction>
            <TableAction onClick={doExport}>Export</TableAction>
            <TableAction onClick={importFile}>Import</TableAction>
            <TableAction disabled={!hasPendingImports} onClick={calculateData}>
              Review and Submit
            </TableAction>
          </TableActionsWrap>
        )}
        {isAdmin && (
          <TableActionsWrap>
            <TableAction onClick={toggleDisplayCurrency}>{`Switch to ${
              forceCurrency === "EUR" ? "local currency" : "EUR"
            }`}</TableAction>
          </TableActionsWrap>
        )}
      </TableHeadingWrap>
      {/* <Table recordType="employees" columns={columns.employees} query={tableQuery} where={tableWhere} /> */}
      {/* DEFECT No. 14 Statrs  */}
      {!isOnlyBusinessOwner &&
        <Table
          recordType="employees"
          // columns={columns.employees}
          /* Release 3.1 - 1.1 - Starts */
          // columns={currentRequestType == "businessexcellenceaward" ? columns.employees_businessexcellenceaward : columns.employees}
          columns={columns.employees}
          /* Release 3.1 - 1.1 - Ends */
          query={tableQuery}
          where={tableWhere}
          isEuroSort={forceCurrency === "EUR" ? true : false}
          config={config}
          account={account}
          noCheckBox={true}
          showCount={true}
        />
      }
      {/* DEFECT No. 14 Ends  */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
    messages: state.messages,
    // {/* DEFECT No. 14 Statrs  */}
    config: state.config,
    // {/* DEFECT No. 14 Ends  */}
    mocFilters: state.mocFilters,
  };
};

export default connect(mapStateToProps, {
  unselectAllRecord,
  showModal,
  toggleShowInEur,
  updateFilters,
})(MassOffCycle);
