import promotion_job_family_name from './promotion_job_family_name';
import promotion_job_subfamily_name from './promotion_job_subfamily_name';
import promotion_job_name from './promotion_job_name';
import promotion_job_grade from './promotion_job_grade';
import new_position_title from './new_position_title';
import current_country_name from './current_country_name';
import new_country_name from './new_country_name';
import fte_annual_salary from './fte_annual_salary';
import new_fte_annual_salary from './new_fte_annual_salary';
import fte_incentive_target_percentage from './fte_incentive_target_percentage';
import new_incentive_target_percentage from './new_incentive_target_percentage';
import job_name from './job_name';
import job_family_name from './job_family_name';
import job_subfamily_name from './job_subfamily_name';
import fte_incentive_target_amount from './fte_incentive_target_amount';
import new_fte_incentive_target_amount from './new_fte_incentive_target_amount';
import fte_ttc_annual_salary from './fte_ttc_annual_salary';
import current_organization_unit_1_name from './current_organization_unit_1_name';
import new_organization_unit_1_name from './new_organization_unit_1_name';
import current_organization_unit_name from './current_organization_unit_name';
import new_organization_unit_name from './new_organization_unit_name';
/* Release 3.2 - 1.1 - Starts */
import offcycle_budget_update from './offcycle_budget_update';
import bea_budget_update from './bea_budget_update';
/* Release 3.2 - 1.1 - Ends */

export default {
  promotion_job_family_name,
  promotion_job_subfamily_name,
  promotion_job_name,
  promotion_job_grade,
  new_position_title,
  current_country_name,
  new_country_name,
  fte_annual_salary,
  new_fte_annual_salary,
  fte_incentive_target_percentage,
  new_incentive_target_percentage,
  fte_incentive_target_amount,
  new_fte_incentive_target_amount,
  job_name,
  job_family_name,
  job_subfamily_name,
  fte_ttc_annual_salary,
  current_organization_unit_1_name,
  new_organization_unit_1_name,
  current_organization_unit_name,
  new_organization_unit_name,
  /* Release 3.2 - 1.1 - Starts */
  offcycle_budget_update,
  bea_budget_update,
/* Release 3.2 - 1.1 - Ends */
};