import { itpRenderer } from '../../renderers';

export default (table) => {
  let name = 'new_incentive_target_percentage';
  let visible_in_tooltip = /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
    ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true;
    /* Release 3.2 - 1.1 - Ends */
  let renderer = (value, data, account, config) => itpRenderer('new', value, data, account, config);

  switch (table) {
    case 'editCompensation':
    case 'rejectRequests':
    case 'approveRequests':
      case 'editInternalHire':
      name = 'request.new_incentive_target_percentage';
      renderer = (value, data, account, config) => itpRenderer('new', value, data.request, account, config);
      break;
    case 'myRequests':
    case 'requestsHistory':
    case 'approvedRequestHistory':
    case 'pendingRequests':
      break;
    default:
      break;
  }

  return { 
    name,
    label: "Incentive Target Percentage",
    tooltip_section: "New",
    visible_in_tooltip,
    renderer
  };
};