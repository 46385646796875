import React, { useState } from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import Tooltip from "react-tooltip-lite";
import { selectRecord, unselectRecord } from "../../../actions";
import { capitalize, isDebug } from "../../../util";
import {
  RowWrap,
  Cell,
  SelectCellWrap,
  InfoCellWrap,
  CellWrap,
  TooltipInfo,
} from "../..";

const Row = (props) => {
  /**release 2 Ourtalent scenario changeData added */
  const {
    account,
    infoTooltip,
    recordType,
    expandable,
    selectable,
    columns,
    item,
    background,
    selectRecord,
    unselectRecord,
    editFunc,
    changeData,
    /* DEM0057819 Starts  */ selectedEmployees /* DEM0057819 Ends  */,
    /* Release 3.1 - Starts */ 
    checkboxIsDisplayed = false, 
    selectedRequests, 
    /* Release 3.1 - Ends */
    /* Release 3.2 - 9.1Req - Starts */
    handleSimulationSelect,
    checked_status,
    /* Release 3.2 - 9.1Req - Ends */
    /* Release 3.2 - 1.1 - Starts */
    selectedBudgets,
    selectedBusinessOwnerBudgets,
    /* Release 3.2 - 1.1 - Ends */
  } = props;
  //console.log("Tooltip test", infoTooltip);
  const indexColumn = columns.find((c) => c.index);
  const [expanded, setExpanded] = useState(false);
  /* Release 3.1 - Starts */
  let selected = props[`selected${capitalize(recordType)}`]
    ? props[`selected${capitalize(recordType)}`].find(
        (i) => i[indexColumn.name] === item[indexColumn.name]
      ) || false
    : false;
  /* Release 3.1 - Starts */
  const isAllowed = (column) => {
    if (column.allow) {
      /* DEM0057819 Starts  */
      console.log(column, "=====>column");
      if (
        (column.mom_title == "requestCompesation" ||
          column.mom_title == "requestOnetime" ||
          /* Release 3.2 - 1.1 - Starts */
          column.mom_title == "requestBusinessExcellenceAward"
          /* Release 3.2 - 1.1 - Ends */
          ) &&
        column.name == "target_budget_org"
      ) {
        var selectedEmployeeIsSubordinate = 0;
        if (selectedEmployees.length > 0) {
          for (var incr = 0; incr < selectedEmployees.length; incr++) {
            if (
              selectedEmployees[incr].line_manager_pers_no == account.pers_no
            ) {
              console.log("USER IS SUBORDINATE");
              selectedEmployeeIsSubordinate = 1;
              break;
            }
          }
          column.allow = ["hr1", "hr2", "hr3", "bcb"];
          if (selectedEmployeeIsSubordinate == "1") {
            if (account.roles.length > 0) {
              for (var incr = 0; incr < account.roles.length; incr++) {
                if (
                  account.roles[incr] == "hr1" ||
                  account.roles[incr] == "hr2" ||
                  account.roles[incr] == "hr3"
                ) {
                  //column.allow = ["bcb"];
                  column.allow = [];
                  break;
                }
              }
            }
          }
        }
      }
      /* DEM0057819 Ends  */
      return column.allow.find((role) =>
        account.roles.find((arole) => arole === role)
      );
    }
    return true;
  };

  const isVisible = (column) => {
    return typeof column.visible === "function"
      ? column.visible(column, item, account) || false
      : column.visible;
  };
  // R2 - Debug consoling the my employees item
  const handleRowSelect = (event) => {
    /* Release 3.2 - 9.1Req - Starts */
    if (indexColumn.section == "internalHireSimulation") {
      handleSimulationSelect(event, item, indexColumn);
      return false;
    }
    /* Release 3.2 - 9.1Req - Ends */
    /* Release 3.1 - Starts */
    if(event.target.checked && 
      (
        (indexColumn.section == "changeApproverRequests" && selectedRequests.length > 0)
        /* Release 3.2 - 1.1 - Starts */
        || (indexColumn.section == "beabudgetmanage" && selectedBudgets.length > 0)
        || (indexColumn.section == "beabusinessownerbudgetmanage" && selectedBusinessOwnerBudgets.length > 0)
        /* Release 3.2 - 1.1 - Starts */
      ) 
      ) {
      selected = false;
      /* Release 3.2 - 1.1 - Starts */
      let alert_message =
        (indexColumn.section === "beabudgetmanage")
          ? "At a time only one budget is allowed to select."
          : (indexColumn.section === "beabusinessownerbudgetmanage")
          ? "At a time only one business owner is allowed to select."
          : "At a time only one request is allowed in change approver.";
      /* Release 3.2 - 1.1 - Starts */
      alert(alert_message);
      return false;
     }   
    /* Release 3.1 - Ends */    
    /* Release 3.2 - 8.1 - Starts */
    if(event.target.checked && indexColumn.section == "employeeDashboardHistory" && selectedEmployees.length > 0) {
      selected = false;
      alert("At a time only one employee is allowed to view history.");
      return false;
     } 
    /* Release 3.2 - 8.1 - Ends */
    //console.log(item);
    let res = selectable
      ? event.target.checked
        ? selectRecord(item, indexColumn, recordType)
        : unselectRecord(item, indexColumn, recordType)
      : null;    
  };

  const handleRowClick = (event) =>
    expandable
      ? event.target.tagName !== "INPUT"
        ? setExpanded(!expanded)
        : null
      : null;

  /*  ADD A NEW ROLE FOR ADMIN FUNCTIONALITY 19 */
  const isAdmin = account.roles.includes("admin");
  /* Release 3.2 - 10.1 - Starts */
  const isRcb = account.roles.includes("rcb");
  const isBCB = account.roles.includes("bcb");
  /* Release 3.2 - 10.1 - Ends */

  //console.log(columns, "======columns")
  /*  FIXING DELEGATION ISSUE STARTS */
  var checkboxDisableStatus = "";
  if (indexColumn.section == "Delegation List Page") {
    //console.log(account.pers_no);
    //console.log(item.delegation_pers_no.pers_no);
    if (account.pers_no == item.delegation_pers_no.pers_no) {
      checkboxDisableStatus = true;
    } else {
      if (item.is_active == 2) checkboxDisableStatus = true;
      else checkboxDisableStatus = false;
    }
  }

  //DEM0058258
  const isHRC = account.roles.includes("hrc");
  /* Release 3.2 - 10.1 - Starts */
  const isHr =
    account.roles.includes("hr1") ||
    account.roles.includes("hr2") ||
    account.roles.includes("hr3") ||  
    account.roles.includes("chr") ||
    isHRC ||
    isBCB ||
    account.roles.includes("rhr");
  const isGct = account.roles.includes("gct");
  const isManager = account.roles.includes("manager");
  const isSuperManager = account.roles.includes("supermanager");
  const isGTap = account.roles.includes("gtap");
  const isRTap = account.roles.includes("rtap");
  const isKeyUser = account.roles.includes("keyuser");
  const isTap = isGTap || isRTap;
  const isOnlyRcb =
    isRcb && !isTap && !isGct && !isManager && !isSuperManager && !isAdmin && !isHr && !isKeyUser;
  /* Release 3.2 - 10.1 - Ends */
  /* Release 3.2 - 10.3 - Starts */
  const isOnlyKeyUser =
    !account.roles.includes("manager") &&
    !account.roles.includes("supermanager") &&
    !account.roles.includes("bcb") &&
    !account.roles.includes("gct") &&
    !account.roles.includes("rcb") &&
    !account.roles.includes("admin") &&
    !account.roles.includes("gtap") &&
    !account.roles.includes("rtap") &&
    account.roles.includes("keyuser") &&
    !account.roles.includes("hr1") &&
    !account.roles.includes("hr2") &&
    !account.roles.includes("hr3") &&
    !account.roles.includes("chr") &&
    !account.roles.includes('hrc') &&
    !account.roles.includes('bg_head') &&
    !account.roles.includes('bl2') &&
    !account.roles.includes('bl3') &&
    !account.roles.includes('rhr')&&
    !account.roles.includes('business_owner');
  /* Release 3.2 - 10.3 - Ends */
  var checkboxIsDisplayedForHRC = "yes";
  if (isHRC && indexColumn.section == "AllRequestHRC") {
    checkboxIsDisplayedForHRC = "no";
  }
  /* Release 3.1 - Starts */ 
  else if (!isAdmin && (indexColumn.section === "approvedRequestHistory" 
          /* Release 3.2 - 10.3 - Starts */ 
          || indexColumn.section === "AllRecquestsHistory" 
          /* Release 3.2 - 10.3 - Ends */
          /* Release 3.2 - 8.1 - Starts */
          || indexColumn.section === "employeehistoryaddresssection"
          || indexColumn.section === "employeePerformanceDashboard"
          || indexColumn.section === "targetIncentiveHistory"
          || indexColumn.section === "salaryHistory"
          || indexColumn.section === "organizationalHistory"
          || indexColumn.section === "actualPaidIncentiveHistory"
          || indexColumn.section === "jobGradeHistory"
          /* Release 3.2 - 8.1 - Ends */
          /* Release 3.2 - 10.1 - Starts */
          || ((indexColumn.section === "employees" || indexColumn.section === "approvedRequestsHistory")  && (isOnlyRcb || isOnlyKeyUser))
          || (indexColumn.section === "approvedRequestsHistory" && isBCB)
          /* Release 3.2 - 10.1 - Ends */
          /* Release 3.3 - 8.3 - Starts */
          || indexColumn.section === "roCountryStatistics"
          || indexColumn.section === "roInsuranceTaxData"
          || indexColumn.section === "frLocalPayAttributes"
          || indexColumn.section === "hcrLSGroupcompensation"
          /* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
          || indexColumn.section === "latestInformation"
          || indexColumn.section === "referralAward"
          || indexColumn.section === "orgAssignmentIT0001"
          || indexColumn.section === "actionsIT0000"
          /* Release 3.3 - 8.3 - Ends */
          /*  DEM0069361 - TAP Access Part 3 Starts */
          || indexColumn.section === "roEducation"
          /*  DEM0069361 - TAP Access Part 3 Ends */

          )) {
    checkboxIsDisplayedForHRC = "no";
  }
  /* Release 3.2 - 9.1Req - Starts */
  if (!isAdmin && indexColumn.section === "internalHireSimulation") {
    checkboxIsDisplayedForHRC = "yes";
    selected = checked_status;
  }
  /* Release 3.2 - 9.1Req - Ends */
  /* Release 3.1 - Ends */
  /*  FIXING DELEGATION ISSUE ENDS */
  return (
    <>
      <RowWrap onClick={handleRowClick} background={background}>
        {/*  FIXING DELEGATION ISSUE */}
        {/*//DEM0058258*/}
        {!isAdmin &&
          checkboxIsDisplayedForHRC == "yes" &&
          !checkboxDisableStatus &&
          selectable && (
            <SelectCellWrap width="50px">
              <input
                name={item[indexColumn.name]}
                type="checkbox"
                onChange={handleRowSelect}
                checked={selected}
                disabled={checkboxDisableStatus}
              />
            </SelectCellWrap>
          )}
        {/*//DEM0058258*/}
        {!isAdmin &&
          checkboxIsDisplayedForHRC == "yes" &&
          checkboxDisableStatus &&
          selectable && (
            <SelectCellWrap width="50px">
              <input
                name={item[indexColumn.name]}
                type="checkbox"
                onChange={handleRowSelect}
                checked={false}
                disabled={checkboxDisableStatus}
              />
            </SelectCellWrap>
          )}
        {/* Release 3.1 - Starts */}
        {isAdmin && checkboxIsDisplayed  && (
          <SelectCellWrap width="50px">
            <input
              name={item[indexColumn.name]}
              type="checkbox"
              onChange={handleRowSelect}
              checked={selected}
              disabled={checkboxDisableStatus}
            />
          </SelectCellWrap>
        )}
        {/* Release 3.1 - Ends */}
        {columns.map((column) => {
          if (!isVisible(column) || !isAllowed(column)) {
            return null;
          }
          return (
            <Cell
              /**release 2 our talent row scroll ui and change data starts */
              background={background}
              changeData
              /**release 2 our talent row scroll ui and change data ends */
              cellEditFunc={editFunc ? editFunc : ""}
              key={`cell-${column.name}`}
              column={column}
              rowData={item}
              {...props}
            />
          );
        })}
        {infoTooltip && (
          <CellWrap width="70px">
            <StyledTooltip
              content={<TooltipInfo columns={columns} data={selected} />}
              zIndex={99999999999999999}
              direction="left" //fix for tool tip freeze
              // direction="left-start"
              arrow={false}
            >
              <InfoCellWrap>?</InfoCellWrap>
            </StyledTooltip>
          </CellWrap>
        )}
      </RowWrap>
      {isDebug && expandable && expanded && selected && (
        <pre>{JSON.stringify(selected, undefined, 2)}</pre>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    selectedRequests: state.selectedRequests,
    /*  FIXING DELEGATION ISSUE */
    selectedDelegates: state.selectedDelegates,
    /* Release 3.2 - 1.1 - Starts */
    selectedBudgets: state.selectedBudgets,
    selectedBusinessOwnerBudgets: state.selectedBusinessOwnerBudgets,
    /* Release 3.2 - 1.1 - Ends */
    account: state.account,
  };
};

export default connect(mapStateToProps, { selectRecord, unselectRecord })(Row);

const StyledTooltip = styled(Tooltip)`
  & span {
    width: 50%;
    margin-top: 20px;
  }
`;
