export default (table) => {
  let name = 'organization_unit_1_name';
  
  switch (table) {
    case 'editCompensation':
    case 'editOnetime':
    case 'rejectRequests':
    case 'approveRequests':
      case 'editInternalHire':
      name = 'request.for.organization_unit_1_name';
      break;
    case 'myRequests':
    case 'requestsHistory':
    case 'pendingRequests':
      name = 'for.organization_unit_1_name';
      break;
    default:
      break;
  }

  return { 
    name,
    label: "Business Group",
    tooltip_section: "Current",
    visible_in_tooltip: true,
    allow: ["hr1","hr2","hr3","bcb"
   ,/* Release 3.2 - 10.1 - Starts */ "rcb" /* Release 3.2 - 10.1 - Ends */
  ]
  };
};