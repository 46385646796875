import React, { useState, 
  /* Release 3.2 - 8.1 - Starts */
  useEffect
  /* Release 3.2 - 8.1 - Ends */
} from "react";
import { connect } from "react-redux";
import { unselectAllRecord, showModal, toggleShowInEur } from "../actions";
import {
  GlobalMessages,
  Header,
  Budget,
  Table,
  TableHeadingWrap,
  TableActionsWrap,
  TableAction,
  SelectFilter,
} from "../components";
import { columns } from "../util/config";
import { canRaiseRequest } from "../util/helper";
// R2 - imported inputfield for search
import {
  InputField,
  /* Release 3.1 - Starts */
  Select,
  /* Release 3.1 - Ends */
} from "../components/styled";

const Employees = (props) => {
  // const { selectedEmployees, messages, showModal, account, toggleShowInEur } = props;
  const {
    selectedEmployees,
    messages,
    showModal,
    account,
    toggleShowInEur,
    config,
    unselectAllRecord,
  } = props; // DEFECT No. 14
  const { forceCurrency, pers_no, roles, accessibles
   /* Release 3.2 - 10.1 - Starts */
   ,rcbHRRegion
   /* Release 3.2 - 10.1 - Ends */
  } = account;

  const isGct = roles.includes("gct");
  const isManager = roles.includes("manager");
  const isSuperManager = roles.includes("supermanager");
  const isAdmin = roles.includes("admin");
  console.log(roles, "ROLES====>");
  const isHr =
    roles.includes("hr1") ||
    roles.includes("hr2") ||
    roles.includes("hr3") ||
    /* Release 3.2 - 10.1 - Starts */
    //roles.includes("rcb") ||
    /* Release 3.2 - 10.1 - Ends */
    roles.includes("chr") ||
    roles.includes("hrc") ||
    roles.includes("bcb") ||
    roles.includes("rhr");
  /* R1.2 - Added role for TAP users */
  const isGTap = roles.includes("gtap");
  const isRTap = roles.includes("rtap");
  /* Release 3.1 - Starts */
  const isBcb = roles.includes("bcb");
  /* Release 3.1 - Ends */
  /* Release 3.2 - 10.1 - Starts */
  const isRcb = roles.includes("rcb");
  /* Release 3.2 - 10.1 - Ends */
  /* Release 3.2 - 10.4 - Starts */
  const isKeyUser = roles.includes("keyuser");
  /* Release 3.2 - 10.4 - Ends */
  const isTap = isGTap || isRTap;
  const isOnlyTap =
    isTap && !isGct && !isManager && !isSuperManager && !isAdmin && !isHr && !isKeyUser;
  /* Release 3.2 - 10.1 - Starts */
  const isOnlyRcb =
    isRcb && !isTap && !isGct && !isManager && !isSuperManager && !isAdmin && !isHr && !isKeyUser;
  /* Release 3.2 - 10.1 - Ends */
  /* Release 3.2 - 10.3 - Starts */
  const isOnlyKeyUser =
    !roles.includes("manager") &&
    !roles.includes("supermanager") &&
    !roles.includes("bcb") &&
    !roles.includes("gct") &&
    !roles.includes("rcb") &&
    !roles.includes("admin") &&
    !roles.includes("gtap") &&
    !roles.includes("rtap") &&
    roles.includes("keyuser") &&
    !roles.includes("hr1") &&
    !roles.includes("hr2") &&
    !roles.includes("hr3") &&
    !roles.includes("chr") &&
    !roles.includes('hrc') &&
    !roles.includes('bg_head') &&
    !roles.includes('bl2') &&
    !roles.includes('bl3') &&
    !roles.includes('rhr')&&
    !roles.includes('business_owner');
  /* Release 3.2 - 10.3 - Ends */
  /* Release 3.2 - 9.1 - Starts */
  const isHRBP =
    roles.includes("hr1") ||
    roles.includes("hr2") ||
    roles.includes("hr3");
  /* Release 3.2 - 9.1 - Ends */
  /* R1.2 - Hook to switch for TAP users */

  let currentViewDefaultStatus = true;
  if(isOnlyTap)
    currentViewDefaultStatus = false;

  const [currentView, setCurrentView] = useState(currentViewDefaultStatus);

  const [tableQuery, setTableQuery] = useState(
    (isGct || isAdmin 
    /* Release 3.2 - 10.4 - Starts */
     || isKeyUser
    /* Release 3.2 - 10.4 - Ends */
    )
      ? "employees"
      : isHr
      ? "employeesForHr"
      /* Release 3.2 - 10.1 - Starts */
      : isRcb
      /*  DEM0066861 - Regional BCB role Starts */
      ? "employeesForRBCB"
      /*  DEM0066861 - Regional BCB role Ends */
      /* Release 3.2 - 10.1 - Ends */
      : isOnlyTap
      /*  DEM0067988 - TAP Access based on requisition Starts */
      //? "employeesForTap"
      ? "employeesForTapRequisition"
      /*  DEM0067988 - TAP Access based on requisition Ends */
      : "employeesForManager"
  );
  const [tableWhere, setTableWhere] = useState(
    (isHr || isGct || isAdmin || isOnlyTap || isKeyUser)
      ? {}
      /* Release 3.2 - 10.1 - Starts */
      : isRcb 
      ? { hr_region: { in: rcbHRRegion } } 
      /* Release 3.2 - 10.1 - Ends */
      : { line_manager_pers_no: { eq: pers_no } }
  );
  const filterOptions =
    (isGct || isAdmin || isHr 
    /* Release 3.2 - 10.1 - Starts */
    || isRcb
    /* Release 3.2 - 10.1 - Ends */
    /* Release 3.2 - 10.4 - Starts */
    || isKeyUser)
    /* Release 3.2 - 10.4 - Ends */
      ? ["All", "Direct Subordinates"]
      : ["All", "Direct Subordinates", "2 Below"];
  const hasSelectedEmployees = selectedEmployees.length > 0;
  /* Release 3.2 - 9.1Req - Starts */
  const hasSelectedEmployeesForSimulation = selectedEmployees.length == 1;
  /* Release 3.2 - 9.1Req - Ends */
  const hasGlobalMessages = messages.length > 0;
  const hasBudgetVisibility =
    (accessibles && accessibles.budgetOrgs.length > 0) || isGct || isAdmin;
  const visible = isGct || isAdmin || isSuperManager || isHr
  /* Release 3.2 - 10.1 - Starts */
  || isRcb
  /* Release 3.2 - 10.1 - Ends */
  /* Release 3.2 - 10.4 - Starts */
  || isKeyUser
  /* Release 3.2 - 10.4 - Ends */
  ;
  /*const hasOnlyDirectSelected = isSuperManager
    ? canRaiseRequest(selectedEmployees, account.pers_no)
    : true;*/
    const [slectedFilterValue, setSlectedFilterValue] = useState('All');
    
  /* R1.2 - Toggle Functionality for TAP users */
  const onSelectChange = (event) => {
    setSearchText("");
    const filter = event.target.value;

    setSlectedFilterValue(filter);

    switch (filter) {
      case "Direct Subordinates":
        if ((isManager || isHr || isAdmin
          /* Release 3.2 - 10.4 - Starts */
          || isKeyUser
          /* Release 3.2 - 10.4 - Ends */
          ) && !isRcb) {
          setTableQuery("managerDirectSubOrdinates");
          setTableWhere({ line_manager_pers_no: { eq: pers_no } });
        }
        /* Release 3.2 - 10.1 - Starts */
        else if(isRcb) { 
          setTableQuery("managerDirectSubOrdinates");
          //setTableWhere({ hr_region: { in: rcbHRRegion } }, { line_manager_pers_no: { eq: pers_no } });
          setTableWhere({ line_manager_pers_no: { eq: pers_no } });
        }
        /* Release 3.2 - 10.1 - Ends */
        break;
      case "2 Below":
        if (isManager) {
          setTableQuery("managerSecondSubOrdinates");
          setTableWhere({ line_manager_pers_no: { eq: pers_no } });
        }
        break;
      default:
        if (isGct || isAdmin
          /* Release 3.2 - 10.4 - Starts */
          || isKeyUser
          /* Release 3.2 - 10.4 - Ends */
          ) {
          setTableQuery("employees");
          /**release 2 - dropdown change issue start */
          setTableWhere({});
          /**release 2 - dropdown change issue ends */
          break;
        }
        if (isHr) {
          setTableQuery("employeesForHr");
          /**release 2 - dropdown change issue  starts*/
          setTableWhere({});
          /**release 2 - dropdown change issue ends */
          break;
        }
        /* Release 3.2 - 10.1 - Starts */
        /*  DEM0066861 - Regional BCB role Starts */
        else if(isRcb) { 
          setTableQuery("employeesForRBCB");
          setTableWhere({ hr_region: { in: rcbHRRegion } });
          break;
        }
        /*  DEM0066861 - Regional BCB role Ends */
        /* Release 3.2 - 10.1 - Ends */
        if (isManager) {
          setTableQuery("employeesForManager");
          setTableWhere({ line_manager_pers_no: { eq: pers_no } });
          break;
        }
    }
  };
  var hasOnlyDirectSelected = false;
  //console.log("slectedFilterValue====>", slectedFilterValue)
  //console.log("isRcb===>", isRcb);
  //console.log("isOnlyRcb===>", isOnlyRcb);

  if(slectedFilterValue != 'Direct Subordinates')
  {
    if(isOnlyRcb)
    {
      hasOnlyDirectSelected = false;
    }
    else if(isRcb)
    {
      if(isBcb)
      {
        for(const emp of selectedEmployees){
          //console.log("emp.region_cb_pers_no===>", emp.region_cb_pers_no);
          //console.log("emp.business_cb_pers_no===>", emp.business_cb_pers_no);
          //console.log("account.pers_no===>", account.pers_no);
          //if(emp.region_cb_pers_no == account.pers_no){
            if(emp.region_cb_pers_no == emp.business_cb_pers_no){
            hasOnlyDirectSelected = true;
            break;
          }
        }  
      }
      if(hasOnlyDirectSelected == false)
      {
        if(isHr)
        {
          for(const emp of selectedEmployees){
            //console.log("emp.region_cb_pers_no===>", emp.region_cb_pers_no);
            //console.log("emp.hrbp_n1_pers_no===>", emp.hrbp_n1_pers_no);
            //console.log("emp.hrbp_n2_pers_no===>", emp.hrbp_n2_pers_no);
            //console.log("emp.hrbp_n3_pers_no===>", emp.hrbp_n3_pers_no);
            if(emp.region_cb_pers_no == emp.hrbp_n1_pers_no || emp.region_cb_pers_no == emp.hrbp_n2_pers_no || emp.region_cb_pers_no == emp.hrbp_n3_pers_no){
              hasOnlyDirectSelected = true;
              break;
            }
          } 
        }
      }
      if(hasOnlyDirectSelected == false)
      {
        if(isManager)
        {
          for(const emp of selectedEmployees){
            //console.log("emp.region_cb_pers_no===>", emp.region_cb_pers_no);
            //console.log("emp.line_manager_pers_no===>", emp.line_manager_pers_no);
            //console.log("account.pers_no===>", account.pers_no);
            //if(emp.region_cb_pers_no == account.pers_no){
              if(emp.region_cb_pers_no == emp.line_manager_pers_no){
              hasOnlyDirectSelected = true;
              break;
            }
          } 
        }
      }
      
      /*for(const emp of selectedEmployees){
        console.log("emp.region_cb_pers_no===>", emp.region_cb_pers_no);
        console.log("account.pers_no===>", account.pers_no);
        if(emp.region_cb_pers_no == account.pers_no){
          hasOnlyDirectSelected = false;
          break;
        }
      }*/
    }  
    else
    {
      hasOnlyDirectSelected = isSuperManager
      ? canRaiseRequest(selectedEmployees, account.pers_no)
      : true;
    }
  }
  else
  {
    hasOnlyDirectSelected = isSuperManager
    ? canRaiseRequest(selectedEmployees, account.pers_no)
    : true;
  }
  //console.log("hasOnlyDirectSelected====>", hasOnlyDirectSelected)
  // R2 - Search Functionality State variable
  const [searchText, setSearchText] = useState("");

  const handleRequestCompensation = (event) => {
    showModal({ component: "compesation" });
  };
  /* R1.2 - Toggle Functionality for TAP users */
  const toggleTAP = (event) => {
    unselectAllRecord("employees");
    if (currentView === false) {
      setCurrentView(true);
      setSearchText("");
      setPlaceholderText("Search on Nokia Id, Email, Name, Country [at least 3 characters]");
      setTableQuery(
        (isGct || isAdmin
        /* Release 3.2 - 10.4 - Starts */
        || isKeyUser
        /* Release 3.2 - 10.4 - Ends */
        )
          ? "employees"
          : isHr
          ? "employeesForHr"
          /* Release 3.2 - 10.1 - Starts */
          : isRcb
          /*  DEM0066861 - Regional BCB role Starts */
          ? "employeesForRBCB"
          /*  DEM0066861 - Regional BCB role Ends */
          /* Release 3.2 - 10.1 - Ends */
          : "employeesForManager"
      );
      setTableWhere(
        (isHr || isGct || isAdmin
          /* Release 3.2 - 10.4 - Starts */
          || isKeyUser)
          /* Release 3.2 - 10.4 - Ends */
          ? {}
          /* Release 3.2 - 10.1 - Starts */
          : isRcb 
          ? { hr_region: { in: rcbHRRegion } }
           /* Release 3.2 - 10.1 - Ends */
          : { line_manager_pers_no: { eq: pers_no } }
      );
    } else if (currentView === true) {
      setCurrentView(false);
      setSearchText("");
      setPlaceholderText("Search on Nokia Id, Requisition ID, Email, Name, Country [at least 3 characters]");
      /*  DEM0067988 - TAP Access based on requisition Starts */
      //setTableQuery("employeesForTap");
      setTableQuery("employeesForTapRequisition");
      /*  DEM0067988 - TAP Access based on requisition Ends */

      setTableWhere({});
    }
  };
  

  const handleRequestOneTime = (event) => {
    showModal({ component: "onetime" });
  };

  const toggleDisplayCurrency = (event) => {
    toggleShowInEur(forceCurrency ? null : "EUR");
  };
  // R2 - Search Functionality Starts
  const handleSearchChange = (event) => {
    unselectAllRecord("employees");
    setSearchText(event.target.value);
    const searchFilter = event.target.value;
    const searchTableWhere = { ...tableWhere, employee_search: searchFilter };
    if (searchFilter.length >= 3) {
      setTableWhere(searchTableWhere);
    } else if (searchFilter === "" || searchFilter.length < 3) {
      setTableWhere(tableWhere);
    }
  };
  const handleTapSearchChange = (event) => {
    unselectAllRecord("employees");
    setSearchText(event.target.value);
    const searchFilter = event.target.value;
    //console.log(searchFilter, "tap-search");
    if (searchFilter.length >= 3) {
      if (isTap) {
        /*  DEM0067988 - TAP Access based on requisition Starts */
        if(currentView)
        {
          setTableQuery("employeesForManager");
          setTableWhere({ employee_search: searchFilter });
        }
        else
        {
          //setTableQuery("employeesForTap");
          setTableQuery("employeesForTapRequisition");
          setTableWhere({ employee_search: searchFilter });
        }
        /*  DEM0067988 - TAP Access based on requisition Ends */
      }
    } else if (searchFilter === "" || searchFilter.length < 3) {
      /*  DEM0067988 - TAP Access based on requisition Starts */
      //setTableQuery(isTap && "employeesForTap");
      if (isTap) 
      {
        if(currentView)
        {
          setTableQuery("employeesForManager");
          setTableWhere({});
        }
        else
        {
          setTableQuery(isTap && "employeesForTapRequisition");
          setTableWhere(isTap && {});
        }
      }
      /*  DEM0067988 - TAP Access based on requisition Ends */
    }
  };
  const clearChange = () => {
    unselectAllRecord("employees");
    setSearchText("");
    const { employee_search, ...newTableWhere } = tableWhere;
    setTableWhere(newTableWhere);
  };
  const clearTapChange = () => {
    unselectAllRecord("employees");
    setSearchText("");
    if (isTap) {
      setTableQuery("employeesForTap");
      setTableWhere({});
    }
  };
  // R2 - Search Functionality Ends
  /* R1.2 - Internal Hire Popup TAP users */
  const handleInternalHire = () => {
    showModal({ component: "internalHire" });
  };
  /* R1.2 - Internal Hire Popup TAP users */
  /* Release 3.2 - 9.1Req - Starts */
  const handleInternalHireSimulations = () => {
    showModal({ component: "internalHireSimulations" });
  };
  /* Release 3.2 - 9.1Req - Ends */
  /* Release 3.2 - 8.1 - Starts */
  useEffect(() => {
    unselectAllRecord("employees");
  }, [unselectAllRecord]);
  /* Release 3.2 - 8.1 - Ends */

  /*  DEM0067988 - TAP Access based on requisition Starts */
  let searchPlaceholder= 'Search on Nokia Id, Email, Name, Country [at least 3 characters]';
  //console.log("isOnlyTapisOnlyTapisOnlyTap====>", isOnlyTap)
  if(isOnlyTap)
    searchPlaceholder= 'Search on Nokia Id, Requisition ID, Email, Name, Country [at least 3 characters]';
  const [placeholderText, setPlaceholderText] = useState(searchPlaceholder);
  /*  DEM0067988 - TAP Access based on requisition Ends */

  console.log("currentView========>", currentView)
  return (
    <>
      <Header {...props} />
      {hasBudgetVisibility && <Budget {...props} />}
      {hasGlobalMessages && <GlobalMessages />}
      <TableHeadingWrap>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ marginRight: "15px" }}>My Employees</h2>

          {visible && currentView && (
            <SelectFilter
              options={filterOptions}
              onSelectChange={onSelectChange}
            ></SelectFilter>
          )}
          {/* R2 - Search and clear input boxes */}
          <InputField
            type="text"
            placeholder={placeholderText}
            onChange={currentView ? handleSearchChange : handleTapSearchChange}
            value={searchText}
            style={{
              width: "350px",
              marginLeft: "20px",
              height: "32px",
              outline: "#CCC",
              borderRadius: "5px",
              border: "1px solid #CCC",
            }}
          />
          <div
            onClick={currentView ? clearChange : clearTapChange}
            style={{
              height: "32px",
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: "500",
              textAlign: "center",
              position: "relative",
              top: "6px",
              right: "20px",
            }}
          >
            X
          </div>
          {/* R2 - Search and clear input boxes */}
          {/* R1.2 - Tooltip and Toggle button only if TAP user */}
          {!isOnlyTap && isTap && (
            <>
              {/* Release 3.1 - Starts */}
              <Select
                style={{ width: "auto", paddingTop: "5px", fontSize: "12px" }}
                onChange={toggleTAP}
                
              >
                <option value={true} SELECTED>Current View</option>
                <option value={false}>TAP View</option>
              </Select>
              {/* Release 3.1 - Ends */}
            </>
          )}
          {/* R1.2 - Tooltip and Toggle button only if TAP user */}
        </div>
        {!isAdmin && (
          <TableActionsWrap>
            <TableAction onClick={toggleDisplayCurrency}>{`Switch to ${
              forceCurrency === "EUR" ? "local currency" : "EUR"
            }`}</TableAction>
            {/* R1.2 - Button for TAP users */}
            {(isOnlyTap ||
              (isTap && !currentView) 
              /* Release 3.1 - Starts */ ||
              isBcb /* Release 3.1 - Ends */ 
              /* Release 3.2 -9.1 - Starts */ || isHRBP /* Release 3.2 -9.1 - Ends */
              )  && (
                <React.Fragment>
                  <TableAction
                    disabled={!hasSelectedEmployees || !hasOnlyDirectSelected}
                    onClick={handleInternalHire}
                  >
                    {isBcb && !isTap 
                    /* Release 3.2 -10.1 - Starts */ || isRcb /* Release 3.2 -10.1 - Ends */
                    /* Release 3.2 -9.1 - Starts */ || isHRBP /* Release 3.2 -9.1 - Ends */
                    ? "Calculation" : "Request"} Internal Hiring
                  </TableAction>
                  {/* Release 3.2 - 9.1Req - Starts */}
                  <TableAction
                  disabled={!hasSelectedEmployeesForSimulation || !hasOnlyDirectSelected}
                  onClick={handleInternalHireSimulations}
                >
                  Create Simulations
                </TableAction>
                {/* Release 3.2 - 9.1Req - Ends */}
              </React.Fragment>
            )}
            {/* R1.2 - Button for TAP users */}
            {!isOnlyTap && currentView && 
            /* Release 3.2 - 10.4 - Starts */ !isOnlyKeyUser && /* Release 3.2 - 10.4 - Ends */
            /* Release 3.2 - 10.1 - Starts */ !isOnlyRcb && /* Release 3.2 - 10.1 - Ends */
            (
              <TableAction
                disabled={!hasSelectedEmployees || !hasOnlyDirectSelected}
                onClick={handleRequestCompensation}
              >
                Request Compensation Change
              </TableAction>
            )}
            {!isOnlyTap && currentView && 
            /* Release 3.2 - 10.4 - Starts */ !isOnlyKeyUser && /* Release 3.2 - 10.4 - Ends */
            /* Release 3.2 - 10.1 - Starts */ !isOnlyRcb && /* Release 3.2 - 10.1 - Ends */
            (
              <TableAction
                disabled={!hasSelectedEmployees || !hasOnlyDirectSelected}
                onClick={handleRequestOneTime}
              >
                Request One-Time Payment
              </TableAction>
            )}
          </TableActionsWrap>
        )}
        {isAdmin && (
          <TableActionsWrap>
            <TableAction onClick={toggleDisplayCurrency}>{`Switch to ${
              forceCurrency === "EUR" ? "local currency" : "EUR"
            }`}</TableAction>
            {/* R1.2 - Button for TAP users */}
            {(isOnlyTap ||
              (isTap && !currentView) /* Release 3.1 - Starts */ ||
              isBcb) /* Release 3.1 - Ends */ && (
              <React.Fragment>
                <TableAction
                  disabled={!hasSelectedEmployees || !hasOnlyDirectSelected}
                  onClick={handleInternalHire}
                >
                  Request Internal Hiring
                </TableAction>
                {/* Release 3.2 - 9.1Req - Starts */}
                <TableAction
                  disabled={!hasSelectedEmployeesForSimulation || !hasOnlyDirectSelected}
                  onClick={handleInternalHireSimulations}
                >
                  Create Simulations
                </TableAction>
                {/* Release 3.2 - 9.1Req - Ends */}
              </React.Fragment>
            )}
            {/* R1.2 - Button for TAP users */}
          </TableActionsWrap>
        )}
      </TableHeadingWrap>
      {/* <Table recordType="employees" columns={columns.employees} query={tableQuery} where={tableWhere} /> */}
      {/* DEFECT No. 14 Statrs  */}
      {hasSelectedEmployees && <div>{selectedEmployees.length} selected</div>}
      {/* R1.2 - Added condition for TAP users to columns */}
      <Table
        recordType="employees"
        columns={
          isOnlyTap
            //? columns.tapEmployees
            //: currentView
            //? columns.tapEmployees
            /*  DEM0067988 - TAP Access based on requisition Starts */
            ? columns.tapEmployeesRequisition
            : currentView
            ? columns.employees
            : !currentView
            ? columns.tapEmployeesRequisition
            /*  DEM0067988 - TAP Access based on requisition Ends */
            : columns.employees
        }
        query={tableQuery}
        where={tableWhere}
        isEuroSort={forceCurrency === "EUR" ? true : false}
        config={config}
        account={account}
      />

      {/* DEFECT No. 14 Ends  */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
    messages: state.messages,
    // {/* DEFECT No. 14 Statrs  */}
    config: state.config,
    // {/* DEFECT No. 14 Ends  */}
  };
};

export default connect(mapStateToProps, {
  showModal,
  toggleShowInEur,
  unselectAllRecord,
})(Employees);
