import { optionsFilter } from '../../filters';
export default (table) => {
  let name = 'promotion_job_grade';
  let options = optionsFilter;
 
  let defaultValue = (value, data) => data.job_grade;

  switch (table) {
    case 'editCompensation':
      case 'editInternalHire':
      name = 'request.promotion_job_grade';
      defaultValue = (value, data) => data.request.for.job_grade;
      break;
    default:
      break;
  }

  let property = {
    type: "string",
    name,
    label: "New Job Grade",
    section: "Promotion",
    visible: true,
    defaultValue,
    justify: 'center',
    width: '65px'
  }
/*   if (table == "internalHire") {
    property.inputType = "select";
    property.options = options;
    property.editable =  true;
  } */

  return property;
};