import React from 'react';
/*Release 2 - budget management column*/
export default (table) => {
    let renderer = (value, data) => {
        console.log("Budget Check" , data);
        const forecast = data.budget - data.budget_in_progress - data.budget_spent;
       return  <>&#8364; { forecast.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</>

    };

    return {
        name: "budget_forecast",
        label: "Forecast",
        section: "Budget Management",
        visible: true,
        sortable: true,
        width: '10%',
        renderer
    };
};