import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const LogoLink = styled(Link)`
  width: 93px;
  margin-right: 2rem;
`;

const LogoutLink = styled.a`
  color: #0065ed;
  text-decoration: underline;
  padding-left: 0.5rem;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.strongTextColor};
    text-decoration: none;
  }
`;
{/*  Added for admin calculations for R2 */}
const CommonLink = styled.a`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.strongTextColor};
    text-decoration: none;
  }
`;

export {
  LogoLink,
  LogoutLink,
  CommonLink
};
