import React from 'react';
   /*Release 2 - budget management column*/
export default (table) => {
   // let  renderer=(value, data) => <>&#8364; { data.budget_available.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }</>;
    let renderer = (value, data) => {
      console.log("Budget Check" , data);
      const avail = data.budget  - data.budget_spent;
     return  <>&#8364; { avail.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</>

  };
      return {
           name: "budget_available", 
           label: "Available",
           section: "Budget Management",
           visible: true,
           sortable: true,
           width:'10%',
           renderer
      };
    };