import React from 'react';
import { PaginationWrap, PageSizeWrap, PaginationNavigationWrap } from '../../';

const MIN_PAGES_IN_RANGE = 1;
const MAX_PAGES_IN_RANGE = 5;

const Pagination = props => {

  const { count, pageSize, currentPage, onPageSizeChange, onPageChange, reqFrom,showCount } = props;


  // const [currentPage, setCurrentPage] = useState(1);

  const numberOfPages = Math.ceil(count / pageSize);
  const pagingRangeLength = MAX_PAGES_IN_RANGE > numberOfPages ? numberOfPages : MAX_PAGES_IN_RANGE;
  let startingPageInRange = currentPage - Math.floor(pagingRangeLength / 2);
  startingPageInRange = Math.max(startingPageInRange, MIN_PAGES_IN_RANGE);
  startingPageInRange = Math.min(startingPageInRange, MIN_PAGES_IN_RANGE + numberOfPages - pagingRangeLength);
  const pagingRange = Array.from({ length: pagingRangeLength }, (el, i) => startingPageInRange + i);

  const handlePageChange = page => async event => {
    let goto = page;
    switch (page) {
      case 'first':
        goto = 1;
        break;
      case 'next':
        goto = currentPage + 1 > numberOfPages ? numberOfPages : currentPage + 1;
        break;
      case 'previous':
        goto = currentPage - 1 || 1;
        break;
      case 'last':
        goto = numberOfPages;
        break;
      default:
        goto = page;
        break;
    }
    // setCurrentPage(currentPage => goto);
    onPageChange(goto);
  };
  //Release 2 - Mass Off Cycle reducing pagination - Starts
  let options;
  if (reqFrom && reqFrom === "moc") {
    options = <> <option value="10">10</option>
      <option value="20">20</option>
      <option value="40">40</option> </>;
  } else {
    options = <> <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="200">200</option>
      <option value="500">500</option>
      <option value="1000">1000</option></>;
  }
  //Release 2 - Mass Off Cycle reducing pagination - Ends

  return (
    <PaginationWrap>
      <PageSizeWrap>
        <span>Show</span>
        <select value={pageSize} onChange={onPageSizeChange}>
            {/* R2 Wave 3: To restrict pagination in MOC review and submit - Starts*/}
         {options}
          {/* R2 Wave 3: To restrict pagination in MOC review and submit - Ends*/}
        </select>
      {/* R2 Wave 3: to show count in MOC screen */}
      {showCount && <div style={{display: "inline-block",marginLeft: "50%",fontWeight:"bold"}}>Total Records: {count}</div>}
      {/* R2 Wave 3: to show count in MOC screen */}
      </PageSizeWrap>
      <PaginationNavigationWrap>
        <div onClick={handlePageChange('first')}>First |</div>
        <div onClick={handlePageChange('previous')}>Previous |</div>
        {pagingRange.map(pg => {
          return (
            <div key={Math.random()} className={pg === currentPage ? 'current' : ''} active={pg === currentPage ? 'true' : 'false'} onClick={handlePageChange(pg)}>{pg}{','}</div>
          );
        })}
        <div onClick={handlePageChange('next')}>| Next |</div>
        <div onClick={handlePageChange('last')}>Last</div>
      </PaginationNavigationWrap>
    </PaginationWrap>
  );
};

export { Pagination };
