//Release 2 Admin Table requirement
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import exportFromJSON from "export-from-json";
import { apolloClient, apolloProxyClient } from "../index";
import { Grid } from "react-awesome-spinners";
import * as queries from "../graphql";
import { showModal, toggleShowInEur } from "../actions";
import {
  GlobalMessages,
  Header,
  Table,
  TableHeadingWrap,
  TableActionsWrap,
  TableAction,
} from "../components";
import { columns } from "../util/config";

const AdminTable = (props) => {
  /* Release 3.1 - Starts */
  const ddlOptions = [
    {
      columnsString: JSON.stringify(columns.admin_itp),
      text: "Theoretical ITP",
      value: "itpsForAdmin",
    },
    {
      columnsString: JSON.stringify(columns.admin_gpp),
      text: "GPP Guideline",
      value: "gppsForAdmin",
    },
    {
      columnsString: JSON.stringify(columns.admin_con),
      text: "Countries",
      value: "conForAdmin",
    },
    {
      columnsString: JSON.stringify(columns.admin_inc),
      text: "Increases",
      value: "incForAdmin",
    },
    {
      columnsString: JSON.stringify(columns.admin_tapUsers),
      text: "TAP Users",
      value: "tapUsersForAdmin",
    },
    /* DEM0065375 - External Users Access Starts  */
    {
      columnsString: JSON.stringify(columns.admin_externalTapUsers),
      text: "External TAP Employees",
      value: "externalTapUsersForAdmin",
    },
    /* DEM0065375 - External Users Access Ends  */
    {
      columnsString: JSON.stringify(columns.admin_hrbpUsers),
      text: "PP Users",
      value: "orgHRBPUsersForAdmin",
    },
    {
      columnsString: JSON.stringify(columns.admin_adminUsers),
      text: "Admin Users",
      value: "orgAdminUsersForAdmin",
    },
    {
      columnsString: JSON.stringify(columns.admin_gctUsers),
      text: "GCT Users",
      value: "gctUsersForAdmin",
    },
    {
      columnsString: JSON.stringify(columns.admin_hrConnection),
      /* Release 3.1 - 4.6 - 145709 - Starts */
      text: "HR Services",
      /* Release 3.1 - 4.6 - 145709 - Ends */
      value: "hrConnectionForAdmin",
    },
    /* Release 3.2 - 10.1 - Starts */
    {
      columnsString: JSON.stringify(columns.admin_rbcbUsers),
      text: "Regional R&R Users",
      value: "orgRBCBUsersForAdmin",
    },
    /* Release 3.2 - 10.1 - Ends */
    /* Release 3.2 - 10.4 - Starts */
    {
      columnsString: JSON.stringify(columns.admin_keyUsers),
      text: "Key Users",
      value: "orgKeyUsersForAdmin",
    },
    /* Release 3.2 - 10.4 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    {
      columnsString: JSON.stringify(columns.admin_businessOwners),
      text: "Business Owners",
      value: "orgBusinessOwnersForAdmin",
    },
    /* Release 3.2 - 1.1 - Ends */
  ];
  const [tableRefreshKey, setTableRefreshKey] = useState(1);
  /* Release 3.1 - Ends */
  const { messages, showModal, account, config } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { roles } = account;

  const isAdmin = roles.includes("admin");
  /* Release 3.2 - 10.1 - Starts */
  const isRcb = roles.includes("rcb");
  /* Release 3.2 - 10.1 - Ends */

  const [tableQuery, setTableQuery] = useState("itpsForAdmin");
  const [tableColumn, setTableColumn] = useState(columns.admin_itp);
  const [tablechange, setTableChange] = useState(false);

  const hasGlobalMessages = messages.length > 0;

  const visible = (isAdmin /* Release 3.2 - 10.1 - Starts */ || isRcb) /* Release 3.2 - 10.1 - Ends */;

  const tableSelectionRef = useRef(null);

  const handleImportData = (event) => {
    showModal({ component: "ATImport", content: tableQuery });
  };

  const handleExportData = (event) => {
    httpClient
      .query({
        query: queries[tableQuery],
        variables: { offset: 0 },
      })
      .then((response) => {
        // const responseData = response && response[tableQuery];

        let data = response.data[tableQuery].items.map(
          ({
            /* Release 3.1 - Starts */
            upd_ins_by,
            /* Release 3.1 - Ends */
            upd_ins_ts,
            ORG1_HRBP_persno,
            ORG2_HRBP_persno,
            ORG3_HRBP_persno,
            __typename,
            ...rest
          }) => rest
        );
        /* Release 3.1 - 4.6 - 145709 - Starts */
        if(tableQuery == "hrConnectionForAdmin") {
          data = data.map(
            ({
              full_name,
              ...rest
            }) => rest
          );
        }
        /* Release 3.1 - 4.6 - 145709 - Ends */
        data = data.map((val) => {
          var temp = Object.assign({}, val);
          if (temp.hasOwnProperty("spread")) {
            if (temp.spread === null) {
              temp.spread = "";
            }
          }

          if (temp.hasOwnProperty("min")) {
            if (temp.min === null) {
              temp.min = "";
            }
          }
          if (temp.hasOwnProperty("max")) {
            if (temp.max === null) {
              temp.max = "";
            }
          }
          return temp;
        });
        /* Release 3.1 - Starts */
        if (data && data.length > 0) {
          data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':""'));
        }
        else {
          let column = tableColumn.map(a => a.name).reduce((a, v) => ({ ...a, [v]: ''}), {}) ;
          data.push(column);
          data = data.map(
            ({
              upd_ins_by,
              upd_ins_ts,
              ORG1_HRBP_persno,
              ORG2_HRBP_persno,
              ORG3_HRBP_persno,
              ...rest
            }) => rest
          );
        }
        /* Release 3.1 - Ends */
        const fileName = tableQuery + "_" + new Date().toISOString();
        const exportType = "csv";

        exportFromJSON({ data, fileName, exportType });
      })
      .catch((err) => console.error(err));
  };
  const refreshData = () => {
    var ev = new Event("change", { bubbles: true });
    //ev.simulated = true;
    tableSelectionRef.current.dispatchEvent(ev);
  };

  const tableChange = (event) => {
    setTableQuery(event.target.value);
    console.log(event.target.value)
    console.log(event.target.selectedOptions[0])
    setTableColumn(
      JSON.parse(event.target.selectedOptions[0].getAttribute("data-col"))
    );
    setTableChange(tablechange ? false : true);
  };
  /* Release 3.1 - Starts */
  useEffect(() => {
    if (messages.length > 0) {
      setTableRefreshKey(Math.floor(Math.random() * 86));
    }
  }, [messages]);
  /* Release 3.1 - Ends */
  return (
    <div style={{ fontSize: "0.8rem" }}>
      <Header {...props} />

      {hasGlobalMessages && <GlobalMessages />}
      <TableHeadingWrap
        style={{ justifyContent: "center", marginBlockEnd: "1em" }}
      >
        <div
          style={{
            width: "23%",
            display: "flex",
            justifyContent: "left",
            gap: "10px",
            alignItems: "baseline",
            borderRight: "2px solid rgba(128, 128, 128, 0.63)",
          }}
        >
          <h2
            style={{
              marginBlockStart: "0em",
              marginBlockEnd: "0em",
            }}
          >
            Admin Tables Management
          </h2>
        </div>

        {visible && (
          <div
            style={{
              width: "58%",
              display: "flex",
              justifyContent: "left",
              paddingLeft: "2%",
              fontWeight: "500",
              fontSize: "15px",
              gridGap: "10px",
            }}
          >
            <label style={{ paddingTop: "0.5%" }}> Select Table : </label>
            <select
              value={tableQuery}
              onChange={tableChange}
              ref={tableSelectionRef}
              id="frmAssigneeLI_admin-table-select"
            >
              {/* Release 3.1 - Starts */}
              {ddlOptions.map(({ text, value, columnsString }) => (
                <option data-col={columnsString} value={value} key={value}>
                  {text}
                </option>
              ))}
              {/* Release 3.1 - Ends */}
            </select>
          </div>
        )}

        {(isAdmin /* Release 3.2 - 10.1 - Starts */ || isRcb)  /* Release 3.2 - 10.1 - Ends */&& (
          <TableActionsWrap>
            {/* <TableAction onClick={refreshData}>Refresh</TableAction> */}
            <TableAction onClick={handleExportData}>
              Export To Excel
            </TableAction>
            <TableAction onClick={handleImportData}>Import</TableAction>
          </TableActionsWrap>
        )}
      </TableHeadingWrap>

      <Table
        recordType="AdminTable"
        columns={tableColumn}
        query={tableQuery}
        isChange={tablechange}
        config={config}
        account={account}
        /* Release 3.1 - Starts */
        key={tableRefreshKey}
        /* Release 3.1 - Ends */
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
    messages: state.messages,
    config: state.config,
  };
};

export default connect(mapStateToProps, { showModal, toggleShowInEur })(
  AdminTable
);
