/* Release 3.2 - 1.1 - Starts */
import { 
  UNSELECT_BUSINESSOWNERBUDGET,
  SELECT_BUSINESSOWNERBUDGET,
  UPDATE_BUSINESSOWNERBUDGET,
  UNSELECT_ALL_BUSINESSOWNERBUDGETS } from '../actions/index';


export default (state = [], action) => {
  switch (action.type) {
    case SELECT_BUSINESSOWNERBUDGET:
      return state.find(i => action.payload[action.indexColumn.name] === i[action.indexColumn.name]) ? state : state.concat([ action.payload]);
    case UNSELECT_BUSINESSOWNERBUDGET:
      return state.filter(item => action.payload[action.indexColumn.name] !== item[action.indexColumn.name]);
    case UNSELECT_ALL_BUSINESSOWNERBUDGETS:
      return [];
      
    case UPDATE_BUSINESSOWNERBUDGET:
      return state.map((item, index) => {
        if (item[action.indexColumn.name] === action.id) {
          return {...item, ...action.data};
        }
        return item;
      });
    default:
      return state;
  }
};
/* Release 3.2 - 1.1 - Ends */