/* Release 3.1 - Starts */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Ring } from "react-awesome-spinners";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import {
  FormHeading,
  FormLabel,
  SelectedTable,
  ErrorsBlock,
  IconWrap,
  FormButtonsWrap,
  FormButton,
  LoaderWrap,
  WarningsBlock,
  IconWrapWithoutBorder,
} from "../";
import { columns } from "../../util/config";
import { unselectAllRecord, pushMessage } from "../../actions";
import {
  CHANGE_APPROVER_REQUEST,
  approvals,
  PEOPLE_SUGGEST,
} from "../../graphql";
import { apolloClient, apolloProxyClient } from "../../index";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./ChangeApproverRequest.css";

const ChangeApproverRequest = (props) => {
  const [submitWarnings, setSubmitWarnings] = useState([]);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [approveRequest] = useMutation(CHANGE_APPROVER_REQUEST);
  const [approvers, setApprovers] = useState([]);

  const {
    selectedRequests,
    account,
    hideModal,
    unselectAllRecord,
    pushMessage,
  } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;

  useEffect(() => {
    if (selectedRequests && selectedRequests.length > 0) {
      const requestData = selectedRequests[0];
      const approvals = requestData.request.approvals;
      if (approvals && approvals.length > 0) {
        approvals.sort((a, b) => a.approver_level - b.approver_level);
        let newApprovers = [];
        approvals.forEach((element) => {
          const approverEmployeeDeatails = element.approver;
          newApprovers.push({
            id: element.id,
            employee_request_id: element.employee_request_id,
            /* Release 3.1 - 4.1 - Starts */
            email_work: approverEmployeeDeatails && approverEmployeeDeatails.email_work ? approverEmployeeDeatails.email_work : null,
            full_name: approverEmployeeDeatails && approverEmployeeDeatails.full_name ? approverEmployeeDeatails.full_name : null,
            nokia_id: approverEmployeeDeatails && approverEmployeeDeatails.nokia_id ? approverEmployeeDeatails.nokia_id : null,
            /* Release 3.1 - 4.1 - Ends */
            approver_pers_no: element?.approver_pers_no,
            approval_status: element?.approval_status,
            approver_level: element?.approver_level,
            selected_approver: approverEmployeeDeatails
              ? {
                  label:
                    /* Release 3.1 - 4.1 - Starts */
                    approverEmployeeDeatails.full_name ? approverEmployeeDeatails.full_name : '' +
                    " (" +
                    approverEmployeeDeatails.nokia_id ? approverEmployeeDeatails.nokia_id : '' +
                    ") (" +
                    approverEmployeeDeatails.email_work ? approverEmployeeDeatails.email_work : '' +
                    ")",
                  value: approverEmployeeDeatails.pers_no ? approverEmployeeDeatails.pers_no : element.approver_pers_no,
                    /* Release 3.1 - 4.1 - Ends */
                }
              : {
                  label: "Inactive approver",
                  value: element.approver_pers_no,
                },
            selected_approver_pers_no: element.approver_pers_no,
            approver_is_active: approverEmployeeDeatails ? true : false,
          });
        });
        setApprovers(newApprovers);
      }
    }
  }, []);

  const handleQuit = () => {
    hideModal();
    unselectAllRecord("requests");
  };

  const handleApproveSend = async () => {
    setIsSubmitting(true);
    setSubmitErrors([]);
    setSubmitWarnings([]);

    let changeApprovers = [];
    approvers.forEach((item) => {
      if (item.approver_pers_no != item.selected_approver_pers_no) {
        changeApprovers.push({
          employee_request_id: item.employee_request_id,
          approval_id: item.id,
          approval_status: item.approval_status,
          pre_approver_pers_no: item.approver_pers_no,
          post_approver_pers_no: item.selected_approver_pers_no,
          pre_approver_is_active: item.approver_is_active,
          upd_ins_pers_no: account.pers_no,
        });
      }
    });

    if (changeApprovers && changeApprovers.length == 0) {
      setSubmitWarnings(["Please change at least one approver."]);
      setIsSubmitting(false);
      return false;
    }

    const results = await Promise.all(
      changeApprovers.map(
        async ({
          employee_request_id,
          approval_id,
          approval_status,
          pre_approver_pers_no,
          post_approver_pers_no,
          pre_approver_is_active,
          upd_ins_pers_no,
        }) => {
          try {
            await approveRequest({
              variables: {
                employee_request_id,
                approval_id,
                approval_status,
                pre_approver_pers_no,
                post_approver_pers_no,
                pre_approver_is_active,
                upd_ins_pers_no,
              },
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: approvals,
                  variables: {
                    where: {
                      approver_pers_no: { ne: account.pers_no },
                      approval_status: { eq: "active" },
                    },
                    orderBy: [{ column: "employee_request_id", order: "DESC" }],
                    limit: 20,
                    offset: 0,
                  },
                },
              ],
            });
            return { [employee_request_id]: false };
          } catch (e) {
            return { [employee_request_id]: e.message };
          }
        }
      )
    );

    const errors = results.filter((r) =>
      Object.keys(r).find((p_no) => r[p_no])
    );
    if (!errors.length) {
      hideModal();
      unselectAllRecord("requests");
      pushMessage({
        type: "success",
        message: "Approver request(s) successfully change.",
      });
    } else {
      setSubmitErrors(errors.map((e) => Object.values(e).shift()));
      setIsSubmitting(false);
    }
  };

  const fetchApprover = (input) => {
    if (input.length >= 3) {
      return new Promise((resolve, reject) => {
        httpClient
          .query({
            query: PEOPLE_SUGGEST,
            variables: { where: { searchvalue: input }, limit: 15, offset: 0 },
          })
          .then((response) => {
            if (response.data && response.data.match_people) {
              resolve(
                response.data.match_people.items.map((a) => ({
                  label:
                    a.full_name +
                    " (" +
                    a.nokia_id +
                    ") (" +
                    a.email_work +
                    ")",
                  value: a.pers_no,
                  Name: a.full_name,
                }))
              );
            }
          })
          .catch((err) => console.error(err));
      });
    } else {
      return new Promise((resolve) => { resolve() });;
    }
  };

  const handleSelectOnChange = (e, index) => {
    let newApprover = [...approvers];
    newApprover[index].selected_approver = e;
    newApprover[index].selected_approver_pers_no = e.value;
    setApprovers(newApprover);
  };

  const LoadingIndicator = (e) => {
    return (
      <>
        {e.selectProps.inputValue &&
        e.selectProps.inputValue.replace(/\s+/g, "").length >= 3
          ? <span className="loading">...</span>
          : ""}
      </>
    );
  };
  return (
    <div>
      <FormHeading>Change Approver request</FormHeading>
      <SelectedTable
        recordType="requests"
        items={selectedRequests}
        columns={columns.changeApprovers}
      />
      {approvers.map((value, index) => {
        return (
          <div className="row-group" key={index}>
            <div className="col-label">
              <FormLabel>Level {value.approver_level}</FormLabel>
            </div>
            <div className="col-select">
              <AsyncSelect
                components={{ LoadingIndicator }}
                loadingMessage={(e) =>
                  e.inputValue && e.inputValue.replace(/\s+/g, "").length >= 3
                    ? "Loading"
                    : "Type at least three character"
                }
                noOptionsMessage={(e) =>
                  e.inputValue && e.inputValue.replace(/\s+/g, "").length >= 3
                    ? "No matches found"
                    : "Type at least three character"
                }
                loadOptions={fetchApprover}
                onChange={(e) => handleSelectOnChange(e, index)}
                placeholder="Type at least three character"
                className="select"
                value={value.selected_approver}
                isDisabled={
                  value.approval_status != "active" &&
                  value.approval_status != "pending"
                }
              />
            </div>
          </div>
        );
      })}
      {submitErrors.length > 0 &&
        submitErrors.map((err) => {
          return (
            <>
              <br />
              <ErrorsBlock key={Math.random()}>
                <IconWrap>!</IconWrap>
                {err}
              </ErrorsBlock>
            </>
          );
        })}
      {submitWarnings.length > 0 &&
        submitWarnings.map((err) => {
          return (
            <>
              <br />
              <WarningsBlock key={Math.random()}>
                <IconWrapWithoutBorder>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{
                      marginBottom: "5.5%",
                      marginRight: "3%",
                    }}
                  />
                </IconWrapWithoutBorder>
                {err}
              </WarningsBlock>
            </>
          );
        })}
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
        <FormButton primary disabled={isSubmitting} onClick={handleApproveSend}>
          Submit
        </FormButton>
        {isSubmitting && (
          <LoaderWrap>
            <Ring color={"#001235"} size="2" sizeUnit="rem" />
          </LoaderWrap>
        )}
      </FormButtonsWrap>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
  };
};

export default connect(mapStateToProps, { unselectAllRecord, pushMessage })(
  ChangeApproverRequest
);
/* Release 3.1 - Ends */
