import React from "react";
import {
  dateRenderer,
  dateTimeRenderer,
  currencyDisplayRenderer,
  percentRenderer,
  incentivePlanRenderer,
  numberRenderer,
  optionsFilter,
  currencySymbolRenderer,
  peoplePicker,
  //reason with issue
  reasonRenderer,
  /* Release 3.2 - 9.1Req - Starts */
  dateRendererFromString,
  currencySymbolRendererForSimulation,
  /* Release 3.2 - 9.1Req - Ends */
} from ".";

import fields from "./fields/table";
import tooltipFields from "./fields/tooltips";

import { Status, RequestTooltip } from "../components/request";
import Value from "react-select-2/lib/Value";

const columns = {
  editCompensation: [
    { index: true, name: "employee_request_id" },
    fields.nokia_id("editCompensation"),
    fields.full_name("editCompensation"),
    fields.promotion_job_family_name("editCompensation"),
    fields.promotion_job_subfamily_name("editCompensation"),
    fields.promotion_job_name("editCompensation"),
    fields.promotion_job_grade("editCompensation"),
    fields.new_position_title("editCompensation"),
    fields.fte_ttc_annual_salary("editCompensation"),
    fields.ttc_increase_amount("editCompensation"),
    {
      type: "float",
      inputType: "number",
      name: "request.ttc_increase_percentage",
      label: "Total Target Cash Increase %",
      section: "Merit/Promotion Increase",
      visible: true,
      editable: true,
      renderer: numberRenderer,
    },
    {
      type: "float",
      name: "request.new_fte_ttc_annual_salary",
      label: "New Total Target Cash",
      section: "New",
      visible: true,
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    // Current Section
    tooltipFields.current_country_name("editCompensation"),
    tooltipFields.job_family_name("editCompensation"),
    tooltipFields.job_subfamily_name("editCompensation"),
    tooltipFields.job_name("editCompensation"),
    {
      name: "request.for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: true,
    },
    {
      name: "request.for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: true,
    },
    tooltipFields.fte_annual_salary("editCompensation"),
    tooltipFields.fte_incentive_target_percentage("editCompensation"),
    tooltipFields.fte_incentive_target_amount("editCompensation"),
    tooltipFields.fte_ttc_annual_salary("editCompensation"),
    {
      name: "request.for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: true,
      renderer: numberRenderer,
    },
    {
      name: "request.for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: true,
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: true,
    },
    // New Section
    tooltipFields.new_country_name("editCompensation"),
    tooltipFields.promotion_job_family_name("editCompensation"),
    tooltipFields.promotion_job_subfamily_name("editCompensation"),
    tooltipFields.promotion_job_name("editCompensation"),
    tooltipFields.promotion_job_grade("editCompensation"),
    tooltipFields.new_position_title("editCompensation"),
    tooltipFields.new_fte_annual_salary("editCompensation"),
    tooltipFields.new_incentive_target_percentage("editCompensation"),
    tooltipFields.new_fte_incentive_target_amount("editCompensation"),
    {
      name: "request.new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    {
      name: "request.new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "request.new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
       /* Release 3.2 - 1.1 - Starts */
       (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
       /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) =>
        value || data.request.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "request.effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "request.payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type === "onetime" || data.request.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    /* Release 3.2 - 11.2 - Starts */
    // {
    //   name: "request.reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "request.reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    {
      name: "request.offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        /* Release 3.2 - 1.1 - Starts */
        data.request.request_type !== "businessexcellenceaward",
        /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "request.offcycle_budget_update",
      label: "BEA Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        data.request.request_type === "businessexcellenceaward",
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Ends */
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "request.comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward")
      /* Release 3.2 - 1.1 - Ends */ 
      && data.request.comments.length > 0,
      renderer: (value, data) =>
        data.request.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.request.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.request.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
  ],
  editOnetime: [
    { index: true, name: "employee_request_id" },
    fields.nokia_id("editOnetime"),
    fields.full_name("editOnetime"),
    fields.fte_ttc_annual_salary("editOnetime"),
    fields.ttc_increase_amount("editOnetime"),
    fields.lumpsum_abs_percentage("editOnetime"),
    {
      type: "float",
      inputType: "number",
      name: "request.ttc_increase_percentage",
      label: "% In Relation To Total Target Cash",
      section: "Merit/Promotion Increase",
      visible: true,
      renderer: percentRenderer,
    },
    {
      type: "string",
      inputType: "select",
      name: "request.payment_type",
      label: "Payment Type",
      section: "Merit/Promotion Increase",
      visible: true,
      editable: true,
      options: optionsFilter,
    },
    // Current Section
    tooltipFields.current_country_name("editOnetime"),
    tooltipFields.job_family_name("editOnetime"),
    tooltipFields.job_subfamily_name("editOnetime"),
    tooltipFields.job_name("editOnetime"),
    {
      name: "request.for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: true,
    },
    {
      name: "request.for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: true,
    },
    tooltipFields.fte_annual_salary("editOnetime"),
    tooltipFields.fte_incentive_target_percentage("editOnetime"),
    tooltipFields.fte_incentive_target_amount("editOnetime"),
    tooltipFields.fte_ttc_annual_salary("editOnetime"),
    {
      name: "request.for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: true,
      renderer: numberRenderer,
    },
    {
      name: "request.for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: true,
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: true,
    },
    // Date
    {
      name: "request.effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "request.payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type === "onetime" || data.request.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    /* Release 3.2 - 11.2 - Starts */
    // {
    //   name: "request.reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "request.reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    {
      name: "request.offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        /* Release 3.2 - 1.1 - Starts */
         data.request.request_type !== "businessexcellenceaward",
        /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "request.offcycle_budget_update",
      label: "BEA Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        data.request.request_type === "businessexcellenceaward",
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Ends */
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "request.comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward")
      /* Release 3.2 - 1.1 - Ends */ 
      && data.request.comments.length > 0,
      renderer: (value, data) =>
        data.request.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.request.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.request.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
  ],
  /** release 2 - admin tables columns - starts */
  admin_gpp: [
    {
      index: true,
      name: "gpp_guidelines_id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      /* Release 3.1 - Starts */
      width: "5%",
      /* Release 3.1 - Ends */
    },
    {
      name: "country_code",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Country Code",
      /* Release 3.1 - Starts */
      width: "15%",
      /* Release 3.1 - Ends */
    },
    {
      name: "quartile",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Quartile",
      width: "25%",
    },
    {
      name: "max_guidelines",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Maximum Guidelines",
      width: "20%",
    },
    /* Release 3.1 - Starts */
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "15%",
    },
    /* Release 3.1 - Ends */
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      /* Release 3.1 - Starts */
      width: "20%",
      /* Release 3.1 - Ends */
    },
  ],
  admin_itp: [
    {
      index: true,
      name: "Id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "job_family_name",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      /* Release 3.1 - Starts */
      label: "Job Family Name",
      width: "20%",
      /* Release 3.1 - Ends */
    },
    {
      name: "country_name",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      /* Release 3.1 - Starts */
      label: "Country Name",
      width: "20%",
      /* Release 3.1 - Ends */
    },
    {
      name: "job_grade",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Job Grade",
      /* Release 3.1 - Starts */
      width: "10%",
      /* Release 3.1 - Ends */
    },
    {
      name: "itp",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ITP",
      width: "10%",
    },
    /* Release 3.1 - Starts */
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "15%",
    },
    /* Release 3.1 - Ends */
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      /* Release 3.1 - Starts */
      width: "20%",
      /* Release 3.1 - Ends */
    },
  ],
  admin_con: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "country",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Country",
      width: "25%",
    },
    {
      name: "spread",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Spread",
      /* Release 3.1 - Starts */
      width: "20%",
      /* Release 3.1 - Ends */
    },
    {
      name: "grade_progression",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Grade Progression",
      /* Release 3.1 - Starts */
      width: "20%",
      /* Release 3.1 - Ends */
    },
    /* Release 3.1 - Starts */
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "15%",
    },
    /* Release 3.1 - Ends */
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      /* Release 3.1 - Starts */
      width: "15%",
      /* Release 3.1 - Ends */
    },
  ],
  admin_inc: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "combo",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Combo",
      width: "25%",
    },
    //MIN WAS MISSING IN PROJQAW2 BRANCH WEIRD  -  ADDED IT AGAIN
    {
      name: "min",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      type: "float",
      inputType: "number",
      label: "Minimum",
      /* Release 3.1 - Starts */
      width: "10%",
      /* Release 3.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "max",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      type: "float",
      inputType: "number",
      label: "Maximum",
      /* Release 3.1 - Starts */
      width: "10%",
      /* Release 3.1 - Ends */
      renderer: numberRenderer,
    },
    /* Release 3.1 - 4.6 - Issue 145707 - Starts */
    {
      name: "country",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Country",
      /* Release 3.1 - Starts */
      width: "20%",
      /* Release 3.1 - Ends */
    },
    /* Release 3.1 - 4.6 - Issue 145707 - Ends */
    /* Release 3.1 - Starts */
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "15%",
    },
    /* Release 3.1 - Ends */
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      /* Release 3.1 - Starts */
      width: "15%",
      /* Release 3.1 - Ends */
    },
  ],
  /** release 2 - admin tables columns - ends */
  requestOnetime: [
    { index: true, name: "pers_no" },
    fields.nokia_id("requestOnetime"),
    fields.full_name("requestOnetime"),
    fields.fte_ttc_annual_salary("requestOnetime"),
    fields.ttc_increase_amount("requestOnetime"),
    fields.lumpsum_abs_percentage("requestOnetime"),
    {
      type: "float",
      inputType: "number",
      name: "ttc_increase_percentage",
      label: "% In Relation To Total Target Cash",
      section: "Merit/Promotion Increase",
      visible: true,
      editable: false,
      renderer: percentRenderer,
    },
    {
      type: "string",
      inputType: "select",
      name: "payment_type",
      label: "Payment Type",
      section: "Merit/Promotion Increase",
      visible: true,
      editable: true,
      options: optionsFilter,
    },
    {
      type: "string",
      name: "payment_reason",
      label: "Payment Reason",
      section: "Merit/Promotion Increase",
      visible: false,
      editable: false,
    },
    fields.target_budget_org("requestOnetime"),
    // Current Section
    tooltipFields.current_country_name("requestOnetime"),
    tooltipFields.job_family_name("requestOnetime"),
    tooltipFields.job_subfamily_name("requestOnetime"),
    tooltipFields.job_name("requestOnetime"),
    {
      name: "job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: true,
    },
    {
      name: "position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: true,
    },
    tooltipFields.fte_annual_salary("requestOnetime"),
    tooltipFields.fte_incentive_target_percentage("requestOnetime"),
    tooltipFields.fte_incentive_target_amount("requestOnetime"),
    tooltipFields.fte_ttc_annual_salary("requestOnetime"),
    {
      name: "ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: true,
      renderer: numberRenderer,
    },
    {
      name: "incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: true,
      renderer: incentivePlanRenderer,
    },
    {
      name: "ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: true,
    },
    // Budget Section
    {
      name: "offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: true,
      renderer: currencyDisplayRenderer,
    },
  ],
  editInternalHire: [
    { index: true, name: /* Release 3.2 - 13.1 -Starts */ "employee_request_id" /* Release 3.2 - 13.1 -Ends */ },
    fields.rr_number("editInternalHire"),
    fields.nokia_id("editInternalHire"),
    {
      type: "string",
      name: "request.for.full_name",
      label: "Name",
      section: "Current Information",
      visible: true,
      width: "10%",
    },
    //fields.full_name('internalHire'),
    //fields.promotion_job_family_name('internalHire'),
    //fields.promotion_job_grade('internalHire'),
    fields.new_incentive_scheme("editInternalHire"),
    //fields.promotion_job_family_name('editInternalHire'),
    fields.promotion_job_subfamily_name("editInternalHire"),
    fields.promotion_job_name("editInternalHire"),
    fields.promotion_job_grade("editInternalHire"),
    fields.new_position_title("editInternalHire"),
    fields.fte_ttc_annual_salary("editInternalHire"),
    {
      type: "string",
      name: "request.promotional_increase_percentage_guideline",
      section: "internalHire",
      label: "Promotional increase % guideline",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    fields.ttc_increase_amount("editInternalHire"),
    fields.ttc_increase_perc("editInternalHire"),
    {
      type: "float",
      inputType: "number",
      name: "request.case_3_12_month_actual_incentive_amount_fte",
      label: /* Release 3.2 - 13.1 - 149299 - Starts */ "Case 3-12 months Base Salary paid (FTE)"/* Release 3.2 - 13.1 - 149299 - Ends */,
      section: "editInternalHire",
      visible: true,

      disabled: true,
      colFrom: "internalHire",

      width: "10%",
      renderer: numberRenderer,

      editable: true,
      inputType: "number",
      // before : currencySymbolRenderer,
    },
    /* Release 3.2 - 13.1 - Starts */
    fields.addl_pay_amount("editInternalHire"),
    fields.sum_of_pay_amount("editInternalHire"),
    /* Release 3.2 - 13.1 - Ends */
    fields.new_abs("editInternalHire"),
    fields.new_ita("editInternalHire"),
    fields.new_itp("editInternalHire"),
    {
      type: "float",
      name: "request.override_transition_allowance_local_currency",
      label: "Override Transition allowance (Local Currency)",
      section: "editInternalHire",
      colFrom: "internalHire",
      disabled: true,
      visible: true,

      width: "10%",
      renderer: numberRenderer,

      editable: true,
      inputType: "number",
      // before : currencySymbolRenderer,
    },
    {
      type: "float",
      name: "request.new_fte_ttc_annual_salary",
      label: "New Total Target Cash",
      section: "New",
      visible: true,
      //  renderer: currencyDisplayRenderer,
      width: "10%",
    },
    {
      type: "float",
      name: "request.ttc_increase",
      label: "Total Increase Amount",
      section: "New",
      visible: true,
      // renderer: currencyDisplayRenderer,
      width: "10%",
    },
    {
      type: "float",
      name: "request.ttc_increase_perc",
      label: "Total Increase " + "%",
      section: "New",
      visible: true,

      width: "10%",
    },
    {
      type: "string",
      inputType: "peoplepicker",
      name: "request.hiringmanager",
      label: "Hiring Manager",
      section: "TAP Employees Information",
      visible: true,
      editable: true,
      section: "EditInternalRequest",
      //   defaultValue : (value, data) =>{
      //     console.log("People Picker", data);
      //   return  data.request?
      //    {
      //     label: data.request.hmanager.full_name + " (" + data.request.hmanager.nokia_id + ") (" + data.request.hmanager.email_work + ")",
      //     value: data.request.hmanager.pers_no
      //   } : null;
      // },
      width: "10%",
    },
    // Current Section
    tooltipFields.current_country_name("editInternalHire"),
    tooltipFields.job_family_name("editInternalHire"),
    tooltipFields.job_subfamily_name("editInternalHire"),
    tooltipFields.job_name("editInternalHire"),
    {
      name: "request.for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: true,
    },
    {
      name: "request.for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: true,
    },
    tooltipFields.fte_annual_salary("editInternalHire"),
    tooltipFields.fte_incentive_target_percentage("editInternalHire"),
    tooltipFields.fte_incentive_target_amount("editInternalHire"),
    tooltipFields.fte_ttc_annual_salary("editInternalHire"),
    {
      name: "request.for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: true,
      renderer: numberRenderer,
    },
    {
      name: "request.for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: true,
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: true,
    },
    // New Section
    tooltipFields.new_country_name("editInternalHire"),
    tooltipFields.promotion_job_family_name("editInternalHire"),
    tooltipFields.promotion_job_subfamily_name("editInternalHire"),
    tooltipFields.promotion_job_name("editInternalHire"),
    tooltipFields.promotion_job_grade("editInternalHire"),
    tooltipFields.new_position_title("editInternalHire"),
    tooltipFields.new_fte_annual_salary("editInternalHire"),
    tooltipFields.new_incentive_target_percentage("editInternalHire"),
    tooltipFields.new_fte_incentive_target_amount("editInternalHire"),
    {
      name: "request.new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    {
      name: "request.new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "request.new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) =>
        value || data.request.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "request.effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "request.payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type === "onetime" || data.request.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    /* Release 3.2 - 11.2 - Starts */
    // {
    //   name: "request.reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "request.reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    {
      name: "request.Final_Off_Cycle_Budget",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: true,
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
  ],

  internalHire: [
    { index: true, name: "pers_no" },
    fields.rr_number("internalHire"),
    fields.nokia_id("internalHire"),
    {
      type: "string",
      name: "full_name",
      label: "Name",
      section: "Current Information",
      visible: true,
      width: "10%",
    },
    //fields.full_name('internalHire'),
    //fields.promotion_job_family_name('internalHire'),
    //fields.promotion_job_grade('internalHire'),
    fields.new_incentive_scheme("internalHire"),
    //fields.promotion_job_family_name('internalHire'),
    fields.promotion_job_subfamily_name("internalHire"),
    fields.promotion_job_name("internalHire"),
    fields.promotion_job_grade("internalHire"),
    fields.new_position_title("internalHire"),
    fields.fte_ttc_annual_salary("internalHire"),
    {
      type: "string",
      name: "promotional_increase_percentage_guideline",
      section: "internalHire",
      label: "Promotional increase % guideline",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    fields.ttc_increase_amount("internalHire"),
    fields.ttc_increase_perc("internalHire"),
    {
      type: "float",
      inputType: "number",
      name: "case_3_12_month_actual_incentive_amount_fte",
      label: /* Release 3.2 - 13.1 - 149299 - Starts */"Case 3-12 months Base Salary paid (FTE)"/* Release 3.2 - 13.1 - 149299 - Ends */,
      section: "internalHire",
      visible: true,

      disabled: true,
      colFrom: "internalHire",

      width: "10%",
      renderer: numberRenderer,

      editable: true,
      inputType: "number",
      before: currencySymbolRenderer,
    },
    /* Release 3.2 - 13.1 - Starts */
    fields.addl_pay_amount("internalHire"),
    fields.sum_of_pay_amount("internalHire"),
    /* Release 3.2 - 13.1 - Ends */
    fields.new_abs("internalHire"),
    fields.new_ita("internalHire"),
    fields.new_itp("internalHire"),
    {
      type: "float",
      name: "override_transition_allowance_local_currency",
      label: "Override Transition allowance (Local Currency)",
      section: "internalHire",
      colFrom: "internalHire",
      disabled: true,
      visible: true,

      width: "10%",
      renderer: numberRenderer,

      editable: true,
      inputType: "number",
      before: currencySymbolRenderer,
    },
    {
      type: "float",
      name: "new_fte_ttc_annual_salary",
      label: "New Total Target Cash",
      section: "New",
      visible: true,
      renderer: currencyDisplayRenderer,
      width: "10%",
    },
    {
      type: "float",
      name: "ttc_increase",
      label: "Total Increase Amount",
      section: "New",
      visible: true,
      renderer: currencyDisplayRenderer,
      width: "10%",
    },
    {
      type: "float",
      name: "ttc_increase_perc",
      label: "Total Increase " + "%",
      section: "New",
      visible: true,
      renderer: percentRenderer,
      width: "10%",
    },
    {
      type: "string",
      inputType: "peoplepicker",
      name: "hiringmanager",
      label: "Hiring Manager",
      section: "TAP Employees Information",
      visible: true,
      editable: true,
      // renderer : peoplePicker,
      width: "10%",
    },
    // Current Section
    tooltipFields.current_country_name("internalHire"),
    tooltipFields.job_family_name("internalHire"),
    tooltipFields.job_subfamily_name("internalHire"),
    tooltipFields.job_name("internalHire"),
    {
      name: "job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: true,
    },
    {
      name: "position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: true,
    },
    tooltipFields.fte_annual_salary("internalHire"),
    tooltipFields.fte_incentive_target_percentage("internalHire"),
    tooltipFields.fte_incentive_target_amount("internalHire"),
    tooltipFields.fte_ttc_annual_salary("internalHire"),
    {
      name: "ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: true,
      renderer: numberRenderer,
    },
    {
      name: "incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: true,
      renderer: incentivePlanRenderer,
    },
    {
      name: "ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: true,
    },
    // New Section
    tooltipFields.new_country_name("internalHire"),
    tooltipFields.promotion_job_family_name("internalHire"),
    tooltipFields.promotion_job_subfamily_name("internalHire"),
    tooltipFields.promotion_job_name("internalHire"),
    tooltipFields.promotion_job_grade("internalHire"),
    tooltipFields.new_position_title("internalHire"),
    tooltipFields.new_fte_annual_salary("internalHire"),
    tooltipFields.new_incentive_target_percentage("internalHire"),
    tooltipFields.new_fte_incentive_target_amount("internalHire"),
    {
      name: "new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: true,
      renderer: currencyDisplayRenderer,
    },
    {
      name: "new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: true,
      renderer: numberRenderer,
    },
    {
      name: "new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: true,
      renderer: incentivePlanRenderer,
    },
    {
      name: "new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: true,
      renderer: (value, data) => value || data.ttc_employee_quartile,
    },
    // Budget Section
    {
      name: "Final_Off_Cycle_Budget",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: true,
      renderer: currencyDisplayRenderer,
    },
    {
      name: "Final_Off_Cycle_Budget_euro",
      label: "Off-Cycle Budget Spending in euro",
      tooltip_section: "Budget",
      visible_in_tooltip: true,
    },
  ],
  /* Release 3.2 - 9.1Req - Starts */
  internalHireSimulations: [
    { index: true, name: "employee_request_simulation_id", section: "internalHireSimulation" },
    {
      type: "string",
      name: "rr_number",
      section: "internalHireSimulation",
      label: "RR Number",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },{
      type: "string",
      name: "effective_date",
      section: "internalHireSimulation",
      label: "Effective Date",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
      renderer: dateRendererFromString
    },
    {
      type: "string",
      name: "nokia_id",
      section: "internalHireSimulation",
      label: "Nokia Id",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    {
      type: "string",
      //section: "internalHireSimulation",
      name: "full_name",
      label: "Name",
      section: "Current Information",
      visible: true,
      width: "10%",
    },
    {
      type: "string",
      name: "promotion_job_family_name",//new_incentive_scheme
      section: "internalHireSimulation",
      label: "New Job Family",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    {
      type: "string",
      name: "promotion_job_subfamily_name",//promotion_job_subfamily_name
      section: "internalHireSimulation",
      label: "New Job Sub Family",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    {
      type: "string",
      name: "promotion_job_name",//promotion_job_name
      section: "internalHireSimulation",
      label: "New Job",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    //fields.full_name('internalHire'),
    //fields.promotion_job_family_name('internalHire'),
    //fields.promotion_job_grade('internalHire'),
    //fields.promotion_job_family_name('internalHire'),
    
    fields.promotion_job_grade("internalHireSimulation"),
    {
      type: "string",
      name: "new_position_title",//new_position_title
      section: "internalHireSimulation",
      label: "Position Title",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    //fields.new_position_title("internalHire"),
    //fields.fte_ttc_annual_salary("internalHire"),
    {
      type: "string",
      name: "fte_ttc_annual_salary",
      section: "internalHireSimulation",
      label: "Current Total Target Cash",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    {
      type: "string",
      name: "promotional_increase_percentage_guideline",
      section: "internalHireSimulation",
      label: "Promotional increase % guideline",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    {
      type: "string",
      name: "ttc_increase_amount",//ttc_increase_amount
      section: "internalHireSimulation",
      label: "Total Target Cash Increase",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
      renderer: numberRenderer,
      before: currencySymbolRendererForSimulation
    },
    //fields.ttc_increase_amount("internalHire"),
    {
      type: "string",
      name: "ttc_increase_percentage",//ttc_increase_perc
      section: "internalHireSimulation",
      label: "Total Target Cash increase %",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
      renderer: numberRenderer
    },
    //fields.ttc_increase_perc("internalHire"),
    {
      type: "float",
      name: "case_3_12_month_actual_incentive_amount_fte",
      label: /* Release 3.2 - 13.1 - 149299 - Starts */"Case 3-12 months Base Salary paid (FTE)"/* Release 3.2 - 13.1 - 149299 - Ends */,
      section: "internalHireSimulation",
      visible: true,
      width: "10%",
      renderer: numberRenderer,
      before: currencySymbolRendererForSimulation,
    },
    /* Release 3.2 - 13.1 - Starts */
    fields.addl_pay_amount("internalHire"),
    fields.sum_of_pay_amount("internalHire"),
    /* Release 3.2 - 13.1 - Ends */
    fields.new_abs("internalHireSimulation"),
    fields.new_ita("internalHireSimulation"),
    fields.new_itp("internalHireSimulation"),
    {
      type: "float",
      name: "override_transition_allowance_local_currency",
      label: "Override Transition allowance (Local Currency)",
      section: "New",
      //colFrom: "internalHireSimulation",
      //disabled: true,
      visible: true,
      width: "10%",
      renderer: numberRenderer,
      before: currencySymbolRendererForSimulation,
    },
    {
      type: "float",
      name: "new_fte_ttc_annual_salary",
      label: "New Total Target Cash",
      section: "New",
      visible: true,
      renderer: currencyDisplayRenderer,
      width: "10%",
    },
    {
      type: "float",
      name: "ttc_increase",
      label: "Total Increase Amount",
      section: "New",
      visible: true,
      renderer: currencyDisplayRenderer,
      width: "10%",
    },
    {
      type: "float",
      name: "ttc_increase_perc",
      label: "Total Increase " + "%",
      section: "New",
      visible: true,
      renderer: percentRenderer,
      width: "10%",
    },
    {
      type: "string",
      name: "hiring_manager_detail",
      label: "Hiring Manager",
      section: "internalHireSimulation",
      visible: true,
      width: "10%",
    },
    {
      type: "string",
      name: "Final_Off_Cycle_Budget",
      label: "Off-Cycle Budget Spending",
      section: "internalHireSimulation",
      visible: true,
      width: "10%",
      renderer: currencyDisplayRenderer,
    },
    {
      type: "string",
      name: "Final_Off_Cycle_Budget_euro",
      label: "Off-Cycle Budget Spending in euro",
      section: "internalHireSimulation",
      visible: true,
      width: "10%",
    },
  ],
  /* Release 3.2 - 9.1Req - Ends */
  requestCompesation: [
    { index: true, name: "pers_no" },
    fields.nokia_id("requestCompesation"),
    fields.full_name("requestCompesation"),
    fields.promotion_job_family_name("requestCompesation"),
    fields.promotion_job_subfamily_name("requestCompesation"),
    fields.promotion_job_name("requestCompesation"),
    fields.promotion_job_grade("requestCompesation"),
    fields.new_position_title("requestCompesation"),
    fields.fte_ttc_annual_salary("requestCompesation"),
    fields.ttc_increase_amount("requestCompesation"),
    {
      type: "float",
      inputType: "number",
      name: "ttc_increase_percentage",
      label: "Total Target Cash Increase %",
      section: "Merit/Promotion Increase",
      visible: true,
      editable: true,
      renderer: numberRenderer,
    },
    {
      type: "float",
      name: "new_fte_ttc_annual_salary",
      label: "New Total Target Cash",
      section: "New",
      visible: true,
      renderer: currencyDisplayRenderer,
    },
    //New column added as part Phase 1 release fixes Starts
    {
      type: "float",
      name: "ttc_increase",
      label: "Total Increase Amount",
      section: "New",
      visible: true,
      renderer: currencyDisplayRenderer,
    },
    {
      type: "float",
      name: "ttc_increase_perc",
      label: "Total Increase " + "%",
      section: "New",
      visible: true,
      renderer: percentRenderer,
    },
    //New column added as part Phase 1 release fixes Ends
    fields.target_budget_org("requestCompesation"),
    // Current Section
    tooltipFields.current_country_name("requestCompesation"),
    tooltipFields.job_family_name("requestCompesation"),
    tooltipFields.job_subfamily_name("requestCompesation"),
    tooltipFields.job_name("requestCompesation"),
    {
      name: "job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: true,
    },
    {
      name: "position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: true,
    },
    tooltipFields.fte_annual_salary("requestCompesation"),
    tooltipFields.fte_incentive_target_percentage("requestCompesation"),
    tooltipFields.fte_incentive_target_amount("requestCompesation"),
    tooltipFields.fte_ttc_annual_salary("requestCompesation"),
    {
      name: "ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: true,
      renderer: numberRenderer,
    },
    {
      name: "incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: true,
      renderer: incentivePlanRenderer,
    },
    {
      name: "ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: true,
    },
    // New Section
    tooltipFields.new_country_name("requestCompesation"),
    tooltipFields.promotion_job_family_name("requestCompesation"),
    tooltipFields.promotion_job_subfamily_name("requestCompesation"),
    tooltipFields.promotion_job_name("requestCompesation"),
    tooltipFields.promotion_job_grade("requestCompesation"),
    tooltipFields.new_position_title("requestCompesation"),
    tooltipFields.new_fte_annual_salary("requestCompesation"),
    tooltipFields.new_incentive_target_percentage("requestCompesation"),
    tooltipFields.new_fte_incentive_target_amount("requestCompesation"),
    {
      name: "new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: true,
      renderer: currencyDisplayRenderer,
    },
    {
      name: "new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: true,
      renderer: numberRenderer,
    },
    {
      name: "new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: true,
      renderer: incentivePlanRenderer,
    },
    {
      name: "new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: true,
      renderer: (value, data) => value || data.ttc_employee_quartile,
    },
    // Budget Section
    {
      name: "offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: true,
      renderer: currencyDisplayRenderer,
    },
  ],

  //release 2 - budget management columns
  budgetmanage: [
    { index: true, name: "budget_id", sortable: false, visible: false },
    fields.budget_year("budgetmanage"),
    fields.budget_orgname("budgetmanage"),
    fields.budget_capital("budgetmanage"),
    fields.budget_value("budgetmanage"),
    fields.budget_inprogress("budgetmanage"),
    fields.budget_spent("budgetmanage"),
    fields.budget_forecast("budgetmanage"),
    fields.budget_available("budgetmanage"),
    fields.budget_description("budgetmanage"),
  ],

  employees: [
    { index: true, name: "pers_no", section: "employees" },
    fields.nokia_id("employees"),
    fields.full_name("employees"),
    /* FOR DEFECT 8 STARTS  */
    fields.organization_unit_name("employees"),
    fields.business_group("employees"),
    /* FOR DEFECT 8 ENDS  */
    fields.country_name("employees"),
    fields.fte_annual_salary("employees"),
    fields.fte_incentive_target_percentage("employees"),
    fields.fte_incentive_target_amount("employees"),
    fields.fte_addl_ttc_amount("employees"),
    fields.fte_ttc_annual_salary("employees"),
    {
      type: "string",
      name: "ttc_employee_quartile",
      section: "Current Compensation Information",
      label: "Current Quartile",
      visible: true,
      filterable: true,
      width: "70px",
      sortable: true,
    },
    {
      type: "string",
      name: "incentive_plan_type",
      section: "Current Information",
      label: "Incentive Plan",
      visible: true,
      sortable: true,
      renderer: incentivePlanRenderer,
    },
    fields.job_name("employees"),
    fields.job_grade("employees"),
  ],
  myRequests: [
    {
      type: "string",
      index: true,
      name: "employee_request_id",
      label: "ID",
      visible: true,
      sortable: true,
      width: "5%",
    },
    fields.created_ts("myRequests"),
    fields.request_type("myRequests"),
    fields.request_for("myRequests"),
    /* FOR DEFECT 8 STARTS  */
    fields.organization_unit_name("myRequests"),
    fields.business_group("myRequests"),
    /* FOR DEFECT 8 ENDS  */
    {
      type: "string",
      name: "request_country",
      label: "Country",
      visible: true,
      sortable: true,
      //Width Defect - px -> %
      width: "5%",
    },
    fields.ttc_increase_amount("myRequests"),
    fields.total_increaseed_amount("myRequests"),
    fields.total_increaseed_percent("myRequests"),
    {
      type: "string",
      name: "request_description",
      label: "Reason for the Request",
      visible: true,
      sortable: true,
      //added after Anca's Prod Issue
      width: "20%",
      renderer: reasonRenderer, // new rendered for reason issue
      after: (value, data, account, config) => (
        <RequestTooltip
          columns="myRequests"
          requestData={data}
          account={account}
          config={config}
        />
      ),
    },
    {
      type: "string",
      name: "status",
      label: "Status",
      visible: true,
      sortable: true,
      renderer: (value) => <Status value={value} />,
      //Width Defect - px -> %
      width: "10%",
    },
    // Current Section
    tooltipFields.current_country_name("myRequests"),
    tooltipFields.job_family_name("myRequests"),
    tooltipFields.job_subfamily_name("myRequests"),
    tooltipFields.job_name("myRequests"),
    {
      name: "for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("myRequests"),
    tooltipFields.fte_incentive_target_percentage("myRequests"),
    tooltipFields.fte_incentive_target_amount("myRequests"),
    tooltipFields.fte_ttc_annual_salary("myRequests"),
    {
      name: "for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("myRequests"),
    tooltipFields.promotion_job_family_name("myRequests"),
    tooltipFields.promotion_job_subfamily_name("myRequests"),
    tooltipFields.promotion_job_name("myRequests"),
    tooltipFields.promotion_job_grade("myRequests"),
    tooltipFields.new_position_title("myRequests"),
    tooltipFields.new_fte_annual_salary("myRequests"),
    tooltipFields.new_incentive_target_percentage("myRequests"),
    tooltipFields.new_fte_incentive_target_amount("myRequests"),
    {
      name: "new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: currencyDisplayRenderer,
    },
    {
      name: "new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) => value || data.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type === "onetime" || data.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
     {
      name: "reminder_info.last_reminder_date",
      tooltip_section: "Date",
      label: "Last Reminder Date",
      visible_in_tooltip: (column, data, account) =>
        data.request_type === "internalhire",
      renderer: dateTimeRenderer,
    },
    {
      name: "reminder_info.reminder_count",
      tooltip_section: "Date",
      label: "Reminder Count",
      visible_in_tooltip: (column, data, account) =>
        data.request_type === "internalhire",
      renderer: (value) => value,
    },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    {
      name: "offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        /* Release 3.2 - 1.1 - Starts */
        data.request_type !== "businessexcellenceaward",
        /* Release 3.2 - 1.1 - Ends */
      renderer: currencyDisplayRenderer,
    },
    {
      name: "offcycle_budget_update",
      label: "BEA Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        /* Release 3.2 - 1.1 - Starts */
        data.request_type === "businessexcellenceaward",
        /* Release 3.2 - 1.1 - Ends */
      renderer: currencyDisplayRenderer,
    },
    {
      name: "budget_organization_name",
      allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb"],
      tooltip_section: "Budget",
      label: "Organization Name",
      visible_in_tooltip: (column, data, account) =>
        data.budget_allocations && data.budget_allocations[0],
      defaultValue: (value, data) =>
        data.budget_allocations[0].organization_unit_name,
    },

    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) => data.approvals.length > 0,
      renderer: (value, data) =>
        data.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) => data.comments.length > 0,
      renderer: (value, data) =>
        data.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },

    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "award_title",
      label: "Award Title",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_message",
      label: "Award Message",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "ttc_increase_amount",
      label: "Award Amount",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
      renderer: currencyDisplayRenderer,
    },
    {
      name: "award_privacy",
      label: "Award Privacy",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_reason",
      label: "Award Reason",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "nominator_email_address",
      label: "Nominator Email Address",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    /* Release 3.2 - 1.1 - Ends */
  ],
  approvedRequestHistory: [
    {
      type: "string",
      index: true,
      name: "employee_request_id",
      label: "ID",
      visible: true,
      sortable: true,
      width: "50px",
      section: "approvedRequestHistory",
    },
    fields.created_ts("approvedRequestHistory"),
    fields.request_by("approvedRequestHistory"),
    fields.request_type("approvedRequestHistory"),
    //fields.effective_date("approveRequestHistory"),
    fields.request_for("approvedRequestHistory"),
    /* FOR DEFECT 8 STARTS  */
    fields.organization_unit_name("approvedRequestHistory"),
    fields.business_group("approvedRequestHistory"),
    /* FOR DEFECT 8 ENDS  */
    fields.ttc_increase_amount("approvedRequestHistory"),
    fields.total_increaseed_percent("approvedRequestHistory"),
    {
      type: "string",
      name: "request.request_description",
      label: "Reason for the Request",
      visible: true,
      sortable: false,
      //added after Anca's Prod Issue
      //width: "14%",
      renderer: reasonRenderer, // new rendered for reason issue
      after: (value, data, account, config) => (
        <RequestTooltip
          columns="approvedRequestHistory"
          requestData={data.request}
          account={account}
          config={config}
        />
      ),
    },
    // Current Section
    tooltipFields.current_country_name("approvedRequestHistory"),
    tooltipFields.job_family_name("approvedRequestHistory"),
    tooltipFields.job_subfamily_name("approvedRequestHistory"),
    tooltipFields.job_name("approvedRequestHistory"),
    {
      name: "for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("approvedRequestHistory"),
    tooltipFields.fte_incentive_target_percentage("approvedRequestHistory"),
    tooltipFields.fte_incentive_target_amount("approvedRequestHistory"),
    tooltipFields.fte_ttc_annual_salary("approvedRequestHistory"),
    {
      name: "for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("approvedRequestHistory"),
    tooltipFields.promotion_job_family_name("approvedRequestHistory"),
    tooltipFields.promotion_job_subfamily_name("approvedRequestHistory"),
    tooltipFields.promotion_job_name("approvedRequestHistory"),
    tooltipFields.promotion_job_grade("approvedRequestHistory"),
    tooltipFields.new_position_title("approvedRequestHistory"),
    tooltipFields.new_fte_annual_salary("approvedRequestHistory"),
    tooltipFields.new_incentive_target_percentage("approvedRequestHistory"),
    tooltipFields.new_fte_incentive_target_amount("approvedRequestHistory"),
    {
      name: "new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: currencyDisplayRenderer,
    },
    {
      name: "new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) => value || data.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type === "onetime" || data.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
    //  {
    //   name: "reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    /* Release 3.2 - 1.1 - Starts */
    tooltipFields.offcycle_budget_update("approvedRequestHistory"),
    tooltipFields.bea_budget_update("approvedRequestHistory"),
    /* Release 3.2 - 1.1 - Ends */
    {
      name: "budget_organization_name",
      allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb"],
      tooltip_section: "Budget",
      label: "Organization Name",
      visible_in_tooltip: (column, data, account) =>
        data.budget_allocations && data.budget_allocations[0],
      defaultValue: (value, data) =>
        data.budget_allocations[0].organization_unit_name,
    },
    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) => data.approvals.length > 0,
      renderer: (value, data) =>
        data.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) => data.comments.length > 0,
      renderer: (value, data) =>
        data.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },

    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "award_title",
      label: "Award Title",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_message",
      label: "Award Message",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "ttc_increase_amount",
      label: "Award Amount",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
      renderer: currencyDisplayRenderer,
    },
    {
      name: "award_privacy",
      label: "Award Privacy",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_reason",
      label: "Award Reason",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "nominator_email_address",
      label: "Nominator Email Address",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    /* Release 3.2 - 1.1 - Ends */
  ],
  pendingRequests: [
    {
      type: "string",
      index: true,
      name: "employee_request_id",
      label: "ID",
      visible: true,
      sortable: true,
      width: "50px",
    },
    fields.created_ts("pendingRequests"),
    fields.request_by("pendingRequests"),
    fields.request_type("pendingRequests"),
    fields.request_for("pendingRequests"),
    /* FOR DEFECT 8 STARTS  */
    fields.organization_unit_name("pendingRequests"),
    fields.business_group("pendingRequests"),
    /* FOR DEFECT 8 ENDS  */
    fields.ttc_increase_amount("pendingRequests"),
    fields.total_increaseed_percent("pendingRequests"),
    {
      type: "string",
      name: "request.request_description",
      label: "Reason for the Request",
      visible: true,
      sortable: false,
      //added after Anca's Prod Issue
      //width: "16%",
      renderer: reasonRenderer, // new rendered for reason issue
      //after: (value, data, account, config) => <RequestTooltip columns="pendingRequests" requestData={data.request} account={account} config={config}/>
      /*  FIXED THE TOOL TIP ISSUE STARTS  */
      after: (value, data, account, config) =>
        // data.approver && 
        (
          <RequestTooltip
            columns="pendingRequests"
            requestData={{
              ...data.request,
              full_name: data.approver && data.approver.full_name ? data.approver.full_name : "Inactive approver",
            }}
            account={account}
            config={config}
          />
        ),
      /*  FIXED THE TOOL TIP ISSUE ENDS  */
    },
    // Current Section
    tooltipFields.current_country_name("pendingRequests"),
    tooltipFields.current_organization_unit_name("pendingRequests"),
    tooltipFields.current_organization_unit_1_name("pendingRequests"),
    tooltipFields.job_family_name("pendingRequests"),
    tooltipFields.job_subfamily_name("pendingRequests"),
    tooltipFields.job_name("pendingRequests"),
    {
      name: "for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
      (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
      /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
      (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
      /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("pendingRequests"),
    tooltipFields.fte_incentive_target_percentage("pendingRequests"),
    tooltipFields.fte_incentive_target_amount("pendingRequests"),
    tooltipFields.fte_ttc_annual_salary("pendingRequests"),
    {
      name: "for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("pendingRequests"),
    tooltipFields.new_organization_unit_name("pendingRequests"),
    tooltipFields.new_organization_unit_1_name("pendingRequests"),
    tooltipFields.promotion_job_family_name("pendingRequests"),
    tooltipFields.promotion_job_subfamily_name("pendingRequests"),
    tooltipFields.promotion_job_name("pendingRequests"),
    tooltipFields.promotion_job_grade("pendingRequests"),
    tooltipFields.new_position_title("pendingRequests"),
    tooltipFields.new_fte_annual_salary("pendingRequests"),
    tooltipFields.new_incentive_target_percentage("pendingRequests"),
    tooltipFields.new_fte_incentive_target_amount("pendingRequests"),
    {
      name: "new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: currencyDisplayRenderer,
    },
    {
      name: "new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) => value || data.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type === "onetime" || data.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
    //  {
    //   name: "reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    /* Release 3.2 - 1.1 - Starts */
    tooltipFields.offcycle_budget_update("pendingRequests"),
    tooltipFields.bea_budget_update("pendingRequests"),
    /* Release 3.2 - 1.1 - Ends */
    {
      name: "budget_organization_name",
      allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb"],
      tooltip_section: "Budget",
      label: "Organization Name",
      visible_in_tooltip: (column, data, account) =>
        data.budget_allocations && data.budget_allocations[0],
      defaultValue: (value, data) =>
        data.budget_allocations[0].organization_unit_name,
    },

    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) => data.approvals.length > 0,
      renderer: (value, data) =>
        data.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) => data.comments.length > 0,
      renderer: (value, data) =>
        data.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },

    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "award_title",
      label: "Award Title",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_message",
      label: "Award Message",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "ttc_increase_amount",
      label: "Award Amount",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
      renderer: currencyDisplayRenderer,
    },
    {
      name: "award_privacy",
      label: "Award Privacy",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_reason",
      label: "Award Reason",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "nominator_email_address",
      label: "Nominator Email Address",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    /* Release 3.2 - 1.1 - Ends */
  ],
  requestsHistory: [
    {
      type: "string",
      index: true,
      name: "employee_request_id",
      label: "ID",
      visible: true,
      sortable: true,
      width: "50px",
      /* Release 3.2 - 10.3 - Starts */
      section: "AllRecquestsHistory"
      /* Release 3.2 - 10.3 - Ends */
    },
    fields.created_ts("requestHistory"),
    fields.request_type("requestHistory"),
    {
      type: "string",
      name: "for",
      label: "Employee",
      visible: true,
      sortable: false,
      renderer: (value) => `${value.full_name} (${value.nokia_id})`,
      //width: "200px"
      width: "80px" /* FOR DEFECT 8 STARTS  */,
    },
    /* FOR DEFECT 8 STARTS  */    
    fields.organization_unit_name("requestHistory"),
    fields.business_group("requestHistory"),
    /* FOR DEFECT 8 ENDS  */
    /* Release 3.2 - 10.3 - Starts */
    fields.business_unit_name("requestHistory"),
    /* Release 3.2 - 10.3 - Starts */
    {
      type: "string",
      name: "by.full_name",
      label: "Requestor",
      visible: true,
      sortable: true,
      renderer: (value) => value,
      //width: "150px"
      width: "100px" /* FOR DEFECT 8 STARTS  */,
    },
    {
      type: "string",
      name: "request_country",
      label: "Country",
      visible: true,
      sortable: true,
      width: "100px",
    },
    fields.ttc_increase_amount("requestHistory"),
    fields.total_increaseed_percent("requestHistory"),
    {
      type: "string",
      name: "request_description",
      label: "Reason for the Request",
      visible: true,
      sortable: true,
      //added after Anca's Prod Issue
      //width: "16%",
      renderer: reasonRenderer, // new rendered for reason issue
      after: (value, data, account, config) => (
        <RequestTooltip
          columns="requestsHistory"
          requestData={data}
          account={account}
          config={config}
        />
      ),
    },
    {
      type: "string",
      name: "status",
      label: "Status",
      visible: true,
      sortable: true,
      renderer: (value) => <Status value={value} />,
      width: "150px",
    },
    // Current Section
    tooltipFields.current_country_name("requestsHistory"),
    tooltipFields.job_family_name("requestsHistory"),
    tooltipFields.job_subfamily_name("requestsHistory"),
    tooltipFields.job_name("requestsHistory"),
    {
      name: "for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
      (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
      /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("requestsHistory"),
    tooltipFields.fte_incentive_target_percentage("requestsHistory"),
    tooltipFields.fte_incentive_target_amount("requestsHistory"),
    tooltipFields.fte_ttc_annual_salary("requestsHistory"),
    {
      name: "for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("requestsHistory"),
    tooltipFields.promotion_job_family_name("requestsHistory"),
    tooltipFields.promotion_job_subfamily_name("requestsHistory"),
    tooltipFields.promotion_job_name("requestsHistory"),
    tooltipFields.promotion_job_grade("requestsHistory"),
    tooltipFields.new_position_title("requestsHistory"),
    tooltipFields.new_fte_annual_salary("requestsHistory"),
    tooltipFields.new_incentive_target_percentage("requestsHistory"),
    tooltipFields.new_fte_incentive_target_amount("requestsHistory"),
    {
      name: "new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
      ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true,
      /* Release 3.2 - 1.1 - Ends */
      renderer: currencyDisplayRenderer,
    },
    {
      name: "new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
      ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true,
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
      ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true,
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
      ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true,
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) => value || data.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type === "onetime" || data.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
    //  {
    //   name: "reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    /* Release 3.2 - 1.1 - Starts */
    tooltipFields.offcycle_budget_update("requestsHistory"),    
    tooltipFields.bea_budget_update("requestsHistory"),
    /* Release 3.2 - 1.1 - Ends */
    {
      name: "budget_organization_name",
      allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb"],
      tooltip_section: "Budget",
      label: "Organization Name",
      visible_in_tooltip: (column, data, account) =>
        data.budget_allocations && data.budget_allocations[0],
      defaultValue: (value, data) =>
        data.budget_allocations[0].organization_unit_name,
    },

    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) => data.approvals.length > 0,
      renderer: (value, data) =>
        data.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) => data.comments.length > 0,
      renderer: (value, data) =>
        data.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },

    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "award_title",
      label: "Award Title",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_message",
      label: "Award Message",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "ttc_increase_amount",
      label: "Award Amount",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
      renderer: currencyDisplayRenderer,
    },
    {
      name: "award_privacy",
      label: "Award Privacy",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_reason",
      label: "Award Reason",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "nominator_email_address",
      label: "Nominator Email Address",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    /* Release 3.2 - 1.1 - Ends */
  ],
  rejectRequests: [
    { index: true, name: "employee_request_id" },
    fields.request_by("rejectRequests"),
    fields.request_type("rejectRequests"),
    fields.request_for("rejectRequests"),
    fields.ttc_increase_amount("rejectRequests"),
    // Current Section
    tooltipFields.current_country_name("rejectRequests"),
    tooltipFields.current_organization_unit_name("rejectRequests"),
    tooltipFields.current_organization_unit_1_name("rejectRequests"),
    tooltipFields.job_family_name("rejectRequests"),
    tooltipFields.job_subfamily_name("rejectRequests"),
    tooltipFields.job_name("rejectRequests"),
    {
      name: "request.for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "request.for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("rejectRequests"),
    tooltipFields.fte_incentive_target_percentage("rejectRequests"),
    tooltipFields.fte_incentive_target_amount("rejectRequests"),
    tooltipFields.fte_ttc_annual_salary("rejectRequests"),
    {
      name: "request.for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio ",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "request.for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("rejectRequests"),
    tooltipFields.new_organization_unit_name("rejectRequests"),
    tooltipFields.new_organization_unit_1_name("rejectRequests"),
    tooltipFields.promotion_job_family_name("rejectRequests"),
    tooltipFields.promotion_job_subfamily_name("rejectRequests"),
    tooltipFields.promotion_job_name("rejectRequests"),
    tooltipFields.promotion_job_grade("rejectRequests"),
    tooltipFields.new_position_title("rejectRequests"),
    tooltipFields.new_fte_annual_salary("rejectRequests"),
    tooltipFields.new_incentive_target_percentage("rejectRequests"),
    tooltipFields.new_fte_incentive_target_amount("rejectRequests"),
    {
      name: "request.new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    {
      name: "request.new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio ",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "request.new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) =>
        value || data.request.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "request.effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "request.payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type === "onetime" || data.request.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
    //  {
    //   name: "request.reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "request.reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    {
      name: "request.offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        /* Release 3.2 - 1.1 - Starts */
        data.request.request_type !== "businessexcellenceaward",
        /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "request.offcycle_budget_update",
      label: "BEA Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        data.request.request_type === "businessexcellenceaward",
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Ends */
    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) =>
        data.request.approvals.length > 0,
      renderer: (value, data) =>
        data.request.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) =>
        data.request.comments.length > 0,
      renderer: (value, data) =>
        data.request.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },

    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.request.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.request.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "request.award_title",
      label: "Award Title",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    {
      name: "request.award_message",
      label: "Award Message",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    {
      name: "request.ttc_increase_amount",
      label: "Award Amount",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    {
      name: "request.award_privacy",
      label: "Award Privacy",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    {
      name: "request.award_reason",
      label: "Award Reason",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    {
      name: "request.nominator_email_address",
      label: "Nominator Email Address",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    /* Release 3.2 - 1.1 - Ends */
  ],
  approveRequests: [
    { index: true, name: "employee_request_id" },
    fields.request_by("approveRequests"),
    fields.request_type("approveRequests"),
    /* Release 3.1 - Starts */
    fields.effective_payment_date("approveRequests"),
    /* Release 3.1 - Ends */
    /* Release 3.2 - 12.2 - Starts */
    fields.payment_date("approveRequests"),
    /* Release 3.2 - 12.2 - Ends */
    fields.request_for("approveRequests"),
    fields.ttc_increase_amount("approveRequests"),
    fields.override_manager_proposal("approveRequests"),
    fields.override_fte_annual_salary("approveRequests"),
    fields.override_fte_incentive_target_amount("approveRequests"),
    fields.override_fte_incentive_target_percentage("approveRequests"),
    fields.override_fte_ttc_annual_salary("approveRequests"),
    fields.override_promotion_increase("approveRequests"),
    fields.override_ti_harmonization("approveRequests"),
    // Current Section
    tooltipFields.current_country_name("approveRequests"),
    tooltipFields.current_organization_unit_name("approveRequests"),
    tooltipFields.current_organization_unit_1_name("approveRequests"),
    tooltipFields.job_family_name("approveRequests"),
    tooltipFields.job_subfamily_name("approveRequests"),
    tooltipFields.job_name("approveRequests"),
    {
      name: "request.for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "request.for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("approveRequests"),
    tooltipFields.fte_incentive_target_percentage("approveRequests"),
    tooltipFields.fte_incentive_target_amount("approveRequests"),
    tooltipFields.fte_ttc_annual_salary("approveRequests"),
    {
      name: "request.for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "request.for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("approveRequests"),
    tooltipFields.new_organization_unit_name("approveRequests"),
    tooltipFields.new_organization_unit_1_name("approveRequests"),
    tooltipFields.promotion_job_family_name("approveRequests"),
    tooltipFields.promotion_job_subfamily_name("approveRequests"),
    tooltipFields.promotion_job_name("approveRequests"),
    tooltipFields.promotion_job_grade("approveRequests"),
    tooltipFields.new_position_title("approveRequests"),
    tooltipFields.new_fte_annual_salary("approveRequests"),
    tooltipFields.new_incentive_target_percentage("approveRequests"),
    tooltipFields.new_fte_incentive_target_amount("approveRequests"),
    {
      name: "request.new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    {
      name: "request.new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "request.new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) =>
        value || data.request.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "request.effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "request.payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type === "onetime" || data.request.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
    //  {
    //   name: "request.reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "request.reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    {
      name: "request.offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        /* Release 3.2 - 1.1 - Starts */
        (data.request.request_type !== "internalhire" && data.request.request_type !== "businessexcellenceaward"),
        /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    {
      name: "request.Final_Off_Cycle_Budget",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        data.request.request_type === "internalhire",
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "request.offcycle_budget_update",
      label: "BEA Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        data.request.request_type === "businessexcellenceaward",
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Ends */
    {
      name: "budget_organization_name",
      allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb"],
      tooltip_section: "Budget",
      label: "Organization Name",
      visible_in_tooltip: (column, data, account) =>
        data.budget_allocations && data.budget_allocations[0],
      defaultValue: (value, data) =>
        data.budget_allocations[0].organization_unit_name,
    },
    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) =>
        data.request.approvals.length > 0,
      renderer: (value, data) =>
        data.request.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) =>
        data.request.comments.length > 0,
      renderer: (value, data) =>
        data.request.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },

    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.request.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.request.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "request.award_title",
      label: "Award Title",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    {
      name: "request.award_message",
      label: "Award Message",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    {
      name: "request.ttc_increase_amount",
      label: "Award Amount",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    {
      name: "request.award_privacy",
      label: "Award Privacy",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    {
      name: "request.award_reason",
      label: "Award Reason",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    {
      name: "request.nominator_email_address",
      label: "Nominator Email Address",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request.request_type === "businessexcellenceaward"),
    },
    /* Release 3.2 - 1.1 - Ends */
  ],
  /*  FIXING DELEGATION ISSUE */
  delegatesForManager: [
    {
      type: "string",
      index: true,
      name: "id",
      label: "ID",
      visible: false,
      sortable: false,
      section: "Delegation List Page",
    },

    {
      type: "string",
      name: "delegation_pers_no.full_name",
      label: "Assignee",
      visible: true,
      sortable: false,
      section: "Delegation List Page",
    },
    {
      type: "string",
      name: "delegation_pers_no.nokia_id",
      label: "Assignee Nokia ID",
      visible: true,
      sortable: false,
      section: "Delegation List Page",
    },
    {
      type: "string",
      name: "pers_no.full_name",
      label: "Delegator",
      visible: true,
      sortable: false,
      section: "Delegation List Page",
    },
    {
      type: "string",
      name: "pers_no.nokia_id",
      label: "Delegator Nokia ID",
      visible: true,
      sortable: false,
      section: "Delegation List Page",
    },
    {
      type: "string",
      name: "approver_type",
      label: "Role",
      visible: true,
      sortable: false,
      section: "Delegation List Page",
    },
    {
      type: "datetime",
      name: "start_date",
      label: "Start Date",
      visible: true,
      filterable: false,
      sortable: false,
      renderer: dateRenderer,
      section: "Delegation List Page",
    },
    {
      type: "datetime",
      name: "end_date",
      label: "End Date",
      visible: true,
      filterable: false,
      sortable: false,
      renderer: dateRenderer,
      section: "Delegation List Page",
    },
    {
      type: "datetime",
      name: "upd_ins_ts",
      label: "Revoke Date",
      visible: true,
      filterable: false,
      sortable: false,
      renderer: dateRenderer,
      section: "Delegation List Page",
    },
    {
      type: "string",
      name: "is_active",
      label: "Status",
      visible: true,
      sortable: false,
      section: "Delegation List Page",
    },
  ],
  delegatesForRevoke: [
    {
      type: "string",
      index: true,
      name: "id",
      label: "ID",
      visible: false,
      sortable: false,
      section: "Delegation Revoke Page",
    },
    {
      type: "string",
      name: "delegation_pers_no.full_name",
      label: "Assignee",
      visible: true,
      sortable: false,
      section: "Delegation Revoke Page",
    },
    {
      type: "string",
      name: "delegation_pers_no.nokia_id",
      label: "Assignee Nokia ID",
      visible: true,
      sortable: false,
      section: "Delegation Revoke Page",
    },
    {
      type: "string",
      name: "pers_no.full_name",
      label: "Delegator",
      visible: true,
      sortable: false,
      section: "Delegation Revoke Page",
    },
    {
      type: "string",
      name: "pers_no.nokia_id",
      label: "Delegator Nokia ID",
      visible: true,
      sortable: false,
      section: "Delegation Revoke Page",
    },
    {
      type: "string",
      name: "approver_type",
      label: "Role",
      visible: true,
      sortable: false,
      section: "Delegation Revoke Page",
    },
    {
      type: "datetime",
      name: "start_date",
      label: "Start Date",
      visible: true,
      filterable: false,
      sortable: false,
      renderer: dateRenderer,
      section: "Delegation Revoke Page",
    },
    {
      type: "datetime",
      name: "end_date",
      label: "End Date",
      visible: true,
      filterable: false,
      sortable: false,
      renderer: dateRenderer,
      section: "Delegation Revoke Page",
    },
    {
      type: "datetime",
      name: "upd_ins_ts",
      label: "Revoke Date",
      visible: true,
      filterable: false,
      sortable: false,
      renderer: dateRenderer,
      section: "Delegation Revoke Page",
    },
    {
      type: "string",
      name: "is_active",
      label: "Status",
      visible: true,
      sortable: false,

      section: "Delegation Revoke Page",
    },
  ],
  // R1.2 - Coulmns added for TAP view Employees
  tapEmployees: [
    { index: true, name: "nokia_id" },
    fields.nokia_id("employeesForTap"),
    fields.full_name("employeesForTap"),
    fields.country_name("employees"),
    {
      type: "string",
      name: "city",
      section: "TAP Employees Information",
      label: "City",
      visible: true,
      filterable: true,
      width: "70px",
      sortable: true,
    },
    {
      type: "string",
      name: "hr_region",
      section: "TAP Employees Information",
      label: "HR Region",
      visible: true,
      filterable: true,
      width: "70px",
      sortable: true,
    },
    fields.business_group("employees"),
    fields.organization_unit_name("employeesForTap"),
    {
      type: "string",
      name: "incentive_plan_type",
      section: "Current Information",
      label: "Incentive Plan",
      visible: true,
      sortable: true,
      width: "5%",
      renderer: incentivePlanRenderer,
    },
    fields.job_name("employees"),
    {
      type: "string",
      name: "position_title",
      section: "TAP Employees Information",
      label: "Position Title",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    fields.email_work("employeesForTap"),
  ],
  mocfailure: [
    {
      index: true,
      name: "for.nokia_id",
      section: "MOC Failure",
      sortable: true,
      visible: true,
      label: "Nokia ID",
      width: "15%",
    },
    {
      name: "for.full_name",
      section: "MOC Failure",
      sortable: true,
      visible: true,
      label: "Full Name",
      width: "15%",
    },
    {
      name: "request_type",
      section: "MOC Failure",
      sortable: true,
      visible: true,
      label: "Request Type",
      width: "15%",
    },
    {
      name: "import_status",
      section: "MOC Failure",
      sortable: true,
      visible: true,
      label: "Status",
      width: "8%",
    },

    {
      name: "import_err_msg",
      section: "MOC Failure",
      sortable: true,
      visible: true,
      label: "Reason for failure",
      width: "45%",
    },
  ],
  //DEM0058258
  allrequesthrc: [
    {
      type: "string",
      index: true,
      name: "employee_request_id",
      label: "ID",
      visible: true,
      sortable: true,
      width: "50px",
      section: "AllRequestHRC",
    },
    fields.created_ts("requestHistory"),
    fields.request_type("requestHistory"),
    {
      type: "string",
      name: "for",
      label: "Employee",
      visible: true,
      sortable: false,
      renderer: (value) => `${value.full_name} (${value.nokia_id})`,
      //width: "200px"
      width: "80px" /* FOR DEFECT 8 STARTS  */,
    },
    {
      type: "string",
      name: "by.full_name",
      label: "Requestor",
      visible: true,
      sortable: true,
      renderer: (value) => value,
      //width: "150px"
      width: "100px" /* FOR DEFECT 8 STARTS  */,
    },
    {
      type: "string",
      name: "request_country",
      label: "Country",
      visible: true,
      sortable: true,
      width: "100px",
    },
    fields.ttc_increase_amount("requestHistory"),
    fields.total_increaseed_percent("requestHistory"),
    {
      type: "string",
      name: "request_description",
      label: "Reason for the Request",
      visible: true,
      sortable: true,
      //added after Anca's Prod Issue
      //width: "16%",
      renderer: reasonRenderer, // new rendered for reason issue
      after: (value, data, account, config) => (
        <RequestTooltip
          columns="requestsHistory"
          requestData={data}
          account={account}
          config={config}
        />
      ),
    },
    {
      type: "string",
      name: "status",
      label: "Status",
      visible: true,
      sortable: true,
      renderer: (value) => <Status value={value} />,
      width: "150px",
    },
    // Current Section
    tooltipFields.current_country_name("requestsHistory"),
    tooltipFields.job_family_name("requestsHistory"),
    tooltipFields.job_subfamily_name("requestsHistory"),
    tooltipFields.job_name("requestsHistory"),
    {
      name: "for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("requestsHistory"),
    tooltipFields.fte_incentive_target_percentage("requestsHistory"),
    tooltipFields.fte_incentive_target_amount("requestsHistory"),
    tooltipFields.fte_ttc_annual_salary("requestsHistory"),
    {
      name: "for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("requestsHistory"),
    tooltipFields.promotion_job_family_name("requestsHistory"),
    tooltipFields.promotion_job_subfamily_name("requestsHistory"),
    tooltipFields.promotion_job_name("requestsHistory"),
    tooltipFields.promotion_job_grade("requestsHistory"),
    tooltipFields.new_position_title("requestsHistory"),
    tooltipFields.new_fte_annual_salary("requestsHistory"),
    tooltipFields.new_incentive_target_percentage("requestsHistory"),
    tooltipFields.new_fte_incentive_target_amount("requestsHistory"),
    {
      name: "new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: currencyDisplayRenderer,
    },
    {
      name: "new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) => value || data.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type === "onetime" || data.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
    //  {
    //   name: "reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    {
      name: "offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: true,
      renderer: currencyDisplayRenderer,
    },
    {
      name: "budget_organization_name",
      allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb"],
      tooltip_section: "Budget",
      label: "Organization Name",
      visible_in_tooltip: (column, data, account) =>
        data.budget_allocations && data.budget_allocations[0],
      defaultValue: (value, data) =>
        data.budget_allocations[0].organization_unit_name,
    },

    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) => data.approvals.length > 0,
      renderer: (value, data) =>
        data.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.approval_status != "canceled" && a.on_behalf_of_approver ? a.on_behalf_of_approver.full_name + " on behalf of " : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) => data.comments.length > 0,
      renderer: (value, data) =>
        data.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}{" "}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
  ],
  /* Release 3.1 - Starts */
  approvedRequestsHistory: [
    {
      type: "string",
      index: true,
      name: "employee_request_id",
      label: "ID",
      visible: true,
      sortable: true,
      width: "50px",
      section: "approvedRequestsHistory"
    },
    fields.created_ts("requestHistory"),
    fields.business_group("requestHistory"),
    {
      type: "string",
      name: "by.full_name",
      label: "Requestor",
      visible: true,
      sortable: true,
      renderer: (value) => value,
      width: "120px",
    },
    fields.request_type("requestHistory"),
    {
      type: "string",
      name: "for",
      label: "Employee",
      visible: true,
      sortable: false,
      renderer: (value) => `${value.full_name} (${value.nokia_id})`,
      width: "120px",
    },
    fields.ttc_increase_amount("requestHistory"),
    fields.total_increaseed_percent("requestHistory"),
    {
      type: "string",
      name: "request_description",
      label: "Reason for the Request",
      visible: true,
      sortable: true,
      renderer: reasonRenderer,
      after: (value, data, account, config) => (
        <RequestTooltip
          columns="requestsHistory"
          requestData={data}
          account={account}
          config={config}
        />
      ),
    },
    // Current Section
    tooltipFields.current_country_name("requestsHistory"),
    tooltipFields.job_family_name("requestsHistory"),
    tooltipFields.job_subfamily_name("requestsHistory"),
    tooltipFields.job_name("requestsHistory"),
    {
      name: "for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("requestsHistory"),
    tooltipFields.fte_incentive_target_percentage("requestsHistory"),
    tooltipFields.fte_incentive_target_amount("requestsHistory"),
    tooltipFields.fte_ttc_annual_salary("requestsHistory"),
    {
      name: "for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("requestsHistory"),
    tooltipFields.promotion_job_family_name("requestsHistory"),
    tooltipFields.promotion_job_subfamily_name("requestsHistory"),
    tooltipFields.promotion_job_name("requestsHistory"),
    tooltipFields.promotion_job_grade("requestsHistory"),
    tooltipFields.new_position_title("requestsHistory"),
    tooltipFields.new_fte_annual_salary("requestsHistory"),
    tooltipFields.new_incentive_target_percentage("requestsHistory"),
    tooltipFields.new_fte_incentive_target_amount("requestsHistory"),
    {
      name: "new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: currencyDisplayRenderer,
    },
    {
      name: "new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) => value || data.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type === "onetime" || data.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
    //  {
    //   name: "reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    /* Release 3.2 - 1.1 - Starts */
    tooltipFields.offcycle_budget_update("requestsHistory"),
    tooltipFields.bea_budget_update("requestsHistory"),
    /* Release 3.2 - 1.1 - Ends */
    {
      name: "budget_organization_name",
      allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb"],
      tooltip_section: "Budget",
      label: "Organization Name",
      visible_in_tooltip: (column, data, account) =>
        data.budget_allocations && data.budget_allocations[0],
      defaultValue: (value, data) =>
        data.budget_allocations[0].organization_unit_name,
    },

    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) => data.approvals.length > 0,
      renderer: (value, data) =>
        data.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.on_behalf_of_approver
                  ? a.on_behalf_of_approver.full_name + " on behalf of "
                  : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.on_behalf_of_approver
                  ? a.on_behalf_of_approver.full_name + " on behalf of "
                  : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.on_behalf_of_approver
                ? a.on_behalf_of_approver.full_name + " on behalf of "
                : ""}
              {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.on_behalf_of_approver
                ? a.on_behalf_of_approver.full_name + " on behalf of "
                : ""}
              {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) => data.comments.length > 0,
      renderer: (value, data) =>
        data.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "award_title",
      label: "Award Title",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_message",
      label: "Award Message",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "ttc_increase_amount",
      label: "Award Amount",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
      renderer: currencyDisplayRenderer,
    },
    {
      name: "award_privacy",
      label: "Award Privacy",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "award_reason",
      label: "Award Reason",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    {
      name: "nominator_email_address",
      label: "Nominator Email Address",
      tooltip_section: "Award",
      visible_in_tooltip: (column, data, account) =>
        (data.request_type === "businessexcellenceaward"),
    },
    /* Release 3.2 - 1.1 - Ends */
  ],
  /* Release 3.1 - Starts */
  changeApproverRequests: [
    {
      type: "string",
      index: true,
      name: "employee_request_id",
      label: "ID",
      visible: true,
      sortable: true,
      width: "50px",
      section: "changeApproverRequests",
    },
    fields.created_ts("pendingRequests"),
    fields.request_by("pendingRequests"),
    fields.request_type("pendingRequests"),
    fields.request_for("pendingRequests"),
    /* FOR DEFECT 8 STARTS  */
    fields.organization_unit_name("pendingRequests"),
    fields.business_group("pendingRequests"),
    /* FOR DEFECT 8 ENDS  */
    fields.ttc_increase_amount("pendingRequests"),
    fields.total_increaseed_percent("pendingRequests"),
    {
      type: "string",
      name: "request.request_description",
      label: "Reason for the Request",
      visible: true,
      sortable: false,
      //added after Anca's Prod Issue
      //width: "16%",
      renderer: reasonRenderer, // new rendered for reason issue
      //after: (value, data, account, config) => <RequestTooltip columns="pendingRequests" requestData={data.request} account={account} config={config}/>
      /*  FIXED THE TOOL TIP ISSUE STARTS  */
      after: (value, data, account, config) =>
        // data.approver && 
        (
          <RequestTooltip
            columns="pendingRequests"
            requestData={{
              ...data.request,
              full_name: data.approver && data.approver.full_name ? data.approver.full_name : "Inactive approver",
            }}
            account={account}
            config={config}
          />
        ),
      /*  FIXED THE TOOL TIP ISSUE ENDS  */
    },
    // Current Section
    tooltipFields.current_country_name("pendingRequests"),
    tooltipFields.current_organization_unit_name("pendingRequests"),
    tooltipFields.current_organization_unit_1_name("pendingRequests"),
    tooltipFields.job_family_name("pendingRequests"),
    tooltipFields.job_subfamily_name("pendingRequests"),
    tooltipFields.job_name("pendingRequests"),
    {
      name: "for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("pendingRequests"),
    tooltipFields.fte_incentive_target_percentage("pendingRequests"),
    tooltipFields.fte_incentive_target_amount("pendingRequests"),
    tooltipFields.fte_ttc_annual_salary("pendingRequests"),
    {
      name: "for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("pendingRequests"),
    tooltipFields.new_organization_unit_name("pendingRequests"),
    tooltipFields.new_organization_unit_1_name("pendingRequests"),
    tooltipFields.promotion_job_family_name("pendingRequests"),
    tooltipFields.promotion_job_subfamily_name("pendingRequests"),
    tooltipFields.promotion_job_name("pendingRequests"),
    tooltipFields.promotion_job_grade("pendingRequests"),
    tooltipFields.new_position_title("pendingRequests"),
    tooltipFields.new_fte_annual_salary("pendingRequests"),
    tooltipFields.new_incentive_target_percentage("pendingRequests"),
    tooltipFields.new_fte_incentive_target_amount("pendingRequests"),
    {
      name: "new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: currencyDisplayRenderer,
    },
    {
      name: "new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) => value || data.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type !== "onetime" && data.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request_type === "onetime" || data.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
    //  {
    //   name: "reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    {
      name: "offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: true,
      renderer: currencyDisplayRenderer,
    },
    {
      name: "budget_organization_name",
      allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb"],
      tooltip_section: "Budget",
      label: "Organization Name",
      visible_in_tooltip: (column, data, account) =>
        data.budget_allocations && data.budget_allocations[0],
      defaultValue: (value, data) =>
        data.budget_allocations[0].organization_unit_name,
    },

    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) => data.approvals.length > 0,
      renderer: (value, data) =>
        data.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.on_behalf_of_approver
                  ? a.on_behalf_of_approver.full_name + " on behalf of "
                  : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.on_behalf_of_approver
                  ? a.on_behalf_of_approver.full_name + " on behalf of "
                  : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.on_behalf_of_approver
                ? a.on_behalf_of_approver.full_name + " on behalf of "
                : ""}
              {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.on_behalf_of_approver
                ? a.on_behalf_of_approver.full_name + " on behalf of "
                : ""}
              {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) => data.comments.length > 0,
      renderer: (value, data) =>
        data.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
  ],
  admin_tapUsers: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "email_work",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Email",
      width: "25%",
    },
    {
      name: "is_gtap",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Is GTap",
      width: "7%",
    },
    {
      name: "is_rtap",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Is RTap",
      width: "7%",
    },
    {
      name: "rtap_regions",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "RTap Regions",
      width: "10%",
    },
    {
      name: "countries_include",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Included Countries",
      width: "12%",
    },
    {
      name: "countries_exclude",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Excluded Countries",
      width: "12%",
    },
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "12%",
    },
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      width: "13%",
    },
  ],
  /* DEM0065375 - External Users Access Starts  */
  admin_externalTapUsers: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "email_work",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Email",
      width: "40%",
    },
    {
      name: "is_gtap",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Is GTap",
      width: "7%",
    },
    {
      name: "is_rtap",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Is RTap",
      width: "7%",
    },
    {
      name: "rtap_regions",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "RTap Regions",
      width: "10%",
    },
    /* DEM0070926 Starts */
    {
      name: "is_subcontractor",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Is Subcontractor",
      width: "10%",
    },
    /* DEM0070926 Ends */
    /*{
      name: "countries_include",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Included Countries",
      width: "12%",
    },
    {
      name: "countries_exclude",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Excluded Countries",
      width: "12%",
    },*/
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "12%",
    },
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      width: "13%",
    },
  ],
  /* DEM0065375 - External Users Access Ends  */
  admin_hrbpUsers: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "ORG_ID_1",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Org1 Id",
      width: "10%",
    },
    /* Release 3.1 - 4.6 - Issue 145707 - Starts */
    {
      index: true,
      name: "ORG1_HRBP_nokiaid",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Org1 Nokia Id",
      width: "12%",
    },
    {
      name: "ORG_ID_2",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Org2 Id",
      width: "10%",
    },
    {
      name: "ORG2_HRBP_nokiaid",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Org2 Nokia Id",
      width: "12%",
    },
    {
      name: "ORG_ID_3",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Org3 Id",
      width: "10%",
    },
    {
      name: "ORG3_HRBP_nokiaid",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Org3 Nokia Id",
      width: "12%",
    },
    /* Release 3.1 - 4.6 - Issue 145707 - Ends */
    {
      name: "ORG1_HRBP_persno",
      section: "Admin Tables",
      sortable: true,
      visible: false,
      label: "Org1 Pers No",
      width: "8.5%",
    },
    {
      name: "ORG2_HRBP_persno",
      section: "Admin Tables",
      sortable: true,
      visible: false,
      label: "Org2 Pers No",
      width: "8.5%",
    },
    {
      name: "ORG3_HRBP_persno",
      section: "Admin Tables",
      sortable: true,
      visible: false,
      label: "Org3 Pers No",
      width: "8.5%",
    },
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "15%",
    },
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      width: "13%",
    },
  ],
  admin_adminUsers: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "email_work",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Email",
      width: "55%",
    },
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "20%",
    },
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      width: "20%",
    },
  ],
  admin_gctUsers: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "email_work",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Email",
      width: "55%",
    },
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "20%",
    },
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      width: "20%",
    },
  ],
  admin_hrConnection: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "nokia_id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Nokia Id",
      width: "20%",
    },
    /* Release 3.1 - 4.6 - 145709 - Starts */
    {
      name: "full_name",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Full Name",
      width: "20%",
    },
    /* Release 3.1 - 4.6 - 145709 - Ends */
    {
      name: "country_name",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Country Name",
      /* Release 3.1 - 4.6 - 145709 - Starts */
      width: "15%",
      /* Release 3.1 - 4.6 - 145709 - Ends */
    },
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "20%",
    },
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      width: "20%",
    },
  ],
  changeApprovers: [
    { index: true, name: "employee_request_id" },
    fields.request_by("approveRequests"),
    fields.request_type("approveRequests"),
    fields.request_for("approveRequests"),
    fields.ttc_increase_amount("approveRequests"),
    fields.override_manager_proposal("approveRequests"),
    fields.override_fte_annual_salary("approveRequests"),
    fields.override_fte_incentive_target_amount("approveRequests"),
    fields.override_fte_incentive_target_percentage("approveRequests"),
    fields.override_fte_ttc_annual_salary("approveRequests"),
    fields.override_promotion_increase("approveRequests"),
    fields.override_ti_harmonization("approveRequests"),
    // Current Section
    tooltipFields.current_country_name("approveRequests"),
    tooltipFields.current_organization_unit_name("approveRequests"),
    tooltipFields.current_organization_unit_1_name("approveRequests"),
    tooltipFields.job_family_name("approveRequests"),
    tooltipFields.job_subfamily_name("approveRequests"),
    tooltipFields.job_name("approveRequests"),
    {
      name: "request.for.job_grade",
      tooltip_section: "Current",
      label: "Job Grade",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    {
      name: "request.for.position_title",
      tooltip_section: "Current",
      label: "Position Title",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    tooltipFields.fte_annual_salary("approveRequests"),
    tooltipFields.fte_incentive_target_percentage("approveRequests"),
    tooltipFields.fte_incentive_target_amount("approveRequests"),
    tooltipFields.fte_ttc_annual_salary("approveRequests"),
    {
      name: "request.for.ttc_compa_ratio",
      tooltip_section: "Current",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "request.for.incentive_plan_type",
      tooltip_section: "Current",
      label: "Incentive Plan",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.for.ttc_employee_quartile",
      tooltip_section: "Current",
      label: "Quartile",
      visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
        (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
        /* Release 3.2 - 1.1 - Ends */
    },
    // New Section
    tooltipFields.new_country_name("approveRequests"),
    tooltipFields.new_organization_unit_name("approveRequests"),
    tooltipFields.new_organization_unit_1_name("approveRequests"),
    tooltipFields.promotion_job_family_name("approveRequests"),
    tooltipFields.promotion_job_subfamily_name("approveRequests"),
    tooltipFields.promotion_job_name("approveRequests"),
    tooltipFields.promotion_job_grade("approveRequests"),
    tooltipFields.new_position_title("approveRequests"),
    tooltipFields.new_fte_annual_salary("approveRequests"),
    tooltipFields.new_incentive_target_percentage("approveRequests"),
    tooltipFields.new_fte_incentive_target_amount("approveRequests"),
    {
      name: "request.new_fte_ttc_annual_salary",
      label: "Total Target Cash",
      tooltip_section: "New",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    {
      name: "request.new_ttc_compa_ratio",
      tooltip_section: "New",
      label: "TTC Compa-Ratio",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: numberRenderer,
    },
    {
      name: "request.new_incentive_plan_type",
      tooltip_section: "New",
      label: "Incentive Plan",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: incentivePlanRenderer,
    },
    {
      name: "request.new_quartile",
      tooltip_section: "New",
      label: "Quartile",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data) =>
        value || data.request.for.ttc_employee_quartile,
    },
    // Date
    {
      name: "request.effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
    {
      name: "request.payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      /* Release 3.2 - 1.1 - Starts */
      (data.request.request_type === "onetime" || data.request.request_type === "businessexcellenceaward"),
      /* Release 3.2 - 1.1 - Ends */
      renderer: dateRenderer,
    },
     /* Release 3.2 - 11.2 - Starts */
    //  {
    //   name: "request.reminder_info.last_reminder_date",
    //   tooltip_section: "Date",
    //   label: "Last Reminder Date",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: dateTimeRenderer,
    // },
    // {
    //   name: "request.reminder_info.reminder_count",
    //   tooltip_section: "Date",
    //   label: "Reminder Count",
    //   visible_in_tooltip: (column, data, account) =>
    //     data.request_type === "internalhire",
    //   renderer: (value) => value,
    // },
    /* Release 3.2 - 11.2 - Ends */
    // Budget Section
    {
      name: "request.offcycle_budget_update",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        /* Release 3.2 - 1.1 - Starts */
        (data.request.request_type !== "internalhire" && data.request.request_type !== "businessexcellenceaward"),
        /* Release 3.2 - 1.1 - Ends */
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    {
      name: "request.Final_Off_Cycle_Budget",
      label: "Off-Cycle Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        data.request.request_type === "internalhire",
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Starts */
    {
      name: "request.offcycle_budget_update",
      label: "BEA Budget Spending",
      tooltip_section: "Budget",
      visible_in_tooltip: (column, data, account) =>
        data.request.request_type === "businessexcellenceaward",
      renderer: (value, data, account, config) =>
        currencyDisplayRenderer(value, data.request, account, config),
    },
    /* Release 3.2 - 1.1 - Ends */
    {
      name: "budget_organization_name",
      allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb"],
      tooltip_section: "Budget",
      label: "Organization Name",
      visible_in_tooltip: (column, data, account) =>
        data.budget_allocations && data.budget_allocations[0],
      defaultValue: (value, data) =>
        data.budget_allocations[0].organization_unit_name,
    },
    // R2 - Approval Chain Section Displaying levels of information
    {
      name: "approver",
      tooltip_section: "Approval Chain",
      visible_in_tooltip: (column, data, account) =>
        data.request.approvals.length > 0,
      renderer: (value, data) =>
        data.request.approvals.map((a, index) =>
          a.approver === null ? (
            a.approval_status === "active" ? (
              <span style={{ fontWeight: "600" }}>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.on_behalf_of_approver
                  ? a.on_behalf_of_approver.full_name + " on behalf of "
                  : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            ) : (
              <span>
                {/* Release 3.1 - 4.2 - 145685 - Starts */}
                {a.on_behalf_of_approver
                  ? a.on_behalf_of_approver.full_name + " on behalf of "
                  : ""}
                {/* Release 3.1 - 4.2 - 145685 - Ends */}
                Inactive approver<span className="arrowStyles"></span>
              </span>
            )
          ) : a.approval_status === "active" ? (
            <span style={{ fontWeight: "600" }}>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.on_behalf_of_approver
                ? a.on_behalf_of_approver.full_name + " on behalf of "
                : ""}
              {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          ) : (
            <span>
              {/* Release 3.1 - 4.2 - 145685 - Starts */}
              {a.on_behalf_of_approver
                ? a.on_behalf_of_approver.full_name + " on behalf of "
                : ""}
              {/* Release 3.1 - 4.2 - 145685 - Ends */}
              {a.approver.full_name}
              <span className="arrowStyles"></span>
            </span>
          )
        ),
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) =>
        data.request.comments.length > 0,
      renderer: (value, data) =>
        data.request.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Starts */
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.request.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.request.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    /* Release 3.1 - Ends */
  ],
  /* Release 3.1 - Ends */
  /* Release 3.2 -10.1 - Starts */
  admin_rbcbUsers: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "email_work",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Email",
      width: "30%",
    }, 
    {
      name: "hr_region",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "HR Region",
      width: "25%",
    },    
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "20%",
    },
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      width: "20%",
    },
  ],
  /* Release 3.2 -10.1 - Ends */
  /* Release 3.2 -10.4 - Starts */
  admin_keyUsers: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "email_work",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Email",
      width: "55%",
    },
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "20%",
    },
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      width: "20%",
    },
  ],
  /* Release 3.2 -10.4 - Ends */
  /* Release 3.2 - 8.1 - Starts */
  employeeDashboardHistory: [
    {
      type: "string",
      index: true,
      name: "id",
      //width: "50px",
      section: "employeeDashboardHistory",
    },
    {
      type: "string",
      name: "full_name",
      label: "Employee Name",
      visible: true,
      sortable: true,
      //width: "50px",
      section: "employeeDashboardHistory",
    },
    {
      type: "string",
      name: "pers_no",
      label: "Person Number",
      visible: true,
      sortable: true,
      //width: "50px",
      section: "employeeDashboardHistory",
    },
    {
      type: "string",
      name: "nokia_id",
      label: "Nokia ID",
      visible: true,
      sortable: true,
      //width: "50px",
      section: "employeeDashboardHistory",
    },
    {
      type: "string",
      name: "requisition_id",
      label: "Requisition ID",
      visible: true,
      sortable: true,
      //width: "50px",
      section: "employeeDashboardHistory",
    },
    {
      type: "string",
      name: "organization_unit_name",
      label: "Organization unit",
      visible: true,
      sortable: true,
      //width: "50px",
      section: "employeeDashboardHistory",
    },
  ],
   /* Release DEM0069359:Add additional data for TAP users - Part 1 Start */
  employeehistoryaddrespermanent: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers No",
      index: true,
      visible: false,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
    {
      type: "string",
      name: "country",
      label: "Country",
      visible: true,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
    {
      type: "string",
      name: "work_state_province",
      label: "State code- State name",
      visible: true,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
    {
      type: "string",
      name: "postal_code",
      label: "Zip code",
      visible: true,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
    {
      type: "string",
      name: "city",
      label: "City",
      visible: true,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
  ],
  employeehistoryaddressoffice: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers No",
      index: true,
      visible: false,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
    {
      type: "string",
      name: "country_name",
      label: "Country",
      visible: true,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
    {
      type: "string",
      name: "postal_code",
      label: "Postal code",
      visible: true,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
    {
      type: "string",
      name: "city",
      label: "City",
      visible: true,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
    {
      type: "string",
      name: "street_and_house_num",
      label: "Street and House No",
      visible: true,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
    {
      type: "string",
      name: "building_name",
      label: "Building code-Building Name",
      visible: true,
      sortable: false,
      section: "employeehistoryaddresssection",
    },
  ],
   /* Release DEM0069359:Add additional data for TAP users - Part 1 Ends */
  employeePerformanceDashboard: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers No",
      index: true,
      visible: false,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    {
      type: "string",
      name: "evaluation_date",
      label: "Evaluation Date",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    {
      type: "string",
      name: "country_name",
      label: "Country Name",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    {
      type: "string",
      name: "evaluation_code",
      label: "Evaluation Code",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    {
      type: "string",
      name: "evaluatuion_type",
      label: "Evaluation Type",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    {
      type: "string",
      name: "osc_rating",
      label: "OCS Rating",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    {
      type: "string",
      name: "evaluation_manager",
      label: "Evaluation Manager",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    {
      type: "string",
      name: "evaluations_changed_on",
      label: "Evaluations Changed On",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    {
      type: "string",
      name: "evaluation_source",
      label: "Evaluation Source",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    /*  DEM0069361 - TAP Access Part 3 Starts */
    /*{
      type: "string",
      name: "evaluation_changed_by",
      label: "Evaluation Changed By",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },*/
    /*  DEM0069361 - TAP Access Part 3 Ends */
    {
      type: "string",
      name: "evaluation_period_start_date",
      label: "Evaluation Start Date",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
    {
      type: "string",
      name: "evaluation_period_end_date",
      label: "Evaluation End Date",
      visible: true,
      sortable: false,
      section: "employeePerformanceDashboard",
    },
  ],
  targetIncentiveHistory: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "targetIncentiveHistory",
    },
    {
      type: "string",
      name: "start_date",
      label: "Date From",
      visible: true,
      sortable: false,
      section: "targetIncentiveHistory",
    },
    {
      type: "string",
      name: "end_date",
      label: "Date To",
      visible: true,
      sortable: false,
      section: "targetIncentiveHistory",
    },
    {
      type: "string",
      name: "incentive_target_amount",
      label: "Incentive Target amount",
      visible: true,
      sortable: false,
      section: "targetIncentiveHistory",
      renderer: currencyDisplayRenderer,
    },
    {
      type: "string",
      name: "incentive_target_percentage",
      label: "Incentive Target Percentage",
      visible: true,
      sortable: false,
      section: "targetIncentiveHistory",
      renderer: percentRenderer,
    },
    {
      type: "string",
      name: "incentive_plan_type",
      label: "Compensation Plan Type",
      visible: true,
      sortable: false,
      section: "targetIncentiveHistory",
    },
  ],  
  
  adresscurrent1: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "adresscurrent1",
    }],
   /* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
   actionsIT0000: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "actionsIT0000",
    },
    {
      type: "string",
      name: "Date_From",
      label: "Date From",
      visible: true,
      sortable: false,
      renderer: dateRenderer,
      section: "actionsIT0000",
    },
    {
      type: "string",
      name: "Action_type_name",
      label: "Action Type Name",
      visible: true,
      sortable: false,
      section: "actionsIT0000",
    },
    {
      type: "string",
      name: "Action_reason_name",
      label: "Action Reason Name",
      visible: true,
      sortable: false,
      section: "actionsIT0000",
    },
  ],
    orgAssignmentIT0001: [
      {
        type: "string",
        name: "pers_no",
        label: "Pers no",
        index: true,
        visible: false,
        sortable: false,
        section: "orgAssignmentIT0001",
      },
      {
        type: "string",
        name: "Action_start_date",
        label: "Start Date",
        visible: true,
        sortable: false,
        renderer: dateRenderer,
        section: "orgAssignmentIT0001",
      },
      {
        type: "string",
        name: "Cost_center",
        label: "Cost Center",
        visible: true,
        sortable: false,
        section: "orgAssignmentIT0001",
      },
      {
        type: "string",
        name: "Position_name",
        label: "Position Name",
        visible: true,
        sortable: false,
        section: "orgAssignmentIT0001",
      },
      {
        type: "string",
        name: "Position",
        label: "Position",
        visible: true,
        sortable: false,
        section: "orgAssignmentIT0001",
      },
      {
        type: "string",
        name: "Org_Unit",
        label: "OrgUnit",
        visible: true,
        sortable: false,
        section: "orgAssignmentIT0001",
      },
      {
        type: "string",
        name: "Org_Unit_Name",
        label: "OrgUnit Name",
        visible: true,
        sortable: false,
        section: "orgAssignmentIT0001",
      }
      ,
      {
        type: "string",
        name: "job_id",
        label: "Job Key",
        visible: true,
        sortable: false,
        section: "orgAssignmentIT0001",
      },
      {
        type: "string",
        name: "job_name",
        label: "Job Name",
        visible: true,
        sortable: false,
        section: "orgAssignmentIT0001",
      },     
    ],
      referralAward: [
        {
          type: "string",
          name: "pers_no",
          label: "Pers no",
          index: true,
          visible: false,
          sortable: false,
          section: "referralAward",
        },
        {
          type: "string",
          name: "Addl_Pay_Amount",
          label: "Amount",
          visible: true,
          sortable: false,
          section: "referralAward",
        },
        {
          type: "string",
          name: "Addl_Pay_Currency",
          label: "Currency",
          visible: true,
          sortable: false,
          section: "referralAward",
        },
        {
          type: "string",
          name: "Addl_Pay_Date_of_Origin",
          label: "Date",
          visible: true,
          sortable: false,
          renderer: dateRenderer,
          section: "referralAward",
        },
        {
          type: "string",
          name: "Assignment_No",
          label: "Assignment number",
          visible: true,
          sortable: false,
          section: "referralAward",
        },
        {
          type: "string",
          name: "Reason_for_Addl_Pay",
          label: "Reason for change",
          visible: true,
          sortable: false,
          section: "referralAward",
        },
      ],
  latestInformation: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Employment_status_name",
      label: "Status",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Headcount_status_name",
      label: "HC Status",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "EE_group_name",
      label: "Employee group",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "DT01_nokia_start",
      label: "Nokia Start date",
      visible: true,
      sortable: false,
      renderer: dateRenderer,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Contract_type_name",
      label: "Contract Type/Contact End Date",
      visible: true,
      sortable: false,
      renderer: dateRenderer,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Annual_working_hours",
      label: "Working Time",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Daily_working_hours",
      label: "Daily Working Hours",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Hours_per_week",
      label: "Hours Per Week",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Monthly_hours",
      label: "Monthly Hours",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Working_time_start_date",
      label: "Working Time Start Date",
      visible: true,
      sortable: false,
      renderer: dateRenderer,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Weekly_workdays",
      label: "Weekly Workdays",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Work_schedule_rule",
      label: "Work Schedule Rule",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
    {
      type: "string",
      name: "Work_Week",
      label: "Work Week",
      visible: true,
      sortable: false,
      section: "latestInformation",
    },
  ]

  ,jobGradeHistory: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "jobGradeHistory",
    },
    {
      type: "string",
      name: "start_date",
      label: "Date From",
      visible: true,
      sortable: false,
      section: "jobGradeHistory",
    },
    {
      type: "string",
      name: "end_date",
      label: "Date To",
      visible: true,
      sortable: false,
      section: "jobGradeHistory",
    }, {
      type: "string",
      name: "country_name",
      label: "Country Name",
      visible: true,
      sortable: false,
      section: "jobGradeHistory",
    },
    {
      type: "string",
      name: "job_grade",
      label: "Job Grade",
      visible: true,
      sortable: false,
      section: "jobGradeHistory",
    },
    {
      type: "string",
      name: "job_family_name",
      label: "Job Family",
      visible: true,
      sortable: false,
      section: "jobGradeHistory",
    },
    {
      type: "string",
      name: "job_subfamily_name",
      label: "Job SubFamily",
      visible: true,
      sortable: false,
      section: "jobGradeHistory",
    },
    {
      type: "string",
      name: "job_profile_name",
      label: "Job Profile Name",
      visible: true,
      sortable: false,
      section: "jobGradeHistory",
    },{
      type: "string",
      name: "job_id",
      label: "Job ID",
      visible: true,
      sortable: false,
      section: "jobGradeHistory",
    }
    ,{
      type: "string",
      name: "hc_rls_group",
      label: "Business Line",
      visible: true,
      sortable: false,
      section: "jobGradeHistory",
    },
  ],
   /* Start work DEM0069360:Add additional data for TAP users Part 2 */ 
     hcrLSGroupcompensation: [
      {
        type: "string",
        name: "pers_no",
        label: "Pers no",
        index: true,
        visible: false,
        sortable: false,
        section: "hcrLSGroupcompensation",
      },
    {
      type: "string",
      name: "hc_rls_group",
      label: "HC RLS Group",
      visible: true,
      sortable: false,
      section: "hcrLSGroupcompensation",
    }
  ],


  actualPaidIncentiveHistory: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "actualPaidIncentiveHistory",
    },    
    {
      type: "string",
      name: "paid_date",
      label: "Paid Date",
      visible: true,
      sortable: false,
      section: "actualPaidIncentiveHistory",
    },
    {
      type: "string",
      name: "addl_pay_amount",
      label: "Additional Pay Amount",
      visible: true,
      sortable: false,
      section: "actualPaidIncentiveHistory",
      renderer: currencyDisplayRenderer,
    },
    {
      type: "string",
      name: "wage_type",
      label: "Wage Type",
      visible: true,
      sortable: false,
      section: "actualPaidIncentiveHistory",
    },
    {
      type: "string",
      name: "incentive_name",
      label: "Incentive Name",
      visible: true,
      sortable: false,
      section: "actualPaidIncentiveHistory",
    },
  ],
   /* End work DEM0069360:Add additional data for TAP users Part 2 */ 
  organizationalHistory: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "organizationalHistory",
    },    
    {
      type: "string",
      name: "start_date",
      label: "Start Date",
      visible: true,
      sortable: false,
      section: "organizationalHistory",
    },   
    {
      type: "string",
      name: "end_date",
      label: "End Date",
      visible: true,
      sortable: false,
      section: "organizationalHistory",
    }, 
    {
      type: "string",
      name: "org_unit",
      label: "Org Unit",
      visible: true,
      sortable: false,
      section: "organizationalHistory",
    },
    {
      type: "string",
      name: "org_unit_name",
      label: "Org Name",
      visible: true,
      sortable: false,
      section: "organizationalHistory",
    },
    {
      type: "string",
      name: "line_mgr_n_nokia_id",
      label: "Line Manager Nokia ID",
      visible: true,
      sortable: false,
      section: "organizationalHistory",
    },
    {
      type: "string",
      name: "line_mgr_full_name",
      label: "Line Manager Name",
      visible: true,
      sortable: false,
      section: "organizationalHistory",
    },    
  ],
  /* Start work DEM0069360:Add additional data for TAP users Part 2 */ 
  salaryHistory: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "salaryHistory",
    },    
    {
      type: "string",
      name: "start_date",
      label: "Start Date",
      visible: true,
      sortable: false,
      section: "salaryHistory",
    },   
    {
      type: "string",
      name: "end_date",
      label: "End Date",
      visible: true,
      sortable: false,
      section: "salaryHistory",
    }, 
    {
      type: "string",
      name: "annual_base_salary",
      label: "Annual Base Salary",
      visible: true,
      sortable: false,
      section: "salaryHistory",
      renderer: currencyDisplayRenderer,
    },{
      type: "string",
      name: "FTE_Addl_TTC_Amount",
      label: "Total Target Cash (TTC)",
      visible: true,
      sortable: false,
      section: "salaryHistory",
      renderer: currencyDisplayRenderer,
    }, 
    {
      type: "string",
      name: "fte_annual_salary",
      label: "FTE Annual Salary",
      visible: true,
      sortable: false,
      section: "salaryHistory",
      renderer: currencyDisplayRenderer,
    },
    {
      type: "string",
      name: "fte_ttc_annual_salary",
      label: "FTE Total Target Cash (TTC)",
      visible: true,
      sortable: false,
      section: "salaryHistory",
      renderer: currencyDisplayRenderer,
    }, 
    {
      type: "string",
      name: "Pay_Period_salary_wage_amount_1",
      label: "Monthly Base Salary",
      visible: true,
      sortable: false,
      section: "salaryHistory",
      renderer: currencyDisplayRenderer,
    },
    {
      type: "string",
      name: "pay_grade",
      label: "Pay Grade",
      visible: true,
      sortable: false,
      section: "salaryHistory",
    },
    {
      type: "string",
      name: "reason_for_pay_change",
      label: "Reason For Pay Change",
      visible: true,
      sortable: false,
      section: "salaryHistory",
    },{
      type: "string",
      name: "TTC_Employee_Quartile",
      label: "TTC Quartile",
      visible: true,
      sortable: false,
      section: "salaryHistory",
    },{
      type: "string",
      name: "TTC_Compa_Ratio",
      label: "TTC Compa-Ratio",
      visible: true,
      sortable: false,
      section: "salaryHistory",
    },     
  ],
  /* End work DEM0069360:Add additional data for TAP users Part 2 */ 
  /* Release 3.2 - 8.1 - Ends */
  /* Release 3.2 - 1.1 - Starts */
  admin_businessOwners: [
    {
      index: true,
      name: "id",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "ID",
      width: "5%",
    },
    {
      name: "email_work",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Email",
      width: "55%",
    },    
    {
      name: "upd_ins_by",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Uploaded By",
      width: "20%",
    },
    {
      name: "upd_ins_ts",
      section: "Admin Tables",
      sortable: true,
      visible: true,
      label: "Last Updated",
      width: "20%",
    },
  ],
  beabudgetmanage: [
    { index: true, name: "budget_id", sortable: false, visible: false, section: "beabudgetmanage" },
    fields.budget_year("beabudgetmanage"),
    fields.budget_orgname("beabudgetmanage"),
    fields.budget_capital("beabudgetmanage"),
    fields.budget_value("beabudgetmanage"),
    fields.budget_inprogress("beabudgetmanage"),
    fields.budget_spent("beabudgetmanage"),
    fields.budget_forecast("beabudgetmanage"),
    fields.budget_available("beabudgetmanage"),
    fields.budget_description("beabudgetmanage"),
  ],
  beabusinessownerbudgetmanage: [
    { index: true, name: "bo_budget_id", sortable: false, visible: false, section: "beabusinessownerbudgetmanage" },
    fields.merge_full_name("beabusinessownerbudgetmanage"),
    fields.bo_budget("beabusinessownerbudgetmanage"),
    fields.bo_budget_inprogress("beabusinessownerbudgetmanage"),
    fields.bo_budget_spent("beabusinessownerbudgetmanage"),
    fields.bo_budget_available("beabusinessownerbudgetmanage"),
  ],
  requestBusinessExcellenceAward: [
    { index: true, name: "guid", section: "requestBusinessExcellenceAward" },
    fields.nokia_id("requestBusinessExcellenceAward"),
    fields.full_name("requestBusinessExcellenceAward"),
    //fields.fte_ttc_annual_salary("requestBusinessExcellenceAward"),
    fields.ttc_increase_amount("requestBusinessExcellenceAward"),
    fields.lumpsum_abs_percentage("requestBusinessExcellenceAward"),
    {
      type: "float",
      inputType: "number",
      name: "ttc_increase_percentage",
      label: "% In Relation To Total Target Cash",
      section: "Merit/Promotion Increase",
      visible: true,
      editable: false,
      renderer: percentRenderer,
    },
    {
      type: "string",
      name: "award_title",
      label: "Award Title",
      visible: true,
      editable: false,
      width: "120px"
    },
    {
      type: "string",
      name: "award_message",
      label: "Award Message",
      visible: true,
      editable: false,
      width: "180px"
    },
    {
      type: "string",
      name: "award_privacy",
      label: "Award Privacy",
      visible: true,
      editable: false,
    },
    {
      type: "string",
      name: "award_reason",
      label: "Award Reason",
      visible: true,
      editable: false,
    },
    {
      type: "string",
      name: "nominator_email_address",
      label: "Nominator Email Address",
      visible: true,
      editable: false,
      width: "120px"
    },
  ],
  /* Release 3.2 - 1.1 - Ends */
  /* Release 3.2 - 1.1 - Starts */  
  editBEA: [
    { index: true, name: "employee_request_id" },
    fields.nokia_id("editBEA"),
    fields.full_name("editBEA"),
    fields.fte_ttc_annual_salary("editBEA"),
    fields.ttc_increase_amount("editBEA"),
    fields.lumpsum_abs_percentage("editBEA"),
    {
      type: "float",
      inputType: "number",
      name: "request.ttc_increase_percentage",
      label: "% In Relation To Total Target Cash",
      section: "Merit/Promotion Increase",
      visible: true,
      renderer: percentRenderer,
    },
    {
      type: "string",
      name: "request.award_title",
      label: "Award Title",
      visible: true,
      editable: false,
      width: "120px"
    },
    {
      type: "string",
      name: "request.award_message",
      label: "Award Message",
      visible: true,
      editable: false,
      width: "180px"
    },
    {
      type: "string",
      name: "request.award_privacy",
      label: "Award Privacy",
      visible: true,
      editable: false,
    },
    {
      type: "string",
      name: "request.award_reason",
      label: "Award Reason",
      visible: true,
      editable: false,
    },
    {
      type: "string",
      name: "request.nominator_email_address",
      label: "Nominator Email Address",
      visible: true,
      editable: false,
      width: "120px"
    },
    // Date
    {
      name: "request.effective_date",
      tooltip_section: "Date",
      label: "Effective Date",
      visible_in_tooltip: (column, data, account) =>
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward"),
      renderer: dateRenderer,
    },
    {
      name: "request.payment_date",
      tooltip_section: "Date",
      label: "Payment Date",
      visible_in_tooltip: (column, data, account) =>
      (data.request.request_type === "onetime" || data.request.request_type === "businessexcellenceaward"),
      renderer: dateRenderer,
    },
    {
      name: "request.reminder_info.last_reminder_date",
      tooltip_section: "Date",
      label: "Last Reminder Date",
      visible_in_tooltip: (column, data, account) =>
        data.request_type === "internalhire",
      renderer: dateRenderer,
    },
    {
      name: "request.reminder_info.reminder_count",
      tooltip_section: "Date",
      label: "Reminder Count",
      visible_in_tooltip: (column, data, account) =>
        data.request_type === "internalhire",
      renderer: (value) => value,
    },
    // R2 - Comment Section displaying with history and timestamp
    {
      name: "request.comment_description",
      tooltip_section: "Comments",
      visible_in_tooltip: (column, data, account) =>
      (data.request.request_type !== "onetime" && data.request.request_type !== "businessexcellenceaward")
      && data.request.comments.length > 0,
      renderer: (value, data) =>
        data.request.comments.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>{a.description}</span>
              <i>
                {" "}
                --
                {a.commenter === null
                  ? "Inactive Approver"
                  : a.commenter.full_name}
                , {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
    {
      name: "change_approver_description",
      tooltip_section: "Change Approver",
      visible_in_tooltip: (column, data, account) =>
        data.request.changeApprovers.length > 0,
      renderer: (value, data) =>
        data.request.changeApprovers.map((a, index) => (
          <ul style={{ paddingLeft: "10px", margin: 0 }}>
            <li style={{ listStyleType: "circle" }}>
              <span style={{ fontSize: "11px" }}>
                {a.approval ? "Level " + a.approval.approver_level : ""} - {"'"}
                {a.preApprover
                  ? a.preApprover.full_name
                  : "Inactive approver"}{" "}
                {"'"}
                {" replaced with "} {"'"}
                {a.postApprover
                  ? a.postApprover.full_name
                  : "Inactive approver"}
                {"'"}
              </span>
              <i>
                --{a.changer ? a.changer.full_name : "Inactive changer"},{" "}
                {dateTimeRenderer(a.upd_ins_ts)}
              </i>
            </li>
          </ul>
        )),
    },
  ],
  /* Release 3.2 - 1.1 - Ends */
  /* Release 3.3 - 8.3 - Starts */
  roCountryStatistics: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "roCountryStatistics",
    },    
    {
      type: "string",
      name: "subtype",
      label: "Sub Type",
      visible: true,
      sortable: false,
      section: "roCountryStatistics",
    },    
    {
      type: "string",
      name: "date_from",
      label: "Date From",
      visible: true,
      sortable: false,
      section: "roCountryStatistics",
    },    
    {
      type: "string",
      name: "date_to",
      label: "Date To",
      visible: true,
      sortable: false,
      section: "roCountryStatistics",
    },    
    {
      type: "string",
      name: "occup_code",
      label: "Occup Code",
      visible: true,
      sortable: false,
      section: "roCountryStatistics",
    },   
    {
      type: "string",
      name: "occup_code_description",
      label: "Occup Code Description",
      visible: true,
      sortable: false,
      section: "roCountryStatistics",
    },    
  ],
  roInsuranceTaxData: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      width: "5%",
      section: "roInsuranceTaxData",
    },   
    {
      type: "string",
      name: "date_from",
      label: "Date From",
      visible: true,
      sortable: false,
      width: "5%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "date_to",
      label: "Date To",
      visible: true,
      sortable: false,
      width: "5%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "contract_type_ins_unemployment",
      label: "Contract Type Ins Unemployment",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "number_of_hours",
      label: "Number of Hours",
      visible: true,
      sortable: false,
      width: "5%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "assured_type",
      label: "Assured Type",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "ass_type_er_contr_exempt",
      label: "Ass Type Er Contract Exempt",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "reduc_deduc_amount",
      label: "Reduce Deduct Amount",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "contract_type_ins_pension",
      label: "Contract Type Ins Pension",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "partial_time",
      label: "Partial Time",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "retired_ee",
      label: "Retired Ee",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "work_condition",
      label: "Work Condition",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "norma",
      label: "Norma",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "contract_type_ins_health",
      label: "Contract Type Ins Health",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "health_house_id",
      label: "Health House Id",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "tax_free_amount",
      label: "Tax Free Amount",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "stop_personal_tax_free",
      label: "Stop Personal Tax Free",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "tax_calculate",
      label: "Tax Calculate",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "tax_exemption_for_handicapp",
      label: "Tax Exemption for Handicapp",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "tax_exemption_for_programmers",
      label: "Tax Exemption for Programmers",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "tax_exemption_for_researchers",
      label: "Tax Exemption for Researchers",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "tax_office",
      label: "Tax Office",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },    
    {
      type: "string",
      name: "tax_office_name",
      label: "Tax Office Name",
      visible: true,
      sortable: false,
      width: "10%",
      section: "roInsuranceTaxData",
    },   
  ],
  frLocalPayAttributes: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      width: "5%",
      section: "frLocalPayAttributes",
    },    
    {
      type: "string",
      name: "date_from",
      label: "Date From",
      visible: true,
      sortable: false,
      width: "5%",
      section: "frLocalPayAttributes",
    },    
    {
      type: "string",
      name: "date_to",
      label: "Date To",
      visible: true,
      sortable: false,
      width: "5%",
      section: "frLocalPayAttributes",
    },    
    {
      type: "string",
      name: "comp_pay_scl_type",
      label: "Comp Pay Scl Type",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },
	  {
      type: "string",
      name: "capacity_utilization",
      label: "Capacity Utilization",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },
	  {
      type: "string",
      name: "comp_pay_scl_area",
      label: "Comp Pay Scl Area",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },
	  {
      type: "string",
      name: "working_hours_per_payroll_period",
      label: "Working Hours Per Payroll Period",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },
	  {
      type: "string",
      name: "comp_pay_scl_grp",
      label: "Comp Pay Scl Grp",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },
	  {
      type: "string",
      name: "comp_pay_scl_lev",
      label: "Comp Pay Scl Level",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },
	  {
      type: "string",
      name: "france_salary",
      label: "France Salary",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
      renderer: currencyDisplayRenderer,
    },   
	  {
      type: "string",
      name: "wage_type1_text",
      label: "Wage Type1 Text",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },   
	  {
      type: "string",
      name: "wage_type1_amount",
      label: "Wage Type1 Amount",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
      renderer: currencyDisplayRenderer,
    },
	  {
      type: "string",
      name: "wage_type2_text",
      label: "Wage Type2 Text",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },
	  {
      type: "string",
      name: "wage_type2_amount",
      label: "Wage Type2 Amount",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
      renderer: currencyDisplayRenderer,
    },
	  {
      type: "string",
      name: "wage_type3_text",
      label: "Wage Type3 Text",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },
	  {
      type: "string",
      name: "wage_type3_amount",
      label: "Wage Type3 Amount",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
      renderer: currencyDisplayRenderer,
    },
	  {
      type: "string",
      name: "wage_type4_text",
      label: "Wage Type4 Text",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
    },
	  {
      type: "string",
      name: "wage_type4_amount",
      label: "Wage Type4 Amount",
      visible: true,
      sortable: false,
      width: "10%",
      section: "frLocalPayAttributes",
      renderer: currencyDisplayRenderer,
    },   
  ],
  /* Release 3.3 - 8.3 - Ends */
  /*  DEM0067988 - TAP Access based on requisition Starts */
  tapEmployeesRequisition: [
    { index: true, name: "nokia_id" },
    fields.nokia_id("employeesForTapRequisition"),
    {
      type: "string",
      name: "requisition_id",
      section: "TAP Employees Information",
      label: "Requisition ID",
      visible: true,
      filterable: true,
      width: "70px",
      sortable: true,
    },
    fields.full_name("employeesForTapRequisition"),
    fields.country_name("employees"),
    {
      type: "string",
      name: "city",
      section: "TAP Employees Information",
      label: "City",
      visible: true,
      filterable: true,
      width: "70px",
      sortable: true,
    },
    {
      type: "string",
      name: "hr_region",
      section: "TAP Employees Information",
      label: "HR Region",
      visible: true,
      filterable: true,
      width: "70px",
      sortable: true,
    },
    fields.business_group("employees"),
    fields.organization_unit_name("employeesForTapRequisition"),
    {
      type: "string",
      name: "incentive_plan_type",
      section: "Current Information",
      label: "Incentive Plan",
      visible: true,
      sortable: true,
      width: "5%",
      renderer: incentivePlanRenderer,
    },
    fields.job_name("employees"),
    {
      type: "string",
      name: "position_title",
      section: "TAP Employees Information",
      label: "Position Title",
      visible: true,
      filterable: true,
      width: "10%",
      sortable: true,
    },
    fields.email_work("employeesForTapRequisition"),
  ],
  /*  DEM0067988 - TAP Access based on requisition Ends */

  /*  DEM0069361 - TAP Access Part 3 Starts */
  roEducation: [
    {
      type: "string",
      name: "pers_no",
      label: "Pers no",
      index: true,
      visible: false,
      sortable: false,
      section: "roEducation",
    },    
    /*{
      type: "string",
      name: "hc_rls_group",
      label: "HC RLS Group",
      visible: true,
      sortable: false,
      section: "roEducation",
    }, */  
    {
      type: "string",
      name: "country",
      label: "Country Code",
      visible: true,
      sortable: false,
      section: "roEducation",
    },    
    {
      type: "string",
      name: "country_name",
      label: "Country Name",
      visible: true,
      sortable: false,
      section: "roEducation",
    },    
    {
      type: "string",
      name: "certificate_code_text",
      label: "Certificate",
      visible: true,
      sortable: false,
      section: "roEducation",
    },   
    {
      type: "string",
      name: "education_code_text",
      label: "Educational Establishment",
      visible: true,
      sortable: false,
      section: "roEducation",
    }, 
    /*{
      type: "string",
      name: "branch_of_study",
      label: "Branch of Study",
      visible: true,
      sortable: false,
      section: "roEducation",
    },    
    {
      type: "string",
      name: "batch_id",
      label: "Batch ID",
      visible: true,
      sortable: false,
      section: "roEducation",
    },*/
  ],
  /*  DEM0069361 - TAP Access Part 3 Ends */

};

export { columns };
