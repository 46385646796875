import React, { useState, useRef, useEffect } from "react";

import { MOC_UPLOAD, moc_failure, moc_requests } from "../../graphql";
import { apollouploadClient } from "../../";
import { Mutation, ApolloProvider } from "react-apollo";
import {
  Table,
  TableHeadingWrap,
  /* Release 3.2 - 7.1 - Starts */
  TableActionsWrap,
  TableAction,
  LoaderWrap,
  /* Release 3.2 - 7.1 - Ends */
} from "../../components";
import { columns } from "../../util/config";
import { FormButton, FormButtonsWrap } from "../";
/* Release 3.2 - 7.1 - Starts */
import exportFromJSON from "export-from-json";
import { apolloClient, apolloProxyClient } from "../../index";
import * as queries from "../../graphql";
import { Ring } from "react-awesome-spinners";
/* Release 3.2 - 7.1 - Ends */
const divStyle = {
  margin: "0.5%",
  width: "100%",
};
const divStyle1 = {
  margin: "0.5%",
  width: "100%",  
  /* Release 3.2 - 1.1 - Starts */
  maxHeight: "200px",
  overflowX: "auto",
  /* Release 3.2 - 1.1 - Ends */
};

export function UploadFile(props) {
  /* Release 3.2 - 1.1 - Starts */
  const outputResult = {
    Status: true,
    Message: [{ row: 0, message: "No File Chosen" }],
    scounter: 0,
    fcounter: 0,
  };
  /* Release 3.2 - 1.1 - Starts */
  const { reqType, pers, refresh, handleQuit } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const [filestatus, setstatus] = useState(
    /* Release 3.2 - 1.1 - Starts */ outputResult /* Release 3.2 - 1.1 - Ends */
  );
  const [filecolor, setcolor] = useState("black");
  const [tableQuery, setTableQuery] = useState("moc_failure");
  const [tableWhere, setTableWhere] = useState({});
  const [tableColumn, setTableColumn] = useState(columns.mocfailure);
  const [isStopped, setIsStopped] = useState(false);
  const [result, setResult] = useState("");
  const [tabkey, setKey] = useState(0);
  const close = (event) => {
    handleQuit();
  };
  /* Release 3.2 - 7.1 - Starts */
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleExportData = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    httpClient
      .query({
        query: queries[tableQuery],
        variables: {
          where: tableWhere,
          offset: 0,
        },
      })
      .then((response) => {
        let data = response.data[tableQuery].items.map(
          ({ upd_ins_by, upd_ins_ts, __typename, ...rest }) => rest
        );
        data = data.map((val) => {
          var temp = Object.assign({}, val);
          if (temp.hasOwnProperty("spread")) {
            if (temp.spread === null) {
              temp.spread = "";
            }
          }

          if (temp.hasOwnProperty("min")) {
            if (temp.min === null) {
              temp.min = "";
            }
          }
          if (temp.hasOwnProperty("max")) {
            if (temp.max === null) {
              temp.max = "";
            }
          }
          return temp;
        });
        if (data && data.length > 0) {
          data = data.map(function(item){
            return {
              "Nokia ID": item.for && item.for.nokia_id ? item.for.nokia_id : '',
              "Full Name": item.for && item.for.full_name ? item.for.full_name : '',
              "Request Type": item.request_type,
              "Status": item.import_status,
              "Reason for failure": item.import_err_msg,
            }
          });
          data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':""'));
          const fileName = tableQuery + "_" + new Date().toISOString();
          const exportType = "csv";
          exportFromJSON({ data, fileName, exportType });
        } else {
          alert("No Record Found to Export the Excel");
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
      });
  };
  /* Release 3.2 - 7.1 - Ends */
  return (
    <div style={{ width: "100%" }}>
      <div style={divStyle}>
        <ApolloProvider client={apollouploadClient}>
          <Mutation mutation={MOC_UPLOAD}>
            {(mocUpload) => (
              <input
                type="file"
                accept=".xlsx"
                style={{ width: "100%" }}
                required
                onChange={async ({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => {
                  /* Release 3.2 - 1.1 - Starts */
                  setstatus({
                    Status: true,
                    Message: [
                      {
                        row: 0,
                        message:
                          "File Selected. Upload inprogress. Please wait...",
                      },
                    ],
                    scounter: 0,
                    fcounter: 0,
                  });
                  /* Release 3.2 - 1.1 - Ends */
                  setcolor("#eae412");
                  if (validity.valid) {
                    // setTableWhere({ request_type: { eq: reqType.toString() }, import_status: { eq: "pending" }, requester_pers_no: { eq: pers.toString() }, module: "massoffcycle" });

                    const res = await mocUpload({
                      variables: { file, reqType },
                    });
                    setResult(res);
                    /* Release 3.2 - 1.1 - Starts */
                    setstatus({
                      Status: res.data.mocUpload.Status,
                      Message: res.data.mocUpload.Message,
                      scounter: res.data.mocUpload.scounter,
                      fcounter: res.data.mocUpload.fcounter,
                    });
                    /* Release 3.2 - 1.1 - Ends */
                    setcolor(res.data.mocUpload.Status ? "#1ccc07" : "#ea6626");
                    setKey(Math.random());
                    setTableWhere({
                      request_type: { eq: reqType.toString() },
                      import_status: { eq: "stopped" },
                      requester_pers_no: { eq: pers.toString() },
                      module: "massoffcycle",
                    });
                    setIsStopped(
                      res.data.mocUpload.scounter > 0 ||
                        res.data.mocUpload.fcounter > 0
                        ? true
                        : false
                    );
                    refresh();
                  }
                }}
              />
            )}
          </Mutation>
        </ApolloProvider>
      </div>
      <div style={divStyle1}>
        Current Status :{" "}
        <span style={{ color: filecolor }}>
          {" "}
          {/* Release 3.1 - Starts */}
          {filestatus &&
            filestatus.Message.map((item) => {
              return (
                <>
                  {item.row == 0 || (item.row == 1 && <br />)}
                  <span key={item.row} style={{ color: filecolor }}>
                    {item.row == 0
                      ? item.message
                      : "Row " + item.row + " : " + item.message}
                  </span>
                  <br />
                </>
              );
            })}
          {/* Release 3.1 - Ends */}{" "}
        </span>
      </div>
      <div style={{ color: "red", fontSize: "1rem !important" }}>
        Note:
        <br></br>
        <span>&#9888;</span> Please make sure not to change the column order and
        header name.
        <br></br>
        <span>&#9888;</span> Once you import, your previous import data will be
        cleared. So please make sure you review and submit the records before
        importing.
        <br></br>
        <span>&#9888;</span> Please make sure to upload an excel with maximum
        rows of 2000.
        <br></br>
        <span>&#9888;</span>The status of the records will be displayed below
        once the import is successfull.
      </div>
      {isStopped && (
        <>
          <div style={{ fontSize: "0.8rem" }}>
            <TableHeadingWrap
              style={{ marginBlockEnd: "1em" }}
            >
              <div
                style={{
                  width: "23%",
                  display: "flex",
                  justifyContent: "left",
                  gap: "10px",
                  alignItems: "baseline",
                }}
              >
                Mass Off-Cycle Import Status -
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "left",
                  gap: "10px",
                  alignItems: "baseline",
                }}
              >
                Success records - {result.data.mocUpload.scounter} Stopped
                records - {result.data.mocUpload.fcounter}
              </div>

              {/* Release 3.2 - 7.1 - Starts */}
              <TableActionsWrap>
                {isSubmitting && (
                  <LoaderWrap>
                    <Ring color={"#001235"} size="2" sizeUnit="rem" />
                  </LoaderWrap>
                )}
                {result.data.mocUpload.fcounter > 0 &&
                <TableAction
                  primary
                  disabled={isSubmitting}
                  onClick={handleExportData}
                >
                  Export To Excel
                </TableAction>}
              </TableActionsWrap>
              {/* Release 3.2 - 7.1 - Ends */}
            </TableHeadingWrap>
            <Table
              recordType="MOCIssueTable"
              columns={tableColumn}
              query={tableQuery}
              where={tableWhere}
              key={tabkey}
            />
          </div>
          <FormButtonsWrap>
            <FormButton onClick={close}>Continue</FormButton>
          </FormButtonsWrap>
        </>
      )}
    </div>
  );
  // const [uploadFileMutation] = useMutation(UPLOAD_FILE);
  //const apolloClient = useApolloClient();

  //const onChange = ({
  //  target: {
  //  validity,
  //   files: [file],
  //   },
  //  }) =>{
  //    console.log( process.env.REACT_APP_GRAPHQL_API);
  //     validity.valid &&
  //    Uploadclient.mutate({
  //mutation: UPLOAD_FILE,
  //variables: { file }
  //})
  //.then(resp => console.log('resp'))};
  // uploadFileMutation({ variables: { file } }).then(() => {
  //  apolloClient.resetStore();
  // });

  // return <input type="file" required onChange={onChange} />;
}
