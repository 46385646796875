import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faUsers,
  faUserSecret,
  faUnlock,
  faShare,
  faChartPie,
  faDatabase,
  faCalculator,
  /* Release 3.1 - Starts */
  faExchangeAlt,
  faStopCircle,
  /* Release 3.1 - Ends */
} from "@fortawesome/free-solid-svg-icons";

import {
  HeaderWrap,
  ProxyHeader,
  ProxySpan,
  LogoLink,
  LogoutLink,
  MainMenu,
  SubMenuItem,
  MainMenuItem,
  InActiveMainMenuLink,
  MainMenuLink,
  HeaderLeftWrap,
  SubMenuLink,
  SubMainMenu,
  SubMainMenuLink,
} from "../";

import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

const Header = (props) => {
  const {
    route,
    account,
    /* Release 3.1 - Starts */ isToolLock = false /* Release 3.1 - Ends */,
  } = props;
  /* DEM0058258 */
  const { roles, totalHRCRelationExists, finalHRCRelation } = account;

  /*  GET UNIQUE REGIONS THAT HRC BELONGS TO */
  const uniqueRegion = [
    ...new Set(
      finalHRCRelation.map((obj) => {
        return obj.region;
      })
    ),
  ];
  //console.log(uniqueRegion, "=====>>>>>>uniqueRegion<<<<<=======");
  //console.log(account, "=====>>>>>>ACCOUNT<<<<<=======");
  const isHR = roles.includes("hr");
  const isGct = roles.includes("gct");
  const isAdmin = roles.includes("admin");
  /**Release 2 add delegates for new roles rtap and gtap */
  const isGTAP = roles.includes("gtap");
  const isRTAP = roles.includes("rtap");
  /* DEM0058258 */
  const isHRC = roles.includes("hrc");

  const hasSomethingToApprove = true;

  // const employeesLinkLabel = isHR || isSuperManager ? 'Employees' : 'My employees';

  /* UPDATE THE CONDITION FOR DELEGATION */
  const isSuperManager = roles.includes("supermanager");
  const isBCB = roles.includes("bcb");
  const isHR1 = roles.includes("hr1");
  const isHR2 = roles.includes("hr2");
  /*  HR3_ROLE STARTS */
  const isHR3 = roles.includes("hr3");
  /*  HR3_ROLE ENDS */
  const isManager = roles.includes("manager");
  const isRcb = roles.includes("rcb");
  const isChr = roles.includes("chr");

 /* Release 3.2 - 10.4 - Starts */
  const isKeyUser = roles.includes("keyuser");
  const isOnlyKeyUser =
  !roles.includes("manager") &&
  !roles.includes("supermanager") &&
  !roles.includes("bcb") &&
  !roles.includes("gct") &&
  !roles.includes("rcb") &&
  !roles.includes("admin") &&
  !roles.includes("gtap") &&
  !roles.includes("rtap") &&
  roles.includes("keyuser") &&
  !roles.includes("hr1") &&
  !roles.includes("hr2") &&
  !roles.includes("hr3") &&
  !roles.includes("chr") &&
  !roles.includes('hrc') &&
  !roles.includes('bg_head') &&
  !roles.includes('bl2') &&
  !roles.includes('bl3') &&
  !roles.includes('rhr')&&
  !roles.includes('business_owner');
 /* Release 3.2 - 10.4 - Ends */
 /* Release 3.2 - 1.1 - Starts */
 const isBusinessOwner = roles.includes("business_owner");
 const isOnlyBusinessOwner =
  !roles.includes("manager") &&
  !roles.includes("supermanager") &&
  !roles.includes("bcb") &&
  !roles.includes("gct") &&
  !roles.includes("rcb") &&
  !roles.includes("admin") &&
  !roles.includes("gtap") &&
  !roles.includes("rtap") &&
  !roles.includes("keyuser") &&
  !roles.includes("hr1") &&
  !roles.includes("hr2") &&
  !roles.includes("hr3") &&
  !roles.includes("chr") &&
  !roles.includes('hrc') &&
  !roles.includes('bg_head') &&
  !roles.includes('bl2') &&
  !roles.includes('bl3') &&
  !roles.includes('rhr')&&
  roles.includes('business_owner');
 /* Release 3.2 - 1.1 - Ends */
 /* Release 3.2 - 10.1 - Starts */
 const isOnlyRcb =
  !roles.includes("manager") &&
  !roles.includes("supermanager") &&
  !roles.includes("bcb") &&
  !roles.includes("gct") &&
  roles.includes("rcb") &&
  !roles.includes("admin") &&
  !roles.includes("gtap") &&
  !roles.includes("rtap") &&
  !roles.includes("keyuser") &&
  !roles.includes("hr1") &&
  !roles.includes("hr2") &&
  !roles.includes("hr3") &&
  !roles.includes("chr") &&
  !roles.includes('hrc') &&
  !roles.includes('bg_head') &&
  !roles.includes('bl2') &&
  !roles.includes('bl3') &&
  !roles.includes('rhr')&&
  !roles.includes('business_owner');
  /* Release 3.2 - 10.1 - Starts */

  const adminLinksStyle = { 
    border: "0px solid",
    width: "100%",
    float: "left",
    margin: "0",
    padding: "0",
    height: "auto",
    color: "#000",
    textDecoration: "none",
    fontWeight: "normal",
    fontSize: "15px",
  };

  const iconStyle = {
    fontSize: "20px",
    marginTop: "0.3%",
    marginRight: "0.5%",
  };
  /* Start AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
  const revert = () => {
    sessionStorageClear();
    window.location.href = window.location.origin;
  };  
  const logoutClick = () => {
    sessionStorageClear();
    route.auth.logout();
  };
  const sessionStorageClear = () =>  {
    sessionStorage.removeItem("proxy_access");
    sessionStorage.removeItem("proxy_name");
    sessionStorage.removeItem("access_token_payload");
    sessionStorage.removeItem("access_token");
    sessionStorage.clear();
  }
  /* End AADSTS50196 error on office 356 log... - TrackingID#2208020050000912 */
  const liststyle = {
    marginLeft: "0.5%",
    fontSize: "20px",
    listStyle: "none",
    cursor: "pointer",
    color: "#0065ed",
  };

  /**Variable for menu style Fix - 24 March 2021 Starts */
  const linkStyle = {
    border: "0px solid",
    width: "100%",
    float: "left",
    margin: "0",
    padding: "0",
    height: "auto",
    color: "#000",
    textDecoration: "none",
    fontWeight: "normal",
    fontSize: "15px",
    whiteSpace: "nowrap",
  };
  /**Variable for menu style Fix - 24 March 2021 Ends */
  console.log("isAdminisAdmin======>", isAdmin);
  console.log("isKeyUserisKeyUser======>", isKeyUser);
  console.log("isHRCisHRC======>", isHRC); 
  return (
    <>
      {/* Release 3.1 - Starts */}
      {isToolLock && (
        <>
          <HeaderWrap {...props}>
            <HeaderLeftWrap>
              <LogoLink to="/">
                {/*DEM0068681 - Change NOKIA logo Starts  */}
                {/*<svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 800 134.84"
                  width="100"
                  height="20"
                  aria-label="Nokia logo"
                >
                  <path
                    fill="#124191"
                    d="M38.52 133.18H0V2.18H66.62L143.05 99.98V2.18H181.57V133.18H116.3L38.52 33.71V133.18M365.87 92.67C365.87 110.88 362.65 117.16 357.32 123.18C349.15 132.14 337.26 134.84 314.35 134.84L248.58 134.84C225.67 134.84 213.76 132.14 205.6 123.18C200.27 117.16 197.05 110.88 197.05 92.67L197.05 42.17C197.05 23.97 200.27 17.7 205.6 11.67C213.77 2.7 225.67 0 248.58 0L314.35 0C337.26 0 349.15 2.7 357.32 11.67C362.65 17.7 365.87 23.97 365.87 42.17L365.87 92.67M312.73 101.65C321.53 101.65 324.63 101.13 326.74 99.21C328.71 97.42 329.58 95.11 329.58 87.41L329.58 47.43C329.58 39.73 328.71 37.44 326.74 35.64C324.63 33.71 321.53 33.2 312.73 33.2L250.19 33.2C241.39 33.2 238.29 33.71 236.19 35.64C234.21 37.44 233.34 39.73 233.34 47.43L233.34 87.41C233.34 95.11 234.21 97.42 236.19 99.21C238.29 101.13 241.39 101.65 250.19 101.65L312.73 101.65V101.65M421.85 2.18V133.18H381.6V2.18H421.85M489.85 2.18H542.99L471.27 63.45 552.16 133.18H495.3L421.85 64.86 489.85 2.18M559.58 2.18H599.84V133.18H559.58M753.31 133.18 741.4 110.36H663.51L651.74 133.17H607.27L678.62 2.18H728.66L800 133.18H753.31M677.5 81.91H727.55L702.52 34.48 677.5 81.91"
                  ></path>
      </svg>*/}
      <img src="./logo.svg" style={{ width: "75%" }} />
      {/*DEM0068681 - Change NOKIA logo Ends  */}
              </LogoLink>
            </HeaderLeftWrap>
            <MainMenu>
              {account && (
                <MainMenuItem style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Hi {account.name} &nbsp;
                </MainMenuItem>
              )}
              <Menu
                menuButton={
                  <img
                    src="./lines.png"
                    style={{ cursor: "pointer", height: "20%" }}
                  />
                }
              >
                <MenuItem onClick={logoutClick}>
                  <FontAwesomeIcon
                    icon={faUnlock}
                    style={{ marginBottom: "5.5%", marginRight: "3%" }}
                  />
                  <div style={linkStyle}>Logout</div>
                </MenuItem>
              </Menu>
            </MainMenu>
          </HeaderWrap>
        </>
      )}
      {/* Release 3.1 - Ends */}
      {/* Release 3.1 - Starts */}
      {!isToolLock && (
        <>
          {
            // Release 2 - Admin Proxy header notification with option to revert back
          }
          {sessionStorage.getItem("proxy_access") !== null && (
            <ProxyHeader>
              {" "}
              <FontAwesomeIcon icon={faUserSecret} style={iconStyle} />{" "}
              <ProxySpan>
                {" "}
                You are currently in Proxy Mode for the user -{" "}
                {sessionStorage.getItem("proxy_name")}{" "}
              </ProxySpan>{" "}
              <li style={liststyle} onClick={revert}>
                {" "}
                - Revert To Self{" "}
              </li>{" "}
            </ProxyHeader>
          )}

          <HeaderWrap {...props}>
            <HeaderLeftWrap>
              <LogoLink to="/">
                {/*DEM0068681 - Change NOKIA logo Starts  */}
                {/*<svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 800 134.84"
                  width="100"
                  height="20"
                  aria-label="Nokia logo"
                >
                  <path
                    fill="#124191"
                    d="M38.52 133.18H0V2.18H66.62L143.05 99.98V2.18H181.57V133.18H116.3L38.52 33.71V133.18M365.87 92.67C365.87 110.88 362.65 117.16 357.32 123.18C349.15 132.14 337.26 134.84 314.35 134.84L248.58 134.84C225.67 134.84 213.76 132.14 205.6 123.18C200.27 117.16 197.05 110.88 197.05 92.67L197.05 42.17C197.05 23.97 200.27 17.7 205.6 11.67C213.77 2.7 225.67 0 248.58 0L314.35 0C337.26 0 349.15 2.7 357.32 11.67C362.65 17.7 365.87 23.97 365.87 42.17L365.87 92.67M312.73 101.65C321.53 101.65 324.63 101.13 326.74 99.21C328.71 97.42 329.58 95.11 329.58 87.41L329.58 47.43C329.58 39.73 328.71 37.44 326.74 35.64C324.63 33.71 321.53 33.2 312.73 33.2L250.19 33.2C241.39 33.2 238.29 33.71 236.19 35.64C234.21 37.44 233.34 39.73 233.34 47.43L233.34 87.41C233.34 95.11 234.21 97.42 236.19 99.21C238.29 101.13 241.39 101.65 250.19 101.65L312.73 101.65V101.65M421.85 2.18V133.18H381.6V2.18H421.85M489.85 2.18H542.99L471.27 63.45 552.16 133.18H495.3L421.85 64.86 489.85 2.18M559.58 2.18H599.84V133.18H559.58M753.31 133.18 741.4 110.36H663.51L651.74 133.17H607.27L678.62 2.18H728.66L800 133.18H753.31M677.5 81.91H727.55L702.52 34.48 677.5 81.91"
                  ></path>
                </svg>*/}
                <img src="./logo.svg" style={{ width: "75%" }} />
                {/*DEM0068681 - Change NOKIA logo Starts  */}
              </LogoLink>
              <MainMenu>
              {/* Release 3.2 - 1.1 - Starts */ !isOnlyBusinessOwner && /* Release 3.2 - 1.1 - Ends */ (
                <MainMenuItem>
                  <MainMenuLink to="/employees">My Employees</MainMenuLink>
                </MainMenuItem>
                )}

                {!isHR && 
                /* Release 3.2 - 10.1 - Starts */
                !isOnlyRcb &&
                /* Release 3.2 - 10.1 - Ends */
                (
                  <MainMenuItem>
                    <MainMenuLink to="/my-requests">My Requests</MainMenuLink>
                  </MainMenuItem>
                )}          
                      
                {/* Release 3.2 - 1.1 - Starts */ !isOnlyBusinessOwner && /* Release 3.2 - 1.1 - Ends */  (isHR || isGct || isAdmin 
                 /* Release 3.2 - 10.3 - Starts */
                 || isBCB
                 /* Release 3.2 - 10.3 - Ends */
                 /* Release 3.2 - 10.1 - Starts */
                 || isRcb 
                 /* Release 3.2 - 10.1 - Ends */
                 /* Release 3.2 - 10.4 - Starts */
                 || isKeyUser
                /* Release 3.2 - 10.4 - Ends */
                 ) && (
                  <MainMenuItem>
                    <MainMenuLink to="/requests-history">
                      All Requests
                    </MainMenuLink>
                  </MainMenuItem>
                )}
                {/* Release 3.2 - 1.1 - Starts */  !isOnlyBusinessOwner && /* Release 3.2 - 1.1 - Ends */ hasSomethingToApprove && 
                (
                  <MainMenuItem>
                    <MainMenuLink to="/pending-requests">
                      Requests to Approve/Reject
                    </MainMenuLink>
                  </MainMenuItem>
                )}
                {/* Release 3.2 - 1.1 - Starts */ !isOnlyBusinessOwner && /* Release 3.2 - 1.1 - Ends */ hasSomethingToApprove 
                && (
                  <MainMenuItem>
                    <MainMenuLink to="/approved-requests">
                      Approved Requests
                    </MainMenuLink>
                  </MainMenuItem>
                )}
                {/* DEM0058258 */}
                {
                  /*totalHRCRelationExists > 0 && finalHRCRelation.length > 0 && */
                  isHRC 
                  /* Release 3.2 - 10.4 - Starts */
                 && !isKeyUser
                 /* Release 3.2 - 10.4 - Ends */                  
                  && (
                    <MainMenuItem>
                      <MainMenuLink to="/all-requests-hrc">
                        All Requests
                      </MainMenuLink>
                    </MainMenuItem>
                  )
                }
                {/* DEM0073149 - Reoprts Access for HRC and KeyUser Starts */}
                {/* Release 3.1 - Starts */}
                {(isAdmin || isKeyUser ||  isHRC) 
                /* Release 3.2 - 10.4 - Ends */
                && (
                  <MainMenuItem>
                    <MainMenuLink to="/reports">Reports</MainMenuLink>
                  </MainMenuItem>
                )}
                {/* Release 3.1 - Ends */}
                {/* DEM0073149 - Reoprts Access for HRC and KeyUser Ends */}

                {/* Release 2 ADMIN LINKS WERE PLACED IN TOP NAVIGATION NOW CHANGED AS PER BUSINESS*/}
                {false && (
                  <SubMenuItem>
                    <InActiveMainMenuLink to="/#">
                      Admin Links{" "}
                    </InActiveMainMenuLink>
                    <SubMainMenu>
                      <SubMenuLink>
                        <SubMainMenuLink to="/admin-proxy">
                          Admin Proxy
                        </SubMainMenuLink>
                      </SubMenuLink>

                      <SubMenuLink>
                        <SubMainMenuLink to="/admin-calculation">
                          Admin Calculation
                        </SubMainMenuLink>
                      </SubMenuLink>
                    </SubMainMenu>
                  </SubMenuItem>
                )}
                {/* UPDATE THE CONDITION FOR DELEGATION */}
                {/*(isGct || isSuperManager || isBCB || isHR1 || isHR2) && (
            <MainMenuItem>
              <MainMenuLink to="/my-delegates">My Delegates</MainMenuLink>
            </MainMenuItem>
          )*/}
              </MainMenu>
            </HeaderLeftWrap>
            {/* UPDATE THE MENU FOR DELEGATION */}
            <MainMenu>
              {account && (
                <MainMenuItem style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Hi {account.name} &nbsp;
                </MainMenuItem>
              )}
              {/*  HR3_ROLE */}
              {/* DEM0058258 */}
              {account &&
                (isGct ||
                  isSuperManager ||
                  isBCB ||
                  isHR1 ||
                  isHR2 ||
                  isHR3 ||
                  isAdmin ||
                  isGTAP ||
                  isRTAP ||
                  isManager ||
                  isChr ||
                  isRcb ||
                  isHRC ||
                  /* Release 3.2 - 10.4 - Starts */
                  isKeyUser ||
                  /* Release 3.2 - 10.4 - Starts */
                  /* Release 3.2 - 1.1 - Starts */
                  isBusinessOwner
                  /* Release 3.2 - 1.1 - Starts */
                  ) && (
                  <Menu
                    menuButton={
                      <img
                        src="./lines.png"
                        style={{ cursor: "pointer", height: "20%" }}
                      />
                    }
                  >
                    {/* allowed my deligation for RTAP and GTAP users */}
                    {account && !isAdmin && 
                    /* Release 3.2 - 10.4 - Starts */
                    !isOnlyKeyUser &&
                    /* Release 3.2 - 10.4 - Ends */
                    /* Release 3.2 - 10.1 - Starts */
                    !isOnlyRcb &&
                    /* Release 3.2 - 10.1 - Ends */                    
                    /* Release 3.2 - 1.1 - Starts */
                    !isOnlyBusinessOwner &&
                    /* Release 3.2 - 1.1 - Starts */
                    (
                      <MenuItem>
                        <FontAwesomeIcon
                          icon={faShare}
                          style={{
                            marginBottom: "5.5%",
                            marginRight: "3%",
                          }}
                        />
                        <MainMenuLink style={linkStyle} to="/my-delegates">
                          My Delegates
                        </MainMenuLink>
                      </MenuItem>
                    )}

                    {/*  Added for admin calculations R2 */}
                    {account && isAdmin && (
                      <MenuItem>
                        <FontAwesomeIcon
                          icon={faCalculator}
                          style={{
                            marginBottom: "5.5%",
                            marginRight: "3%",
                          }}
                        />
                        <MainMenuLink
                          style={adminLinksStyle}
                          to="/admin-calculator"
                        >
                          Calculations
                        </MainMenuLink>
                      </MenuItem>
                    )}
                    {/* Release 2 ADMIN proxy LINK - starts*/}
                    {account && isAdmin && (
                      <MenuItem>
                        <FontAwesomeIcon
                          icon={faUsers}
                          style={{
                            marginBottom: "5.5%",

                            marginRight: "3%",
                          }}
                        />
                        <MainMenuLink style={linkStyle} to="/admin-proxy">
                          Proxy
                        </MainMenuLink>
                      </MenuItem>
                    )}
                    {/* Release 2 ADMIN proxy LINK - Ends*/}
                    {/*  HR3_ROLE */}
                    {account &&
                      (isBCB ||
                        isHR1 ||
                        isHR2 ||
                        isHR3 ||
                        isManager ||
                        isSuperManager ||
                        isChr ||
                        /* Release 3.2 - 1.1 - Starts */
                        isBusinessOwner 
                        /* Release 3.2 - 1.1 - Ends */
                        ) && (
                        <MenuItem>
                          <FontAwesomeIcon
                            icon={faUsers}
                            style={{ marginBottom: "5.5%", marginRight: "3%" }}
                          />
                          <MainMenuLink style={linkStyle} to="/mass-off-cycle">
                            Mass Requests
                          </MainMenuLink>
                        </MenuItem>
                      )}
                    {/* Release 2 ADMIN Table LINK - starts*/}
                    {(account && isAdmin)
                    && (
                      <MenuItem>
                        <FontAwesomeIcon
                          icon={faDatabase}
                          style={{
                            marginBottom: "5.5%",
                            marginRight: "3%",
                          }}
                        />
                        <MainMenuLink style={linkStyle} to="/admin-tables">
                          Admin Tables
                        </MainMenuLink>
                      </MenuItem>
                    )}
                    {/* Release 2 ADMIN Table LINK - Ends*/}

                    {/* Release 2 BM LINK - starts*/}

                    {account && (isAdmin || isGct || isBCB) && (
                      <MenuItem>
                        <FontAwesomeIcon
                          icon={faChartPie}
                          style={{
                            marginBottom: "5.5%",

                            marginRight: "3%",
                          }}
                        />
                        <MainMenuLink style={linkStyle} to="/budget-manage">
                          Offcycle Budget Management
                        </MainMenuLink>
                      </MenuItem>
                    )}
                    {/* Release 2 BM LINK - Ends*/}

                    {/* Release 3.1 - Starts */}

                    {account && isAdmin && (
                      <MenuItem>
                        <FontAwesomeIcon
                          icon={faExchangeAlt}
                          style={{
                            marginBottom: "5.5%",
                            marginRight: "3%",
                          }}
                        />
                        <MainMenuLink style={linkStyle} to="/change-approver">
                          Change Approver
                        </MainMenuLink>
                      </MenuItem>
                    )}
                    {/* Release 3.2 - 8.1 - Starts */}
                    { (isGTAP ||
                      isRTAP) && (
                      <MenuItem>
                        <FontAwesomeIcon
                        icon={faUsers}
                        style={{ marginBottom: "5.5%", marginRight: "3%" }}
                        />
                        <MainMenuLink style={linkStyle} to="/employee-history-dashboard">
                        Employee History Dashboard
                        </MainMenuLink>
                      </MenuItem>
                    )}
                    {/* Release 3.2 - 8.1 - Ends */}

                    {account && isAdmin && (
                      <MenuItem>
                        <FontAwesomeIcon
                          icon={faStopCircle}
                          style={{
                            marginBottom: "5.5%",
                            marginRight: "3%",
                          }}
                        />
                        <MainMenuLink style={linkStyle} to="/lockdown-tool">
                          Lockdown Tool
                        </MainMenuLink>
                      </MenuItem>
                    )}
                    {/* Release 3.1 - Ends */}

                    {/* Release 3.2 - 1.1 - Starts */}
                    {account && (isAdmin || isGct || isBCB) && (
                      <MenuItem>
                        <FontAwesomeIcon
                          icon={faChartPie}
                          style={{
                            marginBottom: "5.5%",
                            marginRight: "3%",
                          }}
                        />
                        <MainMenuLink style={linkStyle} to="/bea-budget-manage">
                          BEA Budget Management
                        </MainMenuLink>
                      </MenuItem>
                    )}
                    {/* Release 3.2 - 1.1 - Ends */}

                    <MenuItem onClick={logoutClick}>
                      <FontAwesomeIcon
                        icon={faUnlock}
                        style={{
                          marginBottom: "5.5%",

                          marginRight: "3%",
                        }}
                      />
                      {/*  Log Out link fix - 24 Mar 2021 */}
                      {/* < MainMenuLink style={linkStyle} to="/#">Logout</ MainMenuLink> </MenuItem>*/}
                      <div style={linkStyle}>Logout</div>
                    </MenuItem>

                    {/**Logout</MenuItem> */}
                  </Menu>
                )}
              {/*  HR3_ROLE */}
              {/* DEM0058258 */}
              {account &&
                !isGct &&
                !isSuperManager &&
                !isBCB &&
                !isHR1 &&
                !isHR2 &&
                !isHR3 &&
                !isAdmin &&
                !isGTAP &&
                !isRTAP &&
                !isManager &&
                !isChr &&
                !isRcb &&
                !isHRC &&
                /* Release 3.2 - 10.4 - Starts */
                !isKeyUser &&
                /* Release 3.2 - 10.4 - Ends */
                /* Release 3.2 - 1.1 - Starts */
                !isBusinessOwner &&
                /* Release 3.2 - 1.1 - Starts */
                (
                  <Menu
                    menuButton={
                      <img
                        src="./lines.png"
                        style={{ cursor: "pointer", height: "20%" }}
                      />
                    }
                  >
                    <MenuItem onClick={logoutClick}>
                      <FontAwesomeIcon
                        icon={faUnlock}
                        style={{ marginBottom: "5.5%", marginRight: "3%" }}
                      />
                      {/*  Log Out link fix - 24 Mar 2021 */}
                      {/* < MainMenuLink style={linkStyle} to="/#">Logout</ MainMenuLink> </MenuItem>*/}
                      <div style={linkStyle}>Logout</div>
                    </MenuItem>
                    {/**Logout</MenuItem> */}
                  </Menu>
                )}
              {/*
        
        {account && <MainMenuItem>Hi {account.name}</MainMenuItem>}
        <LogoutLink onClick={logoutClick}>Logout</LogoutLink>*/}
            </MainMenu>
          </HeaderWrap>
        </>
      )}
      {/* Release 3.1 - Ends */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps)(Header);
