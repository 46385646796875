/* Release 3.2 - 9.1Req - Starts */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { connect } from "react-redux";
import { Ring } from "react-awesome-spinners";
import {
  unselectAllRecord,
  budgetSetUpdated,
  pushMessage,
} from "../../actions";
import {
  DatePickerField,
  SelectedTable,
  CalculatorNew,
  FormButton,
  FormButtonsWrap,
  FormLabel,
  FormLabelInline,
  FormHeading,
  DescriptionInput,
  LoaderWrap,
  ErrorsBlock,
  IconWrap,
  SimulationTable,
} from "../";
import { columns } from "../../util/config";
import {
  CREATE_REQUEST_WITH_BUDGET,
  CREATE_REQUEST_SIMULATION,
  SIMULATIONS,
  BUDGET,
  requests,
  employees,
  DELETESIMULATIONS,
} from "../../graphql";
import { convertToUTC } from "../../util/helper";
import { apolloClient, apolloProxyClient } from "../../index";
import "react-datepicker/dist/react-datepicker.css";
import effective_date from "../../util/fields/table/effective_date";

const InternalHireSimulations = (props) => {
  const {
    selectedEmployees,
    account,
    hideModal,
    unselectAllRecord,
    budgetSetUpdated,
    pushMessage,
  } = props;  
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  /* Release 3.1 - Starts */
  const { roles } = account;
  const isGTap = roles.includes("gtap");
  const isRTap = roles.includes("rtap");
  const isTap = isGTap || isRTap;
  const isBcb = roles.includes("bcb");
  /* Release 3.1 - Ends */
   /* Release 3.2 - 9.1 - Starts */
   const isHRBP =
   roles.includes("hr1") ||
   roles.includes("hr2") ||
   roles.includes("hr3");
 /* Release 3.2 - 9.1 - Ends */
  const [updatedItem, setUpdatedItem] = useState("");
  const [updateData, setUpdateData] = useState({});
  const [requestDescription, setRequestDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [selectedEmployeeDetail, setSelectedEmployeeDetail] =
    useState(selectedEmployees);
  const now = new Date();
  const nextMonth =
    now.getMonth() === 11
      ? new Date(now.getFullYear() + 1, 0, 1)
      : new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const [effectiveDate, setEffectiveDate] = useState(nextMonth);
  const [simulationData, setSimulationData] = useState([]);
  const [simulationEntry, setSimulationEntry] = useState(true);

  const [createRequest] = useMutation(CREATE_REQUEST_WITH_BUDGET);
  const [createSimulationRequest] = useMutation(CREATE_REQUEST_SIMULATION);
  const [deleteSimulations] = useMutation(DELETESIMULATIONS);
  const [updatedKey, setUpdatedKey] = useState(1);
  const [defaultValue, setDefaultValue] = useState(selectedEmployees);
  const update = (item, data) => {
    if (updatedItem !== item) {
      setUpdatedItem(item);
    }
    setUpdateData(data);
  };
  const getSimulationsInfo = async () => {
    setIsSubmitting(true);
    return await new Promise((resolve, reject) => {
      httpClient
        .query({
          query: SIMULATIONS,
          variables: {
            where: {
              requester_pers_no: { eq: account.pers_no },
              request_type: {
                eq: selectedEmployees[0] && selectedEmployees[0].request_type
                  ? selectedEmployees[0].request_type
                  : "internalhire",
              },
              requested_pers_no: {
                eq: selectedEmployees[0] ? selectedEmployees[0].pers_no : null,
              },
              status: { eq: "active" },
            },
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          if (
            response.data &&
            response.data.requestSimulationList &&
            response.data.requestSimulationList.items.length > 0
          ) {
            resolve(response.data.requestSimulationList.items);
          } else {
            resolve([]);
          }
          setIsSubmitting(false);
        })
        .catch((err) => {
          console.error(err);
          setIsSubmitting(false);
          // setMessages(["Internal Server Error!"]);
          //setMessageType("error");
        });
    }).catch((err) => {
      console.error(err);
      setIsSubmitting(false);
      //setMessages(["Internal Server Error!"]);
      //setMessageType("error");
    });
  };
  useEffect(() => {
    getSimulationsInfo().then((simulationsInfo) => {
      var resultSimulation = simulationsInfo.map(function (obj) {
        let result_data = JSON.parse(obj.requested_data);
        result_data.employee_request_simulation_id =
          obj.employee_request_simulation_id;
        return result_data;
      });
      setSimulationData(resultSimulation);
    });
  }, [updatedKey]);
  const getEmployeeInfo = async (pers_id) => {
    return await new Promise((resolve, reject) => {
      httpClient
        .query({
          query: employees,
          variables: {
            where: {
              pers_no: { eq: pers_id },
            },
          },
        })
        .then((response) => {
          if (
            response.data &&
            response.data.employees &&
            response.data.employees.items.length > 0
          ) {
            resolve(response.data.employees.items[0]);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          console.error(err);
          // setMessages(["Internal Server Error!"]);
          //setMessageType("error");
        });
    }).catch((err) => {
      console.error(err);
      //setMessages(["Internal Server Error!"]);
      //setMessageType("error");
    });
  };

  const handleSimulationSelect = (event, item, indexColumn) => {
    let index_check = selectedEmployeeDetail.findIndex(
      (d) =>
        d.employee_request_simulation_id == item.employee_request_simulation_id
    );
    if (event.target.checked && index_check < 0) {
      setSelectedEmployeeDetail([item]);
      setSimulationEntry(false);
    } else {
      setSelectedEmployeeDetail(selectedEmployees);
      setSimulationEntry(true);
    }
  };
  function isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  function isNegative(n) {
    return ((n = +n) || 1 / n) < 0;
  }
  function UpdateN() {
    let ttc_increase_amount = document.querySelectorAll(
      'input[name="ttc_increase_amount"]'
    );
    let ttc_increase_percentage = document.querySelectorAll(
      'input[name="ttc_increase_percentage"]'
    );
    /**Release 2 Our Talent - scenarios change starts */
    let new_fte_annual_salary = document.querySelectorAll(
      'input[name="new_fte_annual_salary"]'
    );
    let new_fte_incentive_target_amount = document.querySelectorAll(
      'input[name="new_fte_incentive_target_amount"]'
    );

    /**Release 2 Our Talent - scenarios change Ends */

    ttc_increase_amount.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    ttc_increase_percentage.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });

    /**Release 2 Our Talent - scenarios change starts */
    new_fte_annual_salary.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    new_fte_incentive_target_amount.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    /**Release 2 Our Talent - Scenarios Change Ends */
  }

  const handleChange = (args) => (event) => {
    const { column, columns, item } = args;

    const indexColumn = columns.find((c) => c.index);
    let value = "";
    if (column.inputType !== "peoplepicker") {
      value =
        column.inputType === "checkbox"
          ? event.target.checked
          : event.target.value;
    } else {
      value = event.value;
    }

    value =
      (column.inputType !== "checkbox" && parseInt(value)) < 0 ? 0 : value;
    update(
      item[indexColumn.name],
      {
        request_type: "internalhire",
        [column.name]: column.type === "float" ? parseFloat(value) : value,
      },
      event
    );
  };

  const handleDescriptionChange = (event) => {
    setRequestDescription(event.target.value);
    setTimeout(() => {
      UpdateN();
    }, 100);
  };
  const handleEffectiveDateChange = (date) => {
    setEffectiveDate(date);
    setTimeout(() => {
      UpdateN();
    }, 100);
  };

  const handleQuit = (event) => {
    hideModal();
    unselectAllRecord("employees");
  };

  const hasBudgetVisibility =
    account.accessibles && account.accessibles.budgetOrgs.length > 0;

  const handleRequestCompensationSend = async (event) => {
    setTimeout(() => {
      UpdateN();
    }, 100);

    setIsSubmitting(true);
    setSubmitErrors([]);

    if (!requestDescription) {
      setSubmitErrors(["Reason is required!"]);
      setIsSubmitting(false);
      return null;
    }

    let refetchAfterCreateRequest = [
      {
        query: requests,
        variables: {
          where: { requester_pers_no: { eq: account.pers_no } },
          limit: 20,
          offset: 0,
          orderBy: [{ column: "created_ts", order: "DESC" }],
        },
      },
    ];
    if (hasBudgetVisibility) {
      account.accessibles.budgetOrgs.forEach((b) =>
        refetchAfterCreateRequest.push({
          query: BUDGET,
          variables: { organization_unit: b },
        })
      );
    }

    /*  ADD NEW FIELD TO FIX DEFECT 9 STARTS */
    let lastInsertedID = 0;
    let lastInsertedStatus = "";
    /*  ADD NEW FIELD TO FIX DEFECT 9 STARTS */
    const results = await Promise.all(
      selectedEmployeeDetail.map(
        async ({
          pers_no,
          nokia_id,
          country_name,
          organization_unit_1,
          organization_unit_2,
          organization_unit_3,
          organization_unit_4,
          is_gpp_eligible,
          gpp_increase_amount,
          gpp_increase_percentage,
          ttc_increase_amount,
          ttc_increase_percentage,
          fte_ttc_annual_salary,
          promoted,
          demoted,
          promotion_job_grade_delta,
          promotion_job_family_name,
          promotion_job_subfamily_name,
          promotion_job_name,
          promotion_job_grade,
          promotion_pay_grade,
          promotion_incentive_target_percentage,
          mandatory_ttc_increase_percentage,
          mandatory_ttc_increase_amount,
          apply_ti_harmonization,
          interim_job_family_name,
          interim_job_grade,
          interim_fte_incentive_target_amount,
          interim_incentive_target_percentage,
          interim_total_increase_amount,
          interim_increase_amount_for_salincr,
          interim_increase_amount_for_tih,
          interim_salary_range_min,
          interim_salary_range_max,
          step_1_abs,
          step_1_ita,
          step_1_itp,
          interim_theo_itp,
          interim_itp_situation,
          step_2_abs_gap,
          step_2_ita_gap,
          interim_remainder_for_split,
          new_fte_annual_salary,
          new_fte_incentive_target_amount,
          new_incentive_target_percentage,
          new_itp_situation,
          new_fte_ttc_annual_salary,
          new_quartile,
          new_incentive_plan_type,
          new_position_title,
          request_type,
          currency,
          offcycle_budget_update,
          new_ttc_compa_ratio,
          target_budget_org,
          organization_unit_2_name,
          organization_unit_1_name,
          ttc_increase_perc,
          current_combo_situation,
          promo_increase_pct_guideline_min,
          promo_increase_pct_guideline_max,
          promotional_increase_percentage_guideline,
          rr_number,
          case_3_12_month_actual_incentive_amount_fte,
          override_transition_allowance_local_currency,
          // new_incentive_scheme,
          internalhire_off_cycle_spending,
          internal_hire_off_cycle_spending_euro,
          is_sip,
          Case_One_Max_Budget_calc,
          Case_one_TTC_increase_minus_Max_budget,
          Case_one_Budget_charge,
          Case_two_TI_Increase,
          Case_two_Promotion_Budget,
          Case_two_Total_budget,
          Case_two_Budget_charge,
          Case_three_twelve_month_Total_Target_Cash,
          Case_three_new_TTC_Gap_to_twelve_month_TTC,
          Case_three_Max_capped_transit_allowance,
          Case_three_Transition_allowance_total,
          Case_three_Budget_charge,
          sip_case,
          Final_Off_Cycle_Budget,
          hiring_manager,
          employee_request_simulation_id,
          effective_date,
          request_country,
        }) => {
          try {
            const results_Inner = await createRequest({
              variables: {
                data: {
                  effective_date: convertToUTC(
                    effectiveDate ? effectiveDate : effective_date
                  ),
                  request_description: requestDescription,
                  requester_pers_no: account.pers_no,
                  request_country: country_name
                    ? country_name
                    : request_country,
                  request_type,
                  pers_no,
                  is_gpp_eligible,
                  gpp_increase_amount,
                  gpp_increase_percentage,
                  ttc_increase_amount,
                  ttc_increase_percentage,
                  fte_ttc_annual_salary,
                  promoted,
                  demoted,
                  promotion_job_grade_delta,
                  promotion_job_family_name,
                  promotion_job_subfamily_name,
                  promotion_job_name,
                  promotion_job_grade,
                  promotion_pay_grade,
                  promotion_incentive_target_percentage,
                  mandatory_ttc_increase_percentage,
                  mandatory_ttc_increase_amount,
                  apply_ti_harmonization,
                  interim_job_family_name,
                  interim_job_grade,
                  interim_fte_incentive_target_amount,
                  interim_incentive_target_percentage,
                  interim_total_increase_amount,
                  interim_increase_amount_for_salincr,
                  interim_increase_amount_for_tih,
                  interim_salary_range_min,
                  interim_salary_range_max,
                  step_1_abs,
                  step_1_ita,
                  step_1_itp,
                  interim_theo_itp,
                  interim_itp_situation,
                  step_2_abs_gap,
                  step_2_ita_gap,
                  interim_remainder_for_split,
                  new_fte_annual_salary,
                  new_fte_incentive_target_amount,
                  new_incentive_target_percentage,
                  new_itp_situation,
                  new_fte_ttc_annual_salary,
                  new_quartile,
                  new_incentive_plan_type,
                  new_position_title,
                  currency,
                  offcycle_budget_update: Final_Off_Cycle_Budget,
                  new_ttc_compa_ratio,
                  target_budget_org,
                  organization_unit_2_name,
                  organization_unit_1_name,
                  ttc_increase_perc /*  ADD FIELD THAT USED FOR 20% MERIT INCRESE */,
                  current_combo_situation,
                  promo_increase_pct_guideline_min,
                  promo_increase_pct_guideline_max,
                  promotional_increase_percentage_guideline,
                  rr_number,
                  case_3_12_month_actual_incentive_amount_fte,
                  override_transition_allowance_local_currency,
                  // new_incentive_scheme,
                  internalhire_off_cycle_spending,
                  internal_hire_off_cycle_spending_euro,
                  is_sip,
                  Case_One_Max_Budget_calc,
                  Case_one_TTC_increase_minus_Max_budget,
                  Case_one_Budget_charge,
                  Case_two_TI_Increase,
                  Case_two_Promotion_Budget,
                  Case_two_Total_budget,
                  Case_two_Budget_charge,
                  Case_three_twelve_month_Total_Target_Cash,
                  Case_three_new_TTC_Gap_to_twelve_month_TTC,
                  Case_three_Max_capped_transit_allowance,
                  Case_three_Transition_allowance_total,
                  Case_three_Budget_charge,
                  sip_case,

                  Final_Off_Cycle_Budget,
                  hiring_manager,
                  employee_request_simulation_id: parseInt(
                    employee_request_simulation_id
                  ),
                },
              },
              awaitRefetchQueries: true,
              refetchQueries: refetchAfterCreateRequest,
            });

            /*  FIL THE VALUES IN NEW FIELDS TO FIX DEFECT 9 STARTS */
            lastInsertedID =
              results_Inner.data.createRequest.employee_request_id;
            lastInsertedStatus = results_Inner.data.createRequest.status;
            /*  FIL THE VALUES IN NEW FIELDS TO FIX DEFECT 9 ENDS */
            return { [pers_no]: false };
          } catch (e) {
            const err_request_exist = e.graphQLErrors.some(
              (er) => er.extensions.code === "EXISTING_REQUEST"
            );
            return {
              [pers_no]: err_request_exist
                ? `There is already a pending request for employee ${nokia_id}`
                : e.message,
            };
          }
        }
      )
    );

    const errors = results.filter((r) =>
      Object.keys(r).find((p_no) => r[p_no])
    );
    if (!errors.length) {
      /*  SET THE MESSAGE CONDITION TO FIX DEFECT 9 STARTS */
      let finalMessage = "";
      let finalMessageType = "";
      if (lastInsertedStatus == "stopped") {
        finalMessageType = "failure";
        finalMessage = "Please review the request with HRBP!";
      } else {
        finalMessageType = "success";
        finalMessage = "Request(s) successfully sent!";
      }
      /*  SET THE MESSAGE CONDITION TO FIX DEFECT 9 ENDS */

      hideModal();
      unselectAllRecord("employees");
      budgetSetUpdated(true);
      pushMessage({
        //type: 'success',
        //message: 'Request(s) successfully sent!'
        type: finalMessageType,
        message: finalMessage /*  ADD THE MESSAGE VARIABLE TO FIX DEFECT 9 */,
      });
    } else {
      /* Addd below code for resolution for defect #16 and #17--START */
      setTimeout(() => {
        UpdateN();
      }, 100);

      /* code for resolution for defect #16 and #17--END */

      setSubmitErrors(errors.map((e) => Object.values(e).shift()));
      setIsSubmitting(false);
    }
  };

  const handleRequestSimulation = async (event) => {
    setTimeout(() => {
      UpdateN();
    }, 100);

    setIsSubmitting(true);
    setSubmitErrors([]);

    const results = await Promise.all(
      selectedEmployees.map(
        async ({
          pers_no,
          nokia_id,
          full_name,
          country_name,
          organization_unit_1,
          organization_unit_2,
          organization_unit_3,
          organization_unit_4,
          is_gpp_eligible,
          gpp_increase_amount,
          gpp_increase_percentage,
          ttc_increase_amount,
          ttc_increase_percentage,
          fte_ttc_annual_salary,
          promoted,
          demoted,
          promotion_job_grade_delta,
          promotion_job_family_name,
          promotion_job_subfamily_name,
          promotion_job_name,
          promotion_job_grade,
          promotion_pay_grade,
          promotion_incentive_target_percentage,
          mandatory_ttc_increase_percentage,
          mandatory_ttc_increase_amount,
          apply_ti_harmonization,
          interim_job_family_name,
          interim_job_grade,
          interim_fte_incentive_target_amount,
          interim_incentive_target_percentage,
          interim_total_increase_amount,
          interim_increase_amount_for_salincr,
          interim_increase_amount_for_tih,
          interim_salary_range_min,
          interim_salary_range_max,
          step_1_abs,
          step_1_ita,
          step_1_itp,
          interim_theo_itp,
          interim_itp_situation,
          step_2_abs_gap,
          step_2_ita_gap,
          interim_remainder_for_split,
          new_fte_annual_salary,
          new_fte_incentive_target_amount,
          new_incentive_target_percentage,
          new_itp_situation,
          new_fte_ttc_annual_salary,
          new_quartile,
          new_incentive_plan_type,
          new_position_title,
          request_type,
          currency,
          offcycle_budget_update,
          new_ttc_compa_ratio,
          target_budget_org,
          organization_unit_2_name,
          organization_unit_1_name,
          ttc_increase_perc,
          current_combo_situation,
          promo_increase_pct_guideline_min,
          promo_increase_pct_guideline_max,
          promotional_increase_percentage_guideline,
          rr_number,
          case_3_12_month_actual_incentive_amount_fte,
          override_transition_allowance_local_currency,
          // new_incentive_scheme,
          internalhire_off_cycle_spending,
          internal_hire_off_cycle_spending_euro,
          is_sip,
          Case_One_Max_Budget_calc,
          Case_one_TTC_increase_minus_Max_budget,
          Case_one_Budget_charge,
          Case_two_TI_Increase,
          Case_two_Promotion_Budget,
          Case_two_Total_budget,
          Case_two_Budget_charge,
          Case_three_twelve_month_Total_Target_Cash,
          Case_three_new_TTC_Gap_to_twelve_month_TTC,
          Case_three_Max_capped_transit_allowance,
          Case_three_Transition_allowance_total,
          Case_three_Budget_charge,
          sip_case,
          Final_Off_Cycle_Budget,
          Final_Off_Cycle_Budget_euro,
          hiring_manager,
          ttc_increase,
          /* Release 3.2 - 13.1 - Starts */
          sum_of_pay_amount,
          lastYearWageHistory,
          /* Release 3.2 - 13.1 - Ends */
        }) => {
          try {
            if (!rr_number) {
              return { [pers_no]: "Please enter RR Number" };
            }
            if (!hiring_manager) {
              return { [pers_no]: "Please Select Hiring Manger" };
            }
            var fetchhiringmanager = await getEmployeeInfo(hiring_manager);
            var json_requested_data = {
              effective_date: convertToUTC(effectiveDate),
              request_description: requestDescription,
              requester_pers_no: account.pers_no,
              request_country: country_name,
              request_type: request_type,
              nokia_id: nokia_id,
              full_name: full_name,
              pers_no,
              is_gpp_eligible,
              gpp_increase_amount,
              gpp_increase_percentage,
              ttc_increase_amount,
              ttc_increase_percentage,
              fte_ttc_annual_salary,
              promoted,
              demoted,
              promotion_job_grade_delta,
              promotion_job_family_name,
              promotion_job_subfamily_name,
              promotion_job_name,
              promotion_job_grade,
              promotion_pay_grade,
              promotion_incentive_target_percentage,
              mandatory_ttc_increase_percentage,
              mandatory_ttc_increase_amount,
              apply_ti_harmonization,
              interim_job_family_name,
              interim_job_grade,
              interim_fte_incentive_target_amount,
              interim_incentive_target_percentage,
              interim_total_increase_amount,
              interim_increase_amount_for_salincr,
              interim_increase_amount_for_tih,
              interim_salary_range_min,
              interim_salary_range_max,
              step_1_abs,
              step_1_ita,
              step_1_itp,
              interim_theo_itp,
              interim_itp_situation,
              step_2_abs_gap,
              step_2_ita_gap,
              interim_remainder_for_split,
              new_fte_annual_salary,
              new_fte_incentive_target_amount,
              new_incentive_target_percentage,
              new_itp_situation,
              new_fte_ttc_annual_salary,
              new_quartile,
              new_incentive_plan_type,
              new_position_title,
              currency,
              offcycle_budget_update: Final_Off_Cycle_Budget,
              new_ttc_compa_ratio,
              target_budget_org,
              organization_unit_2_name,
              organization_unit_1_name,
              ttc_increase_perc,
              current_combo_situation,
              promo_increase_pct_guideline_min,
              promo_increase_pct_guideline_max,
              promotional_increase_percentage_guideline,
              rr_number,
              case_3_12_month_actual_incentive_amount_fte,
              override_transition_allowance_local_currency,
              // new_incentive_scheme,
              internalhire_off_cycle_spending,
              internal_hire_off_cycle_spending_euro,
              is_sip,
              Case_One_Max_Budget_calc,
              Case_one_TTC_increase_minus_Max_budget,
              Case_one_Budget_charge,
              Case_two_TI_Increase,
              Case_two_Promotion_Budget,
              Case_two_Total_budget,
              Case_two_Budget_charge,
              Case_three_twelve_month_Total_Target_Cash,
              Case_three_new_TTC_Gap_to_twelve_month_TTC,
              Case_three_Max_capped_transit_allowance,
              Case_three_Transition_allowance_total,
              Case_three_Budget_charge,
              sip_case,

              
              Final_Off_Cycle_Budget,
              Final_Off_Cycle_Budget_euro,
              ttc_increase,
              hiring_manager,
              hiring_manager_detail:
                fetchhiringmanager && fetchhiringmanager.full_name
                  ? fetchhiringmanager.full_name +
                    "(" +
                    fetchhiringmanager.nokia_id +
                    ")" +
                    "(" +
                    fetchhiringmanager.email_work +
                    ")"
                  : null,
              /* Release 3.2 - 13.1 - Starts */
              sum_of_pay_amount: sum_of_pay_amount,
              lastYearWageHistory: lastYearWageHistory ? lastYearWageHistory : null,
              /* Release 3.2 - 13.1 - Ends */
            };
            var data_exist_check =
              simulationData &&
              simulationData.findIndex((item) => {
                let new_item = { ...item };
                delete new_item.employee_request_simulation_id;
                return (
                  JSON.stringify(new_item) ==
                  JSON.stringify(json_requested_data)
                );
              });

            if (data_exist_check != -1) {
              return {
                [pers_no]:
                  "Entered Simulation is already exist. Please try a new simulation.",
              };
            } else {
              setSubmitErrors([]);
              const results_Inner = await createSimulationRequest({
                variables: {
                  data: {
                    request_type: request_type,
                    requester_pers_no: account.pers_no,
                    requested_pers_no: pers_no,
                    requested_data: JSON.stringify(json_requested_data),
                  },
                },
              });

              return { [pers_no]: false };
            }
          } catch (e) {
            console.log("datafetch-error", e);
            const err_request_exist = e.graphQLErrors.some(
              (er) => er.extensions.code === "EXISTING_REQUEST"
            );
            return {
              [pers_no]: err_request_exist && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
                ? e.graphQLErrors[0].message
                : e.message,
            };
          }
        }
      )
    );
    const errors = results.filter((r) =>
      Object.keys(r).find((p_no) => r[p_no])
    );

    if (!errors.length) {
      setUpdatedKey(Math.floor(Math.random() * 86));
      defaultValue[0].hiring_manager = selectedEmployees[0].hiring_manager;
      selectedEmployees[0] = defaultValue[0]; //initialize the existing or default value
      setIsSubmitting(false);
    } else {
      setTimeout(() => {
        UpdateN();
      }, 100);

      setSubmitErrors(errors.map((e) => Object.values(e).shift()));
      setIsSubmitting(false);
    }
  };

  const deleteRequestSimulation = async (event) => {
    setIsSubmitting(true);  
    setSubmitErrors([]);
    let simulation_ids = selectedEmployeeDetail.map(a => a.employee_request_simulation_id);

    if (simulation_ids.lengh <= 0 && !simulation_ids[0]) {
      setSubmitErrors(["Please select simulation."]);
      setIsSubmitting(false);
      return;
    }
    await deleteSimulations({
      variables: {
        employee_request_simulation_id: parseInt(simulation_ids[0]),
      },
    })
      .then((response) => {
        if (response && response.data && response.data.deleteRequestSimulations) {
          setSelectedEmployeeDetail(selectedEmployees);
          setSimulationEntry(true);
          setUpdatedKey(Math.floor(Math.random() * 86));
        } else {
          setSubmitErrors(["Unable to delete simulation. Please try again."]);
        }
        
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    
  }

  return (
    <div>
      <FormHeading>Internal Hire With Simulations</FormHeading>
      {simulationEntry && simulationData.length < 4 && (
        <>
          <FormLabel>Selected employees: {selectedEmployees.length}</FormLabel>
          <div style={{ overflowX: "scroll" }}>
            <SelectedTable
              recordType="employees"
              items={selectedEmployees}
              columns={columns.internalHire}
              onChange={handleChange}
              /**Release 2 - Our Talent scenario */
              changeData={updateData}
            />
          </div>
          {/* Release 3.1 - Starts */}
          {(isTap || isBcb) && (
            <>
              <FormLabelInline>Effective date:</FormLabelInline>
              <DatePickerField
                selected={effectiveDate}
                onChange={handleEffectiveDateChange}
                minDate={new Date()}
                popperPlacement="right"
              />
            </>
          )}
        </>
      )}
      {!simulationData || simulationData.length == 0 ? (
        <FormHeading>No Simulation Data Found</FormHeading>
      ) : (
        <React.Fragment>
            <SimulationTable
              recordType="simulations"
              columns={columns.internalHireSimulations}
              items={simulationData}
              onChange={handleChange}
              changeData={updateData}
              handleSimulationSelect={handleSimulationSelect}
              selectedEmployeeDetail={selectedEmployeeDetail}
              key={updatedKey}
            />
        </React.Fragment>
      )}
      {isTap && !simulationEntry && (
        <>
          <FormLabel>
            Reason for request:
            <i style={{ width: "100%", float: "left" }}>
              [Please do not use the Reason field for Backdated changes dates as
              this field is visible only for approvers and not updating CoreHR
              System!]
            </i>
          </FormLabel>
          <DescriptionInput
            name="request_description"
            onChange={handleDescriptionChange}
            onKeyPress={isNumber}
          />
        </>
      )}
      <br />
      {submitErrors.length > 0 &&
        submitErrors.map((err) => {
          return (
            <ErrorsBlock key={Math.random()}>
              <IconWrap>!</IconWrap>
              {err}
            </ErrorsBlock>
          );
        })}
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
        {(isTap || isBcb || isHRBP) && (
          <React.Fragment>
            {simulationEntry && simulationData && simulationData.length < 4 && (
              <FormButton
                primary
                disabled={isSubmitting}
                onClick={handleRequestSimulation}
              >
                Save as Draft
              </FormButton>
            )}
            {!simulationEntry && simulationData && simulationData.length > 0  && (
            <FormButton   
                primary             
                disabled={isSubmitting}
                onClick={deleteRequestSimulation}
              >
                Delete Simulation
              </FormButton>
            )}
            {isTap && simulationData && simulationData.length > 0 && (
              <FormButton
                primary
                disabled={isSubmitting || simulationEntry}
                onClick={handleRequestCompensationSend}
              >
                Send request(s)
              </FormButton>
            )}
          </React.Fragment>
        )}
        {isSubmitting && (
          <LoaderWrap>
            <Ring color={"#001235"} size="2" sizeUnit="rem" />
          </LoaderWrap>
        )}
      </FormButtonsWrap>
      <CalculatorNew
        columns={columns.internalHire}
        recordType="employees"
        item={updatedItem}
        data={updateData}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
  };
};

export default connect(mapStateToProps, {
  unselectAllRecord,
  budgetSetUpdated,
  pushMessage,
})(InternalHireSimulations);
/* Release 3.2 - 9.1Req - Ends */
