import React from 'react';
import styled from 'styled-components/macro';
import Tooltip from 'react-tooltip-lite';
import { InfoCellWrap, TooltipInfo } from '..';
import { columns } from '../../util/config';

const RequestTooltip = props => {
  const { requestData, columns: col } = props;
  
  return (
    <StyledTooltip
      key={Math.random()}
      content={<TooltipInfo key={Math.random()} columns={columns[col]} data={requestData} />}
      zIndex={99999999999999999}
      // direction="left-start"
      direction="left" //fix for tool tip freeze
      arrow={false}>
      <InfoCellWrap>?</InfoCellWrap>
    </StyledTooltip>
  );
};

export { RequestTooltip };

const StyledTooltip = styled(Tooltip)`
  & span {
    width: 50%;
    margin-top: 20px;
  }
`;
