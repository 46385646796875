import React from 'react';
/*Release 2 - budget management column*/
export default (table) => {
    let  renderer=(value, data) => <>&#8364; {data.budget_in_progress.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> ;
         
      return {
           name: "budget_in_progress", 
           label: "In Progress",
           section: "Budget Management",
           visible: true,
           sortable: true,
           width:'10%',
           renderer
      };
    };