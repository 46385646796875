import { gql } from "apollo-boost";

const USERS_FOR_ADMIN = gql`
  query Employees(
    $where: EmployeesListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    employees(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        first_name
        last_name
        full_name
        nokia_id
        employment_status
        country_name
        country
        email_work
        is_line_manager
        line_manager_pers_no
        pers_start_date
        fte_ttc_annual_salary
        fte_annual_salary
        ttc_employee_quartile
        fte_incentive_target_amount
        fte_incentive_target_percentage
        current_theo_itp
        current_itp_situation
        fte_addl_ttc_amount
        ttc_compa_ratio
        incentive_plan_type
        currency
        job_id
        job_level
        job_grade
        job_name
        job_profile_id
        job_family_name
        job_subfamily_name
        position_title
        pay_scale_area
        salary_range_min: ttc_salary_range_min
        salary_range_mid: ttc_salary_range_mid
        salary_range_max: ttc_salary_range_max
        pay_grade
        organization_unit
        organization_unit_name
        organization_unit_obbrev
        organization_unit_1
        organization_unit_1_name
        organization_unit_2
        organization_unit_2_name
        organization_unit_3
        organization_unit_3_name
        organization_unit_4
        organization_unit_4_name
        # /* Release 3.2 - 13.1 -Starts */
        lastYearWageHistory {
          pers_no
          addl_pay_amount
          currency
        }
        sum_of_pay_amount
        # /* Release 3.2 - 13.1 -Ends */
        # /* Release 3.2 - 13.1 - 149299 - Starts */
        case_3_12_month_actual_incentive_amount_fte: fte_annual_salary
        # /* Release 3.2 - 13.1 - 149299 - Ends */

        #/*  DEM0065665 - Effective date Starts */
        company_code
        geo_region
        #/*  DEM0065665 - Effective date Ends */
        region_cb_pers_no
        business_cb_pers_no
        hrbp_n1_pers_no
        hrbp_n2_pers_no
        hrbp_n3_pers_no
      }
    }
  }
`;

const GPP_ADMIN = gql`
  query gppsForAdmin(
    $where: AdminGppsListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    gppsForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        gpp_guidelines_id
        country_code
        quartile
        max_guidelines
        # /* Release 3.1 - Starts */
        upd_ins_by
        # /* Release 3.1 - Ends */
        upd_ins_ts
      }
    }
  }
`;

const ITP_ADMIN = gql`
  query itpsForAdmin(
    $where: AdminItpsListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    itpsForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        Id
        job_family_name
        country_name
        job_grade
        itp
        # /* Release 3.1 - Starts */
        upd_ins_by
        # /* Release 3.1 - Ends */
        upd_ins_ts
      }
    }
  }
`;

const INC_ADMIN = gql`
  query incForAdmin(
    $where: AdminIncListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    incForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        combo
        min
        max
        country
        # /* Release 3.1 - Starts */
        upd_ins_by
        # /* Release 3.1 - Ends */
        upd_ins_ts
      }
    }
  }
`;

const CON_ADMIN = gql`
  query conForAdmin(
    $where: AdminConListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    conForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        country
        spread
        grade_progression
        # /* Release 3.1 - Starts */
        upd_ins_by
        # /* Release 3.1 - Ends */
        upd_ins_ts
      }
    }
  }
`;

const USERS_FOR_MANAGER = gql`
  query EmployeesForManager(
    $where: EmployeesListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    employeesForManager(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        first_name
        last_name
        full_name
        nokia_id
        employment_status

        country_name
        country
        email_work
        is_line_manager
        line_manager_pers_no
        pers_start_date
        fte_ttc_annual_salary
        fte_annual_salary
        ttc_employee_quartile
        fte_incentive_target_amount
        fte_incentive_target_percentage
        current_theo_itp
        current_itp_situation
        fte_addl_ttc_amount
        ttc_compa_ratio
        incentive_plan_type
        currency
        job_id
        job_level
        job_grade
        job_name
        job_profile_id
        job_family_name
        job_subfamily_name
        position_title
        pay_scale_area
        salary_range_min: ttc_salary_range_min
        salary_range_mid: ttc_salary_range_mid
        salary_range_max: ttc_salary_range_max
        pay_grade
        organization_unit
        organization_unit_name
        organization_unit_obbrev
        organization_unit_1
        organization_unit_1_name
        organization_unit_2
        organization_unit_2_name
        organization_unit_3
        organization_unit_3_name
        organization_unit_4
        organization_unit_4_name
        # /* Release 3.2 - 13.1 -Starts */
        lastYearWageHistory {
          pers_no
          addl_pay_amount
          currency
        }
        sum_of_pay_amount
        # /* Release 3.2 - 13.1 -Ends */
        # /* Release 3.2 - 13.1 - 149299 - Starts */
        case_3_12_month_actual_incentive_amount_fte: fte_annual_salary
        # /* Release 3.2 - 13.1 - 149299 - Ends */
        #/*  DEM0065665 - Effective date Starts */
        company_code
        geo_region
        #/*  DEM0065665 - Effective date Ends */
        region_cb_pers_no
        business_cb_pers_no
        hrbp_n1_pers_no
        hrbp_n2_pers_no
        hrbp_n3_pers_no
      }
    }
  }
`;
/*  DEM0066861 - Regional BCB role Starts */
const USERS_FOR_RBCB = gql`
  query EmployeesForRBCB(
    $where: EmployeesListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    employeesForRBCB(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        first_name
        last_name
        full_name
        nokia_id
        employment_status

        country_name
        country
        email_work
        is_line_manager
        line_manager_pers_no
        pers_start_date
        fte_ttc_annual_salary
        fte_annual_salary
        ttc_employee_quartile
        fte_incentive_target_amount
        fte_incentive_target_percentage
        current_theo_itp
        current_itp_situation
        fte_addl_ttc_amount
        ttc_compa_ratio
        incentive_plan_type
        currency
        job_id
        job_level
        job_grade
        job_name
        job_profile_id
        job_family_name
        job_subfamily_name
        position_title
        pay_scale_area
        salary_range_min: ttc_salary_range_min
        salary_range_mid: ttc_salary_range_mid
        salary_range_max: ttc_salary_range_max
        pay_grade
        organization_unit
        organization_unit_name
        organization_unit_obbrev
        organization_unit_1
        organization_unit_1_name
        organization_unit_2
        organization_unit_2_name
        organization_unit_3
        organization_unit_3_name
        organization_unit_4
        organization_unit_4_name
        # /* Release 3.2 - 13.1 -Starts */
        lastYearWageHistory {
          pers_no
          addl_pay_amount
          currency
        }
        sum_of_pay_amount
        # /* Release 3.2 - 13.1 -Ends */
        # /* Release 3.2 - 13.1 - 149299 - Starts */
        case_3_12_month_actual_incentive_amount_fte: fte_annual_salary
        # /* Release 3.2 - 13.1 - 149299 - Ends */
        #/*  DEM0065665 - Effective date Starts */
        company_code
        geo_region
        #/*  DEM0065665 - Effective date Ends */
        region_cb_pers_no
        business_cb_pers_no
        hrbp_n1_pers_no
        hrbp_n2_pers_no
        hrbp_n3_pers_no
      }
    }
  }
`;
/*  DEM0066861 - Regional BCB role Ends */
const USERS_FOR_TAP = gql`
  query employeesForTap(
    $where: EmployeesListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    employeesForTap(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        first_name
        last_name
        full_name
        nokia_id
        employment_status
        country_name
        country
        hr_region
        city
        email_work
        is_line_manager
        line_manager_pers_no
        pers_start_date
        fte_ttc_annual_salary
        fte_annual_salary
        ttc_employee_quartile
        fte_incentive_target_amount
        fte_incentive_target_percentage
        current_theo_itp
        current_itp_situation
        fte_addl_ttc_amount
        ttc_compa_ratio
        incentive_plan_type
        currency
        job_id
        job_level
        job_grade
        job_name
        job_profile_id
        job_family_name
        job_subfamily_name
        position_title
        pay_scale_area
        salary_range_min: ttc_salary_range_min
        salary_range_mid: ttc_salary_range_mid
        salary_range_max: ttc_salary_range_max
        pay_grade
        organization_unit
        organization_unit_name
        organization_unit_obbrev
        organization_unit_1
        organization_unit_1_name
        organization_unit_2
        organization_unit_2_name
        organization_unit_3
        organization_unit_3_name
        organization_unit_4
        organization_unit_4_name
        # /* Release 3.2 - 13.1 -Starts */
        lastYearWageHistory {
          pers_no
          addl_pay_amount
          currency
        }
        sum_of_pay_amount
        # /* Release 3.2 - 13.1 -Ends */
        # /* Release 3.2 - 13.1 - 149299 - Starts */
        case_3_12_month_actual_incentive_amount_fte: fte_annual_salary
        # /* Release 3.2 - 13.1 - 149299 - Ends */
        #/*  DEM0065665 - Effective date Starts */
        company_code
        geo_region
        #/*  DEM0065665 - Effective date Ends */
        region_cb_pers_no
        business_cb_pers_no
        hrbp_n1_pers_no
        hrbp_n2_pers_no
        hrbp_n3_pers_no
      }
    }
  }
`;

const MANAGER_DIRECT_ORDINATES = gql`
  query ManagerDirectOrdinates(
    $where: EmployeesListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    managerDirectSubOrdinates(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        first_name
        last_name
        full_name
        nokia_id
        employment_status
        country_name
        country
        email_work
        is_line_manager
        line_manager_pers_no
        pers_start_date
        fte_ttc_annual_salary
        fte_annual_salary
        ttc_employee_quartile
        fte_incentive_target_amount
        fte_incentive_target_percentage
        current_theo_itp
        current_itp_situation
        fte_addl_ttc_amount
        ttc_compa_ratio
        incentive_plan_type
        currency
        job_id
        job_level
        job_grade
        job_name
        job_profile_id
        job_family_name
        job_subfamily_name
        position_title
        pay_scale_area
        salary_range_min: ttc_salary_range_min
        salary_range_mid: ttc_salary_range_mid
        salary_range_max: ttc_salary_range_max
        pay_grade
        organization_unit
        organization_unit_name
        organization_unit_obbrev
        organization_unit_1
        organization_unit_1_name
        organization_unit_2
        organization_unit_2_name
        organization_unit_3
        organization_unit_3_name
        organization_unit_4
        organization_unit_4_name
        #/*  DEM0065665 - Effective date Starts */
        company_code
        geo_region
        #/*  DEM0065665 - Effective date Ends */
        region_cb_pers_no
        business_cb_pers_no
        hrbp_n1_pers_no
        hrbp_n2_pers_no
        hrbp_n3_pers_no

      }
    }
  }
`;

const MANAGER_SECOND_DIRECT_ORDINATES = gql`
  query ManagerSecondDirectOrdinates(
    $where: EmployeesListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    managerSecondSubOrdinates(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        first_name
        last_name
        full_name
        nokia_id
        employment_status
        country_name
        country
        email_work
        is_line_manager
        line_manager_pers_no
        pers_start_date
        fte_ttc_annual_salary
        fte_annual_salary
        ttc_employee_quartile
        fte_incentive_target_amount
        fte_incentive_target_percentage
        current_theo_itp
        current_itp_situation
        fte_addl_ttc_amount
        ttc_compa_ratio
        incentive_plan_type
        currency
        job_id
        job_level
        job_grade
        job_name
        job_profile_id
        job_family_name
        job_subfamily_name
        position_title
        pay_scale_area
        salary_range_min: ttc_salary_range_min
        salary_range_mid: ttc_salary_range_mid
        salary_range_max: ttc_salary_range_max
        pay_grade
        organization_unit
        organization_unit_name
        organization_unit_obbrev
        organization_unit_1
        organization_unit_1_name
        organization_unit_2
        organization_unit_2_name
        organization_unit_3
        organization_unit_3_name
        organization_unit_4
        organization_unit_4_name
        #/*  DEM0065665 - Effective date Starts */
        company_code
        geo_region
        #/*  DEM0065665 - Effective date Starts */
      }
    }
  }
`;

const BUDGET_FOR_ADMIN = gql`
  query budgetsForAdmin(
    $where: AdminBudgetListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    budgetsForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        budget_id
        budget_organization_unit
        budget
        description
        year
        capital_budget
        budget_available
        budget_in_progress
        budget_spent
        organization_unit {
          organization_unit_name
          organization_unit_1
          organization_unit_1_name
        }
      }
    }
  }
`;
const USERS_FOR_HR = gql`
  query EmployeesForHr(
    $where: EmployeesListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    employeesForHr(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        first_name
        last_name
        full_name
        nokia_id
        employment_status
        country_name
        country
        hr_region
        city
        email_work
        is_line_manager
        line_manager_pers_no
        pers_start_date
        fte_ttc_annual_salary
        fte_annual_salary
        ttc_employee_quartile
        fte_incentive_target_amount
        fte_incentive_target_percentage
        current_theo_itp
        current_itp_situation
        fte_addl_ttc_amount
        ttc_compa_ratio
        incentive_plan_type
        currency
        job_id
        job_level
        job_grade
        job_name
        job_profile_id
        job_family_name
        job_subfamily_name
        position_title
        pay_scale_area
        salary_range_min: ttc_salary_range_min
        salary_range_mid: ttc_salary_range_mid
        salary_range_max: ttc_salary_range_max
        pay_grade
        organization_unit
        organization_unit_name
        organization_unit_obbrev
        organization_unit_1
        organization_unit_1_name
        organization_unit_2
        organization_unit_2_name
        organization_unit_3
        organization_unit_3_name
        organization_unit_4
        organization_unit_4_name
        # /* Release 3.2 - 13.1 -Starts */
        lastYearWageHistory {
          pers_no
          addl_pay_amount
          currency
        }
        sum_of_pay_amount
        # /* Release 3.2 - 13.1 -Ends */
        # /* Release 3.2 - 13.1 - 149299 - Starts */
        case_3_12_month_actual_incentive_amount_fte: fte_annual_salary
        # /* Release 3.2 - 13.1 - 149299 - Ends */
        #/*  DEM0065665 - Effective date Starts */
        company_code
        geo_region
        #/*  DEM0065665 - Effective date Ends */
        region_cb_pers_no
        business_cb_pers_no
        hrbp_n1_pers_no
        hrbp_n2_pers_no
        hrbp_n3_pers_no
      }
    }
  }
`;

const BUDGET = gql`
  query budget($organization_unit: String!) {
    budget(organization_unit: $organization_unit) {
      description
      budget
      budget_available
      budget_spent
      budget_in_progress
      org: organization_unit {
        organization_unit_name
        organization_unit
        organization_unit_obbrev
      }
    }
  }
`;

const BUDGETS = gql`
  query Budgets(
    $where: BudgetListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    budgets(where: $where, offset: $offset, limit: $limit, orderBy: $orderBy) {
      totalCount
      items {
        description
        budget
        budget_available
        budget_spent
        budget_in_progress
        org: organization_unit {
          organization_unit_name
          organization_unit
          organization_unit_obbrev
        }
      }
    }
  }
`;

const REQUESTS = gql`
  query Requests(
    $where: RequestsListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    requests(where: $where, offset: $offset, limit: $limit, orderBy: $orderBy) {
      totalCount
      items {
        employee_request_id
        request_description
        request_type
        request_country
        ttc_increase_amount
        status
        reject_comments
        currency
        ttc_increase_percentage
        new_fte_annual_salary
        new_fte_incentive_target_amount
        new_incentive_target_percentage
        new_itp_situation
        new_fte_ttc_annual_salary
        new_quartile
        new_incentive_plan_type
        interim_job_grade
        created_ts
        upd_ins_ts
        promotion_job_family_name
        promotion_job_subfamily_name
        promotion_job_name
        promotion_job_grade
        new_position_title
        payment_date
        effective_date
        new_ttc_compa_ratio
        payment_type
        offcycle_budget_update
        mandatory_ttc_increase_amount
        mandatory_ttc_increase_percentage
        rr_number
        promotional_increase_percentage_guideline
        case_3_12_month_actual_incentive_amount_fte
        override_transition_allowance_local_currency
        hiring_manager
        is_sip
        sip_case
        Final_Off_Cycle_Budget
        current_combo_situation
        promo_increase_pct_guideline_min
        promo_increase_pct_guideline_max
        internalhire_off_cycle_spending
        internal_hire_off_cycle_spending_euro
        Case_One_Max_Budget_calc
        Case_one_TTC_increase_minus_Max_budget
        Case_one_Budget_charge
        Case_two_TI_Increase
        Case_two_Promotion_Budget
        Case_two_Total_budget
        Case_two_Budget_charge
        Case_three_twelve_month_Total_Target_Cash
        Case_three_new_TTC_Gap_to_twelve_month_TTC
        Case_three_Max_capped_transit_allowance
        Case_three_Transition_allowance_total
        Case_three_Budget_charge
        hmanager: hm {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          email_work
          organization_unit_name
          organization_unit_1_name          
          # /* Release 3.2 - 10.3 Starts */
          organization_unit_2_name
          # /* Release 3.2 - 10.3 Ends */
        }
        for: pers_no {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          country_name
          fte_ttc_annual_salary
          fte_annual_salary
          fte_incentive_target_amount
          fte_incentive_target_percentage
          current_theo_itp
          current_itp_situation
          fte_addl_ttc_amount
          ttc_employee_quartile
          job_family_name
          job_subfamily_name
          job_name
          position_title
          job_grade
          country
          pers_start_date
          pay_scale_area
          salary_range_min: ttc_salary_range_min
          salary_range_mid: ttc_salary_range_mid
          salary_range_max: ttc_salary_range_max
          pay_grade
          incentive_plan_type
          ttc_compa_ratio
          organization_unit_2_name
          organization_unit_1_name
          organization_unit_name
        }
        by: requester_pers_no {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          job_family_name
        }
        approvals {
          employee_request_id
          approval_status
          approver_level
          approver {
            full_name
          }
          on_behalf_of_approver {
            full_name
          }
        }
        comments {
          pre_status
          post_status
          description
          commenter_pers_no
          upd_ins_ts
          commenter {
            pers_no
            first_name
            last_name
            full_name
            nokia_id
            job_family_name
          }
        }
        budget_allocations {
          request_id
          amount
          organization_unit
          organization_unit_name
          organization_unit_level
          upd_ins_ts
        }
        changeApprovers {
          pre_approver_pers_no
          post_approver_pers_no
          pre_approver_is_active 
          upd_ins_pers_no
          upd_ins_ts
          changer {
            pers_no
            full_name
          }
          preApprover {
            pers_no
            full_name
          }
          postApprover {
            pers_no
            full_name
          }
          approval {
            id
            employee_request_id
            approver_level
          }
        }
        # /* Release 3.2 - 13.1 -Starts */
        lastYearWageHistory {
          pers_no
          addl_pay_amount
          currency
        }
        sum_of_pay_amount
        # /* Release 3.2 - 13.1 -Ends */
        # /* Release 3.2 - 11.2 -Starts */
        reminder_info {
          last_reminder_date
          reminder_count
        }
        # /* Release 3.2 - 11.2 -Ends */
        # /* Release 3.1 - 1.1 - Starts */
        award_title
        award_message
        award_privacy
        award_reason
        nominator_email_address
        # /* Release 3.1 - 1.1 - Ends */
      }
    }
  }
`;

const REQUESTALLHRC = gql`
  query Requestallhrc(
    $where: RequestsListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    requestallhrc(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        employee_request_id
        request_description
        request_type
        request_country
        ttc_increase_amount
        status
        reject_comments
        currency
        ttc_increase_percentage
        new_fte_annual_salary
        new_fte_incentive_target_amount
        new_incentive_target_percentage
        new_itp_situation
        new_fte_ttc_annual_salary
        new_quartile
        new_incentive_plan_type
        interim_job_grade
        created_ts
        upd_ins_ts
        promotion_job_family_name
        promotion_job_subfamily_name
        promotion_job_name
        promotion_job_grade
        new_position_title
        payment_date
        effective_date
        new_ttc_compa_ratio
        payment_type
        offcycle_budget_update
        mandatory_ttc_increase_amount
        mandatory_ttc_increase_percentage
        rr_number
        promotional_increase_percentage_guideline
        case_3_12_month_actual_incentive_amount_fte
        override_transition_allowance_local_currency
        hiring_manager
        is_sip
        sip_case
        Final_Off_Cycle_Budget
        current_combo_situation
        promo_increase_pct_guideline_min
        promo_increase_pct_guideline_max
        internalhire_off_cycle_spending
        internal_hire_off_cycle_spending_euro
        Case_One_Max_Budget_calc
        Case_one_TTC_increase_minus_Max_budget
        Case_one_Budget_charge
        Case_two_TI_Increase
        Case_two_Promotion_Budget
        Case_two_Total_budget
        Case_two_Budget_charge
        Case_three_twelve_month_Total_Target_Cash
        Case_three_new_TTC_Gap_to_twelve_month_TTC
        Case_three_Max_capped_transit_allowance
        Case_three_Transition_allowance_total
        Case_three_Budget_charge
        hmanager: hm {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          email_work
          organization_unit_name
          organization_unit_1_name
          # /* Release 3.2 - 10.3 Starts */
          organization_unit_2_name
          # /* Release 3.2 - 10.3 Ends */
        }
        for: pers_no {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          country_name
          fte_ttc_annual_salary
          fte_annual_salary
          fte_incentive_target_amount
          fte_incentive_target_percentage
          current_theo_itp
          current_itp_situation
          fte_addl_ttc_amount
          ttc_employee_quartile
          job_family_name
          job_subfamily_name
          job_name
          position_title
          job_grade
          country
          pers_start_date
          pay_scale_area
          salary_range_min: ttc_salary_range_min
          salary_range_mid: ttc_salary_range_mid
          salary_range_max: ttc_salary_range_max
          pay_grade
          incentive_plan_type
          ttc_compa_ratio
          organization_unit_2_name
          organization_unit_1_name
          organization_unit_name
        }
        by: requester_pers_no {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          job_family_name
        }
        approvals {
          employee_request_id
          approval_status
          approver_level
          approver {
            full_name
          }
          on_behalf_of_approver {
            full_name
          }
        }
        comments {
          pre_status
          post_status
          description
          commenter_pers_no
          upd_ins_ts
          commenter {
            pers_no
            first_name
            last_name
            full_name
            nokia_id
            job_family_name
          }
        }
        budget_allocations {
          request_id
          amount
          organization_unit
          organization_unit_name
          organization_unit_level
          upd_ins_ts
        }
        changeApprovers {
          pre_approver_pers_no
          post_approver_pers_no
          pre_approver_is_active
          upd_ins_pers_no
          upd_ins_ts
          changer {
            pers_no
            full_name
          }
          preApprover {
            pers_no
            full_name
          }
          postApprover {
            pers_no
            full_name
          }
          approval {
            id
            employee_request_id
            approver_level
          }
        }
        # /* Release 3.2 - 11.2 -Starts */
        reminder_info {
          last_reminder_date
          reminder_count
        }
        # /* Release 3.2 - 11.2 -Ends */
        # /* Release 3.1 - 1.1 - Starts */
        award_title
        award_message
        award_privacy
        award_reason
        nominator_email_address
        # /* Release 3.1 - 1.1 - Ends */
      }
    }
  }
`;

const PEOPLE_SUGGEST = gql`
  query match_people($where: EmployeeListFilter, $offset: Int, $limit: Int) {
    match_people(where: $where, offset: $offset, limit: $limit) {
      totalCount
      items {
        pers_no
        first_name
        full_name
        email_work
        nokia_id
      }
    }
  }
`;
const HM_SUGGEST = gql`
  query hiringmanager($where: EmployeeListFilter, $offset: Int, $limit: Int) {
    hiringmanager(where: $where, offset: $offset, limit: $limit) {
      totalCount
      items {
        pers_no
        first_name
        full_name
        email_work
        nokia_id
      }
    }
  }
`;
const ORG_SUGGEST = gql`
  query organizationsForAdmin(
    $where: OrganizationListFilterForAdmin
    $limit: Int
    $offset: Int
  ) {
    organizationsForAdmin(where: $where, limit: $limit, offset: $offset) {
      organization_unit
      organization_unit_name
      organization_unit_1
    }
  }
`;

const PARENT_BUDGET = gql`
  query organizationBudgetForAdmin(
    $organization_unit: String!
    $organization_unit_1: String!
    $year: String!
  ) {
    organizationBudgetForAdmin(
      organization_unit: $organization_unit
      organization_unit_1: $organization_unit_1
      year: $year
    ) {
      budget_id
      description
      budget
      budget_available
      budget_in_progress
      budget_organization_unit
      country_name
      capital_budget
      capital_budget_available
      childs_organization_bugdet
      childs_organization_bugdet_spent
    }
  }
`;

/*  DEM0060697 OCC Corrections 2 CHANGES STARTS */
const PENDING_REQUESTS = gql`
  query PendingRequests(
    $where: ApprovalsListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    approvals(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        employee_request_id
        approver_level
        request {
          comments {
            pre_status
            post_status
            description
            commenter_pers_no
            upd_ins_ts
            commenter {
              pers_no
              first_name
              last_name
              full_name
              nokia_id
              job_family_name
            }
          }
          approvals {
            id
            employee_request_id
            approver_pers_no
            approval_status
            approver_level
            approver {
              full_name,
              email_work,
              nokia_id,
              pers_no
            }
            on_behalf_of_approver {
              full_name
            }
          }
          request_description
          request_type
          request_country
          ttc_increase_amount
          status
          reject_comments
          currency
          ttc_increase_percentage
          new_fte_annual_salary
          new_fte_incentive_target_amount
          new_incentive_target_percentage
          new_itp_situation
          new_fte_ttc_annual_salary
          new_quartile
          new_incentive_plan_type
          interim_job_grade
          promotion_job_family_name
          promotion_job_subfamily_name
          promotion_job_name
          new_position_title
          promotion_job_grade
          promotion_job_grade_delta
          promotion_incentive_target_percentage
          upd_ins_ts
          created_ts
          # is_gpp_eligible
          gpp_increase_amount
          gpp_increase_percentage
          promoted
          demoted
          mandatory_ttc_increase_percentage
          mandatory_ttc_increase_amount

          apply_ti_harmonization
          interim_job_family_name
          interim_job_grade
          interim_fte_incentive_target_amount
          interim_incentive_target_percentage
          interim_total_increase_amount
          interim_increase_amount_for_salincr
          interim_increase_amount_for_tih
          step_1_abs
          step_1_ita
          step_1_itp
          interim_theo_itp
          interim_itp_situation
          step_2_abs_gap
          step_2_ita_gap
          payment_date
          effective_date
          new_ttc_compa_ratio
          payment_type
          offcycle_budget_update
          rr_number
          promotional_increase_percentage_guideline
          case_3_12_month_actual_incentive_amount_fte
          override_transition_allowance_local_currency
          hiring_manager
          is_sip
          sip_case
          Final_Off_Cycle_Budget
          current_combo_situation
          promo_increase_pct_guideline_min
          promo_increase_pct_guideline_max
          internalhire_off_cycle_spending
          internal_hire_off_cycle_spending_euro
          Case_One_Max_Budget_calc
          Case_one_TTC_increase_minus_Max_budget
          Case_one_Budget_charge
          Case_two_TI_Increase
          Case_two_Promotion_Budget
          Case_two_Total_budget
          Case_two_Budget_charge
          Case_three_twelve_month_Total_Target_Cash
          Case_three_new_TTC_Gap_to_twelve_month_TTC
          Case_three_Max_capped_transit_allowance
          Case_three_Transition_allowance_total
          Case_three_Budget_charge
          hmanager: hm {
            pers_no
            first_name
            last_name
            full_name
            nokia_id
            email_work
            organization_unit_name
            organization_unit_1_name
            # /* Release 3.2 - 10.3 Starts */
            organization_unit_2_name
            # /* Release 3.2 - 10.3 Ends */            
          }
          for: pers_no {
            pers_no
            first_name
            last_name
            full_name
            nokia_id
            country_name
            fte_ttc_annual_salary
            fte_annual_salary
            fte_incentive_target_amount
            fte_incentive_target_percentage
            current_theo_itp
            current_itp_situation
            fte_addl_ttc_amount
            ttc_employee_quartile
            job_family_name
            job_subfamily_name
            job_name
            position_title
            job_grade
            country
            pers_start_date
            pay_scale_area
            salary_range_min: ttc_salary_range_min
            salary_range_mid: ttc_salary_range_mid
            salary_range_max: ttc_salary_range_max
            pay_grade
            incentive_plan_type
            ttc_compa_ratio
            organization_unit_1_name
            organization_unit_name
            organization_unit_2_name
          }
          by: requester_pers_no {
            pers_no
            first_name
            last_name
            full_name
            nokia_id
            job_family_name
          }
          budget_allocations {
            request_id
            amount
            organization_unit
            organization_unit_name
            organization_unit_level
            upd_ins_ts
          }
          changeApprovers {
            pre_approver_pers_no
            post_approver_pers_no
            pre_approver_is_active 
            upd_ins_pers_no
            upd_ins_ts
            changer {
              pers_no
              full_name
            }
            preApprover {
              pers_no
              full_name
            }
            postApprover {
              pers_no
              full_name
            }
            approval {
              id
              employee_request_id
              approver_level
            }
          }
          # /* Release 3.2 - 11.2 -Starts */
          reminder_info {
            last_reminder_date
            reminder_count
          }
          # /* Release 3.2 - 11.2 -Ends */
          # /* Release 3.1 - 1.1 - Starts */
          award_title
          award_message
          award_privacy
          award_reason
          nominator_email_address
          # /* Release 3.1 - 1.1 - Ends */
        }
        approver {
          full_name
        }
      }
    }
  }
`;
/* Release 3.2 - 9.1Req - Starts */
const SIMULATIONS = gql`
  query requestSimulationList(
    $where: RequestSimulationListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    requestSimulationList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        employee_request_simulation_id
        request_type
        requester_pers_no
        requested_pers_no
        requested_data
        upd_ins_by
        upd_ins_ts
        status
      }
    }
  }
`;
/* Release 3.2 - 9.1Req - Ends */

const OVERRIDE_REQUEST_WITH_BUDGET = gql`
  mutation OverrideRequest(
    $employee_request_id: ID!
    $data: UpdateRequestInput!
  ) {
    overrideRequest(employee_request_id: $employee_request_id, data: $data)
  }
`;

const EDIT_REQUEST = gql`
  mutation EditRequest($employee_request_id: ID!, $data: EditRequestInput!) {
    editRequest(employee_request_id: $employee_request_id, data: $data)
  }
`;

const CANCEL_REQUEST_WITH_BUDGET = gql`
  mutation CancelRequest($employee_request_id: ID!) {
    cancelRequest(employee_request_id: $employee_request_id)
  }
`;

const REVIEW_REQUEST = gql`
  mutation ReviewRequest(
    $employee_request_id: ID!
    $approver_level: Int!
    $comment: String!
  ) {
    reviewRequest(
      employee_request_id: $employee_request_id
      approver_level: $approver_level
      comment: $comment
    )
  }
`;
const CREATE_REQUEST_WITH_BUDGET = gql`
  mutation CreateRequest($data: CreateRequestInput!) {
    createRequest(data: $data) {
      employee_request_id
      status
    }
  }
`;
/* Release 3.2 - 9.1Req - Starts */
const CREATE_REQUEST_SIMULATION = gql`
  mutation CreateSimulationRequest($data: CreateSimulationRequestInput!) {
    createSimulation(data: $data) {
      employee_request_simulation_id
      requested_data
      status
    }
  }
`;
/* Release 3.2 - 9.1Req - Ends */
/*Add New Budget */
const CREATE_BUDGET = gql`
  mutation createBudget($data: createBudgetInputOCC!) {
    createBudget(data: $data)
  }
`;

const EDIT_BUDGET = gql`
  mutation editBudget($budget_id: ID!, $data: editBudgetInputOCC!) {
    editBudget(budget_id: $budget_id, data: $data)
  }
`;

const REJECT_REQUEST = gql`
  mutation RejectRequest(
    $employee_request_id: ID!
    $approver_level: Int!
    $comment: String!
  ) {
    rejectRequest(
      employee_request_id: $employee_request_id
      approver_level: $approver_level
      comment: $comment
    )
  }
`;

const APPROVE_REQUEST = gql`
  mutation ApproveRequest(
    $employee_request_id: ID!
    $approver_level: Int!
    $comment: String!
    # /* Release 3.1 - Starts */
    $effective_date: String
    $request_type: String
    $payment_date: String
    # /* Release 3.1 - Ends */
  ) {
    approveRequest(
      employee_request_id: $employee_request_id
      approver_level: $approver_level
      comment: $comment
      # /* Release 3.1 - Starts */
      effective_date: $effective_date
      request_type: $request_type
      payment_date: $payment_date
      # /* Release 3.1 - Ends */
    )
  }
`;

const DELETE_REQUEST = gql`
  mutation DeleteRequest($requestId: ID!) {
    deleteRequest(EmployeRequestsID: $requestId)
  }
`;

const UPDATE_BUDGET_FROM_REQUEST_DELETED = gql`
  mutation UpdateBudgetFromRequestDeleted(
    $country: String!
    $requestedAmount: Float!
  ) {
    updateBudgetFromRequestDeleted(
      Country: $country
      requestedAmount: $requestedAmount
    ) {
      BudgetId
      budget
      budgetAvailable
      budgetInProgress
      budgetSpent
      Country
      description
    }
  }
`;

const GPP_LOOKUP_TABLE = gql`
  query gpps {
    gpps {
      country: country_code
      quartile
      max: max_guidelines
    }
  }
`;

const ITP_LOOKUP_TABLE = gql`
  query itps {
    itps {
      job_family: job_family_name
      country: country_name
      job_grade
      itp
    }
  }
`;

const EXCHANGE_RATES = gql`
  query exchangeRates {
    exchange {
      to_currency
      from_currency
      exchange_rate
    }
  }
`;

const JOBS = gql`
  query jobs {
    allJobs {
      job_family_name
      job_subfamily_name
      job_name
      pay_grade
      job_grade
    }
  }
`;

const CONFIG = gql`
  query config {
    allJobs {
      job_family_name
      job_subfamily_name
      job_name

      pay_grade
      job_grade
    }
    exchange {
      to_currency
      from_currency
      exchange_rate
    }
    itps {
      job_family: job_family_name
      country: country_name
      job_grade
      itp
    }
    gpps {
      country: country_code
      quartile
      max: max_guidelines
    }
    incentivePlanTypes {
      code: incentive_plan_type
      description: incentive_plan
    }
    #/*  DEM0065665 - Effective date Starts */
    effectiveDateConfigs {
      id
      iso_code
      company_code
      region
      country
      salary_increase_effective_date
      one_time_payment_effective_date
      upd_ins_by
      upd_ins_ts
    }
    #/*  DEM0065665 - Effective date Ends */
    allSalaryRanges {
      country_code
      country_name
      grade_region
      pay_grade
      salary_min
      ttc_salary_range_min
      ttc_salary_range_mid
      ttc_salary_range_max
      currency_code
      currency_text
    }
    countries {
      country
      spread
      grade_progression
    }
    increases {
      combo
      min
      max
      country
    }
  }
`;

const SALARY_RANGE = gql`
  query salaryRange(
    $country: String!
    $payScaleArea: String!
    $payGrade: String!
    $currency: String!
  ) {
    salaryRange(
      country_name: $country
      grade_region: $payScaleArea
      pay_grade: $payGrade
      currency_code: $currency
    ) {
      min
      mid
      max
    }
  }
`;
/* DEM0058258 */
const AUTHORIZE = gql`
  query Authorize {
    authorize {
      token
      payload {
        pers_no
        email
        roles
        name
        sub
        firstName
        lastName
        sessionId
        countryCode
        org_name
        org_unit_1
        org_unit_2
        org_unit_3
        org_unit_4
        budget_org
        line_manager
        totalHRCRelationExists
        finalHRCRelation {
          hrc_contact_id
          region
          country_name
          hrc_contact_pers_no
          hrc_created_at
        }
        geoRegion
        hrRegion
        accessibles {
          budgetOrgs
          employeeVisibilityOrgs
        }
        # /* Release 3.2 - 10.1 - Starts */
        rcbHRRegion
        # /* Release 3.2 - 10.1 - Starts */
      }
    }
  }
`;

const ALL_DELEGATES = gql`
  query allDelegates {
    delegationList {
      items {
        pers_no
        full_name
      }
    }
  }
`;

const ALL_DELEGATES_WITH_SEARCH = gql`
  query delegationList(
    $where: delegationListFilter!
    $limit: Int!
    $orderBy: [OrderByInput]
  ) {
    delegationList(where: $where, limit: $limit, orderBy: $orderBy) {
      items {
        pers_no
        nokia_id
        full_name
      }
    }
  }
`;
/*  HR3_ROLE */
const DELEGATES_ROLES_WITH_PERS = gql`
  query delegationRoles($pers_no: String!) {
    delegationRoles(pers_no: $pers_no) {
      is_second_line_manager
      is_business_cb
      is_hrbp_n1
      is_hrbp_n2
      is_hrbp_n3
      is_gtap
      is_rtap
      rtap_regions
      hr_region
      is_hr_connection
    }
  }
`;

const DELEGATES_DETAILS = gql`
  query delegationDetail(
    $where: delegationDetailListFilter!
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    delegationDetail(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        pers_no
        approver_type
        delegation_pers_no
        action_task
        start_date
        end_date
        is_active
        upd_ins_ts
      }
    }
  }
`;

const DELEGATES_FOR_MANAGER = gql`
  query DelegatesForManager(
    $where: DelegatesForManagerListFilter!
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    delegatesForManager(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        pers_no {
          pers_no
          full_name
          nokia_id
        }
        delegation_pers_no {
          full_name
          nokia_id
          pers_no
        }
        start_date
        end_date
        is_active
        action_task
        upd_ins_ts
        approver_type
      }
    }
  }
`;

const CREATE_DELEGATE = gql`
  mutation createDelegation($data: createDelegationInput!) {
    createDelegation(data: $data) {
      id
      pers_no {
        pers_no
        full_name
        nokia_id
      }
      approver_type
      delegation_pers_no {
        pers_no
        full_name
        nokia_id
      }
      action_task
      start_date
      end_date
      is_active
      upd_ins_ts
    }
  }
`;
const EDIT_DELEGATION = gql`
  mutation editDelegation($id: ID!, $data: EditDelegationInput!) {
    editDelegation(id: $id, data: $data)
  }
`;
const UPLOAD_FILE = gql`
  mutation singleUpload($file: Upload!, $adtable: String!) {
    singleUpload(file: $file, adtable: $adtable) {
      Status
      # /* Release 3.1 - Starts */
      Message {
        row
        message
      }
      # /* Release 3.1 - Ends */
    }
  }
`;
// Added for admin calculations R2
const GET_FORMULAS = gql`
  query formulas {
    formulas {
      formula_id
      formula_name
      field_name
      formula_type
      formula
      isOurTalentForumla
    }
  }
`;
// Added for admin calculations R2
const UPDATE_FORMULA = gql`
  mutation updateFormula(
    $formula_name: String!
    $field_name: String!
    $formula_type: String!
    $formula: String!
  ) {
    updateFormula(
      formula_name: $formula_name
      field_name: $field_name
      formula_type: $formula_type
      formula: $formula
    )
  }
`;
const GET_FORMULAS_META_INFO = gql`
  query formulaMetaInfo {
    formulaMetaInfo {
      functions
      fields
      operators
      ref_tbls
    }
  }
`;

const MOCREQUESTS = gql`
  query moc_requests(
    $where: RequestsMOCListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    moc_requests(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        request_type
        promotion_job_family_name
        promotion_job_subfamily_name
        ttc_increase_amount
        payment_type
        import_status
        promotion_job_grade
        new_position_title
        promotion_job_name
        promotion_pay_grade
        for: pers_no {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          employment_status
          country_name
          country
          email_work
          is_line_manager
          line_manager_pers_no
          pers_start_date
          fte_ttc_annual_salary
          fte_annual_salary
          ttc_employee_quartile
          fte_incentive_target_amount
          fte_incentive_target_percentage
          current_theo_itp
          current_itp_situation
          fte_addl_ttc_amount
          ttc_compa_ratio
          incentive_plan_type
          currency
          job_id
          job_level
          job_grade
          job_name
          job_profile_id
          job_family_name
          job_subfamily_name
          position_title
          pay_scale_area
          salary_range_min: ttc_salary_range_min
          salary_range_mid: ttc_salary_range_mid
          salary_range_max: ttc_salary_range_max
          pay_grade
          organization_unit
          organization_unit_name
          organization_unit_obbrev
          organization_unit_1
          organization_unit_1_name
          organization_unit_2
          organization_unit_2_name
          organization_unit_3
          organization_unit_3_name
          organization_unit_4
          organization_unit_4_name
          #/*  DEM0065665 - Effective date Starts */
          company_code
          geo_region
          #/*  DEM0065665 - Effective date Ends */
          region_cb_pers_no
          business_cb_pers_no
          hrbp_n1_pers_no
          hrbp_n2_pers_no
          hrbp_n3_pers_no
        }
        # /* Release 3.1 - 1.1 - Starts */
        award_title
        award_message
        award_privacy
        award_reason
        nominator_email_address
        guid
        # /* Release 3.1 - 1.1 - Ends */
      }
    }
  }
`;

const MANAGERS_SUGGEST = gql`
  query lineManager($where: EmployeeListFilter, $offset: Int, $limit: Int) {
    lineManager(where: $where, offset: $offset, limit: $limit) {
      totalCount
      items {
        pers_no
        first_name
        full_name
        email_work
        nokia_id
      }
    }
  }
`;

const MOC_EDIT = gql`
  mutation moc_edit($where: RequestsMOCListFilter, $data: UpdateMOCData!) {
    moc_edit(where: $where, data: $data)
  }
`;

const MOC_UPLOAD = gql`
  mutation mocUpload($file: Upload!, $reqType: String!) {
    mocUpload(file: $file, reqType: $reqType) {
      Status
      # /* Release 3.2 - 1.1 - Starts */
      Message {
        row
        message
      }
      # /* Release 3.2 - 1.1 - Ends */
      scounter
      fcounter
    }
  }
`;

const MOC_FAILED = gql`
  query moc_failure(
    $where: RequestsMOCListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    moc_failure(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        request_type
        import_status
        import_err_msg
        for: pers_no {
          full_name
          nokia_id
        }
      }
    }
  }
`;
const MOCFILTERS = gql`
  query mocfilters {
    country {
      country_name
    }
    hrRegion {
      hr_region
    }
    jobGrade {
      job_grade
    }
    incentivePlan {
      incentive_plan_type
      incentive_plan
    }
    organizationUnit1Name {
      organization_unit_1_name
      organization_unit_1
    }
    organizationUnit2Name {
      organization_unit_2_name
      organization_unit_2
    }
    organizationUnit3Name {
      organization_unit_3_name
      organization_unit_3
    }
    organizationUnit4Name {
      organization_unit_4_name
      organization_unit_4
    }
  }
`;
const SAVE_EXPORT_FILTERS = gql`
  mutation saveQuery(
    $where: EmployeesListFilter!
    $template: String!
    $export_status: String!
    $query_name: String!
  ) {
    saveQuery(
      export_sql_query: $where
      template: $template
      export_status: $export_status
      query_name: $query_name
    )
  }
`;

/* Release 3.1 - Starts */
const CHANGE_APPROVER_REQUEST = gql`
mutation changeApproverRequest(
                                $employee_request_id: ID!,
                                $approval_id: Int!,
                                $approval_status: String!,
                                $pre_approver_pers_no: String!,
                                $post_approver_pers_no: String!,
                                $pre_approver_is_active: Boolean!
                                ) 
        {
          changeApproverRequest(
                                employee_request_id: $employee_request_id, 
                                approval_id: $approval_id, 
                                approval_status: $approval_status,
                                pre_approver_pers_no: $pre_approver_pers_no,
                                post_approver_pers_no: $post_approver_pers_no,
                                pre_approver_is_active: $pre_approver_is_active
                                )
        }
`;
const AUDIT_LOGINS = gql`
query auditlogins($where:AuditLoginListFilter ,$offset:Int,$limit: Int, $orderBy: [OrderByInput]){
  auditlogins(where : $where,offset: $offset, limit: $limit, orderBy: $orderBy){
   totalCount
   items {
    Proxy_User
    login_pers_no
    nokia_id
    is_proxy
    login_time
    logout_time
   }
 }
}
`;
const AUDIT_REQUESTS = gql`
query auditrequests($where:AuditRequestListFilter ,$offset:Int,$limit: Int, $orderBy: [OrderByInput]){
  auditrequests(where : $where,offset: $offset, limit: $limit, orderBy: $orderBy){
   totalCount
   items {
    employee_request_id
    requester_pers_no
    # /* Release 3.1 - 4.5 - 145702 - Statrs*/
    Country
    Region
    # /* Release 3.1 - 4.5 - 145702 - Ends*/
    requester_nokia_id
    employee_pers_no
    employee_nokia_id
    request_start_time
    request_status
    request_type
    request_reason
    request_text
    review_rejection_comment
    commenter_pers_no
    commenter_nokia_id
    action_start_time
    action_end_time
    previous_job_family_name
    previous_job_subfamily_name
    previous_job_name
    previous_job_grad
    previous_pay_grade
    previous_fte_annual_salary
    previous_fte_incentive_target_amount
    previous_fte_ttc_annual_salary
    previous_ttc_employee_quartile
    previous_incentive_plan_type
    ttc_increase_amount
    ttc_increase_percentage
    promoted
    promotion_job_family_name
    promotion_job_subfamily_name
    promotion_job_name
    promotion_job_grade
    promotion_pay_grade
    promotion_job_grade_delta
    promotion_incentive_target_percentage
    demoted
    mandatory_ttc_increase_percentage
    mandatory_ttc_increase_amount
    apply_ti_harmonization
    step_1_abs
    step_1_ita
    step_1_itp
    step_2_abs_gap
    step_2_ita_gap
    new_fte_annual_salary
    new_fte_incentive_target_amount
    new_incentive_target_percentag
    new_fte_ttc_annual_salary
    new_quartile
    new_incentive_plan_type
    currency
    offcycle_budget_update
    target_budget_org
    new_itp_situation
    new_position_title
    effective_date
    payment_date
    new_ttc_compa_ratio
    payment_type
    payment_reason
    override_manager_proposal
    override_fte_annual_salary
    override_fte_incentive_target_amount
    override_promotion_increase
    override_ti_harmonization
    override_fte_ttc_annual_salary
    is_proxy
    Proxy_Admin_User
    Proxy_User
    # /* Release 3.1 - 4.5 - 145643 - Starts */
    rr_number
    # /* Release 3.1 - 4.5 - 145643 - Ends */
   }
 }
}
`;
const AUDIT_APPROVALS = gql`
query auditapprovals($where:AuditApprovalListFilter ,$offset:Int,$limit: Int, $orderBy: [OrderByInput]){
  auditapprovals(where : $where,offset: $offset, limit: $limit, orderBy: $orderBy){
   totalCount
   items {
    employee_request_id
    requester_pers_no
    requester_nokia_id
    employee_pers_no
    employee_nokia_id
    request_start_time
    request_final_status
    request_type
    request_reason
    request_text
    approver_pers_no
    approver_nokia_id
    approver_level
    approver_type
    approval_status
    action_start_time
    action_end_time
    is_proxy
    Proxy_Admin_User
    Proxy_User    
    # /* Release 3.1 - 4.5 - 145643 - Starts */
    rr_number
    on_behalf_of_nokia_id
    # /* Release 3.1 - 4.5 - 145643 - Ends */
   }
 }
}
`;
const TAPUSERS_ADMIN = gql`
  query tapUsersForAdmin(
    $where: AdminTapUsersListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    tapUsersForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        email_work
        is_rtap
        is_gtap
        rtap_regions
        countries_include
        countries_exclude
        upd_ins_ts
        upd_ins_by
      }
    }
  }
`;

/* DEM0065375 - External Users Access Starts  */
const EXTERNALTAPUSERS_ADMIN = gql`
  query externalTapUsersForAdmin(
    $where: AdminTapUsersListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    externalTapUsersForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        email_work
        is_rtap
        is_gtap
        rtap_regions
        upd_ins_ts
        upd_ins_by
        # /* DEM0070926 Starts */
        is_subcontractor
        # /* DEM0070926 Ends */
      }
    }
  }
`;
/* DEM0065375 - External Users Access Ends  */

const HRBPUSERS_ADMIN = gql`
  query orgHRBPUsersForAdmin(
    $where: AdminOrgHRBPUsersListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    orgHRBPUsersForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        ORG_ID_1
        # /* Release 3.1 - 4.6 - 145707 - Starts */
        ORG1_HRBP_nokiaid
        # /* Release 3.1 - 4.6 - 145707 - Ends */
        ORG_ID_2
        # /* Release 3.1 - 4.6 - 145707 - Starts */
        ORG2_HRBP_nokiaid
        # /* Release 3.1 - 4.6 - 145707 - Ends */
        ORG_ID_3
        # /* Release 3.1 - 4.6 - 145707 - Starts */
        ORG3_HRBP_nokiaid
        # /* Release 3.1 - 4.6 - 145707 - Ends */
        ORG1_HRBP_persno
        ORG2_HRBP_persno
        ORG3_HRBP_persno
        upd_ins_by
        upd_ins_ts
      }
    }
  }
`;

const ADMINUSERS_ADMIN = gql`
  query orgAdminUsersForAdmin(
    $where: AdminOrgAdminUsersListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    orgAdminUsersForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        email_work
        upd_ins_by
        upd_ins_ts
      }
    }
  }
`;

const GCTUSERS_ADMIN = gql`
query gctUsersForAdmin(
  $where: AdminGCTUsersListFilter
  $offset: Int
  $limit: Int
  $orderBy: [OrderByInput]
) {
  gctUsersForAdmin(
    where: $where
    offset: $offset
    limit: $limit
    orderBy: $orderBy
  ) {
    totalCount
    items {
      id
      email_work
      upd_ins_by
      upd_ins_ts
    }
  }
}
`;
/* Release 3.2 - 10.1 - Starts */
const RBCBUSERS_ADMIN = gql`
query orgRBCBUsersForAdmin(
  $where: AdminOrgRBCBUsersListFilter
  $offset: Int
  $limit: Int
  $orderBy: [OrderByInput]
) {
  orgRBCBUsersForAdmin(
    where: $where
    offset: $offset
    limit: $limit
    orderBy: $orderBy
  ) {
    totalCount
    items {
      id
      email_work
      hr_region
      upd_ins_by
      upd_ins_ts
    }
  }
}
`;
/* Release 3.2 - 10.1 - Ends */
/* Release 3.2 - 10.4 - Starts */
const KEYUSERS_ADMIN = gql`
query orgKeyUsersForAdmin(
  $where: AdminOrgKeyUsersListFilter
  $offset: Int
  $limit: Int
  $orderBy: [OrderByInput]
) {
  orgKeyUsersForAdmin(
    where: $where
    offset: $offset
    limit: $limit
    orderBy: $orderBy
  ) {
    totalCount
    items {
      id
      email_work
      upd_ins_by
      upd_ins_ts
    }
  }
}`;
/* Release 3.2 - 10.4 - Ends */
const HR_CONNECTION_ADMIN = gql`
query hrConnectionForAdmin(
  $where: AdminHRConnectListFilter
  $offset: Int
  $limit: Int
  $orderBy: [OrderByInput]
) {
  hrConnectionForAdmin(
    where: $where
    offset: $offset
    limit: $limit
    orderBy: $orderBy
  ) {
    totalCount
    items {
      id
      nokia_id
      country_name
      upd_ins_by
      upd_ins_ts
      # /* Release 3.1 - 4.6 - 145709 - Starts */
      full_name
      # /* Release 3.1 - 4.6 - 145709 - Ends */
    }
  }
}
`;
/* Release 3.1 - Ends */

const CANCEL_APPROVED_REQUEST_WITH_BUDGET = gql`
  mutation CancelApprovedRequest($employee_request_id: ID!, $comment: String!) {
    cancelApprovedRequest(
      employee_request_id: $employee_request_id
      comment: $comment
    )
  }
`;

const GET_LOCKDOWNTOOL_LIST = gql`
  query getLDT {
    getLDT {
      id
      content
      is_active
      upd_ins_ts
    }
  }
`;

const UPDATE_LOCKDOWNTOOL_INFO = gql`
  mutation updateLDT(
    $id: String!
    $content: String!
    $is_active: Boolean!
  ) {
    updateLDT(id: $id, content: $content, is_active: $is_active)
  }
`;

const SEND_REMINDER_REQUEST = gql`
  query sendReminderAPI {
    sendReminderAPI
  }
`;
/* Release 3.1 - Ends */
/* Release 3.1 - 4.5 - 145699 - Starts */
/* DEM0069252 - Added new field Oracle_Assignment_Number */
const ONETIME_OCC_TO_P24_EXPORT = gql`
query onetTimeOCCToP24Export($where:OnetTimeOCCToP24ExportFilter ,$offset:Int,$limit: Int, $orderBy: [OrderByInput]){
  onetTimeOCCToP24Export(where : $where,offset: $offset, limit: $limit, orderBy: $orderBy){
   totalCount
   items {
    Personnel_Number
    Oracle_Assignment_Number
    Subtype
    Wage_Type_Amount_for_Payments
    Currency_Key
    Number
    Time_Measurement_Unit_Text
    Start_Date
    Accounting_period_default_date_of_origin
    Accounting_year_default_date_of_origin
    Assignment_Number
    Reason_for_Changing_Master_Data
    Company_Code
    Employee_Group
    Employee_Subgroup
    upd_ins_ts
    geo_region
    status
   }
 }
}
`;
/* DEM0069252 - Added new field Oracle_Assignment_Number, Current_Job_Profile_Abbr, Proposed_Job_Profile_Abbr */
const COMPENSATION_OCC_TO_P24_EXPORT = gql`
query compensationOCCToP24Export($where:CompensationOCCToP24ExportFilter ,$offset:Int,$limit: Int, $orderBy: [OrderByInput]){
  compensationOCCToP24Export(where : $where,offset: $offset, limit: $limit, orderBy: $orderBy){
   totalCount
   items {
    First_Name
    Last_Name
    Nokia_ID
    Pers_No
    Oracle_Assignment_Number
    Country
    Region
    Effective_Date
    Reason_Code
    Current_Job_Family
    Current_Job_SubFamily
    Current_Job
    Current_Job_ID
    Current_Job_Profile_Abbr
    Incentive_Plan_Type
    Current_Nokia_Job_Grade
    Current_Position_Title
    Employment_FTE_at_time_of_Submission
    Local_Currency
    Current_Annual_Base_Salary_Full_Time_Equivalent
    Current_Incentive_Target_Amoun_Full_Time_Equivalent
    Current_Incentive_Target
    Additional_Annual_Payment_Full_Time_Equivalent
    Current_Total_Target_Cash_Full_Time_Equivalent
    Proposed_Increment
    Promoted
    Proposed_New_Job_Family
    Proposed_New_Job_Subfamily
    Proposed_New_Job
    Proposed_New_Job_ID
    Proposed_Job_Profile_Abbr
    Proposed_New_Nokia_Job_Grade
    Proposed_New_Title_Mandatory_in_case_of_promotion_max_40_char
    Is_TI_Harmonization_Rule_Applied
    New_Annual_Base_Salary_Full_Time_Equivalent
    New_Incentive_Target_Amount_Ful_Time_Equivalent
    New_Incentive_Target
    Additional_Annual_Payment_Full_Time_Equivalent_2
    New_Total_Target_Cash_Full_Time_Equivalent
    New_Annual_Base_Salary_FTE_Prorated
    New_Incentive_Target_Amount_FTE_Prorated
    Additional_Annual_Payment_FTE_Prorated
    New_Total_Target_Cash_FTE_Prorated
    Comments
    Company_Code
    Employee_Group
    Employee_Subgroup
    Job_Family_Code
    Job_Sub_Family_Code
    upd_ins_ts
    geo_region
    status
   }
 }
}
`;

/* Release 3.1 - 4.5 - 145699 - Ends */

/* DEM0073149 - Report Updation Starts */
const COMPENSATION_OCC_TO_P24_EXPORT_HRKeyUser = gql`
query compensationOCCToP24ExportHRKeyUser($where:CompensationOCCToP24ExportHRKeyUserFilter ,$offset:Int,$limit: Int, $orderBy: [OrderByInput]){
  compensationOCCToP24ExportHRKeyUser(where : $where,offset: $offset, limit: $limit, orderBy: $orderBy){
   totalCount
   items {
    First_Name
    Last_Name
    Nokia_ID
    Pers_No
    Oracle_Assignment_Number
    Country
    Region
    Effective_Date
    Reason_Code
    Current_Job_Family
    Current_Job_SubFamily
    Current_Job
    Current_Job_ID
    Current_Job_Profile_Abbr
    Incentive_Plan_Type
    Current_Nokia_Job_Grade
    Current_Position_Title
    Employment_FTE_at_time_of_Submission
    Local_Currency
    Current_Annual_Base_Salary_Full_Time_Equivalent
    Current_Incentive_Target_Amoun_Full_Time_Equivalent
    Current_Incentive_Target
    Additional_Annual_Payment_Full_Time_Equivalent
    Current_Total_Target_Cash_Full_Time_Equivalent
    Proposed_Increment
    Promoted
    Proposed_New_Job_Family
    Proposed_New_Job_Subfamily
    Proposed_New_Job
    Proposed_New_Job_ID
    Proposed_Job_Profile_Abbr
    Proposed_New_Nokia_Job_Grade
    Proposed_New_Title_Mandatory_in_case_of_promotion_max_40_char
    Is_TI_Harmonization_Rule_Applied
    New_Annual_Base_Salary_Full_Time_Equivalent
    New_Incentive_Target_Amount_Ful_Time_Equivalent
    New_Incentive_Target
    Additional_Annual_Payment_Full_Time_Equivalent_2
    New_Total_Target_Cash_Full_Time_Equivalent
    New_Annual_Base_Salary_FTE_Prorated
    New_Incentive_Target_Amount_FTE_Prorated
    Additional_Annual_Payment_FTE_Prorated
    New_Total_Target_Cash_FTE_Prorated
    Comments
    Company_Code
    Employee_Group
    Employee_Subgroup
    Job_Family_Code
    Job_Sub_Family_Code
    upd_ins_ts
    geo_region
    status
   }
 }
}
`;

const ONETIME_OCC_TO_P24_EXPORT_HRKeyUser = gql`
query onetTimeOCCToP24ExportHRKeyUser($where:OnetTimeOCCToP24ExportHRKeyUserFilter ,$offset:Int,$limit: Int, $orderBy: [OrderByInput]){
  onetTimeOCCToP24ExportHRKeyUser(where : $where,offset: $offset, limit: $limit, orderBy: $orderBy){
   totalCount
   items {
    Personnel_Number
    Oracle_Assignment_Number
    Subtype
    Wage_Type_Amount_for_Payments
    Currency_Key
    Number
    Time_Measurement_Unit_Text
    Start_Date
    Accounting_period_default_date_of_origin
    Accounting_year_default_date_of_origin
    Assignment_Number
    Reason_for_Changing_Master_Data
    Company_Code
    Employee_Group
    Employee_Subgroup
    upd_ins_ts
    geo_region
    status
   }
 }
}
`;

/* DEM0073149 - Report Updation Ends */
/* Release 3.2 - 8.1 - Starts */
const EMPLOYEEDASHBOARDHISTORYLIST = gql`
  query employeeDahboardHistoryList(
    $where: EmployeeDahboardHistoryListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    employeeDahboardHistoryList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        pers_no
        nokia_id
        requisition_id
        full_name
        email_work
        organization_unit
        organization_unit_name
        upi_id
        line_manager_pers_no
        line_manager_full_name
        most_recent_ASR_increase
        date_of_latest_position_change   
        country_name 
      }
    }
  }
`;

const ADDRESSCURRENTHISTORYLIST = gql`
  query addresscurrentHistoryList(
    $where: AddresscurrentHistoryListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    addresscurrentHistoryList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
      pers_no
      ,hc_rls_group
      ,country
      ,country_name
      ,city
      ,building_id
      ,building_name
      ,address_country_name
      ,work_state_province
      ,addresses_start_date
      ,addresses_end_date
      ,address_changed_on
      ,street_and_house_num
      ,bldg_floor
      ,address_record_type
      ,address_country
      ,postal_code
      ,upd_ins_ts
      ,state_fullname
      }
    }
  }
`;
/* Start work DEM0069360:Add additional data for TAP users Part 2 */ 
const HCRLSGROUPHISTORYLIST = gql`
  query hcrlsGroupHistoryList(
    $where: HcrlsGroupHistoryListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    hcrlsGroupHistoryList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        Pers_No
        hc_rls_group
      }
    }
  }
`;
 /* End work DEM0069360:Add additional data for TAP users Part 2 */ 
 /* Start work DEM0069360:Add additional data for TAP users Part 2 */ 
const JOBGRADEHISTORYLIST = gql`
  query jobGradeHistoryList(
    $where: JobGradeHistoryListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    jobGradeHistoryList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        start_date
        end_date
        country_name
        job_grade
        job_family_name
        job_subfamily_name
        job_profile_name
        job_id
        hc_rls_group
      }
    }
  }
`;
/* End work DEM0069360:Add additional data for TAP users Part 2 */
/* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
const LATESTINFORMATIONLIST = gql`
  query latestInformationList(
    $where: LatestInformationListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    latestInformationList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        employment_status
        headcount_status
        EE_group_name
        DT01_nokia_start
        Contract_type_name
        Annual_working_hours
        Headcount_status_name
        Employment_status_name
        Daily_working_hours
        Hours_per_week
        Monthly_hours
        Working_time_start_date
        Weekly_workdays
        Work_Week
        Work_schedule_rule,
        Contract_end_date
      }
    }
  }
`;
const  ACTIONSIT0000LIST = gql`
  query actionsIT0000List(
    $where: ActionsIT0000ListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    actionsIT0000List(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        Date_From
        Date_To
        Action_type_name
        Action_reason_name
      }
    }
  }
`;
const REFERRALAWARDLIST= gql`
  query referralAwardList(
    $where: ReferralAwardListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    referralAwardList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        Addl_Pay_Amount
        Addl_Pay_Currency
        Addl_Pay_Date_of_Origin
        Assignment_No
        Reason_for_Addl_Pay
        Reason_for_Addl_Pay_Text
        Addl_Pay_Wage_Type
      }
    }
  }
`;
const ORGASSIGNMENTIT0001LIST= gql`
  query orgAssignmentIT0001List(
    $where: OrgAssignmentIT0001ListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    orgAssignmentIT0001List(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        Action_start_date
        Cost_center
        Position_name
        Position
        job_name
        job_id
        Org_Unit
        Org_Unit_Name
      }
    }
  }
`;

/* END DEM0069362:Add additional data for TAP users - Part 1 Start */
const TARGETINCENTIVEHISTORYLIST = gql`
  query targetIncentiveHistoryList(
    $where: TargetIncentiveHistoryListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    targetIncentiveHistoryList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        start_date
        end_date
        incentive_target_amount
        incentive_target_percentage
        currency
        # /* Release 3.2 - 8.1 - TC - 149310 - Starts */
        incentive_plan_type
        # /* Release 3.2 - 8.1 - TC - 149310 - Ends */
      }
    }
  }
`;

const ORGANIZATIONALHISTORYLIST = gql`
  query organizationalHistoryList(
    $where: OrganizationalHistoryListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    organizationalHistoryList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        start_date
        end_date
        org_unit
        org_unit_name
        line_mgr_n_nokia_id
        line_mgr_full_name
      }
    }
  }
`;
/* Start work DEM0069360:Add additional data for TAP users Part 2 */ 
const SALARYHISTORYLIST = gql`
  query salaryHistoryList(
    $where: SalaryHistoryListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    salaryHistoryList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        start_date
        end_date
        country_name
        annual_base_salary
        fte_ttc_annual_salary
        fte_annual_salary
        currency
        pay_grade
        reason_for_pay_change
        FTE_Addl_TTC_Amount
        TTC_Employee_Quartile
        TTC_Compa_Ratio
        Pay_Period_salary_wage_amount_1
        Pay_Period_salary_wage_name_1
      }
    }
  }
`;
/* End work DEM0069360:Add additional data for TAP users Part 2 */ 
const PERFORMANCEHISTORYLIST = gql`
  query performanceHistoryList(
    $where: PerformanceHistoryListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    performanceHistoryList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        country_name
        evaluation_code
        evaluatuion_type
        osc_rating
        evaluation_manager
        evaluations_changed_on
        evaluation_source
        evaluation_date
        evaluation_period_start_date
        evaluation_period_end_date
        evaluation_changed_by
      }
    }
  }
`;

const ACTUALPAIDINCENTIVEHISTORYLIST = gql`
  query actualPaidIncentiveHistoryList(
    $where: ActualPaidIncentiveHistoryListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    actualPaidIncentiveHistoryList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        paid_date
        addl_pay_amount
        currency
        wage_type
        incentive_name
      }
    }
  }
`;
/* Release 3.2 - 8.1 - Ends */
/* Release 3.2 - 7.1 - Starts */
const REQUESTSFOREXCELEXPORT = gql`
  query Requests(
    $where: RequestsListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    requests(where: $where, offset: $offset, limit: $limit, orderBy: $orderBy) {
      totalCount
      items {
        employee_request_id
        request_description
        request_type
        request_country
        ttc_increase_amount
        status
        reject_comments
        currency
        ttc_increase_percentage
        new_fte_annual_salary
        new_fte_incentive_target_amount
        new_incentive_target_percentage
        new_itp_situation
        new_fte_ttc_annual_salary
        new_quartile
        new_incentive_plan_type
        interim_job_grade
        created_ts
        upd_ins_ts
        promotion_job_family_name
        promotion_job_subfamily_name
        promotion_job_name
        promotion_job_grade
        new_position_title
        payment_date
        effective_date
        new_ttc_compa_ratio
        payment_type
        offcycle_budget_update
        mandatory_ttc_increase_amount
        mandatory_ttc_increase_percentage
        rr_number
        promotional_increase_percentage_guideline
        case_3_12_month_actual_incentive_amount_fte
        override_transition_allowance_local_currency
        hiring_manager
        is_sip
        sip_case
        Final_Off_Cycle_Budget
        current_combo_situation
        promo_increase_pct_guideline_min
        promo_increase_pct_guideline_max
        internalhire_off_cycle_spending
        internal_hire_off_cycle_spending_euro
        Case_One_Max_Budget_calc
        Case_one_TTC_increase_minus_Max_budget
        Case_one_Budget_charge
        Case_two_TI_Increase
        Case_two_Promotion_Budget
        Case_two_Total_budget
        Case_two_Budget_charge
        Case_three_twelve_month_Total_Target_Cash
        Case_three_new_TTC_Gap_to_twelve_month_TTC
        Case_three_Max_capped_transit_allowance
        Case_three_Transition_allowance_total
        Case_three_Budget_charge
        hmanager: hm {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          email_work
          organization_unit_name
          organization_unit_1_name          
          # /* Release 3.2 - 10.3 Starts */
          organization_unit_2_name
          # /* Release 3.2 - 10.3 Ends */
        }
        for: pers_no {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          country_name
          fte_ttc_annual_salary
          fte_annual_salary
          fte_incentive_target_amount
          fte_incentive_target_percentage
          current_theo_itp
          current_itp_situation
          fte_addl_ttc_amount
          ttc_employee_quartile
          job_family_name
          job_subfamily_name
          job_name
          position_title
          job_grade
          country
          pers_start_date
          pay_scale_area
          salary_range_min: ttc_salary_range_min
          salary_range_mid: ttc_salary_range_mid
          salary_range_max: ttc_salary_range_max
          pay_grade
          incentive_plan_type
          ttc_compa_ratio
          organization_unit_2_name
          organization_unit_1_name
          organization_unit_name
        }
        by: requester_pers_no {
          pers_no
          first_name
          last_name
          full_name
          nokia_id
          job_family_name
        }
        approvals {
          employee_request_id
          approval_status
          approver_level
          approver {
            full_name
          }
          on_behalf_of_approver {
            full_name
          }
        }
        comments {
          pre_status
          post_status
          description
          commenter_pers_no
          upd_ins_ts
          commenter {
            pers_no
            first_name
            last_name
            full_name
            nokia_id
            job_family_name
          }
        }
        budget_allocations {
          request_id
          amount
          organization_unit
          organization_unit_name
          organization_unit_level
          upd_ins_ts
        }
        changeApprovers {
          pre_approver_pers_no
          post_approver_pers_no
          pre_approver_is_active 
          upd_ins_pers_no
          upd_ins_ts
          changer {
            pers_no
            full_name
          }
          preApprover {
            pers_no
            full_name
          }
          postApprover {
            pers_no
            full_name
          }
          approval {
            id
            employee_request_id
            approver_level
          }
        }
        # /* Release 3.2 - 11.2 -Starts */
        reminder_info {
          last_reminder_date
          reminder_count
        }
        # /* Release 3.2 - 11.2 -Ends */
        # /* Release 3.1 - 1.1 - Starts */
        award_title
        award_message
        award_privacy
        award_reason
        nominator_email_address
        # /* Release 3.1 - 1.1 - Ends */
      }
    }
  }
`;
/* Release 3.2 - 7.1 - Ends */
/* Release 3.2 - 9.1 - Starts */
const DELETESIMULATIONS = gql`
mutation deleteRequestSimulations(
  $employee_request_simulation_id: Int!
) {
  deleteRequestSimulations(employee_request_simulation_id: $employee_request_simulation_id)
}`;
/* Release 3.2 - 9.1 - Ends */

/* Release 3.2 - 1.1 - Starts */
const BUSINNESSOWNERS_ADMIN = gql`
  query orgBusinessOwnersForAdmin(
    $where: OrgBusinessOwnersFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    orgBusinessOwnersForAdmin(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        id
        email_work
        upd_ins_by
        upd_ins_ts
      }
    }
  }
`;
const GET_BEA_BUDGET = gql`
  query organizationBEABudget(
    $where: BEABudgetForOrgListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    organizationBEABudget(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        budget_id     
        year
        description
        capital_budget
        budget
        budget_in_progress
        budget_spent
        budget_available
        organization_unit
        organization_unit_name
        upd_ins_pers_no
        upd_ins_full_name
        upd_ins_ts
      }
    }
  }
`;
const GET_BG_ORGANIZATION = gql`
  query bgOrganizations {
    bgOrganizations {
      organization_unit
      organization_unit_name
    }
  }
`;

const BEA_BUDGET_ORGANIZATION = gql`
  query beaBudgetOrganization($organization_unit: String!, $year: String!) {
    beaBudgetOrganization(organization_unit: $organization_unit, year: $year) {
      budget_id  
      year
      description
      capital_budget
      budget
      budget_in_progress
      budget_spent
      organization_unit
      organization_unit_name
    }
  }
`;

const CREATE_BEA_BUDGET = gql`
  mutation createBEABudget($data: createBEABudgetData!) {
    createBEABudget(data: $data)
  }
`;

const UPDATE_BEA_BUDGET = gql`
  mutation updateBEABudget($budget_id: ID!, $data: updateBEABudgetData!) {
    updateBEABudget(budget_id: $budget_id, data: $data)
  }
`;

const BUSINESS_OWNER_SUGGEST = gql`
  query businessOwner_people($where: EmployeeListFilter, $offset: Int, $limit: Int) {
    businessOwner_people(where: $where, offset: $offset, limit: $limit) {
      totalCount
      items {
        pers_no
        first_name
        full_name
        email_work
        nokia_id
      }
    }
  }
`;

const GET_BEA_BUSINESS_OWNER_BUDGET = gql`
  query organizationBEABusinessOwnerBudget(
    $where: BEABusinessOwnerBudgetForOrgListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    organizationBEABusinessOwnerBudget(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        bo_budget_id   
        budget_id
        pers_no
        full_name
        email_work
        nokia_id
        merge_full_name
        currency
        year
        organization_unit
        organization_unit_name
        capital_budget
        budget
        budget_available
        budget_in_progress
        budget_spent
        bo_budget
        bo_budget_in_progress
        bo_budget_spent
        bo_budget_available
        upd_ins_pers_no
        upd_ins_full_name
        upd_ins_ts
      }
    }
  }
`;

const CREATE_BEA_BUSINESS_OWNER_BUDGET = gql`
  mutation createBEABusinessOwnerBudget($data: createBEABusinessOwnerBudgetData!) {
    createBEABusinessOwnerBudget(data: $data)
  }
`;

const UPDATE_BEA_BUSINESS_OWNER_BUDGET = gql`
  mutation updateBEABusinessOwnerBudget($bo_budget_id: ID!, $data: updateBEABusinessOwnerBudgetData!) {
    updateBEABusinessOwnerBudget(bo_budget_id: $bo_budget_id, data: $data)
  }
`;
const AUDIT_BEA_REQUESTS = gql`
query auditBEARequests($where:AuditBEARequestsListFilter ,$offset:Int,$limit: Int, $orderBy: [OrderByInput]){
  auditBEARequests(where : $where,offset: $offset, limit: $limit, orderBy: $orderBy){
   totalCount
   items {
    nominator_id
    recipient_id
    approver_id
    award_name
    award_currency
    award_amount
    award_language
    award_message
    dispatch_date
    award_reason
    award_title
   }
 }
}
`;

const DELETE_BEA_BUDGET = gql`
  mutation deleteBEABidget($budget_id: Int!) {
    deleteBEABidget(budget_id: $budget_id)
  }
`;

const DELETE_BUSINESS_OWNER_BUDGET = gql`
  mutation deleteBEABusinessOwnerBudget($bo_budget_id: Int!) {
    deleteBEABusinessOwnerBudget(bo_budget_id: $bo_budget_id)
  }
`;
/* Release 3.2 - 1.1 - Ends */
/* Release 3.3 - 8.3 - Starts */
const ROCOUNTRYSTATISTICS = gql`
  query roCountryStatisticsList(
    $where: ROCountryStatisticsListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    roCountryStatisticsList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        subtype
        date_from
        date_to
        occup_code
        occup_code_description
      }
    }
  }
`;
const ROINSURANCETAXDATA = gql`
  query roInsuranceTaxDataList(
    $where: ROInsuranceTaxDataListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    roInsuranceTaxDataList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {    
        pers_no
        date_from
        date_to
        contract_type_ins_unemployment
        number_of_hours
        assured_type
        ass_type_er_contr_exempt
        reduc_deduc_amount
        contract_type_ins_pension
        partial_time
        retired_ee
        work_condition
        norma
        contract_type_ins_health
        health_house_id
        tax_free_amount
        stop_personal_tax_free
        tax_calculate
        tax_exemption_for_handicapp
        tax_exemption_for_programmers
        tax_exemption_for_researchers
        tax_office
        tax_office_name
      }
    }
  }
`;
const FRLOCALPAYATTRIBUTES = gql`
  query frLocalPayAttributesList(
    $where: FRLocalPayAttributesListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    frLocalPayAttributesList(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {  
        pers_no
        date_from
        date_to
        comp_pay_scl_type
        capacity_utilization
        comp_pay_scl_area
        working_hours_per_payroll_period
        comp_pay_scl_grp
        comp_pay_scl_lev
        currency
        france_salary
        wage_type1_text
        wage_type1_amount
        wage_type2_text
        wage_type2_amount
        wage_type3_text
        wage_type3_amount
        wage_type4_text
        wage_type4_amount
      }
    }
  }
`;
/* Release 3.3. 8.3 - Ends */

/*  DEM0067988 - TAP Access based on requisition Starts */
const USERS_FOR_TAP_REQUISITION = gql`
  query employeesForTapRequisition(
    $where: EmployeesListFilter
    $offset: Int
    $limit: Int
    $orderBy: [OrderByInput]
  ) {
    employeesForTapRequisition(
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      items {
        pers_no
        first_name
        last_name
        full_name
        nokia_id
        employment_status
        country_name
        country
        hr_region
        city
        email_work
        is_line_manager
        line_manager_pers_no
        pers_start_date
        fte_ttc_annual_salary
        fte_annual_salary
        ttc_employee_quartile
        fte_incentive_target_amount
        fte_incentive_target_percentage
        current_theo_itp
        current_itp_situation
        fte_addl_ttc_amount
        ttc_compa_ratio
        incentive_plan_type
        currency
        job_id
        job_level
        job_grade
        job_name
        job_profile_id
        job_family_name
        job_subfamily_name
        position_title
        pay_scale_area
        salary_range_min: ttc_salary_range_min
        salary_range_mid: ttc_salary_range_mid
        salary_range_max: ttc_salary_range_max
        pay_grade
        organization_unit
        organization_unit_name
        organization_unit_obbrev
        organization_unit_1
        organization_unit_1_name
        organization_unit_2
        organization_unit_2_name
        organization_unit_3
        organization_unit_3_name
        organization_unit_4
        organization_unit_4_name
        # /* Release 3.2 - 13.1 -Starts */
        lastYearWageHistory {
          pers_no
          addl_pay_amount
          currency
        }
        sum_of_pay_amount
        # /* Release 3.2 - 13.1 -Ends */
        # /* Release 3.2 - 13.1 - 149299 - Starts */
        case_3_12_month_actual_incentive_amount_fte: fte_annual_salary
        # /* Release 3.2 - 13.1 - 149299 - Ends */
        #/*  DEM0065665 - Effective date Starts */
        company_code
        geo_region
        #/*  DEM0065665 - Effective date Ends */
        region_cb_pers_no
        business_cb_pers_no
        hrbp_n1_pers_no
        hrbp_n2_pers_no
        hrbp_n3_pers_no
        requisition_id
      }
    }
  }
`;
/*  DEM0067988 - TAP Access based on requisition Ends */

/*  DEM0069361 - TAP Access Part 3 Starts */
const RO_EDUCATIONS = gql`
query roEducationList(
  $where: ROEducationListFilter
  $offset: Int
  $limit: Int
  $orderBy: [OrderByInput]
) {
  roEducationList(
    where: $where
    offset: $offset
    limit: $limit
    orderBy: $orderBy
  ) {
    totalCount
    items {
      pers_no
      hc_rls_group
      country
      country_name
      certificate
      educational_establishment
      branch_of_study
      batch_id
      execution_id
      certificate_code_text
      education_code_text
    }
  }
}
`;
/*  DEM0069361 - TAP Access Part 3 Ends */

export {
  USERS_FOR_MANAGER as employeesForManager,
  /*  DEM0067988 - TAP Access based on requisition Starts */
  USERS_FOR_TAP_REQUISITION as employeesForTapRequisition,
  /*  DEM0067988 - TAP Access based on requisition Ends */
  
  /*  DEM0066861 - Regional BCB role Starts */
  USERS_FOR_RBCB as employeesForRBCB,
  /*  DEM0066861 - Regional BCB role Ends */
  USERS_FOR_TAP as employeesForTap,
  BUDGET_FOR_ADMIN as budgetsForAdmin,
  USERS_FOR_HR as employeesForHr,
  USERS_FOR_ADMIN as employees,
  MANAGER_DIRECT_ORDINATES as managerDirectSubOrdinates,
  MANAGER_SECOND_DIRECT_ORDINATES as managerSecondSubOrdinates,
  BUDGET,
  BUDGETS,
  REQUESTS as requests,
  REQUESTALLHRC as requestallhrc,
  PENDING_REQUESTS as approvals,
  CREATE_REQUEST_WITH_BUDGET,
  OVERRIDE_REQUEST_WITH_BUDGET,
  EDIT_REQUEST,
  DELETE_REQUEST,
  PEOPLE_SUGGEST,
  HM_SUGGEST,
  ORG_SUGGEST,
  PARENT_BUDGET,
  UPDATE_BUDGET_FROM_REQUEST_DELETED,
  CANCEL_REQUEST_WITH_BUDGET,
  REVIEW_REQUEST,
  REJECT_REQUEST,
  APPROVE_REQUEST,
  GPP_LOOKUP_TABLE,
  ITP_LOOKUP_TABLE,
  EXCHANGE_RATES,
  JOBS,
  CONFIG,
  SALARY_RANGE,
  AUTHORIZE,
  /* Create Budget */
  CREATE_BUDGET,
  EDIT_BUDGET,
  /**Release 2 - Admin Table */
  GPP_ADMIN as gppsForAdmin,
  ITP_ADMIN as itpsForAdmin,
  INC_ADMIN as incForAdmin,
  CON_ADMIN as conForAdmin,
  /*  FIXING DELEGATION ISSUE */
  ALL_DELEGATES,
  DELEGATES_DETAILS as dd,
  DELEGATES_FOR_MANAGER as delegatesForManager,
  CREATE_DELEGATE,
  EDIT_DELEGATION,
  ALL_DELEGATES_WITH_SEARCH as delegationListWithSearch,
  DELEGATES_ROLES_WITH_PERS as delegateRolesWithPers,
  /**Release 2 - Admin Tables */
  UPLOAD_FILE,
  GET_FORMULAS, // Added for admin calculations release R2
  UPDATE_FORMULA, // Added for admin calculations release R2
  GET_FORMULAS_META_INFO, // Added for admin calculations release R2
  /*R3 Wave3 MOC start*/
  MOCREQUESTS as moc_requests,
  MOC_UPLOAD,
  MOC_FAILED as moc_failure,
  MOC_EDIT as moc_edit,
  MOCFILTERS,
  MANAGERS_SUGGEST,
  SAVE_EXPORT_FILTERS,
  /*R3 Wave3 MOC end*/

  /* Release 3.1 - Starts */
  CHANGE_APPROVER_REQUEST,
  CANCEL_APPROVED_REQUEST_WITH_BUDGET,
  GET_LOCKDOWNTOOL_LIST,
  UPDATE_LOCKDOWNTOOL_INFO,
  AUDIT_LOGINS as auditlogins,
  AUDIT_REQUESTS as auditrequests,
  AUDIT_APPROVALS as auditapprovals,
  TAPUSERS_ADMIN as tapUsersForAdmin,
  /* DEM0065375 - External Users Access Starts  */
  EXTERNALTAPUSERS_ADMIN as externalTapUsersForAdmin,
  /* DEM0065375 - External Users Access Ends  */
  HRBPUSERS_ADMIN as orgHRBPUsersForAdmin,
  ADMINUSERS_ADMIN as orgAdminUsersForAdmin,
  GCTUSERS_ADMIN as gctUsersForAdmin,
  HR_CONNECTION_ADMIN as hrConnectionForAdmin,
  SEND_REMINDER_REQUEST as sendReminderAPI,
  /* Release 3.2 - 10.1 - Starts */
  RBCBUSERS_ADMIN as orgRBCBUsersForAdmin,
  /* Release 3.2 - 10.1 - Ends */
  /* Release 3.2 - 10.4 - Starts */
  KEYUSERS_ADMIN as orgKeyUsersForAdmin,
  /* Release 3.2 - 10.4 - Ends */
  /* Release 3.1 - Ends */
 /* Release 3.1 - 4.5 - 145699 - Starts */
  ONETIME_OCC_TO_P24_EXPORT as onetTimeOCCToP24Export,
  COMPENSATION_OCC_TO_P24_EXPORT as compensationOCCToP24Export,
  /* Release 3.1 - 4.5 - 145699 - Ends */

  /* DEM0073149 - Report Updation Starts */
  ONETIME_OCC_TO_P24_EXPORT_HRKeyUser as onetTimeOCCToP24ExportHRKeyUser,
  COMPENSATION_OCC_TO_P24_EXPORT_HRKeyUser as compensationOCCToP24ExportHRKeyUser,
  /* DEM0073149 - Report Updation Ends */

  /* Release 3.2 - 9.1Req - Starts */
  CREATE_REQUEST_SIMULATION,
  SIMULATIONS,
  DELETESIMULATIONS,
  /* Release 3.2 - 9.1Req - Ends */
  /* Release 3.2 - 8.1 - Starts */
  EMPLOYEEDASHBOARDHISTORYLIST as employeeDahboardHistoryList,
  ACTUALPAIDINCENTIVEHISTORYLIST as actualPaidIncentiveHistoryList,
  PERFORMANCEHISTORYLIST as performanceHistoryList,
  SALARYHISTORYLIST as salaryHistoryList,
  ORGANIZATIONALHISTORYLIST as organizationalHistoryList,
  TARGETINCENTIVEHISTORYLIST as targetIncentiveHistoryList,
  JOBGRADEHISTORYLIST as jobGradeHistoryList,
   /* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
   LATESTINFORMATIONLIST as latestInformationList,
   ACTIONSIT0000LIST as actionsIT0000List,
   REFERRALAWARDLIST as referralAwardList,
   ORGASSIGNMENTIT0001LIST as orgAssignmentIT0001List,
    /* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
   HCRLSGROUPHISTORYLIST as hcrlsGroupHistoryList,
  /* Release 3.2 - 8.1 - Ends */
  /* Release 3.2 - 7.1 - Starts */
  REQUESTSFOREXCELEXPORT,
  /* Release 3.2 - 7.1 - Ends */
  /* Release 3.2 - 1.1 - Starts */
  BUSINNESSOWNERS_ADMIN as orgBusinessOwnersForAdmin,
  GET_BEA_BUDGET as organizationBEABudget,
  GET_BG_ORGANIZATION as bgOrganizations,
  BEA_BUDGET_ORGANIZATION as getBEABudgetBasedOnOrg,
  CREATE_BEA_BUDGET as createBEABudget,
  UPDATE_BEA_BUDGET as updateBEABudget,
  BUSINESS_OWNER_SUGGEST as businessOwnerPeople,
  GET_BEA_BUSINESS_OWNER_BUDGET as organizationBEABusinessOwnerBudget,
  CREATE_BEA_BUSINESS_OWNER_BUDGET as createBEABusinessOwnerBudget,
  UPDATE_BEA_BUSINESS_OWNER_BUDGET as updateBEABusinessOwnerBudget,
  AUDIT_BEA_REQUESTS as auditBEARequests,
  DELETE_BEA_BUDGET as deleteBEABidget,
  DELETE_BUSINESS_OWNER_BUDGET as deleteBEABusinessOwnerBudget,
  /* Release 3.2 - 1.1 - Ends */ 
  /* Release 3.3 - 8.3 - Starts */ 
  ROCOUNTRYSTATISTICS as roCountryStatisticsList,
  ROINSURANCETAXDATA as roInsuranceTaxDataList,
  FRLOCALPAYATTRIBUTES as frLocalPayAttributesList,
  ADDRESSCURRENTHISTORYLIST as addresscurrentHistoryList,
  
  /* Release 3.3 - 8.3 - Ends */ 

  /*  DEM0069361 - TAP Access Part 3 Starts */
  RO_EDUCATIONS as roEducationList
  /*  DEM0069361 - TAP Access Part 3 Ends */

  
};
