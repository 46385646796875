import React, { useState } from "react";

import { UPLOAD_FILE } from "../../graphql";
import { apollouploadClient } from "../../";
import { Mutation, ApolloProvider } from "react-apollo";
/* Release 3.1 - Starts */
import { pushMessage } from "../../actions";
import { connect } from "react-redux";
/* Release 3.1 - Ends */

const divStyle = {
  margin: "0.5%",
  width: "100%",
};
const divFileStatusStyle = {
  margin: "0.5%",
  width: "100%",
  /* Release 3.1 - Starts */
  maxHeight: "200px",
  overflowX: "auto",
  /* Release 3.1 - Ends */
};
export function UploadFile(props) {
  const {
    adtable,
    /* Release 3.1 - Starts */ 
    hideModal,
    pushMessage,
    /* Release 3.1 - Ends */
  } = props;
  /* Release 3.1 - Starts */
  const outputResult = {
    Status: true,
    Message: [{ row: 0, message: "No File Chosen" }],
  };
  const [filestatus, setstatus] = useState(outputResult);
  /* Release 3.1 - Ends */
  const [filecolor, setcolor] = useState("black");
  return (
    <div style={{ width: "100%" }}>
      <div style={divStyle}>
        <ApolloProvider client={apollouploadClient}>
          <Mutation mutation={UPLOAD_FILE}>
            {(singleUpload) => (
              <input
                type="file"
                accept=".csv"
                style={{ width: "100%" }}
                required
                onChange={async ({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => {
                  /* Release 3.1 - Starts */
                  setstatus({
                    Status: true,
                    Message: [
                      {
                        row: 0,
                        message:
                          "File Selected. Upload inprogress. Please wait...",
                      },
                    ],
                  });
                  /* Release 3.1 - Ends */
                  setcolor("#eae412");

                  if (validity.valid) {
                    const result = await singleUpload({
                      variables: { file, adtable },
                    });
                    /* Release 3.1 - Starts */
                    setstatus({
                      Status: result.data.singleUpload.Status,
                      Message: result.data.singleUpload.Message,
                    });
                    /* Release 3.1 - Ends */
                    setcolor(
                      result.data.singleUpload.Status ? "#1ccc07" : "#ea6626"
                    );
                    if (result.data.singleUpload.Status) {
                      /* DEM0065375 - External Users Access Starts  */
                      //console.log(result.data.singleUpload.Message[0].message)
                      var exp_1 = new Array();
                      exp_1 = result.data.singleUpload.Message[0].message.split("_");
                      //console.log(exp_1)
                      /* Release 3.1 - Starts */
                      hideModal();
                      if(exp_1.length > 1)
                      {
                        pushMessage({
                          type: "success",
                          message: exp_1[1],
                        });
                      }
                      else
                      {
                        pushMessage({
                          type: "success",
                          message: "Successfully updated the table.",
                        });
                      }
                      /* DEM0065375 - External Users Access Ends  */
                      /* Release 3.1 - Ends */
                    }
                  }
                }}
              />
            )}
          </Mutation>
        </ApolloProvider>
      </div>
      <div style={divFileStatusStyle}>
        Current Status : {/* Release 3.1 - Starts */}
        {filestatus &&
          filestatus.Message.map((item) => {
            return (
              <>
                {item.row == 0 || (item.row == 1 && <br />)}
                <span key={item.row} style={{ color: filecolor }}>
                  {item.row == 0
                    ? item.message
                    : "Row " + item.row + " : " + item.message}
                </span>
                <br />
              </>
            );
          })}
        {/* Release 3.1 - Ends */}
      </div>
    </div>
  );
  // const [uploadFileMutation] = useMutation(UPLOAD_FILE);
  //const apolloClient = useApolloClient();

  //const onChange = ({
  //  target: {
  //  validity,
  //   files: [file],
  //   },
  //  }) =>{
  //    console.log( process.env.REACT_APP_GRAPHQL_API);
  //     validity.valid &&
  //    Uploadclient.mutate({
  //mutation: UPLOAD_FILE,
  //variables: { file }
  //})
  //.then(resp => console.log('resp'))};
  // uploadFileMutation({ variables: { file } }).then(() => {
  //  apolloClient.resetStore();
  // });

  // return <input type="file" required onChange={onChange} />;
}
/* Release 3.1 - Starts */
const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps, { pushMessage })(UploadFile);
/* Release 3.1 - Ends */
