import { SHOW_MODAL, HIDE_MODAL } from '../actions/index';

const intialState = {
  isVisible: false,
  modalContent: null,
  modalComponent: null
};

export default (state = intialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return Object.assign({}, state, {
        isVisible: true,
        modalContent: action.content,
        modalComponent: action.component
      });
    case HIDE_MODAL:
      return Object.assign({}, state, {
        isVisible: false
      });
    default:
      return state;
  }
};
