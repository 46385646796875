export default (table) => {
  let name = 'country_name';

  return {
    type: "string",
    name,
    label: "Country",
    section: "Current Information",
    visible: true,
    sortable: true,
    width: '100px'
  };
};