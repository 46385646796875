/* Release 3.1 - Starts */
export const effectiveDateEditablecheck = (value, data) => {
    if ((data.request.request_type == 'internalhire' || data.request.request_type == 'compensation' ) && 
        (data.approver_level == data.request.approvals.length)) {
        return false;
    } else {
        return false
    }
};
export const nameInputField = (value, data) => {
    if (
    /* Release 3.2 - 1.1 - Starts */
    data.request.request_type == "onetime" || data.request.request_type == "businessexcellenceaward"
    /* Release 3.2 - 1.1 - Ends */
    ) {
        return "request.payment_date";
    } else {
        return "request.effective_date";
    }
};
/* Release 3.1 - Ends */
/* Release 3.2 - 12.2 - Starts */
export const paymentDateEditablecheck = (value, data) => {
    if (
      data.request.request_type == "onetime" 
        /* Release 3.2 - 1.1 - Starts */ 
       // || data.request.request_type == "businessexcellenceaward"       
        /* Release 3.2 - 1.1 - Ends */ 
        &&
      data.approver_level == data.request.approvals.length
    ) {
      return true;
    } else {
      return false;
    }
};
/* Release 3.2 - 12.2 - Ends */
  