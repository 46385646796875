/*  Release 3.2 - 1.1 - Starts */
import React, { useState, useRef, useEffect } from "react";
import { Ring } from "react-awesome-spinners";
import { connect } from "react-redux";
import { FormButton, FormButtonsWrap, LoaderWrap } from "../..";
import {
  unselectAllRecord,
  pushMessage,
  toggleShowInEur,
} from "../../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheck,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";
import { apolloClient, apolloProxyClient } from "../../../index";
import {
  businessOwnerPeople,
  organizationBEABusinessOwnerBudget,
  createBEABusinessOwnerBudget,
  updateBEABusinessOwnerBudget,
  deleteBEABusinessOwnerBudget,
} from "../../../graphql";
import BusinessOwnerBudgetTable from "./BusinessOwnerBudgetTable";
import { useMutation } from "@apollo/react-hooks";

const BusinessOwnerBudget = (props) => {
  const { hideModal, selectedBudgets, unselectAllRecord, config, account } =
    props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { roles, accessibles } = account;
  const isBCB = roles.includes("bcb");
  const [messages, setMessages] = useState([]);
  const [messageType, setMessageType] = useState("error");
  const [budgetId, setBudgetId] = useState(0.0);
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [selectedOrgUnit, setSelectedOrgUnit] = useState("");
  const [selectedyear, setSelectedYear] = useState(0);
  const [capitalBudget, setCapitalBudget] = useState(0.0);
  const [budget, setBudget] = useState(0.0);
  const [budgetInProgress, setBudgetInProgress] = useState(0.0);
  const [budgetSpent, setBudgetSpent] = useState(0.0);
  const [budgetForecast, setBudgetForecast] = useState(0.0);
  const [budgetAvailable, setBudgetAvailable] = useState(0.0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const boBudgetInput = useRef(null);
  const selectInputRef = useRef();
  const [selectedPersNo, setSelectedPersNo] = useState("");
  const [isBusinessOwnerBudgetDataExist, setIsBusinessOwnerBudgetDataExist] =
    useState(false);
  const [updatedKey, setUpdatedKey] = useState(1);
  const [boBudgetSelected, setBoBudgetSelected] = useState([]);

  const [addBEABusinessOwnerBudget] = useMutation(createBEABusinessOwnerBudget);
  const [editBEABusinessOwnerBudget] = useMutation(updateBEABusinessOwnerBudget);
  const [deleteBusinessOwnerBudget] = useMutation(deleteBEABusinessOwnerBudget);

  useEffect(() => {
    if (selectedBudgets && selectedBudgets.length > 0) {
      const selectedBudgetdata = selectedBudgets[0];
      setBudgetId(selectedBudgetdata.budget_id);
      setSelectedOrgUnit(selectedBudgetdata.organization_unit);
      setSelectedOrgName(selectedBudgetdata.organization_unit_name);
      setSelectedYear(selectedBudgetdata.year);
      setCapitalBudget(
        selectedBudgetdata.capital_budget
          ? selectedBudgetdata.capital_budget
          : 0
      );
      setBudget(selectedBudgetdata.budget ? selectedBudgetdata.budget : 0);
      setBudgetInProgress(
        selectedBudgetdata.budget_in_progress
          ? selectedBudgetdata.budget_in_progress
          : 0
      );
      setBudgetSpent(
        selectedBudgetdata.budget_spent ? selectedBudgetdata.budget_spent : 0
      );
      setBudgetForecast(
        selectedBudgetdata.budget
          ? selectedBudgetdata.budget -
              ((selectedBudgetdata.budget_in_progress
                ? selectedBudgetdata.budget_in_progress
                : 0) +
                (selectedBudgetdata.budget_spent
                  ? selectedBudgetdata.budget_spent
                  : 0))
          : 0
      );
      setBudgetAvailable(
        selectedBudgetdata.budget
          ? selectedBudgetdata.budget -
              (selectedBudgetdata.budget_spent
                ? selectedBudgetdata.budget_spent
                : 0)
          : 0
      );
      if (selectedBudgetdata.budget_id) {
        callGetBusinessOwnersBudgetInfo(selectedBudgetdata.budget_id);
        // setUpdatedKey(Math.floor(Math.random() * 87));
      }
    }
  }, [selectedBudgets]);

  const callGetBusinessOwnersBudgetInfo = async (budgetId) => {
    getBusinessOwnersBudgetInfo(budgetId).then((businessOwnersBudgetInfo) => {
      // console.log("businessOwnersBudgetInfo ", businessOwnersBudgetInfo);
      setIsBusinessOwnerBudgetDataExist(true);
    });
  };

  const getBusinessOwnersBudgetInfo = async (budgetId) => {
    setMessages([]);
    setIsSubmitting(true);
    return await new Promise((resolve) => {
      httpClient
        .query({
          query: organizationBEABusinessOwnerBudget,
          variables: {
            where: {
              budget_id: { eq: budgetId },
            },
          },
        })
        .then((response) => {
          if (
            response.data &&
            response.data.organizationBEABusinessOwnerBudget &&
            response.data.organizationBEABusinessOwnerBudget.items.length > 0
          ) {
            resolve(response.data.organizationBEABusinessOwnerBudget.items);
          }
          setIsSubmitting(false);
        })
        .catch((err) => {
          console.error(err);
          setMessages(["Internal Server Error!"]);
          setMessageType("error");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    })
      .catch((err) => {
        console.error(err);
        setMessages(["Internal Server Error!"]);
        setMessageType("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const checkBOBudgetAlreadyExists = async (selected_pers_no) => {
    setMessages([]);
    setIsSubmitting(true);
    return await new Promise((resolve) => {
      httpClient
        .query({
          query: organizationBEABusinessOwnerBudget,
          variables: {
            where: {
              pers_no: { eq: selected_pers_no },
              year: { eq: selectedyear },
            },
          },
        })
        .then((response) => {
          if (
            response.data &&
            response.data.organizationBEABusinessOwnerBudget &&
            response.data.organizationBEABusinessOwnerBudget.items.length > 0
          ) {
            resolve(response.data.organizationBEABusinessOwnerBudget.items);
          }
          setIsSubmitting(false);
        })
        .catch((err) => {
          console.error(err);
          setMessages(["Internal Server Error!"]);
          setMessageType("error");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    })
      .catch((err) => {
        console.error(err);
        setMessages(["Internal Server Error!"]);
        setMessageType("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const parentDivwithHeight = {
    width: "92%",
    padding: "2px",
    margin: "0 auto",
    height: "400px",
  };
  const parentDiv = {
    width: "92%",
    padding: "2px",
    margin: "0 auto",
  };
  const rowStyle = {
    width: "100%",
    display: "flex",
  };
  const column20Style = {
    fontSize: "15px",
    fontWeight: "400",
    width: "20%",
    float: "left",
    margin: "10px",
  };
  const column40Style = {
    fontSize: "15px",
    fontWeight: "400",
    width: "40%",
    float: "left",
    margin: "10px",
  };
  const columnHeaderStyle = {
    fontSize: "20px",
    fontWeight: "400",
    width: "100%",
    textAlign: "center",
    margin: "10px",
  };
  const columnButtonStyle = {
    width: "20%",
    float: "left",
  };

  function formatNumber(n) {
    return n
      .toString()
      .replace(/[^0-9.]/g, "")//.replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleQuit = () => {
    hideModal();
    unselectAllRecord("budgets");
  };

  const handleOnClickAdd = async () => {
    try {
      setIsSubmitting(true);
      let budgetInputVal = parseFloat(
        boBudgetInput.current.value
          ? boBudgetInput.current.value
              .toString()
              .replaceAll("€", "")
              .replaceAll(",", "")
              .replaceAll(" ", "")
          : 0
      );

      if (!selectedPersNo) {
        setMessages(["Please select business owner."]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }
      if (budgetInputVal === 0) {
        setMessages(["Please enter budget."]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }

      console.log(
        "selectedPersNo : " +
          selectedPersNo +
          " boBudgetInput : " +
          boBudgetInput.current.value
      );

      const results = await addBEABusinessOwnerBudget({
        variables: {
          data: {
            budget_id: budgetId,
            pers_no: selectedPersNo,
            bo_budget: budgetInputVal,
            bo_budget_available: budgetInputVal,
            bo_budget_in_progress: parseFloat(0),
            bo_budget_spent: parseFloat(0),
          },
        },
      });
     
      if (results) {
        setUpdatedKey(Math.floor(Math.random() * 86));
        clearForm();
        setMessages(["Business Owner budget information added successfully."]);
        setMessageType("success");
      }
      setIsSubmitting(false);
    } catch (e) {
      console.log(
        "Issue in Adding the budget for business owner. Graph QL error: " +
          e.message
      );
      setMessages([e.message]);
      setMessageType("error");
      setIsSubmitting(false);
    }
  };

  const MessageComponent = (props) => {
    return (
      <>
        <FontAwesomeIcon
          icon={
            props.type === "error"
              ? faTimesCircle
              : props.type === "info"
              ? faInfoCircle
              : faCheck
          }
          style={{
            marginRight: "10px",
          }}
        />
        {props.err}
        <br />
      </>
    );
  };

  const NotificationMassages = (props) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        setMessages([]);
        setMessageType("error");
      }, 6000);
      return () => clearTimeout(timer);
    }, []);
    return (
      <>
        {props.messages && props.messages.length > 0 && (
          <div
            key={Math.random()}
            style={{
              border: "1px solid",
              margin: "10px 0px",
              padding: "15px 10px 15px 50px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "10px center",
              color:
                props.type === "error"
                  ? "#D8000C"
                  : props.type === "info"
                  ? "#31708f"
                  : "#4F8A10",
              backgroundColor:
                props.type === "error"
                  ? "#FFBABA"
                  : props.type === "info"
                  ? "#d9edf7"
                  : "#DFF2BF",
            }}
          >
            {props.messages.map((err) => {
              {
                return (
                  <MessageComponent
                    key={Math.random()}
                    type={props.type}
                    err={err}
                  />
                );
              }
            })}
          </div>
        )}
      </>
    );
  };

  const fetchBusinessOwners = (input) => {
    if (input.length >= 3) {
      setIsFetching(true);
      let queryFilter = { searchvalue: input, module: "businessOwners" , organization_unit: { eq: selectedOrgUnit } };
      return new Promise((resolve, reject) => {
        httpClient
          .query({
            query: businessOwnerPeople,
            variables: {
              where: queryFilter,
              limit: 15,
              offset: 0,
            },
          })
          .then((response) => {
            if (response.data && response.data.businessOwner_people) {
              resolve(
                response.data.businessOwner_people.items.map((a) => ({
                  label:
                    a.full_name +
                    " (" +
                    a.nokia_id +
                    ") (" +
                    a.email_work +
                    ")",
                  value: a.pers_no,
                  Name: a.full_name,
                }))
              );
            }
          })
          .catch((err) => console.error(err))
          .finally(() => setIsFetching(false));
      });
    } else {
      setIsFetching(false);
      return new Promise((resolve) => {
        resolve();
      });
    }
  };

  const handleSelectOnChange = async (e) => {
    if (e && e.value) {
      setSelectedPersNo(e.value);
      await checkBOBudgetAlreadyExists(e.value).then(
        (resultOfBoBudgetForPersNo) => {
          if (resultOfBoBudgetForPersNo) {
            setMessageType("info");
            setMessages([
              "Business owner '" +
                e.label +
                "' already have budget information for '" +
                resultOfBoBudgetForPersNo[0].organization_unit_name +
                "(" +
                selectedyear.toString() +
                ")' organization.",
            ]);
            selectInputRef.current.select.select.clearValue();  
            setSelectedPersNo("");         
          }
        }
      );
    }
  };

  const LoadingIndicator = (e) => {
    return (
      <>
        {e.selectProps.inputValue &&
        e.selectProps.inputValue.replace(/\s+/g, "").length >= 3 ? (
          <span className="loading">...</span>
        ) : (
          ""
        )}
      </>
    );
  };

  const handleCurrencyOnBlur = (e) => {
    formatCurrency(e.target.value, e);
  };

  function formatCurrency(input_val, event) {
    // console.log("input_val : " + input_val);
    // console.log("capitalInput : " + capitalInput.current.value);
    // console.log("boBudgetInput : " + boBudgetInput.current.value);
    if (input_val === "") {
      return;
    }
    
    let capitalBudgetInputVal = 0;
    let budgetInputVal = 0;
    let budgetInprogressInputVal = 0;
    let budgetSpentInputVal = 0;

    budgetInputVal = parseFloat(
      input_val.replaceAll("€", "").replaceAll(",", "").replaceAll(" ", "")
    );

    capitalBudgetInputVal = parseFloat(
      capitalBudget
        .toString()
        .replaceAll("€", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
    );
    budgetInprogressInputVal = parseFloat(
      budgetInProgress
        .toString()
        .replaceAll("€", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
    );
    budgetSpentInputVal = parseFloat(
      budgetSpent
        .toString()
        .replaceAll("€", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
    );

    // console.log("capitalBudgetInputVal : " + capitalBudgetInputVal);
    // console.log("budgetInputVal : " + budgetInputVal);
    // console.log("budgetInprogressInputVal : " + budgetInprogressInputVal);
    // console.log("budgetSpentInputVal : " + budgetSpentInputVal);
    // console.log("budgetForecast : " + (budgetInputVal - (budgetInprogressInputVal + budgetSpentInputVal)));
    // console.log("budgetAvailable : " + (budgetInputVal - budgetSpentInputVal));

    // setBudgetForecast(
    //   formatNumber(
    //     parseFloat(budgetInputVal - (budgetInprogressInputVal + budgetSpentInputVal)).toFixed(2)
    //   )
    // );
    // setBudgetAvailable(
    //   formatNumber(
    //     parseFloat(budgetInputVal - budgetSpentInputVal).toFixed(2)
    //   )
    // );

    event.target.value = "€ " + formatNumber(parseFloat(input_val.toString().replaceAll("€", "").replaceAll(",", "").replaceAll(" ", "")).toFixed(2));
  }

  const clearForm = () => {
    setSelectedPersNo("");
    setBoBudgetSelected([]);
    //setBoBudgetAvailable(selectedBOBudget[0] && selectedBOBudget[0].bo_budget_available ? selectedBOBudget[0].bo_budget_available : '');
    boBudgetInput.current.value = "";
    if (selectInputRef.current && selectInputRef.current.select) {
      selectInputRef.current.select.select.clearValue(); 
    }
    
  };

  const handleRequestEditBudget = (event, selectedBOBudget) => {
    setSelectedPersNo(selectedBOBudget[0].pers_no);
    setBoBudgetSelected(selectedBOBudget);
    boBudgetInput.current.value =
      selectedBOBudget[0] && selectedBOBudget[0].bo_budget
        ? selectedBOBudget[0].bo_budget
        : "";
  };
  const handleRequestDeleteBudget = (event, selectedBOBudget) => {
    setSelectedPersNo(selectedBOBudget[0].pers_no);
    setBoBudgetSelected(selectedBOBudget);
    handleOnClickDelete(selectedBOBudget[0].bo_budget_id);
  };
  
  const handleOnClickEdit = async () => {
    try {
      setIsSubmitting(true);
      let budgetInputVal = parseFloat(
        boBudgetInput.current.value
          ? boBudgetInput.current.value
              .toString()
              .replaceAll("€", "")
              .replaceAll(",", "")
              .replaceAll(" ", "")
          : 0
      );

      if (!selectedPersNo) {
        setMessages(["Please select business owner."]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }
      if (budgetInputVal === 0) {
        setMessages(["Please enter budget."]);
        setMessageType("error");
        setIsSubmitting(false);
        return;
      }
      let bo_budget_in_progress_val = boBudgetSelected[0].bo_budget_in_progress ? parseFloat(boBudgetSelected[0].bo_budget_in_progress) : parseFloat(0);
      let bo_budget_spent_val = boBudgetSelected[0].bo_budget_spent ? parseFloat(boBudgetSelected[0].bo_budget_spent) : parseFloat(0);

      const results = await editBEABusinessOwnerBudget({
        variables: {
          bo_budget_id: boBudgetSelected.length &&  boBudgetSelected[0].bo_budget_id ? parseInt(boBudgetSelected[0].bo_budget_id) : "",
          data: {
            budget_id: budgetId,
            pers_no: selectedPersNo,
            bo_budget: budgetInputVal,
            bo_budget_available: budgetInputVal - (bo_budget_in_progress_val + bo_budget_spent_val),
            bo_budget_in_progress: bo_budget_in_progress_val,
            bo_budget_spent: bo_budget_spent_val,
          },
        },
      });

      if (results) {
        setMessages(["Business Owner budget information Upadted successfully."]);
        setMessageType("success");
        setUpdatedKey(Math.floor(Math.random() * 86));
        clearForm();
      }
      setIsSubmitting(false);
    } catch (e) {
      console.log(
        "Issue in adding the budget for business owner. Graph QL error: " +
          e.message
      );
      setMessages([e.message]);
      setMessageType("error");
      setIsSubmitting(false);
    }
  };
  const handleOnClickDelete = async (id) => {
    try {
      setIsSubmitting(true);

      const results = await deleteBusinessOwnerBudget({
        variables: {
          bo_budget_id: id ? parseInt(id) : 0,
        },
      });

      if (results) {
        setUpdatedKey(Math.floor(Math.random() * 86));
        setMessages(["Business Owner budget information deleted successfully."]);
        setMessageType("success");
        clearForm();
      }
      setIsSubmitting(false);
    } catch (e) {
      console.log(
        "Issue in deleted the budget for business owner. Graph QL error: " +
          e.message
      );
      setMessages([e.message]);
      setMessageType("error");
      setIsSubmitting(false);
    }
  };

  return (
    <div
      style={isBusinessOwnerBudgetDataExist ? parentDiv : parentDivwithHeight}
    >
      <div style={rowStyle}>
        <div style={columnHeaderStyle}>
          <input id="searchResult" style={{ display: "none" }} />"
          {selectedOrgName}" - Business Owner Budget for {selectedyear}
        </div>
      </div>
      <NotificationMassages
        key={Math.random()}
        messages={messages}
        type={messageType}
      />
      <div style={rowStyle}>
        <div style={column20Style}>
          <div>
            <b>Capital Budget</b>
          </div>
          <div>
            <label>
              &#8364; {capitalBudget ? formatNumber(parseFloat(capitalBudget).toFixed(2)) : 0.00}
            </label>
          </div>
        </div>
        <div style={column20Style}>
          <div>
            <b>Budget</b>
          </div>
          <div>
            <label>&#8364; {budget ? formatNumber(parseFloat(budget).toFixed(2)) : 0.00}</label>
          </div>
        </div>
        <div style={column20Style}>
          <div>
            <b>Budget Spent</b>
          </div>
          <div>
            <label>&#8364; {budgetSpent ? formatNumber(parseFloat(budgetSpent).toFixed(2)) : 0.00}</label>
          </div>
        </div>
        <div style={column20Style}>
          <div>
            <b>Budget In Progress</b>
          </div>
          <div>
            <label>
              &#8364; {budgetInProgress ? formatNumber(parseFloat(budgetInProgress).toFixed(2)) : 0.00}
            </label>
          </div>
        </div>
        <div style={column20Style}>
          <div>
            <b>Budget Forecast</b>
          </div>
          <div>
            <label>
              &#8364; {budgetForecast ? formatNumber(parseFloat(budgetForecast).toFixed(2)) : 0.00}
            </label>
          </div>
        </div>
        <div style={column20Style}>
          <div>
            <b>Budget Available</b>
          </div>
          <div>
            <label>
              &#8364; {budgetAvailable ? formatNumber(parseFloat(budgetAvailable).toFixed(2)) : 0.00}
            </label>
          </div>
        </div>
      </div>
      <div style={rowStyle}>
        <div style={column40Style}>
          <div>
            Business Owner <span style={{ color: "red" }}>*</span>
          </div>
          <div>
            {
              selectedPersNo && boBudgetSelected.length ? 
              boBudgetSelected[0].full_name + " (" + boBudgetSelected[0].nokia_id + ") (" + boBudgetSelected[0].email_work + ")" 
              : 
              (
                <AsyncSelect
                  ref={selectInputRef}
                  components={{ LoadingIndicator }}
                  loadingMessage={(e) =>
                    e.inputValue && e.inputValue.replace(/\s+/g, "").length >= 3
                      ? "Loading"
                      : "Type at least three character"
                  }
                  noOptionsMessage={(e) =>
                    e.inputValue && e.inputValue.replace(/\s+/g, "").length >= 3
                      ? "No matches found"
                      : "Type at least three character"
                  }
                  loadOptions={fetchBusinessOwners}
                  onChange={(e) => handleSelectOnChange(e)}
                  placeholder="Type at least three character"
                  className="select"
                  //value={selectedPersNo}
                />
              )
            }
            
          </div>
        </div>
        <div style={column40Style}>
          Budget <span style={{ color: "red" }}>*</span> :
          <input
            id="new_boBudget"
            ref={boBudgetInput}
            type="text"
            style={{ height: "2rem", width: "100%" }}
            pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
            placeholder="&#8364;"
            onBlur={handleCurrencyOnBlur}
          />
        </div>
        <div style={columnButtonStyle}>
          <FormButtonsWrap style={{ width: "100%" }}>
            {boBudgetSelected && boBudgetSelected.length > 0 ? (
              <FormButton
                primary
                disabled={isSubmitting}
                onClick={handleOnClickEdit}
              >
                Update
              </FormButton>
            ) : (
              <FormButton
                primary
                disabled={isSubmitting}
                onClick={handleOnClickAdd}
              >
                Add
              </FormButton>
            )}
            <FormButton onClick={handleQuit}>Quit</FormButton>
            {isSubmitting && (
              <LoaderWrap>
                <Ring color={"#001235"} size="2" sizeUnit="rem" />
              </LoaderWrap>
            )}
          </FormButtonsWrap>
        </div>
      </div>
        <BusinessOwnerBudgetTable
          refreshKey={updatedKey}
          account={account}
          config={config}
          budget_id={selectedBudgets[0].budget_id}
          handleRequestEditBudget={handleRequestEditBudget}
          handleRequestDeleteBudget={handleRequestDeleteBudget}
          clearForm={clearForm}
        />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedBudgets: state.selectedBudgets,
    account: state.account,
    config: state.config,
  };
};

export default connect(mapStateToProps, {
  unselectAllRecord,
  pushMessage,
  toggleShowInEur,
})(BusinessOwnerBudget);
/*  Release 3.2 - 1.1 - Ends */
