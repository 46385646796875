export default (table) => {
  let name = 'nokia_id';
  let sortable = false;
  let width = "70px";
  switch (table) {
    case 'editCompensation':
    case 'editOnetime':
    /* Release 3.2 - 1.1 - Starts */
    case 'editBEA':
    /* Release 3.2 - 1.1 - Ends */
      case 'editInternalHire':
      name = 'request.for.nokia_id';
      break;
    case 'employees':
      sortable = true;
      break;
    /* Release 3.2 - 1.1 - Starts */
    case 'beabusinessownerbudgetmanage':
      sortable = false;      
      break;  
    case 'employees_businessexcellenceaward':
      sortable = true;
      break;
    case 'requestBusinessExcellenceAward':
      width = "100px";
      break;
    /* Release 3.2 - 1.1 - Ends */
    default:
      break;
  }

  return {
    type: "string",
    name,
    label: "Nokia ID",
    section: "Identifiers",
    visible: true,
    width,
    sortable
  };
};