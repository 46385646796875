import { currencyDisplayRenderer } from '../../renderers';

export default (table) => {
  
  return {
    type: "float",
    name: "request.override_fte_ttc_annual_salary",
    label: "Override Total Target Cash",
    section: "HR",
    visible: true,
    renderer: (value, data, account, config) => currencyDisplayRenderer(value, data.request, account, config),
    allow: ['hr1', 'hr2', 'hr3', 'bcb']
  };
};