/* eslint-disable no-self-compare */
import { useMemo, useEffect, useCallback } from 'react';
//import { useLazyQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { updateRecord } from '../../actions';
import { capitalize, filterJobCatalog, filterPaymentTypes } from '../../util';
//import { SALARY_RANGE } from '../../graphql';

const BUDGET_MULTIPLIER = 3.5;
const CURRENT_TIMESTAMP = Date.now();

const round = (v, d) => Number(Math.round(v + 'e' + d) + 'e-' + d);
const isNaN = (maybeNaN) => maybeNaN !== maybeNaN;


const Calculator = props => {
  const { columns, recordType, item, data, updateRecord, config, account } = props;
  console.log('config',config)
  //const [getRange, { data: rangeData, called }] = useLazyQuery(SALARY_RANGE);
  console.log("Calculation Date", data);
  const indexColumn = columns.find(c => c.index);

  const flattenRequestData = data => {
    return {
      ...data.request.for, ...{
        employee_request_id: data.employee_request_id,
        request_type: data.request.request_type,
        currency: data.request.currency,
        ttc_increase_amount: data.request.ttc_increase_amount,
        ttc_increase_percentage: data.request.ttc_increase_percentage,
        payment_type: selectedItem.request.payment_type,
        payment_reason: selectedItem.request.payment_reason,
        is_gpp_eligible: data.request.is_gpp_eligible,
        gpp_increase_amount: data.request.gpp_increase_amount,
        gpp_increase_percentage: data.request.gpp_increase_percentage,
        promoted: data.request.promoted,
        promotion_job_family_name: data.request.promotion_job_family_name,
        promotion_job_subfamily_name: data.request.promotion_job_subfamily_name,
        promotion_job_name: data.request.promotion_job_name,
        promotion_pay_grade: data.request.promotion_pay_grade,
        promotion_job_grade: data.request.promotion_job_grade,
        promotion_job_grade_delta: data.request.promotion_job_grade_delta,
        promotion_incentive_target_percentage: data.request.promotion_incentive_target_percentage,
        demoted: data.request.demoted,
        mandatory_ttc_increase_percentage: data.request.mandatory_ttc_increase_percentage,
        mandatory_ttc_increase_amount: data.request.mandatory_ttc_increase_amount,
        apply_ti_harmonization: data.request.apply_ti_harmonization,
        interim_job_family_name: data.request.interim_job_family_name,
        interim_job_grade: data.request.interim_job_grade,
        interim_fte_incentive_target_amount: data.request.interim_fte_incentive_target_amount,
        interim_incentive_target_percentage: data.request.interim_incentive_target_percentage,
        interim_total_increase_amount: data.request.interim_total_increase_amount,
        interim_increase_amount_for_salincr: data.request.interim_increase_amount_for_salincr,
        interim_increase_amount_for_tih: data.request.interim_increase_amount_for_tih,
        step_1_abs: data.request.step_1_abs,
        step_1_ita: data.request.step_1_ita,
        step_1_itp: data.request.step_1_itp,
        interim_theo_itp: data.request.interim_theo_itp,
        interim_itp_situation: data.request.interim_itp_situation,
        step_2_abs_gap: data.request.step_2_abs_gap,
        step_2_ita_gap: data.request.step_2_ita_gap,
        interim_remainder_for_split: data.request.interim_remainder_for_split,
        new_fte_annual_salary: data.request.new_fte_annual_salary,
        new_fte_incentive_target_amount: data.request.new_fte_incentive_target_amount,
        new_incentive_target_percentage: data.request.new_incentive_target_percentage,
        new_fte_ttc_annual_salary: data.request.new_fte_ttc_annual_salary,
        new_itp_situation: data.request.new_itp_situation,
        new_quartile: data.request.new_quartile,
        override_manager_proposal: data.request.override_manager_proposal,
        override_fte_annual_salary: data.request.override_fte_annual_salary,
        override_fte_incentive_target_amount: data.request.override_fte_incentive_target_amount,
        override_promotion_increase: data.request.override_promotion_increase,
        override_ti_harmonization: data.request.override_ti_harmonization,
        override_fte_incentive_target_percentage: data.request.override_fte_incentive_target_percentage,
        override_fte_ttc_annual_salary: data.request.override_fte_ttc_annual_salary,
        new_position_title: data.request.new_position_title,
        interim_pay_grade: data.request.interim_pay_grade,
        interim_salary_range_min: data.request.interim_salary_range_min,
        interim_salary_range_mid: data.request.interim_salary_range_mid,
        interim_salary_range_max: data.request.interim_salary_range_max,
        new_ttc_compa_ratio: data.request.new_ttc_compa_ratio,
        interim_salary_range_spread: data.request.interim_salary_range_spread,
        ttc_increase: data.request.ttc_increase,
        ttc_increase_perc: data.request.ttc_increase_perc,
        /**Release 2 Our talent data */
        /**Release 2 our talent */
      current_combo_situation: data.request.current_combo_situation,
      promo_increase_pct_guideline_min: data.request.promo_increase_pct_guideline_min,
      promo_increase_pct_guideline_max: data.request.promo_increase_pct_guideline_max,
      promotional_increase_percentage_guideline: data.request.promotional_increase_percentage_guideline,
      rr_number: data.request.rr_number,
      case_3_12_month_actual_incentive_amount_fte: data.request.case_3_12_month_actual_incentive_amount_fte,
      override_transition_allowance_local_currency: data.request.override_transition_allowance_local_currency,
     // new_incentive_scheme,
      internalhire_off_cycle_spending: data.request.internalhire_off_cycle_spending,
      internal_hire_off_cycle_spending_euro: data.request.internal_hire_off_cycle_spending_euro,
    
      is_sip: data.request.is_sip,
      Case_One_Max_Budget_calc: data.request.Case_One_Max_Budget_calc,
      Case_one_TTC_increase_minus_Max_budget: data.request.Case_one_TTC_increase_minus_Max_budget,
      Case_one_Budget_charge: data.request.Case_one_Budget_charge,
      Case_two_TI_Increase: data.request.Case_two_TI_Increase,
      Case_two_Promotion_Budget: data.request.Case_two_Promotion_Budget,
      Case_two_Total_budget: data.request.Case_two_Total_budget,
      Case_two_Budget_charge: data.request.Case_two_Budget_charge,
      Case_three_twelve_month_Total_Target_Cash: data.request.Case_three_twelve_month_Total_Target_Cash,
      Case_three_new_TTC_Gap_to_twelve_month_TTC: data.request.Case_three_new_TTC_Gap_to_twelve_month_TTC,
      Case_three_Max_capped_transit_allowance: data.request.Case_three_Max_capped_transit_allowance,
      Case_three_Transition_allowance_total: data.request.Case_three_Transition_allowance_total,
      Case_three_Budget_charge: data.request.Case_three_Budget_charge,
      sip_case: data.request.sip_case,
      Final_Off_Cycle_Budget: data.request.Final_Off_Cycle_Budget,
       Max_Promotional_Increase : data.request.promoted ?  data.request.promo_increase_pct_guideline_max:0,
      sip_change: data.request.sip_change ? data.request.sip_change : data.request.is_sip,
      internalhire_increase_amount: data.request.internalhire_increase_amount,
      internalhire_increase_percentage: data.request.internalhire_increase_percentage,
      hiring_manager: data.request.hiring_manager,
      
      }
    };
  }

  let selectedItem = props[`selected${capitalize(recordType)}`].find(e => e[indexColumn.name] === item);
  console.log("selectedItem", selectedItem);
  if (recordType === 'requests' && selectedItem) {
    selectedItem = flattenRequestData(selectedItem);
  }

  /*  DEFECTS FIX STARTS  */
  const ttc_increase = useMemo(
    () =>
      selectedItem
        ? ((selectedItem.new_fte_ttc_annual_salary) - (selectedItem.fte_ttc_annual_salary))
        : 0,
    [selectedItem]
  );
  //phase2 
  const ttc_increase_perc = useMemo(
    () =>
      selectedItem
        ? round((
          ((selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary) / selectedItem.fte_ttc_annual_salary) * 100), 10)
        : 0,
    [selectedItem]
  );

  const total_increaseed_amount = useMemo(
    () =>
      selectedItem
        ? ((selectedItem.new_fte_ttc_annual_salary) - (selectedItem.fte_ttc_annual_salary))
        : 0,
    [selectedItem]
  );
  /*  DEFECTS FIX ENDS  */

  const exchange_rate = useMemo(
    () => {
      const { exchangeRates } = config;
      if (!selectedItem || !selectedItem.currency) return 1;
      const match = exchangeRates.find(r => r.to_currency === selectedItem.currency && r.from_currency === 'EUR');
      return match ? 1 / match.exchange_rate : 1;
    },
    [selectedItem, config]
  );

  const salary_range_spread = useMemo(
    () => {
      if (!selectedItem) return 0;

      return (1 - selectedItem.salary_range_min / selectedItem.salary_range_mid) + (selectedItem.salary_range_max / selectedItem.salary_range_mid - 1);
    },
    [selectedItem]
  );

  const tenure = useMemo(
    () =>
      selectedItem
        ? (CURRENT_TIMESTAMP - (selectedItem.pers_start_date || CURRENT_TIMESTAMP)) / 86400000 / 365
        : 0,
    [selectedItem]
  );

  const is_gpp_eligible = useMemo(
    () =>
      selectedItem
        ? selectedItem.job_grade === 6 && selectedItem.interim_job_grade === 7 && selectedItem.tenure >= 1.5 && selectedItem.tenure <= 4
        : false,
    [selectedItem]
  );

  const ttc_increase_amount = useMemo(
    () => {
      /**Release 2 Ourtalent making TTC increase amount calcualted at some scenario - Starts */
      if (selectedItem) {
        
        if (selectedItem.sip_change) {
          return selectedItem.new_fte_annual_salary && selectedItem.new_fte_incentive_target_amount && (selectedItem.new_fte_annual_salary + selectedItem.new_fte_incentive_target_amount) >= 0 ? (selectedItem.new_fte_annual_salary + selectedItem.new_fte_incentive_target_amount) - selectedItem.fte_ttc_annual_salary : 0;
        }
        /**Release 2 Ourtalent making TTC increase amount calcualted at some scenario - Ends */
          if (data.ttc_increase_amount !== undefined) {
            const v = isNaN(data.ttc_increase_amount) ? 0 : data.ttc_increase_amount;
            return Math.abs(v);
          }
          if (data.request && data.request.ttc_increase_amount !== undefined) {
            const v = isNaN(data.request.ttc_increase_amount) ? 0 : data.request.ttc_increase_amount;
            return Math.abs(v);
          }

          return selectedItem
            ? round(selectedItem.fte_ttc_annual_salary * ((selectedItem.ttc_increase_percentage || 0) / 100), 10)
            : 0;
        }
        
      
    },
    [selectedItem, data]
  );

  const ttc_increase_percentage = useMemo(
    () => {
            /**Release 2 Ourtalent making TTC increase amount calcualted at some scenario - Starts */
            if (selectedItem) {
        
              if (selectedItem.sip_change) {
               // return selectedItem.new_fte_annual_salary ? selectedItem.new_fte_annual_salary - selectedItem.fte_ttc_annual_salary : 0;
                return  round((((selectedItem.ttc_increase_amount || 0) / selectedItem.fte_ttc_annual_salary) * 100),10); 
              }
              /**Release 2 Ourtalent making TTC increase amount calcualted at some scenario - Ends */
      if (data.ttc_increase_percentage !== undefined) return Math.abs(data.ttc_increase_percentage);
      if (data.request && data.request.ttc_increase_percentage !== undefined) return Math.abs(data.request.ttc_increase_percentage);
      return selectedItem
        ? round((((selectedItem.ttc_increase_amount || 0) / selectedItem.fte_ttc_annual_salary) * 100), 10)
        : 0;
    }
  },
    [selectedItem, data]
  );

  const override_fte_incentive_target_percentage = useMemo(
    () => {
      return selectedItem && selectedItem.override_fte_annual_salary
        ? (selectedItem.override_fte_incentive_target_amount / selectedItem.override_fte_annual_salary) * 100
        : 0;
    },
    [selectedItem]
  );

  const override_fte_ttc_annual_salary = useMemo(
    () =>
      selectedItem
        ? selectedItem.override_fte_incentive_target_amount + selectedItem.override_fte_annual_salary + selectedItem.fte_addl_ttc_amount
        : 0,
    [selectedItem]
  );

  const gpp_increase_amount = useMemo(
    () => {
      if (data.gpp_increase_amount) return data.gpp_increase_amount;

      return selectedItem
        ? selectedItem.fte_ttc_annual_salary * (selectedItem.gpp_increase_percentage / 100)
        : null;
    },
    [selectedItem, data]
  );

  const gpp_increase_percentage = useMemo(
    () => {
      if (data.gpp_increase_percentage) return data.gpp_increase_percentage;

      return selectedItem
        ? (selectedItem.gpp_increase_amount / selectedItem.fte_ttc_annual_salary) * 100
        : null;
    },
    [selectedItem, data]
  );

  const promotion_job_family_name = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.promotion_job_family_name) return activeData.promotion_job_family_name;

      return selectedItem
        ? selectedItem.promotion_job_family_name || selectedItem.job_family_name
        : null;
    },
    [selectedItem, data]
  );

  const new_position_title = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.new_position_title) return activeData.new_position_title;

      return selectedItem
        ? selectedItem.new_position_title || selectedItem.position_title
        : null;
    },
    [selectedItem, data]
  );

  const promotion_job_subfamily_name = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.promotion_job_subfamily_name) return activeData.promotion_job_subfamily_name;

      // const f = buildFilters('job_subfamily_name', activeData, selectedItem);
      // console.log(f)

      let filters = null;
      if (activeData.promotion_job_family_name) {
        filters = { job_family_name: v => v === activeData.promotion_job_family_name };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'job_subfamily_name', config, activeData);
        return filtered.shift();
      }

      return selectedItem
        ? selectedItem.promotion_job_subfamily_name || selectedItem.job_subfamily_name
        : null;
    },
    [selectedItem, data, config]
  );
  const promotion_job_name = useMemo(
    () => {
      const { jobsCatalog } = config;
      console.log("Check job name fix - jobscatalog", jobsCatalog);
      // edit or create
      const activeData = data.request ? data.request : data;
      // changed - Job Role Name
      if (activeData.promotion_job_name) return activeData.promotion_job_name;

      let filters = null;
      /**release 2 OT job name starts */
      //  if (activeData.request_type !== "internalhire") {
      /**release 2 OT job name ENds */
      if (activeData.promotion_job_subfamily_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === activeData.promotion_job_subfamily_name
        };
      }
      if (activeData.promotion_job_family_name) {
        filters = {
          job_family_name: v => v === activeData.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name
        };
      }
      //  }
      /**release 2 OT job name starts */
      //  else {
      //    if (activeData.promotion_job_grade) {
      //     filters = {
      //       job_family_name: v => v === selectedItem.job_family_name,
      //       job_subfamily_name: v => v === selectedItem.job_subfamily_name,
      //       job_grade: v => v === parseInt(activeData.promotion_job_grade).toString(),
      //      job_role_name: v => v === selectedItem.job_role_name,
      //     };
      //   }

      // }
      /**release 2 OT job name Ends */
      if (filters) {
        const filtered = filterJobCatalog(filters, 'job_name', config, activeData);
        console.log("Check job name fix - filters", filtered);
        // Return job title which matches current job grade, if possible for given job selection
        const namesAndGrades = jobsCatalog.filter(j => filtered.includes(j.job_name));
        /**Release 2 - OT job grade starts */
        const matching = namesAndGrades.find(jx => jx.pay_grade.startsWith(selectedItem.job_grade));
        //const matching = namesAndGrades.find(jx => jx.pay_grade.startsWith(activeData.request_type !== "internalhire" ? selectedItem.job_grade : activeData.promotion_job_grade));
        /**Release 2 - OT job grade Ends */
        return matching ? filtered.find(x => x === matching.job_name) : filtered.shift();
      }
      // changed - Something else or Nothing
      return selectedItem
        ? selectedItem.promotion_job_name || selectedItem.job_name
        : null;
    },
    [selectedItem, data, config]
  );

  const promotion_pay_grade = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;
      // console.log('promotion_pay_grade', activeData.promotion_pay_grade)
      // if (activeData.promotion_pay_grade) return activeData.promotion_pay_grade;
      // if (data.request && data.request.promotion_pay_grade) return data.request.promotion_pay_grade;

      let filters = null;
      if (activeData.promotion_job_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === activeData.promotion_job_name
        };
      }
      if (activeData.promotion_job_subfamily_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === activeData.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name
        };
      }
      if (activeData.promotion_job_family_name) {
        filters = {
          job_family_name: v => v === activeData.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name
        };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'pay_grade', config, activeData);
        //const matchingGrade = filtered.find(x => x.startsWith(selectedItem.job_grade));
        //return matchingGrade ? matchingGrade : filtered.shift();
        return filtered.shift();
      }

      return selectedItem
        ? selectedItem.promotion_pay_grade || selectedItem.pay_grade
        : null;
    },
    [selectedItem, data, config]
  );

  const promotion_job_grade = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.promotion_job_grade) return activeData.promotion_job_grade;

      let filters = null;
      if (activeData.promotion_pay_grade) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name,
          pay_grade: v => v === activeData.promotion_pay_grade
        };
      }
      if (activeData.promotion_job_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === activeData.promotion_job_name,
          pay_grade: v => v === selectedItem.promotion_pay_grade
        };
      }
      if (activeData.promotion_job_subfamily_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === activeData.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name,
          pay_grade: v => v === selectedItem.promotion_pay_grade
        };
      }
      if (activeData.promotion_job_family_name) {
        filters = {
          job_family_name: v => v === activeData.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name,
          pay_grade: v => v === selectedItem.promotion_pay_grade
        };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'job_grade', config, activeData);
        return filtered.shift();
      }

      return selectedItem
        ? selectedItem.promotion_job_grade || selectedItem.job_grade
        : null;
    },
    [selectedItem, data, config]
  );

  const gpp_guideline_max = useMemo(
    () => {
      const { gppLookupTable } = config;
      if (!selectedItem || !selectedItem.is_gpp_eligible) return 0.00;
      const match = gppLookupTable.find(obj => obj.quartile === selectedItem.ttc_employee_quartile && obj.country === selectedItem.country);
      return match ? match.max : 0;
    },
    [selectedItem, config]
  );

  const promoted = useMemo(
    () => {
      const { jobGrades } = config;
      return selectedItem
        ? jobGrades.findIndex(x => x === selectedItem.promotion_job_grade) > jobGrades.findIndex(x => x === selectedItem.job_grade) && selectedItem.promotion_job_family_name
          ? true
          : false
        : false
    },
    [selectedItem, config]
  );

  const demoted = useMemo(
    () => {
      const { jobGrades } = config;

      return selectedItem
        ? jobGrades.findIndex(x => x === selectedItem.promotion_job_grade) < jobGrades.findIndex(x => x === selectedItem.job_grade) && selectedItem.promotion_job_family_name
          ? true
          : false
        : false
    },
    [selectedItem, config]
  );

  const promotion_job_grade_delta = useMemo(
    () =>
      selectedItem
        ? selectedItem.promotion_job_grade - selectedItem.job_grade
          ? (selectedItem.promotion_job_grade - selectedItem.job_grade).toString()
          : null
        : null,
    [selectedItem]
  );

  const promotion_incentive_target_percentage = useMemo(
    () => {
      const { itpLookupTable } = config;
      /**Release 2 Issue check - starts */
      if (!selectedItem) return 0.00;
      /**Release 2 issye check - ends */
    //  if (!selectedItem || !selectedItem.promoted) return 0.00;
      const salesJobFamily = selectedItem.promotion_job_family_name === 'Sales' || selectedItem.promotion_job_family_name === 'Pre-Sales';
      const usaOrCanada = selectedItem.country_name === 'USA' || selectedItem.country_name === 'Canada';
      const jf = !salesJobFamily ? '*' : selectedItem.promotion_job_family_name;
      const c = !usaOrCanada || (!salesJobFamily && selectedItem.country_name !== 'USA') ? '*' : selectedItem.country_name;
      const jg = salesJobFamily ? '*' : selectedItem.promotion_job_grade;
      const match = itpLookupTable.find(obj => obj.job_family === jf && obj.country === c && obj.job_grade === jg);
      return match ? match.itp : 0;
    },
    [selectedItem, config]
  );

  const mandatory_ttc_increase_percentage = useMemo(
    () => {
      if (!selectedItem) return 0;
      if (
        !selectedItem.override_promotion_increase &&
        selectedItem.promoted &&
        selectedItem.promotion_job_family_name !== 'Sales' &&
        selectedItem.promotion_job_family_name !== 'Pre-Sales'
      ) {
        if (selectedItem.promotion_incentive_target_percentage - selectedItem.fte_incentive_target_percentage > 5) {
          return 5;
        } else {
          if (selectedItem.promotion_incentive_target_percentage - selectedItem.fte_incentive_target_percentage > 0) {
            return selectedItem.promotion_incentive_target_percentage - selectedItem.fte_incentive_target_percentage;
          }
        }
      }

      return 0;
    },
    [selectedItem]
  );

  const mandatory_ttc_increase_amount = useMemo(
    () =>
      selectedItem
        ? selectedItem.fte_annual_salary * (selectedItem.mandatory_ttc_increase_percentage / 100)
        : 0,
    [selectedItem]
  );

  const apply_ti_harmonization = useMemo(
    () => {
      // default to false
      if (!selectedItem) return false;
      // force apply TI
      if (selectedItem.override_ti_harmonization === 'Apply TI Harmonization') return true;
      // force don't apply TI
      if (selectedItem.demoted) return false;
      // without override, matching conditions for TI
      if (
        (!selectedItem.override_ti_harmonization || selectedItem.override_ti_harmonization === 'Do not override') &&
        (selectedItem.interim_itp_situation !== 'Norm' || selectedItem.promoted)
      ) {
        return true;
      }
      // without override, not matching conditions for TI
      return false;
    },
    [selectedItem]
  );

  const interim_harmonization_type = useMemo(
    () => {
      if (!selectedItem) return 'No TIH';

      if (!selectedItem.apply_ti_harmonization) return 'No TIH';

      if (selectedItem.interim_itp_situation === 'Norm') return 'No TIH';

      if (selectedItem.interim_itp_situation === 'High') return 'Full TIH';

      if (['Sales', 'Pre-Sales'].includes(selectedItem.interim_job_family_name)) return 'Full TIH';

      if (['Q1', 'BM'].includes(selectedItem.ttc_employee_quartile)) return 'No TIH';

      return 'Partial TIH';
    },
    [selectedItem]
  );

  const interim_job_family_name = useMemo(
    () =>
      selectedItem
        ? selectedItem.promoted || selectedItem.promotion_job_family_name != selectedItem.job_family_name ? selectedItem.promotion_job_family_name : selectedItem.job_family_name
        : 0,
    [selectedItem]
  );

  const interim_job_grade = useMemo(
    () =>
      selectedItem
        ? selectedItem.promoted || selectedItem.promotion_job_family_name != selectedItem.job_family_name ? selectedItem.promotion_job_grade : selectedItem.job_grade
        : 0,
    [selectedItem]
  );

  const interim_pay_grade = useMemo(
    () => {
      return selectedItem
        ? selectedItem.promotion_pay_grade // selectedItem.promoted ? selectedItem.promotion_pay_grade : selectedItem.pay_grade
        : 0;
    },
    [selectedItem]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const get_salary_range = (country, payScaleArea, payGrade, currency) => {
    const salaryRanges = config.allSalaryRanges
    let salaryRangeFound;
    for (const salaryRange of salaryRanges) {
      // eslint-disable-next-line
      if (salaryRange.country_name == country && salaryRange.grade_region == payScaleArea && salaryRange.pay_grade == payGrade && salaryRange.currency_code == currency) {
        salaryRangeFound = salaryRange
        break
      }
    }
    return salaryRangeFound
  }

  const interim_salary_range_min = useMemo(
    () => {
      // console.log('interim_salary_range_min' , selectedItem, selectedItem && selectedItem.interim_pay_grade)
      if (!selectedItem || !selectedItem.interim_pay_grade) return 0;
      // getRange({ variables: {
      //   country: selectedItem.country_name,
      //   payScaleArea: selectedItem.pay_scale_area,
      //   payGrade: selectedItem.interim_pay_grade,
      //   currency: selectedItem.currency
      // }});
      const salaryRangeData = get_salary_range(selectedItem.country_name, selectedItem.pay_scale_area, selectedItem.promotion_pay_grade, selectedItem.currency)
      return salaryRangeData ? salaryRangeData.ttc_salary_range_min : 0

      // return rangeData && rangeData.salaryRange
      // ? rangeData.salaryRange.min
      // : 0;
    },
    [selectedItem, get_salary_range]
  );

  const interim_salary_range_mid = useMemo(
    () => {
      if (!selectedItem || !selectedItem.interim_pay_grade) return 0;

      const salaryRangeData = get_salary_range(selectedItem.country_name, selectedItem.pay_scale_area, selectedItem.promotion_pay_grade, selectedItem.currency)
      return salaryRangeData ? salaryRangeData.ttc_salary_range_mid : 0

      // getRange({ variables: {
      //   country: selectedItem.country_name,
      //   payScaleArea: selectedItem.pay_scale_area,
      //   payGrade: selectedItem.interim_pay_grade,
      //   currency: selectedItem.currency
      // }});

      // return rangeData && rangeData.salaryRange
      // ? rangeData.salaryRange.mid
      // : 0;
    },
    [selectedItem, get_salary_range]
  );

  const interim_salary_range_max = useMemo(
    () => {
      if (!selectedItem || !selectedItem.interim_pay_grade) return 0;

      // getRange({ variables: {
      //   country: selectedItem.country_name,
      //   payScaleArea: selectedItem.pay_scale_area,
      //   payGrade: selectedItem.interim_pay_grade,
      //   currency: selectedItem.currency
      // }});

      // return rangeData && rangeData.salaryRange
      // ? rangeData.salaryRange.max
      // : 0;
      const salaryRangeData = get_salary_range(selectedItem.country_name, selectedItem.pay_scale_area, selectedItem.promotion_pay_grade, selectedItem.currency)
      return salaryRangeData ? salaryRangeData.ttc_salary_range_max : 0
    },
    [selectedItem, get_salary_range]
  );

  const interim_salary_range_spread = useMemo(
    () => {
      if (!selectedItem) return 0;

      return (1 - selectedItem.interim_salary_range_min / selectedItem.interim_salary_range_mid) + (selectedItem.interim_salary_range_max / selectedItem.interim_salary_range_mid - 1);
    },
    [selectedItem]
  );

  const interim_fte_incentive_target_amount = useMemo(
    () =>
      selectedItem
        ? selectedItem.fte_incentive_target_amount + selectedItem.mandatory_ttc_increase_amount
        : 0,
    [selectedItem]
  );

  const interim_incentive_target_percentage = useMemo(
    () =>
      selectedItem
        ? (selectedItem.interim_fte_incentive_target_amount / selectedItem.fte_annual_salary) * 100
        : 0,
    [selectedItem]
  );

  const interim_total_increase_amount = useMemo(
    () =>
      selectedItem
        ? selectedItem.ttc_increase_amount + (selectedItem.promoted && selectedItem.is_gpp_eligible ? selectedItem.gpp_increase_amount : 0)
        : 0,
    [selectedItem]
  );

  const interim_increase_amount_for_salincr = useMemo(
    () =>
      selectedItem
        ? selectedItem.override_ti_harmonization !== 'DO NOT apply TI Harmonization'
          ? 0
          : selectedItem.interim_total_increase_amount
        : 0,
    [selectedItem]
  );

  const interim_increase_amount_for_tih = useMemo(
    () =>
      selectedItem
        ? selectedItem.override_ti_harmonization !== 'DO NOT apply TI Harmonization'
          ? selectedItem.interim_total_increase_amount
          : 0
        : 0,
    [selectedItem]
  );

  const step_1_abs = useMemo(
    () =>
      selectedItem
        ? selectedItem.fte_annual_salary + (selectedItem.interim_increase_amount_for_salincr / (1 + (selectedItem.interim_incentive_target_percentage / 100)))
        : 0,
    [selectedItem]
  );

  const step_1_ita = useMemo(
    () =>
      selectedItem
        ? selectedItem.step_1_abs * (selectedItem.interim_incentive_target_percentage / 100)
        : 0,
    [selectedItem]
  );

  const step_1_itp = useMemo(
    () =>
      selectedItem
        ? round((selectedItem.step_1_ita / selectedItem.step_1_abs) * 100, 2)
        : 0,
    [selectedItem]
  );

  const interim_theo_itp = useMemo(
    () => {
      const { itpLookupTable } = config;
      if (!selectedItem) return 0.00;
      const salesJobFamily = selectedItem.interim_job_family_name === 'Sales' || selectedItem.interim_job_family_name === 'Pre-Sales';
      const usaOrCanada = selectedItem.country_name === 'USA' || selectedItem.country_name === 'Canada';
      const jf = !salesJobFamily ? '*' : selectedItem.interim_job_family_name;
      const c = !usaOrCanada || (!salesJobFamily && selectedItem.country_name !== 'USA') ? '*' : selectedItem.country_name;
      const jg = salesJobFamily ? '*' : selectedItem.interim_job_grade;
      const match = itpLookupTable.find(obj => obj.job_family === jf && obj.country === c && obj.job_grade === jg);
      return match ? match.itp : 0;
    },
    [selectedItem, config]
  );

  const interim_itp_situation = useMemo(
    () =>
      selectedItem
        ? selectedItem.step_1_itp === selectedItem.interim_theo_itp ? 'Norm' : selectedItem.step_1_itp > selectedItem.interim_theo_itp ? 'High' : 'Low'
        : 'Norm',
    [selectedItem]
  );

  const step_2_abs_gap = useMemo(
    () => {
      if (!selectedItem) return 0;
      if (!selectedItem.apply_ti_harmonization) return 0;
      if (selectedItem.interim_itp_situation !== 'High') return 0;
      if (!selectedItem.interim_theo_itp) return 0;

      return (selectedItem.step_1_ita / (selectedItem.interim_theo_itp / 100)) - selectedItem.step_1_abs;
    },
    [selectedItem]
  );

  const step_2_ita_gap = useMemo(
    () => {
      if (!selectedItem) return 0;
      if (!selectedItem.apply_ti_harmonization) return 0;
      if (selectedItem.interim_itp_situation !== 'Low') return 0;
      if (!selectedItem.interim_theo_itp) return 0;

      return (selectedItem.step_1_abs * (selectedItem.interim_theo_itp / 100)) - selectedItem.step_1_ita;
    },
    [selectedItem]
  );

  const interim_remainder_for_split = useMemo(
    () => {
      if (!selectedItem) return 0;
      if (!selectedItem.apply_ti_harmonization) return selectedItem.interim_increase_amount_for_tih;
      if (
        (selectedItem.step_2_abs_gap && selectedItem.interim_increase_amount_for_tih > selectedItem.step_2_abs_gap) ||
        (selectedItem.step_2_ita_gap && selectedItem.interim_increase_amount_for_tih > selectedItem.step_2_ita_gap) ||
        (!selectedItem.step_2_abs_gap && !selectedItem.step_2_ita_gap)
      ) {
        return selectedItem.interim_increase_amount_for_tih - selectedItem.step_2_ita_gap - selectedItem.step_2_abs_gap;
      } else {
        return 0;
      }
    },
    [selectedItem]
  );

  const new_fte_annual_salary = useMemo(
    () => {
      if (!selectedItem) return 0;
      const { noDecimalCurrencies = [] } = config;
      const hasNoDecimas = noDecimalCurrencies.find(c => c === selectedItem.currency);
      /**Release 2 - Some scenarios make new annual base salary user input - starts*/
      if (data.new_fte_annual_salary !== undefined) {
        const v = isNaN(data.new_fte_annual_salary) ? 0 : data.new_fte_annual_salary;
        return hasNoDecimas ? round( Math.abs(v), 0) : Math.abs(v);
      }
      if (data.request && data.request.new_fte_annual_salary !== undefined) {
        const v = isNaN(data.request.new_fte_annual_salary) ? 0 : data.request.new_fte_annual_salary;
        return hasNoDecimas ? round( Math.abs(v), 0) : Math.abs(v);
      }
       if (selectedItem.sip_change && !data.request_from )  {
        return selectedItem.new_fte_annual_salary ? selectedItem.new_fte_annual_salary : 0;
      }
      /**Release 2 - Some scenarios make new annual base salary user input - Ends*/

      let value = 0;

      if (selectedItem.override_manager_proposal) {
        value = selectedItem.override_fte_annual_salary || selectedItem.new_fte_annual_salary || null;
        return hasNoDecimas ? round(value, 0) : value;
      }
      if (!selectedItem.apply_ti_harmonization) {
        value = selectedItem.fte_annual_salary + selectedItem.interim_total_increase_amount / (1 + (selectedItem.fte_incentive_target_percentage / 100));
        return hasNoDecimas ? round(value, 0) : value;
      }
      if (selectedItem.interim_itp_situation === 'Low') {
        if (selectedItem.interim_increase_amount_for_tih <= selectedItem.step_2_ita_gap) {
          value = selectedItem.step_1_abs;
          return hasNoDecimas ? round(value, 0) : value;
        } else {
          value = selectedItem.step_1_abs + selectedItem.interim_remainder_for_split / (1 + (selectedItem.interim_theo_itp / 100));
          return hasNoDecimas ? round(value, 0) : value;
        }
      } else {
        if (selectedItem.interim_itp_situation === 'Norm') {
          value = selectedItem.step_1_abs + selectedItem.interim_remainder_for_split / (1 + (selectedItem.interim_theo_itp / 100));
          return hasNoDecimas ? round(value, 0) : value;
        } else {
          if (selectedItem.interim_increase_amount_for_tih <= selectedItem.step_2_abs_gap) {
            value = selectedItem.step_1_abs + selectedItem.interim_increase_amount_for_tih;
            return hasNoDecimas ? round(value, 0) : value;
          } else {
            value = selectedItem.step_1_abs + selectedItem.step_2_abs_gap + selectedItem.interim_remainder_for_split / (1 + (selectedItem.interim_theo_itp / 100));
            return hasNoDecimas ? round(value, 0) : value;
          }
        }
      }
    },
    [selectedItem, config, data]
  );

  const new_fte_incentive_target_amount = useMemo(
    () => {
      if (!selectedItem) return 0;
      const { noDecimalCurrencies = [] } = config;
      const hasNoDecimas = noDecimalCurrencies.find(c => c === selectedItem.currency);
      /**Release 2 Our Talent some scenarios make Our Talent new intensive target amount dynamic - starts */
      if (data.new_fte_incentive_target_amount !== undefined) {
        const v = isNaN(data.new_fte_incentive_target_amount) ? 0 : data.new_fte_incentive_target_amount;
        return hasNoDecimas ? round( Math.abs(v), 0) : Math.abs(v);
      }
      if (data.request && data.request.new_fte_incentive_target_amount !== undefined) {
        const v = isNaN(data.request.new_fte_incentive_target_amount) ? 0 : data.request.new_fte_incentive_target_amount;
        return hasNoDecimas ? round( Math.abs(v), 0) : Math.abs(v);
      }
      if (selectedItem.sip_change && !data.request_from) {
        return selectedItem.new_fte_incentive_target_amount ? selectedItem.new_fte_incentive_target_amount : 0;
      }
      /**Release 2 Our Talent some scenarios make Our Talent new intensive target amount dynamic - Ends */

      let value = 0;

      if (selectedItem.override_manager_proposal) {
        value = selectedItem.override_fte_incentive_target_amount || selectedItem.new_fte_incentive_target_amount;
        return hasNoDecimas ? round(value, 0) : value;
      }
      if (!selectedItem.apply_ti_harmonization) {
        value = selectedItem.step_1_ita + (selectedItem.interim_remainder_for_split * (selectedItem.interim_theo_itp / 100)) / (1 + (selectedItem.interim_theo_itp / 100));
        // value = selectedItem.step_1_ita + (selectedItem.interim_remainder_for_split * (selectedItem.interim_incentive_target_percentage / 100)) / (1 + (selectedItem.interim_incentive_target_percentage / 100));
        return hasNoDecimas ? round(value, 0) : value;
      }
      if (selectedItem.interim_itp_situation === 'Low') {
        if (selectedItem.interim_increase_amount_for_tih <= selectedItem.step_2_ita_gap) {
          value = selectedItem.step_1_ita + selectedItem.interim_increase_amount_for_tih;
          return hasNoDecimas ? round(value, 0) : value;
        } else {
          value = selectedItem.step_1_ita + selectedItem.step_2_ita_gap + (selectedItem.interim_remainder_for_split * (selectedItem.interim_theo_itp / 100)) / (1 + (selectedItem.interim_theo_itp / 100));
          return hasNoDecimas ? round(value, 0) : value;
        }
      } else {
        if (selectedItem.interim_itp_situation === 'Norm') {
          value = selectedItem.step_1_ita + (selectedItem.interim_remainder_for_split * (selectedItem.interim_theo_itp / 100)) / (1 + (selectedItem.interim_theo_itp / 100));
          return hasNoDecimas ? round(value, 0) : value;
        } else {
          if (selectedItem.interim_increase_amount_for_tih <= selectedItem.step_2_abs_gap) {
            value = selectedItem.step_1_ita;
            return hasNoDecimas ? round(value, 0) : value;
          } else {
            value = selectedItem.step_1_ita + (selectedItem.interim_remainder_for_split * (selectedItem.interim_theo_itp / 100)) / (1 + (selectedItem.interim_theo_itp / 100));
            return hasNoDecimas ? round(value, 0) : value;
          }
        }
      }
    },
    [selectedItem, config, data]
  );

  const new_incentive_target_percentage = useMemo(
    () =>
      selectedItem
        ? round((selectedItem.new_fte_incentive_target_amount / selectedItem.new_fte_annual_salary) * 100, 2)
        : 0,
    [selectedItem]
  );

  const new_fte_ttc_annual_salary = useMemo(
    () => {
      const { noDecimalCurrencies = [] } = config;
      if (!selectedItem) return 0;

      const hasNoDecimas = noDecimalCurrencies.find(c => c === selectedItem.currency);
      const value = selectedItem.new_fte_incentive_target_amount + selectedItem.new_fte_annual_salary + selectedItem.fte_addl_ttc_amount;

      return hasNoDecimas ? round(value, 0) : value;
    },
    [selectedItem, config]
  );

  const new_itp_situation = useMemo(
    () =>
      selectedItem
        ? selectedItem.new_incentive_target_percentage === selectedItem.interim_theo_itp ? 'Norm' : selectedItem.new_incentive_target_percentage > selectedItem.interim_theo_itp ? 'High' : 'Low'
        : 'Norm',
    [selectedItem]
  );

  const new_ttc_check = useMemo(
    () => {
      if (selectedItem && selectedItem.new_fte_ttc_annual_salary) {
        const summ = selectedItem.fte_ttc_annual_salary + selectedItem.mandatory_ttc_increase_amount + selectedItem.interim_total_increase_amount;
        return (Math.round(parseFloat(selectedItem.new_fte_ttc_annual_salary) * 100) / 100) === (Math.round(parseFloat(summ) * 100) / 100);
      }
      return false;
    },
    [selectedItem]
  );

  const new_ttc_compa_ratio = useMemo(
    () => {
      if (selectedItem) {
        return selectedItem.new_fte_ttc_annual_salary ? selectedItem.new_fte_ttc_annual_salary / selectedItem.interim_salary_range_mid : selectedItem.ttc_compa_ratio;
      }
      return 0.00;
    },
    [selectedItem]
  );

  const new_quartile = useMemo(
    () => {
      if (selectedItem && selectedItem.new_ttc_compa_ratio > 0) {
        if (selectedItem.new_ttc_compa_ratio < (1 - (selectedItem.interim_salary_range_spread / 2))) {
          return 'BM';
        }

        if (selectedItem.new_ttc_compa_ratio < (1 - (selectedItem.interim_salary_range_spread / 4))) {
          return 'Q1';
        }

        if (selectedItem.new_ttc_compa_ratio < 1) {
          return 'Q2';
        }

        if (selectedItem.new_ttc_compa_ratio < (1 + (selectedItem.interim_salary_range_spread / 4))) {
          return 'Q3';
        }

        if (selectedItem.new_ttc_compa_ratio < (1 + (selectedItem.interim_salary_range_spread / 2))) {
          return 'Q4';
        }

        return 'AM';
      }
      return 'NA';
    },
    [selectedItem]
  );

  const new_incentive_plan_type = useMemo(
    () =>
      selectedItem
        // eslint-disable-next-line
        ? selectedItem.incentive_plan_type === 'YPDI' && selectedItem.job_grade == 12 && selectedItem.interim_job_grade == 13
          ? 'YMDI'
          : selectedItem.incentive_plan_type
        : '',
    [selectedItem]
  );

  const individual_budget_amount = useMemo(
    () =>
      selectedItem
        ? selectedItem.fte_ttc_annual_salary * (BUDGET_MULTIPLIER / 100)
        : 0,
    [selectedItem]
  );

  const individual_spending_amount = useMemo(
    () =>
      selectedItem
        ? selectedItem.ttc_increase_amount + (selectedItem.is_gpp_eligible ? 0 : selectedItem.mandatory_ttc_increase_amount)
        : 0,
    [selectedItem]
  );

  const individual_balance_amount = useMemo(
    () =>
      selectedItem
        ? selectedItem.individual_budget_amount - selectedItem.individual_spending_amount
        : 0,
    [selectedItem]
  );

  const gpp_budget = useMemo(
    () =>
      selectedItem
        ? selectedItem.promoted && selectedItem.is_gpp_eligible
          ? selectedItem.fte_ttc_annual_salary * (selectedItem.gpp_guideline_max / 100) + selectedItem.mandatory_ttc_increase_amount
          : 0
        : 0,
    [selectedItem]
  );

  const gpp_spending = useMemo(
    () =>
      selectedItem
        ? selectedItem.promoted && selectedItem.is_gpp_eligible
          ? selectedItem.gpp_increase_amount + selectedItem.mandatory_ttc_increase_amount
          : 0
        : 0,
    [selectedItem]
  );

  const gpp_balance = useMemo(
    () =>
      selectedItem
        ? selectedItem.gpp_budget - selectedItem.gpp_spending
        : 0,
    [selectedItem]
  );

  const total_spending = useMemo(
    () =>
      selectedItem
        ? selectedItem.ttc_increase_amount + (selectedItem.is_gpp_eligible ? selectedItem.gpp_spending : 0)
        : 0,
    [selectedItem]
  );

  const offcycle_budget_update = useMemo(
    () =>
      selectedItem && selectedItem.new_fte_ttc_annual_salary
        ? selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary
        : 0,
    [selectedItem]
  );

  const payment_type = useMemo(
    () => {
      const activeData = data.request ? data.request : data;
      if (activeData.payment_type) return activeData.payment_type;
      let paymentType = filterPaymentTypes()[0];
      if (selectedItem && selectedItem.payment_type) {
        paymentType = selectedItem.payment_type
      }
      return paymentType;
    },
    [selectedItem, data]
  );

  const payment_reason = useMemo(
    () => {
      return selectedItem && selectedItem.payment_reason ? selectedItem.payment_reason : "Merit lump-sum";
    },
    [selectedItem]
  );

  const target_budget_org = useMemo(
    () => {
      const column = columns.find(c => c.name === "target_budget_org");

      const isAllowed = (column, account) => {
        if (column.allow) {
          return column.allow.find(role => account.roles.find(arole => arole === role));
        }
        return true;
      };
      // Not Allowed for role
      if (!column || !isAllowed(column, account)) return undefined;
      // Manual change
      const activeData = data.request ? data.request : data;
      if (activeData.target_budget_org) return activeData.target_budget_org;
      // Pass on calculate or default value
      return selectedItem
        ? selectedItem.target_budget_org
          ? selectedItem.target_budget_org
          : selectedItem.organization_unit_4 || selectedItem.organization_unit_3 || selectedItem.organization_unit_2 || selectedItem.organization_unit_1
        : undefined
    },
    [selectedItem, data, columns, account]
  );
  /**Release 2 Our Talent - starts*/
  const sip_change = useMemo(
    () => {
      if (selectedItem) {
        if (data && data.request_type == "internalhire" && data.promotion_job_family_name) {
          if(selectedItem.job_family_name === data.promotion_job_family_name  ){
            return false;
          }
          else if ((selectedItem.job_family_name == "Sales" || selectedItem.job_family_name == "Pre-Sales") || (data.promotion_job_family_name == "Pre-Sales" || data.promotion_job_family_name == "Sales" )) {
            return true;
          }

          else {
            return false;
          }
        }
        else  if (data.request && data.request_type == "internalhire" && data.request.promotion_job_family_name) {
          if(selectedItem.job_family_name === data.request.promotion_job_family_name  ){
            return false;
          }
          else if ((selectedItem.job_family_name == "Sales" || selectedItem.job_family_name == "Pre-Sales") || (data.request.promotion_job_family_name == "Pre-Sales" || data.request.promotion_job_family_name == "Sales" )) {
            return true;
          }

          else {
            return false;
          }
        }
        else if (selectedItem.sip_change) {
          return selectedItem.sip_change;
        }
      }
    },
    [selectedItem, data]
  )
  const current_combo_situation = useMemo(
    () => {
      const { countryLookupTable } = config;
      console.log("ot calc", selectedItem);
      if (selectedItem) {
        const match = countryLookupTable.find(obj => obj.country === selectedItem.country_name);
        return match ? match.grade_progression.trim() + " and " + selectedItem.ttc_employee_quartile : "";
      }
      else {
        return "";
      }
    },
    [selectedItem, config]
  );
  const promo_increase_pct_guideline_min = useMemo(
    () => {
      const { increaseLookupTable } = config;
      if (!selectedItem || !selectedItem.current_combo_situation) return 0.00;
      const match = increaseLookupTable.find(obj => obj.combo === selectedItem.current_combo_situation);
      return match ? match.min : 0;
    },
    [selectedItem, config]
  );
  const promo_increase_pct_guideline_max = useMemo(
    () => {
      const { increaseLookupTable } = config;
      if (!selectedItem || !selectedItem.current_combo_situation) return 0.00;
      const match = increaseLookupTable.find(obj => obj.combo === selectedItem.current_combo_situation);
      return match ? match.max : 0;
    },
    [selectedItem, config]
  );

  const promotional_increase_percentage_guideline = useMemo(
    () => {
      if (selectedItem) {

        // console.log("per issue",selectedItem.promo_increase_pct_guideline_max);
        return (selectedItem.promo_increase_pct_guideline_min * 100) + "% to " + (selectedItem.promo_increase_pct_guideline_max * 100) + "%";
      }
      else {
        return "";
      }
    },
    [selectedItem]
  );
/*   const new_incentive_scheme = useMemo(
    () => {

     if(selectedItem){

    if (data.new_incentive_scheme !== undefined) { 

          return data.new_incentive_scheme;
        }
        if (selectedItem.new_incentive_scheme) { return selectedItem.new_incentive_scheme }
        else {
          return selectedItem.job_family_name !== "Pre-Sales" && selectedItem.job_family_name !== "Sales" ? "Non-Sales" : selectedItem.job_family_name;
        }
      }

     }
  ,

    [selectedItem, data]
  ); */
  const rr_number = useMemo(
    () => {

      if (selectedItem) {
        if (data.rr_number !== undefined) {

          return data.rr_number;
        }
        else if (data.request && data.request.rr_number !== undefined) {
          return data.request.rr_number;
        }
        else {

        return selectedItem.rr_number;
      }

    }
  },
    [selectedItem, data]
  );
  const case_3_12_month_actual_incentive_amount_fte = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.sip_change) {

          if (data.case_3_12_month_actual_incentive_amount_fte !== undefined) {
            const v = isNaN(data.case_3_12_month_actual_incentive_amount_fte) ? 0 : data.case_3_12_month_actual_incentive_amount_fte;
            return Math.abs(v);
          }
          else if (selectedItem.case_3_12_month_actual_incentive_amount_fte) { return selectedItem.case_3_12_month_actual_incentive_amount_fte; }
          else {
            return 0;
          }
        }
        else {
          return 0;
        }
      }
    },
    [selectedItem, data]
  );
  const override_transition_allowance_local_currency = useMemo(
    () => {
      if (selectedItem) {
        if (selectedItem.sip_change) {
          if (data.override_transition_allowance_local_currency !== undefined) {
            const v = isNaN(data.override_transition_allowance_local_currency) ? 0 : data.override_transition_allowance_local_currency;
            return Math.abs(v);
          }
          else if (selectedItem.override_transition_allowance_local_currency) {
            return selectedItem.override_transition_allowance_local_currency;
          }
          else {
            return 0;
          }
        }
        else {
          return 0;
        }
      }
    },
    [selectedItem, data]
  );

  const internalhire_off_cycle_spending = useMemo(
    () => {
      if (selectedItem) {
        if (typeof selectedItem.promo_increase_pct_guideline_max == 'undefined') { return 0; }
        if (data.request_type == "internalhire" && selectedItem.promoted && selectedItem.ttc_increase_percentage > (selectedItem.promo_increase_pct_guideline_max * 100 )) {
          return ((selectedItem.ttc_increase_percentage - (selectedItem.promo_increase_pct_guideline_max*100))/100) * selectedItem.fte_ttc_annual_salary
        }

        else if (data.request_type == "internalhire" && selectedItem.promoted && selectedItem.ttc_increase_percentage <= (selectedItem.promo_increase_pct_guideline_max*100)) {
          return 0;
        }


        return selectedItem.new_fte_ttc_annual_salary ? selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary : 0;


      }
    },
    [selectedItem, data]
  )
  const internal_hire_off_cycle_spending_euro = useMemo(
    () => {
      if (selectedItem) {
        if (!selectedItem.internalhire_off_cycle_spending) { return 0; }
        return selectedItem.exchange_rate ? selectedItem.internalhire_off_cycle_spending * selectedItem.exchange_rate : 0;
      }
    },
    [selectedItem]
  );

  const is_sip = useMemo(
    () => {
      if (selectedItem) {
        if (selectedItem.promotion_job_family_name) {
          if ((selectedItem.promotion_job_family_name !== 'Sales' && selectedItem.promotion_job_family_name !== 'Pre-Sales') && (selectedItem.job_family_name !== 'Sales' && selectedItem.job_family_name !== "Pre-Sales")) {
            return false;
          }
          else if(selectedItem.promotion_job_family_name == selectedItem.job_family_name) {
            return false;
          }
          else{
            return true;
          }
        }
        else {
          return false;
        }
      }
    },
    [selectedItem]
  );

  const Max_Promotional_Increase = useMemo(
    () => {
      if(selectedItem){
        return selectedItem.promoted ? selectedItem.promo_increase_pct_guideline_max:0;
      }

    },
    [selectedItem]
  )

  const Case_One_Max_Budget_calc = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          const negativeCheck = selectedItem.promotion_incentive_target_percentage == 100 ? (selectedItem.fte_annual_salary * 0.8 )+ (selectedItem.fte_annual_salary * 0.8 * (selectedItem.promotion_incentive_target_percentage/100)) - selectedItem.fte_ttc_annual_salary :
           ( selectedItem.fte_annual_salary * 0.9 )+ (selectedItem.fte_annual_salary * 0.9 * (selectedItem.promotion_incentive_target_percentage/100)) - selectedItem.fte_ttc_annual_salary;

          if (negativeCheck <= 0) {
            return 0;
          }

          else {
            return selectedItem.promotion_incentive_target_percentage == 100 ?( selectedItem.fte_annual_salary * 0.8) +( selectedItem.fte_annual_salary * 0.8 * (selectedItem.promotion_incentive_target_percentage/100)) - selectedItem.fte_ttc_annual_salary :
             ( selectedItem.fte_annual_salary * 0.9) +( selectedItem.fte_annual_salary * 0.9* (selectedItem.promotion_incentive_target_percentage/100)) - selectedItem.fte_ttc_annual_salary
          }
        }
      }

    },
    [selectedItem]
  );
  const Case_one_TTC_increase_minus_Max_budget = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          return selectedItem.ttc_increase_amount - selectedItem.Case_One_Max_Budget_calc
        }
      }

    },
    [selectedItem]
  );
  const Case_one_Budget_charge = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          return selectedItem.Case_one_TTC_increase_minus_Max_budget <= 0 ? 0 : selectedItem.Case_one_TTC_increase_minus_Max_budget;
        }
      }

    },
    [selectedItem]
  );
  const Case_two_TI_Increase = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          return selectedItem.Case_One_Max_Budget_calc ? selectedItem.Case_One_Max_Budget_calc : 0;
        }
      }

    },
    [selectedItem]
  );

  const Case_two_Promotion_Budget = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          if (selectedItem.promoted) {
            return selectedItem.fte_ttc_annual_salary * selectedItem.promo_increase_pct_guideline_max;
          }
          else {
            return 0;
          }
        }
      }

    },
    [selectedItem]
  );

  const Case_two_Total_budget = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          return selectedItem.Case_two_Promotion_Budget + selectedItem.Case_two_TI_Increase;
        }
      }

    },
    [selectedItem]
  );

  const Case_two_Budget_charge = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          if (selectedItem.ttc_increase_amount - selectedItem.Case_two_Total_budget <= 0) {
            return 0;
          }
          else {

            return selectedItem.ttc_increase_amount - selectedItem.Case_two_Total_budget
          }
        }
      }

    },
    [selectedItem]
  );

  const Case_three_twelve_month_Total_Target_Cash = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          return selectedItem.fte_annual_salary + selectedItem.case_3_12_month_actual_incentive_amount_fte;
        }
      }

    },
    [selectedItem]
  );

  const Case_three_new_TTC_Gap_to_twelve_month_TTC = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          return selectedItem.Case_three_twelve_month_Total_Target_Cash - selectedItem.new_fte_ttc_annual_salary;
        }
      }

    },
    [selectedItem]
  );

  const Case_three_Max_capped_transit_allowance = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          return selectedItem.case_3_12_month_actual_incentive_amount_fte * 0.5;
        }
      }

    },
    [selectedItem]
  );
  const Case_three_Transition_allowance_total = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          if (selectedItem.Case_three_new_TTC_Gap_to_twelve_month_TTC > 0) {
           return Math.min(selectedItem.Case_three_Max_capped_transit_allowance, selectedItem.Case_three_new_TTC_Gap_to_twelve_month_TTC)
          }
          else {
            return 0;
          }
        }
      }

    },
    [selectedItem]
  );




  const Case_three_Budget_charge = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {


          const negativeCheck = selectedItem.override_transition_allowance_local_currency ? 
          selectedItem.override_transition_allowance_local_currency + (selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary - (selectedItem.fte_annual_salary * selectedItem.Max_Promotional_Increase )) :
           selectedItem.Case_three_Transition_allowance_total +( selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary - (selectedItem.fte_annual_salary * selectedItem.Max_Promotional_Increase )) 
          ;

          if (negativeCheck <= 0) {
            return 0;
          }

          else {
            return selectedItem.override_transition_allowance_local_currency ? 
            selectedItem.override_transition_allowance_local_currency + (selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary - (selectedItem.fte_annual_salary * selectedItem.Max_Promotional_Increase )) :
             selectedItem.Case_three_Transition_allowance_total +( selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary - (selectedItem.fte_annual_salary * selectedItem.Max_Promotional_Increase )) 
          }
        }
      }

    },
    [selectedItem]
  );
  const sip_case = useMemo(() => {
    if (selectedItem) {
      const currentJobFamily = selectedItem.job_family_name != "Pre-Sales" && selectedItem.job_family_name != "Sales" ? "Non-Sales" : selectedItem.job_family_name;
      const promo_status = selectedItem.promoted ? "YES" : "NO";
      const targetjobfamily = selectedItem.promotion_job_family_name != "Pre-Sales" && selectedItem.promotion_job_family_name != "Sales" ? "Non-Sales" : selectedItem.promotion_job_family_name;
      if (selectedItem.promotion_job_family_name) {


        return currentJobFamily + " to " + targetjobfamily + " Promo " + promo_status;
      }
      else {
        return currentJobFamily + " to " + currentJobFamily + " Promo " + promo_status;
      }
    }
  },
    [selectedItem])
  const Final_Off_Cycle_Budget_euro = useMemo(
    () => {
      if (selectedItem) {
        if (selectedItem.sip_case) {

          if (selectedItem.sip_case == "Non-Sales to Sales Promo NO") { return selectedItem.Case_one_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_one_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Non-Sales to Pre-Sales Promo NO") { return selectedItem.Case_one_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_one_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Non-Sales to Sales Promo YES") { return selectedItem.Case_two_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_two_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Non-Sales to Pre-Sales Promo YES") { return selectedItem.Case_two_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_two_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Sales to Non-Sales Promo NO") { return selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Sales to Non-Sales Promo YES") { return selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Pre-Sales to Non-Sales Promo NO") { return selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Pre-Sales to Non-Sales Promo YES") { return selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Pre-Sales to Sales Promo YES") { return selectedItem.Case_two_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_two_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Pre-Sales to Sales Promo NO") { return selectedItem.Case_one_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_one_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Sales to Pre-Sales Promo YES") { return selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate }
          else if (selectedItem.sip_case == "Sales to Pre-Sales Promo NO") { return selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate <= 0 ? 0 : selectedItem.Case_three_Budget_charge * selectedItem.exchange_rate }
          else { return selectedItem.internal_hire_off_cycle_spending_euro; }
        }
        else { return ''; }
      }
    },
    [selectedItem]
  )

  const Final_Off_Cycle_Budget= useMemo(
    () => {
      if (selectedItem) {
        if (selectedItem.sip_case) {

          if (selectedItem.sip_case == "Non-Sales to Sales Promo NO") { return selectedItem.Case_one_Budget_charge  <= 0 ? 0 : selectedItem.Case_one_Budget_charge }
          else if (selectedItem.sip_case == "Non-Sales to Pre-Sales Promo NO") { return selectedItem.Case_one_Budget_charge  <= 0 ? 0 : selectedItem.Case_one_Budget_charge  }
          else if (selectedItem.sip_case == "Non-Sales to Sales Promo YES") { return selectedItem.Case_two_Budget_charge  <= 0 ? 0 : selectedItem.Case_two_Budget_charge  }
          else if (selectedItem.sip_case == "Non-Sales to Pre-Sales Promo YES") { return selectedItem.Case_two_Budget_charge  <= 0 ? 0 : selectedItem.Case_two_Budget_charge  }
          else if (selectedItem.sip_case == "Sales to Non-Sales Promo NO") { return selectedItem.Case_three_Budget_charge  <= 0 ? 0 : selectedItem.Case_three_Budget_charge  }
          else if (selectedItem.sip_case == "Sales to Non-Sales Promo YES") { return selectedItem.Case_three_Budget_charge  <= 0 ? 0 : selectedItem.Case_three_Budget_charge  }
          else if (selectedItem.sip_case == "Pre-Sales to Non-Sales Promo NO") { return selectedItem.Case_three_Budget_charge  <= 0 ? 0 : selectedItem.Case_three_Budget_charge  }
          else if (selectedItem.sip_case == "Pre-Sales to Non-Sales Promo YES") { return selectedItem.Case_three_Budget_charge  <= 0 ? 0 : selectedItem.Case_three_Budget_charge }
          else if (selectedItem.sip_case == "Pre-Sales to Sales Promo YES") { return selectedItem.Case_two_Budget_charge  <= 0 ? 0 : selectedItem.Case_two_Budget_charge }
          else if (selectedItem.sip_case == "Pre-Sales to Sales Promo NO") { return selectedItem.Case_one_Budget_charge  <= 0 ? 0 : selectedItem.Case_one_Budget_charge  }
          else if (selectedItem.sip_case == "Sales to Pre-Sales Promo YES") { return selectedItem.Case_three_Budget_charge  <= 0 ? 0 : selectedItem.Case_three_Budget_charge  }
          else if (selectedItem.sip_case == "Sales to Pre-Sales Promo NO") { return selectedItem.Case_three_Budget_charge  <= 0 ? 0 : selectedItem.Case_three_Budget_charge  }
          else { return selectedItem.internalhire_off_cycle_spending; }
        }
        else { return ""; }
      }
    },
    [selectedItem]
  )

  const internalhire_increase_amount   = useMemo(
    () => {
      return selectedItem ? selectedItem.ttc_increase_amount : 0;
     },
     [selectedItem]
  )
  const internalhire_increase_percentage   = useMemo(
    () => {
      return selectedItem ? selectedItem.ttc_increase_percentage : 0;
     },
     [selectedItem]
  )
  const hiring_manager = useMemo(
    () => {

      if(selectedItem){

        const ActiveData= data.request ? data.request : data;
        return ActiveData.hiringmanager ? ActiveData.hiringmanager : ( selectedItem.hiring_manager ? selectedItem.hiring_manager : ""); 

      }

     
    },
    [selectedItem, data]
  )


  /**Release 2 Our Talent - ends*/
  const calcData = useMemo(
    () => {
      return {
        salary_range_spread,
        exchange_rate,
        tenure,
        payment_type,
        payment_reason,
        is_gpp_eligible,
        ttc_increase_amount,
        ttc_increase_percentage,
        gpp_increase_amount,
        gpp_increase_percentage,
        gpp_guideline_max,
        promotion_job_family_name,
        promotion_job_subfamily_name,
        promotion_job_name,
        promotion_pay_grade,
        promotion_job_grade,
        promoted,
        demoted,
        promotion_job_grade_delta,
        promotion_incentive_target_percentage,
        mandatory_ttc_increase_percentage,
        mandatory_ttc_increase_amount,
        apply_ti_harmonization,
        interim_harmonization_type,
        interim_job_family_name,
        interim_job_grade,
        interim_pay_grade,
        interim_salary_range_min,
        interim_salary_range_mid,
        interim_salary_range_max,
        interim_salary_range_spread,
        interim_fte_incentive_target_amount,
        interim_incentive_target_percentage,
        interim_total_increase_amount,
        interim_increase_amount_for_salincr,
        interim_increase_amount_for_tih,
        step_1_abs,
        step_1_ita,
        step_1_itp,
        interim_theo_itp,
        interim_itp_situation,
        step_2_abs_gap,
        step_2_ita_gap,
        interim_remainder_for_split,
        new_fte_annual_salary,
        new_fte_incentive_target_amount,
        new_incentive_target_percentage,
        new_fte_ttc_annual_salary,
        new_itp_situation,
        new_ttc_check,
        new_ttc_compa_ratio,
        new_quartile,
        new_incentive_plan_type,
        individual_budget_amount,
        individual_spending_amount,
        individual_balance_amount,
        gpp_budget,
        gpp_spending,
        gpp_balance,
        total_spending,
        offcycle_budget_update,
        override_fte_incentive_target_percentage,
        override_fte_ttc_annual_salary,
        target_budget_org,
        new_position_title,
        ttc_increase,
        ttc_increase_perc,
        /**Release 2 our talent */
        current_combo_situation,
        promo_increase_pct_guideline_min,
        promo_increase_pct_guideline_max,
        promotional_increase_percentage_guideline,
        rr_number,
        case_3_12_month_actual_incentive_amount_fte,
        override_transition_allowance_local_currency,
       // new_incentive_scheme,
        internalhire_off_cycle_spending,
        internal_hire_off_cycle_spending_euro,
        is_sip,
        Case_One_Max_Budget_calc,
        Case_one_TTC_increase_minus_Max_budget,
        Case_one_Budget_charge,
        Case_two_TI_Increase,
        Case_two_Promotion_Budget,
        Case_two_Total_budget,
        Case_two_Budget_charge,
        Case_three_twelve_month_Total_Target_Cash,
        Case_three_new_TTC_Gap_to_twelve_month_TTC,
        Case_three_Max_capped_transit_allowance,
        Case_three_Transition_allowance_total,
        Case_three_Budget_charge,
        sip_case,
        Final_Off_Cycle_Budget,
        Final_Off_Cycle_Budget_euro,
        sip_change,
        internalhire_increase_amount,
        internalhire_increase_percentage,
        hiring_manager,
        Max_Promotional_Increase 

      };
    },
    [
      salary_range_spread,
      exchange_rate,
      tenure,
      payment_type,
      payment_reason,
      is_gpp_eligible,
      ttc_increase_amount,
      ttc_increase_percentage,
      gpp_increase_amount,
      gpp_increase_percentage,
      gpp_guideline_max,
      promotion_job_family_name,
      promotion_job_subfamily_name,
      promotion_job_name,
      promotion_pay_grade,
      promotion_job_grade,
      promoted,
      demoted,
      promotion_job_grade_delta,
      promotion_incentive_target_percentage,
      mandatory_ttc_increase_percentage,
      mandatory_ttc_increase_amount,
      apply_ti_harmonization,
      interim_harmonization_type,
      interim_job_family_name,
      interim_job_grade,
      interim_pay_grade,
      interim_salary_range_min,
      interim_salary_range_mid,
      interim_salary_range_max,
      interim_salary_range_spread,
      interim_fte_incentive_target_amount,
      interim_incentive_target_percentage,
      interim_total_increase_amount,
      interim_increase_amount_for_salincr,
      interim_increase_amount_for_tih,
      step_1_abs,
      step_1_ita,
      step_1_itp,
      interim_theo_itp,
      interim_itp_situation,
      step_2_abs_gap,
      step_2_ita_gap,
      interim_remainder_for_split,
      new_fte_annual_salary,
      new_fte_incentive_target_amount,
      new_incentive_target_percentage,
      new_fte_ttc_annual_salary,
      new_itp_situation,
      new_ttc_check,
      new_ttc_compa_ratio,
      new_quartile,
      new_incentive_plan_type,
      individual_budget_amount,
      individual_spending_amount,
      individual_balance_amount,
      gpp_budget,
      gpp_spending,
      gpp_balance,
      total_spending,
      offcycle_budget_update,
      override_fte_incentive_target_percentage,
      override_fte_ttc_annual_salary,
      target_budget_org,
      new_position_title,
      ttc_increase,
      ttc_increase_perc,
      /**Release 2 our talent */
      current_combo_situation,
      promo_increase_pct_guideline_min,
      promo_increase_pct_guideline_max,
      promotional_increase_percentage_guideline,
      rr_number,
      case_3_12_month_actual_incentive_amount_fte,
      override_transition_allowance_local_currency,
     // new_incentive_scheme,
      internalhire_off_cycle_spending,
      internal_hire_off_cycle_spending_euro,
    
      is_sip,
      Case_One_Max_Budget_calc,
      Case_one_TTC_increase_minus_Max_budget,
      Case_one_Budget_charge,
      Case_two_TI_Increase,
      Case_two_Promotion_Budget,
      Case_two_Total_budget,
      Case_two_Budget_charge,
      Case_three_twelve_month_Total_Target_Cash,
      Case_three_new_TTC_Gap_to_twelve_month_TTC,
      Case_three_Max_capped_transit_allowance,
      Case_three_Transition_allowance_total,
      Case_three_Budget_charge,
      sip_case,
      Final_Off_Cycle_Budget,
      Final_Off_Cycle_Budget_euro,
      sip_change,
      internalhire_increase_amount,
      internalhire_increase_percentage,
      hiring_manager,
      Max_Promotional_Increase 
    ]
  );

  /* added below code for resolution for defect #16 and #17--START */

  function isNegative(n) {
    return ((n = +n) || 1 / n) < 0;
  }

  function UpdateN() {

    let ttc_increase_amount = document.querySelectorAll(
      'input[name="ttc_increase_amount"]'
    );
    let ttc_increase_percentage = document.querySelectorAll(
      'input[name="ttc_increase_percentage"]'
    );
    /**Release 2 Our Talent - scenarios change starts */
    let new_fte_annual_salary = document.querySelectorAll(
      'input[name="new_fte_annual_salary"]'
    );
    let new_fte_incentive_target_amount = document.querySelectorAll(
      'input[name="new_fte_incentive_target_amount"]'
    );

    /**Release 2 Our Talent - scenarios change Ends */
    ttc_increase_amount.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    ttc_increase_percentage.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    /**Release 2 Our Talent - scenarios change starts */
    new_fte_annual_salary.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    new_fte_incentive_target_amount.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    /**Release 2 Our Talent - Scenarios Change Ends */
  }

  /* code for resolution for defect #16 and #17--END */


  const updateEmployeeData = useCallback(
    () => {
      if (Object.keys(data).length) {
        const updateData = recordType === 'employees'
          ? { ...data, ...calcData }
          : { request: { ...data.request, ...calcData } };
        updateRecord(item, indexColumn, updateData, recordType);
      }
      /* added below code for resolution for defect #16 and #17--START */
      setTimeout(() => {
        UpdateN();
      }, 300);
      /* code for resolution for defect #16 and #17--END */
    },
    [data, calcData, item, indexColumn, updateRecord, recordType]
  );


  useEffect(() => {
    updateEmployeeData(data, calcData);
  }, [data, calcData, updateEmployeeData]);

  console.log("Test the flow of calculation");
  return null;
};

const mapStateToProps = state => {
  return {
    selectedEmployees: state.selectedEmployees,
    selectedRequests: state.selectedRequests,
    config: state.config,
    account: state.account
  };
};

export default connect(
  mapStateToProps,
  { updateRecord }
)(Calculator);
