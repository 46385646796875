import { currencyDisplayRenderer } from '../../renderers';

export default (table) => {
  let name = 'fte_ttc_annual_salary';
  let visible_in_tooltip = /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
  (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true;
  /* Release 3.2 - 1.1 - Ends */;
  let renderer = currencyDisplayRenderer;

  switch (table) {
    case 'editCompensation':
    case 'editOnetime':
    /* Release 3.2 - 1.1 - Starts */
    case 'editBEA':
    /* Release 3.2 - 1.1 - Ends */
    case 'rejectRequests':
    case 'approveRequests':
      case 'editInternalHire':
      name = 'request.for.fte_ttc_annual_salary';
      renderer = (value, data, account, config) => currencyDisplayRenderer(value, data.request, account, config); 
      break;
    case 'myRequests':
    case 'requestsHistory':
    case 'approvedRequestHistory':
    case 'pendingRequests':
      name = 'for.fte_ttc_annual_salary';
      break;
    default:
      break;
  }

  return { 
    name,
    label: "Total Target Cash",
    tooltip_section: "Current",
    visible_in_tooltip,
    renderer
  };
};