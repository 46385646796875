import React from "react";
import { connect } from "react-redux";
import { selectRecord, unselectRecord } from "../../../actions";
import { capitalize } from "../../../util";
import {
  TableHeaderRowWrap,
  CellWrap,
  SortWrap,
  SortEl,
  SelectCellWrap,
} from "../../";

const TableHeader = (props) => {
  const {
    account,
    infoTooltip,
    recordType,
    selectable,
    sortable,
    columns,
    sort,
    onSort,
    items = [],
    selectRecord,
    unselectRecord,
    /* DEM0057819 Starts  */ selectedEmployees /* DEM0057819 Ends  */,
    /* Release 3.1 - Starts */ checkboxIsDisplayed = false /* Release 3.1 - Ends */,
  } = props;
  const indexColumn = columns.find((c) => c.index);
  const isSelected = (item) =>
    props[`selected${capitalize(recordType)}`]
      ? props[`selected${capitalize(recordType)}`].find(
          (i) => i[indexColumn.name] === item[indexColumn.name]
        ) || false
      : false;
  const allSelected = items.length && items.every(isSelected);

  /* FIXING DELEGATION ISSUE STARTS
  Changed the toggleAllRows function as per requirement */
  /*const toggleAllRows = event =>
    items.map((item) => event.target.checked ? selectRecord(item, indexColumn, recordType) : unselectRecord(item, indexColumn, recordType));
  */
  const toggleAllRows = (event) => {
    if (indexColumn.section == "Delegation List Page") {
      items.map((item) =>
        event.target.checked &&
        item.delegation_pers_no.pers_no != account.pers_no &&
        item.is_active != 2
          ? selectRecord(item, indexColumn, recordType)
          : unselectRecord(item, indexColumn, recordType)
      );
    } else {
      items.map((item) =>
        event.target.checked
          ? selectRecord(item, indexColumn, recordType)
          : unselectRecord(item, indexColumn, recordType)
      );
    }
  };
  /* FIXING DELEGATION ISSUE ENDS */

  const isAllowed = (column) => {
    if (column.allow) {
      /* DEM0057819 Starts  */
      //console.log(column, "=====>column")
      if (
        (column.mom_title == "requestCompesation" ||
          column.mom_title == "requestOnetime" ||
          /* Release 3.2 - 1.1 - Starts */
          column.mom_title == "requestBusinessExcellenceAward"
          /* Release 3.2 - 1.1 - Ends */
          ) &&
        column.name == "target_budget_org"
      ) {
        var selectedEmployeeIsSubordinate = 0;
        if (selectedEmployees.length > 0) {
          for (var incr = 0; incr < selectedEmployees.length; incr++) {
            if (
              selectedEmployees[incr].line_manager_pers_no == account.pers_no
            ) {
              console.log("USER IS SUBORDINATE");
              selectedEmployeeIsSubordinate = 1;
              break;
            }
          }
          column.allow = ["hr1", "hr2", "hr3", "bcb"];
          console.log(account);
          if (selectedEmployeeIsSubordinate == "1") {
            if (account.roles.length > 0) {
              for (var incr = 0; incr < account.roles.length; incr++) {
                if (
                  account.roles[incr] == "hr1" ||
                  account.roles[incr] == "hr2" ||
                  account.roles[incr] == "hr3"
                ) {
                  //column.allow = ["bcb"];
                  column.allow = [];
                  break;
                }
              }
            }
          }
        }
      }
      console.log(column, "COLUMNS FINALLY======>");
      /* DEM0057819 Ends  */
      return column.allow.find((role) =>
        account.roles.find((arole) => arole === role)
      );
    }
    return true;
  };
  /*  ADD A NEW ROLE FOR ADMIN FUNCTIONALITY 19 */
  const isAdmin = account.roles.includes("admin");
  /* Release 3.2 - 10.1 - Starts */
  const isRcb = account.roles.includes("rcb");
  const isBCB = account.roles.includes("bcb");
  /* Release 3.2 - 10.1 - Ends */
  //DEM0058258
  //console.log(indexColumn)
  const isHRC = account.roles.includes("hrc");
  /* Release 3.2 - 10.1 - Starts */
  const isHr =
    account.roles.includes("hr1") ||
    account.roles.includes("hr2") ||
    account.roles.includes("hr3") ||
    account.roles.includes("chr") ||
    isHRC ||
    isBCB ||
    account.roles.includes("rhr");
  const isGct = account.roles.includes("gct");
  const isManager = account.roles.includes("manager");
  const isSuperManager = account.roles.includes("supermanager");
  const isGTap = account.roles.includes("gtap");
  const isRTap = account.roles.includes("rtap");
  const isKeyUser = account.roles.includes("keyuser");
  const isTap = isGTap || isRTap;
  const isOnlyRcb =
    isRcb && !isTap && !isGct && !isManager && !isSuperManager && !isAdmin && !isHr && !isKeyUser;
  /* Release 3.2 - 10.1 - Ends */
  /* Release 3.2 - 10.3 - Starts */
  const isOnlyKeyUser =
    !account.roles.includes("manager") &&
    !account.roles.includes("supermanager") &&
    !account.roles.includes("bcb") &&
    !account.roles.includes("gct") &&
    !account.roles.includes("rcb") &&
    !account.roles.includes("admin") &&
    !account.roles.includes("gtap") &&
    !account.roles.includes("rtap") &&
    account.roles.includes("keyuser") &&
    !account.roles.includes("hr1") &&
    !account.roles.includes("hr2") &&
    !account.roles.includes("hr3") &&
    !account.roles.includes("chr") &&
    !account.roles.includes('hrc') &&
    !account.roles.includes('bg_head') &&
    !account.roles.includes('bl2') &&
    !account.roles.includes('bl3') &&
    !account.roles.includes('rhr')&&
    !account.roles.includes('business_owner');
  /* Release 3.2 - 10.3 - Ends */
  var checkboxIsDisplayedForHRC = "yes";
  if (isHRC && indexColumn.section == "AllRequestHRC") {
    checkboxIsDisplayedForHRC = "no";
  }
  /* Release 3.1 - Starts */ 
  else if (!isAdmin && (indexColumn.section === "approvedRequestHistory"
          /* Release 3.2 - 10.3 - Starts */ 
          || indexColumn.section === "AllRecquestsHistory" 
          /* Release 3.2 - 10.3 - Ends */
          /* Release 3.2 - 8.1 - Starts */
          || indexColumn.section === "employeehistoryaddresssection"
          || indexColumn.section === "employeePerformanceDashboard"
          || indexColumn.section === "targetIncentiveHistory"
          || indexColumn.section === "salaryHistory"
          || indexColumn.section === "organizationalHistory"
          || indexColumn.section === "actualPaidIncentiveHistory"
          || indexColumn.section === "jobGradeHistory"
          /* Release 3.2 - 8.1 - Ends */
          /* Release 3.2 - 10.1 - Starts */
          || ((indexColumn.section === "employees" || indexColumn.section === "approvedRequestsHistory") && (isOnlyRcb || isOnlyKeyUser))
          || (indexColumn.section === "approvedRequestsHistory" && isBCB)
          /* Release 3.2 - 10.1 - Ends */
          /* Release 3.3 - 8.3 - Starts */
          || indexColumn.section === "roCountryStatistics"
          || indexColumn.section === "roInsuranceTaxData"
          || indexColumn.section === "frLocalPayAttributes"
          || indexColumn.section === "hcrLSGroupcompensation"
          /* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
          || indexColumn.section === "latestInformation"
          || indexColumn.section === "referralAward"
          || indexColumn.section === "orgAssignmentIT0001"
          || indexColumn.section === "actionsIT0000"
          
          
          /* Release 3.3 - 8.3 - Ends */

          /*  DEM0069361 - TAP Access Part 3 Starts */
          || indexColumn.section === "roEducation"
          /*  DEM0069361 - TAP Access Part 3 Ends */
      )) {
    checkboxIsDisplayedForHRC = "no";
  }
  /* Release 3.2 - 9.1Req - Starts */
  if (!isAdmin && indexColumn.section == "internalHireSimulation") {
    checkboxIsDisplayedForHRC = "yes";
  }
  /* Release 3.2 - 9.1Req - Ends */
  /* Release 3.1 - Ends */
  return (
    <TableHeaderRowWrap style={{position: indexColumn.section === "beabusinessownerbudgetmanage" ? "unset" : "sticky"}}>
      {!isAdmin && checkboxIsDisplayedForHRC == "yes" && selectable && (
        <SelectCellWrap width="50px">
          {/* Release 3.2 - 9.1Req - Starts */}
          {
          (indexColumn.section == "internalHireSimulation"
          /* Release 3.2 - 8.1 - Starts */
          || indexColumn.section === "employeeDashboardHistory"
          /* Release 3.2 - 8.1 - Ends */
          )  ?
          <></> :          
          <input
            name="selectAll"
            type="checkbox"
            onChange={toggleAllRows}
            checked={allSelected}
          />
          }
          {/* Release 3.2 - 9.1Req - Ends */}
        </SelectCellWrap>
      )}
      {/* Release 3.1 - Starts */}
      {isAdmin && checkboxIsDisplayed == true && (
        <SelectCellWrap width="50px">
          {
          (indexColumn.section == "changeApproverRequests"
          /* Release 3.2 - 8.1 - Starts */
          || indexColumn.section === "employeeDashboardHistory"
          /* Release 3.2 - 8.1 - Ends */
          /* Release 3.2 - 1.1 - Starts */
          || indexColumn.section == "beabudgetmanage"
          || indexColumn.section == "beabusinessownerbudgetmanage"
          /* Release 3.2 - 1.1 - Ends */
          ) ?
          <></> :
          <input
            name="selectAll"
            type="checkbox"
            onChange={toggleAllRows}
            checked={allSelected}
          />
          }
        </SelectCellWrap>
      )}
      {/* Release 3.1 - Ends */}
      {columns.map((column) => {
        if (!column.visible || !isAllowed(column)) {
          return null;
        }
        const { placeholder, ...otherProps } = column;

        return (
          <CellWrap
            {...otherProps}
            context="header"
            key={Math.random()}
            style={{ background: "#ECF0F3" }}
          >
            {column.label || column.name}
            {sortable && column.sortable && (
              <SortWrap>
                <SortEl
                  asc
                  active={sort[column.name] === "ASC"}
                  onClick={onSort(column, "ASC")}
                />
                <SortEl
                  desc
                  active={sort[column.name] === "DESC"}
                  onClick={onSort(column, "DESC")}
                />
              </SortWrap>
            )}
          </CellWrap>
        );
      })}
      {/*  FIXING DELEGATION ISSUE: ADD condition*/}
      {infoTooltip && recordType !== "delegates" && indexColumn.section != "requestBusinessExcellenceAward" && (
        <CellWrap
          style={{ background: "#ECF0F3" }}
          context="header"
          width="70px"
        >
          Details
        </CellWrap>
      )}
    </TableHeaderRowWrap>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    selectedRequests: state.selectedRequests,
    /*  FIXING DELEGATION ISSUE */
    selectedDelegates: state.selectedDelegates,

    account: state.account,
  };
};

export default connect(mapStateToProps, { selectRecord, unselectRecord })(
  TableHeader
);
