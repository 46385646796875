import { optionsFilter } from '../../filters';

export default (table) => {
  let name = 'promotion_job_subfamily_name';
  let options = optionsFilter;
  let defaultValue = (value, data) => data.job_subfamily_name;

  switch (table) {
    case 'editCompensation':
      case 'editInternalHire':
      name = 'request.promotion_job_subfamily_name';
      options = (column, config, data) => optionsFilter(column, config, data.request);
      defaultValue = (value, data) => data.request.for.job_subfamily_name;
      break;
    default:
      break;
  }
let property = {
  type: "string",
  inputType: "select",
  name,
  label: "New Job Sub Family",
  section: "Promotion",
  visible: true,
  editable: true,
  options,
  defaultValue
};

if(table == "internalHire" || table == "editInternalHire"){
  property.width = "10%";
}
  return property;
};