export const convertToUTC = (date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
export const isCancellable = (approvals) => {
  if (!approvals || approvals.length === 0) return true;
  let isCancellable = false
  for(let approval of approvals) {
    if(approval.approval_status !== 'approved') {
      isCancellable = true
      break
    }
  }
  return isCancellable;
}

export const canRaiseRequest = (selectedEmployees, pers_no) => {
  if (!selectedEmployees) return false
  for(const emp of selectedEmployees){
    if(emp.line_manager_pers_no !== pers_no){
      return false
    }
  }
  return true
}
{/* DEFECT No. 14 STARTS  */}

// For conversion
export const getExchangeRateValueForItem =(value, data, account = {}, config = {}) => {
  const { forceCurrency } = account;
    const { exchangeRates = [], noDecimalCurrencies = [] } = config;
    const localCurrency = data.currency;
    const match = exchangeRates.find(r => r.to_currency === localCurrency && r.from_currency === forceCurrency);
    const exchangeRate = match ? 1 / match.exchange_rate : 1;
    const curr = forceCurrency ? forceCurrency : localCurrency;
    const hasNoDecimas = noDecimalCurrencies.find(c => c === curr);
    const decimalPlaces = hasNoDecimas ? 0 : 2;
    const val = forceCurrency ? value * exchangeRate : value;
    // return val;
    return parseFloat(val);

}

// for sorting  

export const compareValues=(key, order = 'asc')=>{
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }
  
    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };

}

{/* DEFECT No. 14 Ends  */}

function sortMethodAsc(a, b) {
  return a === b ? 0 : a > b ? 1 : -1;
}
 
function sortMethodDesc(a, b) {
  return -sortMethodAsc(a, b);
}
 
function sortMethodWithDirection(direction = "asc") { 
  return direction === "asc" ? sortMethodAsc : sortMethodDesc
}
 
function sortMethodWithDirectionByColumn(columnName, direction){   
  const sortMethod = sortMethodWithDirection(direction)
  return function(a, b){
      return sortMethod(a[columnName], b[columnName]);
  } 
}
 
export const sortByMultiColmn =(sortArray = [])=>{
 const sortMethodsForColumn = sortArray.map( item => sortMethodWithDirectionByColumn(item.column, item.direction) );
  return function(a,b) {
      let sorted = 0;
      let index = 0;
      while (sorted === 0 && index < sortMethodsForColumn.length) {
          sorted = sortMethodsForColumn[index++](a,b);
      }
      return sorted;
  }
}
/* Release 3.1 - Starts */
export const formatDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export const formatDateMonthFirst = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [month, day, year].join('/');
}
/* Release 3.1 - Ends */