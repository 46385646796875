import React from 'react';
import { StatusWrap } from '../';
import { capitalize } from '../../util';

const Status = props => {
  const { value } = props;
  const pending = ['open', 'pending'].includes(value) ? capitalize('pending') : null;
  const rejected = value === 'rejected' ? capitalize('rejected') : null;
  const canceled = value === 'canceled' ? capitalize('canceled') : null;
  const archived = value === 'archived' ? capitalize('archived') : null;
  const stopped = value === 'stopped' ? capitalize('stopped') : null;
  const approved = value === 'approved' ? capitalize('approved') : null;
  const review = value === 'review' ? capitalize('review') : null;
  return <StatusWrap {...props}>{pending || rejected || canceled || archived || stopped || approved || review}</StatusWrap>;
};

export { Status };
