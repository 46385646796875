import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { Ring } from 'react-awesome-spinners';
import { FormButton, FormButtonsWrap, ErrorsBlock, LoaderWrap, FormHeading, FormLabel, DescriptionInput, IconWrap } from '../';
import { REVIEW_REQUEST, approvals, organizationBEABudget } from '../../graphql';
import { unselectAllRecord, pushMessage } from '../../actions';

const SendbackRequest = props => {
  const { selectedRequests, account, hideModal, unselectAllRecord, pushMessage } = props;

  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);

  const [reviewRequest] = useMutation(REVIEW_REQUEST);

  const handleAbort = event => {
    hideModal();
    unselectAllRecord('requests');
  };

  const handleCommentChange = event => setComment(event.target.value);

  const handleSend = async event => {
    setIsSubmitting(true);
    setSubmitErrors([]);

    if (!comment) {
      setSubmitErrors(['Comment is required!']);
      setIsSubmitting(false);
      return null;
    }
    
    const results = await Promise.all(selectedRequests.map(async ({
        employee_request_id,
        approver_level
      }) => {
      try {
        // R2 - HR Page (comments)
        await reviewRequest({
          variables: { employee_request_id, approver_level, comment },
          awaitRefetchQueries: true,
          refetchQueries: [
            { query: approvals, variables: { 
              // TODO - get this from PendingRequests.js state 
              where: { approver_pers_no: { eq: account.pers_no }, approval_status: {eq: 'active' } },
              orderBy: [ { column: "employee_request_id", order: "DESC" } ],
              limit: 20,
              offset: 0
            } },
            {
              query: organizationBEABudget,
              variables: {
                where: { year: { eq: new Date().getFullYear().toString() } },
              },
            },
          ]
        });
        return {[employee_request_id]: false};
      } catch(e) {
        return {[employee_request_id]: e.message};
      } 
    }));

    const errors = results.filter(r => Object.keys(r).find(r_id => r[r_id]));
    if (!errors.length) {
      hideModal();
      unselectAllRecord('requests');
      pushMessage({
        type: 'success',
        message: 'Request(s) successfully sent back.'
      })
    } else {
      setSubmitErrors(errors.map(e => Object.values(e).shift()));
    }
    setIsSubmitting(false);
  };
 
  return (
    <div>
      <FormHeading>Are you sure you wish to send back {selectedRequests.length} requests ?</FormHeading>
      {/* <SelectedTable
        recordType="requests"
        items={selectedRequests}
        columns={columns.sendbackRequest}
      /> */}
      <FormLabel>Comment</FormLabel>
      <DescriptionInput
        name='comment'
        onChange={handleCommentChange}
      />
      {submitErrors.length > 0 && submitErrors.map(err => {
        return (
          <ErrorsBlock key={Math.random()}>
            <IconWrap>!</IconWrap>
            {err}
          </ErrorsBlock>
        );
      })}
      <FormButtonsWrap>
        {/* DEFECT No. 6 STARTS  */}
        <FormButton onClick={handleAbort}>Quit</FormButton>
        <FormButton primary disabled={isSubmitting} onClick={handleSend}>Send back request(s)</FormButton>
        {/* DEFECT No. 6 ENDS  */}
        {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
      </FormButtonsWrap>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account
  };
};

export default connect(
  mapStateToProps,
  { unselectAllRecord, pushMessage }
)(SendbackRequest);
