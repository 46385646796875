import { currencyDisplayRenderer, numberRenderer } from '../../renderers';

export default (table) => {
  let name = 'total_increaseed_amount';
  let renderer = (value) => value;
  let section = "";
  let label = "Total Increase Amount";
  let width = '100px';
  let inputType = null;
  let sortable = false;
  let editable = false;

  switch (table) {
    case 'myRequests':
      //console.log(config)
      renderer = currencyDisplayRenderer;
      //renderer = (value) => value;
        //Width Defect - px -> %
      width = "7%";
      section = "My Request Page";
      break;

    case 'editCompensation':
        //console.log(config)
        renderer = numberRenderer;
        //renderer = (value) => value;
        //width = "150px";
        section = "Edit Compensation Page";
        break;
    
    default:
      break;
  }

  return {
    type: "string",
    inputType,
    name,
    section,
    label,
    visible: true,
    sortable,
    editable,
    width,
    //before,
    renderer,
    
  };
};