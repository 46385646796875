import { currencyDisplayRenderer ,currencySymbolRenderer, numberRenderer} from '../../renderers';

export default (table) => {
  let name = 'new_fte_incentive_target_amount';
let defaultValue = (value, data) => data.new_fte_incentive_target_amount;
  let renderer = numberRenderer;
  switch (table) {

    case 'editInternalHire':
    name = 'request.new_fte_incentive_target_amount';

    defaultValue = (value, data) => data.request.new_fte_incentive_target_amount;
    break;
  default:
    break;
}

  return { 
    type: "float",
    name,
    label: "New Incentive Target Amount",
    section: "internalHire",
    colFrom : "internalHire",
    visible: true,
    defaultValue,
    renderer,
   
    editable : (value, data) => data.incentive_plan_type === "YLIP" ? true : false,
    inputType : "number",
    //before : currencySymbolRenderer,
    width : '10%',
    
  };
};