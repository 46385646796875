import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Grid } from "react-awesome-spinners";
import * as queries from "../../graphql";
import { TableHeader, Pagination, GridLoader, Row } from "../";
//{/* DEFECT No. 14 STARTS  */}
import {
  getExchangeRateValueForItem,
  compareValues,
  sortByMultiColmn,
} from "../../util/helper";
const fte_percentage = "fte_incentive_target_percentage";
let searchTxt = "";

//{/* DEFECT No. 14 Ends  */}

const DEFAULT_PAGE_SIZE = 20;

const Table = (props) => {
  // const { columns, query, where, recordType, order = {} } = props;

  {
    /* DEFECT No. 14 Statrs  */
  }

  const {
    columns,
    query,
    where,
    recordType,
    order = {},
    isEuroSort,
    account,
    config,
    Budgetfilter,
    editOnClick,
    isChange,
    noCheckBox,
    showCount,
    /* Release 3.1 - Starts */
    checkboxIsDisplayed = false,
    selectedRequests,
    /* Release 3.1 - Ends */
  } = props;

  {
    /* DEFECT No. 14 Ends  */
  }
  //console.log(props.Budgetfilter);

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  // const [columnsConfig, setColumnsConfig] = useState(columns);
  const indexColumn = columns.find((c) => c.index);
  // const [whereConfig, setWhereConfig] = useState(where);
  const [sort, setSort] = useState(order);
  //FIX PROD ISSUE OF DEFAULT SORT STARTS
  useEffect(() => {
    let tempSort = "";
    if (typeof isChange !== "undefined") {
      tempSort = {};
    } else {
      tempSort = sort;
    }
    setSort(tempSort);
  }, [isChange]);
  //FIX PROD ISSUE OF DEFAULT SORT ENDS
  const orderBy = Object.keys(sort).reduce(
    (acc, curr) => acc.concat({ column: curr, order: sort[curr] }),
    []
  );

  // Search offset issue condition for pagination added
  const pageOffset = (currentPage - 1) * pageSize;

  {
    /* DEFECT No. 14 Statrs  */
  }
  let passOrderby = [];
  if (orderBy.length >= 1) {
    if (orderBy.some((item) => item.column === fte_percentage)) {
      passOrderby = orderBy.filter((item) => item.column !== fte_percentage);
    } else {
      passOrderby = orderBy;
    }
  }
  //console.log(query,where);

  {
    /* Table fetch from network only  */
  }
  const { loading, error, data } = useQuery(queries[query], {
    variables: {
      where: where,
      limit: parseInt(pageSize),
      offset: pageOffset,
      orderBy: passOrderby.length ? passOrderby : null,
    },
    fetchPolicy: "network-only",
  });
  {
    /* DEFECT No. 14 Ends  */
  }
  // R2 - Search for pagination reseting offset
  if (
    where &&
    where.employee_search !== undefined &&
    where.employee_search !== searchTxt &&
    !loading &&
    data &&
    data[query]
  ) {
    //forceUpdate();
    setCurrentPage(1);
    searchTxt = where.employee_search;
  }
  // R2 - Search for pagination reseting offset
  const onPageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  const onPageChange = (currentPage) => {
    setCurrentPage(currentPage);
  };
  {
    /* DEFECT No. 14 Statrs  */
  }
  const sortColmn = [
    "fte_annual_salary",
    "fte_incentive_target_amount",
    "fte_ttc_annual_salary",
    "ttc_increase_amount",
  ];
  {
    /* DEFECT No. 14 Ends  */
  }
  /*
  const handleFilter = column => async event => {
    const target = event.target;
    const filter = {...whereConfig};

    const defaultOperator = column.type === 'float' ? 'eq' : 'contains';
    const currColumnFilter = filter[column.name] || false;
    const currColumnOperator = currColumnFilter ? Object.keys(currColumnFilter).shift() : defaultOperator;
    const currColumnValue = currColumnFilter ? currColumnFilter[currColumnOperator] : '';

    if (!target.value && target.name !== 'op') {
      delete(filter[column.name]);
    } else if (target.name === 'op' && currColumnValue) {
      filter[column.name] = {
        [target.value]: currColumnValue
      };
    } else {
      filter[column.name] = {
        [currColumnOperator]: column.type === 'float' ? parseFloat(target.value) : target.value
      };
    }
    await setWhereConfig(filter);
    setCurrentPage(1)
  };
  */
  const handleSort = (column, order) => (event) => {
    if (!column.sortable) return;
    const sortClone = { ...sort };
    if (sortClone[column.name] === order) {
      delete sortClone[column.name];
    } else {
      sortClone[column.name] = order;
    }
    setSort(sortClone);
  };
  /*
  const removeSort = columnName => {
    const sortClone = {...sort};
    delete(sortClone[columnName]);
    setSort(sortClone);
  };
  
  const onColumnsChange = data => {
    const { changedColumns, columnName, columnChecked } = data;
    setColumnsConfig(changedColumns);
    if (!columnChecked) {
      removeSort(columnName);
    }
  };
  */
  const responseData = data && data[query];
  const queryName = queries[query].definitions[0].name.value;

  {
    /* DEFECT No. 14 Statrs  */
  }

  /*if (orderBy.length >= 1) {
    if(orderBy[0].column===fte_percentage){
      responseData.items.sort(compareValues(fte_percentage, orderBy[0].order.toLowerCase()));
    }
  }*/

  if (orderBy.length >= 1) {
    let sortByValue = [];
    if (orderBy.some((item) => item.column === fte_percentage)) {
      orderBy.map((x) => {
        let sortByCol = { column: "", direction: "" };
        sortByCol.column = x.column;
        sortByCol.direction = x.order.toLowerCase();
        sortByValue.push(sortByCol);
      });
      const sortMethod = sortByMultiColmn(sortByValue);
      responseData.items = responseData.items.sort(sortMethod);
    }
  }

  if (isEuroSort && orderBy.length >= 1 && responseData) {
    if (sortColmn.includes(orderBy[0].column)) {
      responseData.items.map((item) => {
        if (item.request !== undefined) {
          item.currency = "EUR";
          item.sortAmt = getExchangeRateValueForItem(
            item.request[orderBy[0].column],
            item.request,
            account,
            config
          );
        } else {
          item.sortAmt = getExchangeRateValueForItem(
            item[orderBy[0].column],
            item,
            account,
            config
          );
        }
      });
      responseData.items.sort(
        compareValues("sortAmt", orderBy[0].order.toLowerCase())
      );
    }
  } else {
    if (orderBy.length >= 1 && responseData) {
      if (sortColmn.includes(orderBy[0].column)) {
        responseData.items.map((item) => {
          if (item.request) {
            item.sortAmount = item.request[orderBy[0].column];
          } else {
            item.sortAmount = item[orderBy[0].column];
          }
        });
        responseData.items.sort(
          compareValues("sortAmount", orderBy[0].order.toLowerCase())
        );
      }
    }
  }
  {
    /* DEFECT No. 14 Ends  */
  }

  //console.log(query);
  //console.log("columns" , columns);
  //console.log("response", responseData);
  return (
    <>
      {/* <Columns columns={columnsConfig} onChange={onColumnsChange} /> */}
      {/*<SearchBar columns={columnsConfig} onChange={handleFilter} where={whereConfig} /> */}
      {loading && (
        <GridLoader>
          <Grid color={"#001235"} />
        </GridLoader>
      )}

      {/* DEFECT No. 13 STARTS  */}
      {/*error && (<TableHeader recordType={recordType} selectable columns={columns} />)*/}
      {error && (
        <div>
          <h2>No available employees</h2>
        </div>
      )}
      {/*error && <p>Error :(</p>*/}
      {/* Release 3.3 - 8.3 - Starts */}
      <div style={{ "overflowX": responseData && responseData.totalCount != 0 ? "auto" : "none" }}>
      {/* Release 3.3 - 8.3 - Ends */}
      {/* DEFECT No. 13 ENDS  */}
      {!loading &&
        responseData &&
        query === "managerDirectSubOrdinates" &&
        responseData.totalCount != 0 && (
          <TableHeader
            recordType={recordType}
            selectable={noCheckBox ? false : true}
            sortable
            columns={columns}
            sort={sort}
            onSort={handleSort}
            items={responseData.items}
          />
        )}

      {!loading &&
        responseData &&
        query !== "managerDirectSubOrdinates" &&
        queryName !== "DelegatesForManager" &&
        queryName !== "budgetsForAdmin" &&
        /* Release 3.2 - 1.1 - Starts */
        // queryName !== "organizationBEABudget" &&
        /* Release 3.2 - 1.1 - Ends */
        queryName !== "moc_failure" &&
        recordType !== "admintables" &&
        responseData.items && (
          <TableHeader
            recordType={recordType}
            selectable={noCheckBox ? false : true}
            sortable
            columns={columns}
            sort={sort}
            onSort={handleSort}
            items={responseData.items}
            /* Release 3.1 - Starts */
            checkboxIsDisplayed={checkboxIsDisplayed}
            selectedRequests={selectedRequests}
            /* Release 3.1 - Ends */
          />
        )}
      {/* DEFECT No. 13 STARTS  */}
      {!loading &&
        responseData &&
        query === "managerDirectSubOrdinates" &&
        responseData.totalCount === 0 && (
          <div>
            <h2>No available employees</h2>
          </div>
        )}
      {!loading &&
        queryName === "DelegatesForManager" &&
        responseData &&
        responseData.totalCount > 0 && (
          <TableHeader
            recordType={recordType}
            selectable={noCheckBox ? false : true}
            sortable
            columns={columns}
            sort={sort}
            onSort={handleSort}
            items={responseData.items}
          />
        )}
      {!loading &&
        queryName === "DelegatesForManager" &&
        responseData &&
        responseData.totalCount == 0 && (
          <div
            style={{
              width: "100%",
              width: "100%",
              border: "1px solid #EFEFEF",
              "text-align": "center",
              margin: "3% 0 0 0",
              background: "aliceblue",
            }}
          >
            <h2 style={{ width: "30%", "margin-left": "30%", padding: "1%" }}>
              You have not assigned any delegate yet.
              <br />
              <br />
              Click on "Create Delegate" to start.
            </h2>
          </div>
        )}

      {/* DEFECT No. 13 Ends  */}

      {/* Release 2 - Admin Tables table header */}
      {!loading &&
        recordType === "admintables" &&
        responseData &&
        responseData.totalCount > 0 && (
          <TableHeader
            recordType={recordType}
            selectable={false}
            sortable
            columns={columns}
            sort={sort}
            onSort={handleSort}
            items={responseData.items}
          />
        )}
      {/* Release 2 - Admin Tables empty message */}
      {!loading &&
        recordType === "admintables" &&
        responseData &&
        responseData.totalCount == 0 && (
          <div
            style={{
              width: "100%",
              width: "100%",
              border: "1px solid #EFEFEF",
              "text-align": "center",
              margin: "3% 0 0 0",
              background: "aliceblue",
            }}
          >
            <h2 style={{ width: "30%", "margin-left": "30%", padding: "1%" }}>
              No Data available
            </h2>
          </div>
        )}

      {/* DEFECT No. 13 Ends  */}

      {/* Release 2 - BudgetManagent table header */}
      {!loading &&
        /* Release 3.2 - 1.1 - Starts */
        queryName === "budgetsForAdmin" &&
        // || queryName === "organizationBEABudget") &&
        /* Release 3.2 - 1.1 - Ends */
        responseData &&
        responseData.totalCount > 0 && (
          <TableHeader
            recordType={recordType}
            selectable={false}
            sortable
            columns={columns}
            sort={sort}
            onSort={handleSort}
            items={responseData.items}
          />
        )}
      {/* Release 2 - BudgetManagent empty message */}
      {!loading &&
        /* Release 3.2 - 1.1 - Starts */
        (queryName === "budgetsForAdmin" ||
          queryName === "organizationBEABudget") &&
        /* Release 3.2 - 1.1 - Ends */
        responseData &&
        responseData.totalCount == 0 && (
          <div
            style={{
              width: "100%",
              width: "100%",
              border: "1px solid #EFEFEF",
              /* Release 3.2 - 1.1 - Starts */
              textAlign: "center",
              /* Release 3.2 - 1.1 - Ends */
              margin: "3% 0 0 0",
              background: "aliceblue",
            }}
          >
            <h2
              style={{
                /* Release 3.2 - 1.1 - Starts */
                width: "70%",
                marginLeft: "20%",
                /* Release 3.2 - 1.1 - Ends */
                padding: "1%",
              }}
            >
              No budget has been created yet for the selected year.
              <br />
              <br />
              If you are an Admin {/* Release 3.2 - 1.1 - Starts */}{" "}
              {queryName === "organizationBEABudget" ? "/ R&R " : ""}{" "}
              {/* Release 3.2 - 1.1 - Ends */} / Global Compensation team, Click
              on "Add Budget" to add.
            </h2>
          </div>
        )}

      {/* Release 2 - MOC failure table header */}
      {!loading &&
        queryName === "moc_failure" &&
        responseData &&
        responseData.totalCount > 0 && (
          <TableHeader
            recordType={recordType}
            selectable={false}
            sortable
            columns={columns}
            sort={sort}
            onSort={handleSort}
            items={responseData.items}
          />
        )}
      {/* Release 2 - MOC empty empty message */}
      {!loading &&
        queryName === "moc_failure" &&
        responseData &&
        responseData.totalCount == 0 && (
          <div
            style={{
              width: "100%",
              width: "100%",
              border: "1px solid #EFEFEF",
              "text-align": "center",
              margin: "3% 0 0 0",
              background: "aliceblue",
            }}
          >
            <h2 style={{ width: "30%", "margin-left": "30%", padding: "1%" }}>
              All the imported records are added successfully.
            </h2>
          </div>
        )}

      {/* Mapping key with index  */}
      {!loading &&
        responseData &&
        responseData.items.map((item, index) => {
          //console.log(item);
          const background = index % 2 ? "#f5f5f5" : "#ffffff";
          // if(props.Budgetfilter && props.Budgetfilter.trim().length != 0){
          // if(item.organization_unit.organization_unit_name.toLowerCase().includes(props.Budgetfilter.toLowerCase().trim())){
          return (
            <Row
              editFunc={editOnClick ? editOnClick : ""}
              recordType={recordType}
              // key={`row-${item[indexColumn.name]}`}
              key={index}
              /* Release 2 - disable selectable for budget */
              selectable={
                noCheckBox
                  ? false
                  : queryName === "budgetsForAdmin" ||
                    /* Release 3.2 - 1.1 - Starts */
                    // queryName !== "organizationBEABudget" ||
                    /* Release 3.2 - 1.1 - Ends */
                    queryName === "moc_failure"
                  ? false
                  : true
              }
              columns={columns}
              item={item}
              background={background}
              /* Release 3.1 - Starts */
              checkboxIsDisplayed={checkboxIsDisplayed}
              selectedRequests={selectedRequests}
              /* Release 3.1 - Ends */
            />
          );
          //   }
          // }
          // else{
          // return (
          //<Row
          // editFunc = {editOnClick?editOnClick:""}
          //recordType={recordType}
          /* disable selectable for budget */
          //selectable = {queryName === 'budgetsForAdmin' ? false:true}
          //key={`row-${item[indexColumn.name]}`}
          //columns={columns}
          //item={item}
          // background={background}
          // />
          //);
          //}
        })}
      </div>
      {/* DEFECT No. 13 STARTS  */}
      {!loading &&
        responseData &&
        query === "managerDirectSubOrdinates" &&
        responseData.totalCount != 0 &&
        responseData.totalCount && (
          <Pagination
            count={responseData.totalCount}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
          />
        )}
      {/* DEFECT No. 13 End  */}

      {!loading &&
        responseData &&
        query != "managerDirectSubOrdinates" &&
        responseData.totalCount > 0 && (
          <Pagination
            count={responseData.totalCount}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            showCount={showCount} //R2 Wave 3: to show count in MOC screen
          />
        )}
    </>
  );
};

export { Table };
