/*Release 2 - budget management column*/
export default (table) => {
         
      return {
           name: "year", 
           label: "Budget Year",
           section: "Budget Management",
           visible: true,
           sortable: true,
           width:'10%'
      };
    };