import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
/* Release 3.1 - Starts */
import { GET_LOCKDOWNTOOL_LIST } from "../graphql";
import { apolloClient, apolloProxyClient } from "../index";
/* Release 3.1 - Ends */

const Home = (props) => {
  const { account } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { roles } = account;

  const isLM = roles.includes("manager");
  const isSuperManager = roles.includes("supermanager");
  const isGct = roles.includes("gct");
  /* Release 3.2 - 10.1 - Starts */
  const isRcb = roles.includes("rcb");
  /* Release 3.2 - 10.1 - Ends */
  const isApprover = !isLM && !isSuperManager;

  /* Release 3.1 - Starts */
  const isAdmin = roles.includes("admin");
  /*  LOCKDOWN TOOL - Adding condition for TAP USER Starts */
  const isRTAP = roles.includes("rtap");
  const isGTAP = roles.includes("gtap");
  /*  LOCKDOWN TOOL - Adding condition for TAP USER Ends */
  /* Release 3.2 - 1.1 - Starts */
  const isOnlyBusinessOwner =
    !roles.includes("manager") &&
    !roles.includes("supermanager") &&
    !roles.includes("bcb") &&
    !roles.includes("gct") &&
    !roles.includes("rcb") &&
    !roles.includes("admin") &&
    !roles.includes("gtap") &&
    !roles.includes("rtap") &&
    !roles.includes("keyuser") &&
    !roles.includes("hr1") &&
    !roles.includes("hr2") &&
    !roles.includes("hr3") &&
    !roles.includes("chr") &&
    !roles.includes('hrc') &&
    !roles.includes('bg_head') &&
    !roles.includes('bl2') &&
    !roles.includes('bl3') &&
    !roles.includes('rhr')&&
    roles.includes('business_owner');
  /* Release 3.2 - 1.1 - Ends */
  const [isToolLock, setIsToolLock] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    /*  LOCKDOWN TOOL - Adding condition for TAP USER Starts */
    //if (!isAdmin) {
      if (!isAdmin && !isRTAP && !isGTAP) {  
    /*  LOCKDOWN TOOL - Adding condition for TAP USER Ends */    
      getLockdownToolInfo().then((lockdownToolInfo) => {
        setIsToolLock(lockdownToolInfo.is_active);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const getLockdownToolInfo = async () => {
    return await new Promise((resolve, reject) => {
      httpClient
        .query({
          query: GET_LOCKDOWNTOOL_LIST,
          variables: {},
        })
        .then((response) => {
          console.log(response);
          if (
            response.data &&
            response.data.getLDT &&
            response.data.getLDT.length > 0
          ) {
            resolve(response.data.getLDT[0]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }).catch((err) => {
      console.error(err);
    });
  };
  const RedirectComponent = (props) => {
    const { loading, isLock, isApprover } = props;

    return (
      <>
        {!loading && (
          <>
            {isLock ? (
              <Redirect to={`/maintenance`} />
            ) : (
              <>
                {isOnlyBusinessOwner ? <Redirect to={`/mass-off-cycle`} /> : (isLM || isSuperManager || isGct) ? (
                  <Redirect to={`/employees`} />
                ) : (
                  isApprover && <Redirect to={`/pending-requests`} />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  /* Release 3.1 - Ends */

  return (
    <>
      {/* Release 3.1 - Starts */}
      <RedirectComponent
        loading={loading}
        isLock={isToolLock}
        isApprover={isApprover}
      />
      {/* Release 3.1 - Ends */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps)(Home);
