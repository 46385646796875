import { combineReducers } from 'redux';
import authReducer from './authReducer';
import selectedEmployeesReducer from './selectedEmployeesReducer';
import selectedRequestsReducer from './selectedRequestsReducer';
/*  FIXING DELEGATION ISSUE */
import selectedDelegatesReducer from './selectedDelegatesReducer';
/* Release 3.2 - 1.1 - Starts */
import selectedBudgetsReducer from './selectedBudgetsReducer';
import selectedBusinessOwnerBudgetsReducer from './selectedBusinessOwnerBudgetsReducer';
/* Release 3.2 - 1.1 - Ends */

import modalReducer from './modalReducer';
import globalMessagesReducer from './globalMessagesReducer';
import accountReducer from './accountReducer';
import configReducer from './configReducer';
import budgetReducer from './budgetReducer';
import formulasReducer from './formulasReducer';
import mocFiltersReducer from './mocFiltersReducer';

export default combineReducers({
  account: accountReducer,
  config: configReducer,
  auth: authReducer,
  selectedEmployees: selectedEmployeesReducer,
  selectedRequests: selectedRequestsReducer,
/*  FIXING DELEGATION ISSUE */
  selectedDelegates: selectedDelegatesReducer,
  /* Release 3.2 - 1.1 - Starts */
  selectedBudgets: selectedBudgetsReducer,
  selectedBusinessOwnerBudgets: selectedBusinessOwnerBudgetsReducer,
  /* Release 3.2 - 1.1 - Ends */

  modal: modalReducer,
  budget: budgetReducer,
  messages: globalMessagesReducer,
  formulas: formulasReducer, //Admin calculations for R2
  mocFilters: mocFiltersReducer
});
