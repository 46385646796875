import React, { useState, useEffect } from 'react';
import { Ring } from 'react-awesome-spinners';
import { FormButton, FormButtonsWrap, FormHeading, LoaderWrap} from '../';
import Select from 'react-select';
import { MOCFILTERS, MANAGERS_SUGGEST } from '../../graphql';
import {apolloClient, apolloProxyClient } from '../../index';
import { connect } from 'react-redux';
import { updateFilters } from '../../actions';
import AsyncSelect from 'react-select/async';

const ExportFilters = (props) => {
    const { hideModal, mocFilters, updateFilters } = props;    
    const proxyCheck = sessionStorage.getItem("proxy_access");
    const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fOption, setOptions] = useState(null);
    const [filterErrors, setFilterError] = useState('');
    let filterValues = {"country":[],"hrRegion":[],"jobGrade":[],"incentivePlan":[],"organizationUnit1Name":[],"organizationUnit2Name":[],"organizationUnit3Name":[],"organizationUnit4Name":[],"lineManager":[]};

    const filtergroup = {
        width:"30vw",
        display: "inline-block",
        margin: "1vw 0 0 8vw",
        fontSize: "12px"
    }
    const labelStyle = {
        fontSize: "12px",
        fontWeight: "bold"
    }
    const getFiltersData = async() => {
        setIsSubmitting(true);
        return new Promise((resolve, reject) => {
            httpClient.query({
                query: MOCFILTERS,
                variables: { },
            }).then((response) => {
                if (response.data) {
                  resolve( response.data);
                  setIsSubmitting(false);
                }
            }).catch((err) =>  
              console.error(err)
            );
        }).catch((err)=>{
            console.error(err)
        });
    }
    useEffect(() => {
        //setFilteredValues({...filterValues});
        console.log('mocFilters frm store', mocFilters)
        getFiltersData().then((data)=>{
            console.log('data is: ',data)
            processFiltersData(data);
        })
      }, []);
      
    const processFiltersData = async (fdata) => {
        await setOptions(
        {
        "countryList": fdata.country.map(obj=>{return {"value":obj.country_name,"label":obj.country_name}}).sort(doSort),
        "regionsList": fdata.hrRegion.map(obj=>{return {"value":obj.hr_region,"label":obj.hr_region}}).sort(doSort),
        "jobGradesList": fdata.jobGrade.map(obj=>{return {"value":obj.job_grade,"label":obj.job_grade}}).sort(doSort),
        "incentivePlanList": fdata.incentivePlan.map(obj=>{return {"value":obj.incentive_plan_type,"label":obj.incentive_plan}}).sort(doSort),
        "organizationUnit1NameList": fdata.organizationUnit1Name.map(obj=>{return {"value":obj.organization_unit_1,"label":obj.organization_unit_1_name}}).sort(doSort),
        "organizationUnit2NameList": fdata.organizationUnit2Name.map(obj=>{return {"value":obj.organization_unit_2,"label":obj.organization_unit_2_name}}).sort(doSort),
        "organizationUnit3NameList": fdata.organizationUnit3Name.map(obj=>{return {"value":obj.organization_unit_3,"label":obj.organization_unit_3_name}}).sort(doSort),
        "organizationUnit4NameList": fdata.organizationUnit4Name.map(obj=>{return {"value":obj.organization_unit_4,"label":obj.organization_unit_4_name}}).sort(doSort),
        }
        )
    }
    
    function doSort( a, b ) {
        if ( a.label < b.label ){
          return -1;
        }
        if ( a.label > b.label ){
          return 1;
        }
        return 0;
    }
    const onSelectChange = async (val, ele) => {
        console.log('event',val, ele);
        switch(ele.name){
            case "country":
                filterValues.country = await val.map((obj)=>{return obj.value});
            break;
            case "hrRegion":
                filterValues.hrRegion = await val.map((obj)=>{return obj.value});
            break;
            case "jobGrade":
                filterValues.jobGrade = await val.map((obj)=>{return obj.value});
            break;
            case "incentivePlan":
                filterValues.incentivePlan = await val.map((obj)=>{return obj.value});
            break;
            case "organizationUnit1Name":
                filterValues.organizationUnit1Name = await val.map((obj)=>{return obj.value});
            break;
            case "organizationUnit2Name":
                filterValues.organizationUnit2Name = await val.map((obj)=>{return obj.value});
            break;
            case "organizationUnit3Name":
                filterValues.organizationUnit3Name = await val.map((obj)=>{return obj.value});
            break;
            case "organizationUnit4Name":
                filterValues.organizationUnit4Name = await val.map((obj)=>{return obj.value});
            break;
            case "lineManager":
                filterValues.lineManager = await val.map((obj)=>{return obj.value});
            break;
        }
                
    }
    
    const FetchManagers = (input) => {
        if (input.length >= 3) {
          return new Promise((resolve, reject) => {
            httpClient.query({
              query: MANAGERS_SUGGEST,
              variables: { where: { searchvalue: input }, limit: 15, offset: 0 },
            }).then((response) => {
                if (response.data && response.data.lineManager) {
                  resolve(response.data.lineManager.items.map(
                    (a) => ({
                      label: a.full_name + " (" + a.nokia_id + ") (" + a.email_work + ")",
                      value: a.pers_no,
                      Name : a.full_name
                    })
                  ));
                }
              }).catch((err) =>
                console.error(err)
              )
          })
        }
      };
    const submitFilters = (evt) => {
        evt.preventDefault();
        if(filterValues.country.length==0 && filterValues.hrRegion.length==0 && filterValues.jobGrade.length==0 && filterValues.incentivePlan.length==0 && filterValues.organizationUnit1Name.length==0 && filterValues.organizationUnit2Name.length==0 && filterValues.organizationUnit3Name.length==0 && filterValues.organizationUnit4Name.length==0 && filterValues.lineManager.length==0){
            setFilterError('please provide atleast one input');
            return false;
        }
        setIsSubmitting(true);
        console.log('filterValues end',filterValues);  
        updateFilters(filterValues);      
        setIsSubmitting(false); 
        filterValues = {};
        hideModal();
        //filterValues = null;
    }
    const handleQuit = event => { hideModal(); };
    
    return (
        <div style={{overflow:"auto"}}>
            <FormHeading>Filter Results</FormHeading>
            {/* <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}> */}
            {fOption===null?
            <div style={{textAlign:"center"}}><h2>Loading....</h2></div>
            :
            <div>
                <div style={filtergroup}><strong style={labelStyle}>Country : </strong>
                <Select
                name="country"
                defaultValue={[]}
                options={fOption.countryList}
                onChange={onSelectChange}
                isMulti
                disabled={fOption==={} || isSubmitting}
                />
                </div>
                <div style={filtergroup}><strong style={labelStyle}>HR Region : </strong><Select
                name="hrRegion"
                defaultValue={[]}
                options={fOption.regionsList}
                onChange={onSelectChange}
                isMulti
                disabled={fOption==={} || isSubmitting}
                /></div>
                <div style={filtergroup}><strong style={labelStyle}>Job Grade : </strong><Select
                name="jobGrade"
                defaultValue={[]}
                options={fOption.jobGradesList}
                onChange={onSelectChange}
                isMulti
                disabled={fOption==={} || isSubmitting}
                /></div>
                <div style={filtergroup}><strong style={labelStyle}>Organization Unit 1: </strong><Select
                name="organizationUnit1Name"
                defaultValue={[]}
                options={fOption.organizationUnit1NameList}
                onChange={onSelectChange}
                isMulti
                disabled={fOption==={} || isSubmitting}
                /></div>
                <div style={filtergroup}><strong style={labelStyle}>Organization Unit 2: </strong><Select
                name="organizationUnit2Name"
                defaultValue={[]}
                options={fOption.organizationUnit2NameList}
                onChange={onSelectChange}
                isMulti
                disabled={fOption==={} || isSubmitting}
                /></div>
                <div style={filtergroup}><strong style={labelStyle}>Organization Unit 3: </strong><Select
                name="organizationUnit3Name"
                defaultValue={[]}
                options={fOption.organizationUnit3NameList}
                onChange={onSelectChange}
                isMulti
                disabled={fOption==={} || isSubmitting}
                /></div>
                <div style={filtergroup}><strong style={labelStyle}>Organization Unit 4: </strong><Select
                name="organizationUnit4Name"
                defaultValue={[]}
                options={fOption.organizationUnit4NameList}
                onChange={onSelectChange}
                isMulti
                disabled={fOption==={} || isSubmitting}
                /></div>
                <div style={filtergroup}><strong style={labelStyle}>Incentive Plan : </strong><Select
                name="incentivePlan"
                defaultValue={[]}
                options={fOption.incentivePlanList}
                onChange={onSelectChange}
                isMulti
                disabled={fOption==={} || isSubmitting}
                /></div>
                <div style={filtergroup}><strong style={labelStyle}>Line Manager: </strong>
                <AsyncSelect
                    //loadOptions={fetchArtists}
                    name="lineManager"
                    loadOptions={FetchManagers}
                    onChange={onSelectChange}
                    placeholder="Type at least three letters"
                    className="select"
                    isMulti
                    disabled={fOption==={} || isSubmitting}
                />
                {/* <Select
                name="lineManager"
                defaultValue={[]}
                options={fOption.LineManagersList}
                onChange={onSelectChange}
                isMulti
                disabled={fOption==={} || isSubmitting}
                /> */}
                </div>
            </div>
            }
            {filterErrors!='' && <div style={{textAlign:"center",color:"red",fontSize:"16px"}}><strong>{filterErrors}</strong></div>}
            <FormButtonsWrap>
                <FormButton onClick={handleQuit} style={{fontSize:"12px"}}>Cancel</FormButton>
                <FormButton onClick={submitFilters} primary disabled={isSubmitting} style={{fontSize:"12px"}}>Submit</FormButton>
                {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
            </FormButtonsWrap>
        </div>
    )
}
const mapStateToProps = state => {
    return {
      mocFilters: state.mocFilters
    };
  
};
  
export default connect(
    mapStateToProps,
    { updateFilters }
  )(ExportFilters);
