/* import { optionsFilter } from '../../filters';

export default (table) => {
  let name = 'new_incentive_scheme';
  let options = optionsFilter;
  let defaultValue = (value, data) => data.job_family_name !== "Sales" && data.job_family_name !== "Pre-Sales" ? "Non-Sales" : data.job_family_name;

  return {
    type: "string",
    inputType: "select",
    name,
    label: "New Incentive Scheme",
    section: "TAP Employees Information",
    visible: true,
    editable: true,
    width: '100px',
    options,
    defaultValue
  }
} */
import { optionsFilter } from '../../filters';

export default (table) => {
  let name = 'promotion_job_family_name';
  let options = optionsFilter;
  let defaultValue = (value, data) => data.job_family_name;
  switch (table) {
   
      case 'editInternalHire':
      name = 'request.promotion_job_family_name';
      options = (column, config, data) => optionsFilter(column, config, data.request);
      defaultValue = (value, data) => data.request.for.job_family_name;
      break;
    default:
      break;
  }

  let property = {
    type: "string",
    inputType: "select",
    name,
    label: "New Job Family",
    section: "Promotion",
    visible: true,
    editable: true,
    options,
    defaultValue,
    width: '100px',
  }


  return property;
};