import { percentRenderer } from '../../renderers';

export default (table) => {
    return {
      name: "fte_incentive_target_percentage",
      section: "Current Compensation Information",
      label: "Current Incentive Target %",
      visible: true,
      filterable: false,
      width: '70px',
      sortable:true,
      renderer: percentRenderer
    };
};