import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Ring } from 'react-awesome-spinners';
import { connect } from 'react-redux';
import { SelectedTable, CalculatorNew, FormButton, FormButtonsWrap, ErrorsBlock, LoaderWrap, FormLabel, FormHeading, IconWrap, DescriptionInput } from '../';
/*  Add New DescriptionInput TO FIX DEFECT 11 */ 
import { columns } from '../../util/config';
import { unselectAllRecord, pushMessage } from '../../actions';
import { EDIT_REQUEST, requests } from '../../graphql';

var textareavaluecheck = null;/*  Add New variable TO FIX DEFECT 11 */ 
const EditOneTime = props => {
  const { selectedRequests, account, hideModal, unselectAllRecord, pushMessage } = props;
 
  const queryFilter = { requester_pers_no: { eq: account.pers_no } };

  const [updatedItem, setUpdatedItem] = useState('');
  const [updateData, setUpdateData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);

  /*  Add New requestDescription TO FIX DEFECT 11 STARTS */ 
  const [requestDescription, setRequestDescription] = useState('');
  /*  Add New requestDescription TO FIX DEFECT 11 ENDS */ 

  const [editRequest] = useMutation(EDIT_REQUEST);

  const update = (item, data) => {
    if (updatedItem !== item) {
      setUpdatedItem(item);
    }
    setUpdateData(data);
  };

  const handleChange = args => event => {
    const { column, columns, item } = args;
    const indexColumn = columns.find(c => c.index);
    let value = column.inputType === 'checkbox' ? event.target.checked : event.target.value;
    value = column.type === 'float' ? parseFloat(value) : value
    const valueObject = column.name.split('.').reduceRight((a,c) => { return { [c]: a }; }, value);
    update(item[indexColumn.name], valueObject);
  };

  /*  Add New handleDescriptionChange TO FIX DEFECT 11 STARTS */ 
  const handleDescriptionChange = event => {
    textareavaluecheck=event.target.value;
setRequestDescription(event.target.value);
};
  /*  Add New handleDescriptionChange TO FIX DEFECT 11 ENDS */ 

  const handleQuit = event => {
    hideModal();
    unselectAllRecord('requests');
  };

  const selectedToEditRequestInput = selectedRequest => {
    const copy = {...selectedRequest};
    delete copy.employee_request_id;
    delete copy.request_type;
    delete copy.reject_comments;
    delete copy.upd_ins_ts;
    delete copy.created_ts;
    delete copy.for;
    delete copy.by;
    delete copy.approvals;
    delete copy.__typename;
    delete copy.salary_range_spread;
    delete copy.exchange_rate;
    delete copy.tenure;
    delete copy.gpp_guideline_max;
    delete copy.interim_harmonization_type;
    delete copy.interim_salary_range_mid;
    delete copy.interim_salary_range_spread;
    delete copy.new_ttc_check;
    delete copy.new_ttc_compa_ratio;
    delete copy.individual_budget_amount;
    delete copy.individual_spending_amount;
    delete copy.individual_balance_amount;
    delete copy.gpp_budget;
    delete copy.gpp_spending;
    delete copy.gpp_balance;
    delete copy.total_spending;
    delete copy.override_fte_incentive_target_percentage;
    delete copy.override_fte_ttc_annual_salary;

    /*  ADD/COPY FIELDS TO FIX DEFECT 11 STARTS */ 
    delete copy.interim_pay_grade;
    delete copy.promotion_pay_grade;
    delete copy.comments
    delete copy.payment_date;
    delete copy.effective_date;
    delete copy.lumpsum_abs_percentage
    delete copy.payment_reason
    delete copy.budget_allocations
    delete copy.ttc_increase;
    delete copy.ttc_increase_perc;
    /**Release 2 Our talent remainig delete */
   delete copy.Final_Off_Cycle_Budget_euro;
   delete copy.sip_change;
   delete copy.internalhire_increase_amount;
   delete copy.internalhire_increase_percentage;
   delete copy.Max_Promotional_Increase;
   delete copy.hmanager;
   delete copy.hiringmanager;
   /**Release 2 Our Talent ends */
    copy.request_description = requestDescription;
    /*  ADD/COPY FIELDS TO FIX DEFECT 11 ENDS */ 

    // delete copy.interim_pay_grade;
    // delete copy.promotion_pay_grade;
    // delete copy.comments
    // delete copy.payment_date;
    // delete copy.effective_date;
    // delete copy.lumpsum_abs_percentage
    // delete copy.payment_reason
    // delete copy.budget_allocations

    /* Added new code for Defect 11 START*/
    if(textareavaluecheck!='' && textareavaluecheck!=null){
      copy.request_description = requestDescription;
  }else{
      copy.request_description =selectedRequests[0].request.comments[selectedRequests[0].request.comments.length - 1].description ;
  }
  /* Added new code for Defect 11 END*/

    copy.status = 'open';

    /* Release 3.1 - Starts */      
    delete copy.changeApprovers;
    /* Release 3.1 - Ends */
    /* Release 3.2 - 1.1 - Starts */ 
    delete copy.lastYearWageHistory;
    delete copy.sum_of_pay_amount;
    delete copy.reminder_info;
    delete copy.award_title;
    delete copy.award_message;
    delete copy.award_privacy;
    delete copy.award_reason;
    delete copy.nominator_email_address;
    /* Release 3.2 - 1.1 - Ends */
    return copy;
  };

  const handleEdit = async event => {
    setIsSubmitting(true);
    setSubmitErrors([]);

    /*  ADD CONDITION FIELDS TO FIX DEFECT 11 STARTS */ 
    if (textareavaluecheck=='') {
      setSubmitErrors(['Reason is required!']);
      setIsSubmitting(false);
      return null;
    }
    /*  ADD CONDITION FIELDS TO FIX DEFECT 11 ENDS  */ 
    
    const results = await Promise.all(selectedRequests.map(async ({
        employee_request_id,
        request
      }) => {
      try {
        const copyRequst = selectedToEditRequestInput(request);
        await editRequest({
          variables: { employee_request_id, data: copyRequst },
          awaitRefetchQueries: true,
          refetchQueries: [
            { query: requests, variables: {
                // TODO - get this from MyRequests.js state 
                limit: 20,
                offset: 0,
                orderBy: [ { column: "upd_ins_ts", order: "DESC" } ],
                where: queryFilter
            }}
          ]
        });
        
        return {[employee_request_id]: false};
      } catch(e) {
        return {[employee_request_id]: e.message};
      } 
    }));
  
    const errors = results.filter(r => Object.keys(r).find(p_no => r[p_no]));
    if (!errors.length) {
      hideModal();
      unselectAllRecord('requests');
      pushMessage({
        type: 'success',
        message: 'Request(s) successfully updated!'
      })
    } else {
      setSubmitErrors(errors.map(e => Object.values(e).shift()));
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <FormHeading>Edit requests</FormHeading>
      <FormLabel>Selected requests: {selectedRequests.length}</FormLabel>
      <SelectedTable
        recordType="requests"
        items={selectedRequests}
        columns={columns.editOnetime}
        onChange={handleChange}
      />
      {/*  ADD FORM FIELD FIELDS TO FIX DEFECT 11 STARTS */ }
      <FormLabel>Reason for request:
      <i style={{'width':'100%','float':'left'}}>[Please do not use the Reason field for Backdated changes dates as this field is visible only for approvers and not updating CoreHR System!]</i>
      </FormLabel>
      <DescriptionInput
        name='request_description'
        onChange={handleDescriptionChange} 
        defaultValue ={selectedRequests[0].request.comments[selectedRequests[0].request.comments.length - 1].description}
      />
      {/*  ADD FORM FIELD FIELDS TO FIX DEFECT 11 ENDS */ }

      {submitErrors.length > 0 && submitErrors.map(err => {
        return (
          <ErrorsBlock key={Math.random()}>
            <IconWrap>!</IconWrap>
            {err}
          </ErrorsBlock>
        );
      })}
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
        <FormButton primary disabled={isSubmitting} onClick={handleEdit}>Submit</FormButton>
        {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
      </FormButtonsWrap>
      <CalculatorNew
        columns={columns.editOnetime}
        recordType="requests"
        item={updatedItem}
        data={updateData}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account
  };
};

export default connect(
  mapStateToProps,
  { unselectAllRecord, pushMessage }
)(EditOneTime);
