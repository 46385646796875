import { currencyDisplayRenderer } from '../../renderers';

export default (table) => {
  let sortable = false;
  let name = "fte_ttc_annual_salary";
  let renderer = currencyDisplayRenderer;
let  width = '80px';
  switch (table) {
    case 'employees':
      sortable = true;
      break;
    case 'editCompensation':
    case 'editOnetime':      
    /* Release 3.2 - 1.1 - Starts */
    case 'editBEA':
    /* Release 3.2 - 1.1 - Ends */
    case 'editInternalHire':
      name = "request.for.fte_ttc_annual_salary";
      renderer = (value, data, account, config) => currencyDisplayRenderer(value, data.request, account, config);
      break;
      case 'internalHire' :
      case 'editInternalHire':
        width  = '10%';
      break;
    /* Release 3.2 - 1.1 - Starts */
    case 'employees_businessexcellenceaward':
      sortable = true;
      break;
    /* Release 3.2 - 1.1 - Ends */
    default:
      break;
  }

  return {
      type: "float",
      name,
      section: "Current Compensation Information",
      label: "Current Total Target Cash",
      visible: true,
      renderer,
      width,
      sortable
    };
};