export default (table) => {
  let name = 'request.for';
  let sortable = false;
  let renderer = (value) => `${value.full_name} (${value.nokia_id})`;
  let width = undefined;

  switch (table) {
    case 'approvedRequestHistory':
       //Start Issue Fix 15
       sortable  =true;
       //End Issue Fix 15
       name = 'full_name';
       renderer= (value, data) => value || `${data.request.for.full_name} (${data.request.for.nokia_id})`;
  
        //End Issue Fix 15
    case 'pendingRequests':
      width = "200px";
      //Start Issue Fix 15
      sortable  =true;
       //End Issue Fix 15
       name = 'full_name';
       renderer= (value, data) => value || `${data.request.for.full_name} (${data.request.for.nokia_id})`;
  
      break;
    case 'myRequests':
      // name = 'for';
      // width = "200px";
       //Start Issue Fix 15
       name     = 'full_name';
       width    = "8%";
       sortable  =true;
       renderer= (value, data) => value || `${data.for.full_name} (${data.for.nokia_id})`;
      //End Issue Fix 15
      
      break;
    default:
      break;
  }

  return {
    type: "string",
    name,
    label: "Employee",
    visible: true,
    width,
    sortable,
    renderer
  };
};