import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { connect } from "react-redux";
import { Ring } from "react-awesome-spinners";
import {
  unselectAllRecord,
  budgetSetUpdated,
  pushMessage,
} from "../../actions";
import {
  DatePickerField,
  SelectedTable,
  CalculatorNew,
  FormButton,
  FormButtonsWrap,
  FormLabel,
  FormLabelInline,
  FormHeading,
  DescriptionInput,
  LoaderWrap,
  ErrorsBlock,
  IconWrap,
} from "../";
import { columns } from "../../util/config";
import { CREATE_REQUEST_WITH_BUDGET, BUDGET, requests } from "../../graphql";
import { convertToUTC } from "../../util/helper";

import "react-datepicker/dist/react-datepicker.css";

const InternalHire = (props) => {
  const {
    selectedEmployees,
    account,
    hideModal,
    unselectAllRecord,
    budgetSetUpdated,
    pushMessage,
  } = props;
  /* Release 3.1 - Starts */
  const { roles } = account;
  const isGTap = roles.includes("gtap");
  const isRTap = roles.includes("rtap");
  const isTap = isGTap || isRTap;
  /* Release 3.1 - Ends */
  const [updatedItem, setUpdatedItem] = useState("");
  const [updateData, setUpdateData] = useState({});
  const [requestDescription, setRequestDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const now = new Date();
  const nextMonth =
    now.getMonth() === 11
      ? new Date(now.getFullYear() + 1, 0, 1)
      : new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const [effectiveDate, setEffectiveDate] = useState(nextMonth);

  const [createRequest] = useMutation(CREATE_REQUEST_WITH_BUDGET);
  const update = (item, data) => {
    if (updatedItem !== item) {
      setUpdatedItem(item);
    }
    setUpdateData(data);
  };
  function isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  function isNegative(n) {
    return ((n = +n) || 1 / n) < 0;
  }
  function UpdateN() {
    let ttc_increase_amount = document.querySelectorAll(
      'input[name="ttc_increase_amount"]'
    );
    let ttc_increase_percentage = document.querySelectorAll(
      'input[name="ttc_increase_percentage"]'
    );
    /**Release 2 Our Talent - scenarios change starts */
    let new_fte_annual_salary = document.querySelectorAll(
      'input[name="new_fte_annual_salary"]'
    );
    let new_fte_incentive_target_amount = document.querySelectorAll(
      'input[name="new_fte_incentive_target_amount"]'
    );

    /**Release 2 Our Talent - scenarios change Ends */

    ttc_increase_amount.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    ttc_increase_percentage.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });

    /**Release 2 Our Talent - scenarios change starts */
    new_fte_annual_salary.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    new_fte_incentive_target_amount.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    /**Release 2 Our Talent - Scenarios Change Ends */
  }

  const handleChange = (args) => (event) => {
    const { column, columns, item } = args;

    const indexColumn = columns.find((c) => c.index);
    let value = "";
    if (column.inputType !== "peoplepicker") {
      value =
        column.inputType === "checkbox"
          ? event.target.checked
          : event.target.value;
    } else {
      value = event.value;
    }

    value =
      (column.inputType !== "checkbox" && parseInt(value)) < 0 ? 0 : value;
    console.log("Onchange value", value);
    update(
      item[indexColumn.name],
      {
        request_type: "internalhire",
        [column.name]: column.type === "float" ? parseFloat(value) : value,
      },
      event
    );
  };

  const handleDescriptionChange = (event) => {
    setRequestDescription(event.target.value);
    setTimeout(() => {
      UpdateN();
    }, 100);
  };
  const handleEffectiveDateChange = (date) => {
    setEffectiveDate(date);
    setTimeout(() => {
      UpdateN();
    }, 100);
  };

  const handleQuit = (event) => {
    hideModal();
    unselectAllRecord("employees");
  };

  const hasBudgetVisibility =
    account.accessibles && account.accessibles.budgetOrgs.length > 0;

  const handleRequestCompensationSend = async (event) => {
    setTimeout(() => {
      UpdateN();
    }, 100);

    setIsSubmitting(true);
    setSubmitErrors([]);

    if (!requestDescription) {
      setSubmitErrors(["Reason is required!"]);
      setIsSubmitting(false);
      return null;
    }

    let refetchAfterCreateRequest = [
      {
        query: requests,
        variables: {
          where: { requester_pers_no: { eq: account.pers_no } },
          limit: 20,
          offset: 0,
          orderBy: [{ column: "created_ts", order: "DESC" }],
        },
      },
    ];
    if (hasBudgetVisibility) {
      account.accessibles.budgetOrgs.forEach((b) =>
        refetchAfterCreateRequest.push({
          query: BUDGET,
          variables: { organization_unit: b },
        })
      );
    }

    /*  ADD NEW FIELD TO FIX DEFECT 9 STARTS */
    let lastInsertedID = 0;
    let lastInsertedStatus = "";
    /*  ADD NEW FIELD TO FIX DEFECT 9 STARTS */

    const results = await Promise.all(
      selectedEmployees.map(
        async ({
          pers_no,
          nokia_id,
          country_name,
          organization_unit_1,
          organization_unit_2,
          organization_unit_3,
          organization_unit_4,
          is_gpp_eligible,
          gpp_increase_amount,
          gpp_increase_percentage,
          ttc_increase_amount,
          ttc_increase_percentage,
          fte_ttc_annual_salary,
          promoted,
          demoted,
          promotion_job_grade_delta,
          promotion_job_family_name,
          promotion_job_subfamily_name,
          promotion_job_name,
          promotion_job_grade,
          promotion_pay_grade,
          promotion_incentive_target_percentage,
          mandatory_ttc_increase_percentage,
          mandatory_ttc_increase_amount,
          apply_ti_harmonization,
          interim_job_family_name,
          interim_job_grade,
          interim_fte_incentive_target_amount,
          interim_incentive_target_percentage,
          interim_total_increase_amount,
          interim_increase_amount_for_salincr,
          interim_increase_amount_for_tih,
          interim_salary_range_min,
          interim_salary_range_max,
          step_1_abs,
          step_1_ita,
          step_1_itp,
          interim_theo_itp,
          interim_itp_situation,
          step_2_abs_gap,
          step_2_ita_gap,
          interim_remainder_for_split,
          new_fte_annual_salary,
          new_fte_incentive_target_amount,
          new_incentive_target_percentage,
          new_itp_situation,
          new_fte_ttc_annual_salary,
          new_quartile,
          new_incentive_plan_type,
          new_position_title,
          request_type,
          currency,
          offcycle_budget_update,
          new_ttc_compa_ratio,
          target_budget_org,
          organization_unit_2_name,
          organization_unit_1_name,
          ttc_increase_perc,
          current_combo_situation,
          promo_increase_pct_guideline_min,
          promo_increase_pct_guideline_max,
          promotional_increase_percentage_guideline,
          rr_number,
          case_3_12_month_actual_incentive_amount_fte,
          override_transition_allowance_local_currency,
          // new_incentive_scheme,
          internalhire_off_cycle_spending,
          internal_hire_off_cycle_spending_euro,
          is_sip,
          Case_One_Max_Budget_calc,
          Case_one_TTC_increase_minus_Max_budget,
          Case_one_Budget_charge,
          Case_two_TI_Increase,
          Case_two_Promotion_Budget,
          Case_two_Total_budget,
          Case_two_Budget_charge,
          Case_three_twelve_month_Total_Target_Cash,
          Case_three_new_TTC_Gap_to_twelve_month_TTC,
          Case_three_Max_capped_transit_allowance,
          Case_three_Transition_allowance_total,
          Case_three_Budget_charge,
          sip_case,
          Final_Off_Cycle_Budget,
          hiring_manager,
        }) => {
          try {
            const results_Inner = await createRequest({
              variables: {
                data: {
                  effective_date: convertToUTC(effectiveDate),
                  request_description: requestDescription,
                  requester_pers_no: account.pers_no,
                  request_country: country_name,
                  request_type,
                  pers_no,
                  is_gpp_eligible,
                  gpp_increase_amount,
                  gpp_increase_percentage,
                  ttc_increase_amount,
                  ttc_increase_percentage,
                  fte_ttc_annual_salary,
                  promoted,
                  demoted,
                  promotion_job_grade_delta,
                  promotion_job_family_name,
                  promotion_job_subfamily_name,
                  promotion_job_name,
                  promotion_job_grade,
                  promotion_pay_grade,
                  promotion_incentive_target_percentage,
                  mandatory_ttc_increase_percentage,
                  mandatory_ttc_increase_amount,
                  apply_ti_harmonization,
                  interim_job_family_name,
                  interim_job_grade,
                  interim_fte_incentive_target_amount,
                  interim_incentive_target_percentage,
                  interim_total_increase_amount,
                  interim_increase_amount_for_salincr,
                  interim_increase_amount_for_tih,
                  interim_salary_range_min,
                  interim_salary_range_max,
                  step_1_abs,
                  step_1_ita,
                  step_1_itp,
                  interim_theo_itp,
                  interim_itp_situation,
                  step_2_abs_gap,
                  step_2_ita_gap,
                  interim_remainder_for_split,
                  new_fte_annual_salary,
                  new_fte_incentive_target_amount,
                  new_incentive_target_percentage,
                  new_itp_situation,
                  new_fte_ttc_annual_salary,
                  new_quartile,
                  new_incentive_plan_type,
                  new_position_title,
                  currency,
                  offcycle_budget_update: Final_Off_Cycle_Budget,
                  new_ttc_compa_ratio,
                  target_budget_org,
                  organization_unit_2_name,
                  organization_unit_1_name,
                  ttc_increase_perc /*  ADD FIELD THAT USED FOR 20% MERIT INCRESE */,
                  current_combo_situation,
                  promo_increase_pct_guideline_min,
                  promo_increase_pct_guideline_max,
                  promotional_increase_percentage_guideline,
                  rr_number,
                  case_3_12_month_actual_incentive_amount_fte,
                  override_transition_allowance_local_currency,
                  // new_incentive_scheme,
                  internalhire_off_cycle_spending,
                  internal_hire_off_cycle_spending_euro,
                  is_sip,
                  Case_One_Max_Budget_calc,
                  Case_one_TTC_increase_minus_Max_budget,
                  Case_one_Budget_charge,
                  Case_two_TI_Increase,
                  Case_two_Promotion_Budget,
                  Case_two_Total_budget,
                  Case_two_Budget_charge,
                  Case_three_twelve_month_Total_Target_Cash,
                  Case_three_new_TTC_Gap_to_twelve_month_TTC,
                  Case_three_Max_capped_transit_allowance,
                  Case_three_Transition_allowance_total,
                  Case_three_Budget_charge,
                  sip_case,

                  Final_Off_Cycle_Budget,
                  hiring_manager,
                },
              },
              awaitRefetchQueries: true,
              refetchQueries: refetchAfterCreateRequest,
            });

            /*  FIL THE VALUES IN NEW FIELDS TO FIX DEFECT 9 STARTS */
            console.log(results_Inner);
            lastInsertedID =
              results_Inner.data.createRequest.employee_request_id;
            lastInsertedStatus = results_Inner.data.createRequest.status;
            /*  FIL THE VALUES IN NEW FIELDS TO FIX DEFECT 9 ENDS */
            return { [pers_no]: false };
          } catch (e) {
            const err_request_exist = e.graphQLErrors.some(
              (er) => er.extensions.code === "EXISTING_REQUEST"
            );
            return {
              [pers_no]: err_request_exist
                ? `There is already a pending request for employee ${nokia_id}`
                : e.message,
            };
          }
        }
      )
    );

    const errors = results.filter((r) =>
      Object.keys(r).find((p_no) => r[p_no])
    );
    if (!errors.length) {
      /*  SET THE MESSAGE CONDITION TO FIX DEFECT 9 STARTS */
      let finalMessage = "";
      let finalMessageType = "";
      if (lastInsertedStatus == "stopped") {
        finalMessageType = "failure";
        finalMessage = "Please review the request with HRBP!";
      } else {
        finalMessageType = "success";
        finalMessage = "Request(s) successfully sent!";
      }
      /*  SET THE MESSAGE CONDITION TO FIX DEFECT 9 ENDS */

      hideModal();
      unselectAllRecord("employees");
      budgetSetUpdated(true);
      pushMessage({
        //type: 'success',
        //message: 'Request(s) successfully sent!'
        type: finalMessageType,
        message: finalMessage /*  ADD THE MESSAGE VARIABLE TO FIX DEFECT 9 */,
      });
    } else {
      /* Addd below code for resolution for defect #16 and #17--START */
      setTimeout(() => {
        UpdateN();
      }, 100);

      /* code for resolution for defect #16 and #17--END */

      setSubmitErrors(errors.map((e) => Object.values(e).shift()));
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <FormHeading>Internal Hire</FormHeading>
      <FormLabel>Selected employees: {selectedEmployees.length}</FormLabel>
      <div style={{ overflowX: "scroll" }}>
        <SelectedTable
          recordType="employees"
          items={selectedEmployees}
          columns={columns.internalHire}
          onChange={handleChange}
          /**Release 2 - Our Talent scenario */
          changeData={updateData}
        />
      </div>
      {/* Release 3.1 - Starts */}
      {isTap && (
        <>
          <FormLabelInline>Effective date:</FormLabelInline>
          <DatePickerField
            selected={effectiveDate}
            onChange={handleEffectiveDateChange}
            minDate={new Date()}
            popperPlacement="right"
          />

          <FormLabel>
            Reason for request:
            <i style={{ width: "100%", float: "left" }}>
              [Please do not use the Reason field for Backdated changes dates as
              this field is visible only for approvers and not updating CoreHR
              System!]
            </i>
          </FormLabel>
          <DescriptionInput
            name="request_description"
            onChange={handleDescriptionChange}
            /*Commented below code for resolution for defect #16 and #17--START */
            onKeyPress={isNumber}
            /* Added for resolution for defect #16 and #17--END */
          />
        </>
      )}
      {/* Release 3.1 - Ends */}
      {submitErrors.length > 0 &&
        submitErrors.map((err) => {
          return (
            <ErrorsBlock key={Math.random()}>
              <IconWrap>!</IconWrap>
              {err}
            </ErrorsBlock>
          );
        })}
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
        {/* Release 3.1 - Starts */}
        {isTap && (
          <FormButton
            primary
            disabled={isSubmitting}
            onClick={handleRequestCompensationSend}
          >
            Send request(s)
          </FormButton>
        )}
        {/* Release 3.1 - Ends */}
        {isSubmitting && (
          <LoaderWrap>
            <Ring color={"#001235"} size="2" sizeUnit="rem" />
          </LoaderWrap>
        )}
      </FormButtonsWrap>
      <CalculatorNew
        columns={columns.internalHire}
        recordType="employees"
        item={updatedItem}
        data={updateData}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
  };
};

export default connect(mapStateToProps, {
  unselectAllRecord,
  budgetSetUpdated,
  pushMessage,
})(InternalHire);
