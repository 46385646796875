/* Release 3.2 - 8.1 - Starts */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Header,
  Budget,
  TableHeadingWrap,
  TableActionsWrap,
  TableAction,
  Table,
  GlobalMessages,
} from "../components";
import { InputField } from "../components/styled";
import { toggleShowInEur, showModal, unselectAllRecord } from "../actions";
import { columns } from "../util/config";

const EmployeeHistoryDashboard = (props) => {
  const {
    account,
    config,
    toggleShowInEur,
    showModal,
    selectedEmployees,
    unselectAllRecord,
    messages,
  } = props;
  const { roles, accessibles, forceCurrency } = account;
  const isAdmin = roles.includes("admin");
  const isGTAP = roles.includes("gtap");
  const isRTAP = roles.includes("rtap");

  const [searchText, setSearchText] = useState("");
  const [queryFilter, setTableFilter] = useState({});
  const [updatedKey, setUpdatedKey] = useState(1);

  const hasBudgetVisibility =
    (accessibles && accessibles.budgetOrgs.length > 0) || isAdmin;
  const hasSelectedEmployees =
    selectedEmployees.length > 0 && selectedEmployees.length == 1;
  const orderBy = {};
  const hasGlobalMessages = messages.length > 0;

  const handleSearchChange = (event) => {
    unselectAllRecord("employees");
    setSearchText(event.target.value);
    const searchFilter = event.target.value;
    const searchObj = { ...queryFilter, employee_search: searchFilter };
    if (searchFilter.length >= 2) {
      setTableFilter(searchObj);
    } else if (searchFilter === "" || searchFilter.length < 2) {
      const { employee_search, ...newqueryFilter } = queryFilter;
      setTableFilter(newqueryFilter);
    }
  };

  const clearChange = () => {
    unselectAllRecord("employees");
    setSearchText("");
    const { employee_search, ...newqueryFilter } = queryFilter;
    setTableFilter(newqueryFilter);
  };

  const toggleDisplayCurrency = (event) => {
    toggleShowInEur(forceCurrency ? null : "EUR");
  };

/* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
const handleOtherData = (event) =>
  showModal({ component: "otherData" });
/* Release DEM0069362:Add additional data for TAP users - Part 1 Ends */

 /* Release DEM0069359:Add additional data for TAP users - Part 1 Start */
  const handleaddressData = (event) =>
  showModal({ component: "addressData" });
   /* Release DEM0069359:Add additional data for TAP users - Part 1 Ends */


  const handlePerformanceDate = (event) =>
    showModal({ component: "performanceData" });

  const handleCompensationData = (event) =>
    showModal({ component: "compensationData" });

  useEffect(() => {
    unselectAllRecord("employees");
    if (messages.length > 0) {
      setUpdatedKey(Math.floor(Math.random() * 100));
    }
  }, [unselectAllRecord, messages]);

  return (
    <>
      {(isGTAP || isRTAP) && (
        <>
          <Header {...props} />
          {hasBudgetVisibility && <Budget {...props} />}
          {hasGlobalMessages && <GlobalMessages />}
          <TableHeadingWrap>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ marginRight: "15px" }}>Employee History Dashboard</h2>
              <InputField
                type="text"
                placeholder="Search on Nokia Id, Requisition Id, Email, Name [at least 3 characters]"
                value={searchText}
                onChange={handleSearchChange}
                style={{
                  width: "350px",
                  marginLeft: "20px",
                  height: "32px",
                  outline: "#CCC",
                  borderRadius: "5px",
                  border: "1px solid #CCC",
                }}
              />
              <div
                onClick={clearChange}
                style={{
                  height: "32px",
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: "500",
                  textAlign: "center",
                  position: "relative",
                  top: "6px",
                  right: "20px",
                }}
              >
                X
              </div>
            </div>
            <TableActionsWrap>
            {/*Release DEM0069362:Add additional data for TAP users - Part 1 Start */} 
            <TableAction
                disabled={!hasSelectedEmployees}
                onClick={handleOtherData}
              >
                Other Information
              </TableAction>
              {/*Release DEM0069362:Add additional data for TAP users - Part 1 Start */} 
            <TableAction
                disabled={!hasSelectedEmployees}
                onClick={handleaddressData}
              >
                Addresses
              </TableAction>
              <TableAction
                disabled={!hasSelectedEmployees}
                onClick={handlePerformanceDate}
              >
                Performance Data
              </TableAction>
              <TableAction
                disabled={!hasSelectedEmployees}
                onClick={handleCompensationData}
              >
                Compensation Data
              </TableAction>
            </TableActionsWrap>
          </TableHeadingWrap>
          {hasSelectedEmployees && <div>{selectedEmployees.length} selected</div>}
          <Table
            recordType="employees"
            columns={columns.employeeDashboardHistory}
            query="employeeDahboardHistoryList"
            where={queryFilter}
            order={orderBy}
            isEuroSort={forceCurrency === "EUR" ? true : false}
            config={config}
            account={account}
            checkboxIsDisplayed={true}
            key={updatedKey}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
    config: state.config,
    selectedEmployees: state.selectedEmployees,
    messages: state.messages,
  };
};

export default connect(mapStateToProps, {
  showModal,
  toggleShowInEur,
  unselectAllRecord,
})(EmployeeHistoryDashboard);
/* Release 3.2 - 8.1 - Ends */
