import { percentRenderer } from '.';

export const absIncreasePercentageRenderer = (value, data, account = {}, config = {}) => {
  let v;
  switch (data.__typename) {
    case "EmployeeFull":
      v = (data.ttc_increase_amount / data.fte_annual_salary) * 100;
      break;
    case "Request":
      v = (data.request.ttc_increase_amount / data.request.for.fte_annual_salary) * 100;
      break;
    default:
      v = 0;
      break;
  }
  return percentRenderer(v);
}