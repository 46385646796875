import React from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { Grid } from 'react-awesome-spinners';
import { BUDGETS,
  /* Release 3.2 - 1.1 - Starts */ organizationBEABudget, /* Release 3.2 - 1.1 - Ends */
 } from '../graphql';
import { currencyDisplayRenderer } from '../util';
import { Header, BudgetWrap, BudgetContent, BudgetRow, BudgetCell, BudgetLabel, GridLoader } from '../components';

const AllBudgets = props => {
  const { account } = props;
  const { accessibles } = account;
  console.log("All budgets" , accessibles);

  const query = { organization_unit: { in: accessibles.budgetOrgs } };

  const { loading, error, data } = useQuery(BUDGETS, { variables: { where: query } });
 
  const responseData = data && data.budgets;

  {/*  INITIALIZED THE TOTAL VARIABLES TO FIX THE DEFECT 12 OF BUDGET */}
  let totalBudget = 0;
  let totalInProgress = 0;
  let totalSpent = 0;
  let totalAvailFore = 0;
  let totalAvailable = 0;

  /* Release 3.2 - 1.1 - Starts */
  const currentYear = new Date().getFullYear().toString();
  const beaBudgetQuery = { year: { eq: currentYear} };
  const { loading: beaBudgetLoding, error: beaBudgetError, data: beaBudgetData } = useQuery(organizationBEABudget, { variables: { where: beaBudgetQuery } });
  const beaBudgetResponseData = beaBudgetData && beaBudgetData.organizationBEABudget;

  let totalBeaBudget = 0;
  let totalBeaInProgress = 0;
  let totalBeaSpent = 0;
  let totalBeaAvailFore = 0;
  let totalBeaAvailable = 0;
  /* Release 3.2 - 1.1 - Ends */
  return (
    <>   
      <Header {...props}/>
      <h2>Budget Display</h2>
      <BudgetWrap>
        <BudgetContent>
          {(loading /* Release 3.2 - 1.1 - Starts */ || beaBudgetLoding /* Release 3.2 - 1.1 - Ends */) && <GridLoader><Grid color={'#001235'}/></GridLoader>} 
          {(error /* Release 3.2 - 1.1 - Starts */ || beaBudgetError /* Release 3.2 - 1.1 - Ends */) && <p>Error :</p>}
          {/*  COMMENTS OLD CONDITION AND ADD NEW ONE TO SORT ORG COLUMN IN DEFECT 12 OF BUDGET */}
          {/*!loading && responseData && responseData.items.map((item, index) => {*/}
          {/* Release 3.2 - 1.1 - Starts */}
          <BudgetRow>           
            <BudgetCell><BudgetLabel>Offcycle Budget </BudgetLabel></BudgetCell>
          </BudgetRow>          
          <BudgetRow>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
          </BudgetRow>
          {/* Release 3.2 - 1.1 - Ends */}
          {!loading && responseData && 
            responseData.items.sort((a, b) => (a.org && a.org.organization_unit_name ? a.org.organization_unit_name : "") > (b.org && b.org.organization_unit_name ? b.org.organization_unit_name : "") ? 1:-1)
            .map((item, index) => {
            {/*  FILL THE TOTAL VARIABLES TO FIX THE DEFECT 12 OF BUDGET */}
            totalBudget = parseFloat(totalBudget) + parseFloat(item.budget) ;
            totalInProgress = parseFloat(totalInProgress) + parseFloat(item.budget_in_progress) ;
            totalSpent = parseFloat(totalSpent) + parseFloat(item.budget_spent) ;
            totalAvailFore = parseFloat(totalAvailFore) + parseFloat(item.budget_available) ;
            totalAvailable = parseFloat(totalAvailable) + (parseFloat(item.budget - item.budget_spent)) ;
            return (
              <BudgetRow key={Math.random()}>
                <BudgetCell><BudgetLabel>Budget: </BudgetLabel>{currencyDisplayRenderer(item.budget, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>In Progress: </BudgetLabel>{currencyDisplayRenderer(item.budget_in_progress, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>Spent: </BudgetLabel>{currencyDisplayRenderer(item.budget_spent, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>Available Forecast: </BudgetLabel>{currencyDisplayRenderer(item.budget_available, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>Available: </BudgetLabel>{currencyDisplayRenderer(item.budget - item.budget_spent, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>{item.org && item.org.organization_unit_name ? item.org.organization_unit_name : ""}</BudgetLabel></BudgetCell>
              </BudgetRow>
            );
          })}
          {/*  DISPLAYED THE TOTAL VARIABLES TO FIX THE DEFECT 12 OF BUDGET */}
          <BudgetRow>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
          </BudgetRow>
          <BudgetRow>            
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalBudget, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalInProgress, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalSpent, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalAvailFore, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalAvailable, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel></BudgetLabel></BudgetCell>
          </BudgetRow>

          {/* Release 3.2 - 1.1 - Starts */}          
          <BudgetRow>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
          </BudgetRow>
          <BudgetRow>           
            <BudgetCell><BudgetLabel>Business Excellence Award Budget </BudgetLabel></BudgetCell>
          </BudgetRow>          
          <BudgetRow>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
          </BudgetRow>
          {!beaBudgetLoding && beaBudgetResponseData && 
          beaBudgetResponseData.items.sort((a, b) => (a.organization_unit_name ? a.organization_unit_name : "") > (b.organization_unit_name ? b.organization_unit_name : "") ? 1:-1)
          .map((item, index) => {
            totalBeaBudget = parseFloat(totalBeaBudget) + parseFloat(item.budget) ;
            totalBeaInProgress = parseFloat(totalBeaInProgress) + parseFloat(item.budget_in_progress) ;
            totalBeaSpent = parseFloat(totalBeaSpent) + parseFloat(item.budget_spent) ;
            totalBeaAvailFore = parseFloat(totalBeaAvailFore) + parseFloat(item.budget_available) ;
            totalBeaAvailable = parseFloat(totalBeaAvailable) + (parseFloat(item.budget - item.budget_spent)) ;
            return (
              <BudgetRow key={Math.random()}>
                <BudgetCell><BudgetLabel>Budget: </BudgetLabel>{currencyDisplayRenderer(item.budget, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>In Progress: </BudgetLabel>{currencyDisplayRenderer(item.budget_in_progress, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>Spent: </BudgetLabel>{currencyDisplayRenderer(item.budget_spent, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>Available Forecast: </BudgetLabel>{currencyDisplayRenderer(item.budget_available, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>Available: </BudgetLabel>{currencyDisplayRenderer(item.budget - item.budget_spent, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>{item.organization_unit_name ? item.organization_unit_name : "N/A"}</BudgetLabel></BudgetCell>
              </BudgetRow>
            );
          })}
          <BudgetRow>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
          </BudgetRow>          
          <BudgetRow>            
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalBeaBudget, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalBeaInProgress, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalBeaSpent, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalBeaAvailFore, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Total: </BudgetLabel>{currencyDisplayRenderer(totalBeaAvailable, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel></BudgetLabel></BudgetCell>
          </BudgetRow>
          <BudgetRow>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
            <BudgetCell><BudgetLabel>&nbsp;</BudgetLabel></BudgetCell>
          </BudgetRow>           
          {/* Release 3.2 - 1.1 - Ends */}
        </BudgetContent>
      </BudgetWrap>
    </>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account
  };
};

export default connect(
  mapStateToProps
)(AllBudgets);
