export default (table) => {
    let name = 'email_work';
    /* Release 3.2 - 1.1 - Starts */
    let sortable = true;
    switch (table) {
      case 'beabusinessownerbudgetmanage':
        sortable = false;
        break;
    }
    /* Release 3.2 - 1.1 - Ends */
    return {
      type: "string",
      name,
      label: "Email Address",
      section: "TAP Employees Information",
      visible: true,
      /* Release 3.2 - 1.1 - Starts */
      // sortable: true,
      sortable
      /* Release 3.2 - 1.1 - Ends */
      // width: '100px'
    };
  };