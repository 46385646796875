/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const ModalWrap = styled.div`
  overflow: hidden;
  position: relative;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999999990;
  background-color: ${({ theme }) => theme.fadedTextColor};
  opacity: 0.8;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999991;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

const ModalInnerContent = styled.div`
  width: 100%;
  height: auto;
  padding: 2rem;
  background-color: ${({ theme }) => theme.primaryBgColor};
  position: relative;
  text-align: left;
  // margin: 80px 0 40px;
  margin:${({size})=>size=='md'?'20px 0 40px;': '80px 0 40px'};
  overflow: hidden;
  z-index: 0;

  @media (min-width: 768px) {

    // width: 95%;
    width:${({size})=>size=='md'?'70%':'95%'};

      }
`;
/*	INCREASE MODAL WINDOW MARGIN DUE TO DELEGATE	*/
// const ModalInnerContent = styled.div`
//   width: 100%;
//   height: auto;
//   padding: 2rem;
//   background-color: ${({ theme }) => theme.primaryBgColor};
//   position: relative;
//   text-align: left;
//   margin: 10px 0 40px;
//   overflow: hidden;
//   z-index: 0;

//   @media (min-width: 768px) {
//     width: 95%;
// 	height:95%;
//   }
// `;

const ModalClose = styled.button`
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  top: 5px;
  right: 5px;
  background-color: transparent;
  color: #000000;
  border: 0;
  line-height: 30px;
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
`;

export {
  ModalWrap,
  ModalOverlay,
  ModalContent,
  ModalInnerContent,
  ModalClose
};
