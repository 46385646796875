import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showModal, unselectAllRecord, toggleShowInEur } from "../actions";
import {
  Budget,
  GlobalMessages,
  Table,
  TableHeadingWrap,
  TableActionsWrap,
  TableAction,
  Header,
  SelectFilter,
  /* Release 3.2 - 7.1 - Starts */
  LoaderWrap,
  /* Release 3.2 - 7.1 - Ends */
} from "../components";
import { columns } from "../util/config";
// R2 - imported inputfield for search
import { InputField } from "../components/styled";
/* Release 3.2 - 7.1 - Starts */
import exportFromJSON from "export-from-json";
import { apolloClient, apolloProxyClient } from "../index";
import * as queries from "../graphql";
import { Ring } from "react-awesome-spinners";
import {
  dateRenderer,
  dateTimeRenderer,
  currencyDisplayRenderer,
  percentRenderer,
  incentivePlanRenderer,
  numberRenderer,
  currencySymbolRenderer,
} from "../util";
/* Release 3.2 - 7.1 - Ends */

const PendingRequests = (props) => {
  // const { account, selectedRequests, messages, showModal, unselectAllRecord, toggleShowInEur } = props;
  const {
    account,
    selectedRequests,
    messages,
    showModal,
    unselectAllRecord,
    toggleShowInEur,
    config,
    /* Release 3.1 - Starts */
    budget,
    budgetSetUpdated,
    /* Release 3.1 - Ends */
  } = props;  
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { forceCurrency, accessibles } = account;
  // unselect all requests which may be selected on another page
  /* Release 3.1 - Starts */
  const [tableRefreshKey, setTableRefreshKey] = useState(1);
  const [budgetRefreshKey, setBudgetRefreshKey] = useState(2);
  /* Release 3.2 - 7.1 - Starts */
  const [isSubmitting, setIsSubmitting] = useState(false);
  /* Release 3.2 - 7.1 - Ends */
  /* Release 3.1 - Ends */
  useEffect(() => {
    unselectAllRecord("requests");
    /* Release 3.1 - Starts */
    if (messages.length > 0) {
      setTableRefreshKey(Math.floor(Math.random() * 86));
      setBudgetRefreshKey(Math.floor(Math.random() * 87));
    }
    /* Release 3.1 - Ends */
  }, [
    unselectAllRecord,
    /* Release 3.1 - Starts */
    messages,
    /* Release 3.1 - Ends */
  ]);
  const isGct = account.roles.includes("gct");

  /*  ADD A NEW ROLE FOR ADMIN FUNCTIONALITY 19 */
  const isAdmin = account.roles.includes("admin");

  /* Release 3.2 - 10.4 - Starts */
  const isKeyUser = account.roles.includes('keyuser')
  /* Release 3.2 - 10.4 - Ends */
  
  /* Release 3.2 - 1.1 - Starts */
  const isHR1 = account.roles.includes('hr1');
  const isHR2 = account.roles.includes('hr2');
  const isHR3 = account.roles.includes('hr3');
  const isRcb= account.roles.includes('rcb');
  const isChr= account.roles.includes('chr');
  const isHRC= account.roles.includes('hrc');
  const isBCB= account.roles.includes('bcb');
  const isRTap= account.roles.includes('rtap');
  const isGTap= account.roles.includes('gtap');
  /* Release 3.2 - 1.1 - Ends */
  /*  ADD A NEW ROLE FOR ADMIN FUNCTIONALITY 19 */

  /*  FIXED THE ISSUE OF RECORDS SELECTION ON ADMIN ROLE LOGGED IN STARTS */
  /* Release 3.1 - 4.2 - 145671 Issue - Starts */
  const [queryFilter, setTableFilter] = useState((isAdmin /* Release 3.2 - 10.4 - Starts */ || isKeyUser /* Release 3.2 - 10.4 - Ends */ ) ? {
    approver_pers_no: { ne: "" },
    approval_status: { eq: "active" },
    req_page: "requeststoapprove",
    reqtype: "all",
  } : {
    approver_pers_no: { eq: account.pers_no },
    approval_status: { eq: "active" },
  });
  /* Release 3.1 - 4.2 - 145671 Issue - Ends */
  //const queryFilter = isAdmin ? {approval_status: {eq: 'active' }} : { approver_pers_no: { eq: account.pers_no }, approval_status: {eq: 'active' } };

  //const [queryFilter, setTableFilter] = useState(isAdmin ? {approval_status: {eq: 'active' }} : { approver_pers_no: { eq: account.pers_no }, approval_status: {eq: 'active' } });

  // R2 - Search Functionality State variable
  const [searchText, setSearchText] = useState("");

  const orderBy = { employee_request_id: "DESC" };

  const hasSelectedRequests = selectedRequests.length > 0;
  const hasGlobalMessages = messages.length > 0;
  const hasBudgetVisibility =
    ((accessibles && accessibles.budgetOrgs.length > 0) || isGct || isAdmin);
  //START WORK BLANK PAGE ISSUE DEMAND DEM0058138 OCC_Inactive_Withdrawn user issue_661
  const canCancel =
    hasSelectedRequests &&
    /* Release 3.1 Starts */
    ((!isAdmin /* Release 3.2 - 10.4 - Starts */ && !isKeyUser /* Release 3.2 - 10.4 - Ends */)
      ? selectedRequests.every((r) => r.request.for.nokia_id !== "NA")
      : true);
  /* Release 3.1 Ends */
  //End WORK BLANK PAGE ISSUE DEMAND DEM0058138 OCC_Inactive_Withdrawn user issue_661

  const handleRequestApprovals = (event) => showModal({ component: "approve" });
  const handleRequestRejections = (event) => showModal({ component: "reject" });
  const handleRequestSendback = (event) => showModal({ component: "sendback" });
  /* Release 3.1 - Starts */
  const handleRequestCancelations = (event) =>
    showModal({ component: "cancel" });
  /* Release 3.1 - Ends */
  // R2 - Search Functionality Starts
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    const searchFilter = event.target.value;
    const searchObj = { ...queryFilter, employee_search: searchFilter };
    if (searchFilter.length >= 2) {
      setTableFilter(searchObj);
      // setTableFilter(isAdmin ? { approval_status: {eq: 'active' }, employee_search: searchFilter }:{ approver_pers_no: { eq: account.pers_no }, approval_status: {eq: 'active' }, employee_search: searchFilter });
    } else if (searchFilter === "" || searchFilter.length < 2) {
      const { employee_search, ...newqueryFilter } = queryFilter;
      setTableFilter(newqueryFilter);
      // setTableFilter(isAdmin ? {approval_status: {eq: 'active' }} : { approver_pers_no: { eq: account.pers_no }, approval_status: {eq: 'active' } });
    }
    /* Release 3.2 - 7.2 - Starts */
    unselectAllRecord("requests");
    /* Release 3.2 - 7.2 - Ends */
  };

  const clearChange = () => {
    setSearchText("");
    const { employee_search, ...newqueryFilter } = queryFilter;
    setTableFilter(newqueryFilter);
    // setTableFilter(isAdmin ? {approval_status: {eq: 'active' }} : { approver_pers_no: { eq: account.pers_no }, approval_status: {eq: 'active' } });
    /* Release 3.2 - 7.2 - Starts */
    unselectAllRecord("requests");
    /* Release 3.2 - 7.2 - Ends */
  };
  // R2 - Search Functionality Ends
  /*  FIX THE DEFECT 10 STARTS  */
  const toggleDisplayCurrency = (event) => {
    toggleShowInEur(forceCurrency ? null : "EUR");
  };
  /*  FIX THE DEFECT 10 ENDS  */

  /*	FOR DELEGATION */
  /* Release 3.1 - 4.2 - 145671 Issue - Starts */
  const filterOptions = (isAdmin /* Release 3.2 - 10.4 - Starts */ || isKeyUser /* Release 3.2 - 10.4 - Ends */) ? ["All", "My Approval", "Delegated Approval"] : ["My Approval", "Delegated Approval", "All"];
  /* Release 3.1 - 4.2 - 145671 Issue - Ends */
  const onSelectChange = (event) => {
    setSearchText("");
    const filter = event.target.value;
    switch (filter) {
      case "Delegated Approval":
        setTableFilter({
          approver_pers_no: { eq: account.pers_no },
          approval_status: { eq: "active" },
          req_page: "requeststoapprove",
          reqtype: "delegator",
        });
        break;
      case "My Approval":
        setTableFilter({
          approver_pers_no: { eq: account.pers_no },
          approval_status: { eq: "active" },
        });
        break;
      case "All":
        /*  FIXED THE ISSUE OF RECORDS SELECTION ON ADMIN ROLE LOGGED IN STARTS */
        if (isAdmin /* Release 3.2 - 10.4 - Starts */ || isKeyUser /* Release 3.2 - 10.4 - Ends */) {
          setTableFilter({
            approver_pers_no: { ne: "" },
            approval_status: { eq: "active" },
            req_page: "requeststoapprove",
            reqtype: "all",
          });
        } else {
          setTableFilter({
            approver_pers_no: { eq: account.pers_no },
            approval_status: { eq: "active" },
            req_page: "requeststoapprove",
            reqtype: "all",
          });
        }
        /*  FIXED THE ISSUE OF RECORDS SELECTION ON ADMIN ROLE LOGGED IN ENDS */
        break;

      default:
        setTableFilter({
          approver_pers_no: { eq: account.pers_no },
          approval_status: { eq: "active" },
        });
    }
    /* Release 3.1 - Starts */
    unselectAllRecord("requests");
    /* Release 3.1 - Ends */
  };
  /* Release 3.2 - 7.1 - Starts */
  const handleExportData = (event) => {
    /* Release 3.2 - 7.2 - Starts */
    let filterValue = null;
    if(hasSelectedRequests) {
      // console.log("selectedRequests", selectedRequests);
      const resultEmployeeRequestId = selectedRequests.map(a => a.employee_request_id);
      // console.log("resultEmployeeRequestId", resultEmployeeRequestId);
      filterValue = {
        ...queryFilter,
        employee_request_id: {in: resultEmployeeRequestId}
      }
     } else {
      filterValue = {...queryFilter};
    }
    // console.log("filterValue", filterValue);
    /* Release 3.2 - 7.2 - Ends */
    event.preventDefault();
    setIsSubmitting(true);
    var tableQuery = 'approvals';
    httpClient
      .query({
        query: queries[tableQuery],
        variables: {
          where: /* Release 3.2 - 7.2 - Starts */filterValue/* Release 3.2 - 7.2 - Ends */,
          offset: 0,
          orderBy: orderBy.length ? orderBy : null,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let data = response.data[tableQuery].items.map(
          ({
            upd_ins_by,
            upd_ins_ts,
            __typename,
            ...rest
          }) => rest
        );
        if (data && data.length > 0) {
          data = data.map(function(item){
            let employee_request_id = item.employee_request_id;
            item = item.request;
            let current_itp_situation = item.for && item.for.current_itp_situation ? item.for.current_itp_situation : 'Norm'
            let new_itp_situation = item.new_itp_situation ? item.new_itp_situation : 'Norm';
            console.log('datafetch-item', item);
            let approvals = '';
            for (let i = 0; i < item.approvals.length;i++) {
              let a = item.approvals[i];
              approvals += a.approver === null ? (
                a.approval_status === "active" ? "Inactive approver"
                : "Inactive approver"
              ) : a.approval_status === "active" ? 
                  a.approver.full_name
              : 
                  a.approver.full_name;
              if (i < item.approvals.length -1 ) {
                approvals += " ⮕ "
              }
            }

            let comments = '';
            for (let i = 0; i < item.comments.length;i++) {
              let a = item.comments[i];
              comments += "◉ ";
              comments += a.description;
              comments += " --";
              comments += (a.commenter === null ? "Inactive Approver" : a.commenter.full_name);
              comments += ", ";
              comments += dateTimeRenderer(a.upd_ins_ts);
              if (i < item.comments.length -1 ) {
                comments += "\n";
              }
            }

            let changeApprovers = '';
            for (let i = 0; i < item.changeApprovers.length;i++) {
              let a = item.changeApprovers[i];
              changeApprovers += "◉ ";
              changeApprovers += a.approval && a.approval != null? "Level " + a.approval.approver_level : "";
              changeApprovers += " -'"; 
              changeApprovers += (a.preApprover ? a.preApprover.full_name : "Inactive approver"); 
              changeApprovers += " ' replaced with '";
              changeApprovers += (a.postApprover ? a.postApprover.full_name : "Inactive approver");
              changeApprovers += "' --";
              changeApprovers += (a.changer ? a.changer.full_name : "Inactive changer");
              changeApprovers += ", ";
              changeApprovers += dateTimeRenderer(a.upd_ins_ts);

              if (i < item.changeApprovers.length -1 ) {
                changeApprovers += "\n";
              }
            }
            
            let total_increaseed_percent = 0;
            if (item.request_type === "onetime"  || item.request_type === "businessexcellenceaward") {
              total_increaseed_percent = item.ttc_increase_percentage;
            } else {
              total_increaseed_percent =
                ((item.new_fte_ttc_annual_salary -
                  item.for.fte_ttc_annual_salary) /
                  item.for.fte_ttc_annual_salary) *
                100;
            }
            /* Release 3.2 - 1.1 Starts */
            let finalData = {};           
            finalData["ID"] = employee_request_id;
            finalData["Date"] = dateTimeRenderer(item.created_ts);
            finalData["Requestor"] = item.by && item.by ? item.by.full_name : '';
            finalData["Request Type"] = item.request_type;
            finalData["Employee"] = `${item.for.full_name} (${item.for.nokia_id})`;
            if (isHR1 || isHR2 || isHR3 || isRcb || isChr || isHRC || isBCB || isRTap || isGTap) {
              finalData["Organization Unit"] = item.organization_unit_name || (item.request_type == 'internalhire' ? item.hmanager && item.hmanager.organization_unit_name ? item.hmanager.organization_unit_name : '' : item.for && item.for.organization_unit_name ? item.for.organization_unit_name : '');
            }
            if (isHR1 || isHR2 || isHR3 || isRcb || isChr || isHRC || isBCB || isRTap || isGTap || isKeyUser) {
              finalData["Business Group"] = item.organization_unit_1_name || (item.request_type == 'internalhire' ? item.hmanager.organization_unit_1_name : item.for.organization_unit_1_name);
            }

            finalData["Amount"] = currencyDisplayRenderer(item.ttc_increase_amount, item, account,
              config);
            finalData["Total Increase %"] = percentRenderer(total_increaseed_percent);
            finalData["Reason for the Request"] = item.request_description;
            finalData["Status"] = item.status;

            finalData["Current-Country"] = item.for && item.for.country_name && item.request_type !== "businessexcellenceaward" ? item.for.country_name : '';
            finalData["Current-Job Family"] = item.for && item.for.job_family_name && item.request_type !== "businessexcellenceaward" ? item.for.job_family_name : '';
            finalData["Current-Job Sub Family"] = item.for && item.for.job_subfamily_name && item.request_type !== "businessexcellenceaward" ? item.for.job_subfamily_name : ''; 
            finalData["Current-Job"] = item.for && item.for.job_name && item.request_type !== "businessexcellenceaward" ? item.for.job_name : '';
            finalData["Current-Job Grade"] = item.for && item.for.job_grade && item.request_type !== "businessexcellenceaward" ? item.for.job_grade : ''; 
            finalData["Current-Position Title"] = item.for && item.for.position_title && item.request_type !== "businessexcellenceaward" ? item.for.position_title : ''; 
            finalData["Current-Annual Base Salary"] = item.for && item.for.fte_annual_salary && item.request_type !== "businessexcellenceaward" ? currencyDisplayRenderer(item.for.fte_annual_salary, item, account,
              config) : '';
            finalData["Current-Incentive Target Percentage"] = item.for && item.for.fte_incentive_target_percentage && item.request_type !== "businessexcellenceaward" ? percentRenderer(item.for.fte_incentive_target_percentage) + ' ' + current_itp_situation : ''; 
            finalData["Current-Incentive Target Amount"] = item.for && item.for.fte_incentive_target_amount && item.request_type !== "businessexcellenceaward" ? currencyDisplayRenderer(item.for.fte_incentive_target_amount, item, account,
              config) : '';
            finalData["Current-Total Target Cash"] = item.for && item.for.fte_ttc_annual_salary && item.request_type !== "businessexcellenceaward" ? currencyDisplayRenderer(item.for.fte_ttc_annual_salary, item, account,
              config) : ''; 
            finalData["Current-TTC Compa-Ratio"] = item.for && item.for.ttc_compa_ratio && item.request_type !== "businessexcellenceaward" ? numberRenderer(item.for.ttc_compa_ratio) : ''; 
            finalData["Current-Incentive Plan"] = item.for && item.for.incentive_plan_type && item.request_type !== "businessexcellenceaward" ? item.for.incentive_plan_type : ''; 
            finalData["Current-Quartile"] = item.for && item.for.ttc_employee_quartile && item.request_type !== "businessexcellenceaward" ? item.for.ttc_employee_quartile : ''; 

            finalData["New-Country"] = item.for && item.for.country_name && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.for.country_name : ''; 
            finalData["New-Job Family"] = item.promotion_job_family_name && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.promotion_job_family_name : ''; 
            finalData["New-Job Sub Family"] = item.promotion_job_subfamily_name && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.promotion_job_subfamily_name : ''; 
            finalData["New-Job"] = item.promotion_job_name && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.promotion_job_name : ''; 
            /*  CAL5065706 - Issue Fixed - By Changing New Job Grade Starts */
            //finalData["New-Job Grade"] = item.for && item.for.job_grade && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.for.job_grade : ''; 
            finalData["New-Job Grade"] = item.promotion_job_grade && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.promotion_job_grade : ''; 
            /*  CAL5065706 - Issue Fixed - By Changing New Job Grade Ends */
            finalData["New-Position Title"] = item.new_position_title && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.new_position_title : ''; 
            finalData["New-Annual Base Salary"] = item.new_fte_annual_salary && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? currencyDisplayRenderer(item.new_fte_annual_salary, item, account,
              config) : '';
            finalData["New-Incentive Target Percentage"] = item.new_incentive_target_percentage && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? percentRenderer(item.new_incentive_target_percentage) + ' ' + new_itp_situation : '';
            finalData["New-Incentive Target Amount"] = item.new_fte_incentive_target_amount && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? currencyDisplayRenderer(item.new_fte_incentive_target_amount, item, account,
              config) : ''; 
            finalData["New-Total Target Cash"] = item.new_fte_ttc_annual_salary && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? currencyDisplayRenderer(item.new_fte_ttc_annual_salary, item, account,
              config) : '';
            finalData["New-TTC Compa-Ratio"] = item.new_ttc_compa_ratio && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? numberRenderer(item.new_ttc_compa_ratio) : '';
            finalData["New-Incentive Plan"] = item.new_incentive_plan_type && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.new_incentive_plan_type : ''; 
            finalData["New-Quartile"] = item.new_quartile && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.new_quartile : '';

            finalData["Effective/Payment Date"] = /* Release 3.2 - 1.1 - Starts */(item.request_type == "onetime" || item.request_type == "businessexcellenceaward")/* Release 3.2 - 1.1 - Ends */ ? (item.payment_date ? dateRenderer(item.payment_date) : '') : (item.effective_date ? dateRenderer(item.effective_date) : '');
            /* Release 3.2 - 11.2 - Starts */
            //  finalData["Last Reminder Date"] = item.reminder_info && item.reminder_info.last_reminder_date ? dateTimeRenderer(item.reminder_info.last_reminder_date) : 'N/A';
            //  finalData["Reminder Count"] = item.reminder_info && item.reminder_info.reminder_count ? item.reminder_info.reminder_count : '0';
            /* Release 3.2 - 11.2 - Ends */
            finalData["Off-Cycle Budget Spending"] = item.offcycle_budget_update ? currencyDisplayRenderer(item.offcycle_budget_update, item, account,
              config) : ''; 
            finalData["Approval Chain"] = approvals;
            finalData["Comments"] = comments;
            finalData["Change Approver"] = changeApprovers; 
            /* Release 3.2 - 1.1 - Starts */
            finalData["Award Title"] = item.award_title ? item.award_title : '';                                
            finalData["Award Message"] = item.award_message ? item.award_message : '';                                
            finalData["Award Privacy"] = item.award_privacy ? item.award_privacy : '';                                
            finalData["Award Reason"] = item.award_reason ? item.award_reason : '';                                
            finalData["Award Amount"] = (item.ttc_increase_amount && item.request_type == 'businessexcellenceaward') ? item.ttc_increase_amount : ''; 
            finalData["Nominator Email Address"] = item.nominator_email_address ? item.nominator_email_address : '';
            /* Release 3.2 - 1.1 - Ends */                                      
          
            return finalData;
            /* Release 3.2 - 1.1 Ends */
          });
          data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':""'));
          const fileName = "pending_requests_" + new Date().toISOString();
          const exportType = "csv";
          exportFromJSON({ data, fileName, exportType });
          setIsSubmitting(false);
        } else {
          setIsSubmitting(false);
          alert("No Record Found to Export the Excel");
        }
        /* Release 3.2 - 7.2 - Starts */
        delete filterValue.employee_request_id;
        /* Release 3.2 - 7.2 - Ends */
      })
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
      });
  };
/* Release 3.2 - 7.1 - Ends */
  return (
    <>
      <Header {...props} />
      {hasBudgetVisibility && (
        <Budget
          /* Release 3.1 - Starts */ 
          account={account}
          budget={budget}
          budgetSetUpdated={budgetSetUpdated}
          key={budgetRefreshKey} 
          /* Release 3.1 - Ends*/
        />
      )}
      {hasGlobalMessages && <GlobalMessages />}
      <TableHeadingWrap>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ marginRight: "25px" }}>Requests to Approve/Reject</h2>
          <SelectFilter
            options={filterOptions}
            onSelectChange={onSelectChange}
          ></SelectFilter>
          {/* R2 - Search and clear input boxes */}
          <InputField
            type="text"
            placeholder="Search on Nokia Id, Request Id, Email, Name, Country [at least 2 characters]"
            value={searchText}
            onChange={handleSearchChange}
            style={{
              width: "350px",
              marginLeft: "20px",
              height: "32px",
              outline: "#CCC",
              borderRadius: "5px",
              border: "1px solid #CCC",
            }}
          />
          <div
            onClick={clearChange}
            style={{
              height: "32px",
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: "500",
              textAlign: "center",
              position: "relative",
              top: "6px",
              right: "20px",
            }}
          >
            X
          </div>
          {/* R2 - Search and clear input boxes */}
        </div>
        <TableActionsWrap>  
        {/* Release 3.2 - 7.1 - Starts */}        
        {isSubmitting && (
            <TableAction 
            style={{
              border: "0",
              marginBottom: "5px",
            }}
          >
            <LoaderWrap>
              <Ring color={"#001235"} size="2" sizeUnit="rem" />
            </LoaderWrap>
            </TableAction>
          )}
          <TableAction
            primary
            disabled={isSubmitting}
            onClick={handleExportData}
          >
            Export To Excel
          </TableAction>
          {/* Release 3.2 - 7.1 - Ends */}
          {/*  FIX THE DEFECT 10 STARTS  */}
          <TableAction onClick={toggleDisplayCurrency}>{`Switch to ${
            forceCurrency === "EUR" ? "local currency" : "EUR"
          }`}</TableAction>
          {/*  FIX THE DEFECT 10 ENDS  */}
          {/*START WORK BLANK PAGE ISSUE DEMAND DEM0058138 OCC_Inactive_Withdrawn user issue_661*/}
          {/* Release 3.1 - Starts */}
          <TableAction disabled={!canCancel} onClick={handleRequestApprovals}>
            Approve
          </TableAction>
          {(!isAdmin) && (
            <>
              <TableAction
                disabled={!canCancel}
                onClick={handleRequestRejections}
              >
                Reject
              </TableAction>
              {/* Release 3.2 - 10.4 - Starts */}
              { !isKeyUser && (
                <TableAction
                  disabled={!canCancel}
                  onClick={handleRequestSendback}
                >
                  Send back
                </TableAction>
              )}
              {/* Release 3.2 - 10.4 - Ends */}
            </>
          )}
          <TableAction
            disabled={!hasSelectedRequests}
            onClick={handleRequestCancelations}
          >
            Cancel
          </TableAction>
          {/* Release 3.1 - Ends */}
          {/*END WORK BLANK PAGE ISSUE DEMAND  DEM0058138 OCC_Inactive_Withdrawn user issue_661*/}
        </TableActionsWrap>
      </TableHeadingWrap>
      {/* <Table recordType="requests" columns={columns.pendingRequests} query="approvals" where={queryFilter} order={orderBy}/> */}
      {/* DEFECT No. 14 Statrs  */}
      <Table
        recordType="requests"
        columns={columns.pendingRequests}
        query="approvals"
        where={queryFilter}
        order={orderBy}
        isEuroSort={forceCurrency === "EUR" ? true : false}
        config={config}
        account={account}
        /* Release 3.1 - Starts */
        checkboxIsDisplayed={true}
        key={tableRefreshKey}
        /* Release 3.1 - Ends */
      />
      {/* DEFECT No. 14 Ends  */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
    messages: state.messages,
    // {/* DEFECT No. 14 Statrs  */}
    config: state.config,
    // {/* DEFECT No. 14 Ends  */}
  };
};

export default connect(mapStateToProps, {
  showModal,
  unselectAllRecord,
  toggleShowInEur,
})(PendingRequests);
