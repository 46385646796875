import React, {
  useState,
} from "react";
import { useMutation } from "@apollo/react-hooks";
import { Ring } from "react-awesome-spinners";
import { connect } from "react-redux";
import {
  SelectedTable,
  CalculatorNew,
  FormButton,
  FormButtonsWrap,
  ErrorsBlock,
  LoaderWrap,
  FormLabel,
  DescriptionInput,
  FormHeading,
} from "../";
import { columns } from "../../util/config";
import { unselectAllRecord, pushMessage } from "../../actions";
import {
  APPROVE_REQUEST,
  approvals,
  OVERRIDE_REQUEST_WITH_BUDGET,
  organizationBEABudget
} from "../../graphql";
/* Release 3.1 - Starts */
import { convertToUTC, formatDate } from "../../util/helper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
/* Release 3.1 - Ends */

const ApproveRequest = (props) => {
  const {
    selectedRequests,
    account,
    hideModal,
    unselectAllRecord,
    pushMessage,
  } = props;
  /* Release 3.1 - Starts */
  const { roles } = account;
  const isAdmin = roles.includes("admin");
  /* Release 3.1 - Ends */
  const [updatedItem, setUpdatedItem] = useState("");
  const [comment, setComment] = useState("");
  const [updateData, setUpdateData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);

  const [approveRequest] = useMutation(APPROVE_REQUEST);
  const [overrideRequest] = useMutation(OVERRIDE_REQUEST_WITH_BUDGET);

  const update = (item, data) => {
    if (updatedItem !== item) {
      setUpdatedItem(item);
    }
    setUpdateData(data);
  };

  const handleChange = (args) => (event) => {
    const { column, columns, item } = args;
    const indexColumn = columns.find((c) => c.index);
    let value =
      column.inputType === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = column.type === "float" ? parseFloat(value) : value;
    const valueObject = column.name.split(".").reduceRight((a, c) => {
      return { [c]: a };
    }, value);
    
    if (item.request.request_type === "internalhire") {
      valueObject.request_type = "internalhire";
      valueObject.request_from = "override";
    }
    update(item[indexColumn.name], valueObject);
  };

  const handleCommentChange = (event) => setComment(event.target.value);

  const handleQuit = (event) => {
    hideModal();
    unselectAllRecord("requests");
  };

  const handleApproveSend = async (event) => {
    setIsSubmitting(true);
    setSubmitErrors([]);
    /* Release 3.1 - Starts */
    let validationErrors = [];
    /* Release 3.1 - Ends */
    if (!comment) {
      /* Release 3.1 - Starts */
      validationErrors.push("Comment is required!");
      /* Release 3.1 - Ends */
    }
    if (comment && comment.replace(/\s/g, "").length == 0) {
      /* Release 3.1 - Starts */
      validationErrors.push("Comment is required!");
      /* Release 3.1 - Ends */
    }
    /* Release 3.1 - Starts */
    for (const selectedRequest of selectedRequests) {
      if (
        selectedRequest.request.request_type == "internalhire" &&
        !selectedRequest.request.effective_date
      ) {
        validationErrors.push(
          "Requested Id " +
            selectedRequest.employee_request_id +
            " - Effective Date is required!"
        );
      }
    }
    if (validationErrors.length > 0) {
      setSubmitErrors(validationErrors);
      setIsSubmitting(false);
      return null;
    }
    /* Release 3.1 - Ends */

    const results = await Promise.all(
      selectedRequests.map(
        async ({ employee_request_id, approver_level, request }) => {
          try {
            if (
              request.override_manager_proposal ||
              request.override_promotion_increase ||
              (request.override_ti_harmonization &&
                request.override_ti_harmonization !== "Do not override")
            ) {
              await overrideRequest({
                variables: {
                  employee_request_id,
                  data: {
                    new_fte_annual_salary: request.new_fte_annual_salary,
                    new_fte_incentive_target_amount:
                      request.new_fte_incentive_target_amount,
                    new_incentive_target_percentage:
                      request.new_incentive_target_percentage,
                    new_itp_situation: request.new_itp_situation,
                    new_fte_ttc_annual_salary:
                      request.new_fte_ttc_annual_salary,
                    new_quartile: request.new_quartile,
                    override_manager_proposal:
                      request.override_manager_proposal,
                    override_fte_annual_salary:
                      request.override_fte_annual_salary,
                    override_fte_incentive_target_amount:
                      request.override_fte_incentive_target_amount,
                    override_promotion_increase:
                      request.override_promotion_increase,
                    override_ti_harmonization:
                      request.override_ti_harmonization,
                    /**Release 2 Our Talent BUdget Offcycle mapping - starts */
                    offcycle_budget_update:
                      request.request_type !== "internalhire"
                        ? request.offcycle_budget_update
                        : request.Final_Off_Cycle_Budget,
                    /**Release 2 Our Talent BUdget Offcycle mapping - ends */
                    override_fte_incentive_target_percentage:
                      request.override_fte_incentive_target_percentage,
                    override_fte_ttc_annual_salary:
                      request.override_fte_ttc_annual_salary,
                    /**Release 2 Our Talent BUdget Offcycle mapping - starts */
                    hiring_manager:
                      request.request_type !== "internalhire"
                        ? ""
                        : request.hiring_manager,
                    Final_Off_Cycle_Budget:
                      request.request_type !== "internalhire"
                        ? null
                        : request.Final_Off_Cycle_Budget,
                    /**Release 2 Our Talent BUdget Offcycle mapping - ends */
                  },
                },
              });
            }
            /* Release 3.1 - Starts */
            let effective_date = request.effective_date;
            let request_type = request.request_type;
            let payment_date = request.payment_date;
            if (request.effective_date && (request_type == "internalhire" ||  request_type == "compensation") ) {
              effective_date = formatDate(
                convertToUTC(new Date(request.effective_date))
              );
              payment_date = null;
            }
            if (request.payment_date && (request_type == "businessexcellenceaward" ||  request_type == "onetime") ) {
              payment_date = formatDate(
                convertToUTC(new Date(request.payment_date))
              );
              effective_date = null;
            }
            /* Release 3.1 - Ends */
            // R2 - HR Page (comments)
            await approveRequest({
              variables: {
                employee_request_id,
                approver_level,
                comment,
                /* Release 3.1 - Starts */
                effective_date,
                request_type,
                payment_date,
                /* Release 3.1 - Ends */
              },
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: approvals,
                  variables: {
                    // TODO - get this from PendingRequests.js state
                    where: {
                      approver_pers_no: { eq: account.pers_no },
                      approval_status: { eq: "active" },
                    },
                    orderBy: [{ column: "employee_request_id", order: "DESC" }],
                    limit: 20,
                    offset: 0,
                  },
                },
                {
                  query: organizationBEABudget,
                  variables: {
                    where: { year: { eq: new Date().getFullYear().toString() } },
                  },
                },
              ],
            });

            return { [employee_request_id]: false };
          } catch (e) {
            return { [employee_request_id]: e.message };
          }
        }
      )
    );

    const errors = results.filter((r) =>
      Object.keys(r).find((p_no) => r[p_no])
    );
    if (!errors.length) {
      hideModal();
      unselectAllRecord("requests");
      pushMessage({
        type: "success",
        message: "Request(s) successfully approved.",
      });
    } else {
      setSubmitErrors(errors.map((e) => Object.values(e).shift()));
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <FormHeading>Approve requests</FormHeading>
      <FormLabel>Selected requests: {selectedRequests.length}</FormLabel>
      <SelectedTable
        recordType="requests"
        items={selectedRequests}
        columns={columns.approveRequests}
        onChange={handleChange}
      />
      <FormLabel>Comment</FormLabel>
      <DescriptionInput
        name="comment"
        onChange={handleCommentChange}
        /* Release 3.1 - Starts */ value={comment} /* Release 3.1 - Ends */
      />
      {/* Release 3.1 - Starts */}
      {submitErrors.length > 0 && (
        <ErrorsBlock>
          <ul style={{ listStyleType: "none" }}>
            {submitErrors.map((err) => {
              return (
                <li key={Math.random()}>
                  <FontAwesomeIcon icon={faTimesCircle} style={{fontSize: '15px'}}/>
                  &nbsp;&nbsp;{err}
                </li>
              );
            })}
          </ul>
        </ErrorsBlock>
      )}
      {/* Release 3.1 - Ends */}
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
        <FormButton primary disabled={isSubmitting} onClick={handleApproveSend}>
          Approve
        </FormButton>
        {isSubmitting && (
          <LoaderWrap>
            <Ring color={"#001235"} size="2" sizeUnit="rem" />
          </LoaderWrap>
        )}
      </FormButtonsWrap>
      <CalculatorNew
        columns={columns.approveRequests}
        recordType="requests"
        item={updatedItem}
        data={updateData}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
  };
};

export default connect(mapStateToProps, { unselectAllRecord, pushMessage })(
  ApproveRequest
);
