import React from 'react';
import { percentRenderer } from '.';
import styled from 'styled-components/macro';

const itpColorMap = { 'Norm': 'green', 'Low': 'blue', 'High': 'red' };

export const itpRenderer = (current, value, data, account = {}, config = {}) => {
  const itp = current === 'current' ? data.current_itp_situation : data.new_itp_situation || 'Norm';

  return (
    <>
      {percentRenderer(value)} 
      <ItpWrap itp={itp}>{itp}</ItpWrap>
    </>
  );
};

const ItpWrap = styled.span`
  color: ${({ itp }) => itpColorMap[itp]};
  margin-left: 5px;
`;