import { numberFieldWithPlaceholderRenderer, currencySymbolRenderer, numberRenderer } from '../../renderers';

export default (table) => {
  
  return {
    type: "float",
    inputType: "number",
    name: "request.override_fte_annual_salary",
    label: "Override Annual Base Salary",
    section: "HR",
    visible: true,
    editable: (column, data) => data.request.request_type === 'compensation' || data.request.request_type === 'internalhire',//Adding this feature to internalhire
    renderer: numberFieldWithPlaceholderRenderer,
    before: (value, data, account, config) => currencySymbolRenderer(value, data.request, account, config),
    allow: ['hr1', 'hr2', 'hr3', 'bcb'],
    placeholder: (value, data, account, config) => numberRenderer(data.request.new_fte_annual_salary),
    width: '200px'
  };
};