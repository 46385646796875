import {
  UNSELECT_EMPLOYEE,
  SELECT_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UNSELECT_ALL_EMPLOYEES
} from '../actions/index';

export default (state = [], action) => {
  switch (action.type) {
    case SELECT_EMPLOYEE:
      return state.find(i => action.payload[action.indexColumn.name] === i[action.indexColumn.name]) ? state : state.concat([action.payload]);
    case UNSELECT_EMPLOYEE:
      return state.filter(item => action.payload[action.indexColumn.name] !== item[action.indexColumn.name]);
    case UNSELECT_ALL_EMPLOYEES:
      return [];
    case UPDATE_EMPLOYEE:
      return state.map((item, index) => {
        if (item[action.indexColumn.name] === action.id) {
          return {...item, ...action.data};
        }
        return item;
      });
    default:
      return state;
  }
};
