export default (table) => {
  let name = 'organization_unit_name';
  
  switch (table) {
    case 'editCompensation':
    case 'editOnetime':
    case 'rejectRequests':
    case 'approveRequests':
      case 'editInternalHire':
      name = 'request.for.organization_unit_name';
      break;
    case 'myRequests':
    case 'requestsHistory':
    case 'pendingRequests':
      name = 'for.organization_unit_name';
      break;
    default:
      break;
  }

  return { 
    name,
    label: "Org Unit",
    tooltip_section: "Current",
    visible_in_tooltip: /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
    (data.request_type === "businessexcellenceaward" || (data.request && data.request.request_type === "businessexcellenceaward")) ? false : true,
    /* Release 3.2 - 1.1 - Ends */
  };
};