/* Release 3.2 - 8.1 - Starts */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FormHeading,
  FormButtonsWrap,
  FormButton,
  Table,
  TableActionsWrap,
  TableAction,
  TableHeadingWrap,
} from "../";
import { columns } from "../../util/config";
import { unselectAllRecord, pushMessage } from "../../actions";

const OtherData = (props) => {
  const [queryFilter, setTableFilter] = useState({});
  const [queryFilterlatest, setqueryFilterlatest] = useState({});
  const [tableName, setTableName] = useState("Overview of Actions (IT0000)");
  const [tableQuery, setTableQuery] = useState("actionsIT0000List");
  const [tableColumns, setTableColumns] = useState(columns.actionsIT0000);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const { selectedEmployees, hideModal, unselectAllRecord } = props;

  const orderBy = {};

  useEffect(() => {
    if (selectedEmployees && selectedEmployees.length > 0) {
      setEmployeeDetails(selectedEmployees[0]);
      setTableFilter({ pers_no: { eq: selectedEmployees[0].pers_no }, Action_type_name: {ne: "No Action"}  });
      setqueryFilterlatest({ pers_no: { eq: selectedEmployees[0].pers_no }})
    }
  }, []);

  const handleQuit = () => {
    hideModal();
    unselectAllRecord("employees");
  };

  return (
    <div>
      <FormHeading>Other Information</FormHeading>
      <TableHeadingWrap
        style={{  marginBlockEnd: "1em" }}
      >
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b>Nokia ID :</b>
          </label>
          {employeeDetails && employeeDetails.nokia_id
            ? employeeDetails.nokia_id
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> Name : </b>
          </label>
          {employeeDetails && employeeDetails.full_name
            ? employeeDetails.full_name
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> UPI ID : </b>
          </label>
          {employeeDetails && employeeDetails.upi_id
            ? employeeDetails.upi_id
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> Requisition ID : </b>
          </label>
          {employeeDetails && employeeDetails.requisition_id
            ? employeeDetails.requisition_id
            : ""}
        </div>
      </TableHeadingWrap>
      <FormHeading>Latest Information</FormHeading>
      <Table
          recordType="latestInformation"
          columns={columns.latestInformation}
          query="latestInformationList"
          where={queryFilterlatest}
          order={orderBy}
          checkboxIsDisplayed={false}
          enablePagination={false}
        />
      <FormHeading>Historical Information</FormHeading>
      <FormButtonsWrap>
        <FormButton
          primary= {tableQuery == "actionsIT0000List"}
          onClick={(e) =>{
            setTableName("Overview of Actions (IT0000)");
            setTableFilter({ pers_no: { eq: selectedEmployees[0].pers_no }, Action_type_name: {ne: "No Action"}  });
            setTableQuery("actionsIT0000List");
            setTableColumns(columns.actionsIT0000);
          }
        }
        >
         Overview of Actions (IT0000) 
         
        </FormButton>
        <FormButton
          primary= {tableQuery == "orgAssignmentIT0001List"}
          onClick={(e) => {
            setTableName("Overview Organizational Assignment (IT 0001) ");
            setTableFilter({ pers_no: { eq: selectedEmployees[0].pers_no }});
            setTableQuery("orgAssignmentIT0001List");
            setTableColumns(columns.orgAssignmentIT0001);
          }}
        >
          Overview Organizational Assignment (IT 0001) 
        </FormButton>
        {/*start the demand */}
        <FormButton
          primary= {tableQuery == "referralAwardList"}
          onClick={(e) => {
            setTableName("Referral Award");
            setTableFilter({ pers_no: { eq: selectedEmployees[0].pers_no },Addl_Pay_Wage_Type:'2130'});
            setTableQuery("referralAwardList");
            setTableColumns(columns.referralAward);
          }}
        >
         Referral Award
        </FormButton>
          {/*End the demand */}
      </FormButtonsWrap>
      {employeeDetails && queryFilter.pers_no && (
        <React.Fragment>
          <FormHeading> {tableName} </FormHeading>
          <Table
            recordType="employeeCompensationDashboard"
            columns={tableColumns}
            query={tableQuery}
            where={queryFilter}
            order={orderBy}
            checkboxIsDisplayed={false}
          />
        </React.Fragment>
      )}
           <React.Fragment></React.Fragment>
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
      </FormButtonsWrap>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
  };
};

export default connect(mapStateToProps, { unselectAllRecord, pushMessage })(
  OtherData
);
/* Release 3.2 - 8.1 - Ends */
