/* Release 3.2 - 1.1 - Starts */
export default (table) => {
  return {
    type: "string",
    name: 'merge_full_name',
    label: "Name",
    section: "Current Information",
    visible: true,
    sortable: false,
    width: "35%",
  };
};
/* Release 3.2 - 1.1 - Ends */