/*  Release 3.2 - 1.1 - Starts */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  unselectAllRecord,
  pushMessage,
  toggleShowInEur,
} from "../../../actions";
import {
  Table,
  TableHeadingWrap,
  TableActionsWrap,
  TableAction,
} from "../../../components";
import { columns } from "../../../util/config";

const BusinessOwnerBudget = (props) => {
  const {
    config,
    account,
    selectedBusinessOwnerBudgets,
    unselectAllRecord,
    budget_id,
    toggleShowInEur,
    refreshKey,
    handleRequestEditBudget,
    handleRequestDeleteBudget,
    clearForm,
  } = props;
  const { forceCurrency, roles, accessibles } = account;
  const isBCB = roles.includes("bcb");
  
  const orderBy = { bo_budget_id: "DESC" };
  const queryFilter = 
  isBCB ? {
    organization_unit: { in: accessibles.budgetOrgs },
    budget_id: {
      eq: budget_id ? budget_id : "",
    },
  } : 
  {
    budget_id: {
      eq: budget_id ? budget_id : "",
    },
  };
  const hasSelectedBusinessOwnerBudgets =
  selectedBusinessOwnerBudgets.length > 0 && selectedBusinessOwnerBudgets.length == 1;

  useEffect(() => {
     unselectAllRecord("businessOwnerBudgets");
  }, [unselectAllRecord, refreshKey]);

  useEffect(() => {
     if (!hasSelectedBusinessOwnerBudgets) {
      clearForm();
     }
  }, [hasSelectedBusinessOwnerBudgets]);

  const rowStyle = {
    width: "100%",
    display: "flex",
  };
  const columnFullStyle = {
    fontSize: "15px",
    fontWeight: "400",
    width: "100%",
    float: "left",
    margin: "10px",
  };

  const toggleDisplayCurrency = (event) => {
    toggleShowInEur(forceCurrency ? null : "EUR");
  };
  const handleRequestEditBudgetData = (event) => {
    handleRequestEditBudget(event, selectedBusinessOwnerBudgets);
  }
  const handleRequestDeleteBudgetData = (event) => {
    handleRequestDeleteBudget(event, selectedBusinessOwnerBudgets);
  }

  const handleCancel = (event) => {
    unselectAllRecord("businessOwnerBudgets");
    clearForm();
  }

  return (
    <>
      <div style={rowStyle}>
        <div style={columnFullStyle}>
          <TableHeadingWrap>
            <TableActionsWrap>
              <TableAction onClick={toggleDisplayCurrency}>
                {`Switch to ${
                  forceCurrency === "EUR" ? "local currency" : "EUR"
                }`}
              </TableAction>
              {
                hasSelectedBusinessOwnerBudgets  && 
                <>
                  <TableAction onClick={handleRequestEditBudgetData}>
                    Edit Budget
                  </TableAction>
                  <TableAction onClick={handleRequestDeleteBudgetData}>
                    Delete Budget
                  </TableAction>
                </>
              }
              {
                hasSelectedBusinessOwnerBudgets  && 
                  <TableAction
                    onClick={handleCancel}
                  >
                    Clear
                  </TableAction>
              }
            </TableActionsWrap>
          </TableHeadingWrap>
        </div>
      </div>
      <div style={rowStyle}>
        <div className="overflow_table" style={columnFullStyle}>
          <Table
            recordType="businessOwnerBudgets"
            columns={columns.beabusinessownerbudgetmanage}
            query="organizationBEABusinessOwnerBudget"
            where={queryFilter}
            order={orderBy}
            isEuroSort={forceCurrency === "EUR" ? true : false}
            config={config}
            account={account}
            checkboxIsDisplayed={true}
            key={refreshKey}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedBusinessOwnerBudgets: state.selectedBusinessOwnerBudgets,
    account: state.account,
    config: state.config,
  };
};

export default connect(mapStateToProps, {
  unselectAllRecord,
  pushMessage,
  toggleShowInEur,
})(BusinessOwnerBudget);
/*  Release 3.2 - 1.1 - Ends */
