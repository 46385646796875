import styled from 'styled-components/macro';

const AppContainer = styled.div`

`;
const ProxyHeader = styled.div`
padding: 0 2rem;
background-color: #ffffff;
position: relative;
z-index: 10;
display: flex;
justify-content: space-between;
justify-content: center;
color: orangered;
`;
const ProxySpan = styled.span`
font-size: 20px;
`;
const HeaderWrap = styled.header`
  padding: 0 2rem;
  background-color: #ffffff;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  height: 100px;
`;

const HeaderLeftWrap = styled.div`
  display: flex;
  align-items: center;
`;

const FooterLink = styled.a`
  text-decoration: underline;
`;
/*DEM0068681 - Change NOKIA logo Starts  */
const FooterWrap = styled.footer`
  padding: 4rem 2rem;
  background: #005AFF;
  color: #ffffff;
`;
/*DEM0068681 - Change NOKIA logo Ends  */
const FooterLogoWrap = styled.div`
  margin-bottom: 40px;
`;

const FooterLegal = styled.div`
  opacity: 0.5;
`;

const BodyWrap = styled.div`
  padding: 0 2rem 2rem;
  background-color: ${({ theme }) => theme.primaryBgColor};
`;

export {
  AppContainer,
  HeaderWrap,
  HeaderLeftWrap,
  FooterWrap,
  FooterLogoWrap,
  FooterLegal,
  BodyWrap,
  FooterLink,
  ProxyHeader,
  ProxySpan
};
