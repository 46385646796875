/* eslint-disable no-self-compare */
import { useMemo, useEffect, useCallback, useState } from 'react';
//import { useLazyQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { updateRecord } from '../../actions';
import { capitalize, filterJobCatalog, filterPaymentTypes } from '../../util';
const { tokenize } = require('excel-formula-tokenizer');

var FormulaParser = require('hot-formula-parser').Parser;
var sform = require('hot-formula-parser').SUPPORTED_FORMULAS;
console.log(sform);
var parser = new FormulaParser();

const BUDGET_MULTIPLIER = 3.5;
const CURRENT_TIMESTAMP = Date.now();


const round = (v, d) => Number(Math.round(v + 'e' + d) + 'e-' + d);
const isNaN = (maybeNaN) => maybeNaN !== maybeNaN;

//excel calculation values
const CalculatorNew = props => {
  const { columns, recordType, item, data, updateRecord, config, account, formulas } = props;
  console.log('config', config);
  let curDate = new Date();
  //const CURRENT_EXCEL_TIMESTAMP = `${curDate.getFullYear()}-${curDate.getMonth() + 1}-${curDate.getDay()}`;

  /* SLEEP FOR 1 seconds  */
  const sleep = ms => new Promise(r => setTimeout(r, ms));
  const callForSleep = async function(a) {
    console.log("Call from ", a);
    console.log("Before Sleeping===>", Math.floor(Date.now() / 1000));
    await sleep('1000');
    console.log("After Sleeping===>", Math.floor(Date.now() / 1000));
  }
  //callForSleep('open');
  const indexColumn = columns.find(c => c.index);

  const flattenRequestData = data => {
    return {
      ...data.request.for, ...{
        employee_request_id: data.employee_request_id,
        request_type: data.request.request_type,
        currency: data.request.currency,
        ttc_increase_amount: data.request.ttc_increase_amount,
        ttc_increase_percentage: data.request.ttc_increase_percentage,
        payment_type: selectedItem.request.payment_type,
        payment_reason: selectedItem.request.payment_reason,
        is_gpp_eligible: data.request.is_gpp_eligible,
        gpp_increase_amount: data.request.gpp_increase_amount,
        gpp_increase_percentage: data.request.gpp_increase_percentage,
        promoted: data.request.promoted,
        promotion_job_family_name: data.request.promotion_job_family_name,
        promotion_job_subfamily_name: data.request.promotion_job_subfamily_name,
        promotion_job_name: data.request.promotion_job_name,
        promotion_pay_grade: data.request.promotion_pay_grade,
        promotion_job_grade: data.request.promotion_job_grade,
        promotion_job_grade_delta: data.request.promotion_job_grade_delta,
        promotion_incentive_target_percentage: data.request.promotion_incentive_target_percentage,
        demoted: data.request.demoted,
        mandatory_ttc_increase_percentage: data.request.mandatory_ttc_increase_percentage,
        mandatory_ttc_increase_amount: data.request.mandatory_ttc_increase_amount,
        apply_ti_harmonization: data.request.apply_ti_harmonization,
        interim_job_family_name: data.request.interim_job_family_name,
        interim_job_grade: data.request.interim_job_grade,
        interim_fte_incentive_target_amount: data.request.interim_fte_incentive_target_amount,
        interim_incentive_target_percentage: data.request.interim_incentive_target_percentage,
        interim_total_increase_amount: data.request.interim_total_increase_amount,
        interim_increase_amount_for_salincr: data.request.interim_increase_amount_for_salincr,
        interim_increase_amount_for_tih: data.request.interim_increase_amount_for_tih,
        step_1_abs: data.request.step_1_abs,
        step_1_ita: data.request.step_1_ita,
        step_1_itp: data.request.step_1_itp,
        interim_theo_itp: data.request.interim_theo_itp,
        interim_itp_situation: data.request.interim_itp_situation,
        step_2_abs_gap: data.request.step_2_abs_gap,
        step_2_ita_gap: data.request.step_2_ita_gap,
        interim_remainder_for_split: data.request.interim_remainder_for_split,
        new_fte_annual_salary: data.request.new_fte_annual_salary,
        new_fte_incentive_target_amount: data.request.new_fte_incentive_target_amount,
        new_incentive_target_percentage: data.request.new_incentive_target_percentage,
        new_fte_ttc_annual_salary: data.request.new_fte_ttc_annual_salary,
        new_itp_situation: data.request.new_itp_situation,
        new_quartile: data.request.new_quartile,
        override_manager_proposal: data.request.override_manager_proposal,
        override_fte_annual_salary: data.request.override_fte_annual_salary,
        override_fte_incentive_target_amount: data.request.override_fte_incentive_target_amount,
        override_promotion_increase: data.request.override_promotion_increase,
        override_ti_harmonization: data.request.override_ti_harmonization,
        override_fte_incentive_target_percentage: data.request.override_fte_incentive_target_percentage,
        override_fte_ttc_annual_salary: data.request.override_fte_ttc_annual_salary,
        new_position_title: data.request.new_position_title,
        interim_pay_grade: data.request.interim_pay_grade,
        interim_salary_range_min: data.request.interim_salary_range_min,
        interim_salary_range_mid: data.request.interim_salary_range_mid,
        interim_salary_range_max: data.request.interim_salary_range_max,
        new_ttc_compa_ratio: data.request.new_ttc_compa_ratio,
        interim_salary_range_spread: data.request.interim_salary_range_spread,
        ttc_increase: data.request.ttc_increase,
        ttc_increase_perc: data.request.ttc_increase_perc,
        /**Release 2 Our talent data */
        /**Release 2 our talent */
      current_combo_situation: data.request.current_combo_situation,
      promo_increase_pct_guideline_min: data.request.promo_increase_pct_guideline_min,
      promo_increase_pct_guideline_max: data.request.promo_increase_pct_guideline_max,
      promotional_increase_percentage_guideline: data.request.promotional_increase_percentage_guideline,
      rr_number: data.request.rr_number,
      case_3_12_month_actual_incentive_amount_fte: data.request.case_3_12_month_actual_incentive_amount_fte,
      override_transition_allowance_local_currency: data.request.override_transition_allowance_local_currency,
     // new_incentive_scheme,
      internalhire_off_cycle_spending: data.request.internalhire_off_cycle_spending,
      internal_hire_off_cycle_spending_euro: data.request.internal_hire_off_cycle_spending_euro,
    
      is_sip: data.request.is_sip,
      Case_One_Max_Budget_calc: data.request.Case_One_Max_Budget_calc,
      Case_one_TTC_increase_minus_Max_budget: data.request.Case_one_TTC_increase_minus_Max_budget,
      Case_one_Budget_charge: data.request.Case_one_Budget_charge,
      Case_two_TI_Increase: data.request.Case_two_TI_Increase,
      Case_two_Promotion_Budget: data.request.Case_two_Promotion_Budget,
      Case_two_Total_budget: data.request.Case_two_Total_budget,
      Case_two_Budget_charge: data.request.Case_two_Budget_charge,
      Case_three_twelve_month_Total_Target_Cash: data.request.Case_three_twelve_month_Total_Target_Cash,
      Case_three_new_TTC_Gap_to_twelve_month_TTC: data.request.Case_three_new_TTC_Gap_to_twelve_month_TTC,
      Case_three_Max_capped_transit_allowance: data.request.Case_three_Max_capped_transit_allowance,
      Case_three_Transition_allowance_total: data.request.Case_three_Transition_allowance_total,
      Case_three_Budget_charge: data.request.Case_three_Budget_charge,
      sip_case: data.request.sip_case,
      Final_Off_Cycle_Budget: data.request.Final_Off_Cycle_Budget,
      Max_Promotional_Increase : data.request.promoted ?  data.request.promo_increase_pct_guideline_max:0,
      sip_change: data.request.sip_change ? data.request.sip_change : data.request.is_sip,
      internalhire_increase_amount: data.request.internalhire_increase_amount,
      internalhire_increase_percentage: data.request.internalhire_increase_percentage,
      hiring_manager: data.request.hiring_manager,
      /* Release 3.2 - 13.1 - Starts */      
      sum_of_pay_amount: 
      (
        data.case_3_12_month_actual_incentive_amount_fte ?
        data.case_3_12_month_actual_incentive_amount_fte :
        data.request.case_3_12_month_actual_incentive_amount_fte ? 
        data.request.case_3_12_month_actual_incentive_amount_fte : 
        0
      )
      + 
      data.request.case_3_12_month_actual_incentive_amount_fte + 
      (
        data.lastYearWageHistory && data.lastYearWageHistory.addl_pay_amount ?
        data.lastYearWageHistory.addl_pay_amount :
        data.request.lastYearWageHistory && data.request.lastYearWageHistory.addl_pay_amount ? 
        data.request.lastYearWageHistory.addl_pay_amount : 
        0
      ),
      lastYearWageHistory: data.lastYearWageHistory ? data.lastYearWageHistory  : data.request.lastYearWageHistory ? data.request.lastYearWageHistory : null
      /* Release 3.2 - 13.1 - Ends */
      }
    };
  }

  let selectedItem = props[`selected${capitalize(recordType)}`].find(e => e[indexColumn.name] === item);
  console.log('selectedItem 123', selectedItem);
  if (recordType === 'requests' && selectedItem) {
    selectedItem = flattenRequestData(selectedItem);
    console.log('selectedItem',selectedItem)
    //defineFormulaVars();
  }
  const hasNoDecimals = useMemo(
    () => {
      if(selectedItem){
        const { noDecimalCurrencies = [] } = config;
        let hd = noDecimalCurrencies.find(c => c === selectedItem.currency);
        return hd?hd:false;  
      }else{
        return false;
      }      
    });

    
    /*
    R2 - Admin Calculations
    defineFormulaVars() - Declare variables/functions needed for parser
    */
    const defineFormulaVars = () =>
    {
      callForSleep('defineFormulaVars');
        const { itpLookupTable, gppLookupTable, exchangeRates, jobGrades, countryLookupTable,increaseLookupTable } = config;
        
        /* all formula fields and functions declaration - Fields starts*/
        parser.setVariable('dateOfJoining', selectedItem.pers_start_date!==null?Date.parse(selectedItem.pers_start_date):selectedItem.pers_start_date);
        parser.setVariable('country', selectedItem.country_name);
        parser.setVariable('currentCountryCode', selectedItem.country);
        parser.setVariable('curSalary', selectedItem.fte_ttc_annual_salary);
        parser.setVariable('currentFteAbs', selectedItem.fte_annual_salary);
        parser.setVariable('currentFteIta', selectedItem.fte_incentive_target_amount);
        parser.setVariable('additionalFteAnnualPayment', selectedItem.fte_addl_ttc_amount==null?0:selectedItem.fte_addl_ttc_amount);
        parser.setVariable('currentJobGrade', selectedItem.job_grade);
        parser.setVariable('jobFamily', selectedItem.job_family_name);
        parser.setVariable('payScaleArea', selectedItem.pay_scale_area);
        parser.setVariable('overrideFteAbs', selectedItem.override_fte_annual_salary);
        parser.setVariable('overrideFteIta', selectedItem.override_fte_incentive_target_amount);
        parser.setVariable('overrideMandatoryIncrease', selectedItem.override_promotion_increase);
        parser.setVariable('overrideTiHarmonization', (selectedItem.override_ti_harmonization===undefined) ? 
         'Do not override' : selectedItem.override_ti_harmonization);
        parser.setVariable('newJobGrade', selectedItem.promotion_job_grade);
        parser.setVariable('currentItp', selectedItem.fte_incentive_target_percentage);
        parser.setVariable('currentQuartile', selectedItem.ttc_employee_quartile);
        parser.setVariable('localCurrencyCode', selectedItem.currency);
        parser.setVariable('overrideManagerProposal', selectedItem.override_manager_proposal===undefined?false:selectedItem.override_manager_proposal);
        parser.setVariable('currency',selectedItem.salary_range_min);
        parser.setVariable('currentSrMid',selectedItem.salary_range_mid);
        parser.setVariable('currentSrMax',selectedItem.salary_range_max);
        parser.setVariable('currentPayGrade',selectedItem.pay_grade);    
        parser.setVariable('TTCIncreasePercentage',(selectedItem.ttc_increase_percentage==''||selectedItem.ttc_increase_percentage===null)?0:selectedItem.ttc_increase_percentage);
        parser.setVariable('TTCIncrease',selectedItem.ttc_increase);
        parser.setVariable('TTCIncreasePerc',selectedItem.ttc_increase_perc);
             
        
        //set variables from Functions start
        parser.setVariable('finalJobFamily', selectedItem.promotion_job_family_name);
        parser.setVariable('currentSrRangeSpread', selectedItem.salary_range_spread);
        parser.setVariable('tenure', selectedItem.tenure===undefined?0:selectedItem.tenure);
        parser.setVariable('merit', selectedItem.ttc_increase_amount);
        parser.setVariable('ttc_increase_pct', selectedItem.ttc_increase_percentage);
        parser.setVariable('lumpSumTwo', selectedItem.gpp_increase_amount);
        parser.setVariable('gppGuidelineMax', selectedItem.gpp_guideline_max===undefined?0.00:selectedItem.gpp_guideline_max);
        parser.setVariable('newPayGrade', selectedItem.promotion_pay_grade);
        parser.setVariable('newJobGrade', selectedItem.promotion_job_grade);
        parser.setVariable('promoted', selectedItem.promoted);
        parser.setVariable('newTheoItp', selectedItem.promotion_incentive_target_percentage);
        parser.setVariable('mandatoryTtcIncreasePercentageDueToPromotion', selectedItem.mandatory_ttc_increase_percentage);
        parser.setVariable('mandatoryTtcIncreaseDueToPromotion', selectedItem.mandatory_ttc_increase_amount);
        parser.setVariable('applyTiHarmonization', selectedItem.apply_ti_harmonization===undefined?false:selectedItem.apply_ti_harmonization);
        parser.setVariable('harmonizationType', selectedItem.interim_harmonization_type);
        parser.setVariable('interimJobFamily', selectedItem.interim_job_family_name);
        parser.setVariable('interimJobGrade', selectedItem.interim_job_grade);
        parser.setVariable('newSrMin', selectedItem.interim_salary_range_min);
        parser.setVariable('newSrMid', selectedItem.interim_salary_range_mid);
        parser.setVariable('newSrMax', selectedItem.interim_salary_range_max);
        parser.setVariable('newSrRangeSpread', selectedItem.interim_salary_range_spread);
        parser.setVariable('interimFteIta', selectedItem.interim_fte_incentive_target_amount);
        parser.setVariable('interimFteItp', selectedItem.interim_incentive_target_percentage);
        parser.setVariable('totalIncreaseAmount', (selectedItem.interim_total_increase_amount==''||selectedItem.interim_total_increase_amount==null)?0:selectedItem.interim_total_increase_amount);
        parser.setVariable('increaseAmountForSalIncr', selectedItem.interim_increase_amount_for_salincr);
        parser.setVariable('increaseAmountForTih', selectedItem.interim_increase_amount_for_tih);
        parser.setVariable('stepOneAbs', selectedItem.step_1_abs);
        parser.setVariable('stepOneIta', selectedItem.step_1_ita);
        parser.setVariable('stepOneItp', selectedItem.step_1_itp);
        parser.setVariable('interimTheoItp', selectedItem.interim_theo_itp);
        parser.setVariable('interimItpSituation', selectedItem.interim_itp_situation);
        parser.setVariable('stepTwoAbsGap', selectedItem.step_2_abs_gap);
        parser.setVariable('stepTwoItaGap', selectedItem.step_2_ita_gap);
        parser.setVariable('remainderForSplit', selectedItem.interim_remainder_for_split===undefined?0:selectedItem.interim_remainder_for_split);
        parser.setVariable('newAbs', selectedItem.new_fte_annual_salary);
        parser.setVariable('newIta', selectedItem.new_fte_incentive_target_amount);
        parser.setVariable('newItp', selectedItem.new_incentive_target_percentage);
        parser.setVariable('newTtc', selectedItem.new_fte_ttc_annual_salary);
        parser.setVariable('newItpSituation', selectedItem.new_itp_situation);
        parser.setVariable('ttcCheck', selectedItem.new_ttc_check);
        parser.setVariable('newCR', selectedItem.new_ttc_compa_ratio);
        parser.setVariable('individualBudgetAmount', selectedItem.individual_budget_amount);
        parser.setVariable('individualSpendingAmount', selectedItem.individual_spending_amount);
        parser.setVariable('individualBalanceAmount', selectedItem.individual_balance_amount);
        parser.setVariable('gppBudget', selectedItem.gpp_budget);
        parser.setVariable('gppSpending', selectedItem.gpp_spending===undefined?0:selectedItem.gpp_spending);
        parser.setVariable('gppBalance', selectedItem.gpp_balance);
        parser.setVariable('overallTotalSpending', selectedItem.total_spending);
        parser.setVariable('overrideItp', selectedItem.override_fte_incentive_target_percentage);
        parser.setVariable('overrideFteTtc', selectedItem.override_fte_ttc_annual_salary);
        parser.setVariable('currentCR', selectedItem.ttc_compa_ratio);  
        parser.setVariable('exchangeRate', selectedItem.exchange_rate);
        

        //newly added excel fields updated
        parser.setVariable('isGPPEligible', selectedItem.is_gpp_eligible);
        parser.setVariable('demoted', selectedItem.demoted);
        parser.setVariable('incentivePlan', selectedItem.incentive_plan_type);
        parser.setVariable('targetBudgetOrg', selectedItem.target_budget_org);
        parser.setVariable('orgUnitFour', selectedItem.organization_unit_4);
        parser.setVariable('orgUnitThree', selectedItem.organization_unit_3);
        parser.setVariable('orgUnitTwo', selectedItem.organization_unit_2);
        parser.setVariable('orgUnitOne', selectedItem.organization_unit_1);
        parser.setVariable('lumpSumTwoPerc', selectedItem.gpp_increase_percentage);
        parser.setVariable('promoJobGradeDelta', selectedItem.promotion_job_grade_delta);
        parser.setVariable('newIncentivePlanType', selectedItem.new_incentive_plan_type);
        parser.setVariable('offCycleBudgetUpdate', selectedItem.offcycle_budget_update);
        parser.setVariable('InterimPayGrade', selectedItem.interim_pay_grade);
        parser.setVariable('hasNoDecimals', hasNoDecimals);

        //formula helper fields
        parser.setVariable('budgetMultiplier', BUDGET_MULTIPLIER);
        parser.setVariable('CURRENT_TIMESTAMP', CURRENT_TIMESTAMP);

        parser.setVariable('true', true);
        parser.setVariable('false', false);
        /* Release 3.2 - 12.2 - Starts */
        if (selectedItem.request_type != "onetime") {
          parser.setVariable('null', null);
        } 
        /* Release 3.2 - 12.2 - Ends */
        parser.setVariable('undefined', undefined);
        parser.setVariable('Infinity', Infinity);
        
        //Adding other fields to be accessed in the dynamic formula
        parser.setVariable('persNo', selectedItem.pers_no);
        parser.setVariable('firstName', selectedItem.first_name);
        parser.setVariable('lastName', selectedItem.last_name);
        parser.setVariable('fullName', selectedItem.full_name);
        parser.setVariable('nokiaID', selectedItem.nokia_id);
        parser.setVariable('employmentStatus', selectedItem.employment_status);
        parser.setVariable('workEmail', selectedItem.email_work);
        parser.setVariable('isLineManager', selectedItem.is_line_manager);
        parser.setVariable('lineManagerPersNo', selectedItem.line_manager_pers_no);
        parser.setVariable('jobID', selectedItem.job_id);
        parser.setVariable('jobLevel', selectedItem.job_level);
        parser.setVariable('jobName', selectedItem.job_name);
        parser.setVariable('jobProfileID', selectedItem.job_profile_id);
        parser.setVariable('jobSubFamilyName', selectedItem.job_subfamily_name);
        parser.setVariable('positionTitle', selectedItem.position_title);
        parser.setVariable('orgUnit', selectedItem.organization_unit);
        parser.setVariable('orgUnitName', selectedItem.organization_unit_name);
        parser.setVariable('orgUnitObbrev', selectedItem.organization_unit_obbrev);
        parser.setVariable('orgUnitOne', selectedItem.organization_unit_1);
        parser.setVariable('orgUnitOneName', selectedItem.organization_unit_1_name);
        parser.setVariable('orgUnitTwo', selectedItem.organization_unit_2);
        parser.setVariable('orgUnitTwoName', selectedItem.organization_unit_2_name);
        parser.setVariable('orgUnitThree', selectedItem.organization_unit_3);
        parser.setVariable('orgUnitThreeName', selectedItem.organization_unit_3_name);
        parser.setVariable('orgUnitFour', selectedItem.organization_unit_4);
        parser.setVariable('orgUnitFourName', selectedItem.organization_unit_4_name);
        parser.setVariable('requestType', data.request_type);
        parser.setVariable('paymentType', data.request ? data.request.payment_type : data.payment_type);
        parser.setVariable('paymentReason', selectedItem.payment_reason);
        parser.setVariable('promotionJobSubfamilyName', selectedItem.promotion_job_subfamily_name);
        parser.setVariable('promotionJobName', selectedItem.promotion_job_name);
        parser.setVariable('newPositionTitle', selectedItem.new_position_title);

        //Our Talent fields start
        parser.setVariable('currentComboSituation', selectedItem.current_combo_situation);
        parser.setVariable('promoIncreasePctGuidelineMin', selectedItem.promo_increase_pct_guideline_min);
        parser.setVariable('promoIncreasePctGuidelineMax', selectedItem.promo_increase_pct_guideline_max);
        parser.setVariable('promoIncreasePctGuideline', selectedItem.promotional_increase_percentage_guideline);
        //parser.setVariable('newIncentiveScheme', selectedItem.new_incentive_scheme);
        parser.setVariable('offCycleSpending', selectedItem.internalhire_off_cycle_spending);
        parser.setVariable('offCycleSpendingInEUR', selectedItem.internal_hire_off_cycle_spending_euro);
        parser.setVariable('sipCase', selectedItem.sip_case);
        parser.setVariable('CaseOneMaxBudgetcalc', selectedItem.Case_One_Max_Budget_calc);
        parser.setVariable('CaseOneTTCIncreaseMinusMaxBudget', selectedItem.Case_one_TTC_increase_minus_Max_budget);
        parser.setVariable('caseOneBudgetCharge', selectedItem.Case_one_Budget_charge);
        parser.setVariable('caseTwoTIIncrease', selectedItem.Case_two_TI_Increase);
        parser.setVariable('caseTwoPromotionBudget', selectedItem.Case_two_Promotion_Budget);
        parser.setVariable('caseTwoTotalBudget', selectedItem.Case_two_Total_budget);
        parser.setVariable('caseTwoBudgetCharge', selectedItem.Case_two_Budget_charge);
        parser.setVariable('caseThreeTwelveMonthActualIncentiveAmountPaid', selectedItem.case_3_12_month_actual_incentive_amount_fte);
        parser.setVariable('OverrideTransitionAllowanceLocalCurrency', selectedItem.override_transition_allowance_local_currency);
        parser.setVariable('caseThreeTwelveMonthTotalTargetCash', selectedItem.Case_three_twelve_month_Total_Target_Cash);
        parser.setVariable('caseThreeNewTTCGapToTwelveMonthTTC', selectedItem.Case_three_new_TTC_Gap_to_twelve_month_TTC);
        parser.setVariable('caseThreeMaxCappedTransitAllowance', selectedItem.Case_three_Max_capped_transit_allowance);
        parser.setVariable('caseThreeTransitionAllowanceTotal', selectedItem.Case_three_Transition_allowance_total);
        parser.setVariable('caseThreeBudgetCharge', selectedItem.Case_three_Budget_charge);
        parser.setVariable('finalOffCycleBudget', selectedItem.Final_Off_Cycle_Budget);
        parser.setVariable('finalOffCycleBudgetEUR', selectedItem.Final_Off_Cycle_Budget_euro);
        parser.setVariable('maxPromoIncrease', selectedItem.Max_Promotional_Increase);
        parser.setVariable('rrNumber',selectedItem.rr_number);
        parser.setVariable('isSip',selectedItem.is_sip);
        parser.setVariable('sipChange',selectedItem.sip_change);
        parser.setVariable('internalHireIncreaseAmt',selectedItem.internalhire_increase_amount);
        parser.setVariable('internalHireIncreasePerc',selectedItem.internalhire_increase_percentage);
        parser.setVariable('hiringManager',selectedItem.hiring_manager);
        
        //Our Talent fields end

        //Custom Functions implementation start
        /* 
        R2 Release - Admin calculations
        To get ITP value from theoretical_itps_tbl based on jobFamily, jobGrade and country
        jobFamily = Pass * for sales and pre-sales for others pass actual job family
        jobGrade = Pass * for sales and pre-sales for others pass actual job grade
        country = Pass * for sales and pre-sales and if country is not USA or Canada 
        */
        parser.setFunction('getITPFromJFAndJGAndCountry', function(params) {
          console.log('params',params);
          let jobFamily = params[0];
          let jobGrade = params[1];
          let country = params[2];
          let match = itpLookupTable.find(obj => obj.job_family === jobFamily && obj.country === country && obj.job_grade === jobGrade);
          return match ? match.itp : 0;
        });
        
        /* 
        R2 Release - Admin calculations
        To get New Salary Range Min,New Salary Range Mid and New Salary Range Max by country, pay scale area, Pay Grade and currency code
        country = Country Code
        payScaleArea = Pay scale Area
        payGrade = Pay Grade
        currency = currency code
        */
       parser.setFunction('getSalaryRange', function(params) {
        console.log('params',params);
        let currency = params[3];
        let payGrade = params[2];
        let payScaleArea = params[1];
        let country = params[0];
        const salaryRanges = config.allSalaryRanges
        console.log('salaryRanges',salaryRanges)
        let salaryRangeFound;
        for (const salaryRange of salaryRanges) {
          // eslint-disable-next-line
          if (salaryRange.country_name == country && salaryRange.grade_region == payScaleArea && salaryRange.pay_grade == payGrade && salaryRange.currency_code == currency) {
            salaryRangeFound = salaryRange
            break
          }
        }
        let result = 0;
        switch(params[4]){
          case 'newSrMin':
            result = salaryRangeFound?salaryRangeFound.ttc_salary_range_min:0;
            break;
          case 'newSrMid':
            result = salaryRangeFound?salaryRangeFound.ttc_salary_range_mid:0;
            break;
          case 'newSrMax':
            result = salaryRangeFound?salaryRangeFound.ttc_salary_range_max:0;
            break;
        }
        return result; 
      });
      /*
      *To get gpp_guideline_max value from GPP Lookup Table
      quartile = Quartile
      countryCode = country Code
      */
      parser.setFunction('getGPPLookupTableByQuartileAndCountry', function(params) {
        console.log('params',params);
        let quartile = params[0];
        let countryCode = params[1];
        if (!selectedItem || !selectedItem.is_gpp_eligible) return 0.00;
        const match = gppLookupTable.find(obj => obj.quartile === quartile && obj.country === countryCode);
        return match ? match.max : 0;
      });
      /**
       * To Get exchange rate based on From and To currency
       * toCurrency = To Currency code
       * fromCurrency = From Currency Code
       */
      parser.setFunction('getExchangeRateByCurrencyCode', function(params) {
        console.log('params',params);
        let toCurrency = params[1];
        let fromCurrency = params[0];
        if (!selectedItem || !selectedItem.currency) return 1;
        const match = exchangeRates.find(r => r.to_currency === toCurrency && r.from_currency === fromCurrency);
        return match ? match.exchange_rate : 1;
      });

      /**
       * To Get promotion pay grade from job catalog
       */
      parser.setFunction('filteredPromotionPayGrade', function(params) {
        // edit or create
        const activeData = data.request ? data.request : data;
        // console.log('promotion_pay_grade', activeData.promotion_pay_grade)
        // if (activeData.promotion_pay_grade) return activeData.promotion_pay_grade;
        // if (data.request && data.request.promotion_pay_grade) return data.request.promotion_pay_grade;

        let filters = null;
        if (activeData.promotion_job_name) {
          filters = {
            job_family_name: v => v === selectedItem.promotion_job_family_name,
            job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
            job_name: v => v === activeData.promotion_job_name
          };
        }
        if (activeData.promotion_job_subfamily_name) {
          filters = {
            job_family_name: v => v === selectedItem.promotion_job_family_name,
            job_subfamily_name: v => v === activeData.promotion_job_subfamily_name,
            job_name: v => v === selectedItem.promotion_job_name
          };
        }
        if (activeData.promotion_job_family_name) {
          filters = {
            job_family_name: v => v === activeData.promotion_job_family_name,
            job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
            job_name: v => v === selectedItem.promotion_job_name
          };
        }

        if (filters) {
          const filtered = filterJobCatalog(filters, 'pay_grade', config, activeData);
          //const matchingGrade = filtered.find(x => x.startsWith(selectedItem.job_grade));
          //return matchingGrade ? matchingGrade : filtered.shift();
          return filtered.shift();
        }else{
          return null;
        }
      });   
      /**
       * To get JobGrade index from Job Catalog
       * param 1 =  Job Grade value
       *  */  
      parser.setFunction('getJobGradeIndex', function(params) {
        let jobGradeValue = params[0];
        return jobGrades.findIndex(x => x === jobGradeValue);
      });

      //Our Talent custom formulas start
      /**
       * To get Grade progression of a country using country Name
       * param 1 =  Country full name
       *  */  
      parser.setFunction('getCountriesGradeProgressions', function(params) {
        let country = params[0];
        const match = countryLookupTable.find(obj => obj.country === country);
        return match ? match.grade_progression.trim(): "";
      });

      /**
       * To get Increase guidelines using current IP situation
       * param 1 =  min/max string
       * param 2 = current ITP situation 
       *  */  
      parser.setFunction('getIncreaseGuidelines', function(params) {
        let minMax = params[0];
        let currentItpSituation = params[1];

        //console.log(selectedItem, "SELECTED COUNTRY");
        //console.log(currentItpSituation, "CURRENT ITP SITUATION");
        //console.log(increaseLookupTable, "increase Lookup Table");

        var keys = ["combo", 'country'];
        if((selectedItem.country == 'US' || selectedItem.country == 'USA') && selectedItem.country_name == 'USA')
          var values = [currentItpSituation, 'USA'];
        else if((selectedItem.country == 'CA' || selectedItem.country == 'CAN') && selectedItem.country_name == 'Canada')
          var values = [currentItpSituation, 'Canada'];
        else
          var values = [currentItpSituation, '*']; 

        var result = increaseLookupTable.filter(function(e) {
          return keys.every(function(a) {
            return values.includes(e[a])
          })
        })
        //console.log(result, "resultresultresultresultresultresultresultresultresultresult")
        var returnedVal = '';
        if(minMax == 'min')
        {
          returnedVal = result[0].min;
        }
        else if(minMax == 'max')
        {
          returnedVal = result[0].max;
        }
        return returnedVal;
        //const match = '';
        //return match ? minMax=='min'?result.min : minMax=='max'?result.max : 0 : 0;

        //const match = increaseLookupTable.find(obj => obj.combo === currentItpSituation);
        //return match ? minMax=='min'?match.min : minMax=='max'?match.max : 0 : 0;
      });
      //Our Talent custom formulas end

      //To check not a number
      parser.setFunction('isNaN', isNaN);
      
    }

    /*
    R2 - Admin Calculations
    getRequiredFormula() - Get required Formula from list of formulas available in DB
    */
    const getRequiredFormula = (formulaNeeded) => {
        let formulaFound = null;
        Object.values(formulas.formulas).forEach((fr, indx) => {
            if (fr.formula_name == formulaNeeded) {
                formulaFound = fr.formula;
            }
        });
        return formulaFound;
    }
    

  /*  DEFECTS FIX STARTS  */
  const ttc_increase = useMemo(
    () =>
      selectedItem
        ? ((selectedItem.new_fte_ttc_annual_salary) - (selectedItem.fte_ttc_annual_salary))
        : 0,
    [selectedItem]
  );
  //phase2 
  const ttc_increase_perc = useMemo(
    () =>
      selectedItem
        ? round((
          ((selectedItem.new_fte_ttc_annual_salary - selectedItem.fte_ttc_annual_salary) / selectedItem.fte_ttc_annual_salary) * 100), 10)
        : 0,
    [selectedItem]
  );

  const total_increaseed_amount = useMemo(
    () =>
      selectedItem
        ? ((selectedItem.new_fte_ttc_annual_salary) - (selectedItem.fte_ttc_annual_salary))
        : 0,
    [selectedItem]
  );
  /*  DEFECTS FIX ENDS  */
  
  const exchange_rate = useMemo(
    () => {
      if (!selectedItem || !selectedItem.currency) return 1;
      //getExchangeRateByCurrencyCode('EUR',localCurrencyCode)
      // console.log('localCurrencyCode',parser.getVariable('localCurrencyCode'));
      // console.log('selectedItem.currency',selectedItem.currency)
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Exchange Rate');
      //(1-currency/currentSrMid)+(currentSrMax/currentSrMid-1)
      let res = parser.parse(formulaToParse);
      console.log('parsed exchange rate', res);
      return res.result;
      // const { exchangeRates } = config;
      // if (!selectedItem || !selectedItem.currency) return 1;
      // const match = exchangeRates.find(r => r.to_currency === selectedItem.currency && r.from_currency === 'EUR');
      // return match ? 1 / match.exchange_rate : 1;
    },
    [selectedItem, config]
  );

  const salary_range_spread = useMemo(
    () => {
      if (!selectedItem) return 0;
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Range Spread');
      //(1-currency/currentSrMid)+(currentSrMax/currentSrMid-1)
      let res = parser.parse(formulaToParse);
      return res.result;     
      //return (1 - selectedItem.salary_range_min / selectedItem.salary_range_mid) + (selectedItem.salary_range_max / selectedItem.salary_range_mid - 1);
    },
    [selectedItem]
  );

  const tenure = useMemo(
    () => {
      if (selectedItem) {
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Tenure');
        let res = parser.parse(formulaToParse);
        //(CURRENT_TIMESTAMP-IF(dateOfJoining<>null,dateOfJoining,CURRENT_TIMESTAMP))/86400000/365
        // console.log('CURRENT_TIMESTAMP',parser.getVariable('CURRENT_TIMESTAMP'))
        // console.log('dateOfJoining', parser.getVariable('dateOfJoining'))
        console.log('parsed Tenure', res)
        return res.result;
      } else {
        return 0;
      }
      // if(selectedItem){
      // console.log('CURRENT_TIMESTAMP',CURRENT_TIMESTAMP)
      // console.log('selectedItem.pers_start_date',selectedItem.pers_start_date)
      // return selectedItem ? (CURRENT_TIMESTAMP - (selectedItem.pers_start_date || CURRENT_TIMESTAMP)) / 86400000 / 365 : 0 
      // }
    },
    [selectedItem]
  );
  
  const is_gpp_eligible = useMemo(
    () =>{
      if(selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('GPP Validation');
        //console.log('GPP Validation to parse ',formulaToParse);
        //AND(currentJobGrade=06,interimJobGrade=07,tenure>=1.5, tenure<=4)
        // console.log('currentJobGrade',parser.parse('currentJobGrade'))
        // console.log('interimJobGrade',parser.parse('interimJobGrade'))
        // console.log('tenure',parser.parse('tenure'))
        let res = parser.parse(formulaToParse);
        console.log('result for GPP Validation is',res)
        return res.result;
      }else{
          return false;
      }
    },
    [selectedItem]
  );

  const ttc_increase_amount = useMemo(
    () => {
      
      /**Release 2 Ourtalent making TTC increase amount calcualted at some scenario - Starts */
      if (selectedItem) {
        /* Release 3.2 - 19.1 Starts */ 
        if (data.ttc_increase_amount !== undefined) {
          const v = isNaN(data.ttc_increase_amount) ? 0 : data.ttc_increase_amount;
          if ((data.request_type && data.request_type == "internalhire") && selectedItem.incentive_plan_type == "YLIP") {
            selectedItem.sip_change = false;
          }
          return Math.abs(v);
        }
        if (data.request && data.request.ttc_increase_amount !== undefined) {
          const v = isNaN(data.request.ttc_increase_amount) ? 0 : data.request.ttc_increase_amount;
          if ((data.request_type && data.request_type == "internalhire") && selectedItem.incentive_plan_type == "YLIP") {
            selectedItem.sip_change = false;
          }
          return Math.abs(v);
        }
        /* Release 3.2 - 19.1 Ends */ 
        if (selectedItem.sip_change) {
          return selectedItem.new_fte_annual_salary && selectedItem.new_fte_incentive_target_amount && (selectedItem.new_fte_annual_salary + selectedItem.new_fte_incentive_target_amount) >= 0 ? (selectedItem.new_fte_annual_salary + selectedItem.new_fte_incentive_target_amount) - selectedItem.fte_ttc_annual_salary : 0;
        }
       /**Release 2 Ourtalent making TTC increase amount calcualted at some scenario - Ends */
          
          
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Merit/Promotion TTC Increase');
          console.log('curSalary',parser.getVariable('curSalary'));
          console.log('ttc_increase_pct',parser.getVariable('ttc_increase_pct'));
          let res = parser.parse(formulaToParse);
          console.log('result Merit/Promotion TTC Increase',res)
          return res.result;
        }
    },
    [selectedItem, data]
  );

  const ttc_increase_percentage = useMemo(
    () => {
      /**Release 2 Ourtalent making TTC increase amount calcualted at some scenario - Starts */
      if (selectedItem) {
        /* Release 3.2 - 19.1 Starts */ 
        if (data.ttc_increase_percentage !== undefined) { 
          if ((data.request_type && data.request_type == "internalhire") && selectedItem.incentive_plan_type == "YLIP") {
            selectedItem.sip_change = false;
          }
          return Math.abs(data.ttc_increase_percentage);
        }
        if (data.request && data.request.ttc_increase_percentage !== undefined) {
          if ((data.request_type && data.request_type == "internalhire") && selectedItem.incentive_plan_type == "YLIP") {
            selectedItem.sip_change = false;
          }
          return Math.abs(data.request.ttc_increase_percentage);
        }
        /* Release 3.2 - 19.1 Ends */ 

        if (selectedItem.sip_change) {
         // return selectedItem.new_fte_annual_salary ? selectedItem.new_fte_annual_salary - selectedItem.fte_ttc_annual_salary : 0;
          return  round((((selectedItem.ttc_increase_amount || 0) / selectedItem.fte_ttc_annual_salary) * 100),10);
        }
        /**Release 2 Ourtalent making TTC increase amount calcualted at some scenario - Ends */
       
        
        return selectedItem
          ? round((((selectedItem.ttc_increase_amount || 0) / selectedItem.fte_ttc_annual_salary) * 100), 10)
          : 0;
        }
    },
    [selectedItem, data]
  );

  const override_fte_incentive_target_percentage = useMemo(
    () => {
      if (selectedItem && selectedItem.override_fte_annual_salary) {
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Override Incentive Target Percentage');
        //IF(overrideFteAbs > 0, (overrideFteIta/overrideFteAbs)*100, 0)
        let res = parser.parse(formulaToParse);
        console.log('parsed override_fte_incentive_target_percentage', res);
        return res.result;
      } else {
        return 0;
      }
    },
    [selectedItem]
  );

  /* Release 2.0 Admin calculation - changing this dynamic */
  const override_fte_ttc_annual_salary = useMemo(
    () => {
      if (selectedItem) {
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Override Total Target Cash (Full-Time Equivalent)');
        //overrideFteAbs+overrideFteIta+additionalFteAnnualPayment
        // console.log('overrideFteAbs',parser.getVariable('overrideFteAbs'))
        // console.log('overrideFteIta',parser.getVariable('overrideFteIta'))
        // console.log('additionalFteAnnualPayment',parser.getVariable('additionalFteAnnualPayment'))
        let res = parser.parse(formulaToParse);
        console.log('parsed', res);
        return res.result;
      } else {
        return 0;
      }
    },
    [selectedItem]
  );

  const gpp_increase_amount = useMemo(
    () => {
      if (data.gpp_increase_amount) return data.gpp_increase_amount;

      return selectedItem
        ? selectedItem.fte_ttc_annual_salary * (selectedItem.gpp_increase_percentage / 100)
        : null;
    },
    [selectedItem, data]
  );

  const gpp_increase_percentage = useMemo(
    () => {
      if (data.gpp_increase_percentage) return data.gpp_increase_percentage;

      if(selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('GPP Increase Percentage');
        // console.log('formulaToParse',formulaToParse);
        // console.log('lumpSumTwo',parser.getVariable('lumpSumTwo'))
        // console.log('curSalary',parser.getVariable('curSalary'))
        //((lumpSumTwo/curSalary)*100)
        let res = parser.parse(formulaToParse);
        console.log('Parsed Gpp Increatse perc',res)
        return res.result;
      }else{
        return null;
      }
    },
    [selectedItem, data]
  );

  const promotion_job_family_name = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;
      //console.log(data);

      if (activeData.promotion_job_family_name) return activeData.promotion_job_family_name;

      return selectedItem
        ? selectedItem.promotion_job_family_name || selectedItem.job_family_name
        : null;
    },
    [selectedItem, data]
  );

  const new_position_title = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.new_position_title) return activeData.new_position_title;

      return selectedItem
        ? selectedItem.new_position_title || selectedItem.position_title
        : null;
    },
    [selectedItem, data]
  );

  const promotion_job_subfamily_name = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.promotion_job_subfamily_name) return activeData.promotion_job_subfamily_name;

      // const f = buildFilters('job_subfamily_name', activeData, selectedItem);
      // console.log(f)

      let filters = null;
      if (activeData.promotion_job_family_name) {
        filters = { job_family_name: v => v === activeData.promotion_job_family_name };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'job_subfamily_name', config, activeData);
        return filtered.shift();
      }

      return selectedItem
        ? selectedItem.promotion_job_subfamily_name || selectedItem.job_subfamily_name
        : null;
    },
    [selectedItem, data, config]
  );
  const promotion_job_name = useMemo(
    () => {
      const { jobsCatalog } = config;
      // edit or create
      const activeData = data.request ? data.request : data;
      // changed - Job Role Name
      if (activeData.promotion_job_name) return activeData.promotion_job_name;

      let filters = null;
      if (activeData.promotion_job_subfamily_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === activeData.promotion_job_subfamily_name
        };
      }
      if (activeData.promotion_job_family_name) {
        filters = {
          job_family_name: v => v === activeData.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name
        };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'job_name', config, activeData);
        // Return job title which matches current job grade, if possible for given job selection
        const namesAndGrades = jobsCatalog.filter(j => filtered.includes(j.job_name));
        const matching = namesAndGrades.find(jx => jx.pay_grade.startsWith(selectedItem.job_grade));
        return matching ? filtered.find(x => x === matching.job_name) : filtered.shift();
      }
      // changed - Something else or Nothing
      return selectedItem
        ? selectedItem.promotion_job_name || selectedItem.job_name
        : null;
    },
    [selectedItem, data, config]
  );

  const promotion_pay_grade = useMemo(
    () => {
      //interimJobGrade&IF(interimJobFamily="Sales","SA",IF(interimJobFamily="Pre-Sales","PS","HR"))

     //IF(filteredPromotionPayGrade()<>null,filteredPromotionPayGrade(),IF(newPayGrade<>null,newPayGrade,currentPayGrade))
     if (!selectedItem) return null;
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('New PayGrade');
      // console.log('filteredPromotionPayGrade()',parser.parse('filteredPromotionPayGrade()'))
      // console.log('currentPayGrade',parser.parse('currentPayGrade'))
      // console.log('newPayGrade',parser.parse('newPayGrade'))
      let res = parser.parse(formulaToParse);
      console.log('parsed New PayGrade',res)
      return res.result;
      
    },
    [selectedItem, data, config]
  );

  const promotion_job_grade = useMemo(
    () => {
      // edit or create
      const activeData = data.request ? data.request : data;

      if (activeData.promotion_job_grade) return activeData.promotion_job_grade;

      let filters = null;
      if (activeData.promotion_pay_grade) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name,
          pay_grade: v => v === activeData.promotion_pay_grade
        };
      }
      if (activeData.promotion_job_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === activeData.promotion_job_name,
          pay_grade: v => v === selectedItem.promotion_pay_grade
        };
      }
      if (activeData.promotion_job_subfamily_name) {
        filters = {
          job_family_name: v => v === selectedItem.promotion_job_family_name,
          job_subfamily_name: v => v === activeData.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name,
          pay_grade: v => v === selectedItem.promotion_pay_grade
        };
      }
      if (activeData.promotion_job_family_name) {
        filters = {
          job_family_name: v => v === activeData.promotion_job_family_name,
          job_subfamily_name: v => v === selectedItem.promotion_job_subfamily_name,
          job_name: v => v === selectedItem.promotion_job_name,
          pay_grade: v => v === selectedItem.promotion_pay_grade
        };
      }

      if (filters) {
        const filtered = filterJobCatalog(filters, 'job_grade', config, activeData);
        return filtered.shift();
      }

      return selectedItem
        ? selectedItem.promotion_job_grade || selectedItem.job_grade
        : null;
    },
    [selectedItem, data, config]
  );
    
  const gpp_guideline_max = useMemo(
    () => {
      if (!selectedItem || !selectedItem.is_gpp_eligible) return 0.00;
      //getGPPLookupTableByQuartileAndCountry(currentQuartile,currentCountryCode)
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('GPP Guidelines Max');
      // console.log('currentQuartile',parser.getVariable('currentQuartile'))
      // console.log('currentCountryCode',parser.getVariable('currentCountryCode'))
      // console.log('selectedItem.ttc_employee_quartile',selectedItem.ttc_employee_quartile)
      // console.log('selectedItem.country',selectedItem.country)
      let res = parser.parse(formulaToParse);
      console.log('parsed GPP Guidelines Max',res)
      return res.result;
    },
    [selectedItem, config]
  );

  const promoted = useMemo(
    () => {
        if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Promoted');
            let res = parser.parse(formulaToParse);
            // console.log('finalJobFamily',parser.getVariable('finalJobFamily'))
            // console.log('newJobGrade',parser.getVariable('newJobGrade'))
            // console.log('currentJobGrade',parser.getVariable('currentJobGrade'))
            console.log('parsed promoted',res)
            return res.result;
        }else{
            return false;
        }
    },
    [selectedItem, config]
  );

  const demoted = useMemo(
    () => {
      if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Demoted');
            let res = parser.parse(formulaToParse);
            console.log('demoted',res)
            return res.result;
       
      }else{
        return false;
      }
    },
    [selectedItem, config]
  );

  const promotion_job_grade_delta = useMemo(
    () =>{
      if(selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Promotion Job Grade Delta');
        //IF(newJobGrade-currentJobGrade,(newJobGrade-currentJobGrade),"null")
        let res = parser.parse(formulaToParse);
        console.log('Promotion Job Grade Delta',res)
        return (res.result=="null"||res.result==null)?null:(res.result).toString();
      }else{
        return null;
      }
    },
    [selectedItem]
  );

  const promotion_incentive_target_percentage = useMemo(
    () => {
      
      /**Release 2 Issue check - starts */
      if (!selectedItem) return 0.00;
      /**Release 2 issye check - ends */
      //IF(OR(promoted<>0,promoted<>"",promoted<>null),getITPFromJFAndJGAndCountry(IF(OR(interimJobFamily="Sales", interimJobFamily="Pre-Sales")=false,"*",interimJobFamily),IF(OR(interimJobFamily="Sales", interimJobFamily="Pre-Sales")=true,"*",interimJobGrade),IF(OR(OR(country="USA",country="Canada")=false,AND(OR(interimJobFamily="Sales", interimJobFamily="Pre-Sales")=false,country<>"USA")=true),"*",country)),0.00)
      defineFormulaVars(); //refresh the values of formula variables
      // console.log('promoted',parser.getVariable('promoted'))
      // console.log('interimJobFamily',parser.getVariable('interimJobFamily'))
      // console.log('interimJobGrade',parser.getVariable('interimJobGrade'))
      // console.log('country',parser.getVariable('country'))
      // console.log('part parsed',parser.parse('getITPFromJFAndJGAndCountry(IF(OR(interimJobFamily="Sales", interimJobFamily="Pre-Sales")=false,"*",interimJobFamily),IF(OR(interimJobFamily="Sales", interimJobFamily="Pre-Sales")=true,"*",interimJobGrade),IF(OR(OR(country="USA",country="Canada")=false,AND(OR(interimJobFamily="Sales", interimJobFamily="Pre-Sales")=false,country<>"USA")=true),"*",country))'))
      let formulaToParse = getRequiredFormula('New Theo ITP');
      
      let res = parser.parse(formulaToParse);
      console.log('parsed promotion_incentive_target_percentage',res);
      return res.result;
    },
    [selectedItem, config]
  );

  const mandatory_ttc_increase_percentage = useMemo(
    () => {
      if (!selectedItem) return 0;
      
      if (!selectedItem.override_promotion_increase){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Mandatory TTC Increase % due to promotion');
        let res = parser.parse(formulaToParse);
        console.log('parsed Mandatory TTC Increase % due to promotion',res)
        return res.result;
      }
      return 0;
    },
    [selectedItem]
  );

  const mandatory_ttc_increase_amount = useMemo(
    () =>
    {
        if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Mandatory TTC Increase due to promotion');
            console.log('formulaToParse',formulaToParse)
            //formulaToParse = 'ROUND(currentFteAbs*(mandatoryTtcIncreasePercentageDueToPromotion/100),2)';
            let res = parser.parse(formulaToParse);
            return res.result;
        }else{
            return 0;
        }
    },
    
    [selectedItem]
  );

  const apply_ti_harmonization = useMemo(
    () => {
      if (!selectedItem) return false;
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Apply TI Harmonization?');
      let res = parser.parse(formulaToParse);
      return res.result;
    },
    [selectedItem]
  );

  const interim_harmonization_type = useMemo(
    () => {

        if (selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Harmonization Type');
            let res = parser.parse(formulaToParse);
            return res.result;
        }else{
            return 'No TIH';
        }
    },
    [selectedItem]
  );

  const interim_job_family_name = useMemo(
    () =>
    {
        if (selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Interim Job Family');
            let res = parser.parse(formulaToParse);
            return res.result;
        }else{
            return 0;
        }
    },
    [selectedItem]
  );

  const interim_job_grade = useMemo(
    () =>
    {
        if (selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Interim Job Grade');
            let res = parser.parse(formulaToParse);
            console.log('parsed interim job grade interim_job_grade', res)
            return res.result;
        }else{
            return 0;
        }
    },
    [selectedItem]
  );

  const interim_pay_grade = useMemo(
    () => {
      if(!selectedItem) return 0;
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Interim Pay Grade');
      console.log('newPayGrade',parser.getVariable('newPayGrade'))
      let res = parser.parse(formulaToParse);
      console.log('parsed Interim Pay Grade', res)
      return res.result;
    },
    [selectedItem]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const get_salary_range = (country, payScaleArea, payGrade, currency) => {
    const salaryRanges = config.allSalaryRanges
    let salaryRangeFound;
    for (const salaryRange of salaryRanges) {
      // eslint-disable-next-line
      if (salaryRange.country_name == country && salaryRange.grade_region == payScaleArea && salaryRange.pay_grade == payGrade && salaryRange.currency_code == currency) {
        salaryRangeFound = salaryRange
        break
      }
    }
    return salaryRangeFound
  }

  const interim_salary_range_min = useMemo(
    () => {
      // console.log('interim_salary_range_min' , selectedItem, selectedItem && selectedItem.interim_pay_grade)
      if (!selectedItem || !selectedItem.interim_pay_grade) return 0;

      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('New Salary Range Min');
      let res = parser.parse(formulaToParse);
      console.log('parsed New Salary Range Min', res)
      return res.result;
    },
    [selectedItem, get_salary_range]
  );

  const interim_salary_range_mid = useMemo(
    () => {
      if (!selectedItem || !selectedItem.interim_pay_grade) return 0;

      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('New Salary Range Mid');
      let res = parser.parse(formulaToParse);
      console.log('parsed New Salary Range Mid', res)
      return res.result;
    },
    [selectedItem, get_salary_range]
  );

  const interim_salary_range_max = useMemo(
    () => {
      if (!selectedItem || !selectedItem.interim_pay_grade) return 0;

      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('New Salary Range Max');
      let res = parser.parse(formulaToParse);
      console.log('parsed New Salary Range Max', res)
      return res.result;
    },
    [selectedItem, get_salary_range]
  );

  const interim_salary_range_spread = useMemo(
    () => {
      if (!selectedItem) return 0;
      if(!selectedItem.interim_salary_range_mid) return 0;
      //(1-newSrMin/newSrMid)+(newSrMax/newSrMid-1)
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('New Salary Range Spread');
      let res = parser.parse(formulaToParse);
      console.log('parsed New Salary Range Spread', res)
      return res.result;
    },
    [selectedItem]
  );

  const interim_fte_incentive_target_amount = useMemo(
    () => {
        if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Interim ITA');
            let res = parser.parse(formulaToParse);
            return res.result;
        }else{
            return 0;
        }
    },
    [selectedItem]
  );

  const interim_incentive_target_percentage = useMemo(
    () => {
        if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Interim ITP');
            let res = parser.parse(formulaToParse);
            return res.result;
        }else{
            return 0;
        }
    },
    [selectedItem]
  );

  const interim_total_increase_amount = useMemo(
    () =>{
        if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Total Increase Amount');
            console.log('merit',parser.getVariable('merit'))
            console.log('promoted',parser.getVariable('promoted'))
            console.log('isGPPEligible',parser.getVariable('isGPPEligible'))
            console.log('lumpSumTwo',parser.getVariable('lumpSumTwo'))
            //merit + IF(AND(promoted=true,isGPPEligible),lumpSumTwo,0)
            let res = parser.parse(formulaToParse);
            console.log('parsed Total Increase Amount', res)
            return res.result;
        }else{
            return 0;
        }
    },
    [selectedItem]
  );

  const interim_increase_amount_for_salincr = useMemo(
    () =>{
      
        if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Step 1 Increase (@ current paymix)');
            let res = parser.parse(formulaToParse);
            console.log('parsed Step 1 Increase (@ current paymix) interim_increase_amount_for_salincr', res)
            return res.result;
        }else{
            return 0;
        }
    },
    [selectedItem]
  );

  const interim_increase_amount_for_tih = useMemo(
    () =>{
      
        if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Step 2 Increase (TIH applied)');
            console.log('overrideTiHarmonization',parser.getVariable('overrideTiHarmonization'));
            console.log('totalIncreaseAmount',parser.getVariable('totalIncreaseAmount')); 
            let res = parser.parse(formulaToParse);
            console.log('parsed Step 2 Increase (TIH applied) interim_increase_amount_for_tih', res)
            return res.result;
        }else{
            return 0;
        }
    },
    [selectedItem]
  );

  const step_1_abs = useMemo(
    () =>{
        if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('Step 1 ABS');
            let res = parser.parse(formulaToParse);
            console.log('parsed Step 1 ABS', res)
            return res.result;
        }else{
            return 0;
        }
    },
    [selectedItem]
  );

  const step_1_ita = useMemo(
    () =>{
      if(selectedItem){
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Step 1 ITA');
          let res = parser.parse(formulaToParse);
          return res.result;
      }else{
          return 0;
      }
    },
    [selectedItem]
  );

  const step_1_itp = useMemo(
    () =>{
        if(selectedItem){
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Step 1 ITP');
          let res = parser.parse(formulaToParse);
          return res.result;
        }else{
            return 0;
        }
      
    },
    [selectedItem]
  );

  const interim_theo_itp = useMemo(
    () => {
      if (!selectedItem) return 0.00;
     if(selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Interim Theo ITP');
        let res = parser.parse(formulaToParse);
        console.log('parsed Interim theo ITP', res)
        return res.result;
      }else{
          return 0;
      }      
    },
    [selectedItem, config]
  );

  const interim_itp_situation = useMemo(
    () =>{
      if(selectedItem){
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Interim ITP Situation');
          let res = parser.parse(formulaToParse);
          return res.result;
      }else{
          return 'Norm';
      }
    },
    [selectedItem]
  );

  const step_2_abs_gap = useMemo(
    () => {
      if (!selectedItem) return 0;
      if (!selectedItem.apply_ti_harmonization) return 0;
      if (selectedItem.interim_itp_situation !== 'High') return 0;
      if (!selectedItem.interim_theo_itp) return 0;
      //IF(applyTiHarmonization=false,0,IF(interimItpSituation<>"High",0,IF(interimTheoItp=false,0,(stepOneIta/(interimTheoItp/100))-stepOneAbs)))
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Step 2 ABS Gap');
      console.log('applyTiHarmonization',parser.getVariable('applyTiHarmonization'))
      console.log('interimItpSituation',parser.getVariable('interimItpSituation'))
      console.log('interimTheoItp',parser.getVariable('interimTheoItp'))
      console.log('stepOneIta',parser.getVariable('stepOneIta'))
      console.log('stepOneAbs',parser.getVariable('stepOneAbs'))
      let res = parser.parse(formulaToParse);
      console.log('parsed step_2_abs_gap', res)
      return res.result;
    },
    [selectedItem]
  );

  const step_2_ita_gap = useMemo(
    () => {
      if (!selectedItem) return 0;
      //IF(applyTiHarmonization=false,0,IF(interimItpSituation<>"Low",0,IF(interimTheoItp=false,0,(stepOneAbs*(interimTheoItp/100))-stepOneIta)))
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Step 2 ITA Gap');
      let res = parser.parse(formulaToParse);
      console.log('parsed step 3 ITA Gap', res)
      return res.result;
    },
    [selectedItem]
  );

  const interim_remainder_for_split = useMemo(
    () => {
      if (!selectedItem) return 0;
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Remainder for Split Situation');
      //IF(applyTiHarmonization=false,increaseAmountForTih,IF(OR(AND(stepTwoAbsGap>0,increaseAmountForTih>stepTwoAbsGap),AND(stepTwoItaGap>0,increaseAmountForTih>stepTwoItaGap),AND(stepTwoAbsGap=0,stepTwoItaGap=0)), increaseAmountForTih-stepTwoItaGap-stepTwoAbsGap, 0  ))
      console.log('applyTiHarmonization',parser.getVariable('applyTiHarmonization'))
      console.log('increaseAmountForTih',parser.getVariable('increaseAmountForTih'))
      console.log('stepTwoAbsGap',parser.getVariable('stepTwoAbsGap'))
      console.log('stepTwoItaGap',parser.getVariable('stepTwoItaGap'))
      let res = parser.parse(formulaToParse);
      console.log('parsed interim_remainder_for_split', res)
      return res.result;
    },
    [selectedItem]
  );

  const new_fte_annual_salary = useMemo(
    () => {
      
      if (!selectedItem) return 0;
      /**Release 2 - Some scenarios make new annual base salary user input - starts*/
      if (data.new_fte_annual_salary !== undefined) {
        const v = isNaN(data.new_fte_annual_salary) ? 0 : data.new_fte_annual_salary;
        /* Release 3.2 - 19.1 Starts */ 
        if (data && data.request_type == "internalhire" && selectedItem.incentive_plan_type == "YLIP") {
          selectedItem.sip_change = true;
        }
        /* Release 3.2 - 19.1 Ends */ 
        return hasNoDecimals ? round( Math.abs(v), 0) : Math.abs(v);
      }
      if (data.request && data.request.new_fte_annual_salary !== undefined) {
        const v = isNaN(data.request.new_fte_annual_salary) ? 0 : data.request.new_fte_annual_salary;
        /* Release 3.2 - 19.1 Starts */ 
        if ((data.request_type && data.request_type == "internalhire") && selectedItem.incentive_plan_type == "YLIP") {
          selectedItem.sip_change = true;
        }
        /* Release 3.2 - 19.1 Ends */ 
        return hasNoDecimals ? round( Math.abs(v), 0) : Math.abs(v);
      }
       if (selectedItem.sip_change && !data.request_from )  {
        return selectedItem.new_fte_annual_salary ? selectedItem.new_fte_annual_salary : 0;
      }
      /**Release 2 - Some scenarios make new annual base salary user input - Ends*/
      
      let value = 0;
      if (selectedItem.override_manager_proposal) {
        console.log('selectedItem.override_fte_annual_salary',selectedItem.override_fte_annual_salary)
      console.log('selectedItem.apply_ti_harmonization',selectedItem.apply_ti_harmonization)
      console.log('selectedItem.override_manager_proposal',selectedItem.override_manager_proposal)
      console.log('selectedItem.new_fte_annual_salary',selectedItem.new_fte_annual_salary)
        value = selectedItem.override_fte_annual_salary || selectedItem.new_fte_annual_salary || null;
        return hasNoDecimals ? round(value, 0) : value;
      }
      // console.log('selectedItem.interim_remainder_for_split',selectedItem.interim_remainder_for_split)
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('New ABS');
        console.log('applyTiHarmonization',parser.getVariable('applyTiHarmonization'))
        console.log('currentFteAbs',parser.getVariable('currentFteAbs'))
        console.log('totalIncreaseAmount',parser.getVariable('totalIncreaseAmount'))
        console.log('currentItp',parser.getVariable('currentItp'))
        console.log('hasNoDecimals',parser.getVariable('hasNoDecimals'))
        console.log('interimItpSituation',parser.getVariable('interimItpSituation'))
        console.log('increaseAmountForTih',parser.getVariable('increaseAmountForTih'))
        console.log('stepTwoItaGap',parser.getVariable('stepTwoItaGap'))
        console.log('stepOneAbs',parser.getVariable('stepOneAbs'))
        console.log('stepOneAbs',parser.getVariable('stepOneAbs'))
        console.log('interimTheoItp',parser.getVariable('interimTheoItp'))
        console.log('stepTwoAbsGap',parser.getVariable('stepTwoAbsGap'))
        console.log('remainderForSplit',parser.getVariable('remainderForSplit'))
        let res = parser.parse(formulaToParse);
        console.log('parsed new abs',res)
        return res.result;
    },
    [selectedItem, config]
  );

  const new_fte_incentive_target_amount = useMemo(
    () => {
      if (!selectedItem) return 0;
      /**Release 2 Our Talent some scenarios make Our Talent new intensive target amount dynamic - starts */
      if (data.new_fte_incentive_target_amount !== undefined) {
        const v = isNaN(data.new_fte_incentive_target_amount) ? 0 : data.new_fte_incentive_target_amount;
        /* Release 3.2 - 19.1 Starts */ 
          if (data && data.request_type == "internalhire" && selectedItem.incentive_plan_type == "YLIP") {
            selectedItem.sip_change = true;
          }
        /* Release 3.2 - 19.1 Ends */ 
        return hasNoDecimals ? round( Math.abs(v), 0) : Math.abs(v);
      }
      if (data.request && data.request.new_fte_incentive_target_amount !== undefined) {
        const v = isNaN(data.request.new_fte_incentive_target_amount) ? 0 : data.request.new_fte_incentive_target_amount;
        /* Release 3.2 - 19.1 Starts */ 
        if ((data.request_type && data.request_type == "internalhire") && selectedItem.incentive_plan_type == "YLIP") {
          selectedItem.sip_change = true;
        }
        /* Release 3.2 - 19.1 Ends */ 
        return hasNoDecimals ? round( Math.abs(v), 0) : Math.abs(v);
      }
      if (selectedItem.sip_change && !data.request_from) {
        return selectedItem.new_fte_incentive_target_amount ? selectedItem.new_fte_incentive_target_amount : 0;
      }
      /**Release 2 Our Talent some scenarios make Our Talent new intensive target amount dynamic - Ends */
      let value = 0;

      if (selectedItem.override_manager_proposal) {
        value = selectedItem.override_fte_incentive_target_amount || selectedItem.new_fte_incentive_target_amount;
        return hasNoDecimals ? round(value, 0) : value;
      }
      //New ITA

        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('New ITA');
        console.log('applyTiHarmonization',parser.getVariable('applyTiHarmonization'))
        console.log('currentFteAbs',parser.getVariable('currentFteAbs'))
        console.log('totalIncreaseAmount',parser.getVariable('totalIncreaseAmount'))
        console.log('currentItp',parser.getVariable('currentItp'))
        console.log('hasNoDecimals',parser.getVariable('hasNoDecimals'))
        console.log('interimItpSituation',parser.getVariable('interimItpSituation'))
        console.log('increaseAmountForTih',parser.getVariable('increaseAmountForTih'))
        console.log('stepTwoItaGap',parser.getVariable('stepTwoItaGap'))
        console.log('stepOneAbs',parser.getVariable('stepOneAbs'))
        console.log('stepOneAbs',parser.getVariable('stepOneAbs'))
        console.log('interimTheoItp',parser.getVariable('interimTheoItp'))
        console.log('stepTwoAbsGap',parser.getVariable('stepTwoAbsGap'))
        console.log('remainderForSplit',parser.getVariable('remainderForSplit'))
        console.log('stepOneIta',parser.getVariable('stepOneIta'))
        console.log('interimFteItp',parser.getVariable('interimFteItp'))
        
        let res = parser.parse(formulaToParse);
        console.log('parsed new ITA',res)
        return res.result;
    },
    [selectedItem, config]
  );

  const new_incentive_target_percentage = useMemo(
    () =>{
        if(selectedItem){
            defineFormulaVars(); //refresh the values of formula variables
            let formulaToParse = getRequiredFormula('New ITP');
            let res = parser.parse(formulaToParse);
            return res.result;
        }else{
            return 0;
        }
    },
    [selectedItem]
  );

  const new_fte_ttc_annual_salary = useMemo(
    () => {
      if(selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        //IF(hasNoDecimals=true,ROUND(newAbs+newIta+additionalFteAnnualPayment,0),ROUND(newAbs+newIta+additionalFteAnnualPayment,2))
        //ROUND(newAbs+newIta+additionalFteAnnualPayment,IF(hasNoDecimals=true,0,2))
        let formulaToParse = getRequiredFormula('New TTC');
        console.log('formulaToParse', formulaToParse);
        console.log('hasNoDeciamls', parser.getVariable('hasNoDecimals'));
        console.log('newAbs', parser.getVariable('newAbs'));
        console.log('newIta', parser.getVariable('newIta'));
        console.log('additionalFteAnnualPayment', parser.getVariable('additionalFteAnnualPayment'));
        let res = parser.parse(formulaToParse);
        console.log('parsed New TTC', res)
        return res.result;
        
      }else{
        return 0;
      }
    },
    [selectedItem, config]
  );

  const new_itp_situation = useMemo(
    () =>{
      if(selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('New ITP Situation');
        console.log('newItp',parser.getVariable('newItp'))
        console.log('interimTheoItp',parser.getVariable('interimTheoItp'))
        //IF(newItp=interimTheoItp,"Norm",IF(newItp>interimTheoItp,"High","Low"))
        let res = parser.parse(formulaToParse);
        return res.result;
      }else{
          return 'Norm';
      }
    },
    [selectedItem]
  );

  const new_ttc_check = useMemo(
    () => {
      if (selectedItem && selectedItem.new_fte_ttc_annual_salary) {
        //IF(ROUND((newTtc*100)/100,0)=ROUND(((curSalary+mandatoryTtcIncreaseDueToPromotion+totalIncreaseAmount)*100)/100,0),true,false)
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('TTC Check');
        console.log('totalIncreaseAmount', parser.getVariable('totalIncreaseAmount'));
        let res = parser.parse(formulaToParse);
        console.log('parsed TTC Check', res)
        return res.result;
      }
      return false;
    },
    [selectedItem]
  );

  const new_ttc_compa_ratio = useMemo(
    () => {
      if (selectedItem) {
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('New TTC Compa-Ratio');
        //IF(AND(newTtc>0,newSrMid>0)=true,newTtc/newSrMid,currentCR)
        console.log('newTtc',parser.getVariable('newTtc'))
        console.log('newSrMid',parser.getVariable('newSrMid'))
        console.log('currentCR',parser.getVariable('currentCR'))
        console.log('AND(newTtc>0,newSrMid>0)',parser.parse('AND(newTtc>0,newSrMid>0)'))
        let res = parser.parse(formulaToParse);
        console.log('new compa is ',res)
        return res.error=="#DIV/0!"?Infinity:res.result;
      }
      return 0.00;
    },
    [selectedItem]
  );

  const new_quartile = useMemo(
    () => {
      if (selectedItem && selectedItem.new_ttc_compa_ratio > 0) {
        console.log('new compa ratio', selectedItem.new_ttc_compa_ratio)
        console.log('new compa ratio parsed', parser.getVariable('newCR'))
        console.log('interim_salary_range_spread',  selectedItem.interim_salary_range_spread)
        console.log('Parsing new quartile', parser.parse('IFERROR((newSrRangeSpread/2),0)'))
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('New Quartile');
        let res = parser.parse(formulaToParse);
        console.log('parsed new quartile', res)
        return res.result;
      }   
      return 'NA';
    },
    [selectedItem]
  );

  /*const new_incentive_plan_type = useMemo(
    () =>{
        if(selectedItem){
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('New Incentive Plan Type');
          //IF(AND(incentivePlan="YPDI",currentJobGrade=12,interimJobGrade="13"),"YMDI",incentivePlan)
          console.log('incentivePlan',parser.getVariable('incentivePlan'))
          console.log('currentJobGrade',parser.getVariable('currentJobGrade'))
          console.log('interimJobGrade',parser.getVariable('interimJobGrade'))
          let res = parser.parse(formulaToParse);
          console.log('parsed new_incentive_plan_type',res)
          return res.result;
        }else{
          return '';
        }
    },
    [selectedItem]
  );*/

  const new_incentive_plan_type = useMemo(
    () =>
    {
      if(selectedItem)
      {
        console.log("IN_PLAN_TYPE 1");
        if(data.request_type == "internalhire")
        {
          console.log("IN_PLAN_TYPE 2");
          if ((selectedItem.promotion_job_family_name == "Sales" || selectedItem.promotion_job_family_name == "Pre-Sales"))
          {
            console.log("IN_PLAN_TYPE 3");
            return 'YSIP';
          }
          else
          {
            console.log("IN_PLAN_TYPE 4");
            if ((selectedItem.promotion_job_family_name != "Sales" || selectedItem.promotion_job_family_name != "Pre-Sales"))
            {
              if(selectedItem.job_grade >= 12 && selectedItem.interim_job_grade >= 13)
                return 'YMDI';
              else 
                return 'YPDI';
            }
            else
            {
              if(selectedItem.incentive_plan_type === 'YPDI' && selectedItem.job_grade == 12 && selectedItem.interim_job_grade == 13)
                return 'YMDI';
              else
                return selectedItem.incentive_plan_type;
            }
          }
        }
        else
        {
          console.log("IN_PLAN_TYPE 5");
          if(selectedItem.incentive_plan_type === 'YPDI' && selectedItem.job_grade == 12 && selectedItem.interim_job_grade == 13)
            return 'YMDI';
          else
            return selectedItem.incentive_plan_type;
        }
      }
    },
    [selectedItem, data]
  );
  
  const individual_budget_amount = useMemo(
    () =>{
      if(!selectedItem) return 0;
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Individual Budget');
      let res = parser.parse(formulaToParse);
      return res.result;
    },
    [selectedItem]
  );

  const individual_spending_amount = useMemo(
    //merit+IF(AND(currentJobGrade=6,newJobGrade=7,tenure>=1.5,tenure<=4),0,mandatoryTtcIncreaseDueToPromotion)
    () =>{
    if (selectedItem){
      //merit + (isGppEligible ? 0 : mandatoryTtcIncreaseDueToPromotion)
      let formulaToParse = getRequiredFormula('Individual Spending');
      defineFormulaVars(); //refresh the values of formula variables
      console.log('merit',parser.getVariable('merit'))
      console.log('currentJobGrade',parser.getVariable('currentJobGrade'))
      console.log('newJobGrade',parser.getVariable('newJobGrade'))
      console.log('tenure',parser.getVariable('tenure'))
      console.log('isGppEligible',parser.getVariable('isGppEligible'))
      console.log('mandatoryTtcIncreaseDueToPromotion',parser.getVariable('mandatoryTtcIncreaseDueToPromotion'))
      console.log('tenure check',parser.parse('merit + (isGppEligible ? 0 : mandatoryTtcIncreaseDueToPromotion)'))
      let res = parser.parse(formulaToParse);
      console.log('parsed Individual Spending',res)
      return res.result;
  }else{
      return 0;
  }
},
    [selectedItem]
  );

  const individual_balance_amount = useMemo(
    //individualBudgetAmount-individualSpendingAmount
    () =>{
      if (selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Individual Balance');
        let res = parser.parse(formulaToParse);
        return res.result;
    }else{
        return 0;
    }
  },
    [selectedItem]
  );

  const gpp_budget = useMemo(
    //IF(AND(promoted=true,currentJobGrade=06,newJobGrade=07,tenure>=1.5,tenure<=4),curSalary *( gppGuidelineMax / 100) + mandatoryTtcIncreaseDueToPromotion,0)
    () =>{
      if (selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('GPP Budget');
        // console.log('promoted',parser.getVariable('promoted'))
        // console.log('currentJobGrade',parser.getVariable('currentJobGrade'))
        // console.log('newJobGrade',parser.getVariable('newJobGrade'))
        // console.log('tenure',parser.getVariable('tenure'))
        // console.log('curSalary',parser.getVariable('curSalary'))
        // console.log('gppGuidelineMax',parser.getVariable('gppGuidelineMax'))
        // console.log('mandatoryTtcIncreaseDueToPromotion',parser.getVariable('mandatoryTtcIncreaseDueToPromotion'))
        let res = parser.parse(formulaToParse);
        console.log('parsed GPP budget',res)
        return res.result;
    }else{
        return 0;
    }
  },
    [selectedItem]
  );

  const gpp_spending = useMemo(
    //IF(AND(promoted="YES",currentJobGrade = 6,newJobGrade = 7,tenure >= 1.5,tenure <= 4), lumpSum2+mandatoryTtcIncreaseDueToPromotion,0)
    () =>{
      if (selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('GPP Spending');
        let res = parser.parse(formulaToParse);
        console.log('parsed GPP Spending',res)
        return res.result;
    }else{
        return 0;
    }
  },
    [selectedItem]
  );

  const gpp_balance = useMemo(
    //gppBudget - gppSpending
    () =>{
      if (selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('GPP Balance');
        let res = parser.parse(formulaToParse);
        return res.result;
    }else{
        return 0;
    }
  },
    [selectedItem]
  );

  const total_spending = useMemo(
    //merit+IF(AND(currentJobGrade=6,newJobGrade=7,tenure>=1.5,tenure<=4),gppSpending,0)
    () =>{
      if (selectedItem){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Overall Total Spending');
        // console.log('merit',parser.getVariable('merit'))
        // console.log('currentJobGrade',parser.getVariable('currentJobGrade'))
        // console.log('newJobGrade',parser.getVariable('newJobGrade'))
        // console.log('tenure',parser.getVariable('tenure'))
        // console.log('gppSpending',parser.getVariable('gppSpending'))
        let res = parser.parse(formulaToParse);
        console.log('parsed Overall Total Spending total_spending',res)
        return res.result;
    }else{
        return 0;
    }
  },
    [selectedItem]
  );

  const offcycle_budget_update = useMemo(
    () =>{
      if (selectedItem && selectedItem.new_fte_ttc_annual_salary){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Off Cycle Budget Update');
        //(newTtc-curSalary)
        console.log('newTtc new_fte_ttc_annual_salary',parser.getVariable('newTtc'))
        console.log('curSalary fte_ttc_annual_salary',parser.getVariable('curSalary'))
        let res = parser.parse(formulaToParse);
        console.log('parsed Off Cycle Budget Update', res)
        return res.result;
      }else{
          return 0;
      }
    },
    [selectedItem]
  );

  const payment_type = useMemo(
    () => {
      const activeData = data.request ? data.request : data;
      if (activeData.payment_type) return activeData.payment_type;
      let paymentType = filterPaymentTypes()[0];
      if (selectedItem && selectedItem.payment_type) {
        paymentType = selectedItem.payment_type
      }
      return paymentType;
    },
    [selectedItem, data]
  );

  const payment_reason = useMemo(
    () => {
      return selectedItem && selectedItem.payment_reason ? selectedItem.payment_reason : "Merit lump-sum";
    },
    [selectedItem]
  );

  const target_budget_org = useMemo(
    () => {
      const column = columns.find(c => c.name === "target_budget_org");
      const isAllowed = (column, account) => {
        if (column.allow) {
          return column.allow.find(role => account.roles.find(arole => arole === role));
        }
        return true;
      };
      // Not Allowed for role
      if (!column || !isAllowed(column, account)) return undefined;
      // Manual change
      const activeData = data.request ? data.request : data;
      if (activeData.target_budget_org) return activeData.target_budget_org;
      //IF(targetBudgetOrg,targetBudgetOrg,IF(orgUnit4,orgUnit4,IF(orgUnit3,orgUnit3,IF(orgUnit2,orgUnit2,IF(orgUnit1,orgUnit1,"undefined")))))
      if (selectedItem){
        if(selectedItem.target_budget_org){
         return selectedItem.target_budget_org;
        }else{
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Target Budget Org');
          //IF(targetBudgetOrg,targetBudgetOrg,IF(orgUnit4,orgUnit4,IF(orgUnit3,orgUnit3,IF(orgUnit2,orgUnit2,IF(orgUnit1,orgUnit1,"undefined")))))
          console.log('unit 4',parser.parse('IF(orgUnitFour<>"",orgUnitFour,"wow")'))
          let res = parser.parse(formulaToParse);
          console.log('parsed Target Budget Org', res)
          return (res.result=="undefined" || res.result=="null")?undefined:res.result;
        }
        
      }else{
          return undefined;
      }
    },
    [selectedItem, data, columns, account]
  );
  /**Release 2 Our Talent - starts*/
  const sip_change = useMemo(
    () => {
      if (selectedItem) {
        if (data && data.request_type == "internalhire" && data.promotion_job_family_name) {
          if(selectedItem.job_family_name === data.promotion_job_family_name  ){
            return false;
          }
          else if ((selectedItem.job_family_name == "Sales" || selectedItem.job_family_name == "Pre-Sales") || (data.promotion_job_family_name == "Pre-Sales" || data.promotion_job_family_name == "Sales" )) {
            return true;
          }

          else {
            return false;
          }
        }
        else  if (data.request && data.request_type == "internalhire" && data.request.promotion_job_family_name) {
          if(selectedItem.job_family_name === data.request.promotion_job_family_name  ){
            return false;
          }
          else if ((selectedItem.job_family_name == "Sales" || selectedItem.job_family_name == "Pre-Sales") || (data.request.promotion_job_family_name == "Pre-Sales" || data.request.promotion_job_family_name == "Sales" )) {
            return true;
          }

          else {
            return false;
          }
        }
        else if (selectedItem.sip_change) {
          return selectedItem.sip_change;
        }
      }
    },
    [selectedItem, data]
  )
  const current_combo_situation = useMemo(
    () => {
      if (selectedItem) {
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Current Combo Situation');
        //getCountriesGradeProgressions(country)&" and "&currentQuartile
        let res = parser.parse(formulaToParse);
        console.log('parsed current_combo_situation', res)
        return res.result;
      }
      else {
        return "";
      }
    },
    [selectedItem, config]
  );
  const promo_increase_pct_guideline_min = useMemo(
    () => {
      if (!selectedItem || !selectedItem.current_combo_situation) return 0.00;
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Promotional increase % Guideline MIN');
      //getIncreaseGuidelines('min',currentComboSituation)
      let res = parser.parse(formulaToParse);
      console.log('parsed promo_increase_pct_guideline_min', res)
      return res.result;
    },
    [selectedItem, config]
  );
  const promo_increase_pct_guideline_max = useMemo(
    () => {
      if (!selectedItem || !selectedItem.current_combo_situation) return 0.00;
      defineFormulaVars(); //refresh the values of formula variables
      let formulaToParse = getRequiredFormula('Promotional increase % Guideline MAX');
      //getIncreaseGuidelines('max',currentComboSituation)
      let res = parser.parse(formulaToParse);
      console.log('parsed promo_increase_pct_guideline_max', res)
      return res.result;
    },
    [selectedItem, config]
  );

  const promotional_increase_percentage_guideline = useMemo(
    () => {
      if (selectedItem) {
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Promotional increase % Guideline');
        //(promoIncreasePctGuidelineMin*100)&"% to "&(promoIncreasePctGuidelineMax*100)&"%"
        let res = parser.parse(formulaToParse);
        console.log('parsed promotional_increase_percentage_guideline', res)
        return res.result;
      }
      else {
        return "";
      }
    },
    [selectedItem]
  );
  const rr_number = useMemo(
    () => {
      if (selectedItem) {
        if (data.rr_number !== undefined) {
          return data.rr_number;
        }
        else if (data.request && data.request.rr_number !== undefined) {
          return data.request.rr_number;
        }
        else {
          return selectedItem.rr_number;
        }
      }
    },
    [selectedItem, data]
  );
  const case_3_12_month_actual_incentive_amount_fte = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.sip_change) {

          if (data.case_3_12_month_actual_incentive_amount_fte !== undefined) {
            const v = isNaN(data.case_3_12_month_actual_incentive_amount_fte) ? 0 : data.case_3_12_month_actual_incentive_amount_fte;
            return Math.abs(v);
          }
          /* Release 3.2 - 13.1 - Starts */
          else if (data.request !== undefined && data.request.case_3_12_month_actual_incentive_amount_fte !== undefined) {
            const v = isNaN(data.request.case_3_12_month_actual_incentive_amount_fte) 
            ?  0 : 
            data.request.case_3_12_month_actual_incentive_amount_fte;
            return Math.abs(v);
          }
          /* Release 3.2 - 13.1 - Ends */
          else if (selectedItem.case_3_12_month_actual_incentive_amount_fte) { return selectedItem.case_3_12_month_actual_incentive_amount_fte; }
          else {
            return 0;
          }
        }
        else {
          // return 0;
          /* Release 3.2 - 13.1 - Starts */
          let case_3_12_month_actual_incentive_amount_fte = 0;

          if (data.case_3_12_month_actual_incentive_amount_fte !== undefined) {
            case_3_12_month_actual_incentive_amount_fte = isNaN(
              data.case_3_12_month_actual_incentive_amount_fte
            )
              ? 0
              : data.case_3_12_month_actual_incentive_amount_fte;
          }
          else if (data.request !== undefined && data.request.case_3_12_month_actual_incentive_amount_fte !== undefined) {
            case_3_12_month_actual_incentive_amount_fte = isNaN(data.request.case_3_12_month_actual_incentive_amount_fte) 
            ? 0 
            : data.request.case_3_12_month_actual_incentive_amount_fte;
          }
          else if (selectedItem.case_3_12_month_actual_incentive_amount_fte) { 
            case_3_12_month_actual_incentive_amount_fte = selectedItem.case_3_12_month_actual_incentive_amount_fte; 
          }

          return Math.abs(case_3_12_month_actual_incentive_amount_fte);
          /* Release 3.2 - 13.1 - Ends */
        }
      }
    },
    [selectedItem, data]
  );
  const override_transition_allowance_local_currency = useMemo(
    () => {
      if (selectedItem) {
        if (selectedItem.sip_change) {
          if (data.override_transition_allowance_local_currency !== undefined) {
            const v = isNaN(data.override_transition_allowance_local_currency) ? 0 : data.override_transition_allowance_local_currency;
            return Math.abs(v);
          }
          else if (selectedItem.override_transition_allowance_local_currency) {
            return selectedItem.override_transition_allowance_local_currency;
          }
          else {
            return 0;
          }
        }
        else {
          return 0;
        }
      }
    },
    [selectedItem, data]
  );

  const internalhire_off_cycle_spending = useMemo(
    () => {
      if (selectedItem) {
      if(data.request_type == "internalhire"){
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Amount tracked against off cycle budget (Local currency)');
        //IF(promoted=true,IF(ttc_increase_pct<=(promoIncreasePctGuidelineMax*100),0,IF(ttc_increase_pct>(promoIncreasePctGuidelineMax*100),((ttc_increase_pct-(promoIncreasePctGuidelineMax*100))/100)*curSalary,0)),IF(newTtc>0,newTtc-curSalary,0))
        // console.log('promoted',parser.getVariable('promoted'))
        // console.log('ttc_increase_pct',parser.getVariable('ttc_increase_pct'))
        // console.log('promoIncreasePctGuidelineMax',parser.getVariable('promoIncreasePctGuidelineMax'))
        // console.log('curSalary',parser.getVariable('curSalary'))
        let res = parser.parse(formulaToParse);
        console.log('parsed internalhire_off_cycle_spending', res)
        return res.result;
      } 
    }
    },
    [selectedItem, data]
  )
  const internal_hire_off_cycle_spending_euro = useMemo(
    () => {
      if (selectedItem) {
        if (!selectedItem.offcycle_budget_update) { return 0; }
        defineFormulaVars(); //refresh the values of formula variables
        console.log('offCycleSpending',parser.getVariable('offCycleSpending'))
        console.log('exchangeRate',parser.getVariable('exchangeRate'))
        let formulaToParse = getRequiredFormula('Amount tracked against off cycle budget EURO');
        //IF(exchangeRate<>null,offCycleSpending*exchangeRate,0)
        let res = parser.parse(formulaToParse);
        console.log('parsed internal_hire_off_cycle_spending_euro', res)
        return res.result;
      }
    },
    [selectedItem]
  );

  const is_sip = useMemo(
    () => {
      if (selectedItem) {
        if (selectedItem.promotion_job_family_name) {
          if ((selectedItem.promotion_job_family_name !== 'Sales' && selectedItem.promotion_job_family_name !== 'Pre-Sales') && (selectedItem.job_family_name !== 'Sales' && selectedItem.job_family_name !== "Pre-Sales")) {
            return false;
          }
          else if(selectedItem.promotion_job_family_name == selectedItem.job_family_name) {
            return false;
          }
          else{
            return true;
          }
        }
        else {
          return false;
        }
      }
    },
    [selectedItem]
  );

  const Max_Promotional_Increase = useMemo(
    () => {
      if(selectedItem){
        //return selectedItem.promoted ? selectedItem.promo_increase_pct_guideline_max:0;
        //IF(promoted=true,promoIncreasePctGuidelineMax,0)
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Max Promotional Increase');
        let res = parser.parse(formulaToParse);
        console.log('parsed Max_Promotional_Increase', res)
        return res.result;
      }

    },
    [selectedItem]
  )

  const Case_One_Max_Budget_calc = useMemo(
    () => {
      
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 1 Max Budget calc');
          //IF(IF(newTheoItp=100,currentFteAbs*0.8+(currentFteAbs*0.8*(newTheoItp/100))-curSalary,(currentFteAbs*0.9)+(currentFteAbs*0.9*(newTheoItp/100))-curSalary)<=0,0,IF(newTheoItp=100,currentFteAbs*0.8+(currentFteAbs*0.8*(newTheoItp/100))-curSalary,(currentFteAbs*0.9)+(currentFteAbs*0.9*(newTheoItp/100))-curSalary))
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_One_Max_Budget_calc', res)
          return res.result;
        }else{
          return 0;
        }
      }
      
        //IF(IF(newTheoItp=100,currentFteAbs*0.8+(currentFteAbs*0.8*(newTheoItp/100))-curSalary,(currentFteAbs*0.9)+(currentFteAbs*0.9*(newTheoItp/100))-curSalary)<=0,0,IF(newTheoItp=100,currentFteAbs*0.8+(currentFteAbs*0.8*(newTheoItp/100))-curSalary,(currentFteAbs*0.9)+(currentFteAbs*0.9*(newTheoItp/100))-curSalary))
        

    },
    [selectedItem]
  );
  const Case_one_TTC_increase_minus_Max_budget = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 1 TTC increase minus Max budget');
          //merit-CaseOneMaxBudgetcalc
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_one_TTC_increase_minus_Max_budget', res)
          return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );
  const Case_one_Budget_charge = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 1 Budget charge');
          //IF(CaseOneTTCIncreaseMinusMaxBudget<=0,0,CaseOneTTCIncreaseMinusMaxBudget)
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_one_Budget_charge', res)
          return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );
  const Case_two_TI_Increase = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 2 TI Increase');
          //IF(CaseOneMaxBudgetcalc<>null,CaseOneMaxBudgetcalc,0)
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_two_TI_Increase', res)
          return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );

  const Case_two_Promotion_Budget = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
        defineFormulaVars(); //refresh the values of formula variables
        let formulaToParse = getRequiredFormula('Case 2 Promotion Budget');
        //IF(promoted=true,curSalary*promoIncreasePctGuidelineMax,0)
        let res = parser.parse(formulaToParse);
        console.log('parsed Case_two_Promotion_Budget', res)
        return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );

  const Case_two_Total_budget = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 2 Total budget');
          //caseTwoTIIncrease+caseTwoPromotionBudget
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_two_Total_budget', res)
          return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );

  const Case_two_Budget_charge = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 2 Budget charge');
          //IF(merit-caseTwoTotalBudget<=0,0,merit-caseTwoTotalBudget)
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_two_Budget_charge', res)
          return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );

  const Case_three_twelve_month_Total_Target_Cash = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 3 -12 month Total Target Cash');
          //currentFteAbs+caseThreeTwelveMonthActualIncentiveAmountPaid
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_three_twelve_month_Total_Target_Cash', res)
          return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );

  const Case_three_new_TTC_Gap_to_twelve_month_TTC = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 3 new TTC Gap to -12month TTC');
          //caseThreeTwelveMonthTotalTargetCash-newTtc
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_three_new_TTC_Gap_to_twelve_month_TTC', res)
          return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );

  const Case_three_Max_capped_transit_allowance = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 3 Max capped transit allowance');
          //caseThreeTwelveMonthActualIncentiveAmountPaid*0.5
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_three_Max_capped_transit_allowance', res)
          return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );
  const Case_three_Transition_allowance_total = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 3 Transition allowance total');
          //IF(caseThreeNewTTCGapToTwelveMonthTTC>0,MIN(caseThreeMaxCappedTransitAllowance,caseThreeNewTTCGapToTwelveMonthTTC),0)
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_three_Transition_allowance_total', res)
          return res.result;
        }else{
          return 0;
        }
      }

    },
    [selectedItem]
  );


  const Case_three_Budget_charge = useMemo(
    () => {
      if (selectedItem) {

        if (selectedItem.is_sip) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Case 3 Budget charge');
          //IF(IF(OverrideTransitionAllowanceLocalCurrency<>null,OverrideTransitionAllowanceLocalCurrency+(newTtc-curSalary-(currentFteAbs*maxPromoIncrease)), caseThreeTransitionAllowanceTotal+( newTtc-curSalary - (currentFteAbs*maxPromoIncrease)))<=0,0,IF(OverrideTransitionAllowanceLocalCurrency<>null,OverrideTransitionAllowanceLocalCurrency+(newTtc-curSalary-(currentFteAbs*maxPromoIncrease)), caseThreeTransitionAllowanceTotal+( newTtc-curSalary - (currentFteAbs*maxPromoIncrease))))
          let res = parser.parse(formulaToParse);
          console.log('parsed Case_three_Budget_charge', res)
          return res.result;
        }else{
          return 0;
        }

      }

    },
    [selectedItem]
  );
  const sip_case = useMemo(() => {
    if (selectedItem) {
      const currentJobFamily = selectedItem.job_family_name != "Pre-Sales" && selectedItem.job_family_name != "Sales" ? "Non-Sales" : selectedItem.job_family_name;
      const promo_status = selectedItem.promoted ? "YES" : "NO";
      const targetjobfamily = selectedItem.promotion_job_family_name != "Pre-Sales" && selectedItem.promotion_job_family_name != "Sales" ? "Non-Sales" : selectedItem.promotion_job_family_name;
      if (selectedItem.promotion_job_family_name) {
        return currentJobFamily + " to " + targetjobfamily + " Promo " + promo_status;
      }
      else {
        return currentJobFamily + " to " + currentJobFamily + " Promo " + promo_status;
      }
    }
  },
    [selectedItem])
  const Final_Off_Cycle_Budget = useMemo(
    () => {
      if (selectedItem) {
        if (selectedItem.sip_case) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Final Off-Cycle Budget');
          //IF(sipCase="Non-Sales to Sales Promo NO",IF(caseOneBudgetCharge<=0,0,caseOneBudgetCharge), IF(sipCase="Non-Sales to Pre-Sales Promo NO",IF(caseOneBudgetCharge<=0,0,caseOneBudgetCharge), IF(sipCase="Non-Sales to Sales Promo YES",IF(caseTwoBudgetCharge<=0,0,caseTwoBudgetCharge), IF(sipCase="Non-Sales to Pre-Sales Promo YES",IF(caseTwoBudgetCharge<=0,0,caseTwoBudgetCharge), IF(sipCase="Sales to Non-Sales Promo NO",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge), IF(sipCase="Sales to Non-Sales Promo YES",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge), IF(sipCase="Pre-Sales to Non-Sales Promo NO",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge), IF(sipCase="Pre-Sales to Non-Sales Promo YES",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge), IF(sipCase="Pre-Sales to Sales Promo YES",IF(caseTwoBudgetCharge<=0,0,caseTwoBudgetCharge), IF(sipCase="Pre-Sales to Sales Promo NO",IF(caseOneBudgetCharge<=0,0,caseOneBudgetCharge), IF(sipCase="Sales to Pre-Sales Promo YES",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge), IF(sipCase="Sales to Pre-Sales Promo NO",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge), IF(sipCase="Sales to Pre-Sales Promo NO",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge), offCycleSpending)))))))))))))
          let res = parser.parse(formulaToParse);
          console.log('parsed Final_Off_Cycle_Budget', res)
          return res.result;          
        }
        else { return ""; }
      }
    },
    [selectedItem]
  );
  const Final_Off_Cycle_Budget_euro = useMemo(
    () => {
      if (selectedItem) {
        if (selectedItem.sip_case) {
          defineFormulaVars(); //refresh the values of formula variables
          let formulaToParse = getRequiredFormula('Final Off-Cycle Budget € (Depending on the case)');

          //IF(sipCase="Non-Sales to Sales Promo NO",IF(caseOneBudgetCharge<=0,0,caseOneBudgetCharge*exchangeRate), IF(sipCase="Non-Sales to Pre-Sales Promo NO",IF(caseOneBudgetCharge<=0,0,caseOneBudgetCharge*exchangeRate), IF(sipCase="Non-Sales to Sales Promo YES",IF(caseTwoBudgetCharge<=0,0,caseTwoBudgetCharge*exchangeRate), IF(sipCase="Non-Sales to Pre-Sales Promo YES",IF(caseTwoBudgetCharge<=0,0,caseTwoBudgetCharge*exchangeRate), IF(sipCase="Sales to Non-Sales Promo NO",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge*exchangeRate), IF(sipCase="Sales to Non-Sales Promo YES",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge*exchangeRate), IF(sipCase="Pre-Sales to Non-Sales Promo NO",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge*exchangeRate), IF(sipCase="Pre-Sales to Non-Sales Promo YES",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge*exchangeRate), IF(sipCase="Pre-Sales to Sales Promo YES",IF(caseTwoBudgetCharge<=0,0,caseTwoBudgetCharge*exchangeRate), IF(sipCase="Pre-Sales to Sales Promo NO",IF(caseOneBudgetCharge<=0,0,caseOneBudgetCharge*exchangeRate), IF(sipCase="Sales to Pre-Sales Promo YES",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge*exchangeRate), IF(sipCase="Sales to Pre-Sales Promo NO",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge*exchangeRate), IF(sipCase="Sales to Pre-Sales Promo NO",IF(caseThreeBudgetCharge<=0,0,caseThreeBudgetCharge*exchangeRate), offCycleSpendingInEUR)))))))))))))
          console.log('offCycleSpendingInEUR', parser.getVariable('offCycleSpendingInEUR'))
          let res = parser.parse(formulaToParse);
          console.log('parsed Final_Off_Cycle_Budget EUR', res)
          return res.result; 
        }
        else { return ""; }
      }
    },
    [selectedItem]
  );
  const internalhire_increase_amount   = useMemo(
    () => {
      return selectedItem ? selectedItem.ttc_increase_amount : 0;
     },
     [selectedItem]
  )
  const internalhire_increase_percentage   = useMemo(
    () => {
      return selectedItem ? selectedItem.ttc_increase_percentage : 0;
     },
     [selectedItem]
  )
  const hiring_manager = useMemo(
    () => {
      if(selectedItem){
        const ActiveData= data.request ? data.request : data;
        return ActiveData.hiringmanager ? ActiveData.hiringmanager : ( selectedItem.hiring_manager ? selectedItem.hiring_manager : ""); 

      }
    },
    [selectedItem, data]
  )
  /* Release 3.2 - 13.1 - Starts */
  const sum_of_pay_amount = useMemo(() => {
    if (selectedItem) {
      let case_3_12_month_actual_incentive_amount_fte = 0;
      let addl_pay_amount = 0;
      let sum_of_pay_amount = 0;

      if (data.case_3_12_month_actual_incentive_amount_fte !== undefined) {
        case_3_12_month_actual_incentive_amount_fte = isNaN(
          data.case_3_12_month_actual_incentive_amount_fte
        )
          ? 0
          : data.case_3_12_month_actual_incentive_amount_fte;
      }
      else if (data.request !== undefined && data.request.case_3_12_month_actual_incentive_amount_fte !== undefined) {
        case_3_12_month_actual_incentive_amount_fte = isNaN(data.request.case_3_12_month_actual_incentive_amount_fte) 
        ?  0 : 
        data.request.case_3_12_month_actual_incentive_amount_fte;
        
      }
      else if (selectedItem.case_3_12_month_actual_incentive_amount_fte) { 
        case_3_12_month_actual_incentive_amount_fte = selectedItem.case_3_12_month_actual_incentive_amount_fte; 
      }
      if (selectedItem.lastYearWageHistory !== undefined && selectedItem.lastYearWageHistory !== null && selectedItem.lastYearWageHistory && selectedItem.lastYearWageHistory.addl_pay_amount) {
        addl_pay_amount = isNaN(selectedItem.lastYearWageHistory.addl_pay_amount)
            ? 0
            : selectedItem.lastYearWageHistory.addl_pay_amount;
      }
      else if (selectedItem.request !== undefined && selectedItem.request !== null && selectedItem.request.lastYearWageHistory !== undefined && selectedItem.request.lastYearWageHistory && selectedItem.request.lastYearWageHistory.addl_pay_amount) {
        addl_pay_amount = isNaN(selectedItem.request.lastYearWageHistory.addl_pay_amount)
            ? 0
            : selectedItem.request.lastYearWageHistory.addl_pay_amount;
      }

      sum_of_pay_amount = case_3_12_month_actual_incentive_amount_fte + addl_pay_amount;

      // console.log("useMemo sum_of_pay_amount selectedItem", selectedItem);
      // console.log("useMemo sum_of_pay_amount case_3_12_month_actual_incentive_amount_fte", case_3_12_month_actual_incentive_amount_fte);
      // console.log("useMemo sum_of_pay_amount addl_pay_amount", addl_pay_amount);
      // console.log("useMemo sum_of_pay_amount sum_of_pay_amount", sum_of_pay_amount);

      return Math.abs(sum_of_pay_amount);
    }
  }, [selectedItem, data]
  );
  /* Release 3.2 - 13.1 - Ends */

  const calcData = useMemo(
    () => {
      return {
        salary_range_spread,
        exchange_rate,
        tenure,
        payment_type,
        payment_reason,
        is_gpp_eligible,
        ttc_increase_amount,
        ttc_increase_percentage,
        gpp_increase_amount,
        gpp_increase_percentage,
        gpp_guideline_max,
        promotion_job_family_name,
        promotion_job_subfamily_name,
        promotion_job_name,
        promotion_pay_grade,
        promotion_job_grade,
        promoted,
        demoted,
        promotion_job_grade_delta,
        promotion_incentive_target_percentage,
        mandatory_ttc_increase_percentage,
        mandatory_ttc_increase_amount,
        apply_ti_harmonization,
        interim_harmonization_type,
        interim_job_family_name,
        interim_job_grade,
        interim_pay_grade,
        interim_salary_range_min,
        interim_salary_range_mid,
        interim_salary_range_max,
        interim_salary_range_spread,
        interim_fte_incentive_target_amount,
        interim_incentive_target_percentage,
        interim_total_increase_amount,
        interim_increase_amount_for_salincr,
        interim_increase_amount_for_tih,
        step_1_abs,
        step_1_ita,
        step_1_itp,
        interim_theo_itp,
        interim_itp_situation,
        step_2_abs_gap,
        step_2_ita_gap,
        interim_remainder_for_split,
        new_fte_annual_salary,
        new_fte_incentive_target_amount,
        new_incentive_target_percentage,
        new_fte_ttc_annual_salary,
        new_itp_situation,
        new_ttc_check,
        new_ttc_compa_ratio,
        new_quartile,
        new_incentive_plan_type,
        individual_budget_amount,
        individual_spending_amount,
        individual_balance_amount,
        gpp_budget,
        gpp_spending,
        gpp_balance,
        total_spending,
        offcycle_budget_update,
        override_fte_incentive_target_percentage,
        override_fte_ttc_annual_salary,
        target_budget_org,
        new_position_title,
        ttc_increase,
        ttc_increase_perc,
        /**Release 2 our talent */
        current_combo_situation,
        promo_increase_pct_guideline_min,
        promo_increase_pct_guideline_max,
        promotional_increase_percentage_guideline,
        rr_number,
        case_3_12_month_actual_incentive_amount_fte,
        override_transition_allowance_local_currency,
        internalhire_off_cycle_spending,
        internal_hire_off_cycle_spending_euro,
        is_sip,
        Case_One_Max_Budget_calc,
        Case_one_TTC_increase_minus_Max_budget,
        Case_one_Budget_charge,
        Case_two_TI_Increase,
        Case_two_Promotion_Budget,
        Case_two_Total_budget,
        Case_two_Budget_charge,
        Case_three_twelve_month_Total_Target_Cash,
        Case_three_new_TTC_Gap_to_twelve_month_TTC,
        Case_three_Max_capped_transit_allowance,
        Case_three_Transition_allowance_total,
        Case_three_Budget_charge,
        sip_case,
        Final_Off_Cycle_Budget,
        Final_Off_Cycle_Budget_euro,
        sip_change,
        internalhire_increase_amount,
        internalhire_increase_percentage,
        hiring_manager,
        Max_Promotional_Increase,
        /* Release 3.2 - 13.1 - Starts */
        sum_of_pay_amount,
        /* Release 3.2 - 13.1 - Ends */
      };
    },
    [
      salary_range_spread,
      exchange_rate,
      tenure,
      payment_type,
      payment_reason,
      is_gpp_eligible,
      ttc_increase_amount,
      ttc_increase_percentage,
      gpp_increase_amount,
      gpp_increase_percentage,
      gpp_guideline_max,
      promotion_job_family_name,
      promotion_job_subfamily_name,
      promotion_job_name,
      promotion_pay_grade,
      promotion_job_grade,
      promoted,
      demoted,
      promotion_job_grade_delta,
      promotion_incentive_target_percentage,
      mandatory_ttc_increase_percentage,
      mandatory_ttc_increase_amount,
      apply_ti_harmonization,
      interim_harmonization_type,
      interim_job_family_name,
      interim_job_grade,
      interim_pay_grade,
      interim_salary_range_min,
      interim_salary_range_mid,
      interim_salary_range_max,
      interim_salary_range_spread,
      interim_fte_incentive_target_amount,
      interim_incentive_target_percentage,
      interim_total_increase_amount,
      interim_increase_amount_for_salincr,
      interim_increase_amount_for_tih,
      step_1_abs,
      step_1_ita,
      step_1_itp,
      interim_theo_itp,
      interim_itp_situation,
      step_2_abs_gap,
      step_2_ita_gap,
      interim_remainder_for_split,
      new_fte_annual_salary,
      new_fte_incentive_target_amount,
      new_incentive_target_percentage,
      new_fte_ttc_annual_salary,
      new_itp_situation,
      new_ttc_check,
      new_ttc_compa_ratio,
      new_quartile,
      new_incentive_plan_type,
      individual_budget_amount,
      individual_spending_amount,
      individual_balance_amount,
      gpp_budget,
      gpp_spending,
      gpp_balance,
      total_spending,
      offcycle_budget_update,
      override_fte_incentive_target_percentage,
      override_fte_ttc_annual_salary,
      target_budget_org,
      new_position_title,
      ttc_increase,
      ttc_increase_perc,
      /**Release 2 our talent */
      current_combo_situation,
      promo_increase_pct_guideline_min,
      promo_increase_pct_guideline_max,
      promotional_increase_percentage_guideline,
      rr_number,
      case_3_12_month_actual_incentive_amount_fte,
      override_transition_allowance_local_currency,
      internalhire_off_cycle_spending,
      internal_hire_off_cycle_spending_euro,
      is_sip,
      Case_One_Max_Budget_calc,
      Case_one_TTC_increase_minus_Max_budget,
      Case_one_Budget_charge,
      Case_two_TI_Increase,
      Case_two_Promotion_Budget,
      Case_two_Total_budget,
      Case_two_Budget_charge,
      Case_three_twelve_month_Total_Target_Cash,
      Case_three_new_TTC_Gap_to_twelve_month_TTC,
      Case_three_Max_capped_transit_allowance,
      Case_three_Transition_allowance_total,
      Case_three_Budget_charge,
      sip_case,
      Final_Off_Cycle_Budget,
      Final_Off_Cycle_Budget_euro,
      sip_change,
      internalhire_increase_amount,
      internalhire_increase_percentage,
      hiring_manager,
      Max_Promotional_Increase,
      /* Release 3.2 - 13.1 - Starts */
      sum_of_pay_amount,
      /* Release 3.2 - 13.1 - Ends */
    ]
  );

  /* added below code for resolution for defect #16 and #17--START */

  function isNegative(n) {
    return ((n = +n) || 1 / n) < 0;
  }

  function UpdateN() {

    let ttc_increase_amount = document.querySelectorAll(
      'input[name="ttc_increase_amount"]'
    );
    let ttc_increase_percentage = document.querySelectorAll(
      'input[name="ttc_increase_percentage"]'
    );
    /**Release 2 Our Talent - scenarios change starts */
    let new_fte_annual_salary = document.querySelectorAll(
      'input[name="new_fte_annual_salary"]'
    );
    let new_fte_incentive_target_amount = document.querySelectorAll(
      'input[name="new_fte_incentive_target_amount"]'
    );

    /**Release 2 Our Talent - scenarios change Ends */
    ttc_increase_amount.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    ttc_increase_percentage.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    /**Release 2 Our Talent - scenarios change starts */
    new_fte_annual_salary.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    new_fte_incentive_target_amount.forEach((e) => {
      let value = e.value;
      if (isNegative(value) || value === 0 || !value || value === "") {
        e.value = "0.00";
      }
    });
    /**Release 2 Our Talent - Scenarios Change Ends */
  }

  /* code for resolution for defect #16 and #17--END */


  const updateEmployeeData = useCallback(
    () => {
      if (Object.keys(data).length) {
        const updateData = recordType === 'employees'
          ? { ...data, ...calcData }
          : { request: { ...data.request, ...calcData } };
        updateRecord(item, indexColumn, updateData, recordType);
      }
      /* added below code for resolution for defect #16 and #17--START */
      setTimeout(() => {
        UpdateN();
      }, 300);
      /* code for resolution for defect #16 and #17--END */
    },
    [data, calcData, item, indexColumn, updateRecord, recordType]
  );

  useEffect(() => {
    updateEmployeeData(data, calcData);

  }, [data, calcData, updateEmployeeData]);

  return null;
};

const mapStateToProps = state => {
  return {
    selectedEmployees: state.selectedEmployees,
    selectedRequests: state.selectedRequests,
    config: state.config,
    account: state.account,
    formulas: state.formulas
  };
};

export default connect(
  mapStateToProps,
  { updateRecord }
)(CalculatorNew);
