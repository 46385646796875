import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { Ring } from 'react-awesome-spinners';

import { DatePickerField, SelectedTable, CalculatorNew, FormButton, FormButtonsWrap, FormLabel, FormLabelInline, FormHeading, DescriptionInput, LoaderWrap, ErrorsBlock, IconWrap } from '../';
import { columns } from '../../util/config';
import { unselectAllRecord, pushMessage } from '../../actions';
import { EDIT_REQUEST, requests } from '../../graphql';

var textareavaluecheck = null;

const EditInternalHire = props => {
    const { selectedRequests, account, hideModal, unselectAllRecord, pushMessage } = props;
   console.log(selectedRequests);
    const queryFilter = { requester_pers_no: { eq: account.pers_no } };
  
    const [updatedItem, setUpdatedItem] = useState('');
    const [updateData, setUpdateData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitErrors, setSubmitErrors] = useState([]);
  
    /*  Add New requestDescription TO FIX DEFECT 11 STARTS */ 
    const [requestDescription, setRequestDescription] = useState('');
    /*  Add New requestDescription TO FIX DEFECT 11 ENDS */ 
  
    const [editRequest] = useMutation(EDIT_REQUEST);
   
    const update = (item, data) => {
      if (updatedItem !== item) {
        setUpdatedItem(item);
      }
      setUpdateData(data);
    };
  
    /*  ADD FUNCTION TO FIX DEFECT #16, #17 STARTS */ 
    function isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
    function isNegative(n) {
      return ((n = +n) || 1 / n) < 0;
    }
    function UpdateN() {
      let ttc_increase_amount = document.querySelectorAll(
        'input[name="request.ttc_increase_amount"]'
      );
      let ttc_increase_percentage = document.querySelectorAll(
        'input[name="request.ttc_increase_percentage"]'
      );
         /**Release 2 Our Talent - scenarios change starts */
    let new_fte_annual_salary = document.querySelectorAll(
        'input[name="request.new_fte_annual_salary"]'
      );
      let new_fte_incentive_target_amount = document.querySelectorAll(
        'input[name="request.new_fte_incentive_target_amount"]'
      );
  
      /**Release 2 Our Talent - scenarios change Ends */
      ttc_increase_amount.forEach((e) => {
        let value = e.value;
        if (isNegative(value) || value === 0 || !value || value === "") {
          e.value = "0.00";
        }
      });
      ttc_increase_percentage.forEach((e) => {
        let value = e.value;
        if (isNegative(value) || value === 0 || !value || value === "") {
          e.value = "0.00";
        }
      });
          /**Release 2 Our Talent - scenarios change starts */
    new_fte_annual_salary.forEach((e) => {
        let value = e.value;
        if (isNegative(value) || value === 0 || !value || value === "") {
          e.value = "0.00";
        }
      });
      new_fte_incentive_target_amount.forEach((e) => {
        let value = e.value;
        if (isNegative(value) || value === 0 || !value || value === "") {
          e.value = "0.00";
        }
      });
      /**Release 2 Our Talent - Scenarios Change Ends */
    }
    /*  ADD FUNCTION TO FIX DEFECT #16, #17 ENDS  */ 
  
    /*  COMMENTED FUNCTION TO FIX DEFECT #16, #17 STARTS */ 
    /*const handleChange = args => event => {
      const { column, columns, item } = args;
      const indexColumn = columns.find(c => c.index);
      let value = column.inputType === 'checkbox' ? event.target.checked : event.target.value;
      value = column.type === 'float' ? parseFloat(value) : value
      const valueObject = column.name.split('.').reduceRight((a,c) => { return { [c]: a }; }, value);
      update(item[indexColumn.name], valueObject);
    };*/
  
    /*  COMMENTED FUNCTION TO FIX DEFECT #16, #17 ENDS */ 
  
    /*  ADD NEW CUSTOMIZED FUNCTION TO FIX DEFECT #16, #17 STARTS */ 
    const handleChange = (args) => (event) => {
      const { column, columns, item } = args;
      const indexColumn = columns.find((c) => c.index);
  
      let value = "";
      if (column.inputType !== "peoplepicker") {
        value =
          column.inputType === "checkbox"
            ? event.target.checked
            : event.target.value;
      }
      else {
        value = event.value;
      }
  
      value = (column.inputType !== "checkbox" && parseInt(value)) < 0 ? 0 : value;
  
      value = column.type === "float" ? parseFloat(value) : value;
  
      const valueObject = column.name.split('.').reduceRight((a,c) => { return { [c]: a }; }, value);
      valueObject.request_type = "internalhire";
      update(item[indexColumn.name], valueObject);
      setTimeout(() => {
        UpdateN();
      }, 100);
    };
    /*  ADD NEW CUSTOMIZED FUNCTION TO FIX DEFECT #16, #17 ENDS */ 
    
    // /*  commented code  New handleDescriptionChange TO FIX DEFECT 11 STARTS */ 
    // const handleDescriptionChange = event => setRequestDescription(event.target.value);
  
    /*  Add New handleDescriptionChange TO FIX DEFECT 11 STARTS */ 
   //issue fixed code start number 11
    const handleDescriptionChange = event => {
      textareavaluecheck=event.target.value;
      setRequestDescription(event.target.value);
      setTimeout(() => {
        UpdateN();
      }, 100);
    };
   //issue fixed code end number 11
  
    const handleQuit = event => {
      hideModal();
      unselectAllRecord('requests');
    };
  
    const selectedToEditRequestInput = selectedRequest => {
      const copy = {...selectedRequest};
      delete copy.employee_request_id;
      delete copy.request_type;
      delete copy.reject_comments;
      delete copy.upd_ins_ts;
      delete copy.created_ts;
      delete copy.for;
      delete copy.by;
      delete copy.approvals;
      delete copy.__typename;
      delete copy.salary_range_spread;
      delete copy.exchange_rate;
      delete copy.tenure;
      delete copy.gpp_guideline_max;
      delete copy.interim_harmonization_type;
      delete copy.interim_salary_range_mid;
      delete copy.interim_salary_range_spread;
      delete copy.new_ttc_check;
      // delete copy.new_ttc_compa_ratio;
      delete copy.individual_budget_amount;
      delete copy.individual_spending_amount;
      delete copy.individual_balance_amount;
      delete copy.gpp_budget;
      delete copy.gpp_spending;
      delete copy.gpp_balance;
      delete copy.total_spending;
      delete copy.override_fte_incentive_target_percentage;
      delete copy.override_fte_ttc_annual_salary;
      
      delete copy.interim_pay_grade;
      // delete copy.promotion_pay_grade;
      delete copy.comments
      delete copy.payment_date;
      delete copy.effective_date;
      delete copy.payment_reason;
      delete copy.budget_allocations
  
      /*  ADD/COPY FIELDS TO FIX DEFECT 11 STARTS */ 
      // delete copy.comments
      // delete copy.payment_date;
      // delete copy.effective_date;
      // delete copy.payment_reason;
      // delete copy.budget_allocations;
      delete copy.promoted;
      delete copy.demoted;
      delete copy.is_gpp_eligible;
      delete copy.ttc_increase;
      delete copy.ttc_increase_perc;
  
     // copy.request_description = requestDescription;
     delete copy.Final_Off_Cycle_Budget_euro;
     delete copy.sip_change;
     delete copy.internalhire_increase_amount;
     delete copy.internalhire_increase_percentage;
     delete copy.Max_Promotional_Increase;
     delete copy.hmanager;
     delete copy.hiringmanager;
      
  
      if(textareavaluecheck!='' && textareavaluecheck!=null){
        copy.request_description = requestDescription;
    }else{
        copy.request_description =selectedRequests[0].request.comments[selectedRequests[0].request.comments.length - 1].description ;
    }
  
      /*  ADD/COPY FIELDS TO FIX DEFECT 11 ENDS */ 
      copy.status = 'open';
  
      /* Release 3.1 - Starts */      
     delete copy.changeApprovers;
      /* Release 3.1 - Ends */
     /* Release 3.2 - 13.1 - Starts */ 
     delete copy.lastYearWageHistory;
     delete copy.sum_of_pay_amount;
     /* Release 3.2 - 13.1 - Ends */
     /* Release 3.2 - 1.1 - Starts */ 
     delete copy.reminder_info;
     delete copy.award_title;
     delete copy.award_message;
     delete copy.award_privacy;
     delete copy.award_reason;
     delete copy.nominator_email_address;
     /* Release 3.2 - 1.1 - Ends */
      return copy;
    };
  
    const handleEdit = async event => {
      setIsSubmitting(true);
      setSubmitErrors([]);
  
      /*  ADD CONDITION FIELDS TO FIX DEFECT 11 STARTS */ 
      if (textareavaluecheck=='') {
        setSubmitErrors(['Reason is required!']);
        setIsSubmitting(false);
        return null;
      }
      /*  ADD CONDITION FIELDS TO FIX DEFECT 11 ENDS */ 
      
      const results = await Promise.all(selectedRequests.map(async ({
          employee_request_id,
          request
        }) => {
        try {
          const copyRequst = selectedToEditRequestInput(request);
          await editRequest({
            variables: { employee_request_id, data: copyRequst },
            awaitRefetchQueries: true,
            refetchQueries: [
              { query: requests, variables: {
                  // TODO - get this from MyRequests.js state 
                  limit: 20,
                  offset: 0,
                  orderBy: [ { column: "upd_ins_ts", order: "DESC" } ],
                  where: queryFilter
              }}
            ]
          });
          
          return {[employee_request_id]: false};
        } catch(e) {
          return {[employee_request_id]: e.message};
        } 
      }));
    
      const errors = results.filter(r => Object.keys(r).find(p_no => r[p_no]));
      if (!errors.length) {
        hideModal();
        unselectAllRecord('requests');
        pushMessage({
          type: 'success',
          message: 'Request(s) successfully updated!'
        })
      } else {
        setSubmitErrors(errors.map(e => Object.values(e).shift()));
        setIsSubmitting(false);
      }
    };
  
    return (
      <div>
        <FormHeading>Edit requests</FormHeading>
        <FormLabel>Selected requests: {selectedRequests.length}</FormLabel>
        <div style={{ "overflowX": "scroll" }}>
        <SelectedTable
          recordType="requests"
          items={selectedRequests}
          columns={columns.editInternalHire}
          onChange={handleChange}
           /**Release 2 - Our Talent scenario */
           changeData={updateData}
        />
        </div>
        {/*  ADD FORM FIELD FIELDS TO FIX DEFECT 11 STARTS */ }
        <FormLabel>Reason for request:
          <i style={{'width':'100%','float':'left'}}>[Please do not use the Reason field for Backdated changes dates as this field is visible only for approvers and not updating CoreHR System!]</i>
        </FormLabel>
        <DescriptionInput
          name='request_description'
          onChange={handleDescriptionChange} 
          onKeyPress={isNumber}
          defaultValue ={selectedRequests[0].request.comments[selectedRequests[0].request.comments.length - 1].description}
        />
        {/*  ADD FORM FIELD FIELDS TO FIX DEFECT 11 ENDS */ }
  
        {submitErrors.length > 0 && submitErrors.map(err => {
          return (
            <ErrorsBlock key={Math.random()}>
              <IconWrap>!</IconWrap>
              {err}
            </ErrorsBlock>
          );
        })}
        <FormButtonsWrap>
          <FormButton onClick={handleQuit}>Quit</FormButton>
          <FormButton primary disabled={isSubmitting} onClick={handleEdit}>Submit</FormButton>
          {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
        </FormButtonsWrap>
        <CalculatorNew
          columns={columns.editInternalHire}
          recordType="requests"
          item={updatedItem}
          data={updateData}
        />
      </div>
    );
  };
  
  const mapStateToProps = state => {
    return {
      selectedRequests: state.selectedRequests,
      account: state.account
    };
  };
  
  export default connect(
    mapStateToProps,
    { unselectAllRecord, pushMessage }
  )(EditInternalHire);
  