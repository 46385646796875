import React, {Fragment,  useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Ring } from 'react-awesome-spinners';
import { connect } from 'react-redux';
import { SelectedTable, Calculator, FormButton, FormButtonsWrap, ErrorsBlock, LoaderWrap, FormLabel, FormHeading, IconWrap, TableHeadingWrap, TableAction } from '../';
import { columns } from '../../util/config';
import { unselectAllRecord, pushMessage } from '../../actions';
import { EDIT_DELEGATION } from '../../graphql';

const RevokeDelegate = props => {
  
  const { selectedDelegates, account, hideModal, unselectAllRecord, pushMessage } = props;
 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);

  const [editDelegation] = useMutation(EDIT_DELEGATION);
  
  const responseData = selectedDelegates;
 
  //console.log(MyDelegates.TableHeadingWrap);
  const handleQuit = event => {
    hideModal();
    //setTableQuery = 'delegatesForManager';
    
    //myVariable = 'delegatesForManager';
    unselectAllRecord('delegates');
    //unselectAllRecord('delegates');
  };
  //useEffect(() => { unselectAllRecord('delegates'); }, [unselectAllRecord]);
  const handleRevokeSend = async event => {
    setIsSubmitting(true);
    setSubmitErrors([]);
    let revokedIDs = [];
    let foundedError = [];
    let errorDuringProcess = '';
    var selectedIDsStr = '';
    const results = await Promise.all(selectedDelegates.map(async ({
        id,
        pers_no,
        is_active,
        delegation_pers_no
      }) => {
        try {
          const revokeResult = await editDelegation({
            variables: { 
              "id":id,
              data: 
              {
                id: id, 
                pers_no: pers_no.pers_no,
                is_active: is_active 
              }
            },
            awaitRefetchQueries: false,
          });
          if(revokeResult)
          {
            revokedIDs[id] = {
              'revoked_record_id' : id, 
              'revoked_record' : delegation_pers_no
            };
          }


        //return {[id]: false};
      } catch(e) {
        //return {[id]: e.message};
        if(e.message != '')
        {
          errorDuringProcess = 'Yes';
          foundedError[id] = {'revoked_record' :delegation_pers_no, 'message' : e.message};
          //setSubmitErrors([e.message]);
          //setIsSubmitting(false);
          //return false;
        }
      } 
      selectedIDsStr += id+',';
    }));
    
    if(errorDuringProcess == '' && foundedError.length == 0)
    {
      hideModal();
      unselectAllRecord('delegates')
      pushMessage({
        type: 'success',
        message: 'Selected assignee(s) successfully revoked!'
      })
    }
    else
    {
      var successMsg = '';
      var restMsg = '';
      if(revokedIDs.length > 0)
      {
        selectedIDsStr = selectedIDsStr.substring(0, selectedIDsStr.length - 1);
        var exp_1 = new Array();
        exp_1 = selectedIDsStr.split(',');
        for(var incr_rvkID = 0; incr_rvkID < exp_1.length; incr_rvkID++)
        {
          if(revokedIDs[exp_1[incr_rvkID]])
          {
            successMsg += revokedIDs[exp_1[incr_rvkID]].revoked_record.full_name+': Selected assignee successfully revoked.\n';
          }
        }
      }
      //console.log('FOUNDED ERRORS');
      //console.log(foundedError);
      if(foundedError.length > 0)
      {
        var exp_2 = new Array();
        exp_2 = selectedIDsStr.split(',');
        for(var incr_rvkID_1 = 0; incr_rvkID_1 < exp_2.length; incr_rvkID_1++)
        {
          if(foundedError[exp_2[incr_rvkID_1]])
          {
            restMsg += '\n'+foundedError[exp_2[incr_rvkID_1]].revoked_record.full_name+': ';
            restMsg += (foundedError[exp_2[incr_rvkID_1]].message.replace("GraphQL error: ", ""));
            restMsg += '\n';
          }
          
        }
      }
      var finalMsg = successMsg + restMsg;
      
      if(revokedIDs.length > 0)
      {
        hideModal();
        unselectAllRecord('delegates')
        pushMessage({
          type: 'failure',
          message: finalMsg
        })
      }
      else
      {
        setSubmitErrors([finalMsg]);
        setIsSubmitting(false);
        return null;
      }
    }
  };
  //console.log(responseData);
  return (
    <div>
      <FormHeading>Revoke Delegate</FormHeading>
      {<FormLabel>Selected assignee(s): {selectedDelegates.length}</FormLabel>}
      <SelectedTable
        recordType="delegates"
        items={selectedDelegates}
        columns={columns.delegatesForRevoke}
        infoTooltip={false}
      />
      <Fragment>
        {/*<div style={{float:'left', width:'100%'}}>
          <h3>Selected assignee(s) for revoke:</h3>
        </div>*/}
        <div style={{float:'left', width:'100%'}}>
          {/*responseData.map((value, index) => {
              return (
                <div style={{float:'left', width:'100%', margin:'1%'}}>
                  <div style={{float:'left', width:'10%', 'font-weight':'bold'}}>Assignee: &nbsp;</div>
                  <div style={{float:'left', width:'65%'}}>{value.delegation_pers_no.full_name}</div>
                </div>
                )
          })*/}
          <div style={{float:'left', width:'100%'}}>
            <FormButtonsWrap>
              <FormButton onClick={handleQuit}>Quit</FormButton>
              <FormButton primary disabled={isSubmitting} onClick={handleRevokeSend}>Revoke</FormButton>
              {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
            </FormButtonsWrap>
          </div>
          <div style={{float:'left', width:'100%'}}>
          {submitErrors.length > 0 && submitErrors.map(err => {
            return (
              <ErrorsBlock key={Math.random()}>
                <IconWrap>!</IconWrap>
                {err}
              </ErrorsBlock>
            );
          })}
          </div>
        </div>  
      </Fragment>
    </div>
  );
  
};

const mapStateToProps = state => {
  return {
    selectedDelegates: state.selectedDelegates,
    account: state.account
  };
};

export default connect(
  mapStateToProps,
  { unselectAllRecord, pushMessage }
)(RevokeDelegate);
