export default (table) => {
  
  return {
    type: "int",
    inputType: "select",
    options: [
      'Do not override', 
      'Apply TI Harmonization', 
      'DO NOT apply TI Harmonization'
    ],
    name: "request.override_ti_harmonization",
    label: "Override TI Harmonization?",
    section: "HR",
    visible: true,
    editable: (column, data) => data.request.request_type === 'compensation' || data.request.request_type === 'internalhire',//Adding this feature to internalhire
    defaultValue: (value, data) => data.override_ti_harmonization,
    allow: ['hr1', 'hr2', 'hr3', 'bcb']
  };
};