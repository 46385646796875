import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { Grid } from 'react-awesome-spinners';
import { BUDGETS, 
  /* Release 3.2 - 1.1 - Starts */ organizationBEABudget, /* Release 3.2 - 1.1 - Ends */
} from '../graphql';
import { currencyDisplayRenderer } from '../util';
import { budgetSetUpdated } from '../actions';
import { GridLoader, BudgetWrap, BudgetContent, BudgetRow, BudgetCell, BudgetLabel, AllBudgetsLink } from '.';

const Budget = props => {
  const { account, budget, budgetSetUpdated } = props;
  const { accessibles } = account;
  console.log("Allbudget", accessibles);
  const { isUpdated } = budget;
  const isGct = account.roles.includes("gct");

  /*  ADD A NEW ROLE FOR ADMIN FUNCTIONALITY 19 */
  const isAdmin = account.roles.includes("admin");
  /* Release 3.2 - 10.4 - Starts */
  const isKeyUser = account.roles.includes("keyuser");
 /* Release 3.2 - 10.4 - Ends */

  const budget_first = [...accessibles.budgetOrgs].shift();

  /*  ADD A NEW ROLE FOR ADMIN FUNCTIONALITY 19 */
  const hasMultipleBudgetsVisible = isGct || isAdmin
  ? true: accessibles.budgetOrgs.length > 1;
  // ACL on API side, if pers_no can see the budget or not
  // or here, based on role ?
  /*  COMMENTS TO FIX THE DEFECT 12 OF BUDGET STARTS - PRASHANT */  
  //const { loading, error, data } = useQuery(BUDGETS,{variables: { organization_unit: budget_first},});
  //const item = data ? data.budgets.items[0] : undefined
  /*  COMMENTS TO FIX THE DEFECT 12 OF BUDGET ENDS */

  /*  ADD A NEW QUERY TO FIX THE DEFECT 12 OF BUDGET STARTS - PRASHANT */
  const query = { organization_unit: { in: accessibles.budgetOrgs } };
  const { loading, error, data } = useQuery(BUDGETS, { variables: { where: query } });
  const responseData = data && data.budgets;
  /*  ADD A NEW QUERY TO FIX THE DEFECT OF BUDGET ENDS */

  {/*  INITIALIZED THE TOTAL VARIABLES TO FIX THE DEFECT 12 OF BUDGET */}
  let totalBudget = 0;
  let totalInProgress = 0;
  let totalSpent = 0;
  let totalAvailFore = 0;
  let totalAvailable = 0;

  /* Release 3.2 - 1.1 - Starts */
  const currentYear = new Date().getFullYear().toString();
  const beaBudgetQuery = { year: { eq: currentYear}, organization_unit: { in: accessibles.budgetOrgs } };
  const { loading: beaBudgetLoding, error: beaBudgetError, data: beaBudgetData } = useQuery(organizationBEABudget, { variables: { where: beaBudgetQuery } });
  const beaBudgetResponseData = beaBudgetData && beaBudgetData.organizationBEABudget;

  let totalBeaBudget = 0;
  let totalBeaInProgress = 0;
  let totalBeaSpent = 0;
  let totalBeaAvailFore = 0;
  let totalBeaAvailable = 0;
  /* Release 3.2 - 1.1 - Ends */

  useEffect(() => {
    if (!isUpdated) return;
    const interval = setInterval(() => budgetSetUpdated(false), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [budgetSetUpdated, isUpdated]);

  return (
    <BudgetWrap>
      {(
        <BudgetContent>
          {(loading /* Release 3.2 - 1.1 - Starts */ || beaBudgetLoding /* Release 3.2 - 1.1 - Ends */) && <GridLoader><Grid color={'#001235'}/></GridLoader>}
          {(error /* Release 3.2 - 1.1 - Starts */ || beaBudgetError /* Release 3.2 - 1.1 - Ends */) && <p>Error :</p>}
          {/*  COMMENTS TO FIX THE DEFECT 12 OF BUDGET STARTS - PRASHANT */}
          {/*  {!loading && !error && item && (
              <BudgetRow>
                {isUpdated && <UpdatedSpan>Updated</UpdatedSpan>}
                <BudgetCell><BudgetLabel>Budget: </BudgetLabel>{currencyDisplayRenderer(item.budget, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>In Progress: </BudgetLabel>{currencyDisplayRenderer(item.budget_in_progress, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>Spent: </BudgetLabel>{currencyDisplayRenderer(item.budget_spent, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>Available Forecast: </BudgetLabel>{currencyDisplayRenderer(item.budget_available, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>Available: </BudgetLabel>{currencyDisplayRenderer(item.budget - item.budget_spent, {currency: 'EUR'})}</BudgetCell>
                <BudgetCell><BudgetLabel>{item.org.organization_unit_name}</BudgetLabel></BudgetCell>
              </BudgetRow>
            )}
            {hasMultipleBudgetsVisible && <AllBudgetsLink to="/all-budgets">See Full Report</AllBudgetsLink>}
          */}  
          {/*  COMMENTS TO FIX THE DEFECT 12 OF BUDGET ENDS */}

          {/*  ADD A NEW QUERY TO FIX THE DEFECT 12 OF BUDGET STARTS - PRASHANT */}
          {!loading && responseData && responseData.items.map((item, index) => {
            totalBudget = parseFloat(totalBudget) + parseFloat(item.budget) ;
            totalInProgress = parseFloat(totalInProgress) + parseFloat(item.budget_in_progress) ;
            totalSpent = parseFloat(totalSpent) + parseFloat(item.budget_spent) ;
            totalAvailFore = parseFloat(totalAvailFore) + parseFloat(item.budget_available) ;
            totalAvailable = parseFloat(totalAvailable) + (parseFloat(item.budget - item.budget_spent)) ;
          })}
          {/* Release 3.2 - 1.1 - Starts */}
          <BudgetRow>           
            <BudgetCell><BudgetLabel>Offcycle Budget </BudgetLabel></BudgetCell>
          </BudgetRow>
          {/* Release 3.2 - 1.1 - Ends */}
          <BudgetRow>           
            <BudgetCell><BudgetLabel>Budget: </BudgetLabel>{currencyDisplayRenderer(totalBudget, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>In Progress: </BudgetLabel>{currencyDisplayRenderer(totalInProgress, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Spent: </BudgetLabel>{currencyDisplayRenderer(totalSpent, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Available Forecast: </BudgetLabel>{currencyDisplayRenderer(totalAvailFore, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Available: </BudgetLabel>{currencyDisplayRenderer(totalAvailable, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel><h3 style={{'margin':'0%'}}>Total Budget Amount</h3></BudgetLabel></BudgetCell>
          </BudgetRow>
          {/* Release 3.2 - 1.1 - Starts */}
          {!beaBudgetLoding && beaBudgetResponseData && beaBudgetResponseData.items.map((item, index) => {
            totalBeaBudget = parseFloat(totalBeaBudget) + parseFloat(item.budget) ;
            totalBeaInProgress = parseFloat(totalBeaInProgress) + parseFloat(item.budget_in_progress) ;
            totalBeaSpent = parseFloat(totalBeaSpent) + parseFloat(item.budget_spent) ;
            totalBeaAvailFore = parseFloat(totalBeaAvailFore) + parseFloat(item.budget_available) ;
            totalBeaAvailable = parseFloat(totalBeaAvailable) + (parseFloat(item.budget - item.budget_spent)) ;
          })}
          <BudgetRow>           
            <BudgetCell><BudgetLabel>Business Excellence Award Budget </BudgetLabel></BudgetCell>
          </BudgetRow>
          <BudgetRow>           
            <BudgetCell><BudgetLabel>Budget: </BudgetLabel>{currencyDisplayRenderer(totalBeaBudget, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>In Progress: </BudgetLabel>{currencyDisplayRenderer(totalBeaInProgress, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Spent: </BudgetLabel>{currencyDisplayRenderer(totalBeaSpent, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Available Forecast: </BudgetLabel>{currencyDisplayRenderer(totalBeaAvailFore, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel>Available: </BudgetLabel>{currencyDisplayRenderer(totalBeaAvailable, {currency: 'EUR'})}</BudgetCell>
            <BudgetCell><BudgetLabel><h3 style={{'margin':'0%'}}>Total Budget Amount</h3></BudgetLabel></BudgetCell>
          </BudgetRow>
          {/* Release 3.2 - 1.1 - End */}
          {hasMultipleBudgetsVisible && <AllBudgetsLink to="/all-budgets">See Full Report</AllBudgetsLink>}
        </BudgetContent>
      )}
    </BudgetWrap>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account,
    budget: state.budget
  };
};

export default connect(
  mapStateToProps,
  { budgetSetUpdated }
)(Budget);
