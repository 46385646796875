import { TOGGLE_DISPLAY_CURRENCY_EUR, UPDATE_ACCOUNT_FROM_AUTH } from '../actions/index';

const intialState = {
  forceCurrency: null
};

export default (state = intialState, action) => {
  switch (action.type) {
    case TOGGLE_DISPLAY_CURRENCY_EUR:
      return Object.assign({}, state, {
        forceCurrency: action.payload
      });
    case UPDATE_ACCOUNT_FROM_AUTH:
      return Object.assign({}, state, {
        pers_no: action.payload.pers_no,
        roles: action.payload.roles,
        email: action.payload.email,
        name: action.payload.name,
        first_name: action.payload.firstName,
        last_name: action.payload.lastName,
        session_id: action.payload.sessionId,
        country_code: action.payload.countryCode,
        org_name: action.payload.org_name,
        org_id: action.payload.org_id,
        org_unit_1: action.payload.org_unit_1,
        org_unit_2: action.payload.org_unit_2,
        org_unit_3: action.payload.org_unit_3,
        org_unit_4: action.payload.org_unit_4,
        budget_org: action.payload.budget_org,
        line_manager_pers_no: action.payload.line_manager,
        accessibles: action.payload.accessibles,
        /* DEM0058258 */
        totalHRCRelationExists: action.payload.totalHRCRelationExists,
        finalHRCRelation: action.payload.finalHRCRelation,
        geoRegion:action.payload.geoRegion,
        hrRegion:action.payload.hrRegion,
        /* Release 3.2 - 10.1 - Starts */
        rcbHRRegion:action.payload.rcbHRRegion,
        /* Release 3.2 - 10.1 - Endss */
      });
    default:
      return state;
  }
};
