import React, {Fragment, useState } from 'react';

import { connect } from 'react-redux';
import { DatePickerField, GridLoader, FormButton, FormButtonsWrap, FormHeading, ErrorsBlock, IconWrap, LoaderWrap } from '..';
import { Grid } from 'react-awesome-spinners';
import { pushMessage, updateAccountFromAuthToken } from '../../actions';
import { Ring } from 'react-awesome-spinners';
import { AUTHORIZE, CREATE_DELEGATE, delegatesForManager, delegationListWithSearch, delegateRolesWithPers } from '../../graphql';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
//import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { convertToUTC } from '../../util/helper';
import ApolloClient from 'apollo-boost';
import { authProvider } from '../../util/auth';

//import SearchItemsList from "./SearchItemsList";

//import Autocomplete from "./Autocomplete"; 

import createStore from '../../store';
import MyDelegates from '../../pages/MyDelegates';
import { parseType } from 'graphql';
let delegateArray = [];
let AssignTaskArray = [];


const CreateDelegate = props => {
  const { account, hideModal, pushMessage } = props;
  const {pers_no, roles} = account;

  const isGct = roles.includes('gct')

  /*  RENEW NEW TOKEN AGAIN STARTS  */
  const extractMSALToken = function()
  {
    const timestamp = Math.floor((new Date()).getTime() / 1000)
    let token = null
  
    for (const key of Object.keys(sessionStorage)){
      if (key.includes('"authority":')){
        const val = JSON.parse(sessionStorage.getItem(key))
        if (val.expiresIn){
          // We have a (possibly expired) token
          if (val.expiresIn > timestamp && val.idToken === val.accessToken){
            // Found the correct token
            token = val.idToken
          }
          else{
            // Clear old data
            sessionStorage.removeItem(key)
          }
        }
      }
    }
    if (token) return token
    throw new Error('No valid token found')
  }
  const store = createStore();
  let accessPayload = sessionStorage.getItem('access_token_payload')
  if (accessPayload) {
    //console.log('accessPayload')
    store.dispatch(updateAccountFromAuthToken(JSON.parse(accessPayload)));
  }
  const acquireInternalToken = async function(externalToken)
  {
    if( !acquireInternalToken.promise ){
      acquireInternalToken.promise = new Promise((resolve, reject)=>{
        //console.log('Hi')
        client.query({query: AUTHORIZE, fetchPolicy: "no-cache"}).then((result)=>{
          //console.log(result)
          //console.log('Hi')
          
          sessionStorage.setItem('access_token', result.data.authorize.token)
          if (!accessPayload) {
            sessionStorage.setItem('access_token_payload', JSON.stringify(result.data.authorize.payload))
            store.dispatch(updateAccountFromAuthToken(result.data.authorize.payload));
          }
          resolve( result.data.authorize.token );
        }).catch((error)=>{
          console.log("ERROR acquiring access token")
          console.log(error.message)
          reject(error);
        });
      });
    }
    //console.log('Hello')
    //console.log(await acquireInternalToken.promise)
    return acquireInternalToken.promise
  }
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_API,
    request: async (operation) => {
      await authProvider.getAccessToken();
      const adIdToken = await authProvider.getIdToken();
      //console.log(adIdToken);

      let externalToken = sessionStorage.getItem('msal.idtoken')
      // If msal.istoken is not found, then we can extract it from the authority
      if(!externalToken){
        externalToken = extractMSALToken()
      }
      if( externalToken !== adIdToken.idToken.rawIdToken ){
        // External token renewed, need to renew internal as well
        delete acquireInternalToken.promise;
        sessionStorage.removeItem('access_token');
      }

      let internalToken = sessionStorage.getItem('access_token')
      if( externalToken && !internalToken && operation.operationName !== "Authorize" ){
        // Exchange external token to internal token
        internalToken = await acquireInternalToken( externalToken )
      }
      if( internalToken ){
        operation.setContext({
          headers: {
            authorization: `Bearer ${internalToken}`
          }
        })
      }
      else if( externalToken ){
        operation.setContext({
          headers: {
            authorization: `Bearer ${externalToken}`
          }
        })
      }
    },
    onError: ({ graphQLErrors, networkError, operation, forward }) => {
      //console.log(graphQLErrors);
      if (graphQLErrors) {
        
      }
    }
  });
  /*  RENEW NEW TOKEN AGAIN ENDS  */
  
  
  
  /*  PREPARE ASSIGN TASKS ARRAY VALUES START */
  AssignTaskArray[0] = {value: 'Approve', label: 'View and approve requests only; cannot view team information'}
  /*  PREPARE ASSIGN TASKS ARRAY VALUES ENDS */

  const now = new Date();

  /*  START DATE  */
  const startMonth_1 = new Date(now.getFullYear(), now.getMonth(), 1);
  //console.log(startMonth_1)  
  const startMonth = now;
  const [startDate, setStartDate] = useState(startMonth);
  const handleStartDateChange = date => setStartDate(date);
  
  /*  END DATE  */
  var oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  //console.log(oneYearFromNow)  
  const nextYear = oneYearFromNow;
  const [endDate, setEndDate] = useState(startMonth);
  const handleEndDateChange = date => setEndDate(date);

  var endDateForField = startMonth;

  /*  ASSIGNEE  */
  let [defaultAssignee, setAssignee] = useState('');
  const handleAssigneeChange = (optionSelected) => 
  {
    const selectedAssignee_ID = optionSelected.value;
    const selectedAssignee_Name = optionSelected.label;
    setAssignee(selectedAssignee_ID);
    //console.log(selectedAssignee_ID+'<===>'+selectedAssignee_Name)
  }

  const [defaultAssignTask, setAssignTask] = useState('Approve');
  const handleAssignTaskChange = date => setAssignTask(defaultAssignTask);
  
  const handleQuit = event => { hideModal(); 
    
    /*document.getElementById('frmAssigneeSingle').value = '';
    document.getElementById('frmAssigneeSingle_Selected').value = '';
    document.getElementById('searchResult').innerHTML = '';
    document.getElementById('frmLoader').style.display = 'none';

    document.getElementById('frmRolesPanel').innerHTML = '';
    document.getElementById('frmRolesPanel').style.display = 'none';
    document.getElementById('frmLoader_Roles').style.display = 'block';
    */
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      width: 'state.selectProps.width',
      borderBottom: '1px dotted pink',
      color: state.selectProps.menuColor,
      padding: 10,
    }),
  }
  let inputText = '';
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);

  const [createDelegation] = useMutation(CREATE_DELEGATE);

  const handleCreateDelegate = async event => {
    setIsSubmitting(true);
    setSubmitErrors([]);  

    let refetchAfterCreateRequest = [{
      query: delegatesForManager,
      variables: {
        limit: 20,
        offset: 0,
        orderBy: [{column: "id", order: "DESC"}]
      }
    }];

    var selectedAssigneeText = document.getElementById("frmAssigneeSingle").value;
    var selectedAssigneeValue = document.getElementById("frmAssigneeSingle_Selected").value;
    //console.log(selectedAssigneeText);
    //console.log(selectedAssigneeValue);
    if (selectedAssigneeText == '')
    {
      setSubmitErrors(['Assignee is required!']);
      setIsSubmitting(false);
      return null;
    }
    if (selectedAssigneeValue == '')
    {
      setSubmitErrors(['Correct assignee is required!']);
      setIsSubmitting(false);
      return null;
    }
    
    /*var Rolescount = 0;
    if(document.getElementById("frmRolesSelection"))
    {
      var oSelect = document.getElementById("frmRolesSelection");
      
      for(var i = 0; i < oSelect.options.length; i++)
      {
        if(oSelect.options[i].selected)
        {
          Rolescount++;
        }
      }
    }
    
    if (Rolescount == 0)
    {
      setSubmitErrors(['At least 1 role should be selected!']);
      setIsSubmitting(false);
      return false;
    }*/

    var checkboxes = document.querySelectorAll('input[name="frmRoles"]');
    var checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
    if (!checkedOne)
    {
      setSubmitErrors(['At least 1 role should be selected!']);
      setIsSubmitting(false);
      return false;
    }
    //console.log(checkboxes)
    //console.log(checkedOne)
    
    //return null;

    if(document.getElementById("frmStartDate"))
    {
      var frmStartDate = document.getElementById("frmStartDate").value;
      var patternStartDate =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
      if(!patternStartDate)
      {
        setSubmitErrors(['Start Date is not valid!']);
        setIsSubmitting(false);
        return false;
      }
    }

    if(document.getElementById("frmEndDate"))
    {
      var frmEndDate = document.getElementById("frmEndDate").value;
      var patternEndDate =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
      if(!patternEndDate)
      {
        setSubmitErrors(['End Date is not valid!']);
        setIsSubmitting(false);
        return false;
      }
    }

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    //console.log(dd+'<====>'+mm+'<====>'+yyyy)
    var currentDateFormated = new Date(mm+'/'+dd+'/'+yyyy);

    var expDate = frmStartDate.split("/");
    var dd_1 = expDate[1];
    var mm_1 = expDate[0]; 
    var yyyy_1 = expDate[2];
    //console.log(dd_1+'<====>'+mm_1+'<====>'+yyyy_1)
    var startDateFormated = new Date(mm_1+'/'+dd_1+'/'+yyyy_1);

    var expDate_2 = frmEndDate.split("/");
    var dd_2 = expDate_2[1];
    var mm_2 = expDate_2[0]; 
    var yyyy_2 = expDate_2[2];
    //console.log(dd_1+'<====>'+mm_1+'<====>'+yyyy_1)
    var endDateFormated = new Date(mm_2+'/'+dd_2+'/'+yyyy_2);

    //console.log (frmStartDate+'<====>'+frmEndDate) 
    //console.log (startDate.getTime()+'<====>'+endDate.getTime()) 
    //console.log (today.getTime()+'<====>'+startDate.getTime()) 
    
    //return null;
    if(Date.parse(startDateFormated) > Date.parse(endDateFormated))
    {
      setSubmitErrors(['End Date should not be smaller from Start Date!']);
      setIsSubmitting(false);
      return false;
    }
    //console.log (currentDateFormated.getTime()+'<====>'+startDateFormated.getTime()) 
    //window.location.reload(false);
    //return null;
    let insertedIds = [];
    let foundedError = [];
    let errorDuringProcess = '';
    let isActive = 0;

    var d1 = Date.parse(currentDateFormated);
    var d2 = Date.parse(frmStartDate);
    if (d2 == d1)
    {
      isActive = 1;
    }
    //console.log(d1+'<====>'+d2);
    //console.log(isActive);
    
    //return null;
   
    //var oSelect = '';
    
    
    


    //console.log("checkboxes")
    //console.log(checkboxes)

    var checkedValue = null; 
    var inputElements = document.getElementsByName('frmRoles');
    for(var i=0; inputElements[i]; ++i){
          if(inputElements[i].checked){
              checkedValue = inputElements[i].value;
              break;
          }
    }
    //return null;
    /*var oSelect = document.getElementsByName("frmRoles");
    for(var i = 0; i < oSelect.options.length; i++)
    {
      if(oSelect.options[i].selected)
      {
        var selectedValue = oSelect.options[i].value;*/
    
    for(var i=0; inputElements[i]; ++i)
    {
      if(inputElements[i].checked)
      {
        var selectedValue = inputElements[i].value;
        //console.log(selectedValue);

        
        //return null;
        try {
          /*console.log("Pers_No====>"+pers_no);
          console.log("approver_type====>"+selectedValue);
          console.log("delegation_pers_no====>"+selectedAssigneeValue);
          console.log("start_date====>"+startDate);
          console.log("end_date====>"+endDate);
          console.log("is_active====>"+isActive);
          
          return null;*/
          const results = await createDelegation({
            variables: {
              data: {
                /*pers_no: 'comp_test_user_001', 
                delegation_id: '50003210', 
                start_date: '2020-11-25', 
                end_date: '2021-07-14'*/
      
                pers_no: pers_no, 
                approver_type: selectedValue,
                delegation_pers_no: selectedAssigneeValue, 
                action_task:"approval",
                start_date: startDate, 
                end_date: endDate,
                is_active: isActive
              }
            },
            //awaitRefetchQueries: true,
            //refetchQueries: refetchAfterCreateRequest
          });
          console.log(results, "RESULTS+++++++++>>>>>>>>");
          if(results)
          {
            if(results.data.createDelegation.id)
            {
              var roleFullName = '';
              if(selectedValue == 'bcb')
              {
                roleFullName = 'Business C&B';
              }
              else if(selectedValue == 'hr1')
              {
                roleFullName = 'HRBP N - 1';
              }
              else if(selectedValue == 'hr2')
              {
                roleFullName = 'HRBP N - 2';
              }
              /*  HR3_ROLE STARTS */
              else if(selectedValue == 'hr3')
              {
                roleFullName = 'HRBP N - 3';
              }
              /*  DEM0058258 */
              else if(selectedValue == 'hrc')
              {
                roleFullName = 'HR Connection';
              }
              else if(selectedValue == 'supermanager')
              {
                roleFullName = 'Second Line managers (SLM)';
              }
              else if(selectedValue == 'gct')
              {
                roleFullName = 'Global Compensation';
              }
              else if(selectedValue == 'gtap')
              {
                roleFullName = 'Global TAP';
              }
              else if(selectedValue == 'rtap')
              {
                roleFullName = 'Regional TAP';
              }
              
              insertedIds[i] = {
                'inserted_record_id' : results.data.createDelegation.id, 
                'inserted_record_for_role' : roleFullName
              };
            }
          }
          
          
        }
        catch(e) {
          //console.log(e)
          //console.log(e.message)
          if(e.message != '')
          {
            var roleFullName = '';
            if(selectedValue == 'bcb')
            {
              roleFullName = 'Business C&B';
            }
            else if(selectedValue == 'hr1')
            {
              roleFullName = 'HRBP N - 1';
            }
            else if(selectedValue == 'hr2')
            {
              roleFullName = 'HRBP N - 2';
            }
            /*  HR3_ROLE STARTS */
            else if(selectedValue == 'hr3')
            {
              roleFullName = 'HRBP N - 3';
            }
            /*  HR3_ROLE ENDS */
            else if(selectedValue == 'hrc')
            {
              roleFullName = 'HR Connection';
            }
            else if(selectedValue == 'supermanager')
            {
              roleFullName = 'Second Line managers (SLM)';
            }
            else if(selectedValue == 'gct')
            {
              roleFullName = 'Global Compensation';
            }
            else if(selectedValue == 'gtap')
            {
              roleFullName = 'Global TAP';
            }
            else if(selectedValue == 'rtap')
            {
              roleFullName = 'Regional TAP';
            }

            errorDuringProcess = 'Yes';
            foundedError[i] = {'for_role' :roleFullName, 'message' : e.message};
            //setSubmitErrors([e.message]);
            //setIsSubmitting(false);
            //return false;
          }
          //console.log(results);
        } 
        //console.log(results);return null;
        if(errorDuringProcess == 'Yes')
        {
          //break;
        }
      }
    }
    //console.log("ERROR DURING PROCESS===>"+errorDuringProcess)
    console.log("INSERTED IDS===>");
    console.log(insertedIds);
    console.log("FOUND ERROR===>")
    console.log(foundedError);
    //return null;
    if(errorDuringProcess == '' && foundedError.length == 0)
    {
      hideModal();
      pushMessage({
        type: 'success',
        message: 'Delegate has been assigned successfully!'
      })
    }
    else
    {
      var successMsg = '';
      var restMsg = '';
      if(insertedIds.length > 0)
      {
        
        for(var incr_insID = 0; incr_insID < insertedIds.length; incr_insID++)
        {
          if(insertedIds[incr_insID] != undefined)
          {
            successMsg += 'For Role '+insertedIds[incr_insID].inserted_record_for_role+': Delegate has been assigned successfully.\n';
          }
          
        }
      }
      
      if(foundedError.length > 0)
      {
        for(var incr_fndfErr = 0; incr_fndfErr < foundedError.length; incr_fndfErr++)
        {
          //console.log("INCREMENT===>"+incr_fndfErr);
          if(foundedError[incr_fndfErr])
          {
            //console.log("IN IF");
            //console.log(incr_fndfErr);
            //if(incr_fndfErr >= 1)
            //{
              
              restMsg += '\nFor Role '+foundedError[incr_fndfErr].for_role+': ';
              restMsg += (foundedError[incr_fndfErr].message.replace("GraphQL error: ", ""));
              restMsg += '\n';
            //}
          }
          
        }
      }
      var finalMsg = successMsg + restMsg;
      
      if(insertedIds.length > 0)
      {
        hideModal();
        pushMessage({
          type: 'failure',
          message: finalMsg
        })
      }
      else
      {
        setSubmitErrors([finalMsg]);
        setIsSubmitting(false);
        return null;
      }
    }
    /*if(errorDuringProcess == '')
    {
      hideModal();
        pushMessage({
          type: 'success',
          message: 'Delegate has been assigned successfully!'
        })
    }*/
  };
  
  const state = {
    item: {
      name: ""
    },
    cursor: 0,
    searchItems: [],
    myTestResult:[]
  };
  const [setKeyPressed] = useState([]);

  const hanldeKeyup = (evt) => {
    if (evt.keyCode === 27) {
      setKeyPressed({ searchItems: [] });
      return false;
    }
  }
  const hanldeKeydown = (evt) =>
  {
    //console.log(evt.keyCode);
    if (evt.keyCode === 38 && cursor > 0) 
    {
      setKeyPressed(prevState => ({
        cursor: prevState.cursor - 1
      }));
    } 
    else if (evt.keyCode === 40 && cursor < searchItems.length - 1) 
    {
      setKeyPressed(prevState => ({
        cursor: prevState.cursor + 1
      }));
    }
    if (evt.keyCode === 13) 
    {
      let currentItem = searchItems[cursor];
      if (currentItem !== undefined) {
        const { name } = currentItem;
        setKeyPressed({ item: { name }, searchItems: [] });
      }
    }
    if (evt.keyCode === 8) 
    {
      //setKeyPressed({ item: { name: "" } });
    }
  }
  const selectItem = (id) => 
  {
    const { searchItems } = state;
    let selectedItem = searchItems.find(item => item.code === id);
    const { name } = selectedItem;
    setKeyPressed({ item: { name }, searchItems: [] });
  }

  const { searchItems, cursor, item } = state;
  const { name } = item;
  const [searchTxtValue, setSearchTxtValue] = useState('');
  
  const _executeSearch = async (evt) => {
    inputText = evt.target.value;
    inputText = inputText.trim();
    //console.log(inputText);
    setSearchTxtValue(inputText);
    
    /*  QUERY FIRE FOR FETCHING RECORDS WITH ENTERED KEYWORD STARTS  */
    var lengthOfInputText = inputText.length;
    //document.getElementById('frmAssigneeSingle_Selected').value = '';
    if(lengthOfInputText >= 3)
    {
      var reg = new RegExp('^[0-9]+$');
      if(reg.test(inputText) === true)  /*  IF TEXT IS NUMBER   */
      {
        var nokiaID = inputText;
        var fullName = '';
      }
      else
      {
        var nokiaID = '';
        var fullName = inputText;
      }
    
      document.getElementById('frmLoader').style.display = 'block';
      const where_1 = { nokia_id: nokiaID, full_name: fullName };
      const delegateSearchResult = await client.query({
        query: delegationListWithSearch,
        variables: { where: where_1, limit : 500 } ,
      })  
      //console.log(delegateSearchResult);
      if(delegateSearchResult)
      {
        if(delegateSearchResult.data.delegationList.items && delegateSearchResult.data.delegationList.items.length > 0)
        {
          var resultStr = '';
          resultStr += '<ul className="list-group" style="list-style-type:none; padding:0%; margin:0%">';
            for(var incr = 0; incr < delegateSearchResult.data.delegationList.items.length; incr++)
            {
              if(incr%2 == 0)
                var bgColor = '#EFEFEF';
              else
                var bgColor = '#FFFFFF';

              var currentBGColor = bgColor;  
              var result_PersNo = delegateSearchResult.data.delegationList.items[incr].pers_no;
              //console.log(result_PersNo)
              var result_NokiaID = delegateSearchResult.data.delegationList.items[incr].nokia_id;
              var result_FullName = delegateSearchResult.data.delegationList.items[incr].full_name;
              var result_FullNameWithNokiaID = delegateSearchResult.data.delegationList.items[incr].full_name+' ['+result_NokiaID+']';
              var LIID = 'frmAssigneeLI_'+result_PersNo;
              resultStr += '<li id="'+LIID+'" style="line-height: 20px;font-size: 12px;cursor: pointer;background-color:'+bgColor+'" onClick="';
              
              resultStr += 'var LILable = document.getElementById(\''+LIID+'\').textContent;';
              resultStr += 'document.getElementById(\'frmAssigneeSingle\').value = \''+result_FullName+'\';';
              resultStr += 'document.getElementById(\'frmAssigneeSingle_Selected\').value = \''+result_PersNo+'\';';
              //resultStr += 'document.getElementById(\'frmAssigneeSingle_Selected\').value = \'kkkkkkkkkkkkk\';';
              resultStr += 'document.getElementById(\'frmAssigneeSingle\').focus();';
              resultStr += 'document.getElementById(\'searchResult\').innerHTML = \'&nbsp;\';';
              resultStr += 'document.getElementById(\'searchResult\').style.display = \'none\';';
              resultStr += '"';
              
              resultStr += 'onMouseOver="';
              resultStr += 'document.getElementById(\''+LIID+'\').style.backgroundColor = \'antiquewhite\'; ';
              resultStr += '"';

              resultStr += 'onMouseOut="';
              resultStr += 'document.getElementById(\''+LIID+'\').style.backgroundColor = \''+currentBGColor+'\'; ';
              resultStr += '"';

              resultStr += '>';
                resultStr += result_FullNameWithNokiaID;
              resultStr += '</li>';
            }
          resultStr += '</ul>';
          document.getElementById('searchResult').style.display = 'block';
          document.getElementById('searchResult').innerHTML = resultStr;
          document.getElementById('frmLoader').style.display = 'none';
        }
        else
        {
          document.getElementById('searchResult').style.display = 'none';
          document.getElementById('searchResult').innerHTML = '&nbsp;';
          document.getElementById('frmLoader').style.display = 'none';
          document.getElementById('frmAssigneeSingle_Selected').value = '';
        }
      }
    }
    /*  QUERY FIRE FOR FETCHING RECORDS WITH ENTERED KEYWORD ENDS  */
    
  }
  
  const _executeSecondSearch = async (evt) => {
    var selectedPersNo = document.getElementById('frmAssigneeSingle_Selected').value;
    if(selectedPersNo != '')
    {
      document.getElementById('frmLoader_Roles').style.display = 'block';
      document.getElementById('frmRolesPanel').style.display = 'none';
      document.getElementById('frmRolesPanel').innerHTML = '&nbsp;';
      const secondResult = await client.query({
        query: delegateRolesWithPers,
        variables: { pers_no: pers_no }
      });
      if(secondResult)
      {
        console.log(secondResult);
        let rolesData = secondResult.data.delegationRoles;
        var bolAnyRoleExists = false;
        if(rolesData)
        {
          var resultStr = '';
          resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';

            if(rolesData.is_business_cb == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';
                resultStr += '<span style="float:left; width:7%">';
                  resultStr += '<input type="checkbox" name="frmRoles" checked="1" value="bcb"  />';
                resultStr += '</span>';
                resultStr += '<span style="float:left; width:80%; color:#000">';
                  resultStr += 'Business C&B';
                resultStr += '</span>';
              resultStr += '</span>';
            }
            
            if(rolesData.is_hrbp_n1 == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';
                resultStr += '<span style="float:left; width:7%">';
                  resultStr += '<input type="checkbox" name="frmRoles" checked="1" value="hr1"  />';
                resultStr += '</span>';
                resultStr += '<span style="float:left; width:80%; color:#000">';
                  resultStr += 'HRBP N – 1';
                resultStr += '</span>';
              resultStr += '</span>';
            }

            if(rolesData.is_hrbp_n2 == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';
                resultStr += '<span style="float:left; width:7%">';
                  resultStr += '<input type="checkbox" name="frmRoles" checked="1" value="hr2"  />';
                resultStr += '</span>';
                resultStr += '<span style="float:left; width:80%; color:#000">';
                  resultStr += 'HRBP N – 2';
                resultStr += '</span>';
              resultStr += '</span>';
            }

            /*  HR3_ROLE STARTS */
            if(rolesData.is_hrbp_n3 == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';
                resultStr += '<span style="float:left; width:7%">';
                  resultStr += '<input type="checkbox" name="frmRoles" checked="1" value="hr3"  />';
                resultStr += '</span>';
                resultStr += '<span style="float:left; width:80%; color:#000">';
                  resultStr += 'HRBP N – 3';
                resultStr += '</span>';
              resultStr += '</span>';
            }
            /*  HR3_ROLE ENDS */

            if(rolesData.is_second_line_manager == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';
                resultStr += '<span style="float:left; width:7%">';
                  resultStr += '<input type="checkbox" name="frmRoles" checked="1" value="supermanager"  />';
                resultStr += '</span>';
                resultStr += '<span style="float:left; width:80%; color:#000">';
                  resultStr += 'Second Line managers (SLM)';
                resultStr += '</span>';
              resultStr += '</span>';
            }

            /*DEM0058258*/
            if(rolesData.is_hr_connection == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';
                resultStr += '<span style="float:left; width:7%">';
                  resultStr += '<input type="checkbox" name="frmRoles" checked="1" value="hrc"  />';
                resultStr += '</span>';
                resultStr += '<span style="float:left; width:80%; color:#000">';
                  resultStr += 'HR Connection';
                resultStr += '</span>';
              resultStr += '</span>';
            }

            if(isGct)
            {
              bolAnyRoleExists = true;
              resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';
                resultStr += '<span style="float:left; width:7%">';
                  resultStr += '<input type="checkbox" name="frmRoles" checked="1" value="gct"  />';
                resultStr += '</span>';
                resultStr += '<span style="float:left; width:80%; color:#000">';
                  resultStr += 'Global Compensation';
                resultStr += '</span>';
              resultStr += '</span>';
            }

            if(rolesData.is_gtap == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';
                resultStr += '<span style="float:left; width:7%">';
                  resultStr += '<input type="checkbox" name="frmRoles" checked="1" value="gtap"  />';
                resultStr += '</span>';
                resultStr += '<span style="float:left; width:80%; color:#000">';
                  resultStr += 'Global TAP User';
                resultStr += '</span>';
              resultStr += '</span>';
            }

            if(rolesData.is_rtap == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0">';
                resultStr += '<span style="float:left; width:7%">';
                  resultStr += '<input type="checkbox" name="frmRoles" checked="1" value="rtap"  />';
                resultStr += '</span>';
                resultStr += '<span style="float:left; width:80%; color:#000">';
                  resultStr += 'Regional TAP User';
                resultStr += '</span>';
              resultStr += '</span>';
            }

            /*resultStr += '<select name="frmRolesSelection[]" id="frmRolesSelection" style="width:100%; height:auto;" multiple>';
            
            if(rolesData.is_hrbp_n1 == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<option value="hr1">HRBP N – 1</option>';
            }
            if(rolesData.is_hrbp_n2 == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<option value="hr2">HRBP N – 2</option>';
            }
            if(rolesData.is_second_line_manager == true)
            {
              bolAnyRoleExists = true;
              resultStr += '<option value="supermanager">Second Line managers (SLM)</option>';
            }

            if(isGct)
            {
              bolAnyRoleExists = true;
              resultStr += '<option value="gct">Global Compensation</option>';
            }
            resultStr += '</select>';*/
          resultStr += '</span>'; 

          if(bolAnyRoleExists == false)
          {
            resultStr += '<span style="float:left; width:100%; margin:1% 0 1% 0;background-color: moccasin;font-weight: bold;padding: 0 1%;">Sorry! You do not have sufficient roles to create the delegate.';
              
            resultStr += '</span>';
          }
          document.getElementById('frmLoader_Roles').style.display = 'none';
          document.getElementById('frmRolesPanel').style.display = 'block';
          document.getElementById('frmRolesPanel').innerHTML = resultStr;
          document.getElementById('frmLoader').style.display = 'none';
        }
      } 
      
    }
  }
  

  return (
    <div style={{height:'500px'}}>  {/* added height for delagete model UI chnages */}
      <FormHeading>Create Delegate</FormHeading>
      <Fragment>
        <div style={{width:'80%', width:'100%', border:'0px solid', margin: '3% 0 0 0'}}>
          <div style={{width:'100%', border:'0px solid'}}>
            <span style={{float:'left', width:'10%'}}><b>Assignee:</b></span>
            <span style={{float:'left', width:'35%'}}>
            <input type="hidden" name="frmAssigneeSingle_Selected" id="frmAssigneeSingle_Selected" style={{width:'100%', border:'1px solid #000', 'line-height':'35px'}}  />
              <input type="text" name="frmAssigneeSingle" id="frmAssigneeSingle" 
              placeholder="Type Nokia ID/Name [at least 3 characters] to get assignee list" style={{width:'100%', border:'1px solid #000', 'line-height':'35px'}} 
              //disabled={!bolAnyRoleExists}
              onKeyDown={hanldeKeydown} 
              onKeyUp={hanldeKeyup} 
              onChange={_executeSearch} 
              onFocus={_executeSecondSearch}
              />
              <input type="hidden" name="aaaaa" id="aaaaa" value=""  style={{width:'100%', border:'1px solid #000', 'line-height':'35px'}} />
              <span style={{float:'left', width:'100%', border: '1px solid', 'min-height': '22px', 'max-height': '140px', position: 'relative', overflow: 'auto', display: 'none'}} id="searchResult">&nbsp;</span>
              {/*[<i style={{color:'#000'}}>Type Nokia ID/Name to get the list.</i>]*/}
            </span>
            <span id="frmLoader" style={{float:'left', width:'3%', 'margin-left':'1%', display: 'none'}}><img src="./animated-ajax-1.gif" /></span>
          </div>
          <div style={{width:'100%', float:'left', border:'0px solid'}}>&nbsp;</div>
          
          <div style={{width:'100%', width:'100%', border:'0px solid'}}>
            <span style={{float:'left', width:'10%'}}><b>Assign Tasks:</b></span>
            <span style={{float:'left', width:'35%'}}>
              <span style={{float:'left', width:'100%', margin:'1% 0 1% 0'}}>
                <span style={{float:'left', width:'7%'}}>
                  <input type="checkbox" value="1" checked='1' disabled='1' />
                </span>
                <span style={{float:'left', width:'90%', color:'#000'}}> {/* increased width from 80 t0 90% for delagete model UI chnages */}
                  View and approve requests only; cannot view team information
                </span>
              </span>
            </span>
          </div>
          <div style={{width:'100%', float:'left', border:'0px solid'}}>&nbsp;</div>

          <div style={{width:'100%', width:'100%', border:'0px solid'}}>
            <span style={{float:'left', width:'10%'}}><b>Roles:</b></span>
            <span style={{float:'left', width:'35%', display: 'block'}} id="frmRolesPanel">Please select Assignee!</span>
            <span id="frmLoader_Roles" style={{float:'left', width:'3%', 'margin-left':'1%', display: 'none'}}><img src="./animated-ajax-1.gif" /></span>
          </div>
          <div style={{width:'100%', float:'left', border:'0px solid'}}>&nbsp;</div>

          <div style={{width:'100%', float:'left', border:'0px solid'}}>
            <span style={{float:'left', width:'10%'}}><b>Start Date:</b></span>
            <span style={{float:'left', width:'60%'}}>
              <DatePickerField id="frmStartDate"  selected={startDate} onChange={handleStartDateChange} minDate={startMonth} maxDate={nextYear}  />
            </span>
          </div>
          <div style={{width:'100%', float:'left', border:'0px solid'}}>&nbsp;</div>
          
          <div style={{width:'100%', float:'left', border:'0px solid'}}>
            <span style={{float:'left', width:'10%'}}><b>End Date:</b></span>
            <span style={{float:'left', width:'60%'}}>
              <DatePickerField id="frmEndDate" selected={endDate} onChange={handleEndDateChange} minDate={startMonth} maxDate={nextYear} />
            </span>
          </div>
          <div style={{width:'100%', float:'left', border:'0px solid'}}>&nbsp;</div>

          <div style={{width:'100%', float:'left', border:'0px solid'}}>
            <span style={{float:'left', width:'10%'}}>&nbsp;</span>
            <span style={{float:'left', width:'60%'}}>
              <FormButtonsWrap>
                <FormButton onClick={handleQuit}>Quit</FormButton>
                <FormButton primary  onClick={handleCreateDelegate}>Create Delegate</FormButton>
                
              </FormButtonsWrap>
              {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
            </span>
          </div>
          <div style={{width:'100%', float:'left', border:'0px solid'}}>&nbsp;</div>
          
          <div style={{width:'100%', float:'left', border:'0px solid'}}>
            <span style={{float:'left', width:'10%'}}>&nbsp;</span>
            <span style={{float:'left', width:'60%'}}>
            {submitErrors.length > 0 && submitErrors.map(err => {
              return (
                <ErrorsBlock key={Math.random()}>
                  <IconWrap>!</IconWrap>
                  {err}
                </ErrorsBlock>
              );
            })}
            </span>
          </div>
        </div>
      </Fragment>
         
    </div>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account
  };
};

export default connect(
  mapStateToProps,
  { pushMessage }
)(CreateDelegate);

document.addEventListener('click', function(e) {
  e = e || window.event;
  var target = e.target;
  var clickedTargetID = target.id;
  console.log(clickedTargetID);
  if(clickedTargetID != '' && !clickedTargetID.includes("react-select"))
  {
    var exp_1 = clickedTargetID.split("_");
    if(exp_1[0] != 'frmAssigneeLI' && document.getElementById('searchResult')) //R2 - w3: To fix blank sccreen error onlcick of any input
    {
      document.getElementById('searchResult').innerHTML = '&nbsp;';
      document.getElementById('searchResult').style.display = 'none';
    }
  }
  else
  {
    if(document.getElementById('searchResult'))
    {
      document.getElementById('searchResult').innerHTML = '&nbsp;';
      document.getElementById('searchResult').style.display = 'none';
    }
  }
  
}, false);