import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showModal, unselectAllRecord, toggleShowInEur } from '../actions';
import { Budget, 
  GlobalMessages, 
  Table, 
  TableHeadingWrap, 
  TableActionsWrap, 
  TableAction, 
  Header, 
  /* Release 3.2 - 7.1 - Starts */
  LoaderWrap,
  /* Release 3.2 - 7.1 - Ends */
  /* Release 3.2 - 1.1 - Starts */
  BOBudget,
  /* Release 3.2 - 1.1 - Ends */
} from '../components';
import { columns } from '../util/config';
import { isCancellable } from '../util/helper';
// R2 - imported inputfield for search
import { InputField } from '../components/styled';
/* Release 3.2 - 7.1 - Starts */
import exportFromJSON from "export-from-json";
import { apolloClient, apolloProxyClient } from "../index";
import * as queries from "../graphql";
import { Ring } from "react-awesome-spinners";
import {
  dateRenderer,
  dateTimeRenderer,
  currencyDisplayRenderer,
  percentRenderer,
  incentivePlanRenderer,
  numberRenderer,
  currencySymbolRenderer,
} from "../util";
/* Release 3.2 - 7.1 - Ends */

const MyRequests = (props) => {
  // const { account, selectedRequests, messages, showModal, unselectAllRecord, toggleShowInEur } = props;

  const {
    account,
    selectedRequests,
    messages,
    showModal,
    unselectAllRecord,
    toggleShowInEur,
    config,
    /* Release 3.1 - Starts */
    budget,
    budgetSetUpdated,
    /* Release 3.1 - Ends */
  } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { forceCurrency, accessibles, roles } = account;
  // unselect all requests which may be selected on another page
  /* Release 3.1 - Starts */
  const [tableRefreshKey, setTableRefreshKey] = useState(1);
  const [budgetRefreshKey, setBudgetRefreshKey] = useState(2);
  /* Release 3.1 - Ends */
  useEffect(() => {
    unselectAllRecord("requests");
    /* Release 3.1 - Starts */
    if (messages.length > 0) {
      setTableRefreshKey(Math.floor(Math.random() * 86));
      setBudgetRefreshKey(Math.floor(Math.random() * 87));
    }
    /* Release 3.1 - Ends */
  }, [
    unselectAllRecord,
    /* Release 3.1 - Starts */
    messages,
    /* Release 3.1 - Ends */
  ]);

  const [queryFilter, setTableFilter] = useState({
    requester_pers_no: { eq: account.pers_no },
    /* Release 3.2 - 10.1 - Starts */
    req_page: "myrequests" ,
    /* Release 3.2 - 10.1 - Ends */
  });
  const orderBy = { created_ts: "DESC" };
  const isGct = account.roles.includes("gct");
  const isAdmin = account.roles.includes("admin");
  const isGTap = account.roles.includes("gtap");
  const isRTap = account.roles.includes("rtap");
  const isTap = isGTap || isRTap;
  /* Release 3.2 - 10.4 - Ends */
  const isKeyUser = account.roles.includes('keyuser');
  /* Release 3.2 - 10.4 - Starts */
  /* Release 3.2 - 1.1 - Starts */
  const isHR1 = account.roles.includes('hr1');
  const isHR2 = account.roles.includes('hr2');
  const isHR3 = account.roles.includes('hr3');
  const isRcb= account.roles.includes('rcb');
  const isChr= account.roles.includes('chr');
  const isHRC= account.roles.includes('hrc');
  const isBCB= account.roles.includes('bcb');
  const isBusinessOwner= account.roles.includes('business_owner');
  const isOnlyBusinessOwner =
    !roles.includes("manager") &&
    !roles.includes("supermanager") &&
    !roles.includes("bcb") &&
    !roles.includes("gct") &&
    !roles.includes("rcb") &&
    !roles.includes("admin") &&
    !roles.includes("gtap") &&
    !roles.includes("rtap") &&
    !roles.includes("keyuser") &&
    !roles.includes("hr1") &&
    !roles.includes("hr2") &&
    !roles.includes("hr3") &&
    !roles.includes("chr") &&
    !roles.includes('hrc') &&
    !roles.includes('bg_head') &&
    !roles.includes('bl2') &&
    !roles.includes('bl3') &&
    !roles.includes('rhr')&&
    roles.includes('business_owner');
  /* Release 3.2 - 1.1 - Ends */
  const isOnlyKeyUser = 
    !roles.includes("manager") &&
    !roles.includes("supermanager") &&
    !roles.includes("bcb") &&
    !roles.includes("gct") &&
    !roles.includes("rcb") &&
    !roles.includes("admin") &&
    !roles.includes("gtap") &&
    !roles.includes("rtap") &&
    roles.includes("keyuser") &&
    !roles.includes("hr1") &&
    !roles.includes("hr2") &&
    !roles.includes("hr3") &&
    !roles.includes("chr") &&
    !roles.includes('hrc') &&
    !roles.includes('bg_head') &&
    !roles.includes('bl2') &&
    !roles.includes('bl3') &&
    !roles.includes('rhr')&&
    !roles.includes('business_owner');
  // R2 - Search Functionality State variable
  const [searchText, setSearchText] = useState('');
  /* Release 3.2 - 7.1 - Starts */
  const [isSubmitting, setIsSubmitting] = useState(false);
  /* Release 3.2 - 7.1 - Ends */

  const hasSelectedRequests = selectedRequests.length > 0;
  const hasGlobalMessages = messages.length > 0;
  const hasBudgetVisibility =
    ((accessibles && accessibles.budgetOrgs.length > 0) || isGct || isAdmin);
  //START WORK BLANK PAGE ISSUE DEMAND DEM0058138 OCC_Inactive_Withdrawn user issue_661
  const canCancel =
    hasSelectedRequests &&
    selectedRequests.every(
      (r) =>
        r.__typename === "Request" &&
        r.request &&
        r.request.for.nokia_id !== "NA" &&
        r.request.status !== "canceled" &&
        r.request.status !== "rejected" &&
        r.request.status !== "archived" &&
        isCancellable(r.request.approvals)
    );
  const canEditOnetime =
    hasSelectedRequests &&
    selectedRequests.every(
      (r) =>
        r.__typename === "Request" &&
        r.request &&
        r.request.for.nokia_id !== "NA" &&
        r.request.request_type === "onetime" &&
        r.request.status === "review" &&
        !r.request.approvals.length
    );
  const canEditCompensation =
    hasSelectedRequests &&
    selectedRequests.every(
      (r) =>
        r.__typename === "Request" &&
        r.request &&
        r.request.for.nokia_id !== "NA" &&
        r.request.request_type === "compensation" &&
        r.request.status === "review" &&
        !r.request.approvals.length
    );
  const canEditInternalHire =
    hasSelectedRequests &&
    selectedRequests.every(
      (r) =>
        r.__typename === "Request" &&
        r.request &&
        r.request.for.nokia_id !== "NA" &&
        r.request.request_type === "internalhire" &&
        r.request.status === "review" &&
        !r.request.approvals.length
    );
  /* Release 3.1 - Starts */
  const canSendReminder =
    hasSelectedRequests &&
    selectedRequests.every(
      (r) =>
        r.__typename === "Request" &&
        r.request &&
        r.request.for.nokia_id !== "NA" &&
        r.request.request_type === "internalhire" &&
        r.request.status === "open" &&
        r.request.approvals.length
    );
  //END WORK BLANK PAGE ISSUE DEMAND DEM0058138 OCC_Inactive_Withdrawn user issue_661
  /* Release 3.1 - Ends */
  //END WORK BLANK PAGE ISSUE DEMAND DEM0058138 OCC_Inactive_Withdrawn user issue_661
  /* Release 3.2 - 1.1 - Starts */
  const canEditBEA =
    hasSelectedRequests &&
    selectedRequests.every(
      (r) =>
        r.__typename === "Request" &&
        r.request &&
        r.request.for.nokia_id !== "NA" &&
        r.request.request_type === "businessexcellenceaward" &&
        r.request.status === "review" &&
        !r.request.approvals.length
    );
  /* Release 3.2 - 1.1 - Ends */
  const handleRequestCancelations = (event) =>
    showModal({ component: "cancel" });

  const handleEditOnetime = (event) => showModal({ component: "editOnetime" });
  const handleEditCompensation = (event) =>
    showModal({ component: "editCompensation" });
  const handleEditInternalHire = (event) =>
    showModal({ component: "editinternalhire" });
  /* Release 3.1 - Starts */
  const handleSendReminder = (event) =>
    showModal({ component: "sendreminderRequest" });
  /* Release 3.1 - Ends */
  /* Release 3.2 - 1.1 - Starts */  
  const handleEditBEA = (event) => showModal({ component: "editBEA" });
  /* Release 3.2 - 1.1 - Ends */
  // R2 - Search Functionality Starts
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    const searchFilter = event.target.value;
    if (searchFilter.length >= 2) {
      setTableFilter({
        requester_pers_no: { eq: account.pers_no },
        employee_search: searchFilter,
        /* Release 3.2 - 10.1 - Starts */
        req_page: "myrequests" ,
        /* Release 3.2 - 10.1 - Ends */
      });
    } else if (searchFilter === "" || searchFilter.length < 2) {
      setTableFilter({ requester_pers_no: { eq: account.pers_no }, /* Release 3.2 - 10.1 - Starts */req_page: "myrequests" /* Release 3.2 - 10.1 - Starts */ });
    }
    /* Release 3.2 - 1.1 - Starts */
    unselectAllRecord("requests");
    /* Release 3.2 - 1.1 - Ends */
  };

  const clearChange = () => {
    setSearchText("");
    setTableFilter({ requester_pers_no: { eq: account.pers_no }, /* Release 3.2 - 10.1 - Starts */req_page: "myrequests" /* Release 3.2 - 10.1 - Starts */ });
    /* Release 3.2 - 7.2 - Starts */
    unselectAllRecord("requests");
    /* Release 3.2 - 7.2 - Ends */
  };
  // R2 - Search Functionality Ends
  /*  FIX THE DEFECT 10 STARTS  */
  const toggleDisplayCurrency = (event) => {
    toggleShowInEur(forceCurrency ? null : "EUR");
  };
  /*  FIX THE DEFECT 10 ENDS  */
  /* Release 3.2 - 7.1 - Starts */
  const handleExportData = (event) => {
    /* Release 3.2 - 7.2 - Starts */
    let filterValue = null;
    if(hasSelectedRequests) {
      // console.log("selectedRequests", selectedRequests);
      const resultEmployeeRequestId = selectedRequests.map(a => a.employee_request_id);
      // console.log("resultEmployeeRequestId", resultEmployeeRequestId);
      filterValue = {
        ...queryFilter,
        employee_request_id: {in: resultEmployeeRequestId}
      }
     } else {
      filterValue = {...queryFilter};
    }
    // console.log("filterValue", filterValue);
    /* Release 3.2 - 7.2 - Ends */
    event.preventDefault();
    setIsSubmitting(true);
    var tableQuery = 'REQUESTSFOREXCELEXPORT';
    httpClient
      .query({
        query: queries[tableQuery],
        variables: {
          where: /* Release 3.2 - 7.2 - Starts */filterValue/* Release 3.2 - 7.2 - Ends */,
          offset: 0,
          orderBy: orderBy.length ? orderBy : null,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let data = response.data['requests'].items.map(
          ({
            upd_ins_by,
            upd_ins_ts,
            __typename,
            ...rest
          }) => rest
        );
        if (data && data.length > 0) {
          data = data.map(function(item){
            let current_itp_situation = item.for && item.for.current_itp_situation ? item.for.current_itp_situation : 'Norm'
            let new_itp_situation = item.new_itp_situation ? item.new_itp_situation : 'Norm';

            let approvals = '';
            for (let i = 0; i < item.approvals.length;i++) {
              let a = item.approvals[i];
              approvals += a.approver === null ? (
                a.approval_status === "active" ? "Inactive approver"
                : "Inactive approver"
              ) : a.approval_status === "active" ? 
                  a.approver.full_name
              : 
                  a.approver.full_name;
              if (i < item.approvals.length -1 ) {
                approvals += " ⮕ "
              }
            }

            let comments = '';
            for (let i = 0; i < item.comments.length;i++) {
              let a = item.comments[i];
              comments += "◉ ";
              comments += a.description;
              comments += " --";
              comments += (a.commenter === null ? "Inactive Approver" : a.commenter.full_name);
              comments += ", ";
              comments += dateTimeRenderer(a.upd_ins_ts);
              if (i < item.comments.length -1 ) {
                comments += "\n";
              }
            }

            let changeApprovers = '';
            for (let i = 0; i < item.changeApprovers.length;i++) {
              let a = item.changeApprovers[i];
              changeApprovers += "◉ ";
              changeApprovers += a.approval && a.approval != null? "Level " + a.approval.approver_level : "";
              changeApprovers += " -'"; 
              changeApprovers += (a.preApprover ? a.preApprover.full_name : "Inactive approver"); 
              changeApprovers += " ' replaced with '";
              changeApprovers += (a.postApprover ? a.postApprover.full_name : "Inactive approver");
              changeApprovers += "' --";
              changeApprovers += (a.changer ? a.changer.full_name : "Inactive changer");
              changeApprovers += ", ";
              changeApprovers += dateTimeRenderer(a.upd_ins_ts);
              
              if (i < item.changeApprovers.length -1 ) {
                changeApprovers += "\n";
              }
            }
            
            let total_increaseed_percent = 0;
            if (item.request_type === "onetime"  || item.request_type === "businessexcellenceaward") {
              total_increaseed_percent = item.ttc_increase_percentage;
            } else {
              total_increaseed_percent =
                ((item.new_fte_ttc_annual_salary -
                  item.for.fte_ttc_annual_salary) /
                  item.for.fte_ttc_annual_salary) *
                100;
            }
            /* Release 3.2 - 1.1 - Starts */
            let finalData = {};           
            finalData["ID"] = item.employee_request_id;
            finalData["Date"] = dateTimeRenderer(item.created_ts);
            finalData["Request Type"] = item.request_type;
            finalData["Employee"] = `${item.for.full_name} (${item.for.nokia_id})`;
            finalData["Requestor"] = item.by && item.by ? item.by.full_name : '';
            finalData["Country"] = item.request_country;
            if (isHR1 || isHR2 || isHR3 || isRcb || isChr || isHRC || isBCB || isRTap || isGTap || isBusinessOwner) {
              finalData["Organization Unit"] = item.organization_unit_name || (item.request_type == 'internalhire' ? item.hmanager && item.hmanager.organization_unit_name ? item.hmanager.organization_unit_name : '' : item.for && item.for.organization_unit_name ? item.for.organization_unit_name : '');
            }
            if (isHR1 || isHR2 || isHR3 || isRcb || isChr || isHRC || isBCB || isRTap || isGTap || isKeyUser || isBusinessOwner) {
              finalData["Business Group"] = item.organization_unit_1_name || (item.request_type == 'internalhire' ? item.hmanager.organization_unit_1_name : item.for.organization_unit_1_name);
            }
            finalData["Amount"] = currencyDisplayRenderer(item.ttc_increase_amount, item);
            finalData["Total Increase %"] = percentRenderer(total_increaseed_percent);
            finalData["Reason for the Request"] = item.request_description;
            finalData["Status"] = item.status;

            finalData["Current-Country"] = item.for && item.for.country_name && item.request_type !== "businessexcellenceaward" ? item.for.country_name : '';
            finalData["Current-Job Family"] = item.for && item.for.job_family_name && item.request_type !== "businessexcellenceaward" ? item.for.job_family_name : '';
            finalData["Current-Job Sub Family"] = item.for && item.for.job_subfamily_name && item.request_type !== "businessexcellenceaward" ? item.for.job_subfamily_name : ''; 
            finalData["Current-Job"] = item.for && item.for.job_name && item.request_type !== "businessexcellenceaward" ? item.for.job_name : '';
            finalData["Current-Job Grade"] = item.for && item.for.job_grade && item.request_type !== "businessexcellenceaward" ? item.for.job_grade : ''; 
            finalData["Current-Position Title"] = item.for && item.for.position_title && item.request_type !== "businessexcellenceaward" ? item.for.position_title : ''; 
            finalData["Current-Annual Base Salary"] = item.for && item.for.fte_annual_salary && item.request_type !== "businessexcellenceaward" ? currencyDisplayRenderer(item.for.fte_annual_salary, item, account,
              config) : '';
            finalData["Current-Incentive Target Percentage"] = item.for && item.for.fte_incentive_target_percentage && item.request_type !== "businessexcellenceaward" ? percentRenderer(item.for.fte_incentive_target_percentage) + ' ' + current_itp_situation : ''; 
            finalData["Current-Incentive Target Amount"] = item.for && item.for.fte_incentive_target_amount && item.request_type !== "businessexcellenceaward" ? currencyDisplayRenderer(item.for.fte_incentive_target_amount, item, account,
              config) : '';
            finalData["Current-Total Target Cash"] = item.for && item.for.fte_ttc_annual_salary && item.request_type !== "businessexcellenceaward" ? currencyDisplayRenderer(item.for.fte_ttc_annual_salary, item, account,
              config) : ''; 
            finalData["Current-TTC Compa-Ratio"] = item.for && item.for.ttc_compa_ratio && item.request_type !== "businessexcellenceaward" ? numberRenderer(item.for.ttc_compa_ratio) : ''; 
            finalData["Current-Incentive Plan"] = item.for && item.for.incentive_plan_type && item.request_type !== "businessexcellenceaward" ? item.for.incentive_plan_type : ''; 
            finalData["Current-Quartile"] = item.for && item.for.ttc_employee_quartile && item.request_type !== "businessexcellenceaward" ? item.for.ttc_employee_quartile : ''; 

            finalData["New-Country"] = item.for && item.for.country_name && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.for.country_name : ''; 
            finalData["New-Job Family"] = item.promotion_job_family_name && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.promotion_job_family_name : ''; 
            finalData["New-Job Sub Family"] = item.promotion_job_subfamily_name && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.promotion_job_subfamily_name : ''; 
            finalData["New-Job"] = item.promotion_job_name && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.promotion_job_name : ''; 
            /*  CAL5065706 - Issue Fixed - By Changing New Job Grade Starts */
            //finalData["New-Job Grade"] = item.for && item.for.job_grade && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.for.job_grade : ''; 
            finalData["New-Job Grade"] = item.promotion_job_grade && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.promotion_job_grade : '';
            /*  CAL5065706 - Issue Fixed - By Changing New Job Grade Ends */
            finalData["New-Position Title"] = item.new_position_title && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.new_position_title : ''; 
            finalData["New-Annual Base Salary"] = item.new_fte_annual_salary && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? currencyDisplayRenderer(item.new_fte_annual_salary, item, account,
              config) : '';
            finalData["New-Incentive Target Percentage"] = item.new_incentive_target_percentage && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? percentRenderer(item.new_incentive_target_percentage) + ' ' + new_itp_situation : '';
            finalData["New-Incentive Target Amount"] = item.new_fte_incentive_target_amount && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? currencyDisplayRenderer(item.new_fte_incentive_target_amount, item, account,
              config) : ''; 
            finalData["New-Total Target Cash"] = item.new_fte_ttc_annual_salary && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? currencyDisplayRenderer(item.new_fte_ttc_annual_salary, item, account,
              config) : '';
            finalData["New-TTC Compa-Ratio"] = item.new_ttc_compa_ratio && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? numberRenderer(item.new_ttc_compa_ratio) : '';
            finalData["New-Incentive Plan"] = item.new_incentive_plan_type && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.new_incentive_plan_type : ''; 
            finalData["New-Quartile"] = item.new_quartile && item.request_type !== "businessexcellenceaward" && item.request_type !== "onetime" ? item.new_quartile : '';

            finalData["Effective/Payment Date"] = /* Release 3.2 - 1.1 - Starts */(item.request_type == "onetime" || item.request_type == "businessexcellenceaward")/* Release 3.2 - 1.1 - Ends */ ? (item.payment_date ? dateRenderer(item.payment_date) : '') : (item.effective_date ? dateRenderer(item.effective_date) : '');
            /* Release 3.2 - 11.2 - Starts */
            //  finalData["Last Reminder Date"] = item.reminder_info && item.reminder_info.last_reminder_date ? dateTimeRenderer(item.reminder_info.last_reminder_date) : 'N/A';
            //  finalData["Reminder Count"] = item.reminder_info && item.reminder_info.reminder_count ? item.reminder_info.reminder_count : '0';
            /* Release 3.2 - 11.2 - Ends */
            finalData["Off-Cycle Budget Spending"] = item.offcycle_budget_update ? currencyDisplayRenderer(item.offcycle_budget_update, item, account,
              config) : ''; 
            finalData["Approval Chain"] = approvals;
            finalData["Comments"] = comments;
            finalData["Change Approver"] = changeApprovers; 
            /* Release 3.2 - 1.1 - Starts */
            finalData["Award Title"] = item.award_title ? item.award_title : '';                                
            finalData["Award Message"] = item.award_message ? item.award_message : '';                                
            finalData["Award Privacy"] = item.award_privacy ? item.award_privacy : '';                                
            finalData["Award Reason"] = item.award_reason ? item.award_reason : '';                                
            finalData["Award Amount"] = (item.ttc_increase_amount && item.request_type == 'businessexcellenceaward') ? item.ttc_increase_amount : ''; 
            finalData["Nominator Email Address"] = item.nominator_email_address ? item.nominator_email_address : '';
            /* Release 3.2 - 1.1 - Ends */                                      
          
            return finalData;
            /* Release 3.2 - 1.1 - Ends */
          });
          data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':""'));
          const fileName = "my_requests_" + new Date().toISOString();
          const exportType = "csv";
          exportFromJSON({ data, fileName, exportType });
          setIsSubmitting(false);
        } else {
          setIsSubmitting(false);
          alert("No Record Found to Export the Excel");
        }
        /* Release 3.2 - 7.2 - Starts */
        delete filterValue.employee_request_id;
        /* Release 3.2 - 7.2 - Ends */
      })
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
      });
  };
  /* Release 3.2 - 7.1 - Ends */
  return (
    <>
      <Header {...props} />
      {hasBudgetVisibility && (
        <Budget
          /* Release 3.1 - Starts */
          account={account}
          budget={budget}
          budgetSetUpdated={budgetSetUpdated}
          key={budgetRefreshKey}
          /* Release 3.1 - Ends*/
        />
      )}
      <BOBudget account={account} key={budgetRefreshKey} />
      {hasGlobalMessages && <GlobalMessages />}
      <TableHeadingWrap>
      <div style={{
        display: "flex",
        justifyContent: "flex-start",
      }}>
      <h2 style={{
            width: "82px",
          }}>My Requests</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >          
          {/* R2 - Search and clear input boxes */}
          <InputField
            type="text"
            placeholder="Search on Nokia Id, Request Id, Email, Name, Country [at least 2 characters]"
            value={searchText}
            onChange={handleSearchChange}
            style={{
              width: "336px",
              marginLeft: "0px",
              height: "32px",
              outline: "#CCC",
              borderRadius: "5px",
              border: "1px solid #CCC",
            }}
          />
          <div
            onClick={clearChange}
            style={{
              height: "32px",
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: "500",
              textAlign: "center",
              position: "relative",
              top: "6px",
              right: "20px",
            }}
          >
            X
          </div>
          {/* R2 - Search and clear input boxes */}
        </div>
      </div>
        {/* Release 3.2 - 7.1 - Starts */}
        {isSubmitting && (
          <TableAction 
          style={{
            border: "0",
            marginBottom: "5px",
          }}
        >
          <LoaderWrap>
            <Ring color={"#001235"} size="2" sizeUnit="rem" />
          </LoaderWrap>
          </TableAction>
        )}
        {/* Release 3.2 - 7.1 - Ends */}
        {!isAdmin && 
        /* Release 3.2 - 10.4 - Starts */ !isOnlyKeyUser && /* Release 3.2 - 10.4 - Ends */
        (
          <TableActionsWrap>
            {/* Release 3.2 - 7.1 - Starts */}
            <TableAction
              primary
              disabled={isSubmitting}
              onClick={handleExportData}
            >
              Export To Excel
            </TableAction>
            {/* Release 3.2 - 7.1 - Ends */}
            <TableAction onClick={toggleDisplayCurrency}>{`Switch to ${forceCurrency === 'EUR' ? 'local currency' : 'EUR'}`}</TableAction>
            <TableAction disabled={!canCancel} onClick={handleRequestCancelations}>Cancel</TableAction>
            { !isOnlyBusinessOwner &&
              <>
              <TableAction disabled={!canEditOnetime} onClick={handleEditOnetime}>Edit One-Time Payment</TableAction>
              <TableAction width="146px" disabled={!canEditCompensation} onClick={handleEditCompensation}>Edit Compensation Change</TableAction>
              <TableAction width="102px" disabled={!canEditInternalHire} onClick={handleEditInternalHire}>Edit Internal Hire</TableAction>
              </>
            }
            <TableAction width="102px" disabled={!canEditBEA} onClick={handleEditBEA}>Edit BEA</TableAction>
            {/* Release 3.1 - Starts */}
            {isTap && (
              <TableAction
                disabled={!canSendReminder}
                onClick={handleSendReminder}
              >
                Send Reminders
              </TableAction>
            )}
            {/* Release 3.1 - ends */}
          </TableActionsWrap>
        )}
        {(isAdmin ||
        /* Release 3.2 - 10.4 - Starts */ isOnlyKeyUser) /* Release 3.2 - 10.4 - Ends */
         && (
          <TableActionsWrap>
            {/* Release 3.2 - 7.1 - Starts */}
            <TableAction
              primary
              disabled={isSubmitting}
              onClick={handleExportData}
            >
              Export To Excel
            </TableAction>
            {/* Release 3.2 - 7.1 - Ends */}
            <TableAction onClick={toggleDisplayCurrency}>{`Switch to ${forceCurrency === 'EUR' ? 'local currency' : 'EUR'}`}</TableAction>
          </TableActionsWrap>
        )}
      </TableHeadingWrap>
      {/* <Table recordType="requests" columns={columns.myRequests} query="requests" where={queryFilter} order={orderBy}/> */}
      {/* DEFECT No. 14 Statrs  */}
      <Table
        recordType="requests"
        columns={columns.myRequests}
        query="requests"
        where={queryFilter}
        order={orderBy}
        isEuroSort={forceCurrency === "EUR" ? true : false}
        config={config}
        account={account}
        /* Release 3.1 - Starts */
        key={tableRefreshKey}
        /* Release 3.1 - Ends */
      />
      {/* DEFECT No. 14 Ends  */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
    messages: state.messages,
    // {/* DEFECT No. 14 Statrs  */}
    config: state.config,
    // {/* DEFECT No. 14 Ends  */}
  };
};

export default connect(mapStateToProps, {
  showModal,
  unselectAllRecord,
  toggleShowInEur,
})(MyRequests);
