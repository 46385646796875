/* Release 3.2 - 8.1 - Starts */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FormHeading,
  FormButtonsWrap,
  FormButton,
  Table,
  TableActionsWrap,
  TableAction,
  TableHeadingWrap,
} from "../";
import { columns } from "../../util/config";
import { unselectAllRecord, pushMessage } from "../../actions";

const CompensationData = (props) => {
  const [queryFilter, setTableFilter] = useState({});
  const [tableName, setTableName] = useState("Job Grade");
  const [tableQuery, setTableQuery] = useState("jobGradeHistoryList");
  const [tableColumns, setTableColumns] = useState(columns.jobGradeHistory);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const { selectedEmployees, hideModal, unselectAllRecord } = props;

  const orderBy = {};

  useEffect(() => {
    if (selectedEmployees && selectedEmployees.length > 0) {
      setEmployeeDetails(selectedEmployees[0]);
      setTableFilter({ pers_no: { eq: selectedEmployees[0].pers_no } });
    }
  }, []);

  const handleQuit = () => {
    hideModal();
    unselectAllRecord("employees");
  };

  return (
    <div>
      <FormHeading>Compensation Data</FormHeading>
      <TableHeadingWrap
        style={{  marginBlockEnd: "1em" }}
      >
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b>Nokia ID :</b>
          </label>
          {employeeDetails && employeeDetails.nokia_id
            ? employeeDetails.nokia_id
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> Name : </b>
          </label>
          {employeeDetails && employeeDetails.full_name
            ? employeeDetails.full_name
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> UPI ID : </b>
          </label>
          {employeeDetails && employeeDetails.upi_id
            ? employeeDetails.upi_id
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> Requisition ID : </b>
          </label>
          {employeeDetails && employeeDetails.requisition_id
            ? employeeDetails.requisition_id
            : ""}
        </div>
      </TableHeadingWrap>
      <TableHeadingWrap
        style={{  marginBlockEnd: "1em" }}
      >
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b>Line Manager :</b>
          </label>
          {employeeDetails && employeeDetails.line_manager_full_name
            ? employeeDetails.line_manager_full_name
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> Recent ASR Increase % : </b>
          </label>
          {employeeDetails && employeeDetails.most_recent_ASR_increase
            ? employeeDetails.most_recent_ASR_increase
            : ""}
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> Date Of Latest Position Change : </b>
          </label>
          {employeeDetails && employeeDetails.date_of_latest_position_change
            ? employeeDetails.date_of_latest_position_change
            : ""}
        </div>
      </TableHeadingWrap>
      <FormButtonsWrap>
        <FormButton
          primary= {tableQuery == "jobGradeHistoryList"}
          onClick={(e) =>{
            setTableName("Job Grade");
            setTableQuery("jobGradeHistoryList");
            setTableColumns(columns.jobGradeHistory);
           
          }
        
        }
        >
          Job Grade
         
        </FormButton>
      
      
         
        <FormButton
          primary= {tableQuery == "targetIncentiveHistoryList"}
          onClick={(e) => {
            setTableName("Incentive Target Amount");
            setTableQuery("targetIncentiveHistoryList");
            setTableColumns(columns.targetIncentiveHistory);
          }}
        >
          Incentive Target Amount
        </FormButton>
        {/*start the demand */}
        <FormButton
          primary= {tableQuery == "actualPaidIncentiveHistoryList"}
          onClick={(e) => {
            setTableName("Additional Payments");
            setTableQuery("actualPaidIncentiveHistoryList");
            setTableColumns(columns.actualPaidIncentiveHistory);
          }}
        >
          Additional Payments
        </FormButton>
          {/*End the demand */}
        <FormButton
          primary= {tableQuery == "organizationalHistoryList"}
          onClick={(e) => {
            setTableName("Organizational History");
            setTableQuery("organizationalHistoryList");
            setTableColumns(columns.organizationalHistory);
          }}
        >
          Organizational History
        </FormButton>
        <FormButton
          primary= {tableQuery == "salaryHistoryList"}
          onClick={(e) => {
            setTableName("Salary History");
            setTableQuery("salaryHistoryList");
            setTableColumns(columns.salaryHistory);
          }}
        >
          Salary History
        </FormButton>
        {/* Release 3.3 - 8.3 - Starts */}
        <FormButton
          primary= {tableQuery == "roCountryStatisticsList"}
          onClick={(e) => {
            setTableName("RO Country Statistic");
            setTableQuery("roCountryStatisticsList");
            setTableColumns(columns.roCountryStatistics);
          }}
        >
          {/*  DEM0069361 - TAP Access Part 3 Starts */}
          RO Country Statistic & Education
          {/*  DEM0069361 - TAP Access Part 3 Ends */}
        </FormButton>
        <FormButton
          primary= {tableQuery == "roInsuranceTaxDataList"}
          onClick={(e) => {
            setTableName("RO Insurance Tax Data");
            setTableQuery("roInsuranceTaxDataList");
            setTableColumns(columns.roInsuranceTaxData);
          }}
        >
          RO Insurance Tax Data
        </FormButton>
        <FormButton
          primary= {tableQuery == "frLocalPayAttributesList"}
          onClick={(e) => {
            setTableName("FR Local Pay Attributes");
            setTableQuery("frLocalPayAttributesList");
            setTableColumns(columns.frLocalPayAttributes);
          }}
        >
          FR Local Pay Attributes
        </FormButton>
        {/* Release 3.3 - 8.3 - Ends */}
      </FormButtonsWrap>
      {employeeDetails && queryFilter.pers_no && (
        <React.Fragment>
          <FormHeading> {tableName} </FormHeading>
          <Table
            recordType="employeeCompensationDashboard"
            columns={tableColumns}
            query={tableQuery}
            where={queryFilter}
            order={orderBy}
            checkboxIsDisplayed={false}
          />
        </React.Fragment>
      )}
      {/*  DEM0069361 - TAP Access Part 3 Starts */}
      
      {tableName=='RO Country Statistic' && (
        <React.Fragment>
        <FormHeading>Education Information</FormHeading>
        <Table
            recordType="employeeCompensationDashboard"
            columns={columns.roEducation}
            query="roEducationList"
            where={queryFilter}
            order={orderBy}
            checkboxIsDisplayed={false}
          />
        </React.Fragment>
       )}
      
      


      {/*  DEM0069361 - TAP Access Part 3 Ends */}      
           <React.Fragment></React.Fragment>
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
      </FormButtonsWrap>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
  };
};

export default connect(mapStateToProps, { unselectAllRecord, pushMessage })(
  CompensationData
);
/* Release 3.2 - 8.1 - Ends */
