/* Release 3.1 - Starts */
import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { connect } from "react-redux";
import { Ring } from "react-awesome-spinners";
import {
  FormButton,
  FormButtonsWrap,
  ErrorsBlock,
  LoaderWrap,
  FormHeading,
  IconWrap,
  FormLabel,
  DescriptionInput,
} from "../";
import { unselectAllRecord, pushMessage } from "../../actions";
import { CANCEL_APPROVED_REQUEST_WITH_BUDGET, requests, organizationBEABudget } from "../../graphql";

const CancelApprovedRequest = (props) => {
  const {
    selectedRequests,
    account,
    hideModal,
    unselectAllRecord,
    pushMessage,
  } = props;

  const queryFilter = { requester_pers_no: { eq: account.pers_no } };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [comment, setComment] = useState("");
  const [cancelRequestWithBudget] = useMutation(
    CANCEL_APPROVED_REQUEST_WITH_BUDGET
  );

  const handleQuit = (event) => {
    hideModal();
    unselectAllRecord("requests");
  };

  const handleCommentChange = (event) => setComment(event.target.value);

  const handleRequestCancelSend = async (event) => {
    setIsSubmitting(true);
    setSubmitErrors([]);
    if (!comment) {
      setSubmitErrors(["Comment is required!"]);
      setIsSubmitting(false);
      return null;
    } else if (comment && comment.replace(/\s/g, "").length == 0) {
      setSubmitErrors(["Comment is required!"]);
      setIsSubmitting(false);
      return null;
    } else {
      const results = await Promise.all(
        selectedRequests.map(async ({ employee_request_id }) => {
          try {
            await cancelRequestWithBudget({
              variables: {
                employee_request_id,
                comment,
              },
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: organizationBEABudget,
                  variables: {
                    where: { year: { eq: new Date().getFullYear().toString() } },
                  },
                },
              ],
            });
            return { [employee_request_id]: false };
          } catch (e) {
            return { [employee_request_id]: e.message };
          }
        })
      );
      const errors = results.filter((r) =>
        Object.keys(r).find((r_id) => r[r_id])
      );
      if (!errors.length) {
        hideModal();
        unselectAllRecord("requests");
        pushMessage({
          type: "success",
          message: "Request(s) successfully canceled.",
        });
      } else {
        setSubmitErrors(errors.map((e) => Object.values(e).shift()));
        setIsSubmitting(false);
      }
    }
  };

  var selectedEmployeeRequestIds = selectedRequests.map(function (el) {
    return el.employee_request_id;
  });

  
  return (
    <div>
      <FormHeading>
        Are you sure you wish to cancel request for {selectedEmployeeRequestIds.join()} ? If
        yes then budget will be roll back.
      </FormHeading>
      <FormLabel>Comment</FormLabel>
      <DescriptionInput name="comment" onChange={handleCommentChange} />
      {submitErrors.length > 0 &&
        submitErrors.map((err) => {
          return (
            <ErrorsBlock key={Math.random()}>
              <IconWrap>!</IconWrap>
              {err}
            </ErrorsBlock>
          );
        })}
      <FormButtonsWrap>
        {/* DEFECT No. 6 STARTS  */}
        <FormButton onClick={handleQuit}>Quit</FormButton>
        <FormButton
          primary
          disabled={isSubmitting}
          onClick={handleRequestCancelSend}
        >
          Cancel approved request(s)
        </FormButton>
        {/* DEFECT No. 6 ENDS  */}
        {isSubmitting && (
          <LoaderWrap>
            <Ring color={"#001235"} size="2" sizeUnit="rem" />
          </LoaderWrap>
        )}
      </FormButtonsWrap>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
  };
};

export default connect(mapStateToProps, { unselectAllRecord, pushMessage })(
  CancelApprovedRequest
);

/* Release 3.1 - Ends */
