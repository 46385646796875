import { itpRenderer } from '../../renderers';

export default (table) => {
  let name = 'new_incentive_target_percentage';
  let defaultValue = (value, data) => data.new_incentive_target_percentage;
  let renderer = (value, data, account, config) => itpRenderer('new', value, data, account, config);
  switch (table) {

      case 'editInternalHire':
      name = 'request.new_incentive_target_percentage';

      defaultValue = (value, data) => data.request.new_incentive_target_percentage;
      break;
    default:
      break;
  }
 

  return { 
    type: "float",
    name,
    label: "New Incentive Target %",
    section: "internalHire",
    colFrom : "internalHire",
    defaultValue,
    visible: true,
    renderer,
    
    width : '10%',
  };
};