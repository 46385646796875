import { requestTypeRenderer } from '../../renderers';

export default (table) => {
  let name = 'request_type';
  let sortable = false;
  let width = undefined;

  switch (table) {
    case 'myRequests':
    case 'requestsHistory':
        //Width Defect - px -> %
      width = "8%";
      sortable = true;
      break;
    case 'approvedRequestHistory':
    case 'pendingRequests':
    case 'rejectRequests':
      name = 'request.request_type';
      width = "100px";
      break;
    case 'approveRequests':
      name = 'request.request_type';
      width = "100px";
      break;
    default:
      break;
  }

  return {
    type: "string",
    name,
    label: "Request Type",
    visible: true,
    width,
    renderer: requestTypeRenderer,
    sortable,
  };
};
