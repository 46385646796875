/* Release 3.2 - 13.1 - Starts */
import { numberRenderer, currencyDisplayRenderer } from "../../renderers";

export default (table) => {
  let name = "sum_of_pay_amount";
  let renderer = (value, data, account, config) => {
    let case_3_12_month_actual_incentive_amount_fte = 0;
    let addl_pay_amount = 0;
    let sum_of_pay_amount = 0;
    let data_currency = { currency: "EUR" };
    switch (table) {
      case "internalHire":
        case_3_12_month_actual_incentive_amount_fte =
          data.case_3_12_month_actual_incentive_amount_fte
            ? data.case_3_12_month_actual_incentive_amount_fte
            : 0;
        addl_pay_amount = data.lastYearWageHistory
          ? data.lastYearWageHistory.addl_pay_amount
          : 0;
        data_currency = data;
        break;

      case "editInternalHire":
        name = "request.sum_of_pay_amount";
        case_3_12_month_actual_incentive_amount_fte = data.request
          .case_3_12_month_actual_incentive_amount_fte
          ? data.request.case_3_12_month_actual_incentive_amount_fte
          : 0;
        addl_pay_amount = data.request.lastYearWageHistory
          ? data.request.lastYearWageHistory.addl_pay_amount
          : 0;
        data_currency = data.request;
        break;
    }
    sum_of_pay_amount =
      case_3_12_month_actual_incentive_amount_fte + addl_pay_amount;
    // console.log("sum_of_pay_amount value", sum_of_pay_amount);
    let currencyDisplayRendererValue = currencyDisplayRenderer(
      sum_of_pay_amount,
      data_currency,
      account,
      config
    );
    // console.log("sum_of_pay_amount currencyDisplayRendererValue", currencyDisplayRendererValue);
    return currencyDisplayRendererValue;
  };
  let label = /* Release 3.2 - 13.1 - 149299 - Starts */"Case 3-12 months Total Target Cash"/* Release 3.2 - 13.1 - 149299 - Ends */;
  let width = "120px";

  return {
    type: "float",
    name,
    label,
    visible: true,
    renderer,
    width,
  };
};
/* Release 3.2 - 13.1 - Ends */
