/* Release 3.2 - 1.1 - Starts */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { apolloClient, apolloProxyClient } from "../../index";
import { Grid } from "react-awesome-spinners";
import { connect } from "react-redux";
import { Ring } from "react-awesome-spinners";
import {
  unselectAllRecord,
  budgetSetUpdated,
  pushMessage,
  selectRecord,
  updateRecord,
} from "../../actions";
import {
  DatePickerField,
  SelectedTable,
  CalculatorNew,
  FormButton,
  FormButtonsWrap,
  ErrorsBlock,
  LoaderWrap,
  FormLabel,
  FormLabelInline,
  FormHeading,
  DescriptionInput,
  IconWrap,
} from "../";
import { columns } from "../../util/config";
import {
  CREATE_REQUEST_WITH_BUDGET,
  BUDGET,
  requests,
  moc_requests,
  moc_edit,
} from "../../graphql";
import { convertToUTC } from "../../util/helper";
import { Pagination, GridLoader } from "../";
import exportFromJSON from "export-from-json";
import {
  currencyDisplayRenderer,
  percentRenderer,
  numberRenderer,
} from "../../util";
import "react-datepicker/dist/react-datepicker.css";

const Mocbeasubmission = (props) => {
  const {
    modal,
    account,
    config,
    hideModal,
    selectedEmployees,
    unselectAllRecord,
    budgetSetUpdated,
    selectRecord,
    pushMessage,
  } = props;  
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const indexColumn = columns.requestBusinessExcellenceAward.find((c) => c.index);

  const DEFAULT_PAGE_SIZE = 20;
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const pageOffset = (currentPage - 1) * pageSize;
  const [requestDescription, setRequestDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const now = new Date();
  const nextPrevMonth =
    now.getDate() >= 20
      ? new Date(now.getFullYear(), now.getMonth() + 2, 1)
      : new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const [paymentDate, setPaymentDate] = useState(nextPrevMonth);

  const [createRequest] = useMutation(CREATE_REQUEST_WITH_BUDGET);
  const [data, setresdata] = useState(undefined);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(false);
  const [calciarray, setcalci] = useState([]);
  const [pagetrigger, setpagetrigger] = useState(false);

  const [editMOC] = useMutation(moc_edit);
  useEffect(() => {
    handleTrigger();
  }, [data]);
  useEffect(() => {
    setloading(true);
    httpClient
      .query({
        query: moc_requests,
        variables: {
          where: modal.modalContent.where,
          limit: parseInt(pageSize),
          offset: pageOffset,
          orderBy: null,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response.data && response.data.moc_requests) {
          response.data.moc_requests.items.forEach((element) => {
            const ele = { ...element, ...element.for };
            delete ele.for;
            selectRecord(ele, indexColumn, "employees");
          });
          setresdata(response.data);
          setloading(false);
          seterror(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setresdata(undefined);
        setloading(false);
        seterror(true);
      });
  }, [pagetrigger]);

  const onPageSizeChange = (event) => {
    setloading(true);
    unselectAllRecord("employees");
    setPageSize(event.target.value);
    setCurrentPage(1);
    setpagetrigger(pagetrigger ? false : true);
  };

  const onPageChange = (currentPage) => {
    setloading(true);
    unselectAllRecord("employees");
    setCurrentPage(currentPage);
    setpagetrigger(pagetrigger ? false : true);
  };

  const handleChange = (args) => (event) => {
    console.log("On change called");
    const { column, columns, item } = args;
    const indexColumn = columns.find((c) => c.index);
    let value =
      column.inputType === "checkbox"
        ? event.target.checked
        : event.target.value;

    value =
      (column.inputType !== "checkbox" && parseInt(value)) < 0 ? 0 : value;

    const calarray = [];

    calarray.push(
      <CalculatorNew
        key={1}
        columns={columns}
        recordType="employees"
        item={item[indexColumn.name]}
        data={{
          request_type: "businessexcellenceaward",
          [column.name]: column.type === "float" ? parseFloat(value) : value,
        }}
      />
    );
    setcalci(calarray);
  };

  const handleDescriptionChange = (event) =>
    setRequestDescription(event.target.value);

  const handlePaymentDateChange = (date) => setPaymentDate(date);

  const handleQuit = () => {
    hideModal();
    unselectAllRecord("employees");
  };

  const hasBudgetVisibility =
    account.accessibles && account.accessibles.budgetOrgs.length > 0;

  const handleRequestBEASend = async () => {
    setIsSubmitting(true);
    setSubmitErrors([]);

    if (!requestDescription) {
      setSubmitErrors(["Reason is required!"]);
      setIsSubmitting(false);
      return null;
    }

    let refetchAfterCreateRequest = [
      {
        query: requests,
        variables: {
          where: { requester_pers_no: { eq: account.pers_no } },
          limit: 20,
          offset: 0,
          orderBy: [{ column: "created_ts", order: "DESC" }],
        },
      },
    ];
    if (hasBudgetVisibility) {
      account.accessibles.budgetOrgs.forEach((b) =>
        refetchAfterCreateRequest.push({
          query: BUDGET,
          variables: { organization_unit: b },
        })
      );
    }

    const results = await Promise.all(
      selectedEmployees.map(
        async ({
          pers_no,
          nokia_id,
          country_name,
          currency,
          ttc_increase_amount,
          ttc_increase_percentage,
          fte_annual_salary,
          request_type,
          offcycle_budget_update,
          // payment_type,
          // payment_reason,
          //target_budget_org,
          organization_unit_2_name,
          organization_unit_1_name,
          award_title,
          award_message,
          award_privacy,
          award_reason,
          nominator_email_address,
        }) => {
          let lumpsum_abs_percentage = (ttc_increase_amount / fte_annual_salary) * 100;
          try {
            await createRequest({
              variables: {
                data: {
                  payment_date: convertToUTC(paymentDate),
                  request_description: requestDescription,
                  requester_pers_no: account.pers_no,
                  request_country: country_name,
                  request_type,
                  pers_no,
                  ttc_increase_amount,
                  ttc_increase_percentage,
                  lumpsum_abs_percentage: lumpsum_abs_percentage,
                  currency,
                  offcycle_budget_update,
                  // payment_type,
                  // payment_reason,
                  //target_budget_org,
                  organization_unit_2_name,
                  organization_unit_1_name,
                  award_title,
                  award_message,
                  award_privacy,
                  award_reason,
                  nominator_email_address,
                },
              },
              awaitRefetchQueries: true,
              refetchQueries: refetchAfterCreateRequest,
            });

            return {
              pers_no: false,
              "Person No.": pers_no,
              "Nokia ID": nokia_id,
              Reason: "Successfully saved the data",
            };
          } catch (e) {
            const err_request_exist = e.graphQLErrors.some(
              (er) => er.extensions.code === "EXISTING_REQUEST"
            );
            return {
              pers_no: err_request_exist
                ? `There is already a pending request for employee ${nokia_id}`
                : e.message,
              "Person No.": pers_no,
              "Nokia ID": nokia_id,
              Reason: e.message,
            };
          }
        }
      )
    );

    const errors = results.filter((r) => r["pers_no"]);
    await editMOC({
      variables: {
        where: {
          request_type: { eq: "businessexcellenceaward" },
          import_status: { eq: "pending" },
          requester_pers_no: { eq: account.pers_no.toString() },
          pers_no: {
            in: results
              .filter((r) => !r["pers_no"])
              .map((e) => e["Person No."]),
          },
        },
        data: { import_status: "completed", Submission_Status: "Success" },
      },
      awaitRefetchQueries: false,
      refetchQueries: [
        {
          query: moc_requests,
          variables: {
            where: modal.modalContent.where,
            limit: parseInt(pageSize),
            offset: pageOffset,
            orderBy: null,
          },
          fetchPolicy: "network-only",
        },
      ],
    });

    if (errors.length > 0) {
      const fileName = "MassOffCycle_BusinessExcellenceAward" + "_" + new Date().toISOString();
      const exportType = "csv";
      errors.forEach(function (v) {
        delete v["pers_no"];
      });

      const data = errors;
      exportFromJSON({ data, fileName, exportType });
      alert(
        "Some or all of the requests submitted are failed. Please review the requests on the CSV that would be downloaded"
      );
      setIsSubmitting(false);
    } else {
      hideModal();
      pushMessage({
        type: "success",
        message: "Request(s) successfully reviewed.",
      });
    }
    // hideModal();
    unselectAllRecord("employees");
    setIsSubmitting(false);
    setCurrentPage(1);
    setpagetrigger(pagetrigger ? false : true);
    budgetSetUpdated(true);
  };
  const handleTrigger = () => {
    const calarray = [];
    selectedEmployees.forEach((element, i) => {
      calarray.push(
        <CalculatorNew
          key={i}
          columns={columns.requestBusinessExcellenceAward}
          recordType="employees"
          item={element[indexColumn.name]}
          data={{
            request_type: "businessexcellenceaward",
            ttc_increase_amount:
              parseFloat(element.ttc_increase_amount) < 0
                ? 0
                : parseFloat(element.ttc_increase_amount),
          }}
        />
      );
    });
    setcalci(calarray);
  };

  const handleExportData = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (selectedEmployees && selectedEmployees.length > 0) {
      var data = selectedEmployees.map(function(item){
        // let current_itp_situation = item && item.current_itp_situation ? item.current_itp_situation : 'Norm'
        // let new_itp_situation = item.new_itp_situation ? item.new_itp_situation : 'Norm';
        
        let total_increaseed_percent = 0;
        total_increaseed_percent = item.ttc_increase_percentage;
    
        return {
          "ID": item.employee_request_id,
          "Pers No.": item.pers_no,
          "Nokia ID": item.nokia_id,
          "Full Name": item.full_name,
          //"Current Total Target Cash": item && item.fte_annual_salary ? currencyDisplayRenderer(item.fte_annual_salary, item) : '',
          "Award Amount": currencyDisplayRenderer(item.ttc_increase_amount, item),
          "% In Relation to Annual Base Salary": percentRenderer((item.ttc_increase_amount / item.fte_annual_salary) * 100),
          "% In Relation To Total Target Cash": percentRenderer(total_increaseed_percent),
          // "Payment Type": item.payment_type ? item.payment_type : '',
          // "Payment Reason": item.payment_reason ? item.payment_reason : '',
          // "Target Budget": item.target_budget_org ? item.target_budget_org : '',
          // "Current-Country": item && item.country_name ? item.country_name : '',
          // "Current-Job Family": item && item.job_family_name ? item.job_family_name : '',
          // "Current-Job Sub Family": item && item.job_subfamily_name ? item.job_subfamily_name : '', 
          // "Current-Job": item && item.job_name ? item.job_name : '',
          // "Current-Job Grade": item && item.job_grade ? item.job_grade : '', 
          // "Current-Position Title": item && item.position_title ? item.position_title : '', 
          // "Current-Annual Base Salary": item && item.fte_annual_salary ? currencyDisplayRenderer(item.fte_annual_salary, item) : '',
          // "Current-Incentive Target Percentage": item && item.fte_incentive_target_percentage ? percentRenderer(item.fte_incentive_target_percentage) + ' ' + current_itp_situation : '', 
          // "Current-Incentive Target Amount": item && item.fte_incentive_target_amount ? currencyDisplayRenderer(item.fte_incentive_target_amount, item) : '',
          // "Current-Total Target Cash": item && item.fte_ttc_annual_salary ? currencyDisplayRenderer(item.fte_ttc_annual_salary, item) : '', 
          // "Current-TTC Compa-Ratio": item && item.ttc_compa_ratio ? numberRenderer(item.ttc_compa_ratio) : '', 
          // "Current-Incentive Plan": item && item.incentive_plan_type ? item.incentive_plan_type : '', // verify this 
          // "Current-Quartile": item && item.ttc_employee_quartile ? item.ttc_employee_quartile : '', 
          // "BEA Budget Spending": item.offcycle_budget_update ? currencyDisplayRenderer(item.offcycle_budget_update, item) : '',
          "Award Title": item.award_title ? item.award_title : '',                                
          "Award Message": item.award_message ? item.award_message : '',                                
          "Award Privacy": item.award_privacy ? item.award_privacy : '',                                
          "Award Reason": item.award_reason ? item.award_reason : '',                                
          "Award Amount": (item.ttc_increase_amount && item.request_type == 'businessexcellenceaward') ? item.ttc_increase_amount : '', 
          "Nominator Email Address": item.nominator_email_address ? item.nominator_email_address : '',                                              
        }
      });
      data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':""'));
      const fileName = "massoffcycle-businessexcellence" + new Date().toISOString();
      const exportType = "csv";
      exportFromJSON({ data, fileName, exportType });
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      alert("No Record Found to Export the Excel");
    }
  };

  return (
    <div>
      <FormHeading>Request Business Excellence Award</FormHeading>
      <FormLabel>Selected employees: {selectedEmployees.length}</FormLabel>
      <FormHeading 
        style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
      <div>
          Total Business Excellence Award Budget Spend : &euro;{" "}
        {selectedEmployees
          .reduce(
            (accumulator, current) =>
              accumulator +
              current.offcycle_budget_update * current.exchange_rate,
            0
          )
          .toFixed(2)}
        </div>
        {!loading && data && (     
            <FormButton
              disabled={isSubmitting}
              onClick={handleExportData}
            >
              Export To Excel
            </FormButton> 
        )}
      </FormHeading>
      {loading && (
        <GridLoader>
          <Grid color={"#001235"} />
        </GridLoader>
      )}
      {error && (
        <div>
          <h2>Unable to fetch the pending requests</h2>
        </div>
      )}
      
      {!loading && data && (
        <div style={{ overflowX: "auto" }}>
        <SelectedTable
          recordType="employees"
          items={selectedEmployees}
          columns={columns.requestBusinessExcellenceAward}
          onChange={handleChange}
          infoTooltip={false}
        />
        </div>
      )}
      {!loading && data && (
        <Pagination
          count={data.moc_requests.totalCount}
          currentPage={currentPage}
          pageSize={pageSize}
          reqFrom="moc"
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
        />
      )}
      {!loading && data && <FormLabelInline>Payment date:</FormLabelInline>}
      {!loading && data && (
        <DatePickerField
          selected={paymentDate}
          onChange={handlePaymentDateChange}
          disabled={true}
        />
      )}
      {!loading && data && (
        <FormLabel>
          Reason for request:
          <i style={{ width: "100%", float: "left" }}>
            [Please do not use the Reason field for Backdated changes dates as
            this field is visible only for approvers and not updating CoreHR
            System!]
          </i>
        </FormLabel>
      )}
      {!loading && data && (
        <DescriptionInput
          name="request_description"
          onChange={handleDescriptionChange}
        />
      )}
      {submitErrors.length > 0 &&
        submitErrors.map((err) => {
          return (
            <ErrorsBlock key={Math.random()}>
              <IconWrap>!</IconWrap>
              {err}
            </ErrorsBlock>
          );
        })}
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
        <FormButton
          primary
          disabled={isSubmitting}
          onClick={handleRequestBEASend}
        >
          Send request(s)
        </FormButton>
        {isSubmitting && (
          <LoaderWrap>
            <Ring color={"#001235"} size="2" sizeUnit="rem" />
          </LoaderWrap>
        )}
      </FormButtonsWrap>
      <>{calciarray}</>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
    config: state.config,
  };
};

export default connect(mapStateToProps, {
  unselectAllRecord,
  budgetSetUpdated,
  pushMessage,
  selectRecord,
  updateRecord,
})(Mocbeasubmission);
/* Release 3.2 - 1.1 - Ends */
