import React from 'react';
import { FilterWrap } from '../styled';

const SelectFilter = props => {
  const { options, onSelectChange } = props;

  return (
    <FilterWrap>
      <select onChange={onSelectChange}>
        {options.map((o, index) => <option key={index} value={o}>{o}</option>)}
      </select>
    </FilterWrap>
  );
};

export { SelectFilter };
