import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

const BudgetWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 40px;
  justify-content: flex-end;
  background-color: #edf2f5;
  margin: 1rem 0;
  padding: 1rem 2rem;
`;

const BudgetTrigger = styled.a`
  
`;

const BudgetContent = styled.div`
  width: 100%;
`;

const BudgetRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BudgetCell = styled.div`
  flex: 1;
`;

const BudgetLabel = styled.span`
  font-weight: bold;
`;

const UpdatedSpan = styled.span`
  line-height: 1rem;
  padding: 10px 35px;
  border-radius: 5px;
  background-color: #ADCAF8;
`;

const AllBudgetsLink = styled(NavLink)`
  color: #0065ed;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.strongTextColor};
    text-decoration: none;
  }
`;

export {
  BudgetWrap,
  BudgetTrigger,
  BudgetContent,
  BudgetRow,
  BudgetCell,
  BudgetLabel,
  UpdatedSpan,
  AllBudgetsLink
};
