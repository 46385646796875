import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

const MainMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  list-style: none;
  list-style-image: none;
  list-style-type: none;
  margin: 0;
  padding: 0; 
`;
const SubMainMenu =  styled.ul`
display: flex;
margin-top: 23px;
    position: absolute;
    visibility: hidden;
  opacity: 0;
  display: none;
  
justify-content: left;
flex-direction: column;
list-style: none;
list-style-image: none;
list-style-type: none;
`;

const MainMenuItem = styled.li`
  display: flex;
  margin: 0;
  list-style: none;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  &:hover > ul {   visibility: visible;
    opacity: 1;
    display: block; }
`;


const SubMenuItem = styled.li`
  display: flex;
  margin: 0;
  list-style: none;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  &:hover > ul {   visibility: visible;
    opacity: 1;
    display: block; }
`;

const SubMenuLink = styled.li`
display: flex;
margin: 0;
list-style: none;
flex-direction: column;
flex-shrink: 0;
align-items: left;
`;

const MainMenuLink = styled(NavLink)`
  display: flex;
  position: relative;
  height: 100px;
  padding: 0 1rem;
  align-items: center;
  transform: translateZ(0);
  font-size: 16px;
  color: ${({ theme }) => theme.strongTextColor};
  z-index: 1;
  transition: all 0.3s ease-in-out;
  text-decoration: underline;

  &:hover {
    color: #0065ed;
    text-decoration: none;
  }

  &.active {
    color: #0065ed;
    text-decoration: none;
    font-weight: bold;
  }
`;

const InActiveMainMenuLink = styled(NavLink)`
  display: flex;
  position: relative;
 
  padding: 0 1rem;
  align-items: center;
  transform: translateZ(0);
  font-size: 16px;
  color: ${({ theme }) => theme.strongTextColor};
  z-index: 1;
  transition: all 0.3s ease-in-out;
  text-decoration: underline;

  &:hover {
    color: #0065ed;
    text-decoration: none;
  }


`;

const SubMainMenuLink = styled(NavLink)`
  display: flex;
  position: relative;
 
  align-items: left;
  transform: translateZ(0);
  font-size: 16px;
  color: ${({ theme }) => theme.strongTextColor};
  z-index: 1;
  transition: all 0.3s ease-in-out;
  text-decoration: underline;

  &:hover {
    color: #0065ed;
    text-decoration: none;
  }

  &.active {
    color: #0065ed;
    text-decoration: none;
    font-weight: bold;
  }
`;

const FooterMenu = styled.ul`
  justify-content: flex-end;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const FooterMenuItem = styled.li`
  margin-left: 24px;
  list-style: none;
  font-size: 9px
`;

export {
  MainMenu,
  MainMenuItem,
  MainMenuLink,
  FooterMenu,
  FooterMenuItem,
  SubMenuLink,
  SubMainMenu ,
  SubMainMenuLink,
InActiveMainMenuLink,
SubMenuItem


};
