import React, {useState} from 'react';
import {apolloClient, apolloProxyClient } from '../index';
import { useMutation } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { Ring } from 'react-awesome-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faCalculator, faFileCode, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { Header } from '../components';
import { Select, FormButton, FormButtonsWrap, LoaderWrap, FormHeading, CommonLink} from '../components/styled';
import excelContent from '../../src/FormulaReferenceExcelContent.js';
import { GET_FORMULAS, UPDATE_FORMULA, GET_FORMULAS_META_INFO } from '../graphql'; //R2 - Admin Calculations
import fileSaver from 'file-saver'
const {tokenize} = require('excel-formula-tokenizer');
    
const AdminCalculator = props => {
  const { account } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const { roles } = account;
 
  const isAdmin = roles.includes("admin");
  
 
    const [formulas, setFormulas] = useState([]);
    const [metaInfo, setFormulaMeta] = useState([]);
    const [orgFormula, setOrgFormula] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitErrors, setSubmitErrors] = useState([]);
    const [isCurrFormulaValid, setIsCurrFormulaValid] = useState(false);
    

    const [updateFormula] = useMutation(UPDATE_FORMULA);
    
    const labelstyle = {
        fontSize : '17px',
        marginLeft : '10px'
    }
  
    const textAreaStyle = {
        margin: '0 0 0 10px',
        padding: '5px',
        width: '406px',
        height: '84px',
        fontSize: '15px',
        border: '1px solid #ccc',
        fontFamily: 'NokiaPureHeadline'
    }
    const centerTable = {
        marginLeft : 'auto',
        marginRight : 'auto'
    }
    const tdLabel = {
        width: '150px'
    }
    const linkStyle = {
        display: 'inline-block',
        marginLeft: '168px',
        position: 'absolute',
        marginTop: '-28px'
    };
    const selectStyle = {
        fontSize: '15px'
    }

    var getFromBetween = {
        results:[],
        string:"",
        getFromBetween:function (sub1,sub2) {
            if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
            var SP = this.string.indexOf(sub1)+sub1.length;
            var string1 = this.string.substr(0,SP);
            var string2 = this.string.substr(SP);
            var TP = string1.length + string2.indexOf(sub2);
            return this.string.substring(SP,TP);
        },
        removeFromBetween:function (sub1,sub2) {
            if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
            var removal = sub1+this.getFromBetween(sub1,sub2)+sub2;
            this.string = this.string.replace(removal,"");
        },
        getAllResults:function (sub1,sub2) {
            // first check to see if we do have both substrings
            if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;
    
            // find one result
            var result = this.getFromBetween(sub1,sub2);
            // push it to the results array
            this.results.push(result);
            // remove the most recently found one from the string
            this.removeFromBetween(sub1,sub2);
    
            // if there's more substrings
            if(this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
                this.getAllResults(sub1,sub2);
            }
            else return;
        },
        get:function (string,sub1,sub2) {
            this.results = [];
            this.string = string;
            this.getAllResults(sub1,sub2);
            return this.results;
        }
    };

    function downloadFile(evnt){
        evnt.preventDefault();
        let fileName = 'Reference Data for Calculator.xlsx';
        const blob = base64toBlob(excelContent,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        fileSaver.saveAs(blob, fileName);
        
    }
    function base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);
    
        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);
    
            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }
    //Show formulas list on load
    React.useEffect(() => {
        getFormulas().then((fms)=>{
            setFormulas(fms);
        });
        getFormulasMetaInfo().then((meta)=>{
            processMetaInfo(meta);
        });
    }, []); 

    const processMetaInfo = (metaInfo) => {
        console.log('metaInfo',metaInfo)
        let metaTemp = {};
        metaTemp.functions =  metaInfo[0].functions.split(',');
        metaTemp.fields =  metaInfo[0].fields.split(',');
        metaTemp.operators =  metaInfo[0].operators.split(',');
        setFormulaMeta(metaTemp);
    }
    
    const getFormulas = async() => {
        setIsSubmitting(true);
        setSubmitErrors([]);
        return await new Promise((resolve, reject) => {
            httpClient.query({
                query: GET_FORMULAS,
                variables: { },
            }).then((response) => {
                if (response.data && response.data.formulas) {
                  resolve( response.data.formulas);
                  setIsSubmitting(false);
                }
            }).catch((err) =>  
              console.error(err)
            );
        }).catch((err)=>{
            console.error(err)
        });
    }
    const getFormulasMetaInfo = async() => {
        setIsSubmitting(true);
        setSubmitErrors([]);
        return await new Promise((resolve, reject) => {
            httpClient.query({
                query: GET_FORMULAS_META_INFO,
                variables: { },
            }).then((response) => {
                if (response.data && response.data.formulaMetaInfo) {
                  resolve( response.data.formulaMetaInfo);
                  setIsSubmitting(false);
                }
            }).catch((err) =>  
              console.error(err)
            );
        }).catch((err)=>{
            console.error(err)
        });
    }
    
    const selectFormula = (ele) => {
        let formulaDetails;
        if(ele.currentTarget.value !==''){
            formulaDetails = __getFormulaByID(ele.currentTarget.value);
            console.log(formulaDetails);
            document.getElementById('frmAssigneeLI_formula').value = formulaDetails.formula;
            setOrgFormula({...formulaDetails});
            document.getElementById('frmAssigneeLI_formulaType').innerHTML = formulaDetails.formula_type;
            document.getElementById('frmAssigneeLI_selectedFormula').title = formulaDetails.formula_name;
            
        }else{
            setOrgFormula({});
            document.getElementById('frmAssigneeLI_formula').value = '';
            document.getElementById('frmAssigneeLI_formulaType').innerHTML = 'NA';
            document.getElementById('frmAssigneeLI_selectedFormula').title = '';
        }
        setIsCurrFormulaValid(false);
    }
    const cancelFormula = () => {
        console.log('orgFormula to cancel',orgFormula);
        let undo = window.confirm("Are you sure you want to undo the unsaved changes?");
        console.log('original formula', orgFormula)
        if (undo) document.getElementById('frmAssigneeLI_formula').value = orgFormula.formula;
    }
    const _getCurrentFormulaRecord = () => {
        let currentFormula = document.getElementById('frmAssigneeLI_formula').value;
        return {...orgFormula,formula:currentFormula};
    }
    const onFormulaUpdate = () => {
        setIsCurrFormulaValid(false);
    }
    const validateFormula = () => {
        let formulaErrors = [];
        metaInfo.fields.push('maxPromoIncrease')
        let supportedItems = {
            'functions': metaInfo.functions, 
            'fields': metaInfo.fields,
            'operators': metaInfo.operators,
            'ref_tbls': ['theoretical_itps_tbl','salary_ranges_tbl','country_codes_tbl']
        };
        console.log('supported functions',supportedItems.functions)
        let formulaToValidate = _getCurrentFormulaRecord();
        let tokens = [];
        try{
            tokens = tokenize(formulaToValidate.formula);
        }catch(er){
            alert('Please Check the Syntax of the Formula');
        }
        
        if(tokens.length === 0){
            alert('Please Select a Formula.');
            return false;
        }else{
            console.log('validating',formulaToValidate.formula);
            try{
                let hardCodedValues = getFromBetween.get(formulaToValidate.formula,"'","'");//formulaToValidate.
                console.log('hardCodedValues',hardCodedValues);
                console.log(tokens);
                let funcStartCount = 0;
                let funcStopCount = 0;
                tokens.forEach((item) => {
                    
                    if((item.type === 'function' || item.type === 'subexpression') && item.subtype === 'start'){
                        funcStartCount+=1;
                        if(!supportedItems.functions.includes(item.value)){
                            formulaErrors.push(`Function ${item.value} is Not Supported`);
                        }
                    }
                    if((item.type === 'function' || item.type === 'subexpression') && item.subtype === 'stop'){
                        funcStopCount+=1;
                    }
                    if(item.type === 'operator-infix' && !supportedItems.operators.includes(item.value)){
                        formulaErrors.push(`Operator ${item.value} is Not Supported`);
                    }
                    if(item.type === 'operand' && item.subtype === 'range' && !supportedItems.fields.includes(item.value) && !(hardCodedValues.includes(item.value))){
                        console.log(supportedItems.ref_tbls, ' -- ', item.value.split('['));
                        if(!supportedItems.ref_tbls.includes(item.value.split('[')[0])){
                            formulaErrors.push(`Field ${item.value} is Not Supported`);
                         }
                    }
                }); 
                if(funcStartCount !== funcStopCount){
                    formulaErrors.push('Syntax Check was Failed for the Formula. Please Re-Check the Formula.');
                }
                if(formulaErrors.length){
                    let allFErrors = formulaErrors.join('\n - ');
                    // formulaErrors.forEach(fError => {
                    //     allFErrors
                    // })
                    alert(`Formula Errors: \n - ${allFErrors}`);
                    setIsCurrFormulaValid(false);
                    return false;
                }else{
                    setIsCurrFormulaValid(true);
                    alert('Formula has been Validated successfully.');
                    return true;
                }
            }catch(ers){
                alert('Formula Validation was Failed! Please Re-Check the Formula.');
            }
            
        }        
    }
    
    const saveFormula = async () => {
        let formulaToSave = _getCurrentFormulaRecord();
        setIsSubmitting(true);
        console.log('update record',formulaToSave);
            await updateFormula({
                variables: {
                    formula_name: formulaToSave.formula_name,
                    formula_type: formulaToSave.formula_type,
                    field_name: formulaToSave.field_name,
                    formula: formulaToSave.formula
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     { query: GET_FORMULAS, variables: {} }
                // ]
              }).then((response) => {
                if (response.data.updateFormula) {
                    setOrgFormula(formulaToSave);
                    let fList = [...formulas];
                    Object.values(fList).forEach(function(key) {
                        if (key.formula_id === formulaToSave.formula_id) {
                          key.formula = formulaToSave.formula
                        }
                    });
                    setFormulas(fList, setIsSubmitting(false));
                    alert('Formula has been saved successfully.');
                    setIsCurrFormulaValid(false);
                }else{
                    setIsSubmitting(false);
                    alert('Formula Updation Failed!')
                }
            }).catch((err) => {
                setIsSubmitting(false);
                alert('Unable to update the Formula!');
                console.error(err)
            } 
              
            );
    }
    function doSort( a, b ) {
        if ( a.formula_name < b.formula_name ){
          return -1;
        }
        if ( a.formula_name > b.formula_name ){
          return 1;
        }
        return 0;
      }
    const formulaDropdownItems = () => {
        let items = [];
        const sorted = Object.values([...formulas]).sort(doSort);
        {sorted.map(item => {
            items.push(<option
            key={item.formula_id}
            value={item.formula_id}
            >
            {item.formula_name}{item.isOurTalentForumla?" (Our Talent)":""}
            </option>);
        })}
        return items;
    }
    const __getFormulaByID = (fid) => {
        return Object.values(formulas).find(key => key.formula_id === fid);
    }
  return (
    <>   
      <Header {...props}/>
     {isAdmin?
    <div>
        <div style={{textAlign:'center'}} >
            <FormHeading><h1>  Manage Calculations  <FontAwesomeIcon icon={faCalculator} /> </h1></FormHeading>
        </div>
        <table className="center" style={centerTable}>
            <tbody>
                <tr>
                    <td style={tdLabel}><label style={labelstyle}>Column Name</label></td>
                    <td>:</td>
                    <td>
                        <Select disabled={isSubmitting} style={{width: '406px',paddingTop: '5px', fontSize: '15px'}} name="formulaName" id="frmAssigneeLI_formulaName" onChange={selectFormula}>
                        <option value="">Select</option>
                        {formulaDropdownItems()}
                        </Select>
                    </td>
                </tr>
                <tr>
                    <td><label style={labelstyle}>Result Type</label></td>
                    <td>:</td>
                    <td>
                        <div style={labelstyle}> <strong id="frmAssigneeLI_formulaType">NA</strong></div>
                    </td>
                </tr>
                <tr>
                    <td><label style={labelstyle} id="frmAssigneeLI_selectedFormula" title="">Formula</label></td>
                    <td>:</td>
                    <td>
                        <textarea onChange={onFormulaUpdate} id="frmAssigneeLI_formula" style={textAreaStyle}></textarea>
                    </td>
                </tr>
                    <tr>
                    <td colSpan="3" style={{textAlign:"center"}}>
                        <FormButtonsWrap>
                            <FormButton disabled={isSubmitting} style={selectStyle} onClick={cancelFormula}> <FontAwesomeIcon icon={faUndo} /> Cancel</FormButton>
                            <FormButton disabled={isSubmitting} style={selectStyle} onClick={validateFormula} primary> <FontAwesomeIcon icon={faFileSignature} /> Validate</FormButton>
                            <FormButton disabled={isSubmitting || !isCurrFormulaValid} style={selectStyle} onClick={saveFormula} primary id="frmAssigneeLI_formulaSaveBtn"> <FontAwesomeIcon icon={faFileCode} /> Save</FormButton>
                            {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
                        </FormButtonsWrap>
                        <CommonLink style={linkStyle} onClick={downloadFile}>Download Reference Data</CommonLink>
                    </td>
                    </tr>
            </tbody>
        </table>
    </div>
    : 
    <div>You do not have permission to access this page</div>
     }
    </>
  );  
};

const mapStateToProps = state => {
  return {
    account: state.account
  };
};

export default connect(
  mapStateToProps
)(AdminCalculator);
