import styled from 'styled-components/macro';
import { DebounceInput } from 'react-debounce-input';
import DatePicker from "react-datepicker";

const ErrorMessage = styled.span`
  color: red;
`;

const LoaderWrap = styled.span`
  > div {
    width: 2rem;
    height: 2rem;
  }
`;

const FormButton = styled.button`
  cursor: pointer;
  color: ${({ disabled, primary }) => disabled ? '#cccccc' : primary ? '#ffffff' : '#0A61EE'};
  padding: 0.7rem 1rem 0.4rem 1rem;
  border: 1px solid;
  border-color: ${({ disabled }) => disabled ? '#cccccc' : '#0A61EE'};
  background-color: ${({ primary }) => primary ? '#0A61EE' : 'transparent'};
  border-radius: 5px;

  & + & {
    margin-left: 1rem;
  }
`;

const FormButtonsWrap = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
`;

const FormLabel = styled.div`
  margin-top: 2rem;
`;

const FormLabelInline = styled.div`
  margin-top: 2rem;
  margin-right: 2rem;
  display: inline-block;
`;

const FormHeading = styled.h2``;

const DescriptionInput = styled.textarea`
  width: 70%;
  height: 100px;
  padding: 10px;
  line-height: 1.5;
`;

/*  FIXING DELEGATION ISSUE */
/*  UPDATE HEIGHT, MIN-HEIGHT, white-space */
const ErrorsBlock = styled.div`
  color: #000000;
  display: flex;
  background-color: #FFA6A8;
  min-height:60px;
  height: auto;
  border-radius: 3px;
  align-items: center;
  padding: 0 1rem;
`;

const GlobalMessagesWrap = styled.div``;

/*  FIXING DELEGATION ISSUE */
/*  UPDATE HEIGHT, MIN-HEIGHT,  white-space */
const GlobalMessage = styled.div`
  background-color: ${({ type }) => type === 'success' ? '#C8ECBB' : '#F7AAAA'};
  color: #000000;
  min-height:60px;
  height: auto;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
`;

const GlobalMessageClose = styled.div`
  background: transparent;
  width: 24px;
  height: 24px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '';
    height: 24px;
    border-left: 2px solid #444444;
    position: absolute;
    transform: rotate(45deg);
    left: 10px;
  }

  &:before {
    content: '';
    height: 24px;
    border-left: 2px solid #444444;
    position: absolute;
    transform: rotate(-45deg);
    left: 10px;
  }
`;

const InputField = styled(DebounceInput)`
  padding: 5px 5px 2px;
  line-height: 1;
`;

const DatePickerField = styled(DatePicker)`
  line-height: 1;
  text-align: center;
  padding: 5px 5px 2px;
`;
{/*  Added for admin calculations R2 */}
const Select = styled.select`
    width: 100%;
    height: 35px;
    background: white;
    padding-left: 5px;
    font-size: 14px;
    border: 1px solid #cccccc;
    margin-left: 10px;

    option {
        background: white;
        display: flex;
        white-space: pre;
        min-height: 20px;
        padding: 0px 2px 1px;
    }
`;

/* Release 3.1 - Starts */
const WarningsBlock = styled.div`
  color: #856404;
  display: flex;
  background-color: #fff3cd;
  min-height:60px;
  height: auto;
  border-radius: 3px;
  align-items: center;
  padding: 0 1rem;
`;
/* Release 3.1 - Ends */

export {
  FormButton,
  FormButtonsWrap,
  FormLabel,
  FormLabelInline,
  FormHeading,
  DescriptionInput,
  ErrorMessage,
  ErrorsBlock,
  LoaderWrap,
  GlobalMessagesWrap,
  GlobalMessage,
  GlobalMessageClose,
  InputField,
  DatePickerField,
  Select, //  Added for admin calculations R2 
  /* Release 3.1 - Starts */
  WarningsBlock,
  /* Release 3.1 - Ends */
};
