import React, { useState } from 'react';
import styled from 'styled-components/macro';

const Columns = props => {
  const { columns, onChange } = props;

  const sections = columns.reduce((acc, curr) => {
    if (!curr.section) return acc;

    if (!acc[curr.section]) {
      acc[curr.section] = [];
    }
    acc[curr.section].push(curr)
    return acc;
  }, {});

  const isSectionVisible = section => sections[section].every(c => c.visible);

  const [open, setOpen] = useState(false);
  const initialSectionsConfig = Object.keys(sections).reduce((acc, curr) => 
    Object.assign(acc, {[curr]: { visible: isSectionVisible(curr)}}),{});
  const [sectionsConfig, setSectionsConfig] = useState(initialSectionsConfig);

  const toggleColumn = (column, updateSection = true) => {
    const changed = columns.map(o => o.name === column.name ? Object.assign(o, {visible: !column.visible}) : o);
    if (updateSection && isSectionVisible(column.section) !== sectionsConfig[column.section].visible) {
      toggleSection(column.section, false);
    }
    onChange({ changedColumns: changed, columnName: column.name, columnChecked: !column.visible });
  };

  const toggleSection = (section, updateColumns = true) => {
    const clone = Object.assign(sectionsConfig, {[section]: {visible: !sectionsConfig[section].visible}});
    setSectionsConfig(clone);
    if (updateColumns) {
      sections[section].map(col => toggleColumn({...col, visible: !sectionsConfig[section].visible}, false));
    }
  };

  return (
    <Wrap>
      <a href="!#" onClick={() => setOpen(!open)}>Columns</a>
      {open && (
        <Controls>
        {Object.keys(sections).map(section => {
          return (
            <Section key={Math.random()}>
              <Label>
                <span><strong>{section}</strong></span>
                <input
                  name={section}
                  type="checkbox"
                  checked={sectionsConfig[section].visible}
                  onChange={() => toggleSection(section)}
                />
              </Label>
              {sections[section].map((column, i) => {
                return (
                  <Label key={Math.random()}>
                    <span>{column.label || column.name}</span>
                    <input
                      name={column.name}
                      type="checkbox"
                      checked={column.visible}
                      onChange={() => toggleColumn(column)} />
                  </Label>
                );
              })}
            </Section>
          );
        })}
        </Controls>
      )}
    </Wrap>
  );
};

export { Columns };

const Wrap = styled.div`
  text-align: right;
`;

const Controls = styled.div`
  display: flex;
`;

const Section = styled.div`
  flex: 1;
  padding: 1rem;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
