import { currencyDisplayRenderer } from '../../renderers';

export default (table) => {
  return {
    type: "float",
    name: "fte_annual_salary",
    section: "Current Compensation Information",
    label: "Current Annual Base Salary",
    visible: true,
    sortable: true,
    filterable: true,
    width: '150px',
    renderer: currencyDisplayRenderer
  };
};