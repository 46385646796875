import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { apolloClient, apolloProxyClient } from '../../index';
import { Grid } from 'react-awesome-spinners';
import { connect } from 'react-redux';
import { Ring } from 'react-awesome-spinners';
import { unselectAllRecord, budgetSetUpdated, pushMessage, selectRecord, updateRecord } from '../../actions';
import { DatePickerField, SelectedTable, CalculatorNew, FormButton, FormButtonsWrap, ErrorsBlock, LoaderWrap, FormLabel, FormLabelInline, FormHeading, DescriptionInput, IconWrap } from '../';
import { columns } from '../../util/config';
import { CREATE_REQUEST_WITH_BUDGET, BUDGET, requests, moc_requests, moc_edit } from '../../graphql';
import { convertToUTC } from '../../util/helper';
import { Pagination, GridLoader } from '../';
import {
    numberRenderer,
    currencyDisplayRenderer,
    percentRenderer,
  } from "../../util";
import exportFromJSON from 'export-from-json'

import "react-datepicker/dist/react-datepicker.css";

const Mocsubmission = props => {
    const { modal, account, hideModal, selectedEmployees, unselectAllRecord, budgetSetUpdated, pushMessage, selectRecord, config, updateRecord } = props;
    const proxyCheck = sessionStorage.getItem("proxy_access");
    const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
    //const selectedEmployees = [];
    const indexColumn = columns.requestCompesation.find(c => c.index);


    const DEFAULT_PAGE_SIZE = 20;
    const [triggerCalc, setCalcTrigger] = useState(false);
    const [updatedItem, setUpdatedItem] = useState('');
    const [updateData, setUpdateData] = useState({});
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [currentPage, setCurrentPage] = useState(1);
    const pageOffset = (currentPage - 1) * pageSize;
    const [requestDescription, setRequestDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitErrors, setSubmitErrors] = useState([]);
    const now = new Date();
    const nextMonth = now.getMonth() === 11 ? new Date(now.getFullYear() + 1, 0, 1) : new Date(now.getFullYear(), now.getMonth() + 1, 1);
    

    const [createRequest] = useMutation(CREATE_REQUEST_WITH_BUDGET);
    const [data, setresdata] = useState(undefined);
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState(false);
    const [calciarray, setcalci] = useState([]);
    const [pagetrigger, setpagetrigger] = useState(false);

    const [editMOC] = useMutation(moc_edit);
    useEffect(() => {
        handleTrigger();
    }, [data])
    useEffect(() => {
        setloading(true);
        httpClient.query({
            query: moc_requests,
            variables: { where: modal.modalContent.where, limit: parseInt(pageSize), offset: pageOffset, orderBy: null },
            fetchPolicy: "network-only"
        })
            .then((response) => {


                if (response.data && response.data.moc_requests) {




                    response.data.moc_requests.items.forEach((element, index, array) => {
                        const ele = { ...element, ...element.for };
                        delete ele.for;
                        selectRecord(ele, indexColumn, "employees");

                    })
                    setresdata(response.data);
                    setloading(false);
                    seterror(false);


                }

            })
            .catch((err) => {
                console.error(err);
                setresdata(undefined);
                setloading(false);
                seterror(true);

            }
            )



    }, [pagetrigger])

    const onPageSizeChange = event => {
        setloading(true);
        unselectAllRecord('employees');
        setPageSize(event.target.value);
        setCurrentPage(1);
        setpagetrigger(pagetrigger ? false : true);
    };

    const onPageChange = currentPage => {
        setloading(true);
        unselectAllRecord('employees');
        setCurrentPage(currentPage);
        setpagetrigger(pagetrigger ? false : true);
    };


    const update = (item, data) => {
        console.log("item", item);
        console.log("data", data);
        if (updatedItem !== item) {
            setUpdatedItem(item);
        }
        setUpdateData(data);
    };


    function isNumber(evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    function isNegative(n) {
        return ((n = +n) || 1 / n) < 0;
    }
    function UpdateN() {
        let ttc_increase_amount = document.querySelectorAll(
            'input[name="ttc_increase_amount"]'
        );
        let ttc_increase_percentage = document.querySelectorAll(
            'input[name="ttc_increase_percentage"]'
        );
        ttc_increase_amount.forEach((e) => {
            let value = e.value;
            if (isNegative(value) || value === 0 || !value || value === "") {
                e.value = "0.00";
            }
        });
        ttc_increase_percentage.forEach((e) => {
            let value = e.value;
            if (isNegative(value) || value === 0 || !value || value === "") {
                e.value = "0.00";
            }
        });
    }

    const handlenextcalc = (args) => {

    }

    const handleChange = (args) => (event) => {
        console.log("On change called");
        const { column, columns, item } = args;
        const indexColumn = columns.find((c) => c.index);
        let value =
            column.inputType === "checkbox"
                ? event.target.checked
                : event.target.value;

        value =
            (column.inputType !== "checkbox" && parseInt(value)) < 0 ? 0 : value;

        const calarray = [];

        calarray.push(<CalculatorNew
            key={1}
            columns={columns}
            recordType="employees"
            item={item[indexColumn.name]}
            data={{
                request_type: "compensation",
                [column.name]: column.type === "float" ? parseFloat(value) : value,
            }}


        />)
        setcalci(calarray);


    };




    const handleDescriptionChange = (event) => {
        setRequestDescription(event.target.value);
        setTimeout(() => {
            UpdateN();
        }, 100);
    };
    const handleEffectiveDateChange = (date) => {
        setEffectiveDate(date);
        setTimeout(() => {
            UpdateN();
        }, 100);
    };


    const handleQuit = event => { hideModal(); unselectAllRecord('employees'); };

    const hasBudgetVisibility = account.accessibles && account.accessibles.budgetOrgs.length > 0;

    const handleRequestCompensationSend = async event => {


        setTimeout(() => {
            UpdateN();
        }, 100);


        setIsSubmitting(true);
        setSubmitErrors([]);

        if (!effectiveDate) {
          setSubmitErrors(['Effective Date is required!']);
          setIsSubmitting(false);
          return null;
      }
      
        if (!requestDescription) {
            setSubmitErrors(['Reason is required!']);
            setIsSubmitting(false);
            return null;
        }

        let refetchAfterCreateRequest = [{
            query: requests,
            variables: {
                where: { requester_pers_no: { eq: account.pers_no } },
                limit: 20,
                offset: 0,
                orderBy: [{ column: "created_ts", order: "DESC" }]
            }
        }];
        if (hasBudgetVisibility) {
            account.accessibles.budgetOrgs.forEach(b =>
                refetchAfterCreateRequest.push({
                    query: BUDGET, variables: { organization_unit: b }
                })
            );
        }


        let lastInsertedID = 0;
        let lastInsertedStatus = '';


        const results = await Promise.all(selectedEmployees.map(async ({
            pers_no,
            nokia_id,
            country_name,
            organization_unit_1,
            organization_unit_2,
            organization_unit_3,
            organization_unit_4,
            is_gpp_eligible,
            gpp_increase_amount,
            gpp_increase_percentage,
            ttc_increase_amount,
            ttc_increase_percentage,
            promoted,
            demoted,
            promotion_job_grade_delta,
            promotion_job_family_name,
            promotion_job_subfamily_name,
            promotion_job_name,
            promotion_job_grade,
            promotion_pay_grade,
            promotion_incentive_target_percentage,
            mandatory_ttc_increase_percentage,
            mandatory_ttc_increase_amount,
            apply_ti_harmonization,
            interim_job_family_name,
            interim_job_grade,
            interim_fte_incentive_target_amount,
            interim_incentive_target_percentage,
            interim_total_increase_amount,
            interim_increase_amount_for_salincr,
            interim_increase_amount_for_tih,
            interim_salary_range_min,
            interim_salary_range_max,
            step_1_abs,
            step_1_ita,
            step_1_itp,
            interim_theo_itp,
            interim_itp_situation,
            step_2_abs_gap,
            step_2_ita_gap,
            interim_remainder_for_split,
            new_fte_annual_salary,
            new_fte_incentive_target_amount,
            new_incentive_target_percentage,
            new_itp_situation,
            new_fte_ttc_annual_salary,
            new_quartile,
            new_incentive_plan_type,
            new_position_title,
            request_type,
            currency,
            offcycle_budget_update,
            new_ttc_compa_ratio,
            target_budget_org,
            organization_unit_2_name,
            organization_unit_1_name,
            ttc_increase_perc
        }) => {
            try {
                const results_Inner = await createRequest({
                    variables: {
                        data: {
                            effective_date: convertToUTC(effectiveDate),
                            request_description: requestDescription,
                            requester_pers_no: account.pers_no,
                            request_country: country_name,
                            request_type,
                            pers_no,
                            is_gpp_eligible,
                            gpp_increase_amount,
                            gpp_increase_percentage,
                            ttc_increase_amount,
                            ttc_increase_percentage,
                            promoted,
                            demoted,
                            promotion_job_grade_delta,
                            promotion_job_family_name,
                            promotion_job_subfamily_name,
                            promotion_job_name,
                            promotion_job_grade,
                            promotion_pay_grade,
                            promotion_incentive_target_percentage,
                            mandatory_ttc_increase_percentage,
                            mandatory_ttc_increase_amount,
                            apply_ti_harmonization,
                            interim_job_family_name,
                            interim_job_grade,
                            interim_fte_incentive_target_amount,
                            interim_incentive_target_percentage,
                            interim_total_increase_amount,
                            interim_increase_amount_for_salincr,
                            interim_increase_amount_for_tih,
                            interim_salary_range_min,
                            interim_salary_range_max,
                            step_1_abs,
                            step_1_ita,
                            step_1_itp,
                            interim_theo_itp,
                            interim_itp_situation,
                            step_2_abs_gap,
                            step_2_ita_gap,
                            interim_remainder_for_split,
                            new_fte_annual_salary,
                            new_fte_incentive_target_amount,
                            new_incentive_target_percentage,
                            new_itp_situation,
                            new_fte_ttc_annual_salary,
                            new_quartile,
                            new_incentive_plan_type,
                            new_position_title,
                            currency,
                            offcycle_budget_update,
                            new_ttc_compa_ratio,
                            target_budget_org,
                            organization_unit_2_name,
                            organization_unit_1_name,
                            ttc_increase_perc
                        }
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: refetchAfterCreateRequest
                });


                console.log(results_Inner);
                lastInsertedID = results_Inner.data.createRequest.employee_request_id;
                lastInsertedStatus = results_Inner.data.createRequest.status;
                return { 'pers_no': false, 'Person No.': pers_no, 'Nokia ID': nokia_id, 'Reason': 'Successfully saved the data' };
            } catch (e) {
                const err_request_exist = e.graphQLErrors.some(er => er.extensions.code === 'EXISTING_REQUEST');
                return { 'pers_no': err_request_exist ? `There is already a pending request for employee ${nokia_id}` : e.message, 'Person No.': pers_no, 'Nokia ID': nokia_id, 'Reason': e.message };
            }
        }));

        const errors = results.filter(r => r['pers_no']);
        const success = results.filter(r => !r['pers_no']);
        console.log("Success check", { where: { request_type: { eq: 'compensation' }, import_status: { eq: "pending" }, requester_pers_no: { eq: account.pers_no.toString() }, pers_no: { in: results.filter(r => !r['pers_no']).map(e => e['Person No.']) } }, data: { import_status: 'completed', Submission_Status: 'Success' } });      //  if (success) {



        await editMOC({
            variables: { where: { request_type: { eq: 'compensation' }, import_status: { eq: "pending" }, requester_pers_no: { eq: account.pers_no.toString() }, pers_no: { in: results.filter(r => !r['pers_no']).map(e => e['Person No.']) } }, data: { import_status: 'completed', Submission_Status: 'Success' } },
            awaitRefetchQueries: false,
            refetchQueries: [
                { query: moc_requests, variables: { where: modal.modalContent.where, limit: parseInt(pageSize), offset: pageOffset, orderBy: null } }
            ]
        });

        //  }
        if (errors.length > 0) {

            setTimeout(() => {
                UpdateN();
            }, 100);


            const fileName = "MassOffCycle_Compensation" + "_" + new Date().toISOString();
            const exportType = 'csv';
            errors.forEach(function (v) { delete v['pers_no'] });

            const data = errors;
            exportFromJSON({ data, fileName, exportType });
            alert("Some or all of the requests submitted are failed. Please review the requests on the CSV that would be downloaded")
            setIsSubmitting(false);
        } else {
            hideModal();
            pushMessage({
              type: "success",
              message: "Request(s) successfully reviewed.",
            });
        }

       // hideModal();
        unselectAllRecord('employees');
        setIsSubmitting(false);
        setCurrentPage(1);
        setpagetrigger(pagetrigger ? false : true);
        budgetSetUpdated(true);

    };

    const handleTrigger = () => {
        const calarray = [];
        selectedEmployees.forEach((element, i) => {
            calarray.push(<CalculatorNew
                key={i}
                columns={columns.requestCompesation}
                recordType="employees"
                item={element[indexColumn.name]}
                data={{
                    request_type: "compensation",
                    ttc_increase_amount: parseFloat(element.ttc_increase_amount) < 0 ? 0 : parseFloat(element.ttc_increase_amount)
                }}


            />)
        })
        setcalci(calarray);

    }
    /* Release 3.2 - 1.1 - Starts */
  const handleExportData = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (selectedEmployees && selectedEmployees.length > 0) {
      var data = selectedEmployees.map(function(item){
        let current_itp_situation = item && item.current_itp_situation ? item.current_itp_situation : 'Norm'
        let new_itp_situation = item.new_itp_situation ? item.new_itp_situation : 'Norm';
        
        let total_increaseed_percent = 0;
        total_increaseed_percent = item.ttc_increase_percentage;
    
        return {
            "ID": item.employee_request_id,
            "Pers No.": item.pers_no,
            "Nokia ID": item.nokia_id,
            "Full Name": item.full_name,
            "New-Job Family": item && item.job_family_name ? item.job_family_name : '', 
            "New-Job Sub Family": item && item.job_subfamily_name ? item.job_subfamily_name : '', 
            "New-Job": item && item.job_name ? item.job_name : '',
            "New-Job Grade": item && item.job_grade ? item.job_grade : '',
            "New-Position Title": item && item.position_title ? item.position_title : '',  
            "Current Total Target Cash": item.new_fte_annual_salary ? currencyDisplayRenderer(item.new_fte_annual_salary, item) : '',
            "Total Target Percentage": item.new_incentive_target_percentage ? percentRenderer(item.new_incentive_target_percentage) + ' ' + new_itp_situation : '',
            "Total Target Amount": item.new_fte_incentive_target_amount ? currencyDisplayRenderer(item.new_fte_incentive_target_amount, item) : '', 
            "New-Total Target Cash": item.new_fte_ttc_annual_salary ? currencyDisplayRenderer(item.new_fte_ttc_annual_salary, item) : '',
            "Target Budget": item.target_budget_org ? item.target_budget_org : '',     
            "Current-Country": item.country_name ? item.country_name : '',
              "Current-Job Family": item.job_family_name ? item.job_family_name : '',
              "Current-Job Sub Family": item.job_subfamily_name ? item.job_subfamily_name : '', 
              "Current-Job": item.job_name ? item.job_name : '',
              "Current-Job Grade": item.job_grade ? item.job_grade : '', 
              "Current-Position Title": item.position_title ? item.position_title : '', 
              "Current-Annual Base Salary": item.fte_annual_salary ? currencyDisplayRenderer(item.fte_annual_salary, item) : '',
              "Current-Incentive Target Percentage": item.fte_incentive_target_percentage ? percentRenderer(item.fte_incentive_target_percentage) + ' ' + current_itp_situation : '', 
              "Current-Incentive Target Amount": item.fte_incentive_target_amount ? currencyDisplayRenderer(item.fte_incentive_target_amount, item) : '',
              "Current-Total Target Cash": item.fte_ttc_annual_salary ? currencyDisplayRenderer(item.fte_ttc_annual_salary, item) : '', 
              "Current-TTC Compa-Ratio": item.ttc_compa_ratio ? numberRenderer(item.ttc_compa_ratio) : '', 
              "Current-Incentive Plan": item.incentive_plan_type ? item.incentive_plan_type : '',
              "Current-Quartile": item.ttc_employee_quartile ? item.ttc_employee_quartile : '', 

              "New-Country": item.country_name ? item.country_name : '', 
              "New-Job Family": item.job_family_name ? item.job_family_name : '', 
              "New-Job Sub Family": item.job_subfamily_name ? item.job_subfamily_name : '', 
              "New-Job": item.job_name ? item.job_name : '', 
              "New-Job Grade":  item.job_grade ? item.job_grade : '',  
              "New-Position Title": item.position_title ? item.position_title : '',  
              "New-Annual Base Salary": item.new_fte_annual_salary ? currencyDisplayRenderer(item.new_fte_annual_salary, item) : '',
              "New-Incentive Target Percentage": item.new_incentive_target_percentage ? percentRenderer(item.new_incentive_target_percentage) + ' ' + new_itp_situation : '',
              "New-Incentive Target Amount": item.new_fte_incentive_target_amount ? currencyDisplayRenderer(item.new_fte_incentive_target_amount, item) : '', 
              "New-Total Target Cash": item.new_fte_ttc_annual_salary ? currencyDisplayRenderer(item.new_fte_ttc_annual_salary, item) : '',
              "New-TTC Compa-Ratio": item.new_ttc_compa_ratio ? numberRenderer(item.new_ttc_compa_ratio) : '',
              "New-Incentive Plan": item.new_incentive_plan_type ? item.new_incentive_plan_type : '', 
              "New-Quartile": item.new_quartile ? item.new_quartile : '',
              "Off-Cycle Budget Spending": item.offcycle_budget_update ? currencyDisplayRenderer(item.offcycle_budget_update, item) : '',                                                    
        }
      });
      data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':""'));
      const fileName = "massoffcycle-compensation" + new Date().toISOString();
      const exportType = "csv";
      exportFromJSON({ data, fileName, exportType });
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      alert("No Record Found to Export the Excel");
    }
  };
  /* Release 3.2 - 1.1 - Ends */

  /*  DEM0065665 - Effective date Starts */
  let includeDatesFinal = [];
  if(!loading && data)
  {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var currentDisplayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    var processYear = date.getFullYear();

    
    
    const { allEffectiveDateConfigs } = config;
    //console.log("selectedEmployees====>", selectedEmployees)
    //console.log("selectedEmployees.length====>", selectedEmployees.length)
    //console.log("allEffectiveDateConfigs====>", allEffectiveDateConfigs)
    var calenderDateRange = new Array();
    var dateRangeIncr = 0;
    if(selectedEmployees.length == 1)
    {
      //for(var incr_1 = 0; incr_1 < selectedEmployees.length; incr_1++)
      //{
        var ISO_Code      = selectedEmployees[0].country;
        var Company_Code  = selectedEmployees[0].company_code;
        var Region        = selectedEmployees[0].geo_region;
        if(Region == 'EUR')
          Region = 'Europe';
        var Country       = selectedEmployees[0].country_name;
        var is_employee_matched = 'No';
        if(allEffectiveDateConfigs.length > 0)
        {
          for(var incr_2 = 0; incr_2 < allEffectiveDateConfigs.length; incr_2++)
          {
            var ID_Config                 = allEffectiveDateConfigs[incr_2].id;
            var ISO_Code_Config            = allEffectiveDateConfigs[incr_2].iso_code;
            var Company_Code_Config        = allEffectiveDateConfigs[incr_2].company_code;
            var Region_Config              = allEffectiveDateConfigs[incr_2].region;
            var Country_Config             = allEffectiveDateConfigs[incr_2].country;
            var EffDate_Compensation_Config = allEffectiveDateConfigs[incr_2].salary_increase_effective_date;
            var EffDate_Onetime_Config     = allEffectiveDateConfigs[incr_2].one_time_payment_effective_date;
            
            //if(ISO_Code == ISO_Code_Config && Region == Region_Config && Country == Country_Config)
            if(Country == Country_Config)
            {
              is_employee_matched = 'Yes';
              calenderDateRange[dateRangeIncr] = EffDate_Compensation_Config;
              break;

              /*var exp_1 = new Array();
              exp_1 = Company_Code_Config.split(",");
              if(exp_1.length > 0)
              {
                for(var incr_3 = 0; incr_3 < exp_1.length; incr_3++)
                {
                  if(Company_Code == exp_1[incr_3])
                  {
                    is_employee_matched = 'Yes';
                    break;
                  }
                }
              }
              if(is_employee_matched == 'Yes')
              {
                calenderDateRange[dateRangeIncr] = EffDate_Compensation_Config;
              }
              break;*/
            }
          }
        }
      //}
      //console.log("calenderDateRange=====>", calenderDateRange)
      //console.log("is_employee_matched=====>", is_employee_matched)
      if(is_employee_matched == 'Yes')
      {
        for (let i=0; i<12; i++) 
        {
          var processMonth = (currentDisplayMonth.getMonth()+1);
          //console.log("processMonth.length===>"+processMonth.length);
          if(processMonth != 12 && processMonth != 11 && processMonth != 10)
          {
            processMonth = '0'+processMonth;
          }

          if(calenderDateRange[0] == 'Only the 1st of the month')
          {
            includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
          }
          else if(calenderDateRange[0] == 'Only 1st and 16th of the month')
          {
            includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
            includeDatesFinal.push(new Date(processMonth+"/16/"+processYear));
          }
          else if(calenderDateRange[0] == 'Only 1st or 15th of the month')
          {
            includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
            includeDatesFinal.push(new Date(processMonth+"/15/"+processYear));
          }
          else if(calenderDateRange[0] == 'Any date during the month')
          {
            var totalDaysInMonth = new Date(processYear, processMonth, 0).getDate();
            //console.log("totalDaysInMonth====>", totalDaysInMonth);
            if(totalDaysInMonth > 0)
            {
              for(var days_incr = 1; days_incr <= totalDaysInMonth; days_incr++)
              {
                if(days_incr < 10)
                {
                  days_incr = '0'+days_incr;
                }
                includeDatesFinal.push(new Date(processMonth+"/"+days_incr+"/"+processYear));
              }
            }
          }
          //console.log("====>", processMonth);
          if(processMonth == 12)
          {
            var date_1 = new Date();
            processYear = date.getFullYear()+1;
          }
          currentDisplayMonth.setMonth(currentDisplayMonth.getMonth() + 1);
        }
      }
      else
      {
        for (let i=0; i<12; i++) 
        {
          var processMonth = (currentDisplayMonth.getMonth()+1);
          //console.log("processMonth.length===>"+processMonth.length);
          if(processMonth != 12 && processMonth != 11 && processMonth != 10)
          {
            processMonth = '0'+processMonth;
          }

          var totalDaysInMonth = new Date(processYear, processMonth, 0).getDate();
          //console.log("totalDaysInMonth====>", totalDaysInMonth);
          if(totalDaysInMonth > 0)
          {
            for(var days_incr = 1; days_incr <= totalDaysInMonth; days_incr++)
            {
              if(days_incr < 10)
              {
                days_incr = '0'+days_incr;
              }
              includeDatesFinal.push(new Date(processMonth+"/"+days_incr+"/"+processYear));
            }
          }
          //console.log("====>", processMonth);
          if(processMonth == 12)
          {
            var date_1 = new Date();
            processYear = date.getFullYear()+1;
          }
          currentDisplayMonth.setMonth(currentDisplayMonth.getMonth() + 1);
        }
      }
    }
    else if(selectedEmployees.length > 1)
    {
      calenderDateRange[dateRangeIncr] = 'Only the 1st of the month';
      var countryMatchedWithConfig = 'N';
      var allCountries = new Array();
      for(var incr_1 = 0; incr_1 < selectedEmployees.length; incr_1++)
      {
        var Country       = selectedEmployees[incr_1].country_name;
        allCountries[incr_1] = Country;
      }

      var allCountrySame = allCountries.every( (val, i, arr) => val === arr[0] );
      console.log("allCountries======>", allCountries);
      console.log("allCountrySame======>", allCountrySame);
      if(allCountrySame == true)
      {
        for(var incr_2 = 0; incr_2 < allEffectiveDateConfigs.length; incr_2++)
        {
          var Country_Config             = allEffectiveDateConfigs[incr_2].country;
          var EffDate_Compensation_Config = allEffectiveDateConfigs[incr_2].salary_increase_effective_date;
          var EffDate_Onetime_Config     = allEffectiveDateConfigs[incr_2].one_time_payment_effective_date;
          if(allCountries[0] == Country_Config)
          {
            countryMatchedWithConfig = 'Y';
            is_employee_matched = 'Yes';
            calenderDateRange[0] = EffDate_Compensation_Config;
            break;
          }
        }
        if(countryMatchedWithConfig == 'N')
        {
          calenderDateRange[0] = 'Any date during the month';
        }
      }
      
      
      for (let i=0; i<12; i++) 
      {
        var processMonth = (currentDisplayMonth.getMonth()+1);
        //console.log("processMonth.length===>"+processMonth.length);
        if(processMonth != 12 && processMonth != 11 && processMonth != 10)
        {
          processMonth = '0'+processMonth;
        }

        if(calenderDateRange[0] == 'Only the 1st of the month')
        {
          includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
        }
        else if(calenderDateRange[0] == 'Only 1st and 16th of the month')
        {
          includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
          includeDatesFinal.push(new Date(processMonth+"/16/"+processYear));
        }
        else if(calenderDateRange[0] == 'Only 1st or 15th of the month')
        {
          includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
          includeDatesFinal.push(new Date(processMonth+"/15/"+processYear));
        }
        else if(calenderDateRange[0] == 'Any date during the month')
        {
          var totalDaysInMonth = new Date(processYear, processMonth, 0).getDate();
          //console.log("totalDaysInMonth====>", totalDaysInMonth);
          if(totalDaysInMonth > 0)
          {
            for(var days_incr = 1; days_incr <= totalDaysInMonth; days_incr++)
            {
              if(days_incr < 10)
              {
                days_incr = '0'+days_incr;
              }
              includeDatesFinal.push(new Date(processMonth+"/"+days_incr+"/"+processYear));
            }
          }
        }
        //console.log("====>", processMonth);
        if(processMonth == 12)
        {
          var date_1 = new Date();
          processYear = date.getFullYear()+1;
        }
        currentDisplayMonth.setMonth(currentDisplayMonth.getMonth() + 1);
      }
    }
    //console.log("CURRENT Year===>"+processYear);
    var minimumDate = '';

    var minimumDate_Highlighted = '';
    var minimumDate_Selectable = '';
    console.log("calenderDateRange===>", calenderDateRange);
    /*  DEM0065665 - Effective date Starts */
    //var currentDisplayMonthDefault = new Date(now.getFullYear(), now.getMonth(), 1);
    if(calenderDateRange.length > 0)
    {
      if(calenderDateRange[0] == 'Only the 1st of the month')
      {
        if(date.getDate() > '1')
        {
          minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

          minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
        }
        else
        {
          minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

          minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
        }
      }
      else if(calenderDateRange[0] == 'Only 1st or 15th of the month')
      {
        if(date.getDate() > '1')
        {
          minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

          minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
        }
        else
        {
          minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

          minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
        }
      }
      else if(calenderDateRange[0] == 'Only 1st and 16th of the month')
      {
        if(date.getDate() > '1')
        {
          minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

          minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
        }
        else
        {
          minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

          minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
        }
      }
      else
      {
        if(date.getDate() > '1')
        {
          minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

          minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
        }
        else
        {
          minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

          minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
          minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
        }
      }
    }  
  }
  if(minimumDate == undefined)
  {
    var date_last = new Date();
    if(date_last.getDate() > '1')
    {
      minimumDate = new Date(date_last.getFullYear(), date_last.getMonth()+1, 1);

      minimumDate_Highlighted = new Date(date_last.getFullYear(), date_last.getMonth()+1, 1);
      minimumDate_Selectable = new Date(date_last.getFullYear(), date_last.getMonth(), 1);
    }
    else
    {
      minimumDate = new Date(date_last.getFullYear(), date_last.getMonth(), 1);

      minimumDate_Highlighted = new Date(date_last.getFullYear(), date_last.getMonth(), 1);
      minimumDate_Selectable = new Date(date_last.getFullYear(), date_last.getMonth(), 1);
    }
  }
  const [effectiveDate, setEffectiveDate] = useState(minimumDate_Highlighted);
  //console.log("includeDatesFinal===>", includeDatesFinal);
  //console.log("includeDatesFinal Length===>", includeDatesFinal.length);
  console.log("minimumDate===>", minimumDate);
  console.log("effectiveDate===>", effectiveDate);
  
  /*  DEM0065665 - Effective date Ends */

    return (
        <div>

            <FormHeading>Request Compensation Change</FormHeading>
            <FormLabel>Selected employees: {selectedEmployees.length}</FormLabel>
            <FormHeading style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
            <div>
                Total Off-Cycle Budget Spend : &euro; {selectedEmployees.reduce((accumulator, current) => accumulator + (current.offcycle_budget_update * current.exchange_rate), 0).toFixed(2)}
            </div>
            {!loading && data && ( 
                <FormButton
                    disabled={isSubmitting}
                    onClick={handleExportData}
                    >
                Export To Excel
                </FormButton> 
            )}
            </FormHeading>
            {loading && <GridLoader><Grid color={'#001235'} /></GridLoader>}
            {error && (<div><h2>Unable to fetch the pending requests</h2></div>)}
            
            {!loading && data && (
                <SelectedTable
                    recordType="employees"
                    items={selectedEmployees}
                    columns={columns.requestCompesation}
                    onChange={handleChange}
                />)}
            {!loading && data && (
                <Pagination
                    count={data.moc_requests.totalCount}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    reqFrom="moc"
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}
                />
            )}
            {!loading && data && (
                <FormLabelInline>Effective date:</FormLabelInline>)}

            {/*  DEM0065665 - Effective date Starts */}    
            {/*!loading && data && (
                <DatePickerField selected={effectiveDate} onChange={handleEffectiveDateChange} />)
                
            */}
            {!loading && data && (
                <DatePickerField 
                selected={effectiveDate} 
                onChange={handleEffectiveDateChange} 
                minDate={minimumDate_Selectable} 
                includeDates={includeDatesFinal}
                /*includeDates={[
                  new Date('12/01/2022'),
                  new Date('12/15/2022'), 
                  new Date('01/01/2023'),
                  new Date('01/15/2023')]}*/
                />)
            }
            {!loading && data && (
                <i style={{'width':'100%','float':'left'}}><b>Please note that in some countries only possible effective date is 1st of the month due to Payroll requirements. If you need further support, please open an HR ticket to People Experience Care team.</b></i>)
            }
            
            {/*  DEM0065665 - Effective date Ends */}
            {!loading && data && (
                <FormLabel>Reason for request:
                    <i style={{'width':'100%','float':'left'}}>[Please do not use the Reason field for Backdated changes dates as this field is visible only for approvers and not updating CoreHR System!]</i>
                </FormLabel>)}
            {!loading && data && (
                <DescriptionInput
                    name='request_description'
                    onChange={handleDescriptionChange}
                    onKeyPress={isNumber}
                />)}

            {submitErrors.length > 0 && submitErrors.map(err => {
                return (
                    <ErrorsBlock key={Math.random()}>
                        <IconWrap>!</IconWrap>
                        {err}
                    </ErrorsBlock>
                );
            })}
            <FormButtonsWrap>
                <FormButton onClick={handleQuit}>Quit</FormButton>

                <FormButton primary disabled={isSubmitting} onClick={handleRequestCompensationSend}>Send request(s)</FormButton>
                {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
            </FormButtonsWrap>

            <>
                {calciarray}
            </>





        </div>
    );
};

const mapStateToProps = state => {
    return {
        selectedEmployees: state.selectedEmployees,
        account: state.account,
        /*  DEM0065665 - Effective date Starts */
        config: state.config,
        /*  DEM0065665 - Effective date Ends */
    };
};

export default connect(
    mapStateToProps,
    { unselectAllRecord, budgetSetUpdated, pushMessage, selectRecord, updateRecord }
)(Mocsubmission);
