export default (table) => {
  return {
      type: "string",
      name: "job_name",
      section: "Current Information",
      label: "Current Job Title",
      visible: true,
      sortable: true,
      filterable: true
    };
};