/* Release 3.2 - 1.1 - Starts */
import { 
  UNSELECT_BUDGET,
  SELECT_BUDGET,
  UPDATE_BUDGET,
  UNSELECT_ALL_BUDGETS } from '../actions/index';


export default (state = [], action) => {
  switch (action.type) {
    case SELECT_BUDGET:
      let newSelectedItem = null; 
      newSelectedItem = action.payload.__typename === 'BEABudgetForOrganization'
        ? action.payload
        : { 
          budget_id: action.payload.budget_id ,
          __typename: action.payload.__typename,
          budget: action.payload
        }
      return state.find(i => action.payload[action.indexColumn.name] === i[action.indexColumn.name]) ? state : state.concat([newSelectedItem]);
    case UNSELECT_BUDGET:
      return state.filter(item => action.payload[action.indexColumn.name] !== item[action.indexColumn.name]);
    case UNSELECT_ALL_BUDGETS:
      return [];
      
    case UPDATE_BUDGET:
      return state.map((item, index) => {
        if (item[action.indexColumn.name] === action.id) {
          const budgetData = {
            budget: {...item.budget, ...action.data.budget}
          };
          return {...item, ...budgetData};
        }
        return item;
      });
    default:
      return state;
  }
};
/* Release 3.2 - 1.1 - Ends */
