import { absIncreasePercentageRenderer } from '../../renderers';

export default (table) => {
  let name = 'lumpsum_abs_percentage';

  switch (table) {
    case 'editOnetime':    
    /* Release 3.2 - 1.1 - Starts */
    case 'editBEA':
    /* Release 3.2 - 1.1 - Ends */
      name = 'request.lumpsum_abs_percentage';
      break;
    default:
      break;
  }

  return {
      type: "float",
      name,
      label: "% In Relation to Annual Base Salary",
      section: "Merit/Promotion Increase",
      visible: true,
      renderer: absIncreasePercentageRenderer
    };
};