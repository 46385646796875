/* Release 3.2 - 13.1 - Starts */
import { numberRenderer, currencyDisplayRenderer } from "../../renderers";

export default (table) => {
  let name = "addl_pay_amount";
  let renderer = numberRenderer;
  let label = /* Release 3.2 - 13.1 - 149299 - Starts */"Case 3-12 months ACTUAL incentive amount paid  (FTE)"/* Release 3.2 - 13.1 - 149299 - Ends */;
  let width = "120px";
  let value_addl_pay_amount = 0;
  let data_currency = { currency: "EUR" };

  renderer = (value, data, account, config) => {
    switch (table) {
      case "internalHire":
        name = "lastYearWageHistory.addl_pay_amount";
        value_addl_pay_amount = data.lastYearWageHistory
          ? data.lastYearWageHistory.addl_pay_amount
          : 0;
        data_currency = data.lastYearWageHistory
          ? data.lastYearWageHistory
          : data;
        break;

      case "editInternalHire":
        name = "request.lastYearWageHistory.addl_pay_amount";
        value_addl_pay_amount = data.request.lastYearWageHistory
          ? data.request.lastYearWageHistory.addl_pay_amount
          : 0;
        data_currency = data.request && data.request.lastYearWageHistory
          ? data.request.lastYearWageHistory
          : data.request ? data.request : data;
        break;
    }
    
    return currencyDisplayRenderer(
      value_addl_pay_amount,
      data_currency /* Release 3.2 - 13.1 - 149299 - Starts */ ? data_currency : { currency: "EUR" }/* Release 3.2 - 13.1 - 149299 - Ends */,
      account,
      config
    );
  };

  return {
    type: "float",
    name,
    label,
    visible: true,
    renderer,
    width,
  };
};
/* Release 3.2 - 13.1 - Ends */
