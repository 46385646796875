export const currencyDisplayRenderer = (value, data, account = {}, config = {}) => {
  // console.log("IH edit issue",value);
  // console.log("IH edit issue data", data);
  const { forceCurrency } = account;
  const { exchangeRates = [], noDecimalCurrencies = [] } = config;
  const localCurrency = data.currency;
  const match = exchangeRates.find(r => r.to_currency === localCurrency && r.from_currency === forceCurrency);
  const exchangeRate = match ? 1/match.exchange_rate : 1;
  const curr = forceCurrency ? forceCurrency : localCurrency;
  const hasNoDecimas = noDecimalCurrencies.find(c => c === curr);
  const decimalPlaces = hasNoDecimas ? 0 : 2;
  const val = forceCurrency ? value * exchangeRate : value;
  
  return parseFloat(val || 0).toLocaleString(undefined, {minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces, style: 'currency', currency: curr, currencyDisplay: 'code'})
};
/* Release 3.2 - 1.1 - Starts */
export const budgetOwnerCurrencyRenderer = (value, data, account = {}, config = {}) => {
  const { forceCurrency } = account;
  const { exchangeRates = [], noDecimalCurrencies = [] } = config;
  const localCurrency = data.currency;
  const curr_from_currency = forceCurrency ? forceCurrency : 'EUR';
  const match = exchangeRates.find(r => r.to_currency === localCurrency && r.from_currency === curr_from_currency);
  const exchangeRate = match ? 1/match.exchange_rate : 1;
  const curr = forceCurrency ? forceCurrency : localCurrency;
  const hasNoDecimas = noDecimalCurrencies.find(c => c === curr);
  const decimalPlaces = hasNoDecimas ? 0 : 2;
  const val = forceCurrency ? value : value / exchangeRate;
  
  return parseFloat(val || 0).toLocaleString(undefined, {minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces, style: 'currency', currency: curr, currencyDisplay: 'code'})
};
/* Release 3.2 - 1.1 - Ends */