import { createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'styled-normalize';

export default createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;
  }

  @font-face {
    font-family: 'NokiaPureHeadline';
    font-weight: normal;
    font-style: normal;
    src: url('/fonts/nokiapureheadline_regular.eot');
    src: url('/fonts/nokiapureheadline_regular.woff2') format('woff2'),
    url('/fonts/nokiapureheadline_regular.woff') format('woff'),
    url('/fonts/nokiapureheadline_regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NokiaPureHeadline';
    font-weight: 300;
    font-style: normal;
    src: url('/fonts/nokiapureheadline_light.eot');
    src: url('/fonts/nokiapureheadline_light.woff2') format('woff2'),
    url('/fonts/nokiapureheadline_light.woff') format('woff'),
    url('/fonts/nokiapureheadline_light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NokiaPureHeadline';
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/nokiapureheadline_bold.eot');
    src: url('/fonts/nokiapureheadline_bold.woff2') format('woff2'),
    url('/fonts/nokiapureheadline_bold.woff') format('woff'),
    url('/fonts/nokiapureheadline_bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NokiaPureHeadlineLight';
    font-weight: normal;
    font-style: normal;
    src: url('/fonts/nokiapureheadline_light.eot');
    src: url('/fonts/nokiapureheadline_light.woff2') format('woff2'),
    url('/fonts/nokiapureheadline_light.woff') format('woff'),
    url('/fonts/nokiapureheadline_light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NokiaPureHeadlineUltralight';
    font-weight: normal;
    font-style: normal;
    src: url('/fonts/nokiapureheadline_ultralight.eot');
    src: url('/fonts/nokiapureheadline_ultralight.woff2') format('woff2'),
    url('/fonts/nokiapureheadline_ultralight.woff') format('woff'),
    url('/fonts/nokiapureheadline_ultralight.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NokiaPureText';
    font-weight: normal;
    font-style: normal;
    src: url('/fonts/nokiapuretextreg.eot');
    src: url('/fonts/nokiapuretextreg.woff2') format('woff2'),
    url('/fonts/nokiapuretextreg.woff') format('woff'),
    url('/fonts/nokiapuretextreg.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NokiaPureText';
    font-weight: 300;
    font-style: normal;
    src: url('/fonts/nokiapuretextlight.eot');
    src: url('/fonts/nokiapuretextlight.woff2') format('woff2'),
    url('/fonts/nokiapuretextlight.woff') format('woff'),
    url('/fonts/nokiapuretextlight.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NokiaPureText';
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/nokiapuretextbold.eot');
    src: url('/fonts/nokiapuretextbold.woff2') format('woff2'),
    url('/fonts/nokiapuretextbold.woff') format('woff'),
    url('/fonts/nokiapuretextbold.ttf') format('truetype');
  }

  body {
    color: ${({ theme }) => theme.normalTextColor};
    font-family: ${props => props.theme.fontFamily};
    font-size: 0.6rem;
    font-style: normal;
    line-height: 1.5;
    font-weight: 300;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern","liga","clig","calt";
    -ms-font-feature-settings: "kern","liga","clig","calt";
    -webkit-font-feature-settings: "kern","liga","clig","calt";
    font-feature-settings: "kern","liga","clig","calt"
  }

  figure {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  svg {
    height: auto;
    max-width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 150ms ease-in-out;
  }

  a:hover {
    text-decoration: none;
  }
  /*** Approver Chain ***/
  .arrowStyles {
    position:relative;
    width: 10px !important;
    top: -2px;
    height:0px;
    border-bottom:2px solid #333;
    display: inline-block;
        margin-right: 10px;
    margin-left: 5px;
    margin-top: 0 !important;
}

.arrowStyles::after {	
  content: '';
  width: 0; 
	height: 0; 
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 5px solid #333;
  position: absolute;
  right: -5px;
  top: -4px;
}
.hideArrowStyles span:last-child .arrowStyles{
  display: none;
} 
/*** Toggle Button Styles ***/
.toggleTAPStyles {
  width: 100%;
  background: #ECF0F3;
  margin-bottom: 8px !important;
  color: #333;
  font-weight: 600;
  margin: auto;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*** R1.2 - Tooltip Styles ***/
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  border: 1px solid #0A61EE;
  color: #0A61EE;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
`;
