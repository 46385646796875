import {
  UNSELECT_REQUEST,
  SELECT_REQUEST,
  UPDATE_REQUEST,
  UNSELECT_ALL_REQUESTS
} from '../actions/index';

export default (state = [], action) => {
  switch (action.type) {
    case SELECT_REQUEST:
      // const requestData = action.payload.__typename === 'Approval' ? action.payload.request : action.payload;
      let newSelectedItem = null; 
      newSelectedItem = action.payload.__typename === 'Approval'
        ? action.payload
        : { 
          employee_request_id: action.payload.employee_request_id ,
          __typename: action.payload.__typename,
          request: action.payload
        }
      return state.find(i => action.payload[action.indexColumn.name] === i[action.indexColumn.name]) ? state : state.concat([newSelectedItem]);
    case UNSELECT_REQUEST:
      return state.filter(item => action.payload[action.indexColumn.name] !== item[action.indexColumn.name]);
    case UNSELECT_ALL_REQUESTS:
      return [];
    case UPDATE_REQUEST:
      return state.map((item, index) => {
        if (item[action.indexColumn.name] === action.id) {
          const requestData = {
            request: {...item.request, ...action.data.request}
          };
          // item.request = {...item.request, ...action.data.request}
          // return item;
          return {...item, ...requestData};
        }
        return item;
      });
    default:
      return state;
  }
};
