export default (table) => {
  let name = 'organization_unit_name';
  let visible_in_tooltip = /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
    ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true;
    /* Release 3.2 - 1.1 - Ends */
  /*  DEM0060697 OCC Corrections 2 CHANGES STARTS */
  switch (table) {
    case 'editCompensation':
    case 'editOnetime':
    case 'rejectRequests':
      name = 'request.hmanager.organization_unit_name';
      break;
    case 'approveRequests':
      name = 'request.hmanager.organization_unit_name';
      break;
    case 'editInternalHire':
      name = 'request.for.organization_unit_name';
      break;
    case 'myRequests':
    case 'requestsHistory':
    case 'pendingRequests':
      //name = 'for.organization_unit_name';
      name = 'for.organization_unit_name___hmanager.organization_unit_name';
      break;
    default:
      break;
  }

  return { 
    name,
    label: "Org Unit",
    tooltip_section: "New",
    visible_in_tooltip
  };
};