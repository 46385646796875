export default (table) => {
  let name = 'organization_unit_1_name';
  let renderer =null;
  let visible_in_tooltip = /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
  ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true;
  /* Release 3.2 - 1.1 - Ends */
  
  switch (table) {
    case 'editCompensation':
    case 'editOnetime':
    case 'rejectRequests':
    case 'approveRequests':
      case 'editInternalHire':
      name = 'request.for.organization_unit_1_name';     
      break;
    case 'myRequests':
    case 'requestsHistory':
    case 'pendingRequests':
      name = 'for.organization_unit_1_name';
      /* Fixes of CAL5249307 Starts */
      renderer=(value, data) => (data.request_type == 'internalhire' ? data.hmanager.organization_unit_1_name : data.for.organization_unit_1_name); 
      /* Fixes of CAL5249307 Ends */
      break;
    default:
      break;
  }

  return { 
    name,
    label: "Business Group",
    tooltip_section: "New",
    visible_in_tooltip,
    renderer,
    allow: ["hr1","hr2","hr3","bcb"
   ,/* Release 3.2 - 10.1 - Starts */ "rcb" /* Release 3.2 - 10.1 - Ends */
   
  ]
  };
};