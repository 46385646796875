//Release 2 - Budget Management add new budget pop - up
import React, { useState, useRef, useEffect } from "react";
import { UploadFile } from "./mocuploadfile";

import { connect } from "react-redux";
import { ErrorsBlock, IconWrap } from "../";
import { pushMessage } from "../../actions";

const MOCImport = (props) => {
  const { account, hideModal, modal, unselectAllRecord, pushMessage } = props;
  console.log(modal.modalContent.currentType);
  const [selectedtable] = useState(modal.modalContent.currentType.toString());
  const tablemapping = {
    compensation: "Compensation Request",
    onetime: "One Time Request",
    /* Release 3.2 - 1.1 - Starts */
    businessexcellenceaward: "Business Excellence Award Request",
    /* Release 3.2 - 1.1 - Ends */
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log(tablemapping[selectedtable]);
  useEffect(() => {}, []);
  const handleQuit = (event) => {
    hideModal();
  };

  const handleEdit = (event) => {};
  return (
    <div
      style={{
        width: "80%",
        padding: "2px",
        margin: "0 auto",
      }}
    >
      <input id="searchResult" style={{ display: "none" }} />
      <div
        style={{
          fontSize: "20px",
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        Import Mass {selectedtable !=  "businessexcellenceaward" && <>Off Cycle</>} - {tablemapping[selectedtable]}
      </div>
      <div style={{ fontSize: "1rem" }}>
        <UploadFile
          reqType={selectedtable}
          pers={account.pers_no}
          refresh={modal.modalContent.reloadReview}
          handleQuit={handleQuit}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps, { pushMessage })(MOCImport);
