import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Ring } from 'react-awesome-spinners';
import { connect } from 'react-redux';
import { unselectAllRecord, budgetSetUpdated, pushMessage } from '../../actions';
import { DatePickerField, SelectedTable, CalculatorNew, FormButton, FormButtonsWrap, ErrorsBlock, LoaderWrap, FormLabel, FormLabelInline, FormHeading, DescriptionInput, IconWrap } from '../';
import { columns } from '../../util/config';
import { CREATE_REQUEST_WITH_BUDGET, BUDGET, requests } from '../../graphql';
import { convertToUTC } from '../../util/helper';

import "react-datepicker/dist/react-datepicker.css";

const RequestOneTime = props => {
  const { selectedEmployees, account, hideModal, unselectAllRecord, budgetSetUpdated, pushMessage, /*  DEM0065665 - Effective date Starts */config/*  DEM0065665 - Effective date Ends */ } = props;

  const [updatedItem, setUpdatedItem] = useState('');
  const [updateData, setUpdateData] = useState({});
  const [requestDescription, setRequestDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const now = new Date();
  const nextMonth = now.getMonth() === 11 ? new Date(now.getFullYear()+1, 0 , 1) : new Date(now.getFullYear(), now.getMonth()+1, 1);
  const [createRequest] = useMutation(CREATE_REQUEST_WITH_BUDGET);

  const update = (item, data) => {
    if (updatedItem !== item) {
      setUpdatedItem(item);
    }
    setUpdateData(data);
  };
  
  const handleChange = args => event => {
    const { column, columns, item } = args;
    const indexColumn = columns.find(c => c.index);
    const value = column.inputType === 'checkbox' ? event.target.checked : event.target.value;
    update(item[indexColumn.name], { 
      request_type: 'onetime',
      [column.name]: column.type === 'float' ? parseFloat(value) : value 
    });
  };

  const handleDescriptionChange = event => setRequestDescription(event.target.value);

  const handlePaymentDateChange = date => setPaymentDate(date);

  const handleQuit = event => { hideModal(); unselectAllRecord('employees'); };

  const hasBudgetVisibility = account.accessibles && account.accessibles.budgetOrgs.length > 0;


  const handleRequestOnetimeSend = async event => {
    setIsSubmitting(true);
    setSubmitErrors([]);

    if (!requestDescription) {
      setSubmitErrors(['Reason is required!']);
      setIsSubmitting(false);
      return null;
    }

    let refetchAfterCreateRequest = [{
      query: requests,
      variables: {
        where: { requester_pers_no: { eq: account.pers_no } },
        limit: 20,
        offset: 0,
        orderBy: [{column: "created_ts", order: "DESC"}]
      }
    }];
    if (hasBudgetVisibility) {
      account.accessibles.budgetOrgs.forEach(b =>
        refetchAfterCreateRequest.push({ 
          query: BUDGET, variables: { organization_unit: b } 
        })
      );
    }

    const results = await Promise.all(selectedEmployees.map(async ({
        pers_no,
        nokia_id,
        country_name,
        currency,
        ttc_increase_amount,
        ttc_increase_percentage,
        request_type,
        offcycle_budget_update,
        payment_type,
        payment_reason,
        target_budget_org,
        organization_unit_2_name,
        organization_unit_1_name
      }) => {
      try {
        await createRequest({
          variables: {
            data: {
              payment_date: convertToUTC(paymentDate),
              request_description: requestDescription,
              requester_pers_no: account.pers_no,
              request_country: country_name,
              request_type,
              pers_no,
              ttc_increase_amount,
              ttc_increase_percentage,
              currency,
              offcycle_budget_update,
              payment_type,
              payment_reason,
              target_budget_org,
              organization_unit_2_name,
              organization_unit_1_name
            }
          },
          awaitRefetchQueries: true,
          refetchQueries: refetchAfterCreateRequest
        });
        
        return {[pers_no]: false};
      } catch(e) {
        const err_request_exist = e.graphQLErrors.some(er => er.extensions.code === 'EXISTING_REQUEST');
        return {[pers_no]: err_request_exist ? `There is already a pending request for employee ${nokia_id}` : e.message};
      } 
    }));

    const errors = results.filter(r => Object.keys(r).find(p_no => r[p_no]));
    if (!errors.length) {
      hideModal();
      unselectAllRecord('employees');
      budgetSetUpdated(true);
      pushMessage({
        type: 'success',
        message: 'Request(s) successfully sent!'
      })
    } else {
      setSubmitErrors(errors.map(e => Object.values(e).shift()));
      setIsSubmitting(false);
    }
  };

  /*  DEM0065665 - Effective date Starts */
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var currentDisplayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  var currentDisplayMonthDefault = new Date(date.getFullYear(), date.getMonth(), 1);
  var processYear = date.getFullYear();

  let includeDatesFinal = [];
  
  const { allEffectiveDateConfigs } = config;
  //console.log("selectedEmployees====>", selectedEmployees)
  //console.log("allEffectiveDateConfigs====>", allEffectiveDateConfigs)
  var calenderDateRange = new Array();
  var dateRangeIncr = 0;
  if(selectedEmployees.length == 1)
  {
    //for(var incr_1 = 0; incr_1 < selectedEmployees.length; incr_1++)
    //{
      var ISO_Code      = selectedEmployees[0].country;
      var Company_Code  = selectedEmployees[0].company_code;
      var Region        = selectedEmployees[0].geo_region;
      if(Region == 'EUR')
        Region = 'Europe';
      var Country       = selectedEmployees[0].country_name;
      var is_employee_matched = 'No';
      if(allEffectiveDateConfigs.length > 0)
      {
        for(var incr_2 = 0; incr_2 < allEffectiveDateConfigs.length; incr_2++)
        {
          var ID_Config                 = allEffectiveDateConfigs[incr_2].id;
          var ISO_Code_Config            = allEffectiveDateConfigs[incr_2].iso_code;
          var Company_Code_Config        = allEffectiveDateConfigs[incr_2].company_code;
          var Region_Config              = allEffectiveDateConfigs[incr_2].region;
          var Country_Config             = allEffectiveDateConfigs[incr_2].country;
          var EffDate_Compensation_Config = allEffectiveDateConfigs[incr_2].salary_increase_effective_date;
          var EffDate_Onetime_Config     = allEffectiveDateConfigs[incr_2].one_time_payment_effective_date;
          
          //if(ISO_Code == ISO_Code_Config && Region == Region_Config && Country == Country_Config)
          if(Country == Country_Config)
          {
            is_employee_matched = 'Yes';
            calenderDateRange[dateRangeIncr] = EffDate_Onetime_Config;
            break;

            /*var exp_1 = new Array();
            exp_1 = Company_Code_Config.split(",");
            if(exp_1.length > 0)
            {
              for(var incr_3 = 0; incr_3 < exp_1.length; incr_3++)
              {
                if(Company_Code == exp_1[incr_3])
                {
                  is_employee_matched = 'Yes';
                  break;
                }
              }
            }
            if(is_employee_matched == 'Yes')
            {
              calenderDateRange[dateRangeIncr] = EffDate_Onetime_Config;
            }
            break;*/
          }
        }
      }
    //}
    //console.log("calenderDateRange=====>", calenderDateRange)
    //console.log("is_employee_matched=====>", is_employee_matched)
    if(is_employee_matched == 'Yes')
    {
      for (let i=0; i<12; i++) 
      {
        var processMonth = (currentDisplayMonth.getMonth()+1);
        //console.log("processMonth.length===>"+processMonth.length);
        if(processMonth != 12 && processMonth != 11 && processMonth != 10)
        {
          processMonth = '0'+processMonth;
        }

        if(calenderDateRange[0] == 'Only the 1st of the month')
        {
          includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
        }
        else if(calenderDateRange[0] == 'Only 1st and 16th of the month')
        {
          includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
          includeDatesFinal.push(new Date(processMonth+"/16/"+processYear));
        }
        else if(calenderDateRange[0] == 'Only 1st or 15th of the month')
        {
          includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
          includeDatesFinal.push(new Date(processMonth+"/15/"+processYear));
        }
        else if(calenderDateRange[0] == 'Any date during the month')
        {
          var totalDaysInMonth = new Date(processYear, processMonth, 0).getDate();
          //console.log("totalDaysInMonth====>", totalDaysInMonth);
          if(totalDaysInMonth > 0)
          {
            for(var days_incr = 1; days_incr <= totalDaysInMonth; days_incr++)
            {
              if(days_incr < 10)
              {
                days_incr = '0'+days_incr;
              }
              includeDatesFinal.push(new Date(processMonth+"/"+days_incr+"/"+processYear));
            }
          }
        }
        //console.log("====>", processMonth);
        if(processMonth == 12)
        {
          var date_1 = new Date();
          processYear = date.getFullYear()+1;
        }
        currentDisplayMonth.setMonth(currentDisplayMonth.getMonth() + 1);
      }
    }
    else
    {
      for (let i=0; i<12; i++) 
      {
        var processMonth = (currentDisplayMonth.getMonth()+1);
        //console.log("processMonth.length===>"+processMonth.length);
        if(processMonth != 12 && processMonth != 11 && processMonth != 10)
        {
          processMonth = '0'+processMonth;
        }

        var totalDaysInMonth = new Date(processYear, processMonth, 0).getDate();
        //console.log("totalDaysInMonth====>", totalDaysInMonth);
        if(totalDaysInMonth > 0)
        {
          for(var days_incr = 1; days_incr <= totalDaysInMonth; days_incr++)
          {
            if(days_incr < 10)
            {
              days_incr = '0'+days_incr;
            }
            includeDatesFinal.push(new Date(processMonth+"/"+days_incr+"/"+processYear));
          }
        }
        //console.log("====>", processMonth);
        if(processMonth == 12)
        {
          var date_1 = new Date();
          processYear = date.getFullYear()+1;
        }
        currentDisplayMonth.setMonth(currentDisplayMonth.getMonth() + 1);
      }
    }
  }
  else if(selectedEmployees.length > 1)
  {
    calenderDateRange[dateRangeIncr] = 'Only the 1st of the month';
    var countryMatchedWithConfig = 'N';
    var allCountries = new Array();
    for(var incr_1 = 0; incr_1 < selectedEmployees.length; incr_1++)
    {
      var Country       = selectedEmployees[incr_1].country_name;
      allCountries[incr_1] = Country;
    }

    var allCountrySame = allCountries.every( (val, i, arr) => val === arr[0] );
    console.log("allCountries======>", allCountries);
    console.log("allCountrySame======>", allCountrySame);
    if(allCountrySame == true)
    {
      for(var incr_2 = 0; incr_2 < allEffectiveDateConfigs.length; incr_2++)
      {
        var Country_Config             = allEffectiveDateConfigs[incr_2].country;
        var EffDate_Compensation_Config = allEffectiveDateConfigs[incr_2].salary_increase_effective_date;
        var EffDate_Onetime_Config     = allEffectiveDateConfigs[incr_2].one_time_payment_effective_date;
        if(allCountries[0] == Country_Config)
        {
          countryMatchedWithConfig = 'Y';
          is_employee_matched = 'Yes';
          calenderDateRange[0] = EffDate_Onetime_Config;
          break;
        }
      }
      if(countryMatchedWithConfig == 'N')
      {
        calenderDateRange[0] = 'Any date during the month';
      }
    }
    
    
    for (let i=0; i<12; i++) 
    {
      var processMonth = (currentDisplayMonth.getMonth()+1);
      //console.log("processMonth.length===>"+processMonth.length);
      if(processMonth != 12 && processMonth != 11 && processMonth != 10)
      {
        processMonth = '0'+processMonth;
      }

      if(calenderDateRange[0] == 'Only the 1st of the month')
      {
        includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
      }
      else if(calenderDateRange[0] == 'Only 1st and 16th of the month')
      {
        includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
        includeDatesFinal.push(new Date(processMonth+"/16/"+processYear));
      }
      else if(calenderDateRange[0] == 'Only 1st or 15th of the month')
      {
        includeDatesFinal.push(new Date(processMonth+"/01/"+processYear));
        includeDatesFinal.push(new Date(processMonth+"/15/"+processYear));
      }
      else if(calenderDateRange[0] == 'Any date during the month')
      {
        var totalDaysInMonth = new Date(processYear, processMonth, 0).getDate();
        //console.log("totalDaysInMonth====>", totalDaysInMonth);
        if(totalDaysInMonth > 0)
        {
          for(var days_incr = 1; days_incr <= totalDaysInMonth; days_incr++)
          {
            if(days_incr < 10)
            {
              days_incr = '0'+days_incr;
            }
            includeDatesFinal.push(new Date(processMonth+"/"+days_incr+"/"+processYear));
          }
        }
      }
      //console.log("====>", processMonth);
      if(processMonth == 12)
      {
        var date_1 = new Date();
        processYear = date.getFullYear()+1;
      }
      currentDisplayMonth.setMonth(currentDisplayMonth.getMonth() + 1);
    }
  }
  var minimumDate = '';

  var minimumDate_Highlighted = '';
  var minimumDate_Selectable = '';
  //console.log("calenderDateRange===>", calenderDateRange);
  /*  DEM0065665 - Effective date Starts */
  //var currentDisplayMonthDefault = new Date(now.getFullYear(), now.getMonth(), 1);
  if(calenderDateRange[0] == 'Only the 1st of the month')
  {
    if(date.getDate() > '1')
    {
      minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

      minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
      minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
    }
    else
    {
      minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

      minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
      minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
    }
  }
  else if(calenderDateRange[0] == 'Only 1st or 15th of the month')
  {
    if(date.getDate() > '1')
    {
      minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);
      
      minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
      minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
    }
    else
    {
      minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

      minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
      minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
    }
  }
  else if(calenderDateRange[0] == 'Only 1st and 16th of the month')
  {
    if(date.getDate() > '1')
    {
      minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

      minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
      minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
    }
    else
    {
      minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

      minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
      minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
    }
  }
  else
  {
    if(date.getDate() > '1')
    {
      minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

      minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth()+1, 1);
      minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
    }
    else
    {
      minimumDate = new Date(date.getFullYear(), date.getMonth(), 1);

      minimumDate_Highlighted = new Date(date.getFullYear(), date.getMonth(), 1);
      minimumDate_Selectable = new Date(date.getFullYear(), date.getMonth(), 1);
    }
  }
  const [paymentDate, setPaymentDate] = useState(minimumDate_Highlighted);
  //console.log("includeDatesFinal===>", includeDatesFinal);
  //console.log("includeDatesFinal Length===>", includeDatesFinal.length);
  //console.log("minimumDate===>", minimumDate);
  /*  DEM0065665 - Effective date Ends */

  return (
    <div>
      <FormHeading>Request One-Time Payment</FormHeading>
      <FormLabel>Selected employees: {selectedEmployees.length}</FormLabel>
      <SelectedTable
        recordType="employees"
        items={selectedEmployees}
        columns={columns.requestOnetime}
        onChange={handleChange}
      />
      <FormLabelInline>Payment date:</FormLabelInline>
       {/**Release 2 change request -  adding minimum date - */}
       {/*  DEM0065665 - Effective date Starts */}
      {/*<DatePickerField selected={paymentDate} onChange={handlePaymentDateChange} minDate={new Date()}/>*/}
      <DatePickerField 
        selected={paymentDate} 
        onChange={handlePaymentDateChange} 
        minDate={minimumDate_Selectable} 
        includeDates={includeDatesFinal}
        /*includeDates={[
          new Date('12/01/2022'),
          new Date('12/15/2022'), 
          new Date('01/01/2023'),
          new Date('01/15/2023')]}*/
        />
      <i style={{'width':'100%','float':'left'}}><b>Please note that in some countries only possible effective date is 1st of the month due to Payroll requirements. If you need further support, please open an HR ticket to People Experience Care team.</b></i>  
      {/*  DEM0065665 - Effective date Ends */}
      <FormLabel>Reason for request:
      <i style={{'width':'100%','float':'left'}}>[Please do not use the Reason field for Backdated changes dates as this field is visible only for approvers and not updating CoreHR System!]</i>
      </FormLabel>
      <DescriptionInput
        name='request_description'
        onChange={handleDescriptionChange}
      />
      {submitErrors.length > 0 && submitErrors.map(err => {
        return (
          <ErrorsBlock key={Math.random()}>
            <IconWrap>!</IconWrap>
            {err}
          </ErrorsBlock>
        );
      })}
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
        <FormButton primary disabled={isSubmitting} onClick={handleRequestOnetimeSend}>Send request(s)</FormButton>
        {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
      </FormButtonsWrap>
      <CalculatorNew
        columns={columns.requestOnetime}
        recordType="employees"
        item={updatedItem}
        data={updateData}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
    /*  DEM0065665 - Effective date Starts */
    config: state.config,
    /*  DEM0065665 - Effective date Ends */
  };
};

export default connect(
  mapStateToProps,
  { unselectAllRecord, budgetSetUpdated, pushMessage }
)(RequestOneTime);
