/* Release 3.1 - Starts */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Ring } from "react-awesome-spinners";
import { FormButton, FormHeading, LoaderWrap, ErrorsBlock, IconWrap } from "..";
import { unselectAllRecord, pushMessage } from "../../actions";
import { sendReminderAPI } from "../../graphql/index";
import { apolloClient, apolloProxyClient } from "../../index";

const SendReminderRequest = (props) => {
  const {
    selectedRequests,
    account,
    hideModal,
    unselectAllRecord,
    pushMessage,
  } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState("");
  const [reminderUrl, setReminderUrl] = useState("");
  var emp_ids = selectedRequests.map(function (el) {
    return el.employee_request_id;
  });
   /* Release 3.1 - 11.1 - 145726 - Starts */
  var emp_items = selectedRequests.map(function (el) {
    let active_index = el.request.approvals.findIndex(item => item.approval_status == "active");
    let approver_name = (el.request.approvals[active_index].approver && el.request.approvals[active_index].approver.full_name) ? el.request.approvals[active_index].approver.full_name : "Inactive";
    return el.employee_request_id+ ": " + approver_name;
  });

  var emp_item = emp_items.join();
   /* Release 3.1 - 11.1 - 145726 - Ends */
  var emp_ids1 = emp_ids.join();

  const handleQuit = (event) => {
    hideModal();
    unselectAllRecord("requests");
  };

  const handleReminder = (event) => {
    let employeeIds = emp_ids1;

    if (emp_ids.length > 1) {
      employeeIds = "'" + emp_ids.join("','") + "'";
    }

    setIsSubmitting(true);
    setSubmitErrors("");
    if (!emp_ids.length) {
      setSubmitErrors("Employee Id is required!");
      setIsSubmitting(false);
      return null;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ EmployeeRequestId: employeeIds }),
    };

    fetch(reminderUrl, requestOptions)
      .then((response) => {
        if (response && response.ok) {
          return response.text();
        } else {
          setSubmitErrors("Failed to send notification mail.");
        }
        setIsSubmitting(false);
      })
      .then((data) => {
        let successData = JSON.parse(data);
        let statusMessage = "Notification mail has been sent successfully.";

        if (successData && successData.Table1) {
          var sentNtfnEmployeeList = successData.Table1.map(function (el) {
            return el.employee_request_id.toString();
          });

          var sentNtfnEmployees = sentNtfnEmployeeList.length
            ? sentNtfnEmployeeList.join()
            : "";
          statusMessage =
            "Notification mail has been sent successfully for employee requested id: " +
            sentNtfnEmployees +
            ".";
          var failerIds = emp_ids.filter(
            (x) => !sentNtfnEmployeeList.includes(x.toString())
          );

          if (failerIds.length) {
            statusMessage +=
              "Failed to send employee requested Id: " + failerIds.join();
          }
        }
        hideModal();
        unselectAllRecord("requests");
        pushMessage({
          type: "success",
          message: statusMessage,
        });
      })
      .catch((e) => {
        console.log(
          "Unable to send notification mail. Please try after sometime.",
          e
        );
        setIsSubmitting(false);
        setSubmitErrors(
          "Unable to send notification mail. Please try after sometime."
        );
      });
  };

  useEffect(() => {
    getSendReminderAPI().then((apiURL) => {
      setReminderUrl(apiURL);
    });
  }, []);

  const getSendReminderAPI = async () => {
    return await new Promise((resolve, reject) => {
      httpClient
        .query({
          query: sendReminderAPI,
          variables: {},
        })
        .then((response) => {
          if (response.data) {
            resolve(
              response.data.sendReminderAPI
                ? response.data.sendReminderAPI
                : "https://prod-13.westeurope.logic.azure.com:443/workflows/43144f3ee05740daa48aeb0e1996b876/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=U7wFJFEZJLHrJyd-UvFJZJ1uEDCc6UzOfwx3GOzh_Po"
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }).catch((err) => {
      console.error(err);
    });
  };

  return (
    <div>
      {submitErrors && (
        <ErrorsBlock>
          <IconWrap>!</IconWrap>
          {submitErrors}
        </ErrorsBlock>
      )}
      <FormHeading>
        Reminder will be sent to the requests {/* Release 3.1 - 11.1 - 145726 - Starts */ emp_item /* Release 3.1 - 11.1 - 145726 - Ends */}
        <p> Do you want to continue?</p>
        {props.selectedRequests.employee_request_id}
      </FormHeading>

      <FormButton onClick={handleQuit}>No</FormButton>
      <FormButton primary disabled={isSubmitting} onClick={handleReminder}>
        Yes
      </FormButton>
      {isSubmitting && (
        <LoaderWrap>
          <Ring color={"#001235"} size="2" sizeUnit="rem" />
        </LoaderWrap>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
  };
};

export default connect(mapStateToProps, { unselectAllRecord, pushMessage })(
  SendReminderRequest
);
/* Release 3.1 - Ends */
