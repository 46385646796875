export default (table) => {

  /* DEM0057819 Starts  */
  let mom_title = 'other';
  switch (table) {
    case 'requestCompesation':
      mom_title = "requestCompesation";
      break;
    case 'requestOnetime':
      mom_title = "requestOnetime";
      break;
    /* Release 3.2 - 1.1 - Starts */
    case 'requestBusinessExcellenceAward':
      mom_title = "requestBusinessExcellenceAward";
      break;
    /* Release 3.2 - 1.1 - Ends */    
    default:
      break;
  }
  /* DEM0057819 Starts  */
  
  return {
    type: "string",
    inputType: "select",
    name: "target_budget_org",
    label: "Target Budget",
    section: "Merit/Promotion Increase",
    visible: true,
    editable: true,
    // this could be affected by budget visibility of requester
    // might need real org names here
    options: (column, config, data) => {
      return ['organization_unit_4','organization_unit_3','organization_unit_2','organization_unit_1'].reduce((a,c) => {
        if (data[c]) a.push({value: data[c], label: data[`${c}_name`]});
        return a;
      }, []);
    },
    // this should probably be lowest level defined budget
    // this could be affected by budget visibility of requester
    defaultValue: (value, data) =>
      data.organization_unit_4 || data.organization_unit_3 || data.organization_unit_2 || data.organization_unit_1,
    allow: ["hr1","hr2","hr3","bcb"],
    /* DEM0057819 Starts  */
    mom_title
    /* DEM0057819 Ends  */
  };
};