import { currencyDisplayRenderer } from '../../renderers';

export default (table) => {
    return {
      name: "fte_incentive_target_amount",
      section: "Current Compensation Information",
      label: "Current Incentive Target Amount",
      visible: true,
      filterable: true,
      sortable:true,
      renderer: currencyDisplayRenderer
    };
};