import { percentRenderer, numberRenderer } from '../../renderers';

export default (table) => {
  let name = 'total_increaseed_percent';
  let renderer = (value) => value;
  let section = "";
  let label = "Total Increase %";
  let width = '80px';
  let inputType = null;
  let sortable = false;
  let editable = false;

  switch (table) {
    case 'myRequests':
      //console.log(config)
      renderer = percentRenderer;
      //renderer = (value) => value;
        //Width Defect - px -> %
      width = "4%";
      section = "My Request Page";
      break;

    case 'pendingRequests':
        //console.log(config)
        renderer = percentRenderer;
        //renderer = (value) => value;
        //width = "150px";
        section = "Pending Requests Page";
        break;
    case 'approvedRequestHistory':
     //console.log(config)
     renderer = percentRenderer;
     //renderer = (value) => value;
     //width = "150px";
     section = "Approved Requests Page";
     break;

    case 'requestHistory':
       //console.log(config)
       renderer = percentRenderer;
       //renderer = (value) => value;
       //width = "150px";
       section = "History Requests Page";
       break;
    
    default:
      break;
  }

  return {
    type: "string",
    inputType,
    name,
    section,
    label,
    visible: true,
    sortable,
    editable,
    width,
    //before,
    renderer,
    
  };
};