import App from "./App";
import { authProvider } from "./util/auth";
/*  FIXING DELEGATION ISSUE */

import {
  Home,
  MyRequests,
  PendingRequests,
  RequestsHistory,
  Employees,
  AllBudgets,
  ApprovedRequests,
  MyDelegates,
  AdminProxy,
  BudgetManage,
  AdminTables,
  AdminCalculator,
  MassOffCycle,
  AllRequestsHrc,
  /* Release 3.1 - Starts */
  ChangeApprover,
  LockdownTool,
  Maintenance,
  Reports,
  /* Release 3.1 - Ends */
  /* Release 3.2 - 8.1 - Starts */
  EmployeeHistoryDashboard,  
  /* Release 3.2 - 8.1 - Ends */
  /* Release 3.2 - 1.1 - Starts */
  BEABudgetManage,
  /* Release 3.2 - 1.1 - Ends */
} from "./pages";

export default (store) => {
  return [
    {
      ...App,
      store: store,
      auth: authProvider,
      routes: [
        {
          path: "/",
          exact: true,
          component: Home,
        },
        {
          path: "/employees",
          component: Employees,
          auth: authProvider,
        },
        {
          path: "/my-requests",
          component: MyRequests,
          auth: authProvider,
        },
        {
          path: "/pending-requests",
          component: PendingRequests,
          auth: authProvider,
        },
        {
          path: "/requests-history",
          component: RequestsHistory,
          auth: authProvider,
        },
        {
          path: "/all-budgets",
          component: AllBudgets,
          auth: authProvider,
        },
        {
          path: "/approved-requests",
          component: ApprovedRequests,
          auth: authProvider,
        },

        //new route for admin proxy
        {
          path: "/admin-proxy",
          component: AdminProxy,
          auth: authProvider,
        },

        /*  FIXING DELEGATION ISSUE */
        {
          path: "/my-delegates",
          component: MyDelegates,
          auth: authProvider,
        },

        /*Release 2 -  adding routes for budget management*/
        {
          path: "/budget-manage",
          component: BudgetManage,
          auth: authProvider,
        },

        //Release 2 - wave 2 Admin tables route
        {
          path: "/admin-tables",
          component: AdminTables,
          auth: authProvider,
        },

        /*  Added for admin calculations for R2 */
        {
          path: "/admin-calculator",
          component: AdminCalculator,
          auth: authProvider,
        },
        {
          path: "/mass-off-cycle",
          component: MassOffCycle,
          auth: authProvider,
        },
        //DEM0058258
        {
          path: "/all-requests-hrc",
          component: AllRequestsHrc,
          auth: authProvider,
        },
        /* Release 3.1 - Starts */
        {
          path: "/change-approver",
          component: ChangeApprover,
          auth: authProvider
        },
        {
          path: "/lockdown-tool",
          component: LockdownTool,
          auth: authProvider,
        },
        {
          path: "/maintenance",
          component: Maintenance,
          auth: authProvider,
        },
        {
          path: "/reports",
          component: Reports,
          auth: authProvider,
        },
        /* Release 3.1 - Ends */
        /* Release 3.2 - 8.1 - Starts */     
        {
          path: "/employee-history-dashboard",
          component: EmployeeHistoryDashboard,
          auth: authProvider,
        },
        /* Release 3.2 - 8.1 - Ends */
        /* Release 3.1 - Starts */
        {
          path: "/bea-budget-manage",
          component: BEABudgetManage,
          auth: authProvider,
        },
        /* Release 3.1 - Ends */
      ],
    },
  ];
};
