/* Release 3.2 - 1.1 - Starts */
import { currencyDisplayRenderer } from '../../renderers';

export default (table) => {
  let renderer = (value, data, account, config) => {
    value = (data.request_type && data.request_type != "businessexcellenceaward") ? value : 0.0;
    return currencyDisplayRenderer(value, data, account, config)
  };
  let visible_in_tooltip = (column, data, account) => (data.request_type && data.request_type != "businessexcellenceaward");

  return {
    key: Math.random(),
    name: "offcycle_budget_update",
    label: "Off-Cycle Budget Spending",
    tooltip_section: "Budget",
    visible_in_tooltip,
    renderer,
  };
};
/* Release 3.2 - 1.1 - Ends */