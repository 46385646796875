/* Release 3.2 - 7.2 - Starts */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Ring } from "react-awesome-spinners";
import {
  FormButton,
  FormButtonsWrap,
  LoaderWrap,
  FormHeading,
  IconWrap,
  ErrorsBlock,
} from "../";
import { pushMessage } from "../../actions";
import { DatePickerField } from "../../components/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import exportFromJSON from "export-from-json";
import { apolloClient, apolloProxyClient } from "../../index";
import * as queries from "../../graphql";
import {
  dateRenderer,
  dateTimeRenderer,
  currencyDisplayRenderer,
  percentRenderer,
  numberRenderer,
} from "../../util";
import { formatDate } from "../../util/helper";

const ExportToExcelAllRequests = (props) => {
  const orderBy = { created_ts: "DESC" };
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  /* FIX OF CAL5065706  STARTS  */
  const { account, hideModal, modal, config } = props;
  /* FIX OF CAL5065706  ENDS  */

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  let filterValue = modal.modalContent;
  //console.warn("Sachin ",filterValue);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [expandDate, setExpandDate] = useState(false);

  const isBCB = account.roles.includes("bcb");
  const isKeyUser = account.roles.includes("keyuser");
  const isRcb = account.roles.includes("rcb");
  const isHR1 = account.roles.includes("hr1");
  const isHR2 = account.roles.includes("hr2");
  const isHR3 = account.roles.includes("hr3");
  const isChr = account.roles.includes("chr");
  const isHRC = account.roles.includes("hrc");
  const isRTap = account.roles.includes("rtap");
  const isGTap = account.roles.includes("gtap");

  const Customised_Input_DatePicker = React.forwardRef((props, ref) => {
    return (
      <div style={{ display: "flex" }}>
        <input
          type="text"
          className=""
          onClick={props.onClick}
          ref={ref}
          value={props.value || props.placeholder}
          style={{
            paddingLeft: "10px",
            outline: "#CCC",
            borderRadius: "5px",
            border: "1px solid #CCC",
          }}
        />
        <div
          onClick={props.onClick}
          style={{
            height: "32px",
            fontSize: "16px",
            cursor: "pointer",
            fontWeight: "500",
            textAlign: "center",
            position: "relative",
            top: "6px",
            right: "20px",
          }}
        >
          <FontAwesomeIcon icon={faCalendarAlt} />
        </div>
      </div>
    );
  });

  const handleQuit = () => {
    hideModal();
    setIsSubmitting(false);
    setSubmitErrors([]);
  };

  const handelExport = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmitErrors([]);
    if (!startDate) {
      setSubmitErrors(["Start date is required!"]);
    } else if (!endDate) {
      setSubmitErrors(["End date is required!"]);
    } else {
      const start_date = formatDate(startDate);
      const end_date = formatDate(
        new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      );
      const date_filter = {
        between: [start_date, end_date],
      };
      filterValue.created_ts = date_filter;
      const load_length = 500;
      let execution_length = 500;
      let data = [];
      let i = 0;
      while (i < execution_length) {
        let fetch_data = await fetchExcelData(load_length, i, filterValue);
        if (fetch_data) {
          if (fetch_data.items) {
            let result_data = fetch_data.items.map(
              ({ upd_ins_by, upd_ins_ts, __typename, ...rest }) => rest
            );
            if (result_data && result_data.length > 0) {
              data = [...data, ...result_data];
            }
          }
          if (i == 0) {
            execution_length = fetch_data.totalCount;
          }
        }
        i += load_length;
      }
      if (data && data.length > 0) {
        data = data.map(function (item) {
          let current_itp_situation =
            item.for && item.for.current_itp_situation
              ? item.for.current_itp_situation
              : "Norm";
          let new_itp_situation = item.new_itp_situation
            ? item.new_itp_situation
            : "Norm";
          let approvals = "";
          for (let i = 0; i < item.approvals.length; i++) {
            let a = item.approvals[i];
            approvals +=
              a.approver === null
                ? a.approval_status === "active"
                  ? "Inactive approver"
                  : "Inactive approver"
                : a.approval_status === "active"
                ? a.approver.full_name
                : a.approver.full_name;
            if (i < item.approvals.length - 1) {
              approvals += " ⮕ ";
            }
          }
          let comments = "";
          for (let i = 0; i < item.comments.length; i++) {
            let a = item.comments[i];
            comments += "◉ ";
            comments += a.description;
            comments += " --";
            comments +=
              a.commenter === null
                ? "Inactive Approver"
                : a.commenter.full_name;
            comments += ", ";
            comments += dateTimeRenderer(a.upd_ins_ts);
            if (i < item.comments.length - 1) {
              comments += "\n";
            }
          }
          let changeApprovers = "";
          for (let i = 0; i < item.changeApprovers.length; i++) {
            let a = item.changeApprovers[i];
            changeApprovers += "◉ ";
            changeApprovers +=
              a.approval && a.approval != null
                ? "Level " + a.approval.approver_level
                : "";
            changeApprovers += " -'";
            changeApprovers += a.preApprover
              ? a.preApprover.full_name
              : "Inactive approver";
            changeApprovers += " ' replaced with '";
            changeApprovers += a.postApprover
              ? a.postApprover.full_name
              : "Inactive approver";
            changeApprovers += "' --";
            changeApprovers += a.changer
              ? a.changer.full_name
              : "Inactive changer";
            changeApprovers += ", ";
            changeApprovers += dateTimeRenderer(a.upd_ins_ts);
            if (i < item.changeApprovers.length - 1) {
              changeApprovers += "\n";
            }
          }
          let total_increaseed_percent = 0;
          if (
            item.request_type === "onetime" ||
            item.request_type === "businessexcellenceaward"
          ) {
            total_increaseed_percent = item.ttc_increase_percentage;
          } else {
            total_increaseed_percent =
              ((item.new_fte_ttc_annual_salary -
                item.for.fte_ttc_annual_salary) /
                item.for.fte_ttc_annual_salary) *
              100;
          }
          /* Release 3.2 - 1.1 Starts */
          let finalData = {};
          finalData["ID"] = item.employee_request_id;
          finalData["Date"] = dateTimeRenderer(item.created_ts);
          finalData["Request Type"] = item.request_type;
          finalData[
            "Employee"
          ] = `${item.for.full_name} (${item.for.nokia_id})`;
          if (
            isHR1 ||
            isHR2 ||
            isHR3 ||
            isRcb ||
            isChr ||
            isHRC ||
            isBCB ||
            isRTap ||
            isGTap
          ) {
            finalData["Organization Unit"] =
              item.organization_unit_name ||
              (item.request_type == "internalhire"
                ? item.hmanager && item.hmanager.organization_unit_name
                  ? item.hmanager.organization_unit_name
                  : ""
                : item.for && item.for.organization_unit_name
                ? item.for.organization_unit_name
                : "");
          }
          /*console.log("PROPS ON MAIN PAGE===>", props);
          console.log("CONFIG ON MAIN PAGE===>", config);
          console.log("ACCOUNT ON MAIN PAGE===>", account);*/
          if (
            isHR1 ||
            isHR2 ||
            isHR3 ||
            isRcb ||
            isChr ||
            isHRC ||
            isBCB ||
            isRTap ||
            isGTap ||
            isKeyUser
          ) {
            finalData["Business Group"] =
              item.organization_unit_1_name ||
              (item.request_type == "internalhire"
                ? item.hmanager.organization_unit_1_name
                : item.for.organization_unit_1_name);
          }
          finalData["Business Unit"] =
            item.organization_unit_2_name ||
            (item.request_type == "internalhire"
              ? item.hmanager.organization_unit_2_name
              : item.for.organization_unit_2_name);
          finalData["Requestor"] = item.by && item.by ? item.by.full_name : "";
          finalData["Country"] = item.request_country;
          /* FIX OF CAL5065706  STARTS  */
          finalData["Amount"] = currencyDisplayRenderer(
            item.ttc_increase_amount,
            item,
            account,
            config
          );
          /* FIX OF CAL5065706 END  */
          finalData["Total Increase %"] = percentRenderer(
            total_increaseed_percent
          );
          finalData["Reason for the Request"] = item.request_description;
          finalData["Status"] = item.status;
          finalData["Current-Country"] =
            item.for &&
            item.for.country_name &&
            item.request_type !== "businessexcellenceaward"
              ? item.for.country_name
              : "";
          finalData["Current-Job Family"] =
            item.for &&
            item.for.job_family_name &&
            item.request_type !== "businessexcellenceaward"
              ? item.for.job_family_name
              : "";
          finalData["Current-Job Sub Family"] =
            item.for &&
            item.for.job_subfamily_name &&
            item.request_type !== "businessexcellenceaward"
              ? item.for.job_subfamily_name
              : "";
          finalData["Current-Job"] =
            item.for &&
            item.for.job_name &&
            item.request_type !== "businessexcellenceaward"
              ? item.for.job_name
              : "";
          finalData["Current-Job Grade"] =
            item.for &&
            item.for.job_grade &&
            item.request_type !== "businessexcellenceaward"
              ? item.for.job_grade
              : "";
          finalData["Current-Position Title"] =
            item.for &&
            item.for.position_title &&
            item.request_type !== "businessexcellenceaward"
              ? item.for.position_title
              : "";
          /* FIX OF CAL5065706  STARTS  */    
          finalData["Current-Annual Base Salary"] =
            item.for &&
            item.for.fte_annual_salary &&
            item.request_type !== "businessexcellenceaward"
              ? currencyDisplayRenderer(item.for.fte_annual_salary, item, account, config)
              : "";
          /* FIX OF CAL5065706 Ends  */
          finalData["Current-Incentive Target Percentage"] =
            item.for &&
            item.for.fte_incentive_target_percentage &&
            item.request_type !== "businessexcellenceaward"
              ? percentRenderer(item.for.fte_incentive_target_percentage) +
                " " +
                current_itp_situation
              : "";
          /* FIX OF CAL5065706  STARTS  */    
          finalData["Current-Incentive Target Amount"] =
            item.for &&
            item.for.fte_incentive_target_amount &&
            item.request_type !== "businessexcellenceaward"
              ? currencyDisplayRenderer(
                  item.for.fte_incentive_target_amount,
                  item,
                  account,
                  config
                )
              : "";
          finalData["Current-Total Target Cash"] =
            item.for &&
            item.for.fte_ttc_annual_salary &&
            item.request_type !== "businessexcellenceaward"
              ? currencyDisplayRenderer(item.for.fte_ttc_annual_salary, item, account, config)
              : "";
          /* FIX OF CAL5065706  ENDS  */ 
          finalData["Current-TTC Compa-Ratio"] =
            item.for &&
            item.for.ttc_compa_ratio &&
            item.request_type !== "businessexcellenceaward"
              ? numberRenderer(item.for.ttc_compa_ratio)
              : "";
          finalData["Current-Incentive Plan"] =
            item.for &&
            item.for.incentive_plan_type &&
            item.request_type !== "businessexcellenceaward"
              ? item.for.incentive_plan_type
              : "";
          finalData["Current-Quartile"] =
            item.for &&
            item.for.ttc_employee_quartile &&
            item.request_type !== "businessexcellenceaward"
              ? item.for.ttc_employee_quartile
              : "";
          finalData["New-Country"] =
            item.for &&
            item.for.country_name &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? item.for.country_name
              : "";
          /* FIX OF CAL5065706 STARTS  */ 
          finalData["New-Job Family"] =
            item.promotion_job_family_name &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? item.promotion_job_family_name
              : "";
          finalData["New-Job Sub Family"] =
            item.promotion_job_subfamily_name &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? item.promotion_job_subfamily_name
              : "";
          finalData["New-Job"] =
            item.promotion_job_name &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? item.promotion_job_name
              : "";

          /*  CAL5065706 - Issue Fixed - By Changing New Job Grade Starts */    
          /*finalData["New-Job Grade"] =
            item.for &&
            item.for.job_grade &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? item.for.job_grade
              : "";
          */  
         /* FIX OF CAL5065706  STARTS  */
          finalData["New-Job Grade"] =
            item.promotion_job_grade &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? item.promotion_job_grade
              : "";
          

          finalData["New-Position Title"] =
            item.new_position_title &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? item.new_position_title
              : "";

          /* FIX OF CAL5065706  ENDS  */    
          /*  CAL5065706 - Issue Fixed - By Changing New Job Grade Ends */      

          /* FIX OF CAL5065706  STARTS  */
          finalData["New-Annual Base Salary"] =
            item.new_fte_annual_salary &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? currencyDisplayRenderer(item.new_fte_annual_salary, item, account, config)
              : "";

          /* FIX OF CAL5065706  ENDS  */
          finalData["New-Incentive Target Percentage"] =
            item.new_incentive_target_percentage &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? percentRenderer(item.new_incentive_target_percentage) +
                " " +
                new_itp_situation
              : "";
          /* FIX OF CAL5065706  STARTS  */
          finalData["New-Incentive Target Amount"] =
            item.new_fte_incentive_target_amount &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? currencyDisplayRenderer(
                  item.new_fte_incentive_target_amount,
                  item,
                  account,
                  config
                )
              : "";
          
          finalData["New-Total Target Cash"] =
            item.new_fte_ttc_annual_salary &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? currencyDisplayRenderer(item.new_fte_ttc_annual_salary, item, account, config)
              : "";
          /* FIX OF CAL5065706  ENDS  */
          finalData["New-TTC Compa-Ratio"] =
            item.new_ttc_compa_ratio &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? numberRenderer(item.new_ttc_compa_ratio)
              : "";
          finalData["New-Incentive Plan"] =
            item.new_incentive_plan_type &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? item.new_incentive_plan_type
              : "";
          finalData["New-Quartile"] =
            item.new_quartile &&
            item.request_type !== "businessexcellenceaward" &&
            item.request_type !== "onetime"
              ? item.new_quartile
              : "";
          finalData["Effective/Payment Date"] =
            /* Release 3.2 - 1.1 - Starts */ item.request_type == "onetime" ||
            item.request_type ==
              "businessexcellenceaward" /* Release 3.2 - 1.1 - Ends */
              ? item.payment_date
                ? dateRenderer(item.payment_date)
                : ""
              : item.effective_date
              ? dateRenderer(item.effective_date)
              : "";
          /* Release 3.2 - 11.2 - Starts */
          //  finalData["Last Reminder Date"] = item.reminder_info && item.reminder_info.last_reminder_date ? dateTimeRenderer(item.reminder_info.last_reminder_date) : 'N/A';
          //  finalData["Reminder Count"] = item.reminder_info && item.reminder_info.reminder_count ? item.reminder_info.reminder_count : '0';
          /* Release 3.2 - 11.2 - Ends */
          /* FIX OF CAL5065706  STARTS  */
          finalData["Off-Cycle Budget Spending"] = item.offcycle_budget_update
            ? currencyDisplayRenderer(item.offcycle_budget_update, item, account, config)
            : "";
          /* FIX OF CAL5065706  ENDS  */
          finalData["Approval Chain"] = approvals;
          finalData["Comments"] = comments;
          finalData["Change Approver"] = changeApprovers;
          /* Release 3.2 - 1.1 - Starts */
          finalData["Award Title"] = item.award_title ? item.award_title : "";
          finalData["Award Message"] = item.award_message
            ? item.award_message
            : "";
          finalData["Award Privacy"] = item.award_privacy
            ? item.award_privacy
            : "";
          finalData["Award Reason"] = item.award_reason
            ? item.award_reason
            : "";
          finalData["Award Amount"] =
            item.ttc_increase_amount &&
            item.request_type == "businessexcellenceaward"
              ? item.ttc_increase_amount
              : "";
          finalData["Nominator Email Address"] = item.nominator_email_address
            ? item.nominator_email_address
            : "";
          /* Release 3.2 - 1.1 - Ends */
          return finalData;
          /* Release 3.2 - 1.1 Ends */
        });
        data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':""'));
        const fileName = "requests_history_" + new Date().toISOString();
        const exportType = "csv";
        exportFromJSON({ data, fileName, exportType });
        setIsSubmitting(false);
        hideModal();
        pushMessage({
          type: "success",
          message: "Data exported successfully.",
        });
      } else {
        setIsSubmitting(false);
        setSubmitErrors(["No Record Found to Export the Excel"]);
      }
    }
    setIsSubmitting(false);
  };

  const fetchExcelData = (limit, offset, filterValue) => {
    const tableQuery = "REQUESTSFOREXCELEXPORT";
    return httpClient
      .query({
        query: queries[tableQuery],
        variables: {
          where: filterValue,
          limit,
          offset: offset,
          orderBy: orderBy.length
            ? orderBy
            : [{ column: "created_ts", order: "DESC" }],
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data["requests"];
      })
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
        return null;
      });
  };

  return (
    <div style={{ height: expandDate ? "320px" : "auto" }}>
      <FormHeading>All Requests export to excel</FormHeading>
      {submitErrors.length > 0 &&
        submitErrors.map((err) => {
          return (
            <ErrorsBlock key={Math.random()}>
              <IconWrap>!</IconWrap>
              {err}
            </ErrorsBlock>
          );
        })}
      <FormButtonsWrap>
        <label> Start Date : </label>
        <DatePickerField
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            if (
              endDate &&
              Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) >
                Date.UTC(
                  endDate.getFullYear(),
                  endDate.getMonth(),
                  endDate.getDate()
                )
            ) {
              setEndDate(date);
            }
          }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          customInput={<Customised_Input_DatePicker />}
          onCalendarOpen={() => {
            setExpandDate(true);
            setSubmitErrors([]);
          }}
          onCalendarClose={() => {
            setExpandDate(false);
            setSubmitErrors([]);
          }}
        />

        <label
          style={{
            paddingLeft: "10px",
          }}
        >
          {" "}
          End Date :{" "}
        </label>
        <DatePickerField
          wrapperClassName="datePicker"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          customInput={<Customised_Input_DatePicker />}
          onCalendarOpen={() => {
            setExpandDate(true);
            setSubmitErrors([]);
          }}
          onCalendarClose={() => {
            setExpandDate(false);
            setSubmitErrors([]);
          }}
        />
        {/* DEFECT No. 6 STARTS  */}
        <FormButton onClick={handleQuit}>Quit</FormButton>
        <FormButton primary disabled={isSubmitting} onClick={handelExport}>
          Export
        </FormButton>
        {/* DEFECT No. 6 ENDS  */}
        {isSubmitting && (
          <LoaderWrap>
            <Ring color={"#001235"} size="2" sizeUnit="rem" />
          </LoaderWrap>
        )}
      </FormButtonsWrap>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
    /* FIX OF CAL5065706  STARTS  */
    config: state.config,
    /* FIX OF CAL5065706  ENDS  */
  };
};

export default connect(mapStateToProps, { pushMessage })(
  ExportToExcelAllRequests
);

/* Release 3.2 - 7.2 - Ends */
