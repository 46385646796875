/* Release 3.2 - 8.1 - Starts */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FormHeading,
  FormButtonsWrap,
  FormButton,
  Table,
  TableHeadingWrap,
} from "../";
import { columns } from "../../util/config";
import { unselectAllRecord, pushMessage } from "../../actions";

const PerformanceData = (props) => {
  const [queryFilter, setTableFilter] = useState({});
  const [employeeDetails, setEmployeeDetails] = useState([]);
  
  const {
    selectedEmployees,
    hideModal,
    unselectAllRecord,
  } = props;

  const orderBy = {};

  useEffect(() => {
    if (selectedEmployees && selectedEmployees.length > 0) {
      setEmployeeDetails(selectedEmployees[0]);
      setTableFilter({ pers_no: { eq: selectedEmployees[0].pers_no} })
    }
  }, []);

  const handleQuit = () => {
    hideModal();
    unselectAllRecord("employees");
  };
  
  return (
    <div>
      <FormHeading>Performance Data</FormHeading>      
      <TableHeadingWrap
        style={{ marginBlockEnd: "1em" }}
      >
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            {" "}
            <b>Nokia ID :</b>{" "}
          </label>
          {employeeDetails && employeeDetails.nokia_id
            ? employeeDetails.nokia_id
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> Name : </b>
          </label>
          {employeeDetails && employeeDetails.full_name
            ? employeeDetails.full_name
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> UPI ID : </b>
          </label>
          {employeeDetails && employeeDetails.upi_id
            ? employeeDetails.upi_id
            : ""}
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            fontWeight: "500",
            fontSize: "13px",
            gridGap: "10px",
            alignItems: "baseline",
          }}
        >
          <label>
            <b> Requisition ID : </b>
          </label>
          {employeeDetails && employeeDetails.requisition_id
            ? employeeDetails.requisition_id
            : ""}
        </div>
      </TableHeadingWrap>
      {employeeDetails &&  queryFilter.pers_no &&   
        <Table
          recordType="employeePerformanceDashboard"
          columns={columns.employeePerformanceDashboard}
          query="performanceHistoryList"
          where={queryFilter}
          order={orderBy}
          checkboxIsDisplayed={false}
        />
      }
      <FormButtonsWrap>
        <FormButton onClick={handleQuit}>Quit</FormButton>
      </FormButtonsWrap>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEmployees: state.selectedEmployees,
    account: state.account,
  };
};

export default connect(mapStateToProps, { unselectAllRecord, pushMessage })(
  PerformanceData
);
/* Release 3.2 - 8.1 - Ends */
