import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import reducers from '../reducers';

const composeEnhancers = composeWithDevTools({});

export default intialState => {
  const store = createStore(reducers, intialState || {}, composeEnhancers(applyMiddleware(thunk)));
  return store;
};
