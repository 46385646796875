export default (table) => {
  let renderer =null;
  /* Release 3.2 - 10.3 - Starts */
  let visible = true;
  /* Release 3.2 - 10.3 - Ends */
  /*  DEM0060697 OCC Corrections 2 CHANGES STARTS */
  switch (table) {
    case 'approvedRequestHistory':
      //renderer=(value, data) => value || data.request.for.organization_unit_1_name;
      renderer=(value, data) => value || (data.request.request_type == 'internalhire' ? data.request.hmanager.organization_unit_1_name : data.request.for.organization_unit_1_name); 
      break;
      case 'employees':
      renderer=(value, data) => value || data.organization_unit_1_name;
      break;
      case 'pendingRequests':
        //renderer=(value, data) => value || data.request.for.organization_unit_1_name;
        renderer=(value, data) => value || (data.request.request_type == 'internalhire' ? data.request.hmanager.organization_unit_1_name : data.request.for.organization_unit_1_name); 
        break;
      /* Release 3.2 - 10.3 - Starts */
      // case 'requestHistory':
      //   visible = false;
      //   break;
      /* Release 3.2 - 10.3 - Ends */
    default:
        //renderer=(value, data) => value || data.for.organization_unit_1_name;
        renderer=(value, data) => value || (data.request_type == 'internalhire' ? data.hmanager.organization_unit_1_name : data.for.organization_unit_1_name);
        break;
  }
  return {
       name: "organization_unit_1_name", 
       allow: ["hr2", "hr1", "hr3", "rcb", "chr", "hrc", "bcb","rtap","gtap"
              ,/* Release 3.2 - 10.3 - Starts */ "keyuser" /* Release 3.2 - 10.3 - Ends */
              ,/* Release 3.2 - 1.1 - Starts */  "business_owner" /* Release 3.2 - 1.1 - Ends */
              ],
       label: "Business Group",
       section: "HR",
       visible: /* Release 3.2 - 10.3 - Starts */ visible /* Release 3.2 - 10.3 - Ends */,
       sortable: true,
         //Width Defect - px -> %
       width:"8%",
       renderer
  };
};