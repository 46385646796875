import nokia_id from "./nokia_id";
import full_name from "./full_name";
import job_name from "./job_name";
import job_grade from "./job_grade";
import promotion_job_family_name from "./promotion_job_family_name";
import promotion_job_subfamily_name from "./promotion_job_subfamily_name";
import promotion_job_name from "./promotion_job_name";
import promotion_job_grade from "./promotion_job_grade";
import new_position_title from "./new_position_title";
import ttc_increase_amount from "./ttc_increase_amount";
import country_name from "./country_name";
import email_work from "./email_work";
import rr_number from "./rr_number";
import new_incentive_scheme from "./incentive_scheme";
import fte_annual_salary from "./fte_annual_salary";
import fte_incentive_target_percentage from "./fte_incentive_target_percentage";
import fte_incentive_target_amount from "./fte_incentive_target_amount";
import fte_addl_ttc_amount from "./fte_addl_ttc_amount";
import fte_ttc_annual_salary from "./fte_ttc_annual_salary";
import created_ts from "./created_ts";
import request_by from "./request_by";
import effective_date from "./effective_date";
import request_for from "./request_for";
import lumpsum_abs_percentage from "./lumpsum_abs_percentage";
import target_budget_org from "./target_budget_org";
import request_type from "./request_type";
import override_manager_proposal from "./override_manager_proposal";
import override_fte_annual_salary from "./override_fte_annual_salary";
import override_fte_incentive_target_amount from "./override_fte_incentive_target_amount";
import override_fte_incentive_target_percentage from "./override_fte_incentive_target_percentage";
import override_fte_ttc_annual_salary from "./override_fte_ttc_annual_salary";
import override_promotion_increase from "./override_promotion_increase";
import override_ti_harmonization from "./override_ti_harmonization";

import total_increaseed_amount from "./total_increaseed_amount"; // FOR DEFECT 1
import total_increaseed_percent from "./total_increaseed_percent"; // FOR CHANGE REQUEST

/* FOR DEFECT 8 STARTS  */
import business_group from "./business_group";
import organization_unit_name from "./organization_unit_name";
/* FOR DEFECT 8 ENDS  */

import budget_description from "./budget_description";
import budget_id from "./budget_id";
import budget_orgname from "./budget_orgname";
import budget_capital from "./budget_capital";
import budget_value from "./budget_value";
import budget_available from "./budget_available";
import budget_spent from "./budget_spent";
import budget_inprogress from "./budget_inprogress";
import budget_year from "./budget_year";
import budget_forecast from "./budget_forecast";
/**Release 2 - Admin Table */
import admin_gpp from "./gpp_Admin_update";

/**Release 2 Our Talent */
import new_abs from "./new_abs";
import new_ita from "./new_ita";
import new_itp from "./new_itp";
import ttc_increase_perc from "./ttc_increase_perc";
/* Release 3.1 - Starts */
import effective_payment_date from "./effective_payment_date";
/* Release 3.1 - Ends */
/* Release 3.2 - 10.3 - Starts */
import business_unit_name from "./business_unit_name";
/* Release 3.2 - 10.3 - Ends */
/* Release 3.2 - 13.1 - Starts */
import addl_pay_amount from "./addl_pay_amount";
import sum_of_pay_amount from "./sum_of_pay_amount";
/* Release 3.2 - 13.1 - Ends */
/* Release 3.2 - 12.2 - Starts */
import payment_date from "./payment_date";
/* Release 3.2 - 12.2 - Ends */
/* Release 3.2 - 1.1 - Starts */
import bo_budget from "./bo_budget";
import bo_budget_available from "./bo_budget_available";
import bo_budget_spent from "./bo_budget_spent";
import bo_budget_inprogress from "./bo_budget_inprogress";
import merge_full_name from "./merge_full_name";
/* Release 3.2 - 1.1 - Ends */

export default {
  nokia_id,
  full_name,
  job_name,
  job_grade,
  promotion_job_family_name,
  promotion_job_subfamily_name,
  promotion_job_name,
  promotion_job_grade,
  new_position_title,
  ttc_increase_amount,
  country_name,
  email_work,
  rr_number,
  new_incentive_scheme,
  fte_annual_salary,
  fte_ttc_annual_salary,
  fte_incentive_target_percentage,
  fte_incentive_target_amount,
  fte_addl_ttc_amount,
  created_ts,
  request_by,
  effective_date,
  request_for,
  lumpsum_abs_percentage,
  target_budget_org,
  request_type,
  override_manager_proposal,
  override_fte_annual_salary,
  override_fte_incentive_target_amount,
  override_fte_incentive_target_percentage,
  override_fte_ttc_annual_salary,
  override_promotion_increase,
  override_ti_harmonization,
  total_increaseed_amount,
  total_increaseed_percent,
  business_group,

  organization_unit_name,
  budget_description,
  budget_id,
  budget_orgname,
  budget_capital,
  budget_forecast,
  budget_value,
  budget_available,
  budget_spent,
  budget_inprogress,
  budget_year,

  admin_gpp,

  new_abs,
  new_ita,
  new_itp,
  ttc_increase_perc,
  /* Release 3.1 - Starts */
  effective_payment_date,
  /* Release 3.1 - Ends */
  /* Release 3.2 - 10.3 - Starts */
  business_unit_name,
  /* Release 3.2 - 10.3 - Ends */
  /* Release 3.2 - 13.1 - Starts */
  addl_pay_amount,
  sum_of_pay_amount,
  /* Release 3.2 - 13.1 - Ends */
  /* Release 3.2 - 12.2 - Starts */
  payment_date,
  /* Release 3.2 - 12.2 - Ends */
  /* Release 3.2 - 1.1 - Starts */
  bo_budget,
  bo_budget_available,
  bo_budget_spent,
  bo_budget_inprogress,
  merge_full_name,
  /* Release 3.2 - 1.1 - Ends */
};
