import { filterJobCatalog, buildFilters, filterPaymentTypes, filterIncentiveTypes } from '.';

export const optionsFilter = (column, config, data) => {
  switch (column.name) {
    case 'promotion_job_family_name':
    case 'request.promotion_job_family_name':     
      return filterJobCatalog(buildFilters('job_family_name', data), 'job_family_name', config, data);
    case 'promotion_job_subfamily_name':
    case 'request.promotion_job_subfamily_name':
      return filterJobCatalog(buildFilters('job_subfamily_name', data), 'job_subfamily_name', config, data);
    case 'promotion_job_name':
    case 'request.promotion_job_name':
      console.log("optiondata - edit check" , data);
      console.log("optionconfig" , config);
      const test = filterJobCatalog(buildFilters('job_name', data), 'job_name', config, data);
      console.log("Test" , test);
      return test;
    case 'request.payment_type':
    case 'payment_type':
      return filterPaymentTypes();
    case 'new_incentive_scheme':
      return filterIncentiveTypes();
    case 'promotion_job_grade' : 
   
      return config.jobGrades.filter((job) => /^\d+$/.test(job));
    default:
      return [];
  }
}