/* Release 3.1 - Starts */
import React from 'react';
import { FilterWrap } from '../styled';

const SelectFilterKeyValue = props => {
  const { options, onSelectChange } = props;

  return (
    <FilterWrap>
      <select onChange={onSelectChange}>
        {options.map((o, index) => <option key={index} value={o.value}>{o.text}</option>)}
      </select>
    </FilterWrap>
  );
};

export { SelectFilterKeyValue };
/* Release 3.1 - Ends */
