/* Release 3.2 - 12.2 - Starts */
import { paymentDateRendererForInternalHire, paymentDateEditablecheck } from "../../renderers";

export default (table) => {
  let name = "request.payment_date";

  return {
    type: "string",
    inputType: "date",
    name,
    //allow: ["admin", "rtap", "gtap"],
    label: "Payment date",
    visible: true,
    editable: paymentDateEditablecheck,
    width: "10%",
    renderer: paymentDateRendererForInternalHire,
  };
};
/* Release 3.2 - 12.2 - Ends */
