/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import React from "react";
import { connect } from "react-redux";
import { hideModal } from "../../actions";
import {
  ModalWrap,
  ModalOverlay,
  ModalContent,
  ModalInnerContent,
  ModalClose,
  ApproveRequest,
  RejectRequest,
  SendbackRequest,
  CancelRequest,
  RequestCompesation,
  RequestOneTime,
  EditOneTime,
  EditCompensation,
  ExportFilters,
  MassOffCycleImport,
  Mocsubmission,
  Mocotsubmission,
  /*  FIXING DELEGATION ISSUE */
  CreateDelegate,
  RevokeDelegate,
  /* release 2  Add New Budget */
  AddNewBudget,
  EditBudget,

  /**Relase 2 - Admin Tables */
  AdminTableImport,
  InternalHire,
  EditInternalHire,
  /* Release 3.1 - Starts */
  ChangeApproverRequest,
  CancelApprovedRequest,
  SendReminderRequest,
  /* Release 3.1 - Ends */
  /* Release 3.2 - 9.1Req - Starts */
  InternalHireSimulations,
  /* Release 3.2 - 9.1Req - Ends */
  /* Release 3.2 - 8.1 - Starts */
  /* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
  OtherData,
  /* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
  CompensationData,
  PerformanceData,
  AddressData,
  /* Release 3.2 - 8.1 - Ends */
  /* Release 3.2 - 1.1 - Starts */
  AddBEABudget,
  EditBEABudget,
  BusinessOwnerBudget,
  Mocbeasubmission,
  EditBEA,
  DeleteBEABudget,
  /* Release 3.2 - 1.1  - Ends */
  /* Release 3.2 - 7.2 - Starts */
  ExportToExcelAllRequests,
  ExportToExcelAllRequestsHrc
  /* Release 3.2 - 7.2 - Ends */
} from "..";

/**
 * Usage:
 * Popup: store.dispatch(showModal({ content: popup.fields.content }))
 * Internal Componet: store.dispatch(showModal({ component: 'approve' }))
 * Hide: store.dispatch(hideModal())
 */

const Modal = (props) => {
  const { modal, hideModal: killModal } = props;
  const popupMap = {
    cancel: CancelRequest,
    approve: ApproveRequest,
    reject: RejectRequest,
    sendback: SendbackRequest,
    compesation: RequestCompesation,
    onetime: RequestOneTime,
    newbudget: AddNewBudget,
    editbudget: EditBudget,
    editOnetime: EditOneTime,
    editCompensation: EditCompensation,
    exportFilters: ExportFilters,
    massOffCycleImport: MassOffCycleImport,
    mocsubmit: Mocsubmission,
    mocotsubmit: Mocotsubmission,
    /*  FIXING DELEGATION ISSUE */
    createdelegate: CreateDelegate,

    revokedelegate: RevokeDelegate,
    /**Release 2 Admin tables */
    ATImport: AdminTableImport,

    // Internal Hire Popup
    internalHire: InternalHire,
    editinternalhire: EditInternalHire,
    /* Release 3.1 - Starts */
    changeApprover: ChangeApproverRequest,
    cancelApproved: CancelApprovedRequest,
    sendreminderRequest: SendReminderRequest,
    /* Release 3.1 - Ends */
    /* Release 3.2 - 9.1Req - Starts */
    internalHireSimulations: InternalHireSimulations,
    /* Release 3.2 - 9.1Req - Ends */
    /* Release 3.2 - 8.1 - Starts */
    /* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
    otherData: OtherData,
    /* Release DEM0069362:Add additional data for TAP users - Part 1 Start */
    compensationData: CompensationData,
    performanceData: PerformanceData,
    addressData: AddressData,
    /* Release 3.2 - 8.1 - Ends */
    /* Release 3.2 - 1.1 - Starts */
    addBEABudget: AddBEABudget,
    editBEABudget: EditBEABudget,
    businessOwnerBudget: BusinessOwnerBudget,
    mocbeasubmit: Mocbeasubmission,
    editBEA: EditBEA,
    deleteBEABudget: DeleteBEABudget,
    /* Release 3.2 - 1.1 - Ends */
    /* Release 3.2 - 7.2 - Starts */
    exportToExcelAllRequests: ExportToExcelAllRequests,
    exportToExcelAllRequestsHrc: ExportToExcelAllRequestsHrc,
    /* Release 3.2 - 7.2 - Ends */
  };

  if (!modal.isVisible) return null;

  const ModalContentRef = React.createRef();

  const modelType = modal.modalComponent === "createdelegate" ? "md" : "lg"; // Added code for delaget UI chnages

  const handleKillModalEvent = (event) => {
    if (
      event.target === ModalContentRef.current ||
      event.target.tagName === "A"
    ) {
      killModal();
    }
  };

  const InternalComponentTagName = modal.modalComponent
    ? popupMap[modal.modalComponent]
    : null;
    
  return (
    <ModalWrap>
      <ModalOverlay />
      <ModalContent ref={ModalContentRef} onClick={handleKillModalEvent}>
        <ModalInnerContent {...props} size={modelType}>
          <ModalClose onClick={killModal}>&#215;</ModalClose>
          {InternalComponentTagName && <InternalComponentTagName {...props} />}
        </ModalInnerContent>
      </ModalContent>
    </ModalWrap>
  );
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

export default connect(mapStateToProps, { hideModal })(Modal);
