/* Release 3.2 - 10.3 - Starts */
export default (table) => {
  let renderer = (value, data) =>
    value || //data.for.organization_unit_2_name;
    (data.request_type == "internalhire"
      ? data.hmanager.organization_unit_2_name
      : data.for.organization_unit_2_name);
  return {
    name: "organization_unit_2_name",
    label: "Business Unit",
    visible: true,
    sortable: true,
    width: "5%",
    renderer,
  };
};
/* Release 3.2 - 10.3 - Ends */
