/* Release 3.1 - Starts */
import React, { useState, useEffect } from "react";
import { apolloClient, apolloProxyClient } from "../index";
import * as queries from "../graphql";
import { connect } from "react-redux";
import exportFromJSON from "export-from-json";
import {
  TableHeadingWrap,
  Header,
  TableActionsWrap,
  LoaderWrap,
  SelectFilterKeyValue,
  TableAction,
} from "../components";
import { DatePickerField } from "../components/index";
import { InputField } from "../components/styled";
import { Ring } from "react-awesome-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheck,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
/* Release 3.1 - 4.5 - 145643 - Starts */
import { formatDate } from "../util/helper";
/* Release 3.1 - 4.5 - 145643 - Ends */
const Reports = (props) => {
  const { account } = props;
  const proxyCheck = sessionStorage.getItem("proxy_access");
  const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
  const isAdmin = account.roles.includes("admin");
  
  /* DEM0073149 - Report Updation Starts */
  const isKeyUser = account.roles.includes("keyuser");
  const isHRC = account.roles.includes("hrc");
  /* DEM0073149 - Report Updation Ends */

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  /* DEM0073149 - Report Updation Starts 
  Remove the default Report Type value */
  const [selectedReportQuery, setSelectedReportQuery] = useState("");
  const [searchText, setSearchText] = useState("");
  const [messages, setMessages] = useState([]);
  const [messageType, setMessageType] = useState("error");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const Customised_Input_DatePicker = React.forwardRef((props, ref) => {
    return (
      <div style={{ display: "flex" }}>
        <input
          type="text"
          className=""
          onClick={props.onClick}
          ref={ref}
          value={props.value || props.placeholder}
          style={{
            paddingLeft: "10px",
            outline: "#CCC",
            borderRadius: "5px",
            border: "1px solid #CCC",
          }}
        />
        <div
          onClick={props.onClick}
          style={{
            height: "32px",
            fontSize: "16px",
            cursor: "pointer",
            fontWeight: "500",
            textAlign: "center",
            position: "relative",
            top: "6px",
            right: "20px",
          }}
        >
          <FontAwesomeIcon icon={faCalendarAlt} />
        </div>
      </div>
    );
  });
  /* DEM0073149 - Report Updation Starts */
  if(isAdmin)
  {
    var ddlOptions = [
      {
        text: "-- Select --",
        value: "",
      },
      {
        text: "Login audit",
        value: "auditlogins",
      },
      {
        text: "Approval Audit",
        value: "auditapprovals",
      },
      {
        text: "Request Audit",
        value: "auditrequests",
      },
      /* Release 3.1 - 4.5 - 145699 - Starts */
  
      {
        text: "Onetime Payment - OCC to NokiaMe Report",
        value: "onetTimeOCCToP24Export",
      },
      {
        text: "Compensation - OCC to NokiaMe Report",
        value: "compensationOCCToP24Export",
      },
      /* Release 3.1 - 4.5 - 145699 - Ends */
      /* Release 3.2 - 1.1 - Starts */
      {
        text: "BEA Shared",
        value: "auditBEASharedRequests",
      },
      {
        text: "BEA Private",
        value: "auditBEAPrivateRequests",
      },
      /* Release 3.2 - 1.1 - Ends */
    ];
  }
  else if(isKeyUser || isHRC)
  {
    
    var ddlOptions = [
      {
        text: "-- Select --",
        value: "",
      },
      {
        text: "Onetime Payment - OCC to NokiaMe Report",
        value: "onetTimeOCCToP24ExportHRKeyUser",
      },
      {
        text: "Compensation - OCC to NokiaMe Report",
        value: "compensationOCCToP24ExportHRKeyUser",
      },
    ];
  }
  /* DEM0073149 - Report Updation Ends */

  const handleReportQueryOnChange = (event) => {
    setSelectedReportQuery(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const clearChange = () => {
    setSearchText("");
    setMessages([]);
  };

  const handleExportData = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessages([]);
    let queryCondition = null;

    if (handleValidation() === true) {
      /* Release 3.1 - 4.5 - 145643 - Starts */
      const start_date = formatDate(startDate);
      const end_date = formatDate(
        new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      );
      const date_filter = {
        between: [start_date, end_date],
      };
      /* if (start_date == end_date) {
        date_filter = {
          eq: start_date,
        }
      } else {
        date_filter = {
          ge: start_date,
          le: end_date
        }
      } */
      /* Release 3.1 - 4.5 - 145643 - Ends */
      switch (selectedReportQuery) {
        case "auditlogins":
          queryCondition = {
            query: queries[selectedReportQuery],
            variables: {
              where: {
                /* Release 3.1 - 4.5 - 145643 - Starts */
                login_time: date_filter,
                /* Release 3.1 - 4.5 - 145643 - Ends */
                search_text: searchText,
              },
              offset: 0,
            },
          };

          break;
        case "auditrequests":
          queryCondition = {
            query: queries[selectedReportQuery],
            variables: {
              where: {
                /* Release 3.1 - 4.5 - 145643 - Starts */
                action_start_time: date_filter,
                /* Release 3.1 - 4.5 - 145643 - Ends */
                search_text: searchText,
              },
              offset: 0,
            },
          };
          break;
        case "auditapprovals":
          queryCondition = {
            query: queries[selectedReportQuery],
            variables: {
              where: {
                /* Release 3.1 - 4.5 - 145643 - Starts */
                request_start_time: date_filter,
                /* Release 3.1 - 4.5 - 145643 - Ends */
                search_text: searchText,
              },
              offset: 0,
            },
          };
          break;
        /* Release 3.1 - 4.5 - 145699 - Starts */
        case "onetTimeOCCToP24Export":
          queryCondition = {
            query: queries[selectedReportQuery],
            variables: {
              where: {
                upd_ins_ts: date_filter,
                search_text: searchText,
              },
              offset: 0,
            },
          };
          break;
        case "compensationOCCToP24Export":
          queryCondition = {
            query: queries[selectedReportQuery],
            variables: {
              where: {
                upd_ins_ts: date_filter,
                search_text: searchText,
              },
              offset: 0,
            },
          };
          break;
        /* Release 3.1 - 4.5 - 145699 - Ends */
        /* DEM0073149 - Report Updation Starts */
        case "onetTimeOCCToP24ExportHRKeyUser":
          queryCondition = {
            query: queries[selectedReportQuery],
            variables: {
              where: {
                upd_ins_ts: date_filter,
                search_text: searchText,
              },
              offset: 0,
            },
          };
          break;
        case "compensationOCCToP24ExportHRKeyUser":
          queryCondition = {
            query: queries[selectedReportQuery],
            variables: {
              where: {
                upd_ins_ts: date_filter,
                search_text: searchText,
              },
              offset: 0,
            },
          };
          break;
        /* DEM0073149 - Report Updation Ends */
        /* Release 3.2 - 1.1 - Starts */
        case "auditBEASharedRequests":
          queryCondition = {
            query: queries['auditBEARequests'],
            variables: {
              where: {
                dispatch_date: date_filter,
                search_text: searchText,
                award_privacy: { eq: "Shared" }
              },
              offset: 0,
            },
          };
          break;
        case "auditBEAPrivateRequests":
          queryCondition = {
            query: queries['auditBEARequests'],
            variables: {
              where: {
                dispatch_date: date_filter,
                search_text: searchText,
                award_privacy: { eq: "Private" },
              },
              offset: 0,
            },
          };
          break;
        /* Release 3.2 - 1.1 - Ends */
        default:
          queryCondition = {
            query: queries[selectedReportQuery],
            variables: {
              offset: 0,
            },
          };
          break;
      }
      httpClient
        .query(queryCondition)
        .then((response) => {
          /* Release 3.2 - 1.1 - Starts */
          let tableQuery = (selectedReportQuery == "auditBEAPrivateRequests" || selectedReportQuery == "auditBEASharedRequests") ? 'auditBEARequests' : selectedReportQuery;
          /* Release 3.2 - 1.1 - Ends */
          let data = response.data[tableQuery].items.map(
            ({ upd_ins_ts, __typename, ...rest }) => rest
          );

          if (data && data.length > 0) {
            /* Release 3.1 - 4.5 - 145699 - Starts */
            let multiDownloadArray = []; 
            if(selectedReportQuery == "onetTimeOCCToP24Export" || selectedReportQuery == "compensationOCCToP24Export") {
              const unique_geo_region = [...new Set(data.map(item => item.geo_region))];
                if(unique_geo_region && unique_geo_region.length > 0) {
                  unique_geo_region.forEach(element => {
                    let result  = data.filter((x) => { return x.geo_region == element; });
                    multiDownloadArray.push(result);
                  });
                }
            }
            /* DEM0073149 - Report Updation Starts */
            if(selectedReportQuery == "onetTimeOCCToP24ExportHRKeyUser" || selectedReportQuery == "compensationOCCToP24ExportHRKeyUser") {
              const unique_geo_region = [...new Set(data.map(item => item.geo_region))];
                if(unique_geo_region && unique_geo_region.length > 0) {
                  unique_geo_region.forEach(element => {
                    let result  = data.filter((x) => { return x.geo_region == element; });
                    multiDownloadArray.push(result);
                  });
                }
            }
            /* DEM0073149 - Report Updation Ends */
            if(multiDownloadArray && multiDownloadArray.length > 0) {
              multiDownloadArray.forEach(element => {
                let dataRegion = null;
                let region_name = element[0].geo_region;
                if(selectedReportQuery == "onetTimeOCCToP24Export") {                  
                  dataRegion = element.map(function(item){
                    return {
                          "Personnel Number": item.Personnel_Number,
                          /* DEM0069252 - Added new field start */
                          "Oracle Assignment Number": item.Oracle_Assignment_Number,
                          /* DEM0069252 - Added new field end */
                          "Subtype": item.Subtype,
                          "Wage Type Amount for Payments": item.Wage_Type_Amount_for_Payments,
                          "Currency Key": item.Currency_Key,
                          "Number": item.Number,
                          "Time/Measurement Unit Text": item.Time_Measurement_Unit_Text,
                          "Start Date": item.Start_Date,
                          "Accounting period - default date of origin": item.Accounting_period_default_date_of_origin,
                          "Accounting year - default date of origin": item.Accounting_year_default_date_of_origin,
                          "Assignment Number": item.Assignment_Number,
                          "Reason for Changing Master Data": item.Reason_for_Changing_Master_Data,
                          "Company Code ": item.Company_Code,
                          "Employee Group ": item.Employee_Group,
                          "Employee Subgroup ": item.Employee_Subgroup,                  
                    }
                  });
                }
                      
                if(selectedReportQuery == "compensationOCCToP24Export") {              
                  dataRegion = element.map(function(item){
                    return {
                      "First Name": item.First_Name,
                      "Last Name": item.Last_Name,
                      "Nokia ID": item.Nokia_ID,
                      "Pers No": item.Pers_No,
                      /* DEM0069252 - Added new field start */
                      "Oracle Assignment Number": item.Oracle_Assignment_Number,
                      /* DEM0069252 - Added new field end */
                      "Country": item.Country,
                      "Region": item.Region,
                      "Effective Date": item.Effective_Date,
                      "Reason Code": item.Reason_Code,
                      "Current Job Family": item.Current_Job_Family,
                      "Current Job SubFamily": item.Current_Job_SubFamily,
                      "Current Job": item.Current_Job,
                      "Current Job ID": item.Current_Job_ID,
                      /* DEM0069252 - Added new field start */
                      "Current Job Profile Abbr": item.Current_Job_Profile_Abbr,
                      /* DEM0069252 - Added new field end */
                      "Incentive Plan Type": item.Incentive_Plan_Type,
                      "Current Nokia Job Grade": item.Current_Nokia_Job_Grade,
                      "Current Position Title": item.Current_Position_Title,
                      "Employment (FTE) % at time of Submission": item.Employment_FTE_at_time_of_Submission,
                      "Local Currency": item.Local_Currency,
                      "Current Annual Base Salary (Full-Time Equivalent)": item.Current_Annual_Base_Salary_Full_Time_Equivalent,
                      "Current Incentive Target Amount (Full-Time Equivalent)": item.Current_Incentive_Target_Amoun_Full_Time_Equivalent,
                      "Current Incentive Target %": item.Current_Incentive_Target,
                      "Additional Annual Payment (Full-Time Equivalent)": item.Additional_Annual_Payment_Full_Time_Equivalent,
                      "Current Total Target Cash (Full-Time Equivalent)": item.Current_Total_Target_Cash_Full_Time_Equivalent,
                      "Proposed Increment (%)": item.Proposed_Increment,
                      "Promoted?": item.Promoted,
                      "Proposed New Job Family": item.Proposed_New_Job_Family,
                      "Proposed New Job Subfamily": item.Proposed_New_Job_Subfamily,
                      "Proposed New Job": item.Proposed_New_Job,
                      "Proposed New Job ID": item.Proposed_New_Job_ID,
                      /* DEM0069252 - Added new field start */
                      "Proposed Job Profile Abbr": item.Proposed_Job_Profile_Abbr,
                      /* DEM0069252 - Added new field end */
                      "Proposed New Nokia Job Grade": item.Proposed_New_Nokia_Job_Grade,
                      "Proposed New Title - Mandatory in case of promotion (max. 40 char.)": item.Proposed_New_Title_Mandatory_in_case_of_promotion_max_40_char,
                      "Is TI Harmonization Rule Applied?": item.Is_TI_Harmonization_Rule_Applied,
                      "New Annual Base Salary (Full-Time Equivalent)": item.New_Annual_Base_Salary_Full_Time_Equivalent,
                      "New Incentive Target Amount (Full-Time Equivalent)": item.New_Incentive_Target_Amount_Ful_Time_Equivalent,
                      "New Incentive Target %": item.New_Incentive_Target,
                      "Additional Annual Payment (Full-Time Equivalent)  ": item.Additional_Annual_Payment_Full_Time_Equivalent_2,
                      "New Total Target Cash (Full-Time Equivalent)": item.New_Total_Target_Cash_Full_Time_Equivalent,
                      "New Annual Base Salary (FTE Prorated)": item.New_Annual_Base_Salary_FTE_Prorated,
                      "New Incentive Target Amount (FTE Prorated)": item.New_Incentive_Target_Amount_FTE_Prorated,
                      "Additional Annual Payment (FTE Prorated)": item.Additional_Annual_Payment_FTE_Prorated,
                      "New Total Target Cash (FTE Prorated)": item.New_Total_Target_Cash_FTE_Prorated,
                      "Comments": item.Comments,
                      "Company Code": item.Company_Code,
                      "Employee Group": item.Employee_Group,
                      "Employee Subgroup": item.Employee_Subgroup,
                      "Job Family Code": item.Job_Family_Code,
                      "Job Sub Family Code": item.Job_Sub_Family_Code,                                    
                    }
                  });
                }


                /* DEM0073149 - Report Updation Starts */
                if(selectedReportQuery == "onetTimeOCCToP24ExportHRKeyUser") {                  
                  dataRegion = element.map(function(item){
                    return {
                          "Personnel Number": item.Personnel_Number,
                          /* DEM0069252 - Added new field start */
                          "Oracle Assignment Number": item.Oracle_Assignment_Number,
                          /* DEM0069252 - Added new field end */
                          "Subtype": item.Subtype,
                          "Wage Type Amount for Payments": item.Wage_Type_Amount_for_Payments,
                          "Currency Key": item.Currency_Key,
                          "Number": item.Number,
                          "Time/Measurement Unit Text": item.Time_Measurement_Unit_Text,
                          "Start Date": item.Start_Date,
                          "Accounting period - default date of origin": item.Accounting_period_default_date_of_origin,
                          "Accounting year - default date of origin": item.Accounting_year_default_date_of_origin,
                          "Assignment Number": item.Assignment_Number,
                          "Reason for Changing Master Data": item.Reason_for_Changing_Master_Data,
                          "Company Code ": item.Company_Code,
                          "Employee Group ": item.Employee_Group,
                          "Employee Subgroup ": item.Employee_Subgroup,                  
                    }
                  });
                }
                      
                if(selectedReportQuery == "compensationOCCToP24ExportHRKeyUser") {              
                  dataRegion = element.map(function(item){
                    return {
                      "First Name": item.First_Name,
                      "Last Name": item.Last_Name,
                      "Nokia ID": item.Nokia_ID,
                      "Pers No": item.Pers_No,
                      /* DEM0069252 - Added new field start */
                      "Oracle Assignment Number": item.Oracle_Assignment_Number,
                      /* DEM0069252 - Added new field end */
                      "Country": item.Country,
                      "Region": item.Region,
                      "Effective Date": item.Effective_Date,
                      "Reason Code": item.Reason_Code,
                      "Current Job Family": item.Current_Job_Family,
                      "Current Job SubFamily": item.Current_Job_SubFamily,
                      "Current Job": item.Current_Job,
                      "Current Job ID": item.Current_Job_ID,
                      /* DEM0069252 - Added new field start */
                      "Current Job Profile Abbr": item.Current_Job_Profile_Abbr,
                      /* DEM0069252 - Added new field end */
                      "Incentive Plan Type": item.Incentive_Plan_Type,
                      "Current Nokia Job Grade": item.Current_Nokia_Job_Grade,
                      "Current Position Title": item.Current_Position_Title,
                      "Employment (FTE) % at time of Submission": item.Employment_FTE_at_time_of_Submission,
                      "Local Currency": item.Local_Currency,
                      "Current Annual Base Salary (Full-Time Equivalent)": item.Current_Annual_Base_Salary_Full_Time_Equivalent,
                      "Current Incentive Target Amount (Full-Time Equivalent)": item.Current_Incentive_Target_Amoun_Full_Time_Equivalent,
                      "Current Incentive Target %": item.Current_Incentive_Target,
                      "Additional Annual Payment (Full-Time Equivalent)": item.Additional_Annual_Payment_Full_Time_Equivalent,
                      "Current Total Target Cash (Full-Time Equivalent)": item.Current_Total_Target_Cash_Full_Time_Equivalent,
                      "Proposed Increment (%)": item.Proposed_Increment,
                      "Promoted?": item.Promoted,
                      "Proposed New Job Family": item.Proposed_New_Job_Family,
                      "Proposed New Job Subfamily": item.Proposed_New_Job_Subfamily,
                      "Proposed New Job": item.Proposed_New_Job,
                      "Proposed New Job ID": item.Proposed_New_Job_ID,
                      /* DEM0069252 - Added new field start */
                      "Proposed Job Profile Abbr": item.Proposed_Job_Profile_Abbr,
                      /* DEM0069252 - Added new field end */
                      "Proposed New Nokia Job Grade": item.Proposed_New_Nokia_Job_Grade,
                      "Proposed New Title - Mandatory in case of promotion (max. 40 char.)": item.Proposed_New_Title_Mandatory_in_case_of_promotion_max_40_char,
                      "Is TI Harmonization Rule Applied?": item.Is_TI_Harmonization_Rule_Applied,
                      "New Annual Base Salary (Full-Time Equivalent)": item.New_Annual_Base_Salary_Full_Time_Equivalent,
                      "New Incentive Target Amount (Full-Time Equivalent)": item.New_Incentive_Target_Amount_Ful_Time_Equivalent,
                      "New Incentive Target %": item.New_Incentive_Target,
                      "Additional Annual Payment (Full-Time Equivalent)  ": item.Additional_Annual_Payment_Full_Time_Equivalent_2,
                      "New Total Target Cash (Full-Time Equivalent)": item.New_Total_Target_Cash_Full_Time_Equivalent,
                      "New Annual Base Salary (FTE Prorated)": item.New_Annual_Base_Salary_FTE_Prorated,
                      "New Incentive Target Amount (FTE Prorated)": item.New_Incentive_Target_Amount_FTE_Prorated,
                      "Additional Annual Payment (FTE Prorated)": item.Additional_Annual_Payment_FTE_Prorated,
                      "New Total Target Cash (FTE Prorated)": item.New_Total_Target_Cash_FTE_Prorated,
                      "Comments": item.Comments,
                      "Company Code": item.Company_Code,
                      "Employee Group": item.Employee_Group,
                      "Employee Subgroup": item.Employee_Subgroup,
                      "Job Family Code": item.Job_Family_Code,
                      "Job Sub Family Code": item.Job_Sub_Family_Code,                                    
                    }
                  });
                }
                

                dataRegion = JSON.parse(JSON.stringify(dataRegion).replace(/\:null/gi, ':""'));
                var fileName = '';
                fileName = selectedReportQuery + "_" + region_name + "_" + new Date().toISOString();
                if(selectedReportQuery == "onetTimeOCCToP24ExportHRKeyUser" || selectedReportQuery == "compensationOCCToP24ExportHRKeyUser")
                {
                  if(isKeyUser && !isHRC)
                  {
                    fileName = fileName.replace("HRKeyUser", "KeyUser");
                  }
                  else if(!isKeyUser && isHRC)
                  {
                    fileName = fileName.replace("HRKeyUser", "HRCUser");
                  }
                  else
                  {
                    fileName = fileName.replace("HRKeyUser", "KeyUser");
                  }
                }
                else
                {
                  fileName = selectedReportQuery + "_" + region_name + "_" + new Date().toISOString();
                }
                /* DEM0073149 - Report Updation Ends */
                const exportType = "csv";                
                exportFromJSON({ data: dataRegion, fileName, exportType });
              });              
            } else {
              /* Release 3.2 - 1.1 - Starts */
              if(selectedReportQuery == "auditBEAPrivateRequests" || selectedReportQuery == "auditBEASharedRequests") {
                data = data.map(function(item){
                  return {
                    "NOMINATOR_ID": item.nominator_id,
                    "RECIPIENT_ID": item.recipient_id,
                    "APPROVER_ID": item.approver_id,
                    "AWARD_NAME": item.award_name,
                    "AWARD_CURRENCY": item.award_currency,
                    "AWARD_AMOUNT": item.award_amount,
                    "AWARD_LANGUAGE": item.award_language,
                    "AWARD_MESSAGE": item.award_message,
                    "DISPATCH_DATE": item.dispatch_date,
                    "AWARD_REASON": item.award_reason,
                    "AWARD_TITLE": item.award_title,                  
                  }
                });
              }
              /* Release 3.2 - 1.1 - Ends */
              /* Release 3.1 - 4.5 - 145699 - Ends */
              data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, ':""'));
              const fileName =
                selectedReportQuery + "_" + new Date().toISOString();
              const exportType = "csv";
              exportFromJSON({ data, fileName, exportType });
            }
            setMessageType("success");
            setMessages(["Data exported scuccessfully."]);
          } else {
            setMessageType("error");
            setMessages(["No data found for selected filter."]);
          }
          setIsSubmitting(false);
        })
        .catch((err) => {
          console.error(err);
          setIsSubmitting(false);
        });
    } else {
      setMessageType("error");
      setIsSubmitting(false);
      return;
    }
  };

  const handleValidation = () => {
    let isValid = true;

    if (!selectedReportQuery) {
      setMessages((prevErrors) => [...prevErrors, "Report type is required!"]);
      isValid = false;
    }

    if (!startDate) {
      setMessages((prevErrors) => [...prevErrors, "Start date is required!"]);
      isValid = false;
    }

    if (!endDate) {
      setMessages((prevErrors) => [...prevErrors, "End date is required!"]);
      isValid = false;
    }

    return isValid;
  };

  const NotificationMassages = (props) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        setMessages([]);
        setMessageType("error");
      }, 6000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <>
        {props.messages && props.messages.length > 0 && (
          <div
            key={Math.random()}
            style={{
              border: "1px solid",
              margin: "10px 0px",
              padding: "15px 10px 15px 50px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "10px center",
              color: props.type === "error" ? "#D8000C" : "#4F8A10",
              backgroundColor: props.type === "error" ? "#FFBABA" : "#DFF2BF",
            }}
          >
            {props.messages.map((err) => {
              {
                return (
                  <>
                    <FontAwesomeIcon
                      icon={props.type === "error" ? faTimesCircle : faCheck}
                      style={{
                        marginRight: "10px",
                      }}
                    />
                    {err}
                    <br />
                  </>
                );
              }
            })}
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <Header {...props} />
      { /* DEM0073149 - Report Updation Starts */ }
      {(isAdmin || isKeyUser || isHRC) && (
        <>
          <NotificationMassages messages={messages} type={messageType} />
          <TableHeadingWrap>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ marginRight: "25px" }}>Reports</h2>
              <SelectFilterKeyValue
                options={ddlOptions}
                onSelectChange={handleReportQueryOnChange}
              ></SelectFilterKeyValue>
              <InputField
                type="text"
                placeholder= {
                  (selectedReportQuery == "auditlogins" /* Release 3.2 - 1.1 - Ends */|| selectedReportQuery == "auditBEAPrivateRequests" || selectedReportQuery == "auditBEASharedRequests"/* Release 3.2 - 1.1 - Ends */) ? "Search on Nokia Id" : "Search on Nokia Id, Request id"        
                }
                value={searchText}
                onChange={handleSearchChange}
                style={{
                  width: "350px",
                  marginLeft: "20px",
                  height: "32px",
                  outline: "#CCC",
                  borderRadius: "5px",
                  border: "1px solid #CCC",
                }}
              />
              <div
                onClick={clearChange}
                style={{
                  height: "32px",
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: "500",
                  textAlign: "center",
                  position: "relative",
                  top: "6px",
                  right: "20px",
                }}
              >
                X
              </div>

              <label> Start Date : </label>
              <DatePickerField
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  if (
                    endDate &&
                    Date.UTC(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate()
                    ) >
                      Date.UTC(
                        endDate.getFullYear(),
                        endDate.getMonth(),
                        endDate.getDate()
                      )
                  ) {
                    setEndDate(date);
                  }
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                customInput={<Customised_Input_DatePicker />}
              />

              <label
                style={{
                  paddingLeft: "10px",
                }}
              >
                {" "}
                End Date :{" "}
              </label>
              <DatePickerField
                wrapperClassName="datePicker"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={<Customised_Input_DatePicker />}
              />
            </div>
            <TableActionsWrap>
              <TableAction
                primary
                disabled={isSubmitting}
                onClick={handleExportData}
                id="btnExportToExcel"
              >
                Export To Excel
              </TableAction>
              {isSubmitting && (
                <LoaderWrap>
                  <Ring color={"#001235"} size="2" sizeUnit="rem" />
                </LoaderWrap>
              )}
            </TableActionsWrap>
          </TableHeadingWrap>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps)(Reports);
/* Release 3.1 - Ends */
