export default (table) => {
  let name = 'promotion_job_name';
  let visible_in_tooltip = /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
  ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true;
  /* Release 3.2 - 1.1 - Ends */
  let defaultValue = (value, data) => data.job_name;

  switch (table) {
    case 'editCompensation':
    case 'rejectRequests':
    case 'approveRequests':
      case 'editInternalHire':
      name = 'request.promotion_job_name';
      defaultValue = (value, data) => data.request.for.job_name;
      break;
    case 'myRequests':
    case 'requestsHistory':
    case 'approvedRequestHistory':
    case 'pendingRequests':
      defaultValue = (value, data) => data.for.job_name;
      break;
    default:
      break;
  }

  return { 
    name,
    label: "Job",
    tooltip_section: "New",
    visible_in_tooltip,
    defaultValue
  };
};