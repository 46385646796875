import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { connect } from "react-redux";
import { Ring } from "react-awesome-spinners";
import {
  FormButton,
  FormButtonsWrap,
  ErrorsBlock,
  LoaderWrap,
  FormHeading,
  IconWrap,
} from "../";
import { unselectAllRecord, pushMessage } from "../../actions";
import { CANCEL_REQUEST_WITH_BUDGET, requests, organizationBEABudget } from "../../graphql";

const CancelRequest = (props) => {
  const {
    selectedRequests,
    account,
    hideModal,
    unselectAllRecord,
    pushMessage,
  } = props;

  const queryFilter = { requester_pers_no: { eq: account.pers_no } };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);

  const [cancelRequestWithBudget] = useMutation(CANCEL_REQUEST_WITH_BUDGET);

  const handleQuit = (event) => {
    hideModal();
    unselectAllRecord("requests");
  };

  const handleRequestCancelSend = async (event) => {
    setIsSubmitting(true);
    setSubmitErrors([]);

    const results = await Promise.all(
      selectedRequests.map(async ({ employee_request_id }) => {
        try {
          await cancelRequestWithBudget({
            variables: {
              employee_request_id,
            },
            refetchQueries: [
              {
                query: requests,
                //test for refetch
                fetchPolicy: "no-cache",
                variables: {
                  // TODO - get this from MyRequests.js state

                  limit: 20,
                  offset: 0,
                  orderBy: [{ column: "upd_ins_ts", order: "DESC" }],
                  where: queryFilter,
                },
              },
              {
                query: organizationBEABudget,
                variables: {
                  where: { year: { eq: new Date().getFullYear().toString() } },
                },
              },
            ],
          });
          return { [employee_request_id]: false };
        } catch (e) {
          return { [employee_request_id]: e.message };
        }
      })
    );

    const errors = results.filter((r) =>
      Object.keys(r).find((r_id) => r[r_id])
    );
    if (!errors.length) {
      hideModal();
      unselectAllRecord("requests");
      pushMessage({
        type: "success",
        message: "Request(s) successfully canceled.",
      });
    } else {
      setSubmitErrors(errors.map((e) => Object.values(e).shift()));
      setIsSubmitting(false);
    }
  };

  /* Release 3.1 - Starts */
  var selectedEmployeeRequestIds = selectedRequests.map(function (el) {
    return el.employee_request_id;
  });
  /* Release 3.1 - Ends */

  return (
    <div>
      <FormHeading>
        Are you sure you wish to cancel request for {selectedEmployeeRequestIds.join()}?
      </FormHeading>
      {submitErrors.length > 0 &&
        submitErrors.map((err) => {
          return (
            <ErrorsBlock key={Math.random()}>
              <IconWrap>!</IconWrap>
              {err}
            </ErrorsBlock>
          );
        })}
      <FormButtonsWrap>
        {/* DEFECT No. 6 STARTS  */}
        <FormButton onClick={handleQuit}>Quit</FormButton>
        <FormButton
          primary
          disabled={isSubmitting}
          onClick={handleRequestCancelSend}
        >
          Cancel request(s)
        </FormButton>
        {/* DEFECT No. 6 ENDS  */}
        {isSubmitting && (
          <LoaderWrap>
            <Ring color={"#001235"} size="2" sizeUnit="rem" />
          </LoaderWrap>
        )}
      </FormButtonsWrap>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRequests: state.selectedRequests,
    account: state.account,
  };
};

export default connect(mapStateToProps, { unselectAllRecord, pushMessage })(
  CancelRequest
);
