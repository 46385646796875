//Release 2 - Budget Management add new budget pop - up
import React, { useState, useRef,useEffect } from 'react';
import { apolloClient, apolloProxyClient } from '../../index';
import { useMutation } from '@apollo/react-hooks';
import { Ring } from 'react-awesome-spinners';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp, faChartPie } from '@fortawesome/free-solid-svg-icons'
import { FormButton, FormButtonsWrap, ErrorsBlock, LoaderWrap, IconWrap, DescriptionInput } from '../';
import AsyncSelect from 'react-select/async';
import { pushMessage } from '../../actions';
import { ORG_SUGGEST, PARENT_BUDGET, CREATE_BUDGET, budgetsForAdmin } from '../../graphql';


const NewBudget = props => {
    const { account, hideModal,modal, pushMessage } = props;    
    const proxyCheck = sessionStorage.getItem("proxy_access");
    const httpClient = proxyCheck == null ? apolloClient : apolloProxyClient;
    const [submitErrors, setSubmitErrors] = useState(false);
    const [selectedyear, setSelectedYear] = useState(parseInt(modal.modalContent.toString()));
    const [currentyear] = useState(new Date().getFullYear());
    const [nextyear] = useState(new Date().getFullYear() + 1);
    const [isParent, checkParent] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [capitalAvailable, setCapitalAvailable] = useState(0);
    const [capital, setCapital] = useState('');
    const [newCapital, setNewCapital] = useState(0);
    const [newBudget, setNewBudget] = useState(0);
    const [requestDescription, setRequestDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const capitalInput = useRef(null);
    const budgetInput = useRef(null);
    const  [allOrgs, setAllOrgs] = useState([]);
    const [createBudget] = useMutation(CREATE_BUDGET);
    useEffect(() => {
        setIsSubmitting(true);
        httpClient.query({
            query: ORG_SUGGEST,
            variables: { where: {  year: { eq: modal.modalContent.toString() } }, offset: 0 },
        })
            .then((response) => {
               
               
                if (response.data && response.data.organizationsForAdmin) {

                   
                    
                    setAllOrgs(  response.data.organizationsForAdmin.map(
                        (a) => ({
                            label: a.organization_unit_name,
                            value: a.organization_unit,
                            parent: a.organization_unit_1
                        })
                    ));
                    
                }
                setIsSubmitting(false);
            })
            .catch((err) =>{
            console.error(err);
            setIsSubmitting(false);}
        )

    },[])
    const labelStyle = {
        "fontSize": "15px",
        "fontWeight": "400",
        "width": "50%",
        "float": "left",
        "marginTop": "2rem"
    }

    function formatNumber(n) {

        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


    function formatCurrency(input_val, event, blur) {
        if (input_val === "") { return; }
        var original_len = input_val.length;
        var caret_pos = event.target.selectionStart;


        if (input_val.indexOf(".") >= 0) {

            var decimal_pos = input_val.indexOf(".");
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = formatNumber(left_side);
            right_side = formatNumber(right_side);


            if (blur === "blur") {

                right_side += "00";
            }

            right_side = right_side.substring(0, 2);

            input_val = "€" + left_side + "." + right_side;
            if (blur === "blur" && event.target.id == "new_budget" && !isParent && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) > capitalAvailable) {
                alert("Budget must be lesser than available capital");
                input_val = "";
            }
            if (event.target.id == "new_budget" && newCapital == 0) {
                alert("Please enter capital budget first");
                input_val = "";
            }
            else if(event.target.id == "new_budget"  && newCapital < parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", ""))){
                alert("Budget must be lesser than capital budget");
                input_val = "";
            }

        } else {

            input_val = formatNumber(input_val);
            input_val = "€" + input_val;

            if (blur === "blur") {

                input_val += ".00";
                if (event.target.id == "new_budget" && !isParent && parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) > capitalAvailable ) {
                    alert("Budget must be lesser than available capital");
                    input_val = "";

                }
                if (event.target.id == "new_budget" && newCapital == 0) {
                    alert("Please enter capital budget first");
                    input_val = "";
                }
                else if(event.target.id == "new_budget"  && newCapital < parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", ""))){
                    alert("Budget must be lesser than capital budget");
                    input_val = "";
                }
            }
        }
        event.target.value = input_val;
        if (blur === "blur") {
            if (event.target.id == "new_budget") { setNewBudget(parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) == NaN ? 0 : parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", ""))) }
            if (event.target.id == "new_capital") { setNewCapital(parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", "")) == NaN ? 0 : parseFloat(event.target.value.replaceAll("€", "").replaceAll(",", ""))) }
            }

        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        event.target.setSelectionRange(caret_pos, caret_pos);
    }

    const handleDescriptionChange = event => setRequestDescription(event.target.value);

    const FetchOrg = (input) => {
        if (input.length >= 3) {
            console.log(input);

            return new Promise((resolve, reject) => {



                        if (allOrgs && allOrgs.length > 0) {

                            resolve(allOrgs.filter(function (el) {
                                return el.label.toLowerCase().includes(input.toLowerCase());
                              }));
                        }
                    
                    
                   
            })
        }
    };


    const handleQuit = event => {
        hideModal();

    };

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value));
    }

    const setOrg = async (e) => {
        capitalInput.current.value = "";
        budgetInput.current.value = "";
        if (e.value == e.parent) {
            checkParent(true);
            setSelectedOrg(e.value);
        }
        else {
            setIsSubmitting(true);
            checkParent(false);
            await httpClient.query({
                query: PARENT_BUDGET,
                fetchPolicy: "network-only",
                variables: { organization_unit: e.value, organization_unit_1: e.parent, year: selectedyear.toString() },
            }).then((response) => {


                if (response.data && response.data.organizationBudgetForAdmin) {
                    console.log(response.data);
                    if (response.data.organizationBudgetForAdmin.capital_budget !== 0) {
                        if (response.data.organizationBudgetForAdmin.capital_budget_available != 0) {
                            setCapitalAvailable(response.data.organizationBudgetForAdmin.capital_budget_available);
                            setCapital(response.data.organizationBudgetForAdmin.capital_budget);
                            setNewCapital(response.data.organizationBudgetForAdmin.capital_budget)
                            checkParent(false);
                            setSelectedOrg(e.value);
                          
                            setNewBudget(0);
                            capitalInput.current.value = response.data.organizationBudgetForAdmin.capital_budget.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                            setIsSubmitting(false);
                        }
                        else {
                            alert("No Capital Budget Available under this parent organisation");
                            setCapitalAvailable(response.data.organizationBudgetForAdmin.capital_budget_available);
                            setCapital('');
                            checkParent(false);
                            setSelectedOrg('');
                            setNewCapital(0);
                            setNewBudget(0);
                            setIsSubmitting(false);
                        }
                    }
                    else {
                        alert("No Capital Budget is assigned for this parent organisation");
                        setCapitalAvailable(0);
                        setCapital('');
                        checkParent(false);
                        setSelectedOrg('');
                        setNewCapital(0);
                        setNewBudget(0);
                        setIsSubmitting(false);
                    }

                }
                else {
                    alert("Issue in checking parent budget details");
                    setCapitalAvailable(0);
                    setCapital('');
                    checkParent(false);
                    setSelectedOrg('');
                    setNewCapital(0);
                    setNewBudget(0);
                    setIsSubmitting(false);
                }
            }, error => {
                console.log('Graph QL error: ' + error.message);
                if (error.message == "GraphQL error: Parent organization budget not avaible") {
                    alert("Budget is not allocated for the parent. Please allocate for the parent first.");
                    setCapitalAvailable(0);
                    setCapital('');
                    checkParent(false);
                    setSelectedOrg('');
                    setNewCapital(0);
                    setNewBudget(0);
                    setIsSubmitting(false);
                }
                else {
                    alert("Issue in checking Parent Budget - " + error.message);
                    setCapitalAvailable(0);
                    setCapital('');
                    checkParent(false);
                    setSelectedOrg('');
                    setNewCapital(0);
                    setNewBudget(0);
                    setIsSubmitting(false);
                }
            });


        }

    }

    const handleEdit = async event => {
        try {
            setIsSubmitting(true);
            const results = await createBudget({
                variables: {
                    data: {

                        description: requestDescription,
                        budget: parseFloat(newBudget),
                        capital_budget: isParent ? parseFloat(newCapital) : parseFloat (0),
                        budget_available: parseFloat(newBudget),
                        budget_in_progress: parseFloat(0),
                        budget_spent: parseFloat(0),
                        year:selectedyear.toString(),
                        organization_unit: selectedOrg
                    }
                },
                awaitRefetchQueries: true,
                refetchQueries:  [
                    { query: budgetsForAdmin,  variables: {
                        where: { year: { eq: selectedyear.toString() } },
                      
                        offset: 0,
                        limit: 20,
                        orderBy : null
                        
                    }}
                  ]
            });
            //console.log(results);
            if (results) {
                setIsSubmitting(false);
                hideModal();
            
                pushMessage({
                  type: 'success',
                  message: 'Budget Created successfully!'
                })
            }
        }
        catch (e) {
            console.log('Graph QL error: ' + e.message );
            alert("Issue in creating the budget." + e.message);
            setSubmitErrors(true);
            setIsSubmitting(false);
        }

    };

    const handleCurrencyKeyUp = (e) => {
        formatCurrency(e.target.value, e);
    }
    const handleCurrencyBlur = (e) => {
        formatCurrency(e.target.value, e, "blur");
    }
    return (
        <div style={{
            "width": "50%",
            "padding": "2px",
            "margin": "0 auto"
        }}>
            <input id="searchResult" style={{ "display": "none" }} />
            <div style={{
                "fontSize": "20px",
                "fontWeight": "400",
                "textAlign": "center"
            }}>Add New Budget</div>

            <div style={{ "width": "100%", "float": "left" }}>
                {/*  ADD FORM FIELD FIELDS TO FIX DEFECT 11 STARTS */}
                <div>
                    <div style={labelStyle}>Year</div>

                    <div style={labelStyle}>
                        <select onChange={handleYearChange} value={modal.modalContent.toString()} disabled={true}>
                            <option value={currentyear}>{currentyear}</option>
                            <option value={nextyear }>{nextyear}</option>

                        </select>
                    </div>
                </div>
                <div>
                    <div style={labelStyle}>Organisation Unit</div>
                    <div style={labelStyle}><AsyncSelect
                        loadOptions={FetchOrg }
                        //filterOption={createFilter({ ignoreAccents: false })}
                        style={{"width" : "100%"}}
                       
                        //options={allOrgs}
                        onChange={(e) => setOrg(e)}
                        placeholder="Type atleast three letters"
                        className="select"
                    />
                        <div>
                            {isParent && <div style={{
                                "paddingTop": "0.6rem",
                                "float": "left",
                                "color": "forestgreen"
                            }}> <FontAwesomeIcon icon={faArrowCircleUp} />  Parent Organisation </div>}
                        </div>
                    </div>
                </div>
                <div>
                    <div style={labelStyle}>Capital Budget {isParent && <span style={{"color" : "red"}}>*</span>}</div>

                    <div style={labelStyle}><input id="new_capital" ref={capitalInput} type="text" style={{ "height": "2rem", "width" : "100%"  }} disabled={isParent ? "" : "disabled"} pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" placeholder="&#8364;" onKeyUp={handleCurrencyKeyUp} onBlur={handleCurrencyBlur} /></div>
                    <div>
                        {selectedOrg != '' && !isParent && <div style={{
                            "paddingTop": "0.6rem",
                            "fontSize": "15px",
                            "color": "forestgreen"
                        }}> <FontAwesomeIcon icon={faChartPie} />  Capital Available : €  {capitalAvailable.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </div>}
                    </div>
                </div>
                <div>
                    <div style={labelStyle}>Budget <span style={{"color" : "red"}}>*</span></div>
                    <div style={labelStyle}><input id="new_budget" ref={budgetInput} type="text" style={{ "height": "2rem", "width" : "100%" }} pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" placeholder="&#8364;" onKeyUp={handleCurrencyKeyUp} onBlur={handleCurrencyBlur} /></div>
                </div>
               
                <div>
                    <div style={labelStyle}>Budget Spent</div>
                    <div style={labelStyle}><label>&#8364; 0.00</label></div>
                </div>
                <div>
                    <div style={labelStyle}>Budget In Progress</div>
                    <div style={labelStyle}><label>&#8364; 0.00</label></div>
                </div>
                <div>
                    <div style={labelStyle}>Budget Available</div>
                    <div style={labelStyle}><label>&#8364; 0.00</label></div>
                </div>
                
                <div>
                    <div style={labelStyle}>Budget description</div>
                    <div style={labelStyle}><DescriptionInput
                      style={{"width" : "100%"}}
                        name='request_description'
                        onChange={handleDescriptionChange}
                        defaultValue=""
                    /></div>
                </div>

            </div>
            {/*  ADD FORM FIELD FIELDS TO FIX DEFECT 11 ENDS */}

            {submitErrors.length > 0 && submitErrors.map(err => {
                return (
                    <ErrorsBlock key={Math.random()}>
                        <IconWrap>!</IconWrap>
                        {err}
                    </ErrorsBlock>
                );
            })}
            <FormButtonsWrap style={{ "width": "100%" }}>
                <FormButton onClick={handleQuit}>Quit</FormButton>
                <FormButton primary disabled={isSubmitting || ( selectedOrg == '' || newBudget <= 0 || newCapital <= 0) ? true : false} onClick={handleEdit}>Submit</FormButton>
                {isSubmitting && <LoaderWrap><Ring color={'#001235'} size='2' sizeUnit='rem' /></LoaderWrap>}
            </FormButtonsWrap>

        </div>
    );
};

const mapStateToProps = state => {
    return {

        account: state.account
    };
};

export default connect(
    mapStateToProps,
    {  pushMessage }
)(NewBudget);
