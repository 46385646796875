export default (table) => {
  let name = 'country_name';
  let visible_in_tooltip = /* Release 3.2 - 1.1 - Starts */(column, data, account) =>    
  ((data.request_type === "businessexcellenceaward" || data.request_type === "onetime") || (data.request && (data.request.request_type === "businessexcellenceaward" || data.request.request_type === "onetime"))) ? false : true;
  /* Release 3.2 - 1.1 - Ends */
  let defaultValue = (value, data) => data.for.country_name;

  switch (table) {
    case 'editCompensation':
    case 'rejectRequests':
    case 'approveRequests':
      case 'editInternalHire':
      name = 'request.country_name';
      defaultValue = (value, data) => data.request.for.country_name;
      break;
    case 'requestCompesation': 
      defaultValue = (value, data) => data.country_name;
      break;
    default:
      break;
  }

  return { 
    name,
    label: "Country",
    tooltip_section: "New",
    visible_in_tooltip,
    defaultValue
  };
};