import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";

const TooltipInfo = (props) => {
  const { data, columns, accountConfig, config } = props;
  console.log("Tooltip Data: ", data);
  console.log("Tooltip column", columns);
  const defaultValue = (column, data) => {
    if (column.defaultValue) {
      //const value = column.name.split('.').reduce((o,i)=>o[i], data);
      //return column.defaultValue(value, data) || '';
      /*  DEM0060697 OCC Corrections 2 CHANGES STARTS */
      let value = "";
      var exp_1 = new Array();
      exp_1 = column.name.split("___");
      if (!exp_1[1] || exp_1[1] == "") {
        value = column.name.split(".").reduce((o, i) => o[i], data);
        return column.defaultValue(value, data) || "";
      } else {
        if (exp_1[1] || exp_1[1] != "") {
          value = exp_1[1].split(".").reduce((o, i) => o[i], data);
          return column.defaultValue(value, data) || "";
        } else {
          value = exp_1[0].split(".").reduce((o, i) => o[i], data);
          return column.defaultValue(value, data) || "";
        }
      }
      /*  DEM0060697 OCC Corrections 2 CHANGES ENDS */
    }
    return "";
  };

  const isVisibleInTooltip = (column) => {
    return typeof column.visible_in_tooltip === "function"
      ? column.visible_in_tooltip(column, data, accountConfig) || false
      : column.visible_in_tooltip;
  };

  const canShowSection = (sectionColumns) => sectionColumns.length > 0;

  const isAllowed = (column) => {
    if (column.allow) {
      return column.allow.find((role) =>
        accountConfig.roles.find((arole) => arole === role)
      );
    }
    return true;
  };

  const tooltipSections = columns.reduce(
    (a, c) =>
      c.tooltip_section && !a.includes(c.tooltip_section)
        ? a.concat(c.tooltip_section)
        : a,
    []
  );
  return (
    <StyledTooltip key={`thead-${Math.random()}`}>
      {tooltipSections &&
        tooltipSections.map((section) => {
          const sectionColumns = columns.filter(
            (c) =>
              isVisibleInTooltip(c) &&
              isAllowed(c) &&
              c.tooltip_section === section
          );

          return (
            <div key={Math.random()}>
              {canShowSection(sectionColumns) && (
                <TooltipHeading key={`thead-${Math.random()}`}>
                  {section}
                </TooltipHeading>
              )}
              {/* R2 - Removed grid for Comments and Approver Chain sections */}
              {sectionColumns[0] !== undefined &&
              (sectionColumns[0].tooltip_section === "Comments" ||
                sectionColumns[0].tooltip_section === "Approval Chain" ||
                sectionColumns[0].tooltip_section === "Change Approver") ? (
                <TooltipRowComments key={`trow-${Math.random()}`}>
                  {sectionColumns &&
                    sectionColumns.map((col) => {
                      //const value = col.name.split('.').reduce((o,i)=>o[i], data);
                      /*  DEM0060697 OCC Corrections 2 CHANGES STARTS */
                      let value = "";
                      var exp_1 = new Array();
                      exp_1 = col.name.split("___");
                      if (!exp_1[1] || exp_1[1] == "") {
                        value = col.name
                          .split(".")
                          .reduce((o, i) => o[i], data);
                      } else {
                        if (exp_1[1] || exp_1[1] != "") 
                        {
                          /*  CAL5586521 - Fixes of Org Unit Name Issue Starts */
                          if(exp_1[1].split(".").reduce((o, i) => o[i], data) == 'N/A')
                          {
                            value = exp_1[0]
                            .split(".")
                            .reduce((o, i) => o[i], data);  
                          }
                          else
                          {
                            value = exp_1[1]
                            .split(".")
                            .reduce((o, i) => o[i], data);
                          }
                          /*  CAL5586521 - Fixes of Org Unit Name Issue Ends */
                        } else {
                          value = exp_1[0]
                            .split(".")
                            .reduce((o, i) => o[i], data);
                        }
                      }
                      /*  DEM0060697 OCC Corrections 2 CHANGES ENDS */
                      if (!isAllowed(col)) return null;
                      return (
                        // <TooltipCell key={`tcell-${col.name}`}>
                        <TooltipCell key={`tcell-${Math.random()}`}>
                          <div className="hideArrowStyles">
                            {col.label ? (
                              // <TooltipCellLabel key={`tcelllabel-${col.name}`}>
                              <TooltipCellLabel key={`tcelllabel-${Math.random()}`}>
                                {col.label}:{" "}
                              </TooltipCellLabel>
                            ) : (
                              ""
                            )}
                            {col.renderer
                              ? col.renderer(value, data, accountConfig, config)
                              : value || defaultValue(col, data)}
                          </div>
                        </TooltipCell>
                      );
                    })}
                </TooltipRowComments>
              ) : (
                <TooltipRow key={`trow-${Math.random()}`}>
                  {sectionColumns &&
                    sectionColumns.map((col) => {
                      //const value = col.name.split('.').reduce((o,i)=>o[i], data);
                      /*  DEM0060697 OCC Corrections 2 CHANGES STARTS */
                      let value = "";
                      var exp_1 = new Array();
                      exp_1 = col.name.split("___");
                      if (!exp_1[1] || exp_1[1] == "") {
                        value = col.name
                          .split(".")
                          .reduce((o, i) => o[i], data);
                      } else {
                        if (exp_1[1] || exp_1[1] != "") 
                        {
                          /*  CAL5586521 - Fixes of Org Unit Name Issue Starts */
                          if(exp_1[1].split(".").reduce((o, i) => o[i], data) == 'N/A')
                          {
                            value = exp_1[0]
                            .split(".")
                            .reduce((o, i) => o[i], data);  
                          }
                          else
                          {
                            value = exp_1[1]
                            .split(".")
                            .reduce((o, i) => o[i], data);
                          }
                          /*  CAL5586521 - Fixes of Org Unit Name Issue Ends */
                        } else {
                          value = exp_1[0]
                            .split(".")
                            .reduce((o, i) => o[i], data);
                        }
                      }
                      /*  DEM0060697 OCC Corrections 2 CHANGES ENDS */
                      if (!isAllowed(col)) return null;
                      return (
                        // <TooltipCell key={`tcell-${col.name}`}>
                        <TooltipCell key={`tcell-${Math.random()}`}>
                          {col.label ? (
                            // <TooltipCellLabel key={`tcelllabel-${col.name}`}>
                            <TooltipCellLabel key={`tcelllabel-${Math.random()}`}>
                              {col.label}:{" "}
                            </TooltipCellLabel>
                          ) : (
                            ""
                          )}
                          {col.renderer
                            ? col.renderer(value, data, accountConfig, config)
                            : value || defaultValue(col, data)}
                        </TooltipCell>
                      );
                    })}
                </TooltipRow>
              )}
              {/* R2 - Removed grid for Comments and Approver Chain sections */}
            </div>
          );
        })}
    </StyledTooltip>
  );
};

const mapStateToProps = (state) => {
  return {
    accountConfig: state.account,
    config: state.config,
  };
};

export default connect(mapStateToProps)(TooltipInfo);

const StyledTooltip = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
`;

const TooltipHeading = styled.h2`
  margin: 0;
  border-bottom: 1px solid #d2d2d2;
`;

const TooltipRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 5px 0;
`;

const TooltipRowComments = styled.div`
  display: block;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 5px 0;
`;

const TooltipCell = styled.div``;

const TooltipCellLabel = styled.span`
  font-weight: bold;
`;
