import React from 'react';
import { TableHeader, Row } from '../';

const SelectedTable = props => {
  /**release  2 Our talent scenario change - changeData prop added */
  const { columns, recordType, items , changeData} = props;
  const indexColumn = columns.find(c => c.index);
  //console.log("update 2 SelectTable changeData", changeData);
  return (
    <>
      <TableHeader recordType={recordType} columns={columns} infoTooltip />
      {items.map((item, index) => {
        const background = index % 2 ? '#f5f5f5' : '#ffffff';
        return (
          <Row 
            key={`row-${item[indexColumn.name]}`}
            columns={columns}
            item={item}
            background={background}
            expandable
            infoTooltip
            keepLocalCurrency
            changeData
            {...props}
          />
        );
      })}
    </>
  );
};

export { SelectedTable };
